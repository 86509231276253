import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldInterface } from '../../../interfaces/FormFieldInterafce';
@Component({
  selector: 'rubicon-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss']
})
export class TextareaComponent implements OnInit {
  field: FormFieldInterface;
  group: FormGroup;
  slug: string;
  indexVal: Number;
  constructor(@Inject('environment') private environment) { }

  ngOnInit() {
    this.indexVal=new Date().getTime();
  }

  ngAfterViewInit(){
    setTimeout(()=>{
      if(this.group.get(this.field.name)){
        this.group.get(this.field.name).markAsPristine({onlySelf: true});
      }
    },0);
  }
}
