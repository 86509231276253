import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'listSort'
})
export class ListSortPipe implements PipeTransform {

  transform(list: any,sortKey:string,sortOrder:number,masterData:any,valueKey:string,displayKey:string): any {
    let masterMap={};
    let newList = [...list];
    if(masterData){
      masterData.forEach((item)=>{
        masterMap[item[valueKey]]=item[displayKey];
      });
    }
    if(sortKey){
      newList.sort(function(a,b){
      a=_.get(a,sortKey);
      b=_.get(b,sortKey);
      if(masterData){
        a=masterMap[a];
        b=masterMap[b];
      }
      return a>b ? sortOrder :  a < b ? -sortOrder : 0;
   });
  }
    return newList;
  }

}
