<ng-container [formGroup]="group">
    
    
    <ng-container *ngIf="field?.css?.floatingLabel; else normal">
        <div [ngClass]="(field?.css?.switchLabelClass)? field?.css?.switchLabelClass :'floating-label'">
                <div class="onoffswitch float-right">
                        <input type="checkbox"  checked [ngClass]="(field?.css?.inputClass)? field?.css?.inputClass :''" [formControlName]="field.name" [id]="field.name + '_' + indexVal" checked />
                        <label class="onoffswitch-label" [for]="field.name + '_' + indexVal">
                          <span class="onoffswitch-inner"></span>
                          <span class="onoffswitch-switch"></span>
                        </label>
                      </div>
                      
            <!-- <div class="switchBtn">
                <input type="checkbox" checked [ngClass]="(field?.css?.inputClass)? field?.css?.inputClass :''" [formControlName]="field.name" [id]="field.name + '_' + indexVal" checked />
                <label [for]="field.name + '_' + indexVal" class="lbl-off">No</label>
                <label [for]="field.name + '_' + indexVal" class="lbl-on">Yes</label>
            </div> -->
            <label class="labelText w-75" for="Areyoubusinessanother">Are you doing business with another name?</label>

            <!-- <ng-container *ngIf="group.get(field.name).value">
               <input 
                type="text"  
                placeholder="&nbsp;"
                [ngClass]="'floating-input'" 
                [formControlName]="'another_business_name'">
                <span class="highlight"></span>
                <div *ngIf="group.get('another_business_name').invalid && (group.get('another_business_name').dirty || group.get('another_business_name').touched)"
                class="error-msg">             
                    <label class="error" *ngIf="group.get('another_business_name').hasError('required')">
                        {{slug + ".errorMessage." + 'another_business_name' + "." + 'required' | translate}}
                    </label>
                    <label class="error" *ngIf="group.get('another_business_name').hasError('pattern')">
                        {{slug + ".errorMessage." + 'another_business_name' + "." + 'pattern' | translate}}
                    </label>
                <label class="error" *ngIf="group.get('another_business_name').hasError('incorrect')">
                    {{slug + ".errorMessage." + 'another_business_name' + ".incorrect" | translate}}
                </label>
            </div>
            </ng-container> -->
        </div>
        
    </ng-container>
    
    
    <ng-template #normal>
        
        <span [ngClass]="(field?.css?.labelClass)? field?.css?.labelClass :'secondary-label pb-1 d-block'">
            
            <span href="javascript:void(0)" *ngIf="slug + '.description.' + field.name | translate" class="infoTip" #infoTip data-placement="top" data-toggle="tooltip"
                [title]="slug + '.description.' + field.name | translate">
            </span>
            <span class="switchWrap" [ngClass]="(field?.css?.switchClass)? field?.css?.switchClass :'switchWrap'">
                <label [ngClass]="(field?.css?.switchLabelClass)? field?.css?.switchLabelClass :'el-switch el-switch-sm'" [for]="field.name + '_' + indexVal">
                    <span class="sr-only">{{slug+'.placeholder.'+field.name | translate }}</span>
                    <input type="checkbox" [ngClass]="(field?.css?.inputClass)? field?.css?.inputClass :''" [formControlName]="field.name" [id]="field.name + '_' + indexVal" checked #elementSwitch>
                    <label class="pt-1 clt" [for]="field.name + '_' + indexVal"> <span class="label-text" [innerHTML]='slug +".label."+field.name | translate' ></span>
                        <ng-container *ngIf="field?.css?.tooltip && field?.css?.tooltip[field.name]"><!--
                        -->
                        <label for="">
                        <a  class='que_info' [ngClass]="{'info-icon-tooltip': field?.css?.tooltip?.prequal_checkbox === 'prequal'}" placement="top" [triggers]="(field?.css?.tooltip && field?.css?.tooltip[field.name+ '_trigger'])?field?.css?.tooltip[field.name+ '_trigger']:'hover'" [ngbTooltip]="tipContent"><!----></a></label><!--​
                        --><ng-template #tipContent><span [innerHTML]='slug + ".tooltip."+ field.name | translate'></span></ng-template><!--
                        --></ng-container><!--
                        -->
                    </label>
                    <span class="el-switch-style"></span>
                </label>
            </span>
            <div [ngClass]="(field?.css?.subHeadingClass)? field?.css?.subHeadingClass :'fs11 fwsb pb-3 pl-4 ml-3'" [innerHTML]="slug +'.label.'+field.name+'_sub_heading' | translate" *ngIf="elementSwitch.checked && field?.css?.showSubHeading">
            </div>

        </span>
        
        <!-- <div class="d-flex checWrap">
            <input type="checkbox" class="form-check-input mt-2" id="safe_harbor_not_reduction" name="safe_harbor_check"> 
            <label class="fs12 fwsb lh pt-1 clt" for="safe_harbor_not_reduction"> Some of these employees are located outside of the U.S.</label>
        </div> -->
    
    
        
    </ng-template>

</ng-container>