import { createReducer, on, Action } from '@ngrx/store';
import * as appActions from './app.actions';

export const APP_KEY = "app"
export interface AppState {
    token?: string;
    nextTask?: string;
    userData?: any;
    appID?: string;
    businessID?: string;
    review?: string;
    otp_email?: string;
    backendUserData?:any;
}

export const initialState: AppState = {
    token: null,
    nextTask: null,
    userData: null,
    appID: null,
    businessID: null,
    otp_email: null,
    backendUserData: null
}
const rootReducer = createReducer(
    initialState
);


export function appReducer(state: AppState | undefined, action: Action) {
    return rootReducer(state, action);
}