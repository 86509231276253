import { createReducer, Action } from '@ngrx/store';

export const UI_KIT_KEY = "uiKitState"

export const initialState = {}

const uiReducer = createReducer(
    initialState
);

export function uiKitReducer(state: any | undefined, action: Action) {
    return uiReducer(state, action);
}