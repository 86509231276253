import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { WorkFlowManagerService } from '../utils/workflow-manager.helper';
@Injectable({
  providedIn: 'root'
})

export class AccessValidatorService {
    
   public app_change_detect = new Subject();
    constructor(
        public store: Store<any>,
        public workflowManagerService: WorkFlowManagerService
    ) {
    }

    checkAccessValidate(appData, validateType: string, action?: any) {
        let is_allowed = false;
        this.store.select('app').pipe(take(1)).subscribe(rootState => {
            let userData = rootState?.userData;
            let userRoleStageAccess = userData?.role_stage_mapping;
            if (userData?.grand_access) {
                is_allowed = true;
            } else {
                switch (validateType) {
                    case 'validateAccess':
                        is_allowed = this.workflowManagerService.validateAccess(appData, userRoleStageAccess, action);
                        break;
                    case 'validateStage':
                        is_allowed = this.workflowManagerService.validateStage(appData, userRoleStageAccess);
                        break;
                    case 'validateStatus':
                        is_allowed = this.workflowManagerService.validateStatus(appData, userRoleStageAccess);
                        break;
                    case 'validateAction':
                        is_allowed = this.workflowManagerService.validateAction(appData, userRoleStageAccess, action);
                        break;
                    case 'validateCurrentAssignment':
                        is_allowed = this.workflowManagerService.validateCurrentAssignment(appData);
                        break;
                    case 'validateUserRestricted':
                        is_allowed = this.workflowManagerService.validateUserRestrict(userData);
                        break; 
                    case 'validateDefaultVisible':
                        is_allowed = this.workflowManagerService.validateDefaultVisible(appData, action);
                        break;
                    default:
                        is_allowed = true;
                        break;
                }
            }
        }).unsubscribe();
        return is_allowed;
    }

    getUserAllowedStage() {
        let stages;
        this.store.select('app').pipe(take(1)).subscribe(rootState => {
            let userData = rootState.userData;
            let userRoleStageAccess = userData.role_stage_mapping;
            stages = this.workflowManagerService.allowedStage(userRoleStageAccess);
        }).unsubscribe();
        return stages;
    }

    changeDetect(data: any) {
        this.app_change_detect.next(data);
    }
}