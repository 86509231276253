import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CommonService } from '@rubicon/utils';
import { FormFieldInterface } from '../../../interfaces/FormFieldInterafce';


@Component({
  selector: 'rubicon-amount',
  templateUrl: './amount.component.html',
  styleUrls: ['./amount.component.scss']
})
export class AmountComponent implements OnInit {

  field: FormFieldInterface;
  group: FormGroup;
  slug: string;
  currency: string;
  indexVal: Number;
  ngForIndex: number = 0;
  ifsLabel = false;
  
  constructor(
    private common: CommonService,
    @Inject("environment") public environment
  ) { }

  ngOnInit(): void {
    if (['ifs-data'].includes(this.slug)) {
      this.ifsLabel = true;
    }
    this.indexVal=new Date().getTime();
      this.currency = "$";
  }

  onPaste(event: ClipboardEvent) {
    let clipboardData = event.clipboardData || window['clipboardData'];
    let pastedText = clipboardData.getData('text/plain').replace('$ ', '').replace(/,/g, '')?.trim();
    // if (pastedText.match(/[^\d]/)) {
    if (!pastedText.match(/^-?[0-9]\d*(\.\d+)?$/)) {
      this.common.popToast('error','', 'Only numeric values are allowed');
      event.preventDefault();
    } else {
      setTimeout(() => {
        this.group.get(this.field.name).patchValue(pastedText);
      }, 20);
    }
    // let clipboardData = event.clipboardData || window['clipboardData'];
    // let pastedText = clipboardData.getData('text').replace('$ ', '').replace(/,/g, '');
    // setTimeout(() => {this.group.get(this.field.name).patchValue(pastedText, {emitEvent: false})},20)
  }

  // focusin(event) {
  //   // remove all , from the amount when the element is focussed in 
  //   let amount = event.target.value;
  //   amount = amount.replace(/,/g, "");
  //   event.target.value = amount;
  // }

  // focusout(event) {
  //   // Adding , in between the numbers -- also included for decimal digits and adding $ or rs sign before 
  //   // it depending on country -- US -- $ IND -- RS
  //   const amount = event.target.value;
  //   const parts = amount.toString().split(".");
  //   parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  //   const get_res = parts.join(".");
  //   event.target.value = get_res;
  // }

}
