import { Component, OnInit, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldInterface } from '../../../interfaces/FormFieldInterafce';
import { Options } from 'ng5-slider';
import { CommonService } from '@rubicon/utils';
@Component({
  selector: 'rubicon-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {

  field: FormFieldInterface;
  group: FormGroup;
  slug: string;
  sliderManualRefresh: EventEmitter<void> = new EventEmitter<void>();
  newOptions: Options;
  value: number;
  constructor(private common: CommonService) { }

  ngOnInit() {
    this.field.slider = {}
    this.sliderConfig();
  }

  sliderConfig() {
    this.common.masterData
      .subscribe((data: any) => {
        if (data && data[this.field.name]) {
          this.field.slider = data[this.field.name];
        } else {
          this.field.config.rules.forEach((rule) => {
            switch (rule.name) {
              case 'min':
                this.field.slider.min = rule.value;
                break;
              case 'max':
                this.field.slider.max = rule.value;
                break;
              case 'step':
                this.field.slider.step = rule.value;
                break;
            }
          });
        }
        this.newOptions = this.createOptions();
        if (!this.group.get(this.field.name).value) {
          this.value = this.field.slider.min;
          this.group.get(this.field.name).patchValue(this.field.slider.min);
        } else {
          this.value = this.group.get(this.field.name).value;
        }
      });
  }

  /**
   * function to create slider options
   */
  createOptions() {
    return {
      floor: this.field.slider.min,
      ceil: this.field.slider.max,
      step: this.field.slider.step,
      showTicksValues: true,
      translate: (value: number): string => {
        if (this.field.type === 'range_amount')
          return '$ ' + (+value).toLocaleString();
        if (this.field.type === 'range_percentage')
          return value+'%';
        if (this.field.type === 'range')
          if (value > 1)
            return value + ' Years';
        return value + 'Year';
      },
      ticksArray: [
        this.field.slider.min,
        this.field.slider.max
      ],
      getLegend: (value: number): string => {
        if (this.field.type === 'range_amount')
          return '$' + value / 1000 + 'K';
        else if (this.field.type === 'range_percentage') {
          return value + ' %';
        } else {
          if (value > 1)
            return value + ' Years';
          else
            return value + ' Year';
        }
      }
    };
  }

  manualChangeAmount(event: any) {
    event = (event + '').replace(/,/g, "");
    event = parseInt(event, 10);

    if (isNaN(event)) {
      event = this.field.slider.min;
    }
    if (event > this.field.slider.max) {
      this.group.controls[this.field.name].setErrors({ 'incorrect': true });
      return this.field.slider.max;
    }
    if (event < this.field.slider.min) {
      this.group.controls[this.field.name].setErrors({ 'incorrect': true });
      return this.field.slider.min;
    }
    return event;
  }

  storeAmount(value) {
    this.group.get(this.field.name).patchValue(value);
  }

}
