import { Injectable, Inject } from '@angular/core';
import * as JSZip from 'jszip';
import { TaskInfoService } from './task-info.service';
import { map, switchMap } from 'rxjs/operators';
import { forkJoin, throwError, of, zip } from 'rxjs';
import { CommonService } from '../common/common.service';
import { CUSTOMER_PORTAL } from '@rubicon/interface/task-info';

@Injectable({
  providedIn: 'root'
})
export class TakeItAwayServiceService {
 

  constructor(private taskInfoService: TaskInfoService,private common:CommonService,
    @Inject('environment') private environment) { }

  getAppDetails(user_id, app_id) {
    return this.taskInfoService.getTaskInfo({
      slug: "application-by-userid",
      user_id,
      app_id
    }).pipe(
      switchMap((res: any) => {
        if (res && res.response_data && res.response_data.user_apps &&
          res.response_data.user_apps.data && res.response_data.user_apps.data.data) {
          return of(res.response_data.user_apps.data.data[0]);
        } else {
          return throwError(res);
        }
      })
    );
  }

  getDocsMetaData(bus_ref_obs:any[]){
    return forkJoin(bus_ref_obs.map(ref=>this.taskInfoService.getTaskInfo({
      slug: "get-uploaded-doc",
      doc_id: ref.ref_id
    }))).pipe(map(respArr=>{
      return respArr.map(res=>{
        return res.response_data.get_uploaded_doc.data.data;
      });
    }));
  }
      


  downloadZip(docs_ref: { doc_type: string, ref_id: string, file_name: string, is_esign: boolean ,type:string}[],planData?,skipUrjanet?) {
   if(!skipUrjanet){
    const urjanetRef=docs_ref.find((doc)=>doc.type==="urjanet");
    if(urjanetRef){
      this.getUrjanetDocuments(urjanetRef,docs_ref,planData);
      return;
    } 
  }
    let doc_data_apis = docs_ref.map(doc => {
      return doc.is_esign?this.downBusinessEsignPdf(doc.ref_id): (doc.type==="urjanet"? this.downloadDocument(doc.ref_id):this.getDocument({ doc_id: doc.ref_id }));
    });

    let fileContents = [];
    forkJoin(doc_data_apis).subscribe((resArray) => {
      let duplicates = {};
      resArray.forEach((res, i) => {
        if (res && 
          (res.download_document && res.download_document.data)||(res.download_urjanet_doc && res.download_urjanet_doc.data)
          ) {
          let file_data = res.download_document?res.download_document.data:res.download_urjanet_doc.data.data.pdf_file;
          let file_name = docs_ref[i].file_name;
          if(!file_name){
            file_name=res.get_uploaded_document.data.data.filename;
          }
          try{
            if(!duplicates.hasOwnProperty(file_name)){
              duplicates[file_name] = 0;
            }else{
              let lastDotIndex = file_name.lastIndexOf(".");
              if(lastDotIndex != -1){
                file_name = file_name.slice(0, lastDotIndex) + `(${++duplicates[file_name]})` + file_name.slice(lastDotIndex);
              }else{
                file_name = file_name+`(${++duplicates[file_name]})`;
              }
            }
          }catch{

          }
          fileContents.push({ file_data, file_type: "application/pdf", file_name: file_name });       
        }
      });
      let zip = new JSZip();
      fileContents.forEach(fileContent => {
        let dataUrl =  fileContent.file_data;
        zip.file(fileContent.file_name, dataUrl, { base64: true });
      });
      if (fileContents.length) {
        zip.generateAsync({
          type: "base64"
        })
          .then( (dataUrl) =>{
            let date = new Date();
            dataUrl = 'data:' + "application/zip" + ';base64,' + dataUrl;
            let zipName = "Full_Application" + "_" + date.toUTCString() + ".zip";
            this.openDownloadLink(dataUrl, zipName);
            if(planData){
              this.taskInfoService.saveTaskInfo({slug:'download_application_count'},planData).subscribe(()=>{});
            }
          });
      }
    });
   
  }

  downBusinessEsignPdf(ref_id ) {
    let check_for_esign_api_name = 'check_for_esign';
    if(this.environment.enable_docusign_forgiveness){
      check_for_esign_api_name = 'check_for_docusign';
    }
    return this.getSignedDocument(ref_id, 'get_document').pipe(
      map(data => {
        if (data && 
          data[check_for_esign_api_name] &&
          data[check_for_esign_api_name].data &&
          data[check_for_esign_api_name].data.data &&
          data[check_for_esign_api_name].data.data.result &&
          (data[check_for_esign_api_name].data.data.result.file_data || 
            data[check_for_esign_api_name].data.data.result.presigned_url)
        ) {
          let download_doc;
          if(data[check_for_esign_api_name].data.data.result.file_data){
            download_doc = data[check_for_esign_api_name].data.data.result.file_data;
          } else {
            download_doc =data[check_for_esign_api_name].data.data.result.presigned_url;
          }
          // return {download_document :{data: data[check_for_esign_api_name].data.data.result.file_data}};
          return {download_document :{data: download_doc}};
        }
        return {};
      }));
  }
  getUrjanetDocuments(urjanetRef,docs_ref,planData?){
    docs_ref=docs_ref.filter((ref)=>!ref.type);
    this.taskInfoService.saveTaskInfo( 
      {slug: CUSTOMER_PORTAL.get_urjanet},
      {correlationId: urjanetRef.ref_id},
      ).subscribe(res => {
        if (
          res &&
          res.urjanet_get_url &&
          res.urjanet_get_url.data &&
          res.urjanet_get_url.data.result
        ) {
          let urjanetDocs=[];
          if(Array.isArray(res.urjanet_get_url.data.result)){
          res.urjanet_get_url.data.result.forEach(result =>{
            urjanetDocs = [...urjanetDocs, ...result.statements, ...result.payments];
          });
        }
          urjanetDocs.forEach(url => {
            docs_ref.push({ref_id: url, file_name: this.getFileName(url),type:'urjanet'});
          });
          this.downloadZip(docs_ref,planData,true);
        }
      },()=>{
        this.downloadZip(docs_ref,planData,true);
      });
  }
  downloadDocument(url){
    return this.taskInfoService.saveTaskInfo( 
      {slug: CUSTOMER_PORTAL.download_urjanet_docs},
      { doc_url: url      })
        
  }
  getFileName(url){
    let arr = url.split('/');
    let filename = arr[arr.length-1];
    return filename;
  }
  getSignedDocument(ref_id, action) {
    const docusign = this.environment.enable_docusign_forgiveness?this.environment.enable_docusign_forgiveness:false;
    if(docusign){
      action = "get_document";
    }
    const slug = {
      slug: docusign? 'check-download-docusign': 'check-download-esign'
    }

    const body = {
      ref_id,
      provider: 'E10',
      action,
      sba_loan_type: 'cpabusinessloans'
    }
    if(docusign){
      body['is_old'] = true;
    }
    return this.taskInfoService.saveTaskInfo(slug, body )
      .pipe(
        map((res: any) => {
          return res;
        })
      )
  }

  openDownloadLink(dataUrl, name) {
    if (window.navigator.msSaveBlob) { // IE
      let blob = this.dataURLtoBlob(dataUrl);
      window.navigator.msSaveOrOpenBlob(blob, name)
    } else {
      const downloadLink = document.createElement("a");
      downloadLink.href = dataUrl;
      downloadLink.download = name;
      downloadLink.click();
    }
  }

  dataURLtoBlob(dataurl) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1].replace(/\s/g, '')),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let blob = new Blob([u8arr], { type: mime });
    return blob;
  }

  getDocument(body) {
    return this.taskInfoService.saveTaskInfo({
      slug: "download-documents"
    }, body)
      .pipe(
        map((res: any) => {
          return res;
        })
      )
  }

  getDocumentUpdatedName(DocCount: any, name){

    const count = DocCount[name] ? ++DocCount[name] : 1;
  
    const namePostfix = count > 1 ? ('(' +(count-1)+ ')') : '';

    DocCount[name] = count;

    let extension = 'pdf';
    const lastIndex = name.lastIndexOf('.');
    let filename = name;
    if(lastIndex > -1) {
      filename = name.substring(0, lastIndex);
      const fileExtension = name.substring(lastIndex + 1);
      extension = fileExtension;
    }

    return filename + namePostfix + '.' + extension;
  }


  showPdf(pdfdata?: string, type = 'application/pdf', fileName: string = 'report') {
    
    if (window.navigator.msSaveBlob) {
      const b64toBlob = (b64Data = pdfdata, contentType = type, sliceSize = 512) => {
        const byteCharacters = atob(b64Data);

        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          const slice = byteCharacters.slice(offset, offset + sliceSize);

          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            try {
              byteNumbers[i] = slice.charCodeAt(i);
            }
            catch (e) {
              console.log(e)
            }
          }

          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
      }
      const blob = b64toBlob(pdfdata, type);

      window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
      const linkSource = `data:${type};base64,` + pdfdata;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();

    }
  }
  checkAndDownload(allDocsRef: any[], planData:any) {
      const {firm_name,app_id}=planData;
      this.taskInfoService.getTaskInfo({slug:'download_application_count',firm_name,app_id}).subscribe((response)=>{
        if (response && response.response_data){
           if(response.response_data.get_application_count_by_case_id 
             &&response.response_data.get_application_count_by_case_id.data
             &&response.response_data.get_application_count_by_case_id.data.data
             &&response.response_data.get_application_count_by_case_id.data.data.count>=1){
                this.downloadZip(allDocsRef)
             }
             else if(response.response_data.get_application_count_by_firm_name 
              &&response.response_data.get_application_count_by_firm_name.data
              &&response.response_data.get_application_count_by_firm_name.data.data
              &&response.response_data.get_application_count_by_firm_name.data.data.count<5){
                this.downloadZip(allDocsRef,planData)
              }
              else{
                this.common.popToast("info", "", "We are sorry ! It seems that you have not completed payment for your plan, please contact support to continue downloading application kits");
              }
        }
      });
  }
}
