import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CanAccessService {

  private userRoles: Set<string>;
  private userData: any;
  allowedEverything: boolean;
 
  constructor() {}

  public setUserData(userData) {
    this.allowedEverything = userData.registration_type && userData.registration_type === 'normal';
    this.userData = this.mapUserRoles(userData);
  }

  // returns a boolean observable
  public checkAuthorization(path: string, access:string): Observable<boolean> {
      
    if(this.allowedEverything) return of(true);
    // we are loading the roles only once
    if (!this.userRoles) {
        const currUser = this.userData;
        return of(this.doCheckAuthorization(path, currUser.permissions, access));
    }
    return of(false);
  }

  private doCheckAuthorization(path: string, permissions: any, access: string): boolean {
    let found = false;
    if (path && path.length && permissions) {
      if (access in permissions) {
            const allowedPaths = path.split('|');
            allowedPaths.forEach((allowedPath) => {
              if (!found && permissions[access].includes(allowedPath)) {
                found = true;
              }
            });
        }

      return found;
    }
    return found;
  }

  private mapUserRoles(userData: any) {
    const user:any = {
        name: userData.name,
        id:userData.id,
        role_id : userData.role_id || userData.role,
        email_address: userData.email_address
    };
    
    if(userData.roles) {
        const { roles } = userData;
        user.role = roles.role_slug;
        user.permissions = this.getUserPermissions(roles.permission_assigned); 
        user.visibility =  roles.visibility
    }

    return {...user};
  }

  private getUserPermissions(permission_assigned: any) {
      const permission = {};

      permission_assigned.forEach(el => {

          if (!permission[el.action]) permission[el.action] = [];

          if (el.permissions) {
              permission[el.action].push(el.permissions.slug);
          }
      });

      return {...permission};
  }
}