import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldInterface } from '@rubicon/interface/form-field';
import { CommonService } from '@rubicon/utils_custom';
import { FormService } from '../../../services/form.service';

@Component({
  selector: 'rubicon-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {
  field: FormFieldInterface;
  group: FormGroup;
  slug: string;
  indexVal: number;
  index: number;
  constructor(private common: CommonService, private formService: FormService) { }

  ngOnInit() {
    this.group.get(this.field.name).valueChanges.subscribe((data) => {
      if(this.field.name != 'state_of_incorporation') { /**to skip a selected field from being triggered */
        this.formService.emit(data);
      }
    });

    this.group.get(this.field.name).valueChanges.subscribe((data) => {
      this.formService.emitFieldEvent({group: this.group, data, index: this.index});
    });

    this.indexVal = new Date().getTime();
    this.common.masterData
      .subscribe((data: any) => {
        if (data&&data[this.field.name]) {
          this.field['options'] = data[this.field.name].data.data;
          if(this.field.config && this.field.config.sort) {
            this.field['options'] = (data[this.field.name].data.data || []).sort((a, b) => {
              const sortValue = this.field.config.sort;
              const sortKey = this.field.config.sortKey;
              let result = a[sortKey] > b[sortKey] ? 1: -1;
              if(sortValue === -1) {
                result = b[sortKey] < a[sortKey]? -1: 1;
              }

              return result;
            })
          }
          this.group.get(this.field.name).patchValue(this.group.get(this.field.name).value);
        }
      });
  }
}

