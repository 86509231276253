import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'masterData'
})
export class MasterDataPipe implements PipeTransform {

  transform(value: string, masterData:any,valueKey:string,displayKey:string): string {
    if(value&&masterData){
    let requiredRecord= masterData.find((item)=>item[valueKey]===value);
    return requiredRecord?requiredRecord[displayKey]:'';
    }
    else 
    return '';
  }

}
