export const CPA_FIRM_STATE = {
    CPA_FIRM_VERIFIED: 'Y',
    CPA_FIRM_NOT_VERIFIED: 'N',
    VR_QUEUE: 'VR_QUEUE',
    MARK: 'Mark',
    EA_FIRM_VERIFIED: 'EA_FIRM_VERIFIED',
    BOOKKEEPER_VERIFIED: 'BOOKKEEPER_VERIFIED',
    DECLINED: 'DECLINED',
    CPA_DECLINED: 'CPA DECLINED',
    NON_CPA_DECLINED: 'NON-CPA DECLINED',
    CPA_AND_NON_CPA_DECLINED: 'CPA & NON-CPA DECLINED',
    DIRECT_DECLINED: 'DIRECT CUSTOMER DECLINED'
}

export const CPA_FIRM_STATE_NAME = {
    CPA_FIRM_VERIFIED: 'CPA Firm, Verified',
    EA_FIRM_VERIFIED: 'EA Firm, Verified',
    BOOKKEEPER_VERIFIED: 'Bookkeeper, Verified',
    DECLINED: 'Verification declined'
}
