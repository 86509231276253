import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class BusinessTypeInterceptor implements HttpInterceptor {
  constructor() { }
  skipSlug = ['manual-upload-bank-statements', 'download-documents', 'get_8821_document', 'utility_parser_async', 'document-listing', 'delete-documents', 'verify_hash', 'expire_hash', 'application-by-userid', 'paychex', 'plaid', 'update_payroll_processor', 'upload-application-data', 'sba_calculator_cpa','loan-info-forgiveness','utility_parser_async_dataSource', 'cpabusiness-loan-info-forgiveness','update_email_link_status','decrypt-from-cpa','update_user_terms','fte_calculator_cpa','get-zip-from-link', 'generate-pdf','get-urjanet','download-urjanet-doc','borrower-form','upload_owner_documents,send-owner-esign'];

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
   
    const slug = request.params.get('slug');
    let only_ppp2_businesses = [];
    //let only_ppp2_businesses = ["501(c)(6)_nonprofit", "housing_cooperative", "joint_venture", "professional_association", "trust", "cooperative", "llp", "npo", "tenant_in_common", "esop", "nonprofit_child_care", "robs","single_member_check","qualified_joint_check"];
    return next.handle(request)
    .pipe(
      map(event => {
        if (event instanceof HttpResponse && !request.url.includes('en.json') && !this.skipSlug.includes(slug)) {
            let resp = event.body.data;
            if(resp && resp.response_data && resp.response_data.business_type && resp.response_data.business_type && resp.response_data.business_type.data && resp.response_data.business_type.data.data.length > 1){
                resp.response_data.business_type.data.data = resp.response_data.business_type.data.data.filter(biz_type=>{
                 if (/ppp2/.test(slug))
                  return biz_type.type!=='other';
                else
                 return !only_ppp2_businesses.includes(biz_type.type)

                });
            }
            return event.clone({
                body: {
                  data: event.body.data
                }
            });
        }
        return event;
      }
    ));
  }

}