import { Injectable } from '@angular/core';

import { select, Store,Action } from '@ngrx/store';
import { AccountDetailsPartialState } from './account-details.reducer';
import {accountDetailsQuery } from './account-details.selectors';

@Injectable()
export class AccountDetailsFacade {
  loaded$ = this.store.pipe(select(accountDetailsQuery.getLoaded));
  allAccountDetails$ = this.store.pipe(
    select(accountDetailsQuery.getAllAccountDetails)
  );
  submitAccountDetails$ = this.store.pipe(select(accountDetailsQuery.getAccountDetailsResponseState));

  constructor(private store: Store<AccountDetailsPartialState>) {}

  dispatch(action: Action) {
    this.store.dispatch(action);
  }
  
}
