import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  PPP_LOAN_INFO_FEATURE_KEY,
  AccountDetailsState,
} from './account-details.reducer';

// Lookup the 'AccountDetails' feature state managed by NgRx
const getAccountDetailsState = createFeatureSelector<AccountDetailsState>(
  PPP_LOAN_INFO_FEATURE_KEY
);

const getLoaded = createSelector(
  getAccountDetailsState,
  (state: AccountDetailsState) => state.loaded
);
const getError = createSelector(
  getAccountDetailsState,
  (state: AccountDetailsState) => state.error
);

const getAllAccountDetails = createSelector(
  getAccountDetailsState,
  getLoaded,
  (state: AccountDetailsState, isLoaded) => {
    return (!state.response && isLoaded) ? state : null;
  }
);
const getAccountDetailsResponseState = createSelector(
  getAccountDetailsState,
  getLoaded,
  (state: AccountDetailsState) => {
    return state.response;
  }
);

export const accountDetailsQuery = {
  getLoaded,
  getError,
  getAllAccountDetails,
  getAccountDetailsResponseState
};
