<footer>
  <div class="container fnav">
    <div class="row">
      <div class="col-sm-12 col-md-4 col-lg-4 copyrightlink">
        <ul class="mt-3">
          <li><a href="#" target="_blank">Privacy</a></li>
          <li><a href="#" target="_blank">Terms</a></li>
          <li><a href="#" target="_blank">Sitemap</a></li>
          <li><a href="#">Contact Us</a></li>
        </ul>
      </div>
      <div class="col-sm-12 col-md-4 col-lg-4 text-center ">
      <p class="wt my-4"> © <a class="wt" href="">Copyright Biz2X 2021</a>. All rights reserved.</p>      
      </div>
      <div class="col-sm-12 col-md-4 col-lg-4 text-right mt-3">
        <img alt="Biz2credit" src="/assets/base/images/b2c-logo.svg">
      </div>
    </div>
  </div>
</footer>