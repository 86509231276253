import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState, APP_KEY } from './app.reducers';

// Lookup the 'LendingNeeds' feature state managed by NgRx
const getAppState = createFeatureSelector<AppState>(
    APP_KEY
);

const getUserData = createSelector(
    getAppState,
    (state: AppState) => state && state.userData ? state.userData.user : null
);

const getAccessToken = createSelector(
    getAppState,
    (state: AppState) => state && state.backendUserData ? state.backendUserData.id : null
);

const getNextTask = createSelector(
    getAppState,
    (state: AppState) => state && state.nextTask ? state.nextTask : null
);

const getAllApp = createSelector(
    getAppState,
    getUserData,
    getAccessToken,
    getNextTask
);

export const appStateQuery = {
    getUserData,
    getAccessToken,
    getAllApp,
    getNextTask
};
