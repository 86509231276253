<!-- <footer>
  <div class="container fnav">
    <div class="row">
      <div class="col-sm-12 col-md-4 col-lg-4 copyrightlink">
        <ul class="mt-3">
          <li><a href="#" target="_blank">Privacy</a></li>
          <li><a href="#" target="_blank">Terms</a></li>
          <li><a href="#" target="_blank">Sitemap</a></li>
          <li><a href="#">Contact Us</a></li>
        </ul>
      </div>
      <div class="col-sm-12 col-md-4 col-lg-4 text-center ">
      <p class="wt my-4"> © <a class="wt" href="">Copyright Biz2X 2020</a>. All rights reserved.</p>      
      </div>
      <div class="col-sm-12 col-md-4 col-lg-4 text-right mt-3">
        <img alt="Biz2credit" src="../../assets/base/images/b2c-logo.svg">
      </div>
    </div>
  </div>
</footer> -->

<header class="pr" style="padding-top: 100px">
  <div class="container">
  <div class="text-center w-100 mt-5"><img class="w-100" [src]="imagesFooter[step%3]" alt="" /></div>
  <div class="row">
    <div class="col-12 col-md-10 complayLogo">      
        <div class="row w-100">
          <div class="col-12 col-md-10">
              <div class="">
                <p class="fwb">Provided by:</p>
                <ul>
                    <li><img class="pt-0" src="assets/images/cpalogo.png" style="width: 230px;" alt="" /></li>
                    <li><img class="pt-0" src="assets/images/affiliate-logo.svg" style="width:108px;" alt="" /></li>
                    <li><img class="pt-4" src="assets/images/biz2credit-logo.svg" alt="" /></li> 
                    <!-- <li><img src="assets/images/conpany-logo.svg" alt="" /></li>
                    <li><img src="assets/images/conpany-logo2.svg" alt="" /></li> -->
                </ul>
            </div>
          </div>
          <!--<div class="col-12 col-md-6 text-md-right">
             <p class="fwsb">Platform created by:</p>
            <ul class="compalyLogo">
                <li><img src="assets/images/biz2xlogo.svg" /></li>
            </ul> 
        </div>-->
        </div>
    </div>
  </div>
     
</div>
</header>