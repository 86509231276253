import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'treeconverter'
})
export class TreeConverterPipe implements PipeTransform {
  transform(array: any[], id: string = '_id', parentId: string = 'parent_id'): any[] {
    const map = array.reduce((acc, node) =>
    {
    return ((node.items = []), (acc[node[id]] = node), acc)
     }, {}
    );
    // console.log(Object.keys(map), map);
    const result = Object.keys(map)
      .map(
        (key:any) =>(map[key][parentId] && map[map[key][parentId]].items.push(map[key]), map[key]))
      .filter((value:any) => value[parentId] === null);
    // console.log(result);
    return result;  
  }
}
