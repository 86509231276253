import {Pipe, PipeTransform} from '@angular/core';  
@Pipe ({  
  name : 'sum_value'  
})  
export class LoanAmountPipe implements PipeTransform {  
    transform (input: any[], objectKey?: string): any {
        let total: number;
        if (objectKey) {
            total = input?.reduce((sum, item) => parseInt(sum) + (parseInt(item[objectKey]) || 0), 0);
        } else {
            total = input?.reduce((sum, item) => parseInt(sum) + parseInt(item), 0)
        }
        return total;
    }
} 