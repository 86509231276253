<nav class="drawerNav">
    <div (click)="onMenuClick()" [ngClass]="!showBar?'showBar':''" class="menuIcon">
        <div class="bar1"></div>
        <div class="bar2"></div>
        <div class="bar3"></div>
    </div>
    <div class="drawerCollapse fixed">
        <span *ngIf="showBar" (click)="onMenuClick()" class="navOverlay"></span>
        <ul class="navbar-nav">

            <li *ngFor="let item of navItems" [class]="item.class" (click)="navigate(item.name)"
                [routerLink]="'/'+APP_ROUTES[item.name]" routerLinkActive='active'>
                <a class="nav-link" data-toggle="tooltip" data-placement="right"
                    [title]="'navbar.title.' + item.name | translate">
                    <span class="sr-only">{{'navbar.label.' + item.name | translate}}</span>
                </a>
            </li>
        </ul>
    </div>
</nav>