import { Inject, Injectable, Injector } from '@angular/core';
import { TaskInfoService, CommonService } from '@rubicon/utils';
import { of, throwError } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { WorkflowActionsService } from '../services/workflow-actions.service';
import { EcoaService } from './ecoa.service';
import { ExperianScoreService } from './experian-score.service';
import { BeneficiaryOwnerFeatureService, conditionHelperService } from '../../../../../core-utils/biz2xPro-config-engine';
import { TreeConverterPipe } from '@rubicon/libraries/core-utils/shared-lazy';
import * as _ from 'lodash';
import { OwnerListService } from './owner-list.service';
import { UpdateStatusService } from './update-status.service';
import { TaskTrackerService } from './task-tracker.service';

@Injectable({
  providedIn: 'root'
})
export class GenerateUploadLetterService{
    backendUserData;
    app_id;
    user_id;
    appDataStore;
    caf_ref;
    productConfig;
    constructor(private taskInfoService: TaskInfoService,
        @Inject(Injector) private readonly injector: Injector,
        @Inject('environment') private environment,
        @Inject('CONSTANTS') private CONSTANTS,
        public translate: TranslateService,
        private WorkflowActionsService : WorkflowActionsService,
        private ecoaService: EcoaService,
        private common: CommonService,
        private experianScoreService: ExperianScoreService,
        private boService: BeneficiaryOwnerFeatureService,
        private treeConverterPipe:TreeConverterPipe,
        private ownersListService: OwnerListService,
        private taskTrackerService: TaskTrackerService,
        private store:Store<any>){
        this.store.pipe(select('app')).subscribe(rootState => {
            this.backendUserData = rootState.userData;
            this.app_id = rootState?.appData?.app_id;
            this.user_id = rootState?.appData?.user_id;
            this.appDataStore = rootState?.appData;
            let orchConfig = rootState?.configurations?.[0]?.products;
            if(orchConfig && !this.productConfig){
              this.productConfig = orchConfig.find(res => res?.name === this.environment.products_enabled[0]);
            }
          });
    }

    private get updateStatusService() {
      return this.injector.get(UpdateStatusService);
    }


    uploadLetters(letter_form_data, config, dateTimeZone, appDataStore?, existing_app_data?) {
       let applicationData;
       let doc_letter_ref;
       let upload_letter = config?.action_type == 'upload_letter';
      //  if(appDataStore && (!this.appDataStore || !this.appDataStore?.app_id)){
       if(appDataStore){
        this.appDataStore = appDataStore;
        this.app_id = appDataStore.app_id || appDataStore.appId || appDataStore._id;
        this.user_id = appDataStore.user_id || appDataStore.userId;
       }
       if(this.taskTrackerService.isTaskCompleted(this.app_id, "upload_letter")){
        return of({});
       }
       let appData$ = existing_app_data ? of(existing_app_data) : this.getApplicationData(config);
        return appData$.pipe(
          switchMap((_applicationData)=>{
            applicationData = _applicationData;
            if (applicationData?.business_references?.length) {
              doc_letter_ref = applicationData ?.business_references.findLast(ele => {
                return ((ele ?.type === "document-management") && (ele ?.response ?.type == config ?.letter_type) && (!ele.revoked));
              });
            }
            if(config.use_letter_form_data){
              return of(letter_form_data);
            }
            return this.getLetterData(letter_form_data, applicationData, config, dateTimeZone)
          }),
          map((letter_data)=>{
            const allAssignments = (this.appDataStore?.app_assignment || []).filter((assign) => assign.stage === config?.rm_stage_id && assign.assigned_to);
            const lastAssignedTo = allAssignments[allAssignments.length - 1];
            const business_data = applicationData?.business?.[0];
            const lead_user = applicationData?.lead_user;
            const uniqueBankers = this.getUniqueStageBankers(this.appDataStore?.app_assignment || []);
            const data:any = {
                filename: applicationData.auto_id + "_"+config?.letter_name+(config?.file_type || ''),
                doc_type_key: config?.letter_type,
                backend_user_id: this.backendUserData?.user_id,
                app_id: applicationData.appId || applicationData._id,
                user_id: applicationData.userId || applicationData.user_id,
                ref_id: applicationData.auto_id,
                product_type : applicationData?.product_name || this.environment?.products_enabled?.[0],
                response_to_be_saved: {
                    type: config?.letter_type,
                    filename: applicationData.auto_id + "_"+config?.letter_name,
                    customer_url: this.environment.customerJourneyUrl,
                    copyright_text: this.environment.copyright_text,
                    senders_name: this.environment.client_name,
                    header_logo_path1: this.environment.logo1_path,
                    header_logo_path2: this.environment.logo2_path,
                    privacy: this.environment.privacy,
                    terms: this.environment.terms,
                    banker_phone_number: this.maskPhoneNumber(lastAssignedTo?.phone_no) || this.environment?.client_number || '(XXX) XXX-XXXX'
                },
                mail_data:{
                  customer_name: lead_user?.first_name,
                  customer_email_address: lead_user?.email_address,
                  unique_stage_banker_email_addresses: uniqueBankers.map(tup => tup.email_address).filter((elem, index, self) => {
                    return index === self.indexOf(elem);
                  })?.filter(Boolean),
                  unique_stage_banker_names: uniqueBankers.map(tup => tup.name).filter((elem, index, self) => {
                    return index === self.indexOf(elem);
                  })?.filter(Boolean),
                  business_name: business_data?.business_name,
                  dateshared: dateTimeZone.transform(new Date(), 'MM-dd-yyyy'),
                  loan_id: applicationData.auto_id,
                  banker_siteurl: this.environment.banker_url,
                  customer_url: this.environment.customerJourneyUrl,
                  copyright_text: this.environment.copyright_text,
                  client_name: this.environment.client_name,
                  header_logo_path1: this.environment.logo1_path,
                  header_logo_path2: this.environment.logo2_path,
                  privacy: this.environment.privacy,
                  terms: this.environment.terms,
                  banker_phone_number: this.maskPhoneNumber(lastAssignedTo?.phone_no) || this.environment.client_number || '(XXX) XXX-XXXX'
                }
            };
            if(this.caf_ref?.ref_id){
              // data.caf_ref_id = this.caf_ref?.ref_id;
              data.caf_ref_id = this.app_id;
            }

            if (doc_letter_ref?._id && config?.single_reference) {
                data['letter_main_ref_id'] = doc_letter_ref._id;
            }
            data.letter_data = letter_data;
            return data;
          }),
          switchMap((data)=>{
            let query:any = { slug: 'generate-upload-letter', type: config?.letter_type };
            if(config?.next_slug){
              query.next_slug = config.next_slug;
            }
            if (upload_letter && config?.next_slug) {
              query['slug'] = config?.next_slug;
            }
            let isLetterUploaded = false;
            let saveTask$ = (upload_letter && !config?.next_slug) ? of({}) : this.taskInfoService.saveTaskInfo(query, data);
            return saveTask$.pipe(
              switchMap((res)=>{
                this.taskTrackerService.taskCompleted((applicationData.appId || applicationData._id), "upload_letter");
                if(res?.update_template_letter_reference || res?.save_template_letter_ref || upload_letter){
                  isLetterUploaded = true;
                }
                //checking ecoa action
                if (isLetterUploaded) {
                  return this.ecoaService.checkEcoaActions({...config, refreshAppData: true}, {...applicationData}).pipe(map(()=>res));
                }
                return of(res);
              }),
              switchMap((data)=>{
                if (config?.status_params && isLetterUploaded) {
                  for (let i = 0; i < config?.status_params?.length; i++) {
                    let condition_data = config?.status_params?.[i];
                    let result = (!condition_data?.conditions) ? {resolved:true}: conditionHelperService.resolve({ ...this.appDataStore }, condition_data?.conditions, 'AND', true);
                    if (result.resolved) {
                      let app_sub_statues: any = applicationData?.master_data_optimised?.app_sub_status || this.WorkflowActionsService.app_sub_status;
                      let appData = { appId: this.app_id, userId: this.user_id, ...this.appDataStore }
                      let currentSubStatus = app_sub_statues?.find(res => res.id === condition_data?.params?.next_sub_status_id);
                      if (currentSubStatus) {
                        return this.updateStatusService.updateSubStatus(currentSubStatus, {}, appData, {}, dateTimeZone);
                      }
                    }
                  }
                }
                return of(data);
              }),
              map((res:any) => {
                  if (isLetterUploaded) {
                      return res;
                  }
                  this.common.popToast("error", "", "Failed to generate letter");
                  throw res;
              })
          );
          })
        )
    }

    getApplicationData(config){
      let child_refs = config.child_ref ? config.child_ref.split(","): ["business_references"];
       if(!child_refs.includes("business_references")){
        child_refs.push("business_references");
       }
       let query = {slug: "app_detail_optimized", "child_ref": child_refs.join(","), "app_id": this.app_id, "user_id": this.user_id};
       if(config?.model){
        query['model'] = config.model;
       }
       if(config?.lead_user){
         query["lead_user"] = true;
       }
      return this.taskInfoService.getTaskInfo(query).pipe(
        map((res)=>{
          if(res?.response_data?.app_detail_optimized?.data?.data?.length != 1){
            this.common.popToast("error", "", "Failed to get application data");
            throw res;
          }
          let appData = res?.response_data?.app_detail_optimized?.data?.data?.[0];
          let master_data_optimised = res?.response_data?.master_data_optimised?.data?.data;
          let get_user_detail = res?.response_data?.get_user_detail?.data?.data;
          if(master_data_optimised){
            appData.master_data_optimised = master_data_optimised;
          }
          if(get_user_detail){
            appData.lead_user = get_user_detail;
          }
          if(config.save_caf_ref_id_in_pdf){
            let caf_ref_index =  appData.business_references.map(elem => elem.type).lastIndexOf('offer_management');
            this.caf_ref = appData?.business_references[caf_ref_index];
          }
          return appData;
        }
        ));
    }

    getLetterData(letter_form_data, applicationData, config, dateTimeZone){
        let letter_data = {};
        if(config?.action_type != 'upload_letter'){
          switch(config?.letter_type){
            case "decline_letter": return this.getDeclineLetterData(letter_form_data, applicationData, config, dateTimeZone);
            case "withdrawn_letter": return this.getWithdrawnLetterData(letter_form_data, applicationData, config, dateTimeZone);
            case "conditional_approval": return this.getCommitmentLetter(letter_form_data, applicationData, config, dateTimeZone);
            case 'prequalification_letter': return this.getPrequalificationLetter(letter_form_data, applicationData, config, dateTimeZone);
            case "noi_letter": return this.getNoiLetter(letter_form_data, applicationData, config, dateTimeZone);
            case "beneficiary_letter": return this.getBeneficiaryLetter(letter_form_data, applicationData, config, dateTimeZone);
            case "application_pdf": return this.getApplicationPdfData(letter_form_data, applicationData, config, dateTimeZone);
        }
        }
        return of(letter_data);
    }

    getDeclineLetterData(letter_form_data, applicationData, config, dateTimeZone){
        let mail_temp_common_data =  this.getMailTempCommonData();
        let currentDate = new Date();
        let primary_owner = applicationData?.owners?.find(owner=>owner.is_primary);
        let business_data = applicationData?.business?.[0];
        let decline_letter_date:any = currentDate.setDate(currentDate.getDate() + config?.letter_days);
        let lead_user = applicationData?.lead_user || {};
        let business_address = [business_data?.street_no, business_data?.street_name, business_data?.city, business_data?.state].filter(v=>v).join(", ")+(business_data?.zip_code?(" "+ business_data?.zip_code):"");
        let primary_owner_address = [primary_owner?.streetNo, primary_owner?.streetName, primary_owner?.city, primary_owner?.state].filter(v=>v).join(", ")+(primary_owner?.zip_code?(" "+ primary_owner?.zip_code):"");
        let primary_owner_name = [primary_owner?.first_name, primary_owner?.last_name].map(v=>v).join(" ");
        let letter_data = {
            "logo": mail_temp_common_data.header_logo_path_1,
            "logo2_path": mail_temp_common_data.logo2_path,
            "date": dateTimeZone.transform(decline_letter_date, this.CONSTANTS.DATE_FORMAT.pipe_date_format || 'MM-dd-yyyy'),
            "ref_no": applicationData?.auto_id || "",
            "business_name": business_data?.business_name,
            "primary_owner_name": primary_owner_name || lead_user?.first_name,
            "business_address": business_address,
            "primary_owner_address": primary_owner_address,
            "total_loan_purpose_amount": business_data?.total_loan_purpose_amount,
            "total_loan_purpose_amount_formatted": business_data?.total_loan_purpose_amount?.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 0
            }),
            "reasons": letter_form_data.decline_reason || []
        }
        if(config?.experian_score_pulled){
          return this.experianScoreService.getPrimaryOwnerExperianDetail(applicationData?.owners, applicationData?.owner_references).pipe(
            map((experian_data)=>{
              let experianSummary = experian_data?.summary;
              if(experianSummary && experianSummary.score != null){
                letter_data["experian_score_pulled"] = true;
                letter_data["credit_score"] = experianSummary?.score;
                letter_data["credit_score_pull_date"] = dateTimeZone.transform(experian_data?.created_at, 'MM-dd-yyyy');
                letter_data["credit_score_explanations"] = experianSummary?.explanations || {}
              }
              return letter_data;
            })
          );
        }
        return of(letter_data);
    }

    getWithdrawnLetterData(letter_form_data, applicationData, config, dateTimeZone){
        let mail_temp_common_data =  this.getMailTempCommonData();
        let currentDate = new Date();
        let primary_owner = applicationData?.owners?.find(owner=>owner.is_primary);
        let business_data = applicationData?.business?.[0];
        let withdrawn_letter_date:any = currentDate.setDate(currentDate.getDate() + config?.letter_days);
        let lead_user = applicationData?.lead_user || {};
        let primary_owner_name = [primary_owner?.first_name, primary_owner?.last_name].map(v=>v).join(" ");
        return of({
            "logo": mail_temp_common_data.header_logo_path_1,
            "logo2_path": mail_temp_common_data.logo2_path,
            "date": dateTimeZone.transform(withdrawn_letter_date, this.CONSTANTS.DATE_FORMAT.pipe_date_format || 'MM-dd-yyyy'),
            "ref_no": applicationData?.auto_id || "",
            "business_name": business_data?.business_name,
            "primary_owner_name": primary_owner_name || lead_user?.first_name,
            "business_address": [business_data?.street_no, business_data?.street_name, business_data?.city, business_data?.state].filter(v=>v).join(", ")+(business_data?.zip_code?(" "+ business_data?.zip_code):""),
            "reasons": Array.isArray(letter_form_data.withdraw_reason)?letter_form_data.withdraw_reason:[letter_form_data.withdraw_reason]
        });
    }

    getNoiLetter(letter_form_data, applicationData, config, dateTimeZone){
      let mail_temp_common_data =  this.getMailTempCommonData();
      let primary_owner = applicationData?.owners?.find(owner=>owner.is_primary);
      let business_data = applicationData?.business?.[0];
      let master_data = applicationData?.master_data_optimised || {};
      let lead_user = applicationData?.lead_user || {};
      let config_master = config?.masters || {};
      let primary_owner_name = [primary_owner?.first_name, primary_owner?.last_name].map(v=>v).join(" ");
      let initialStage = this.WorkflowActionsService.getAllStages()?.find(({order}) => order === 1);
      let initialStageBanker = this.appDataStore?.app_assignment?.find(({stage}) => stage === initialStage?.id);
      let initialStageBankerPhoneNo = this.maskPhoneNumber(initialStageBanker?.phone_no) || mail_temp_common_data.client_number || '(XXX) XXX-XXXX';
      let letter_data = {
            "logo": mail_temp_common_data.header_logo_path_1,
            "logoRight": mail_temp_common_data.logo2_path,
            logo2_path: mail_temp_common_data.logo2_path,
            "privacy": mail_temp_common_data.privacy,
            "terms": mail_temp_common_data.terms,
            "copyright_text": mail_temp_common_data.copyright_text,
            "date": dateTimeZone.transform(new Date(), 'yyyy-MM-dd'),
            "date_formatted": dateTimeZone.transform(new Date(), this.CONSTANTS.DATE_FORMAT.pipe_date_forma || 'MM-dd-yyyy'),
            "ref_no": applicationData?.auto_id || "",
            "borrower_name": business_data?.business_name || "",
            "guarantor_name": primary_owner_name || lead_user?.first_name,
            "dear_name": primary_owner_name || lead_user?.first_name,
            "address": [business_data?.street_no, business_data?.street_name, business_data?.city, business_data?.state].filter(v=>v).join(", ")+(business_data?.zip_code?(" "+ business_data?.zip_code):""),
            "reasons": Array.isArray(letter_form_data.reasons)?letter_form_data.reasons:[],
            initialStageBanker,
            initialStageBankerPhoneNo,
            ...master_data,
            ...config_master
      }
      return of(letter_data);
  }

    getCommitmentLetter(letter_form_data, applicationData, config, dateTimeZone){
      let stageInfo=this.WorkflowActionsService.getStages("type",'underwriting');
      let app_assignment = this.appDataStore.app_assignment;
      let current_uw;
      for(let assignment of app_assignment){
        if(assignment?.stage && assignment.stage == stageInfo.id ){
          current_uw=assignment?.name;
        }
      }
      let letter_obj: any = {};
      this.translate.get([config?.letter_type]).subscribe((translations) => {
        letter_obj = translations[config?.letter_type];
      });
        let mail_temp_common_data =  this.getMailTempCommonData();
        let primary_owner = applicationData?.owners?.find(owner=>owner.is_primary);
        let business_data = applicationData?.business?.[0];
        let master_data = applicationData?.master_data_optimised || {};
        let lead_user = applicationData?.lead_user || {};
        let name = `${primary_owner?.first_name} ${primary_owner?.last_name}` || lead_user?.first_name;
        let guarantor_type = master_data?.company_role?.find(res => res?.type === 'guarantor');
        let guarantors_list = applicationData?.owners?.map(res => ((res.company_role == guarantor_type?.id) ? (`${(res?.first_name) ? (res?.first_name+ ' '): ''}${(res?.last_name)|| ''}`) : undefined)).filter(Boolean);
        let letter_data = {
              "banker_name":this.backendUserData.full_name,
              "logo": mail_temp_common_data.header_logo_path_1,
              "loan_id" : this.appDataStore?.loan_id,
              "logoRight": mail_temp_common_data.logo2_path,
              logo2_path: mail_temp_common_data.logo2_path,
              "current_date": dateTimeZone.transform(new Date(),  this.CONSTANTS.DATE_FORMAT.pipe_date_format || 'MM-dd-yyyy'),
              "ref_no": applicationData?.auto_id || "",
              "borrower_name": business_data?.business_name || "",
              "trade_name" : business_data?.trade_name || "",
              "dear_name" : name,
              "proof_of_release_filed_against" : business_data?.business_name || "",
              "pension_award_letter_for" : name,
              "beneficiary_1" : business_data?.business_name || "",
              "beneficiary_2" : name,
              "guarantor_name": name,
              "guarantors_name": (guarantors_list.length > 0) ? guarantors_list.join(", ") : name,
              "term_guarantor_name" : name,
              "by_guarantor_name" : name,
              "owner_of_borrower_name" : business_data?.business_name || "",
              "by_guarantor_name_1" : name,
              "owner_of_borrower_name_1" : business_data?.business_name || "",
              "guarantors_list" : name,
              "current_uw" : current_uw,
              "address_1": [business_data?.street_no, business_data?.street_name].filter(v=>v).join(", "),
              "address_2": [business_data?.city, business_data?.state].filter(v=>v).join(", ")+","+(business_data?.zip_code?(" "+ business_data?.zip_code):""),
              "total_loan_amount": applicationData?.business[0]?.purpose_arr.reduce((sum : any,key: any)=> sum+parseInt(key.amount_field)||0,0),
              placeholders : letter_obj?.placeholder,
              ...letter_obj?.default_data,
              ...master_data
        }
      if (config?.fetch_cam_data) {
        let latest_offer_management_ref = applicationData.business_references.filter(res => res?.type === 'offer_management')?.slice(-1)?.pop();
        return this.taskInfoService.getTaskInfo({ slug: 'credit_approval_form', loan_offer_id: latest_offer_management_ref?.ref_id }).pipe(switchMap(res => {
          let loan_offer_data = res?.response_data?.get_caf_data?.data?.data?.loan_offer_data;
          let cam_data = {
            loan_amount: applicationData?.business[0]?.purpose_arr.reduce((sum: any, key: any) => sum + parseInt(key.amount_field) || 0, 0),
            loan_purpose: loan_offer_data?.["borrower-info"]?.['use_proceeds'],
            collateral_description: loan_offer_data?.cam_collateral?.comment,
            term_repayment: loan_offer_data?.["rate-information"]?.['interest_rate'],
            term_in_months: loan_offer_data?.["rate-information"]?.['term'] * 12,
            total_general_fees : (loan_offer_data?.["cashflow-review"]?.['estimated_fees']) ? loan_offer_data?.["cashflow-review"]['estimated_fees'].reduce((sum: any, key: any) => sum + parseInt(key.amount1) || 0, 0) : ''
          }
          return of({ ...letter_data, ...cam_data });
        }))
      } else {
        return of(letter_data);
      }
    }

    maskPhoneNumber(num) {
      if(num && Number(num) !== NaN && Number(num) !== 0 && !isNaN(Number(num))) {
        num = num.toString();
        const phoneNumber = num.match(/(\d{3})(\d{3})(\d{4})/);
        return num.length === 10 ? `(${phoneNumber[1]}) ${phoneNumber[2]}-${phoneNumber[3]}` : '';
      }
      return '';
  }

  getUniqueStageBankers(app_assignment) {
    const uniqueStageBankers = [];
    app_assignment.filter(asign=>asign.assigned_to).forEach((b) => {
      if(b.stage) {
        const index = uniqueStageBankers.findIndex((un) => un.stage === b.stage);
        if(index  !== -1) {
          uniqueStageBankers[index] = b;
        } else {
          uniqueStageBankers.push(b);
        }
      } else {
        uniqueStageBankers.push(b);
      }
    });
    return uniqueStageBankers;
  }

  getMailTempCommonData() {
    return {
      banker_siteurl: this.environment.banker_url,
      header_logo_path_1: this.environment.logo1_path,
      header_logo_path_2: this.environment.logo1_path,
      logo2_path: this.environment.logo2_path,
      client_name: this.environment.project_name,
      senders_name: this.environment.senders_name,
      copyright_text: this.environment.copyright_text,
      terms: this.environment.terms,
      privacy: this.environment.privacy,
      client_addressl: this.environment?.client_addressl,
      client_city: this.environment?.client_city,
      client_state: this.environment?.client_state,
      client_zipcode: this.environment?.client_zipcode,
      client_country: this.environment?.client_country,
      client_number: this.environment?.client_number
    }
  }

  getPrequalificationLetter(letter_form_data, applicationData, config, dateTimeZone) {
    const mail_temp_common_data = this.getMailTempCommonData();
    const currentDate = new Date();
    let  letter_obj: any;
    this.translate.get([config?.letter_type]).subscribe((translations) => {
      letter_obj = translations[config?.letter_type];
    });
    const primary_owner = applicationData?.owners?.length ? applicationData?.owners.find(owner=>owner.is_primary): null;
    let name = `${primary_owner?.first_name} ${primary_owner?.last_name}`;
    const letter_data = {
      logo: mail_temp_common_data.header_logo_path_1,
      logo2_path: mail_temp_common_data.logo2_path,
      date: dateTimeZone.transform(currentDate, this.CONSTANTS.DATE_FORMAT.pipe_date_forma || 'MM-dd-yyyy'),
      ref_number: applicationData?.auto_id,
      borrower_name: applicationData?.business[0]?.business_name || '',
      guarantor_name: name,
      dear_name: name,
      address: [applicationData?.business[0]?.street_no, applicationData?.business[0]?.street_name,
      applicationData?.business[0]?.city, applicationData?.business[0]?.state].
         filter(v => v).join(', ') + (applicationData?.business[0]?.zip_code ? (' ' + applicationData?.business[0]?.zip_code) : ''),
      amount: applicationData?.business[0]?.purpose_arr.reduce((sum : any,key: any)=> sum+parseInt(key.amount_field)||0,0),
      ...letter_obj?.default_data,
    }
    return of(letter_data);
  }

  getBeneficiaryLetter(letter_form_data, applicationData, config, dateTimeZone){
    let mail_temp_common_data =  this.getMailTempCommonData();
    let primary_owner, beneficiary_owners = [];
    let BOPrimaryOwnerConfig = this.productConfig?.BOPrimaryOwner;
    let BOAdditionalOwnerConfig = this.productConfig?.BOAdditionalOwner;
    let business_data = applicationData?.business?.[0];
    let ownersList = this.ownersListService.getOwnersForTreeStructure(business_data, applicationData?.owners || []);
    let treeOwners = this.treeConverterPipe.transform(ownersList);
    this.ownersListService.updateActualOwnershipPercent(treeOwners);
    ownersList = ownersList.filter(owner=>owner.owner_type == "individual");
    let authorized_individuals;
    ownersList?.forEach((owner)=>{
      let {
        _id, first_name, last_name, streetNo, city, state, zip_code, ssn, dob, dl_number, dl_state_of_issuance, dl_expiry_date, passport_number,  passport_country_of_issuance, passport_expiry_date, title_at_company, ownership
      } = owner;
      if(typeof ssn == "string" && ssn.length == 9){
        ssn = ssn.replace(/(\d{3})(\d{2})(\d{4})/, "$1-$2-$3");
      }
      if(applicationData?.master_data_optimised?.professional_title){
        title_at_company = applicationData?.master_data_optimised?.professional_title?.find(item=>item.id == title_at_company)?.value || title_at_company;
      }
      let enableBO = BOPrimaryOwnerConfig ? this.boService.conditionConfig(owner, BOAdditionalOwnerConfig) : false;
      let boOwner = {
        _id,
        first_name, last_name, streetNo, city, state, zip_code, ssn, dob, dl_number, dl_state_of_issuance, dl_expiry_date, passport_number, passport_country_of_issuance, passport_expiry_date, title_at_company, enableBO, ownership, country: "United States"
      };
      
      if(owner.is_primary){
        primary_owner = boOwner;
      }
      if (enableBO) {
        if (!authorized_individuals) {
          authorized_individuals = [boOwner];
        } else {
          if (+authorized_individuals[0].ownership < +boOwner.ownership) {
            authorized_individuals = [boOwner];
          } else if (+authorized_individuals[0].ownership == +boOwner.ownership) {
            authorized_individuals.push(boOwner);
          }
        }
        beneficiary_owners.push(boOwner);

      }
    });
    let authorized_individual;
    if(authorized_individuals){
      //updating authorized_individual based on first created_at
      authorized_individuals.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
      authorized_individual = authorized_individuals[0];
      if(authorized_individual){
        authorized_individual.is_authorized_individual = true;
      }
    }
    let authorized_individual_bo = beneficiary_owners?.find(bo=>bo._id == authorized_individual._id) ? true:false;
    if(authorized_individual_bo){
      //providing only name and title if authorized individual BO
      let {first_name, last_name, title_at_company} = authorized_individual; 
      authorized_individual = {first_name, last_name, title_at_company};
    }
    let app_biz_tax_id = business_data?.app_biz_tax_id || "";
    if(typeof app_biz_tax_id == "string" && app_biz_tax_id.length == 9){
      app_biz_tax_id = app_biz_tax_id.replace(/(\d{2})(\d{7})/, "$1-$2");
    }

    let letter_data = {
          "logo": mail_temp_common_data.header_logo_path_1,
          "logo2_path": mail_temp_common_data.logo2_path,
          "business_name": business_data?.business_name || "",
          app_biz_tax_id,
          "streetNo": business_data?.street_no || "",
          "city":business_data?.city || "",
          "state":business_data?.state || "",
          "zip_code":business_data?.zip_code || "",
          "primary_owner": primary_owner,
          "no_25_perc_indiv_owner": beneficiary_owners?.length > 0 ?false: true,
          "beneficiary_owners": beneficiary_owners,
          "authorized_individual": authorized_individual
    }
    return of(letter_data);
  }

  getUploadLetterData(applicationData, config) {
    let business_refs = applicationData?.business_references;
    let letter_main_ref = business_refs.findLast(ele => {
      return ((ele?.type === "document-management") && (ele?.response?.type == config?.letter_type) && (!ele?.revoked));
    });
    let data = {
      app_id: this.appDataStore?.app_id,
      user_id: this.appDataStore?.user_id,
      ref_id: this.appDataStore?.loan_id,
      filename: `${this.appDataStore?.loan_id}_${config?.letter_name}`,
      backend_user_id: this.backendUserData?.user_id,
      doc_type_id: config?.doc_type_id,
      doc_type_key: config?.letter_type,
      ref_api: 'save_template_letter_ref',
      upload_api : 'upload_base64_pdf',
      product_type: applicationData?.product_name || this.environment?.products_enabled?.[0],
      action_type : 'upload_letter',
      response_saved: JSON.stringify({
        type: config?.letter_type,
        filename: `${this.appDataStore?.loan_id}_${config?.letter_name}`
      })
    }
    if (letter_main_ref) {
      data['letter_main_ref_id'] = letter_main_ref?._id;
      data['ref_api'] = 'update_template_letter_reference'
    }
    if (this.caf_ref) {
      data['caf_ref_id'] = this.app_id;
    }
    return of(data);
  }

  getApplicationPdfData(letter_form_data, applicationData, config, dateTimeZone) {
    let filteredAppData = this.filterAppData(applicationData, dateTimeZone);
    let mail_temp_common_data = this.getMailTempCommonData();
    let letter_data = {
      "logo1": mail_temp_common_data.header_logo_path_1,
      "logo2": mail_temp_common_data.logo2_path,
      ...filteredAppData
    }
    if (config?.fetch_collateral_data) {
      return this.taskInfoService.getTaskInfo({ slug: 'collateral-details', app_id: this.app_id, business_id : filteredAppData?.business?.id}).pipe(switchMap(res => {
        let total_collaterals = res?.response_data?.get_collateral_data?.data?.data?.total_collaterals || undefined;
        return of({ ...letter_data, total_collaterals });
      }))
    } else {
      return of(letter_data);
    }
  }


  sortOwnersByLevel(a: any, b: any) {
    if (a.level == b.level) return (a.parent_id < b.parent_id) ? -1 : (a.parent_id > b.parent_id) ? 1 : 0;
    else return (a.level < b.level) ? -1 : 1;
  }

  filterAppData(data, dateTimeZone) {
    if (!data) return {};
    const sortedOwners = data?.owners?.sort(this.sortOwnersByLevel);
    const affiliates  = sortedOwners?.map(owner => {
      if (owner?.affiliate_info?.length > 0) {
        return owner?.affiliate_info.map(val => ({ owner_id: owner?._id, ...val, owner_name: owner?.first_name ? `${owner?.first_name} ${owner?.last_name}` : owner?.businessname }));
      }
      return [];
    })?.flat();
    const owners = sortedOwners?.length > 0 ? sortedOwners.map(res => {
      if(typeof res?.ssn == "string" && res?.ssn.length == 9){
        res.ssn = res?.ssn.replace(/(\d{3})(\d{2})(\d{4})/, "$1-$2-$3");
      }
      res.ownership = (res?.ownership == 0 || res?.ownership) ? String(res?.ownership) : undefined;
      return res;
    }) : undefined;
    return data = {
      business: data?.business?.[0],
      owners: owners,
      business_references: data?.business_references || undefined,
      owner_references: data?.owner_references || undefined,
      loan_id: data?.auto_id,
      current_date: dateTimeZone?.transform(new Date(), 'MM-dd-yyyy') || new Date(),
      total_loan_amount: data?.business[0]?.purpose_arr.reduce((sum: any, key: any) => sum + parseFloat(key.amount_field) || 0, 0),
      masters_data: data?.master_data_optimised,
      affiliate_list: affiliates?.length > 0  ? affiliates : undefined
    }
  }
}
