import { Component, OnInit, ViewChild, ElementRef, ViewChildren } from '@angular/core';
import { FormFieldInterface } from '@rubicon/interface/form-field';
import { FormGroup, FormArray } from '@angular/forms';
import { FormGenerateService, CommonService, JQueryService, NumberDirective } from '@rubicon/utils_custom';
import { Store, select } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { FormService } from '../../../services/form.service';

@Component({
  selector: 'rubicon-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss']
})

export class GroupComponent implements OnInit {
  field: FormFieldInterface;
  group: FormGroup;
  slug: string;
  index_field: number;
  length: number;
  // show_fields = 1;
  fields: any = [];
  thisIsMeSelected = false;
  is_corporate_entity = false;
  index: number;
  constructor(
    private formGenerate: FormGenerateService,
    private jqueryService: JQueryService,
    private store: Store<any>,
    private formService: FormService
  ) { }

  ngOnInit(): void {
  }

  /**
   * function to get controls of a form array
   */
  get control(): any {
    return (this.group.get(this.field.name) as FormGroup).controls;
  }

  /**
   * function to add controls to a formarray
   */
  addMore() {
    const formArray = this.group.get(this.field.name) as FormArray;
    let group_fields = JSON.parse(JSON.stringify(this.field.group_fields));
    if(this.slug == "business-details-forgiveness"){
      group_fields = this.field.group_fields.map(field=>{
        let _field = {...field, validations:[]};
        return _field;
      });
    }

    formArray.push(this.formGenerate.createControl(group_fields));
    if (this.slug === 'applicant-details-ppp2') {
      let owenership = 0;
      let business_structure_type= ''
      this.group.get(this.field.name).value.forEach(field => {
        if (field.ownership)
        owenership += field.ownership;
        if (field.business_structure_type)
        business_structure_type = field.business_structure_type;
      });    
      formArray.controls[formArray.length-1].get('ownership')?formArray.controls[formArray.length-1].get('ownership').patchValue(100-owenership):'';
      formArray.controls[formArray.length-1].get('business_structure_type')?formArray.controls[formArray.length-1].get('business_structure_type').patchValue(business_structure_type):'';
    }
  }

  /**
   * function to get formarray
   */
  get fieldArray(): FormArray {
    return this.group.controls[this.field.name] as FormArray;
  }

  /**
   * function to remove controls to a formarray
   * @param index
   */
  showPopup(index, length) {
    this.index_field = index;
    // Saving the length of the form field -- so that if it is equal to 0 , the form can be reset
    this.length = length;
    this.jqueryService.showModal('#' + this.field.name + '-remove');
  }

  removeForm(i, len) {
    if (len === 1) {
      this.group.reset();
    } else {
      if (this.slug === 'applicant-details') {
      const control = (this.group.get('owners') as FormArray).controls[i];
      if (control.get('is_primary').value === true) {
        this.thisIsMeSelected = false;
      }
    }
    if(this.slug === 'term-loan-owners-details'){
      this.formService.groupFormEvent({status: 'delete', index: i});
    }
      this.fieldArray.removeAt(i);
      this.jqueryService.hideModal('#' + this.field.name + '-remove');
    }

  }

  /**
   * function to get total of a field
   */
  get total() {
    let total = 0;
    for (const group of this.control) {
      total += +group.get(this.field.total).value;
    }
    return total.toLocaleString();
  }

  saturationReached() {
   // && !this.field['noSaturation']
   if (this.slug === 'applicant-details-ppp2') {
      let is_corporate_entity = false
      let owernership = 0;
      let count=0;
      let totalRequired=100;
      this.group.get(this.field.name).value.forEach(field => {
        if (field.is_corporate_entity) {
          is_corporate_entity = field.is_corporate_entity  
        }
        if (field.ownership)
          owernership += field.ownership;
        count++;
      });
      if(count===1){
        totalRequired=100;
      }
        if (this.field['noSaturation']) {
          if (is_corporate_entity) {
            this.is_corporate_entity = true
          } else {
            this.is_corporate_entity = false
          }
          return true;
        }else{
      if(is_corporate_entity ){
        this.is_corporate_entity= true
        return true;
      }else{
        this.is_corporate_entity= false
        return owernership < totalRequired;
      } }
    } else {
      return true;
    }
  }

  setOwnerValue(event, index) {
    if (event && event.target.checked) {
      this.store.pipe(
        select('app'),
        take(1),
      ).subscribe(rootState => {
        if (rootState && rootState.userData) {
          const controls = (this.group.get('owners') as FormArray).controls;
          controls[index].patchValue(rootState.userData.user);
          controls[index].get('applicant_phone').patchValue(rootState.userData.user.phone_number);
          controls[index].get('is_primary').patchValue(event.target.checked);
          this.thisIsMeSelected = true;
        }
      });
    }
  }
  isPrimarySelected(index) {
      this.thisIsMeSelected=this.thisIsMeSelected || (this.group.get('owners')as FormArray).controls[index].get('is_primary').value;
      return this.thisIsMeSelected;
  }

  closeModal(){
    this.jqueryService.hideModal('#' + this.field.name + '-remove');
  }
}

