import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, TemplateRef, ViewChild, Renderer2, ChangeDetectorRef, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, FormControl } from '@angular/forms';
import { FormFieldInterface } from '../../../../interface';
import { select, Store } from '@ngrx/store';
import { DOCUMENT } from '@angular/common';
import { addAppDetails, CommonService, FormGenerateService, MasterDataPipe, TaskInfoService } from '@rubicon/utils';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { distinctUntilChanged, startWith, take, takeUntil} from 'rxjs/operators';
import { OwnerListService } from '../services/owner-list.service';
import { combineLatest, merge, ReplaySubject } from 'rxjs';
import {isEqual} from 'lodash';
import { BeneficiaryOwnerFeatureService } from '../../../../../core-utils/biz2xPro-config-engine';
import { AgGridAngular } from 'ag-grid-angular';
import { GridApi, GridOptions, ICellRendererParams } from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';
import { ActionCellRendererComponent } from './ag-grid-renderer/action-cell-renderer/action-cell-renderer.component';
import { AnchorCellRendererComponent } from './ag-grid-renderer/anchor-cell-renderer/anchor-cell-renderer.component';
import { CheckboxCellRenderer } from './ag-grid-renderer/checkbox-cell-renderer';
import { KnockoutResolveService } from '../knockout-actions/services/knockout-resolve.service';

@Component({
  selector: 'owners-list',
  templateUrl: './owners-list.component.html',
  styleUrls: ['./owners-list.component.scss'],
})
export class OwnersListComponent implements OnInit, OnChanges {
  @Input() config: any;
  @Output() updated_owner_obj= new EventEmitter<any>(); 
  @Output() additional_owner_opened = new EventEmitter<any>();
  @Output() refreshPageEvent = new EventEmitter<any>();
  businessData: any;
  ownerData: any;
  state: any;
  company_role: any;
  business_structure_data : any;
  company_status : any;
  owner_type : any;
  app_data : any;
  blocked_application = false;
  form_slug: string;
  ownerFormFields: FormFieldInterface[] = [];
  addChildOwnersFormConfig: FormFieldInterface[] = [];
  addChildOwnersMasterData: any;
  addChildOwnersForm: FormGroup;
  borrower_type_value: string;
  edit_owner_index:number;
  company_role_obj = {
    'guarantor': '63e9e389ebaba70a71d230f1',
    'key_employee': '5c91e94d3a2ba0289ce71a44',
    'co_borrower' : '63e9e42eebaba70a71d230f2',
    'trust': '63e9e4b7ebaba70a71d230f5',
    '401k': '5c91e94d3a2ba0198ce86a39'
  };
  company_type = {
    oc : '602f71232410192bf46bae90',
    epc: '602f71232410192bf46bae91'
  };
  business_structure_obj = {
    franchise: '5c1ca192eb72d4c894b91612'
  }
  editOwnerData: any = {};
  modalRef: BsModalRef;
  parent_id : string;
  @ViewChild('template') template: TemplateRef<any>;
  businessOwnershipModalRef: BsModalRef;
  businessOwnershipConfig : FormFieldInterface[] = [];
  businessOwnershipForm: FormGroup;
  businessOwnershipSlug : string;
  selectedOwner : any; 
  gender : any;
  race : any;
  veteran : any;
  userData : any;
  ethnicity: any;
  marital : any;
  applicant_business_name : any;
  business_industry : any;
  sub_industry : any;
  yes_no: any;
  applicant_type: any;
  annual_revenue : any;
  residence_type : any;
  @ViewChild('businessOwnershipTemplate') businessOwnershipTemplate: TemplateRef<any>;
  treeStructureRef : BsModalRef;
  @ViewChild('treeStructureTemplate') treeStructureTemplate: TemplateRef<any>;
  treeConfig : any;
  rolesCannotAccess = ['admin', 'neb_credit_manager_l1', 'neb_credit_manager_l2','acap_closer_packager_l1', 'acap_closer_packager_l2', 'neb_closing_manager_l1', 'neb_closing_manager_l2'];
  /* Journey names*/
  assistantJourney = 'assistant'; 
  bankerJourney = 'banker';
  customerJourney = 'customer';
  /* Routes */
  editOwnerRoute  = 'edit-owner';
  ownerDetailsRoute = 'owner-details';
  additional_owner_form: FormGroup;
  application_status_id: any;
  compDestroyed$ = new ReplaySubject(1);
  owner_extra_ques_subs;
  interestRateSubscription;
  additionalOwnerOpen = false;
  showOwnerhipTooltip = false;
  alertConfEle: any;
  errorMessage: any;
  question: any;
  company_role_new;
  orchConfig : any;
  product_type: any;
  @ViewChild("alertConfirmation") alertConfirmation: ElementRef<any>;
  is_authorized_banker = false;
  @ViewChild('agGrid') agGrid!: AgGridAngular;
  @ViewChild('agGridagGridLvl2') agGridLvl2!: AgGridAngular;
  columnDefs: any; //ColDef[];
  lvl2ColDefs: any;
  public domLayout: 'normal' | 'autoHeight' | 'print' = 'autoHeight';
  gridApi: GridApi;
  noRowsTemplate: string = 'No records found.';
  gridOptions: GridOptions = {
    rowHeight: 75,
    headerHeight:40,
    enableCellTextSelection: true,
    suppressRowClickSelection: true,
    suppressRowHoverHighlight: false,
    domLayout: 'autoHeight',
    defaultColDef: {
      sortable: true,
      suppressMovable: true,
      autoHeight:true
    },
    onColumnResized(event) {
      if (event.finished) {
        event.api.resetRowHeights()
      }
    },
    onGridReady(params) {
      setTimeout(() => {
        params.api.resetRowHeights();
        // params.api.sizeColumnsToFit();
      }, 0)
    }
  };
  
  constructor(
    public taskinfoService: TaskInfoService,
    public common: CommonService,
    public formGenerateService: FormGenerateService,
    public modalService: BsModalService,
    public masterDataPipe: MasterDataPipe,
    public ownerListService: OwnerListService,
    public store: Store<any>,
    public boService : BeneficiaryOwnerFeatureService,
    public renderer: Renderer2,
    public fb: FormBuilder,
    @Inject('CONSTANTS') public CONSTANTS,
    @Inject(DOCUMENT) public document: Document,
    @Inject('environment') public environment,
    @Inject('productConfig') public productConfig,
    public cdRef: ChangeDetectorRef,
    public translate: TranslateService,
    private knockoutResolveService:  KnockoutResolveService
  ) { 
      this.additional_owner_form = this.fb.group({
        send_consent_request: new FormArray([]),
      });  
  }

  ngOnInit(): void {
    this.store.pipe(select('app'), take(1)).subscribe(rootState => {
      this.product_type = "SBA-7A"
      this.application_status_id = rootState?.appData?.status_id;
      this.blocked_application = rootState?.appData?.blocked_application;
      this.userData = rootState?.userData;
      let orchConfig = rootState?.configurations?.[0]?.products;
      if(orchConfig){
        this.orchConfig = orchConfig.find(res => res?.name === this.environment.products_enabled[0]);
      }
    });
    this.store.pipe(select('app'),takeUntil(this.compDestroyed$)).subscribe(rootState=>{
      let banker_user_id = rootState?.userData?.user_id;
      this.is_authorized_banker = false;
      if(rootState?.appData?.app_assignment?.length > 0){
        for(let assign of rootState?.appData?.app_assignment){
          if(assign.id == banker_user_id){
            this.is_authorized_banker = true;
          }
        }
      }
    });
    this.setColDef();
    this.setColDefLvl2();
  }

  setColDefLvl2() {
    this.lvl2ColDefs = [
      {
        sortable: false,
        headerName: 'Select for Consent',//this.translate.instant('owner-list.html.lvl2Header1'),
        width: 150,
        cellRendererFramework: CheckboxCellRenderer,
        cellRendererParams: (params: ICellRendererParams) => {
          return {onClick: this.onCheckboxChange.bind(this, params.rowIndex),
          config: this.config,
          is_authorized_banker: this.is_authorized_banker,
          app_data: this.app_data}
        }
      }, {
        field: 'first_name',
        headerName: 'Name',//this.translate.instant('owner-list.html.tableHeader2'),
        width: 140,
        cellRenderer: (params) => {
          let owner = params.data;
          return owner.first_name ? owner.first_name + ' ' +  (owner.middle_name?(owner.middle_name+ ' '):'') + owner.last_name : owner?.businessname;
        }
      }, {
        field: 'ownership',
        headerName: 'Ownership',//this.translate.instant('owner-list.html.tableHeader3'),
        width: 100,
        cellRenderer: (params) => {
          let owner = params.data;
          if(owner?.ownership!=undefined || owner?.ownership!=null)
            return owner.ownership + '%'
        }
      }, {
        field: 'level',
        headerName: 'Level',//this.translate.instant('owner-list.html.tableHeader4'),
        width: 80,
        cellRenderer: (params) => {
          let owner = params.data;
          if(owner?.ownership!=undefined || owner?.ownership!=null)
            return 'Level '+ owner.level;
        }
      }, {
        field: 'company_role',
        headerName: 'Role',//this.translate.instant('owner-list.html.tableHeader5'),
        width: 90,
        cellRenderer: (params) => {
          let owner = params.data;
          if(owner.is_primary)
            return this.masterDataPipe.transform( owner.company_role, this.company_role, 'id', 'value')
            if(owner.is_secondary)
            return this.masterDataPipe.transform(owner.owner_type, this.owner_type, 'type', 'value')
        }
      }, {
        sortable: false,
        headerName: 'Address',//this.translate.instant('owner-list.html.tableHeader6'),
        width: 180,
        wrapText: true,
        autoHeight: true,
        cellRenderer: (params) => {
          let owner = params.data;
          return this.getAddress(owner);
        }
      }, {
        sortable: false,
        headerName: '',
        width: 80,
        cellRendererFramework: ActionCellRendererComponent,
        cellRendererParams: {
          onClick: this.actionClick.bind(this),
          config: this.config,
          is_authorized_banker: this.is_authorized_banker,
          app_data: this.app_data,
          consentAction: true
        }
      }]
  }

  ngOnChanges(){
    if(this.config){
      const {businessData, ownerData, state, company_role, business_structure_data, 
        company_status, owner_type, app_data, gender, race, veteran, ethnicity, marital, 
        applicant_business_name,business_industry, annual_revenue, residence_type, yes_no, applicant_type, professional_title, business_responsibility, citizenship } = this.config;
      this.company_role_new = company_role;
      Object.assign(this,{businessData, ownerData, state, company_role, business_structure_data, 
        company_status, owner_type, app_data,gender, race, veteran, ethnicity, marital,
        applicant_business_name, business_industry, annual_revenue, residence_type, yes_no, applicant_type, professional_title, business_responsibility, citizenship })  
      if(this.ownerData?.length > 0 && this.config?.componentType !== 'owner-verification'){
        this.ownerData.sort(this.ownerListService.sortByLevel);
      }
      if(business_industry){
        this.sub_industry = business_industry.find(ind => ind.id === businessData[0].industry)?.sub_industry;
      }
      if(this.app_data) this.parent_id = this.app_data?.parent_id;
      this.ownerListService.setLevelAndCount(this.ownerData);
      this.checkOwnership();
      this.setConsentCheck();
    }
  }

  refreshPage(){
    this.refreshPageEvent.emit();
  }

  setConsentCheck(){
    const formArray: FormArray = this.additional_owner_form.get('send_consent_request') as FormArray;
    if(this.ownerData?.length > 0 && formArray.controls.length == 0){
      for(let i=0;i<this.ownerData.length;i++){
        if(this.ownerData[i].send_consent_request){
          formArray.push(new FormControl(i+""));
        }
      }
    }
  }

  onCheckboxChange(index) {
    // const formArray: FormArray = this.additional_owner_form.get('send_consent_request') as FormArray;

    // /* Selected */
    // if(event.target.checked){
    //   // Add a new control in the arrayForm
    //   formArray.push(new FormControl(event.target.value));
    // }
    // /* unselected */
    // else{
    //   // find the unselected element
    //   let i: number = 0;
  
    //   formArray.controls.forEach((ctrl: FormControl) => {
    //     if(ctrl.value == event.target.value) {
    //       // Remove the unselected element from the arrayForm
    //       formArray.removeAt(i);
    //       return;
    //     }
  
    //     i++;
    //   });
    // }
    // this.ownerData.forEach((val, ind) => {
    //   if(formArray.value.indexOf(ind?.toString())>=0){
    //     this.ownerData[ind].send_consent_request=true;
    //   } else{
    //     this.ownerData[ind].send_consent_request=false;
    //   }
    // });
    this.ownerData[index]['send_consent_request'] = !this.ownerData[index]['send_consent_request'];
    this.updateOwnerObj({obj: this.ownerData, action: 'update_owner'});
  }
  showActions() {
    // if(this.config?.journey === 'banker'){
    //   let statusIds = [this.CONSTANTS.APPLICATION_STATUS.app_completed, this.CONSTANTS.APPLICATION_STATUS.app_hard_withdrawn, this.CONSTANTS?.APPLICATION_STATUS?.application_hard_decline];
    //   return this.app_data?.complete_application_status && (this.app_data?.status_id && !statusIds.includes(this.app_data?.status_id) ? true : false);
    // } else {
    //   return true;
    // }
    return this.blocked_application ? false : true;
  }
  addOwner(borrower_type: string, parentOwner? : any, levelZeroOwner?: any) {
    if(borrower_type) {
      this.borrower_type_value = borrower_type;
    }
    if(parentOwner){
      /* add owner from business owners */
      this.parent_id = parentOwner?._id;
    } 
    else if(levelZeroOwner) {
       /* add owner from company details tab */
      this.parent_id = "0";
    }
    this.openModal2();
  }

  openModal2(owner? : any): void {
     if (owner?.is_primary) {
      this.common.navigate('owner-details');
      return;
    }
    if(this.app_data.status_id === this.CONSTANTS.APPLICATION_STATUS.application_in_progress && this.config.journey === this.bankerJourney){
      this.common.popToast('error', 'Error', 'Application is incomplete. Please complete your application');
      return;
    }
    if(!this.ownerData.some((data: any) => data.is_primary) && this.config?.componentType!=='owner-verification'){
      this.common.popToast('error', 'Error', 'Please add primary owner.');
      return;
    }
    let params = {
      slug: this.CONSTANTS.SLUG[this.config?.add_child_owners_slug],
      app_id: this.app_data?.app_id,
      user_id: this.app_data?.user_id,
      owner_visible: true,
      company_role_visible: true
    }
    if ( !(this.addChildOwnersFormConfig?.length && this.addChildOwnersForm)) { 
      this.taskinfoService.getTaskInfo(params).subscribe(response => {
        if (response) {
          this.ownerFormFields = JSON.parse(JSON.stringify(response?.form_fields));
          if(response ?.form_fields?.length && this.parent_id!='0'){
            response.form_fields[0].group_fields = response.form_fields[0].group_fields.filter(ele=>!(ele.name ==="is_esop_or_coop" || ele.name ==="entity_type"))
          }
          this.addChildOwnersFormConfig = response?.form_fields;
          this.form_slug = response?.task_slug;
          let respData = response ?.response_data;
          respData['business_structure'] = respData.business_structure_sba
          this.addChildOwnersMasterData = respData;
          this.setOwnerData(owner);
        }
      });
    } else {
      this.addChildOwnersFormConfig = JSON.parse(JSON.stringify(this.ownerFormFields));
      if(this.addChildOwnersFormConfig?.length && this.parent_id!='0'){
        this.addChildOwnersFormConfig[0].group_fields = this.ownerFormFields[0].group_fields.filter(ele=>!(ele.name ==="is_esop_or_coop" || ele.name ==="entity_type"))
      }
      this.setOwnerData(owner);
    }
    this.openAdditOwner();
    // this.modalRef = this.modalService.show(this.template, { class: 'modal-lg thankyouRegister busineInfoModal', backdrop: 'static' });
  }

  showConfirmationPop(formControlval, element) {
    let template: any = this.alertConfirmation;
    this.alertConfEle = formControlval;
    this.modalRef = this.modalService.show(template, {
      id: 1,
      class: 'modal-lg thankyouRegister',
      backdrop: 'static',
    });

    this.question = element;
    switch(this.question){
      case 'app_biz_legal_name':
        this.errorMessage = "We regret to inform you that your response indicates your business does not meet our program eligibility requirements.  Please review your responses and revise any that were made in error."
        break;
      // case 'is_compony_for_profit':
      //   this.errorMessage = "To qualify for a SBA Loan, the borrowing entity must be a For Profit Business. Since you have indicated this is not a For Profit business, it appears your business does not meet SBA eligibility requirements.  If this was answered in error, please revise your selection to continue."
      //   break;
    }
  }

  onalertConfirmation(option) {
    if (option === 'yes') {
      this.alertConfEle.setValue('');
    }
    this.modalRef.hide();
  }

  setOwnerData(owner?: any) {
    this.addChildOwnersForm = this.formGenerateService.createControl(this.addChildOwnersFormConfig);
    let company_role = this.company_role;
    // if(owner?.company_role && company_role) {
    //   let selected_company_role = company_role.find(ele=>ele?.id===owner?.company_role);
    //   company_role = selected_company_role?.for_new_application ? company_role.filter(ele=>ele?.for_new_application && (this.config?.level>1?ele?.visible:true)) : company_role.filter(ele=>ele?.for_old_application);
    // } else {
    //   company_role = company_role?.filter(ele=>ele?.for_new_application && (this.config?.level>1?ele?.visible:true));
    // }
    this.addChildOwnersMasterData.company_role.data.data = company_role;
    
    if(this.checkSignerForBusiness(owner) && !owner?.authorized_signatory){
      this.addChildOwnersForm.controls['owners'].get('authorized_signatory')?.disable();
    }
    this.common.sendMasterDataToFields(this.addChildOwnersFormConfig, this.addChildOwnersMasterData);
    this.addChildOwnersForm?.get("owners.business_structure")?.valueChanges.pipe(distinctUntilChanged(),takeUntil(this.compDestroyed$)).subscribe(val=> {
      this.common.sendMasterDataToFields(this.addChildOwnersFormConfig, this.addChildOwnersMasterData);
    })

    this.addChildOwnersForm.get('owners').get('ownership')?.valueChanges.subscribe(val => {
      if(this.orchConfig?.customer.applicationSteps.ownerDetails?.formConfig && this.orchConfig.customer.applicationSteps.ownerDetails?.formConfig?.role_at_business?.filters){
        let answer = this.knockoutResolveService?.isConsentRequired({ "ownership": val }, this.orchConfig.customer.applicationSteps.ownerDetails?.formConfig?.role_at_business?.filters);
        if(answer.resolved) {
          let filteredRole = this.company_role.find(item => item.type == this.orchConfig.customer.applicationSteps.ownerDetails?.formConfig?.role_at_business?.type);
          if(!!filteredRole) {
            this.addChildOwnersForm.get('owners').get('company_role').patchValue(filteredRole.id);
          }
        }
      }
    })

    // let formControlval3 = this.addChildOwnersForm.get('owners.app_biz_legal_name')
    // formControlval3.valueChanges.pipe(distinctUntilChanged(),takeUntil(this.compDestroyed$)).subscribe(val => {
    //   val === "neither" ? this.showConfirmationPop(formControlval3, "app_biz_legal_name") : ""
    // })borrower_type_value
    // let formControlval1 = this.addChildOwnersForm.get('owners.is_compony_for_profit')
    // formControlval1.valueChanges.pipe(distinctUntilChanged(),takeUntil(this.compDestroyed$)).subscribe(val => {
    //   val === "No" ? this.showConfirmationPop(formControlval1, "is_compony_for_profit") : ""
    // })
    //Epc OC condition.
    this.addChildOwnersForm.controls['owners'].get('owner_type').valueChanges.pipe(distinctUntilChanged()).subscribe(res=>{
      if(res) this.common.sendMasterDataToFields(this.addChildOwnersFormConfig, this.addChildOwnersMasterData);
      let parentId = res === 'corporate' ? this.parent_id ? this.parent_id :  this.app_data?.parent_id ? (this.config?.owner_company_role === this.company_role_obj.co_borrower) ? '0' : this.app_data?.parent_id: null : null;
      this.addChildOwnersForm.controls['owners'].patchValue({parentId: parentId}); 
      if(res === 'corporate' && (owner ? owner.company_role : this.borrower_type_value) !== this.company_role_obj.co_borrower) {
        // this.addChildOwnersForm.get('owners.sba.app_biz_legal_name')?.patchValue(this.company_type.oc);
        this.addChildOwnersForm.get('owners.app_biz_legal_name')?.patchValue("oc");
        this.addChildOwnersForm.get('owners.app_biz_legal_name')?.disable();
      }
      if(res==='corporate' && (owner ? owner.company_role : this.borrower_type_value) === this.company_role_obj.co_borrower){
        this.addChildOwnersForm.get('owners.ownership').patchValue(0);
        this.renderer.addClass(this.document.body, 'hideOwnershipField');
      } else{
        this.renderer.removeClass(this.document.body, 'hideOwnershipField');
      }
      if(res==='corporate' && this.addChildOwnersForm.controls['owners'].get('is_esop_or_coop')){
        this.addChildOwnersForm.controls['owners'].get('is_esop_or_coop').valueChanges.pipe(distinctUntilChanged()).subscribe(data=> {
          this.common.sendMasterDataToFields(this.addChildOwnersFormConfig, this.addChildOwnersMasterData);    
        });
      }
    });
    this.interestRateSubscription?.unsubscribe();
    this.owner_extra_ques_subs?.unsubscribe();
    //acap conditions
    // this.owner_extra_ques_subs = combineLatest(this.addChildOwnersForm.get('owners.ownership').valueChanges.pipe(
    //   startWith(this.addChildOwnersForm.get('owners.ownership').value)),
    //   this.addChildOwnersForm.get('owners.owner_type').valueChanges.pipe(
    //     startWith(this.addChildOwnersForm.get('owners.owner_type').value)
    // )).pipe(
    //   takeUntil(this.compDestroyed$),
    //   distinctUntilChanged((prev:any, curr:any) => prev[0] == curr[0] && prev[1] == curr[1])
    // ).subscribe(([ownership, owner_type])=>{
    //   this.common.sendMasterDataToFields(this.addChildOwnersFormConfig, this.addChildOwnersMasterData);
    //   this.addChildOwnersForm.get('owners.show_extra_fields').setValue((+ownership) >= 20 && owner_type == "individual");
    // });
    this.interestRateSubscription = this.addChildOwnersForm.get('owners').get('date_of_incorporation').valueChanges.subscribe((val) => {
      if(val){
        val = val < new Date(1900,0,1) ? new Date(1900,0,1) : val;
        this.addChildOwnersForm.get('owners').get('date_of_incorporation').setValue(val, { onlySelf: true, emitEvent: false })
      }
    });

    this.addChildOwnersForm.get('owners').valueChanges.pipe(
      distinctUntilChanged(isEqual),
      takeUntil(this.compDestroyed$))
      .subscribe(val=> {
      let enableBO = this.boService.conditionConfig(val, this.orchConfig?.BOAdditionalOwner);
      this.addChildOwnersForm.get('owners')?.get('show_BO_field')?.patchValue(enableBO, {emitEvent: false});
    })

    if(owner) {
      owner.show_extra_fields = (+owner.ownership) >= 20 && owner.owner_type == "individual";
      this.editOwnerData = owner;
      setTimeout(() =>{
        this.formGenerateService.setFormValues(this.addChildOwnersForm, { owners: owner });
        if(owner.company_role === this.company_role_obj.guarantor){
          this.addChildOwnersForm.controls['owners'].patchValue({show_limited_guaranter: owner.company_role});
        }
        if(owner.company_role===this.company_role_obj.key_employee) {
          this.addChildOwnersForm.controls['owners'].patchValue({owner_type: 'individual'});
          this.addChildOwnersForm.controls['owners'].get('owner_type').disable();
        }
      }, 0);
      
      this.addChildOwnersForm.controls['owners'].get('company_role').valueChanges.pipe(distinctUntilChanged(),takeUntil(this.compDestroyed$)).subscribe(company_role=>{
        if(company_role === this.company_role_obj.guarantor){
          this.addChildOwnersForm.controls['owners'].patchValue({show_limited_guaranter: this.company_role_obj.guarantor});
        }
      });
      let stateValue = this.addChildOwnersForm.get("owners.state").value;
      if(stateValue){
        this.taskinfoService.getTaskInfo({slug:"city",state: stateValue}).subscribe((res)=>{
          let cities = res?.response_data?.city?.data?.data[0].cities;
          this.common.sendMasterDataToFields(null, {
            data:cities||[],
            index: 0,
            parent: this.config?.add_child_owners_slug
          }, 'city');
        })
      }
    
    } else {
      this.editOwnerData = null;
      this.edit_owner_index=-1;
      if(this.borrower_type_value === this.company_role_obj.guarantor){
        this.addChildOwnersForm.controls['owners'].patchValue({show_limited_guaranter: this.company_role_obj.guarantor});
      }
      this.addChildOwnersForm.controls['owners'].get('owner_type').valueChanges.pipe(distinctUntilChanged(),takeUntil(this.compDestroyed$)).subscribe(res=>{
        if(this.borrower_type_value) {
          this.addChildOwnersForm.controls['owners'].patchValue({company_role: this.borrower_type_value});
        }
      });
      this.addChildOwnersForm.controls['owners'].get('company_role').valueChanges.pipe(distinctUntilChanged(),takeUntil(this.compDestroyed$)).subscribe(company_role=>{
        if(company_role === this.company_role_obj.guarantor){
          this.addChildOwnersForm.controls['owners'].patchValue({show_limited_guaranter: this.company_role_obj.guarantor});
        }
      });
      if(this.borrower_type_value===this.company_role_obj.key_employee) {
        this.addChildOwnersForm.controls['owners'].patchValue({owner_type: 'individual'});
        this.addChildOwnersForm.controls['owners'].get('owner_type').disable();
      }
    }
  }

  onCloseAdditOwner(){
    this.additionalOwnerOpen = false;
    this.checkOwnership();
    this.additional_owner_opened.emit(this.additionalOwnerOpen);
  }
  
  openAdditOwner(){
    this.additionalOwnerOpen = true;
    this.additional_owner_opened.emit(this.additionalOwnerOpen);
  }

  submitOwner(action: string): void {
    if (this.formGenerateService.validateCustomFormFields(this.addChildOwnersForm, action, this.addChildOwnersFormConfig)) {
      let payload = {
        app_id: this.app_data?.app_id,
        user_id: this.app_data?.user_id,
        action_type: action,
        is_secondary: true,
        ...this.addChildOwnersForm?.getRawValue()?.owners,
        ownership : Number(this.addChildOwnersForm?.getRawValue()?.owners?.ownership)
      }
      if (this.editOwnerData ?._id) {
        payload.owner_id = this.editOwnerData._id;
      }
      else{
        //on add owner
        payload['parent_id'] = this.parent_id ? this.parent_id :  this.app_data?.parent_id;
        payload['level'] = (this.borrower_type_value===this.company_role_obj.co_borrower) ? "0" : this.config?.level;
      }
      if (payload?.company_role!==this.company_role_obj?.guarantor){
        delete payload?.show_limited_guaranter;
        delete payload?.is_limited_guaranter;
      } else {
        delete payload?.show_limited_guaranter;
      }
      if(payload.ordinalCount) delete payload.ordinalCount;
      if(payload?.parentId) delete payload.parentId;
      if(payload.authorized_signatory && this.checkSignerForBusiness(this.editOwnerData ? this.editOwnerData : payload)){
        this.common.popToast('error', 'Error', 'You have already marked a signer for the Business.');
        return;
      }
      if(payload?.app_biz_legal_name === "epc" && this.checkEpcOc('epc', this.editOwnerData ? this.editOwnerData : payload)){
        this.common.popToast('info', 'Info', 'EPC already exist.');
        return;
      }
      if(payload.owner_type === "corporate" && payload.company_role===this.company_role_obj.co_borrower) {
        delete payload.ownership;
      }

      if(payload?.owner_type === 'corporate'){
       payload.authorized_signatory = '';
       }
      const param = { slug: this.CONSTANTS.SLUG[this.config?.add_child_owners_slug],
        hash_for: 'owner_consent', 
       mail_type: '63dd41e5824df17495525734',
       senders_name: this.CONSTANTS.MAIL_TEMPLATE.senders_name,
       frontend_url:`${this.environment.customerJourneyUrl}`,
       verification_url: `owner-verification`,
       role: this.masterDataPipe.transform(payload.company_role , this.company_role_new,'id','value'),
       cpa_firm_name : this.userData.user.cpa_firm_name,
       business_name : this.userData.user.business_name,
       consent: payload?.authorized_signatory ? 'sent' : 'pending',
       product_type: this.product_type
      };   
             
      if(this.config?.componentType!=='owner-verification') {
        this.taskinfoService.saveTaskInfo(param, payload).subscribe(res => {
          if (res ?.update_owner_consent_sba_cpa_another_owner_detail ?.data ?.data ?._id) {
            this.updateOwnerData(res.update_owner_consent_sba_cpa_another_owner_detail.data.data); 
          } else if (res ?.update_owner_consent_sba_cpa ?.data ?.data ?._id) {
            this.updateOwnerData(res.update_owner_consent_sba_cpa.data.data); 
          } else if(res ?.update_owner_sba_cpa ?.data ?.data ?._id) {
            this.updateOwnerData(res.update_owner_sba_cpa.data.data); 
          }

          if( (res?.send_owner_consent_sba_cpa
            && res?.send_owner_consent_sba_cpa?.data
            && res?.send_owner_consent_sba_cpa?.data?.log_id) || (res?.send_owner_consent_sba_cpa_another_owner_detail
              && res?.send_owner_consent_sba_cpa_another_owner_detail?.data
              && res?.send_owner_consent_sba_cpa_another_owner_detail?.data?.log_id)) {
              this.common.popToast('success', '', 'Consent mail sent.');
            }
        });
      } else {
        payload['action_type']='save';
        this.updateOwnerData(payload);
      }
    }
  }

  updateOwnerData(owner_data: any): void{
    if (this.edit_owner_index < 0) {
      this.ownerData.push(owner_data);
      this.common.popToast('success', '', 'Owner added successfully.');
    } else {
      this.ownerData[this.edit_owner_index] = owner_data;
      this.common.popToast('success', '', 'Owner updated successfully.');
    }
    this.editOwnerData = {};
    this.edit_owner_index = -1;
    this.ownerData.sort(this.ownerListService.sortByLevel);
    this.ownerListService.setLevelAndCount(this.ownerData);
    this.updateOwnerObj({obj: this.ownerData, action: 'update_owner'});
    this.onCloseAdditOwner();
  }

  deleteOwner(owner: any, index:number): void {
    if (owner ?._id && index >=0) {
      this.taskinfoService.saveTaskInfo({ slug: this.CONSTANTS.SLUG['delete-owner'] }, { owner_id: owner._id }).subscribe(response => {
        if (response ?.delete_owner ?.data ?.data ?._id) {
          this.spliceOwnerData(index);
        }
      })
    } else if(!owner ?._id && index >=0){
      this.spliceOwnerData(index);
    }
  }

  spliceOwnerData(index): void{
    const formArray: FormArray = this.additional_owner_form.get('send_consent_request') as FormArray;
    if(formArray){
      let deleteIndex = -1;
      formArray.controls.forEach((ctrl: FormControl, i) => {
        if (ctrl.value == index) {
          deleteIndex = i;
          return;
        }
        if((+ctrl.value) > index){
          ctrl.setValue((+ctrl.value)-1);
        }
      });
      if(deleteIndex != -1){
        formArray.removeAt(deleteIndex);
      }
    }
    this.ownerData.splice(index, 1);
    this.updateOwnerObj({obj: this.ownerData, action: 'delete_owner'});  
    this.ownerListService.setLevelAndCount(this.ownerData);
    this.ownerData.sort(this.ownerListService.sortByLevel);
    this.common.popToast('success', '', 'Owner deleted successfully.');
  }

  sendPFS(obj): void {
    const payload = {
      app_id: obj.app_id,
      user_id: obj.user_id,
      owner_id: obj._id,
      hash_for: obj.owner_type === 'individual' ? 'owner_pfs_doc' : 'owner_documents',
      email_address: obj.owner_type === 'individual' ? obj.email_address : obj.biz_email_address,
      to_name: obj.owner_type === 'individual' ? obj.first_name : obj.businessname,
      header_logo_path_1: this.environment.logo1_path,
      client_name: this.CONSTANTS.MAIL_TEMPLATE.project_name,
      senders_name: this.CONSTANTS.MAIL_TEMPLATE.senders_name,
      copyright_text: this.CONSTANTS.MAIL_TEMPLATE.copyright_text,
      frontend_url: `${this.environment.customerJourneyUrl}/${this.productConfig[this.environment.products_enabled[0]]?.route}`,
      terms: this.environment.terms,
      privacy: this.environment.privacy,
      mail_type: obj.owner_type === "individual" ? "5e874c31e2c8e935d455d229" : "5e874c31e2c8e935d455d227",
      no_consent_update: true,
      pfs_mail_date: Math.floor(new Date().getTime() / 1000),
      bank_name: this.CONSTANTS.MAIL_TEMPLATE.project_name,
      pfs_status: 'sent',
      esign_owner_pfs_status: null,
      is_docs_submitted: 0
    }
    const params = {
      slug: this.CONSTANTS.SLUG['request_pfs'],
      app_id: obj.app_id,
      user_id: obj.user_id,
      owner_id: obj._id
    }
    this.taskinfoService.saveTaskInfo(params, payload).subscribe(response => {
      if (response?.update_pfs_status && response?.update_pfs_status?.status === 200) {
        obj.owner_type === "individual" 
        ? this.common.popToast('success', '', 'PFS & Documents mail sent successfully.')
        : this.common.popToast('success', '', 'Documents mail sent successfully.')
        obj.owner_type === "individual" 
        ? this.addActivityLog(obj, 'pfs_document_mail')
        : this.addActivityLog(obj, 'document_mail');
      }
      else{
        this.refresh(obj._id);
        obj.owner_type === 'individual' ? this.common.popToast('success', '', 'PFS already received.') : this.common.popToast('success', '', 'Documents already received.')
      }
    })
  }

  addActivityLog(obj, activity){
    const log_data = {
      role_slug: this.config.journey === this.customerJourney? this.customerJourney : this.userData?.role_slug,
      app_id: this.app_data?.app_id ? this.app_data?.app_id : obj?.app_id,
      backend_user_id: this.userData.user_id,
      user_name: this.userData?.full_name,
      activity: activity,
      note : obj.owner_type === 'individual' ? obj.first_name : obj.businessname,
    };
    this.common.addActivityLog(log_data);
  }

  sendOwnerConsent(obj, type = 'send'): void {
    if (this.config.journey!=="banker" && this.getOwnership('totalOwnership', obj) !== 100) {
      this.common.popToast("error","", '100% ownership participation is required to process your credit request.')
      return;
    }
    const payload = {
      app_id: obj.app_id,
      user_id: obj.user_id,
      owner_id: obj._id,
      hash_for: 'owner_consent',
      email_address: obj.email_address || obj.biz_email_address,
      first_name: obj.owner_type === 'individual' ? obj.first_name : obj.businessname,
      consent: 'sent',
      client_name: this.CONSTANTS.MAIL_TEMPLATE.project_name,
      senders_name: this.CONSTANTS.MAIL_TEMPLATE.senders_name,
      frontend_url:`${this.environment.customerJourneyUrl}`,
      verification_url: obj.is_primary ? `owner-verification/owner-details` : `owner-verification`,
      mail_type: obj.is_primary ? "63f464d17f09424868261a3b" : "63dd41e5824df17495525734",
      role: this.masterDataPipe.transform(obj.company_role , this.company_role_new,'id','value'),
      cpa_firm_name : this.userData.user.cpa_firm_name,
      business_name : this.userData.user.business_name,
      product_type: this.product_type,
      consent_time: Date.now()
    }
    const params = {
      slug: this.CONSTANTS.SLUG['request_owners_consent'],
      hash_for: 'owner_consent',
      app_id: this.app_data?.app_id ? this.app_data?.app_id : obj?.app_id,
      user_id: this.app_data?.user_id ? this.app_data?.user_id : obj.user_id,
      owner_id: obj._id,
      business_id: this.app_data?.business_id,
      mail_type: obj.is_primary ? "63f464d17f09424868261a3b" : "63dd41e5824df17495525734",
      senders_name: this.CONSTANTS.MAIL_TEMPLATE.senders_name,
      frontend_url:`${this.environment.customerJourneyUrl}`,
      verification_url: obj.is_primary ? `owner-verification/owner-details` : `owner-verification`,
      role: this.masterDataPipe.transform(obj.company_role , this.company_role_new,'id','value'),
      cpa_firm_name : this.userData.user.cpa_firm_name,
      business_name : this.userData.user.business_name,
      consent: 'sent',
    }
    this.taskinfoService.saveTaskInfo(params, payload).subscribe(response => {
      let resData = response ?.update_owner_consent_sba_cpa ?.data ?.data; 
      if ( resData?._id) {
        const index = this.ownerData.findIndex((data: any) => data._id === resData._id);
        this.ownerData[index] = resData;
        this.updateOwnerObj({obj: this.ownerData, action: 'update_owner'});
        this.ownerListService.setLevelAndCount(this.ownerData);
      }

      if (response?.send_owner_consent_sba_cpa?.data?.code == 200) {
        const log_data = {
          role_slug: this.config.journey === this.customerJourney? this.customerJourney : this.userData?.role_slug,
          app_id: this.app_data?.app_id ? this.app_data?.app_id : obj?.app_id,
          backend_user_id: this.userData.user_id,
          user_name: this.userData?.full_name,
          activity: `consent_mail_${type}_to_customer`,
          note : obj.owner_type === 'individual' ? obj.first_name : obj.businessname,
        };
        this.common.addActivityLog(log_data);
        this.common.popToast('success', '', 'Consent mail sent.');
      }

      // if (response?.send_owner_consent?.data?.code == 200) {
      //   if (response ?.update_owner_consent ?.data ?.data ?._id) {

      //     const index = this.ownerData.findIndex(data => data._id === response.update_owner_consent.data.data._id);
      //     this.ownerData[index] = response.update_owner_consent.data.data;
      //     this.addActivityLog('consent_send', obj);
      //   }
      //   this.common.popToast('success', '', 'Consent mail sent.');
      // }
      // else if(response?.get_owner_data?.data?.data.length > 0 && response?.get_owner_data?.data?.data[0].consent === 'received'){
      //   this.refresh(obj._id);
       
      //   this.common.popToast('success', '', 'Consent already received.')
      // }
      // this.refreshOwners20More();
    })
  }
  refresh(owner:any): void {
    const query_params = {
      slug: this.config?.refresh_owner_slug,
      app_id: this.app_data?.app_id,
      user_id: this.app_data?.user_id
    }
    if(this.config?.componentType === 'owner-verification' || owner?.owner_type ==='individual') {
      query_params['owner_id'] = owner._id;
    }
    this.taskinfoService.getTaskInfo(query_params).subscribe(response => {
      const ownerData = response?.response_data ?.get_owner_data ?.data ?.data;
      if ( this.config?.componentType === 'owner-verification' || owner?.owner_type ==='individual') {
        const index = this.ownerData.findIndex((elem:any)=>owner._id === elem._id);
        if(index>=0){
          this.ownerData[index]=ownerData[0];
        }
      } else {
        this.ownerData=ownerData;
      }
      this.updateOwnerObj({obj: this.ownerData, action: 'refresh_data'});
      this.ownerListService.setLevelAndCount(this.ownerData);
      this.ownerData.sort(this.ownerListService.sortByLevel);
    })
  }

  updateOwnerObj(data){
    if(Array.isArray(data.obj)){
      this.ownerData = [...data.obj];
    }
    this.updated_owner_obj.emit(data);
  }

  checkOwnership(){
    if(this.config?.showTopLevel){
      let totalOwnership = this.ownerData.filter(owner=>(owner.level<=1 && owner.parent_id == "0")).reduce((count, owner)=>{
        if(owner.ownership){
          count += +owner.ownership;
        }
        return count;
      },0); 
     this.showOwnerhipTooltip = totalOwnership != 100;
    }else{
      let totalOwnership = 0;
      if(this.ownerData?.[0]){
        totalOwnership = this.getOwnership("totalOwnership", this.ownerData[0])
        this.showOwnerhipTooltip = totalOwnership != 100;
      }else{
        this.showOwnerhipTooltip = false;
      }
    }
  }

  
  checkSignerForBusiness(owner?: any){
    let parentId = owner ? owner.parent_id : this.parent_id ? this.parent_id : this.app_data.parent_id ? this.app_data.parent_id : "0" ;
    let index=-1;
    if(owner && this.edit_owner_index>=0){
      index=this.edit_owner_index
    } else {
      index= this.ownerData.length;
    }
    if(parentId){
      let is_signer_index = this.ownerData.findIndex((data: any) => (data.parent_id===parentId && data.authorized_signatory))
      return ((is_signer_index>=0) && (is_signer_index !== index)) ? true : false;
    }
    return false;
  }

  checkEpcOc(key : string,owner? : any){
    let index=-1;
    if(owner && this.edit_owner_index>=0){
      index=this.edit_owner_index
    } else {
      index= this.ownerData.length;
    }
    if(this.businessData[0]?.sba?.app_biz_legal_name === key){
      return true;
    }
    else {
      let ownerIndex = this.ownerData.findIndex((obj : any) => obj?.app_biz_legal_name === key);
      return ((ownerIndex>=0) && (ownerIndex !== index));
    }
  }

  getAddress(owner, type?: string): string {
    const state = this.state.find(res =>  res.id == owner.state) ?.value;
    const city = owner?.city?.charAt(0) + owner?.city?.slice(1, owner?.city?.length).toLowerCase();
    let streetNo = (type === 'primary_business') ? 'street_no' : 'streetNo';
    let street_name = (type === 'primary_business') ? 'street_name' : 'streetName';
    return `${(owner[streetNo] && typeof owner[streetNo] !== "undefined" && typeof owner[streetNo] === "string") ? owner[streetNo]+', ' : ""}${(owner[street_name] && typeof owner[street_name] !== "undefined" && typeof owner[street_name] === "string") ? owner[street_name]+', ' : ""}${(city && typeof city !== "undefined" && typeof city === "string") ? city+', ' : ""}${(state && typeof state !== "undefined" && typeof state === "string") ? state+', ' : ""}${(owner.zip_code && typeof owner.zip_code !== "undefined" && typeof owner.zip_code === "string") ? owner.zip_code : ""}`;
  }

  getOwnership(type: string, owner?: any): number {
    return this.ownerData.reduce((sum : any, item: any) => {
      if(type === "totalOwnership"){
        return (item?.parent_id === owner?.parent_id) ? parseFloat(((sum + (item?.ownership ? (+item.ownership) : 0))).toFixed(2)) : sum + 0;
      }
    }, 0);
  }

  editOwner(owner : any, index: number){
    if(this.config.journey === this.bankerJourney) {
      this.edit_owner_index = index;
      this.store.dispatch(addAppDetails({
        appData: {
          ...this.app_data,
          editOwnerDetails: owner,
          is_signer_for_business : this.checkSignerForBusiness(owner),
          is_epc : this.checkEpcOc('epc',owner)
        }
      }));
      this.common.navigate(this.editOwnerRoute);
    }
    else if((this.config.journey === this.customerJourney || this.config.journey === this.assistantJourney) && owner.is_primary){
      this.common.navigate(this.ownerDetailsRoute, this.businessData?.[0]?._id);
    }
    else {
      if (owner ?.is_primary) {
        this.common.navigate('owner-details');
        return;
      }
      this.edit_owner_index = index;
      this.openModal2(owner);
    } 
  }

  businessOwneship(action: string, owner? : any) {
    let query_params = { slug: 'business-ownership-percentage'}
    if(action === 'openBusinessOwnershipModal') {
      this.selectedOwner = owner;
      if(this.businessOwnershipForm) this.businessOwnershipForm.reset();
      this.taskinfoService.getTaskInfo(query_params).subscribe(response => {
          this.businessOwnershipConfig = response.form_fields;
          this.businessOwnershipForm = this.formGenerateService.createControl(this.businessOwnershipConfig);
          setTimeout(() => {
            this.formGenerateService.setFormValues(this.businessOwnershipForm, { business_ownership: owner?.business_ownership });
          }, 0);
          this.businessOwnershipSlug = response.task_slug;  
      })
      this.businessOwnershipModalRef = this.modalService.show(this.businessOwnershipTemplate, 
      { class: 'modal-lg thankyouRegister', backdrop: 'static' });
    }

    else if(action === 'submitBusinessOwnership'){
      if(this.formGenerateService.validateCustomFormFields(this.businessOwnershipForm, "continue", this.businessOwnershipConfig)){
      let payload = {
        business_ownership : Number(this.businessOwnershipForm.getRawValue().business_ownership),
        owner_id : this.selectedOwner?._id,
        app_id : this.selectedOwner?.app_id,
        consent : this.selectedOwner?.consent
      }
      this.taskinfoService.saveTaskInfo(query_params, payload).subscribe(res => {
        const ownerData = res?.update_owner_sba_cpa ?.data ?.data;
        if (ownerData) {
          const index = this.ownerData.findIndex((elem:any)=>this.selectedOwner._id === elem._id);
          if(index>=0){
            this.ownerData[index]=ownerData;
            this.ownerListService.setLevelAndCount(this.ownerData);
          }
        }
        this.businessOwnershipModalRef.hide();
        this.common.popToast('success', '', 'Business Ownership percentage added successfully.');
      })
      }
    }
  }

  viewTreeStructure() {
    this.treeConfig = {
      businessData : this.businessData, 
      ownerData : this.ownerData,
      owner_type : this.owner_type,
      company_role : this.company_role
    }
    this.businessOwnershipModalRef = this.modalService.show(this.treeStructureTemplate, 
      { class: 'modal-lg treeStructure', backdrop: 'static' });
  }

  showhideSsnTaxId(obj: any, isParent?: string) {
    if(isParent) obj.parentTaxid = !obj.parentTaxid;
    else obj.showhideSsnTaxId = !obj.showhideSsnTaxId;
  }

  getParentTaxId(obj) {
    if(obj.parent_id){
      return obj.parent_id == '0' ? this.businessData[0].app_biz_tax_id : this.masterDataPipe.transform(obj.parent_id , this.ownerData,'_id','tax_id');
    } 
      return '-';
  }
  showIcon(icon: string, owner?: any): boolean{
    let checkUserAssigned = (this.config?.journey != 'banker' || (this.config?.journey === 'banker' && this.is_authorized_banker));
    let levelCondition = owner?.parent_id === this.config?.app_data?.parent_id;
    switch (icon) {
      case 'deleteIcon':
        if(!owner?._id) return true;
        return checkUserAssigned && (levelCondition && owner.consent === 'pending' && owner.is_secondary && this.config.journey !== this.bankerJourney);

      case 'sendIcon' : 
        if(this.config.journey === this.bankerJourney && checkUserAssigned) return owner.consent === 'pending'; 
        return checkUserAssigned && checkUserAssigned && this.config?.componentType!=='owner-verification' && owner?._id && levelCondition && owner.consent === 'pending';

      case 'pendingIcon':
        return this.config?.componentType!=='owner-verification' && owner.parent_id !== this.app_data?.parent_id && owner.consent === 'pending' && this.config.journey !== this.bankerJourney;
      case 'refreshIcon':
        return this.config?.componentType!=='owner-verification' && owner.consent === 'sent' || (owner.consent === 'pending' && owner.parent_id !== this.app_data?.parent_id && this.config.journey !== this.bankerJourney);

      case 'editIcon' :
        if((!owner?._id) || (this.config.journey === this.bankerJourney && checkUserAssigned)) {
          return  true
        };
        return checkUserAssigned && ((owner?.parent_id === this.app_data?.parent_id && owner?.consent === 'pending') || (owner ?.is_primary));

      case 'resendIcon' : 
        return checkUserAssigned && this.config?.componentType!=='owner-verification' && this.config.journey === this.bankerJourney && owner.consent === 'sent'; 
        
      case 'addOwnerInAccordian' :
        return this.config.show_add_owner_option_in_accordian && owner.owner_type == 'corporate' && owner?.level < 4 && owner.consent == "received"; 

      case 'addOwnerInCompanyDetails' : 
        return this.config.show_add_owner_option_in_company_details;

      case 'addOwnerInBottom' : 
        return this.config.show_add_owner_option_in_bottom;
        
      case 'businessOwnerShipPercentageIcon' :
        return checkUserAssigned && this.config.showBusinessOwnerShipPercentage && !(owner?.owner_type == "corporate" && owner?.company_role==this.company_role_obj?.co_borrower);  
      default:  
        break;
    }
  }
  ngOnDestroy() {
    this.modalRef?.hide();
    this.businessOwnershipModalRef?.hide();
    this.compDestroyed$.next();
    this.compDestroyed$.complete();
  }

  onGridReady(params) {
    this.gridApi = params.api;
  }

  setColDef() {
    this.columnDefs = [
      {
        sortable: false,
        headerName: 'Status',//this.translate.instant('owner-list.html.tableHeader1'),
        width: 150,
        cellRendererFramework: AnchorCellRendererComponent,
        cellRendererParams: {
          onClick: this.actionClick.bind(this),
          config: this.config,
          is_authorized_banker: this.is_authorized_banker,
          app_data: this.app_data
        }
      }, {
        field: 'first_name',
        headerName: 'Name',//this.translate.instant('owner-list.html.tableHeader2'),
        width: 140,
        cellRenderer: (params) => {
          let owner = params.data;
          return owner.first_name ? owner.first_name + ' ' +  (owner.middle_name?(owner.middle_name+ ' '):'') + owner.last_name : owner?.businessname;
          // if(!owner.fullname)
          //   return owner.first_name + ' ' +  (owner.middle_name?(owner.middle_name+ ' '):'') + owner.last_name;
          // if(owner.fullname)
          //   return owner.fullname;
          // if(owner.businessname)
          //   return owner.businessname
        }
      }, {
        field: 'ownership',
        headerName: 'Ownership',//this.translate.instant('owner-list.html.tableHeader3'),
        width: 100,
        cellRenderer: (params) => {
          let owner = params.data;
          if(owner?.ownership!=undefined || owner?.ownership!=null)
            return owner.ownership + '%';
          else if(owner?.parent_id === '0' && owner?.owner_type == "corporate" && this.masterDataPipe.transform(owner?.company_role, this.company_role, 'id', 'type') == "co_borrower"){
            return 'N/A';
          }
        }
      }, {
        field: 'level',
        headerName: 'Level',//this.translate.instant('owner-list.html.tableHeader4'),
        width: 80,
        cellRenderer: (params) => {
          let owner = params.data;
          if(owner?.ownership!=undefined || owner?.ownership!=null)
            return 'Level '+ owner.level;
          else if(owner?.parent_id === '0'){
            return 'Level '+ owner.level;
          }
        }
      }, {
        field: 'company_role',
        headerName: 'Role',//this.translate.instant('owner-list.html.tableHeader5'),
        width: 85,
        cellRenderer: (params) => {
          let owner = params.data;
          if(owner.is_primary)
            return this.masterDataPipe.transform( owner.company_role, this.company_role, 'id', 'value')
            if(owner.is_secondary)
            return this.masterDataPipe.transform(owner.owner_type, this.owner_type, 'type', 'value')
        }
      }, {
        sortable: false,
        headerName: 'Address',//this.translate.instant('owner-list.html.tableHeader6'),
        width: 170,
        wrapText: true,
        autoHeight: true,
        cellRenderer: (params) => {
          let owner = params.data;
          return this.getAddress(owner);
        }
      }, {
        sortable: false,
        headerName: '',
        width: 82,
        cellRendererFramework: ActionCellRendererComponent,
        cellRendererParams: {
          onClick: this.actionClick.bind(this),
          config: this.config,
          is_authorized_banker: this.is_authorized_banker,
          app_data: this.app_data
        }
      }]

  }

  actionClick(params) {
    let owner = params.rowData.data, index = params.rowData.rowIndex;
    if(params.action == 'refresh'){
      this.refresh(owner)
    } else if(params.action == 'edit'){
      this.editOwner(owner, index)
    } else if(params.action == 'delete'){
      this.deleteOwner(owner, index)
    } else if(params.action == 'consent'){
      this.sendOwnerConsent(owner)
    } else if(params.action == 'resendConsent'){
      this.sendOwnerConsent(owner,'resend')
    } else if(params.action == 'ownershipPercent'){
      this.businessOwneship('openBusinessOwnershipModal',owner)
    }
  }
 
}