import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldInterface } from '@rubicon/interface/form-field';
@Component({
  selector: 'rubicon-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss']
})
export class TextareaComponent implements OnInit {
  field: FormFieldInterface;
  group: FormGroup;
  slug: string;
  indexVal: Number;
  char_count: number;

  constructor() { }

  ngOnInit() {
    this.indexVal=new Date().getTime();
    if(this.field?.format?.limit_count){
      this.char_count = this.group.value[this.field.name]?.length || 0;
      this.group.get(this.field.name).valueChanges.subscribe(val=>{
        this.char_count = val?.length || 0;
      })
    }
  }
}
