import { Injectable } from '@angular/core';
import { TaskInfoService } from '../api/task-info.service';
import { VERIFIER_PORTAL } from '@rubicon/interface/task-info';

@Injectable({
  providedIn: 'root',
})
export class ActivityService {
    
  activityNotes = {
      'MARK_REVIEW': 'CPA firm marked as reviewd.',
      'MARK_VERIFIED' :  'CPA firm marked as verified.',
      'VERIFICATION_QUEUE' : 'CPA firm moved to verification queue.',
      'MARK_NON_VERIFIED': 'CPA firm marked as Non-Verfied.',
      'ASSIGN_TO'  : 'CPA firm assigned to a verifier.',
      'REVIEWER_NOTE' : 'Note is added by verifier.',
      'CLEAR_PULL_UPLOADED' : 'Clear pull Report Initiated.',
      'VERIFIER_SCORE_UPDATE': 'Verification score updated.',
      'CPA_FIRM_BLOCK_UNBLOCK': 'Blocked a CPA firm',
      'CPA_VERIFICATION_DECLINED': 'CPA verification Declined',
  }

  constructor( private taskInfoService: TaskInfoService) { }

  saveActivity( type: string, backendUserId: string, userId: string, message?:string){
    let payload = {
        backend_user_id : backendUserId,
        user_id : userId,
        note : message ? message : this.activityNotes[type],
        action: type
    }

    this.taskInfoService.saveTaskInfo({
        slug : VERIFIER_PORTAL.VERIFIER_ACTIVITY
    }, payload).subscribe(res=>{
        
    }, err=>{
       console.log('err', err)
    })
  }

}
