import { Injectable, Inject } from "@angular/core";
import { KnockoutModalActionService } from "./knockout-modal-action";
import { Subject, Observable, of, throwError } from "rxjs";
import { map, switchMap, take } from "rxjs/operators";
import { CommonService, TaskInfoService } from "@rubicon/utils";
import { select, Store } from "@ngrx/store";
import { DateTimezonePipe } from '@rubicon/libraries/core-utils/shared-lazy';
import { ManageLoansService } from '../../services/manage-loans.service';
import { UpdateStatusService } from '../../services/update-status.service';

@Injectable({
    providedIn: 'root'
})
export class KnockoutActionService {
    orchConfig;
    backendUserData;
    constructor(private modalActionService: KnockoutModalActionService,
        @Inject('environment') public environment,
        private updateStatusService: UpdateStatusService,
        public common: CommonService,
        private store: Store<any>,
        private manageLoanService: ManageLoansService,
        private dateTimeZonePipe: DateTimezonePipe,
        private taskInfoService: TaskInfoService) {
            this.store.select('app').pipe(take(1)).subscribe(rootState => {
                this.orchConfig = rootState?.configurations?.[0];
            });
            this.store.pipe(select('app')).subscribe(rootState => {
                if(this.environment.journeyType != 'customer-journey'){
                    this.backendUserData = rootState.userData;  
                }
            });

    }

    resolveActionType(params, resolverOb, knockoutFilter, knockoutOn, knockoutConfig, knockoutFlags, saveDataApi$, knockoutData): Observable<any> {
        let actionCompleted$ = new Subject();
        switch (knockoutFilter ?.config ?.actionType) {
            case "proceedWithFlag":
                this.modalActionService.openModal(params, resolverOb, knockoutFilter, knockoutOn, actionCompleted$, knockoutConfig);
                break;
            case "decline":
                this.modalActionService.openModal(params, resolverOb, knockoutFilter, knockoutOn, actionCompleted$, knockoutConfig);
                break;
        }
        return actionCompleted$.asObservable().pipe(
            switchMap((action_data: any)=>{
                action_data.knockoutFlags = knockoutFlags;
                action_data.knockoutFlag = knockoutData.knockoutFlag;
                if(action_data.cancel){
                    return of(action_data);
                }
                else if(action_data.continue){
                    return saveDataApi$.pipe(
                        this.getUpdateKnockoutFlagOperator(params,action_data, knockoutFilter),
                        this.navigationOperator(params, action_data)
                    )
                }
                else if(action_data.decline){
                    return saveDataApi$.pipe(
                        this.getUpdateKnockoutFlagOperator(params,action_data, knockoutFilter),
                        this.getDeclineAppOperator(params, action_data),
                        this.navigationOperator(params, action_data)
                    )
                }
                return of(action_data);
            })
        );
    }

    navigationOperator(params, action_data){
        return map(() => {
            if (action_data && action_data.decline == true) {
                let nextTask;
                if(!params.app_id){
                    nextTask = "signin";
                }
                else if (['banker-journey'].includes(this.environment.journeyType)) {
                    nextTask = 'banker-dashboard';
                } else {
                    nextTask = 'dashboard';
                }
                this.common.navigate(nextTask, params.business_id);
            }
            return action_data;
        })
    }
    
    // return { all_data: { [key]: resolverOb }, resolve_data: body };

    getUpdateKnockoutFlagOperator(params, action_data, knockoutFilter){
        return switchMap((res:any)=>{
            action_data.saveDataApiRes = res;
            if(res?.nextTask){
                let knockoutFlag = action_data.knockoutFlag || {}
                let currentKnockoutType =  {};
                let is_knockout = false;
                for(let key in action_data.knockoutFlags){
                    currentKnockoutType[key] = action_data.knockoutFlags[key]?.resolverOb?.resolved;
                }
                knockoutFlag[knockoutFilter.type] = currentKnockoutType;
                for(let type in knockoutFlag){
                    if(type != "is_knockout"){
                        for(let key in knockoutFlag[type]){
                            if(knockoutFlag[type][key]){
                                is_knockout = true;
                            }
                        }
                    }
                }
                knockoutFlag.is_knockout =  is_knockout;
                if(params.business_id){
                    return this.updateKnockoutFlags(params, action_data, knockoutFlag)
                }else{
                    action_data.knockoutFlag = knockoutFlag;
                    return of(action_data);
                }
            }else{
                return throwError(action_data);
            }
        })
    }

    updateKnockoutFlags(_params, action_data, knockoutFlag) {
        let params = { slug: "update_business", ..._params };
        let application_customer_declined_params = this.orchConfig?.settings?.knockoutConfig?.application_customer_declined;
        let body:any = {
            knockoutFlag
        };
        if(action_data.decline && application_customer_declined_params?.status_id){
            body.blocked_application = true;
        }
        return this.taskInfoService.saveTaskInfo(params, body).pipe(
            map((res:any)=>{
                if(res?.nextTask){
                    return action_data;
                }
                throw action_data;
            })
        );
    }

    getDeclineAppOperator(params, action_data){
        return switchMap(()=>{
            if(!params.app_id){
                return of(action_data);
            }
            let application_customer_declined_params = this.orchConfig?.settings?.knockoutConfig?.application_customer_declined;
            return this.taskInfoService.getTaskInfo({slug: "app_detail_optimized", app_id: params.app_id, child_ref:"business_references,app_assignment", model:"app_sub_status"}).pipe(
                switchMap((res)=>{
                    let appData = res?.response_data?.app_detail_optimized?.data?.data?.[0];
                    let app_sub_status_master = res?.response_data?.master_data_optimised?.data?.data?.app_sub_status;
                    let sub_status = app_sub_status_master?.find(res=>res.id == application_customer_declined_params.sub_status_id);
                    if(!sub_status){
                        return throwError(action_data);
                    }
                    return this.manageLoanService.prepareBankerMap(appData).pipe(
                        switchMap((bankerMap)=>{
                            let applicationData = {
                                ...appData,
                                appId: params.app_id,
                                userId: appData.user_id,
                                loan_id: appData.auto_id,
                                app_id: params.app_id,
                                user_id: appData.user_id,   
                                app_assignment: this.manageLoanService.getUserAssignments(appData, bankerMap)
                            }
                            if(!sub_status.config){
                                sub_status.config = {};
                            }
                            sub_status.config.skip_check_access = true;
                            return this.updateStatusService.updateSubStatus(sub_status, {},applicationData, this.backendUserData || {}, this.dateTimeZonePipe).pipe(
                                map((res: any)=>{
                                    if(!res?.error) {
                                        return action_data;
                                    }else{
                                        throw action_data;
                                    }
                                })
                            )
                        })
                    )
                })
            );
            // return this.taskInfoService.saveTaskInfo({...params,slug:"decline_knockout_app"},{}).pipe(map((res:any)=>{
            //     if(res?.nextTask){
            //         return action_data;
            //     }else{
            //         throw action_data;
            //     }
            // }));
        });
    }

    
    addActivityLog(app_id, activity, note) {
        let log_data = {
            role_slug: this.backendUserData?.role_slug,
            app_id: app_id,
            backend_user_id: this.backendUserData?.user_id,
            user_name: this.backendUserData?.full_name || 'system',
            activity: activity,
            note
          };
        this.common.addActivityLog(log_data);
    }
}