import { Injectable } from '@angular/core';
import { Subject, Observable, of, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpRequest, HttpEventType, HttpResponse, HttpParams } from '@angular/common/http';
import { ApiResponse, apiUrls } from '@rubicon/interface/task-info';
import { environment } from '@env/environment';
import { retryWhen } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  fileEvent = new Subject<any[]>();
  uploadedFiles = [];
  private filesUploaded:Subject<{doc_id:string, name: string, progress: Observable<number>,size:number,created_at:string}> = new Subject<{doc_id:string, name: string, progress: Observable<number>,size:number,created_at:string}>();

  constructor(private http: HttpClient) { }

  public uploadFile(files: File[],params:any,body): { [key: string]: { progress: Observable<number> } } {

      // this will be the our resulting map
      const status: { [key: string]: { progress: Observable<number>, size: any, doc_id: BehaviorSubject<string> }} = {};

      for (const file of files) {

          // create a new multipart-form for every file
          const formData: FormData = new FormData();
          formData.append('file',  file);
          formData.append('filekey', 'data');
          formData.append('response_to_be_saved', JSON.stringify({name:file.name,size:file.size}));
          Object.keys(body).forEach(key=>{
            formData.append(key,body[key]);
          })
    let httpParms=new HttpParams(); 
    Object.keys(params).forEach(key=>{
      httpParms=httpParms.append(key, params[key]);
    })

          // create a http-post request and pass the form
          // tell it to report the upload progress
          let req = new HttpRequest('POST', environment.orchUrl + apiUrls.executeTask+'?slug='+params.slug, formData, {
              reportProgress: true
              
          });

          if (!req.headers.has('Content-Type')) {
              req = req.clone({ headers: req.headers.delete('Content-Type','application/json') });
          }
          // create a new progress-subject for every file
          const progress = new Subject<number>();
          const doc_id = new BehaviorSubject<string>('');

          // send the http-request and subscribe for progress-updates
          
          this.http.request(req).subscribe({next:(event: any) => {
              
              if (event && event.type === HttpEventType.UploadProgress) {

                  // calculate the progress percentage
                  const percentDone = Math.round(100 * event.loaded / event.total);
                  // pass the percentage into the progress-stream
                  progress.next(percentDone);
              } else if (event instanceof HttpResponse) {

               
                  doc_id.next(event.body.data.response_data.manual_doc_upload.data.data.doc_id);
                  doc_id.complete();
                  this.filesUploaded.next({doc_id:event.body.data.response_data.manual_doc_upload.data.data.doc_id, name: file.name, progress: of(100),size:file.size,created_at:event.body.data.response_data.manual_doc_upload.data.data.created_at});
                
                progress.complete();
              }


          },
        error:(error:any)=>{
          doc_id.next("error");
          doc_id.complete();
          this.filesUploaded.next({doc_id:'error', name: file.name, progress: of(100),size:file.size,created_at:""});
        
        progress.error(error);
        }});

          // Save every progress-observable in a map of all observables
          status[file.name] = {
              progress: progress.asObservable(),
              size: this.calculateSize(file.size),
              doc_id: doc_id          };
      }

      // return the map of progress.observables
      return status;
  }
  calculateSize(size: number) {
    let filesize = null;
    if (size < 1000) {
        filesize = `${size} bytes`;
    } else if (size < 1000 * 1000) {
        filesize = `${size / 1000} kb`;
    } else if (size < 1000 * 1000 * 1000) {
        filesize = `${size / 1000 / 1000} mb`;
    } else {
        filesize = `${size / 1000 / 1000 / 1000} gb`;
    }
    return filesize;
}
filesUploadedListener() {
  return this.filesUploaded.asObservable();
}
}
