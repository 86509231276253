<div class="container">
    <div class="wbg businessFunForm  ffp">                
        <div class="fs24 pdtc fwsb pb-4">Apply for an Account
            <span class="fs14 gtColor pt-2 fwm d-block">Enter your details for verification and create your account</span>
        </div>
        <form *ngIf="signupConfig.length > 0" [formGroup]="signupForm" (ngSubmit)="onSubmit()"> 
            <rubicon-dynamic [fields]="signupConfig" class="row" [group]="signupForm" [slug]="slug">
            </rubicon-dynamic>                    
            <div class="row btnRow pt-2">
                <div class="col-12 col-lg-12">
                    <div class="form-group">
                        <button class="btn btn-primary px-4">Apply for Account</button>
                    </div>
                    <p class="fs11 p-2 mb-0">You will be prompted on the next step to enter your firm information.</p>
                </div>
            </div>
        </form>
            
    </div>
</div>

<ng-template #userSuccess>
    <div class="modal-header">
        <h4 class="modal-title pull-left font18">Alert</h4>
        <button type="button" class="close pull-right" aria-label="Close"
            (click)="userRequestRef.hide(); apptype=null">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h5>Your firm is already registered. Please reach out to {{firmData}}  (administrator of the firm) to create your profile.</h5>
    </div>
</ng-template>