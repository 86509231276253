import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

// Third party imports
import { Ng5SliderModule } from 'ng5-slider';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule } from 'ngx-mask';
import { TranslateModule } from '@ngx-translate/core';
// Store
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromFormState from './+state/form-state.reducer';
import { FormStateEffects } from './+state/form-state.effects';
import { FormStateFacade } from './+state/form-state.facade';

// Directives
import { FieldDirective } from './directives/field.directive';

// Components
import { DynamicComponent } from './components/dynamic/dynamic.component';
import {
  TextComponent, NumberComponent, PasswordComponent, RadioComponent,
  SliderComponent, CheckboxComponent, SwitchComponent, TelComponent, DateComponent,
  SelectComponent, GroupComponent, TextareaComponent, FileComponent, AmountComponent,
  MultiSelectComponent
} from './components/form-fields';
import { UtilsModule } from '@rubicon/utils_custom';
import { FormService } from './services/form.service';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

@NgModule({
  imports: [
    CommonModule,
    Ng5SliderModule,
    TooltipModule.forRoot(),
    UtilsModule,
    StoreModule.forFeature(
      fromFormState.FORMSTATE_FEATURE_KEY,
      fromFormState.reducer
    ),
    EffectsModule.forFeature([FormStateEffects]),
    ReactiveFormsModule,
    TranslateModule.forChild(),
    NgbModule,
    NgxMaskModule,
    PopoverModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot()
  ],
  declarations: [
    DynamicComponent,
    FieldDirective,
    TextComponent,
    DateComponent,
    NumberComponent,
    SwitchComponent,
    TelComponent,
    TextareaComponent,
    PasswordComponent,
    FileComponent,
    GroupComponent,
    CheckboxComponent,
    RadioComponent,
    SliderComponent,
    SelectComponent,
    AmountComponent,
    MultiSelectComponent
  ],
  exports: [
    Ng5SliderModule,
    NgbModule,
    DynamicComponent,
    FieldDirective,
    TextComponent,
    DateComponent,
    NumberComponent,
    SwitchComponent,
    TelComponent,
    TextareaComponent,
    PasswordComponent,
    FileComponent,
    GroupComponent,
    CheckboxComponent,
    RadioComponent,
    SliderComponent,
    SelectComponent,
    AmountComponent,
    MultiSelectComponent
  ],
  providers: [FormStateFacade, FormService]
})
export class DynamicFormModule { }
