import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject, Observable, Subject, of, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { ToasterService, Toast } from 'angular2-toaster';
import { TaskInfoService } from '../api/task-info.service';
import { SecurityService } from '../security/security.service';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { map, catchError, switchMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { FeatureAccessService } from '../api/FeatureAccess.service';
import * as moment from 'moment';
import {get, includes} from 'lodash';
import { PRODUCTP_TYPE } from 'libs/affiliate-portal/dashboard/src/lib/constants';
@Injectable({
  providedIn: 'root'
})
export class CommonService {

  private masterDataSubject$ = new BehaviorSubject<any>(null);
  private eventDataSubject$ = new BehaviorSubject<any>(null);
  private componentDataSubject$ = new Subject<any>();
  private radioDataSubject$ = new Subject<any>();
  private menuSubject$ = new BehaviorSubject<any>(true);
  private radioSelectSubject$ = new Subject<any>();
  private applicationWorkflowSubject$ = new BehaviorSubject<object>(null);
  private applicationStepSubject$ = new BehaviorSubject<number>(1);
  private eventListenerSubject$ = new BehaviorSubject<any>(null);
  public pgLoaded = new BehaviorSubject(false);
  private $loginSubject = new Subject();
  public counter = 0;
  public isLoggedIn = false;
  public AddressDataSubject$ = new BehaviorSubject<any>(null);
  public LenderDataSubject$ = new BehaviorSubject<any>(null);
  public googleAddressSubject$ = new BehaviorSubject<any>(null);
  masterDataObj = {};
  public userEmailAddress: string;
  public isBackButtonClicked = false;
  public previousStep: string;
  private httpBackend: HttpClient;
  private _ppp2StepsUpdated$ = new BehaviorSubject<any>(null);
  private _ppp2UpdateStepMessage$ = new BehaviorSubject<any>(null);
  private readonly YES_VALUE = "5e8723158f2f4e3ac475fae6";
  private readonly NO_VALUE= "5e8723158f2f4e3ac475fae7";
  private _forgivUpdateStepMessage$ = new BehaviorSubject<any>(null);
  public financingInquiryFlag = new  BehaviorSubject<any>(null);
  public plan_expire_subject = new Subject();
  private multiSelectSubject = new Subject<any>();
  client_commission:any[]=[];
  sbaRadioBtnDisable: any;

  constructor(
    @Inject('INVALID_ROUTES') private invalid_routes, 
    private _router: Router, 
    private toasterService: ToasterService, 
    private taskInfoService: TaskInfoService,
    private security: SecurityService, 
    @Inject('APP_ROUTES') private APP_ROUTES:any[],
    @Inject('environment') private environment,
    private handler: HttpBackend,
    @Inject('CONSTANTS') private constants,
    @Inject('urls') private URLS,
    private translateService: TranslateService,
    private FeatureAccess: FeatureAccessService,
    ) { 
      this.httpBackend = new HttpClient(handler);
    }

  /**
   * function to get master data observable
   */
  get masterData() {
    this.masterDataObj = {};
    return this.masterDataSubject$.asObservable();
  }

  /**
   * function to send data
   * @param val
   */
  sendMasterData(val: any) {
    this.masterDataObj = { ...this.masterDataObj, ...val };
    this.masterDataSubject$.next({ ...this.masterDataObj });
  }

  /**
   * function to get master data observable
   */
  get radioData() {
    return this.radioDataSubject$.asObservable();
  }

  /**
   * function to send data
   * @param val
   */
  sendRadioData(val: any) {
    this.radioDataSubject$.next(val);
  }

  /**
   * function to get master data observable
   */
  get radioSelectData() {
    return this.radioSelectSubject$.asObservable();
  }

  /**
   * function to send data
   * @param val
   */
  setRadioDataForDropDown() {
    this.radioSelectSubject$.next();
  }

  /**
   * function to get master data observable
   */
  get menuFlag() {
    return this.menuSubject$.asObservable();
  }

  /**
   * function to send data
   * @param val : boolean value to show.hide menu
   */
  sendMenuFlag(val: boolean) {
    this.menuSubject$.next(val);
  }

  /**
   * function to send updated application step
   * @param val : Number
   */
  updateStepState(val: number) {
    this.applicationStepSubject$.next(val);
  }

  /**
   * function to get application update step
   */
  get updatedStep() {
    return this.applicationStepSubject$.asObservable();
  }

  /**
   * function to send updated application step
   * @param val
   */
  sendEventListener(val: any) {
    this.eventListenerSubject$.next(val);
  }

  /**
   * function to get application update step
   */
  get eventListener() {
    return this.eventListenerSubject$.asObservable();
  }

  /**
   * function to send master data
   */
  sendMasterDataToFields(fields: any[], responseData: any, fieldName?: string, defaultMasterkey: string = '',multiSelectData? : any) {
    if (fieldName) {
        this.sendMasterData({
          [fieldName]: responseData,
          multiSelectData
        });
    } else {
      for (const val of fields) {
        if (val.type === 'group') {
          this.sendMasterDataToFields(val.group_fields, responseData, fieldName, defaultMasterkey);
        } else {
          if (val.masterKey) {
            this.sendMasterData({
              [val.name]: responseData[val.name] || responseData[defaultMasterkey]
            });
          }
        }
      }
    }

  }
  

  /**
   * function to get current route URL
   */
  get currentURL() {
    return this._router.url;
  }

  /**
   * function to start loader
   */
  startLoad() {
    this.counter += 1;
    this.pgLoaded.next(true);
  }

  /**
   * function to stop loader
   */
  stopLoad() {
    this.counter -= 1;
    if (this.counter <= 0) {
      this.pgLoaded.next(false);
      this.counter = 0;
    }
  }

  /**
   * function to set value when user logged in/out
   * @param val: true/false
   */
  setLogin(val) {
    this.isLoggedIn = val;
    this.$loginSubject.next(val);
  }

  /**
   * function to get login event value
   */
  get loginObservable() {
    return this.$loginSubject.asObservable();
  }

  /**
   * function to navigate routes
   * @param path
   * @param id
   */
  navigate(path: string, id?: any) {
    if (id)
      this._router.navigate([this.APP_ROUTES[path], id]);
    else
      this._router.navigate([this.APP_ROUTES[path]]);
  }

  navigateByUrl(path: string) {
    // If path is invalid
    if(this.invalid_routes.includes('/'+path)) {
      this._router.navigate(['/page-not-found']);
    }
    // when path is valid
    else {
      this._router.navigateByUrl(path);
    }
  }

  /**
   * function to get router events
   */
  get routerEvent() {
    return this._router.events;
  }

  /**
  * function to show toaster
  */
  popToast(type: string, title: string, body: string) {
    const toast: Toast = {
      type,
      title,
      body
    };
    return this.toasterService.pop(toast);
  }

  /**
   * function to store data in storage
   * @param key
   * @param data
   */
  storeData(key: string, data: any , storage?:Storage) {
    storage=storage||sessionStorage;
    if (this.environment.secure) {
      storage.setItem(key, btoa(this.security.set(this.security.modifyData, JSON.stringify(data))));
    } else
    storage.setItem(key, JSON.stringify(data));
  }

  /**
   * function to get data from storage
   * @param key
   */
  getDataFromStorage(key: string, storage?:Storage) {
    storage=storage||sessionStorage;
    if (this.environment.secure) {
      if (storage.getItem(key))
        return JSON.parse(this.security.get(this.security.modifyData, atob(storage.getItem(key))));
      else
        return null
    }
    return JSON.parse(storage.getItem(key));
  }

  /**
   * function to get data from storage
   * @param key
   */
  deleteDataFromStorage(key: string, storage?:Storage) {
    storage=storage||sessionStorage;
    return storage.removeItem(key);
  }

  /**
   * function to send master data
   */
  sendEventCallData(name, action, data: any, eventData: any = {}) {
    data = data[action];
    data = { ...data, ...eventData, name };
    this.eventDataSubject$.next({ data });
  }

  get eventDataObservable() {
    return this.eventDataSubject$.asObservable();
  }

  sendGoogleAddressData(name, action, data: any) {
    data = { ...data, name }
    this.googleAddressSubject$.next({ data });
  }

  get googleAddressObservable() {
    return this.googleAddressSubject$.asObservable();
  }

  sendLenderData(data: any) {
    this.LenderDataSubject$.next({ data });
  }

  get LenderDataObservable() {
    return this.LenderDataSubject$.asObservable();
  }

  sendAddressData(data: any) {
    this.AddressDataSubject$.next({ data });
  }

  get AddressDataObservable() {
    return this.AddressDataSubject$.asObservable();
  }

  sendComponentCallData(name, action, data: any) {
    data = data[action]
    data = { data: [...data.data.data[action]], name: action }
    this.componentDataSubject$.next({ data });
  }

  sendOwnerConsent(owners: any,primaryOwner:string) {
    let ownerHashCalls = 0;
    const consent = [];
    owners.forEach(owner => {
      if (owner.ownership >= 20 && !owner.is_primary && !owner.consent_send) {
        ownerHashCalls++;
        this.taskInfoService.saveTaskInfo({
          slug: 'generate-owners-hash', user_id: owner.user_id
        }, {
            owner_id: owner.id,
            owner_name: owner.first_name,
            email_address: owner.email_address,
            consent_send:true
          }).subscribe((response) => {
            owner.hash = response.create_owner_hash.data.data.hash;
            consent.push({
              to: owner.email_address,
              to_name: owner.first_name,
              owner_consent_url: `${location.origin}/owner/${response.create_owner_hash.data.data.hash}`
            })
            ownerHashCalls--;
            if (ownerHashCalls === 0) {
              this.taskInfoService.saveTaskInfo({
                slug: response.nextTask.value, user_id: owner.user_id
              }, {
                  consents: consent,
                  first_name:primaryOwner
                }).subscribe(() => {
                  this.popToast('success', 'Success', 'Owner Consent sent to owners with having ownerships more or equal to 20%');
                });
            }
          })
      }
    });
  }

  saveCurrentState(app) {
    if (app.businessID && app.nextTask) {
      this.taskInfoService.saveTaskInfo({
        slug: 'save-current-state'
      }, {
          business_id: app.businessID,
          current_state: app.nextTask,
          action_type: 'save',
          app_id: app.appID,
          user_id: app.userData.user.id
        }).subscribe(() => { });
    }
  }

  get componentDataObservable() {
    return this.componentDataSubject$.asObservable();
  }

  getQueryTrackingParamsObservable() {
    let raw_url = sessionStorage.getItem("raw_url") ? sessionStorage.getItem("raw_url") : ((location && location.href)?location.href:'');
    let tracking_params:any = {
      raw_url,
      user_agent: (window&&window.navigator&&window.navigator.userAgent)?window.navigator.userAgent:''
    }
    return this.httpBackend.get('https://api.ipify.org/?format=json').pipe(
      map((res:any)=>{
        if(res && res.ip){
          tracking_params.ip = res.ip;
        }
        return tracking_params;
      }, catchError((error)=>of(tracking_params))
      ));
  }

  objectIdCounter(id: string) {
      let  ctr = 0;
      const timestamp   = parseInt(id.slice(ctr, (ctr+=8)), 16);
      const machineID   = parseInt(id.slice(ctr, (ctr+=6)), 16);
      const processID   = parseInt(id.slice(ctr, (ctr+=4)), 16);
      const counter     = parseInt(id.slice(ctr, (ctr+=6)), 16);
      return counter;
  }

    /**
   * function to send updated application step
   * @param name : String
   */
  updateWorkflowStep(step_obj: object) {
    this.applicationWorkflowSubject$.next(step_obj);
  }

  /**
   * function to get application update step
   */
  get workflowStepListener() {
    return this.applicationWorkflowSubject$.asObservable();
  }

  updatePPP2Steps(is_new_journey){
    this._ppp2StepsUpdated$.next(is_new_journey);
  }

  get ppp2Steps(){
    return this._ppp2StepsUpdated$.asObservable();
  }

  updatePPP2StepMessage(step:string, stepMessage: string){
    this._ppp2UpdateStepMessage$.next({
      step,
      stepMessage
    });
  }

  get ppp2StepMessage(){
    return this._ppp2UpdateStepMessage$.asObservable();
  }

  ucFirst(str) {
    const array = str.split(" ");
    let updatedStr = "";
    array.forEach(a => {
      updatedStr += a.substring(0,1).toUpperCase() + a.substring(1).toLowerCase() + " ";
    });

    return updatedStr.trim();
  }

  padding(id) {
    const str = id+'';
    const length = 5;
    const diff = length - str.length;
    if(diff > 0) {
      // apply number of zeros
      return new Array(diff).fill(0).join('') + str;
    }
    
    return str;
  }

  encodeURIPlus(s) {
    if (!s) {
        return s;
    }
    s = encodeURIComponent(s);
    return s.replace(/\+/g, '%2B');
  }

  calculatePriceOnCoupon(price: number, couponData: any) {
    if(couponData.type === 'PERCENT') {
      const discount = (price * couponData.discount_value)/100; 
      const updatedPrice = this.toFixed2(price - discount);
      return updatedPrice;
    } else if(couponData.type === 'DOLLAR') {
      const updatedPrice = this.toFixed2(price - couponData.discount_value);
      return updatedPrice; 
    } else if(couponData.type === 'FLAT') {
      const updatedPrice = couponData.discount_value;
      return updatedPrice; 
    }

    return price;
  }

  toFixed2(num) {
    return parseInt((num * 100).toString())/100;
  }

  getPayrollDDLTitle(businessData_eligibiltyQuestions,businessData ){
    let message = "Enter values based on a 90-day period of your choosing between May 1, 2019 and September 30, 2019.";
    let dropdownValues = [];
    let payrollTitle = "Total payroll (including owner compensation)";  
    let helptext=''
    if (businessData_eligibiltyQuestions && businessData_eligibiltyQuestions.farm_ranch_or_sole_prop && businessData_eligibiltyQuestions.farm_ranch_or_sole_prop.business_is_sole_prop === true) {
      message = "Enter values based on the full time period of your choosing.";
      dropdownValues = [{key:'2019',value:'2019 full year'},{key:'2020',value:'2020 full year'}]
    } 
    else if (businessData_eligibiltyQuestions && businessData_eligibiltyQuestions.is_seasonal_employer === this.YES_VALUE) {
      message = "Enter values based on a 12-week period of your choosing between February 15, 2019 and February 15, 2020.";
      dropdownValues = [];
    } else {
      if (businessData_eligibiltyQuestions && businessData_eligibiltyQuestions.biz_operation_all_2019 === this.NO_VALUE && businessData_eligibiltyQuestions.number_of_months === "0") {
        message = "Enter values based on the full time period of your choosing.";
        dropdownValues = [{ key: '2020', value: '2020 full year' }, { key: 'TTM', value: 'Last 12 months' }];
      }
      if (businessData_eligibiltyQuestions && businessData_eligibiltyQuestions.biz_operation_all_2019 === this.NO_VALUE && businessData_eligibiltyQuestions.number_of_months > 0 && businessData_eligibiltyQuestions.biz_operation_feb_15_2020 === this.YES_VALUE) {
        message = "Enter values based on the full time period of your choosing.";
        if(businessData.already_ppp_loan){
          payrollTitle = "Total payroll costs from when first in operation in 2019 or 2020 through the end of calendar year 2020 (including owner compensation)";
          message = "Enter values based on the full year 2020 plus all months where the business was operational in 2019.";
          dropdownValues = [];
          //only show message with no ddl
          // dropdownValues = [{ key: '2020', value: '2020 full year' }, { key: 'TTM', value: 'Last 12 months' }];
        }else{
          message = "Enter values based on the full time period of your choosing.";
          dropdownValues = [{ key: '2020', value: '2020 full year' }, { key: 'jan_feb_2020', value: 'Jan-Feb 2020' }];
        }
      }
      if (businessData_eligibiltyQuestions && businessData_eligibiltyQuestions.biz_operation_all_2019 === this.YES_VALUE) {
        message = "Enter values based on the full time period of your choosing.";
        dropdownValues = [{key:'2019',value:'2019 full year'},{key:'2020',value:'2020 full year'},{key:'TTM',value:'Last 12 months'}]
      }
    }
    if (businessData_eligibiltyQuestions &&
      businessData_eligibiltyQuestions.farm_ranch_or_sole_prop &&
      businessData_eligibiltyQuestions.farm_ranch_or_sole_prop.business_is_sole_prop &&
      businessData_eligibiltyQuestions.farm_ranch_or_sole_prop.business_is_sole_prop === true &&
      businessData_eligibiltyQuestions.biz_employ_people === '5e8723158f2f4e3ac475fae6') {
      payrollTitle = 'Total Payroll (paid to employees) '
      helptext='Enter cash compensation paid to employees.'
    }
    return {message, dropdownValues, payrollTitle,helptext};
  }

  updateJourneyDocStepLabels(obj,slug){

      let defaultTranslation = {}
      let label:any;
      let tooltip:any;
    if (obj.label) {
      label = obj.label
      let obj1 = {}
      obj1[label.key] = label.value
      defaultTranslation[slug] = { label: obj1 }
      this.translateService.setTranslation("en",defaultTranslation, true);
      this.translateService.use("en");
    }
    if (obj.tooltip) {
      tooltip = obj.tooltip
      let obj1 = {}
      obj1[tooltip.key] = tooltip.value
      defaultTranslation[slug] = { tooltip: obj1 }
      this.translateService.setTranslation("en",defaultTranslation, true);
      this.translateService.use("en");
    }
    } 
  getDaysAhead(dateString, days){
    // let date;
    // date = new Date(dateString);
    // date.setDate(date.getDate() + days);
    // return date;
    var a = moment(dateString); 
    a.add(days, 'days'); 
    a.format();
    return a;
  }

  checkDraw2Weeks(fundedDate, draw2Weeeks) {
    if(!fundedDate) return false;
    
    // const splitted = fundedDate.split(' ');
  
    // const date = splitted[0];

    // const dt = date.replace(/[\/-]/ig, ' ').split(' ');
    
    // let dd =  dt[2];
    // let yyyy;
    // let mm = dt[1];;
    // if(dt[0].length === 4) {
    //   yyyy = dt[0];
    // } else {
    //   yyyy = dt[2];
    //   dd = dt[0];
    // }

    // const actualDate: any = new Date(mm+'/'+dd+'/'+yyyy);
    const actualDate: any = this.getActualDate(fundedDate);

    if(actualDate == 'Invalid Date') {
      return  false;
    }

    const draw2AllowedDate = new Date().getTime() - (draw2Weeeks * 1000);

    return draw2AllowedDate > actualDate.getTime() ? true : false;
  }

  getActualDate(fundedDate) {

    const splitted = fundedDate.split(' ');
  
    const date = splitted[0];

    const dt = date.replace(/[\/-]/ig, ' ').split(' ');
    
    let dd =  dt[2];
    let yyyy;
    let mm = dt[1];;
    if(dt[0].length === 4) {
      yyyy = dt[0];
    } else {
      yyyy = dt[2];
      dd = dt[0];
    }

   return new Date(mm+'/'+dd+'/'+yyyy);
  }

  calculateDraw1CoveredDate(fundedDate) {
    
    if(!fundedDate) return false;

    const actualDate: any = this.getActualDate(fundedDate);

    if(actualDate == 'Invalid Date') {
      return  false;
    }


    const currentTime = new Date().getTime();
    const fundedTime = actualDate.getTime();

    return Math.round((currentTime-fundedTime)/(60*60*24*7*1000))+1;
  }

  getFundedTime(fundedDate) {

    if (!fundedDate) return false;

    const actualDate: any = this.getActualDate(fundedDate);

    if (actualDate == 'Invalid Date') {
      return false;
    }


    return actualDate.getTime();

  }​​​​​​​​

  cpaExistingOrNew(created_at) {
    if(this.environment.cpa_verified_date) {
      const created_time = isNaN(created_at) ? new Date(created_at).getTime()/1000 : +created_at;
      if(created_time >= this.environment.cpa_verified_date) {
        // CPAUser is a new user
        return true;
      } else {
        return false
      }
    }
  }

  /**service to process plan on payment success */
  processPayment = (res, paymentPlanData, buyPlanData) => {
    if (res &&
      res.buy_plan &&
      res.buy_plan.data &&
      res.buy_plan.data.data) {
      const activeUserPlan = res.buy_plan.data.data.filter((userPlan) => userPlan.is_active).pop();
      const userPlans = res.buy_plan.data.data.map((plans) => plans.plan);
      const isActivePlan = userPlans.filter((userPlan) => userPlan.is_active).pop();
      if (activeUserPlan.payment_status === "success" && activeUserPlan.is_active === true) {
        const all_plans = this.FeatureAccess.orderPlans(userPlans);
        const activePlan = all_plans.filter((plan) => plan.is_active === true).pop();
        this.FeatureAccess.setFeature({ ...activePlan, user_plan_id: activeUserPlan.id });

        if (activeUserPlan.record_id) {
          if (paymentPlanData) {
            paymentPlanData.orderedPlan.record_id = activeUserPlan.record_id;
          } else if (buyPlanData) {
            buyPlanData.record_id = activeUserPlan.record_id;
          }
        }
        // filter out the lower plans
        this.popToast('success', 'Payment successful', 'Successfully subscribed to ' + this.ucFirst(isActivePlan.type) + ' Plan.');
        return of({ paymentSuccess: true })
      } else {
        this.popToast('error', 'Payment Failed', 'Unable to subscribed to ' + this.ucFirst(isActivePlan.type) + ' Plan, please try later');
        return of({ paymentSuccess: false })
        // this.closeModal();
      }
    }
  }


  /** service to update covid flag in business from sba api*/
  findAndUpdateCovidFlag(loanNumber: string, businessData: any) {
    try{
        let get_covid_flag_resp = false; 
        return this.getCovidFlag(loanNumber).pipe(
          switchMap(res=>{
            businessData['loan_no_changed'] = false;
            let covid_flag;
            if (res &&
              res.validate_ppp_loan &&
              res.validate_ppp_loan.data &&
              res.validate_ppp_loan.data.data &&
              res.validate_ppp_loan.data.data.sba_number) {
                get_covid_flag_resp = true;
                covid_flag = res.validate_ppp_loan.data.data.covid_flag;
                let sba_api_funding_date = res.validate_ppp_loan.data.data.funding_date;
                businessData['covid_flag'] = (covid_flag == null) ? "" : covid_flag ;
                businessData['sba_api_funding_date'] = sba_api_funding_date ? sba_api_funding_date : "" ;
                return this.updateBusiness(businessData);
              }else{
                businessData['covid_flag'] = 0;
                businessData['sba_api_funding_date'] = "";
                return this.updateBusiness(businessData);
              }
          }),
          map(resp=>{
            if(get_covid_flag_resp){
              resp.no_covid_flag = false;
            }else{
              resp.no_covid_flag = true;
            }
            return resp;
          })
        );
    }catch(err){
      console.log(err); 
    }

  }

  /**get covid flag */
  getCovidFlag(loanNumber) {
    return this.taskInfoService.saveTaskInfo({ slug: 'validate-ppp-loan' }, { sba_number: loanNumber });
  }

  /**update business*/
  updateBusiness(payload) {
    return this.taskInfoService.saveTaskInfo({ slug: "update_email_link_status" }, payload)
  }

  getTermLoanOwnerHash(payload:any) {
    return this.taskInfoService.saveTaskInfo({
      slug: 'term-loan-owner-hash'
    }, { ...payload }).pipe(
      map(resp => {
        if (resp && resp.generate_owner_hash && resp.generate_owner_hash.data && resp.generate_owner_hash.data.data &&  resp.generate_owner_hash.data.data.encrypted_hash) {
          return {
            hash: resp.generate_owner_hash.data.data.encrypted_hash,
            hash_generated: true
          }
        }else{
          return {
            hash_generated: false,
            hash: null
          }
        }
      })
    )
  }

  sendOwnerConsentMail(payload: any) {
    return this.taskInfoService.saveTaskInfo({
      slug: payload.slug 
    }, payload);
  }

  updateOwnerDetails(payload: any){
      return this.taskInfoService.saveTaskInfo({ slug: payload.slug}, payload)
        .pipe(
          map(resp=>{
            if(resp && resp.term_loan_owner_update && resp.term_loan_owner_update.data && resp.term_loan_owner_update.data.data){
              return {
                is_update: true,
                updated_owner : resp.term_loan_owner_update.data.data
              }
            }else{
              return {
                is_update: false,
                updated_owner : null
              }
            }
          })
        )
  }

  handleTermLoanErrors(response,productType?) {
    const termLoanErrorMessage = get(response, 'submit_tloan_to_marketplace.errors.errors.message');
    let applicationType="";    
    if(productType){
      switch (productType) {
        case this.constants.APP_TYPE.term_loan:
          applicationType = "Term Loan"
          break;
        case this.constants.APP_TYPE.working_capital:
          applicationType = "Revenue-Based Financing Loan";
          break;
        case this.constants.APP_TYPE.ertc:
          applicationType = "ERTC Loan"
          break; 
      }
    }

    if (termLoanErrorMessage && typeof termLoanErrorMessage === 'object') {
      for (var key in termLoanErrorMessage) {
        if (includes(key, 'owner_info')) {
          this.handleOwnerInfoErrors(termLoanErrorMessage, key);
        } else if(includes(key, 'business_info')) {
          this.popToast('error', 'Business Info Error', termLoanErrorMessage[key].message);
        } else if(includes(key, 'lead_info')) {
          this.handleLeadInfoErrors(termLoanErrorMessage, key, applicationType);
        } else {
          this.popToast('error', 'Error', termLoanErrorMessage[key].message);
        }
      }
    } else if(termLoanErrorMessage && typeof termLoanErrorMessage === 'string') {
      if(termLoanErrorMessage == 'You have already submitted this application') {
        this.popToast('error', 'Error', 'You have already submitted a '+ applicationType + ' application under this business name');
      } else {
        this.popToast('error', 'Error', termLoanErrorMessage);
      }
    }else {
      this.popToast('error', 'Error', 'Unable to submit this '+ applicationType + ' application');
    } 
  }

  handleOwnerInfoErrors(termLoanErrorMessage, key) {
    if ((includes(key, 'first_name') || includes(key, 'last_name'))) {
      if (termLoanErrorMessage[key].rule == 'alphaNumeric') {
        this.popToast('error', 'Owner Info Error', `Owner's first and last name should not contain any special characters. Please correct the owner information to submit.`);
        return;
      } else {
        this.popToast('error', 'Owner Info Error', 'Invalid first name or last name in owners. Please correct the owner information to submit.');
        return;
      }
    } else if (key == 'biz_legal_name') {
      if (termLoanErrorMessage[key].rule == 'alphaNumeric') {
        this.popToast('error', 'Owner Info Error', `Owner's Business name should not contain any special characters. Please correct the owner information to submit.`);
        return;
      } else {
        this.popToast('error', 'Owner Info Error', 'Invalid Business Name in owners. Please correct the owner information to submit.');
        return;
      }
    } else if (key == 'owner_info') {
      this.popToast('error', 'Owner Info Error', termLoanErrorMessage[key].message);
    } else {
      this.popToast('error', 'Owner Info Error', 'Please enter the correct owner info details');
    }
  }

  handleLeadInfoErrors(termLoanErrorMessage, key, applicationType?) {
    if ((includes(key, 'first_name') || includes(key, 'first_name'))) {
      if (termLoanErrorMessage[key].rule == 'alphaNumeric') {
        this.popToast('error', 'Logged-In User Info Error', `Logged-In user's first and last name should not contain any special characters`);
        return;
      } else {
        this.popToast('error', 'Logged-In User Info Error', 'Invalid first name or last name in Logged-In user name');
        return;
      }
    } else if (key == 'lead_info') {
      this.popToast('error', 'Logged-In User Info Error', termLoanErrorMessage[key].message);
    } else {
      this.popToast('error', 'Error', 'Something went wrong. Unable to submit this '+(applicationType ? applicationType : '')+' application. Please check your internet connection and try again.');
    }
  }

  isProductTypeChanged(application){
    if(application.offered_product_type && application.product_type === this.constants.APP_TYPE.term_loan &&  application.offered_product_type === this.constants.APP_TYPE.working_capital){
      return true;
    }else{
      return false;
    }
  }

  isProductTypeERTCChanged(application){
    if(application.offered_product_type &&
       (application.product_type === this.constants.APP_TYPE.term_loan || application.product_type === this.constants.APP_TYPE.working_capital) &&
       application.offered_product_type === this.constants.APP_TYPE.ertc){
      return true;
    }else{
      return false;
    }
  }

  calculateCommission(amount){
    let commision=0;
    if(amount <= 50000) {
      const maxCommision = 500;
      const tenPercent = amount*0.1;
      const commisionAmt = tenPercent < maxCommision ? tenPercent : maxCommision;
      commision= parseInt((commisionAmt * 100).toString())/100;
    } else if(amount<=350000){
      commision=.01*amount;
    }
    else if(amount<2000000){
       commision=.005*amount;
    }
    else{
      commision=.0025*amount;
    }
    return commision;
  }

  calculateTermLoanCommission(amount, type,client_commission){
    let commision=0; let client_comm;
    switch (type) {
      case 'enterprise':
        let ent_data = client_commission.filter(item=>item.type === 'enterprise');
          ent_data = ent_data && ent_data.length && ent_data[0];
          client_comm = ent_data['product_commission'].term_loan_commission;
          commision = amount * client_comm;
        break;
      case 'premium':
        let prem_data = client_commission.filter(item=>item.type === 'premium');
          prem_data = prem_data && prem_data.length && prem_data[0];
          client_comm = prem_data['product_commission'].term_loan_commission;
          commision = amount * client_comm;
        break;
      case 'basic':
        let basic_data = client_commission.filter(item=>item.type === 'basic');
          basic_data = basic_data && basic_data.length && basic_data[0];
          client_comm = basic_data['product_commission'].term_loan_commission;
          commision = amount * client_comm;
        break;
      case 'new_premium':
        let new_data = client_commission.filter(item=>item.type === 'new_premium');
          new_data = new_data && new_data.length && new_data[0];
          client_comm = new_data['product_commission'].term_loan_commission;
          commision = amount * client_comm;
        break;
      case 'free':
        let free_data = client_commission.filter(item=>item.type === 'free');
          free_data = free_data && free_data.length && free_data[0];
          client_comm = free_data['product_commission'].term_loan_commission;
          commision = amount * client_comm;
        break;
      default:
        break;
    }
    return commision;
  }

  calculateERTCCommission(amount, type,client_commission) {
    let commision = 0; let client_comm;
    switch (type) {
      case 'enterprise':
        let ent_data = client_commission.filter(item=>item.type === 'enterprise');
          ent_data = ent_data && ent_data.length && ent_data[0];
          client_comm = ent_data['product_commission'].ertc_commission;
          commision = amount * client_comm;
        break;
      case 'premium':
        let prem_data = client_commission.filter(item=>item.type === 'premium');
          prem_data = prem_data && prem_data.length && prem_data[0];
          client_comm = prem_data['product_commission'].ertc_commission;
          commision = amount * client_comm;
        break;
      case 'basic':
        let basic_data = client_commission.filter(item=>item.type === 'basic');
          basic_data = basic_data && basic_data.length && basic_data[0];
          client_comm = basic_data['product_commission'].ertc_commission;
          commision = amount * client_comm;
        break;
      case 'new_premium':
        let new_data = client_commission.filter(item=>item.type === 'new_premium');
          new_data = new_data && new_data.length && new_data[0];
          client_comm = new_data['product_commission'].ertc_commission;
          commision = amount * client_comm;
        break;
      case 'free':
        let free_data = client_commission.filter(item=>item.type === 'free');
          free_data = free_data && free_data.length && free_data[0];
          client_comm = free_data['product_commission'].ertc_commission;
          commision = amount * client_comm;
        break;
      default:
        break;
    }
    return commision;
  }

  calculateWorkingCapitalCommission(amount, type,client_commission){
    let commision=0; let client_comm;
    switch (type) {
      case 'enterprise':
        let ent_data = client_commission.filter(item=>item.type === 'enterprise');
          ent_data = ent_data && ent_data.length && ent_data[0];
          client_comm = ent_data['product_commission'].working_capital_comission;
          commision = amount * client_comm;
        break;
      case 'premium':
        let prem_data = client_commission.filter(item=>item.type === 'premium');
          prem_data = prem_data && prem_data.length && prem_data[0];
          client_comm = prem_data['product_commission'].working_capital_comission;
          commision = amount * client_comm;
        break;
      case 'basic':
        let basic_data = client_commission.filter(item=>item.type === 'basic');
          basic_data = basic_data && basic_data.length && basic_data[0];
          client_comm = basic_data['product_commission'].working_capital_comission;
          commision = amount * client_comm;
        break;
      case 'new_premium':
        let new_data = client_commission.filter(item=>item.type === 'new_premium');
          new_data = new_data && new_data.length && new_data[0];
          client_comm = new_data['product_commission'].working_capital_comission;
          commision = amount * client_comm;
        break;
      case 'free':
        let free_data = client_commission.filter(item=>item.type === 'free');
          free_data = free_data && free_data.length && free_data[0];
          client_comm = free_data['product_commission'].working_capital_comission;
          commision = amount * client_comm;
        break;
      default:
        break;
    }
    return commision;
  }

  getProgressBarClass(due_amount_to_credit_amount){
    let progressBarClass = ''
    if((due_amount_to_credit_amount > 1/8 && due_amount_to_credit_amount < 2/8) || due_amount_to_credit_amount == 1/8 || due_amount_to_credit_amount < 1/8){
      progressBarClass = "red";
    } else if((due_amount_to_credit_amount > 2/8 && due_amount_to_credit_amount < 3/8) || due_amount_to_credit_amount == 2/8){
      progressBarClass = "redOrg";
    } else if((due_amount_to_credit_amount > 3/8 && due_amount_to_credit_amount < 4/8) || due_amount_to_credit_amount == 3/8){
      progressBarClass = "orange";
    } else if((due_amount_to_credit_amount > 4/8 && due_amount_to_credit_amount < 5/8) || due_amount_to_credit_amount == 4/8){
      progressBarClass = "orgYell";
    } else if((due_amount_to_credit_amount > 5/8 && due_amount_to_credit_amount < 6/8) || due_amount_to_credit_amount == 5/8){
      progressBarClass = "yellow";
    }  else if((due_amount_to_credit_amount > 6/8 && due_amount_to_credit_amount < 7/8)|| due_amount_to_credit_amount == 6/8){
      progressBarClass = "yellLigGree";
    } else if((due_amount_to_credit_amount > 7/8 && due_amount_to_credit_amount < 8/8) || due_amount_to_credit_amount == 7/8){
      progressBarClass = "lightGreen";
    } else if(due_amount_to_credit_amount == 1 || due_amount_to_credit_amount > 1){
      progressBarClass = "ligGreeGreen";
    }
    return progressBarClass;
  }


  handleSBAErrors(response) {
    const sbaErrorMessage = get(response, 'submit_sbaloan_to_marketplace.errors.errors.message');
    if (sbaErrorMessage && typeof sbaErrorMessage === 'object') {
      for (var key in sbaErrorMessage) {
        if (includes(key, 'owner_info')) {
          this.handleOwnerInfoErrors(sbaErrorMessage, key);
        } else if(includes(key, 'business_info')) {
          this.popToast('error', 'Business Info Error', sbaErrorMessage[key].message);
        } else if(includes(key, 'lead_info')) {
          this.handleLeadInfoErrors(sbaErrorMessage, key, 'SBA Loan');
        } else {
          this.popToast('error', 'Error', sbaErrorMessage[key].message);
        }
      }
    } else if(sbaErrorMessage && typeof sbaErrorMessage === 'string') {
      if(sbaErrorMessage == 'You have already submitted this application') {
        let applicationType = 'SBA Loan';
        this.popToast('error', 'Error', 'You have already submitted a '+ applicationType + ' application under this business name');
      } else {
        this.popToast('error', 'Error', "SBA Loan "+ sbaErrorMessage);
      }
    } else {
      this.popToast('error', 'Error', 'Unable to submit this SBA Loan Application');
    }
  }

  calculateSBALoanCommission(amount,type,client_commission){
    let commision=0; let client_comm;
    switch (type) {
      case 'enterprise':
      let ent_data = client_commission.filter(item=>item.type === 'enterprise');
          ent_data = ent_data && ent_data.length && ent_data[0];
          client_comm = ent_data['product_commission'].sba_commission;
          commision = amount * client_comm;
        break;
      case 'premium':
        let prem_data = client_commission.filter(item=>item.type === 'premium');
          prem_data = prem_data && prem_data.length && prem_data[0];
          client_comm = prem_data['product_commission'].sba_commission;
          commision = amount * client_comm;
        break;
      case 'basic':
        let basic_data = client_commission.filter(item=>item.type === 'basic');
          basic_data = basic_data && basic_data.length && basic_data[0];
          client_comm = basic_data['product_commission'].sba_commission;
          commision = amount * client_comm;
        break;
      case 'new_premium':
        let new_data = client_commission.filter(item=>item.type === 'new_premium');
          new_data = new_data && new_data.length && new_data[0];
          client_comm = new_data['product_commission'].sba_commission;
          commision = amount * client_comm;
        break;
      case 'free':
        let free_data = client_commission.filter(item=>item.type === 'free');
         free_data = free_data && free_data.length && free_data[0];
          client_comm = free_data['product_commission'].sba_commission;
          commision = amount * client_comm;
        break;
      default:
        break;
    }
    return commision;
  }

  emitMultiSelectItem(item) {
    this.multiSelectSubject.next(item);
  }
  
  senLeadstoSBA(leads){
    this.taskInfoService.saveTaskInfo({ slug: 'leads_to_sba'}, leads)
    .subscribe((res) => {
      if(res && res.leads_to_sba && res.leads_to_sba.data && res.leads_to_sba.data.data && res.leads_to_sba.data.data.id) {
        return res.leads_to_sba.data.data.id; 
      }
    }, err => {
      console.log("err", err)
    })
  }

  getYear() {
    return new Date().getFullYear();
  }

  getSBACountApplicationPage(userId: string) : Observable<boolean> {
    const reqBody = { user_ids : [userId] };
    return this.taskInfoService.saveTaskInfo({ slug: 'get-ppp2-apps' }, reqBody)
      .pipe(map((data) => {
        if(data.ppp2_filter_app.data.data.length > 0) {
          const sbaApplications = data.ppp2_filter_app.data.data.filter((app) => {
            return (
              app['product_type'] === PRODUCTP_TYPE.sba &&
              !(
                app.hasOwnProperty('case_status') &&
                [
                  'funded',
                  'withdrawn',
                  'declined',
                  'declined by borrower',
                  'declined by system',
                  'declined by itria',
                  'declined by verifier',
                  'declined by lender',
                  "criteria don't match",
                ].includes(app.case_status.toLowerCase())
              )
            );
          });

          return sbaApplications.length > 0;
        }else{
          return false;
        }
      }),
      catchError((error) => {
        return of(false);
      })
    )
  }

  getSBACountClientPage(userId: string): Observable<boolean> {
    let reqBody = {user_ids : [userId]}

    return this.taskInfoService.saveTaskInfo({ slug: 'get-ppp2-apps' }, reqBody)
      .pipe(map((data) => {
        if(data.ppp2_filter_app.data.data.length > 0) {
          const sbaApplications = data.ppp2_filter_app.data.data.filter((app) => {
            if(app['product_type'] === PRODUCTP_TYPE.sba) {
              // app doesn't has case-status means application is in progress state.
              if(!app.hasOwnProperty('case_status')) return true;
              else if(
                app.hasOwnProperty('case_status') && 
                ![
                    'funded',
                    'withdrawn',
                    'declined',
                    'declined by borrower',
                    'declined by system',
                    'declined by itria',
                    'declined by verifier',
                    'declined by lender',
                    "criteria don't match",
                  ].includes(app.case_status.toLowerCase())
              ) {
                return true;
              }
              else return false;


            }else {
              return false;
            }
          });

          return sbaApplications.length > 0;
        }else{
          return false;
        }
      }),
      catchError((err) => {
        return of(false);
      })
    )
  }

  getTextFromEn(path) {
    return this.translateService.instant(path);
  }

  getStaticUrl(url_path) {
    return this.URLS[url_path];
  }
}

