export const environment = {
    production: true,
    env : 'prod',
    orchUrl: '//orchestrator.pppforgivenesstool.com/',
    region: 'usa',
    appLang: 'en',
    isdCode: '+1',
    secure: true,
    ownershipPerLimit: 20,
    skipOtp: false,
    google_drive: {
      client_id: '979373805330-r52plpig5io3no03qtng6d1bd6g61ted.apps.googleusercontent.com',
      secret_key: 'LmWHJrLCfsT6DfLwgsJMJQ1R',
      app_id: '979373805330'
    },
    client: 'CPA Business Funding Portal',
    cdnURL: "https://d21f7feagphy64.cloudfront.net/cpabusinessportal/",
    isNTBEnabled:true,
    cssPath: "../../../../../../projects/forgiveness-journey/src/assets/json/css.json",
    plaid_public_key: 'efb1ebcc0b1c9218471a67c57b45ca',
    widgetCDN: "https://d21f7feagphy64.cloudfront.net/servicewidgets/widget-hybrid.service.min.js",
    timezone: 'EDT',
    forgiveness_journey_url: "https://www.pppforgivenesstool.com",
    affiliate_journey_url: "https://cpaloanportal.com",
    mint: true,
    GTM_CODE: '',
    CUSTOMER_BULK_UPLOAD: true,
    multiple_user_apps: true,
    user_management: true,
    aicpa_excel_parsing: true,
    connect_dataSources: true,
    from_name:"CPA Business Funding Portal",
    from:"cpa@biz2credit.com",
    support_email: 'cpasupport@biz2credit.com',
    enable_3508_simple_form: true,
    worldpayScript: 'https://request.eprotect.vantivcnp.com/eProtect/js/eProtect-iframe-client3.min.js',
    payment_required: true,
    paypageId: 'HRKGXdMqDDwzZjRk',
    aicpa_forgiveness_calculator : 'https://future.aicpa.org/resources/download/ppp-loan-forgiveness-calculator-excel',
    addOn: true,
    payment_bcc: "mailarchive@biz2credit.com",
    CONNECT_URJANET_URL: 'https://connect.urjanet.com/ZspQo6FFkGnrXE1r1X72?',
    URJANET_APP_ENV: 'prod',
    enable_govt_declaration_mail:true,
    bugsnag: true,
    // bugsnagKey:'50e20951edd1fedd47f945a765c3d70f',
    etran_sba_check: true,
    underMaintenance: true,
    cpa_verified_date: 1614879398,
    draw2Weeeks: 60*60*24*7*8,
    maxDraw1CoveredPeriodWeeks: 60*60*24*7*24,
    singupActive: false,
    forgiveness_start_date: '2021/01/01',
    forgiveness_end_date: '2021/03/30',
    underMaintenanceForg: false,
    enable_docusign_forgiveness : true,
    brand_id : 'b97fa3a1-0392-440a-993e-bcc4152e41e4',
    mail_to : 'cpa@biz2credit.com',
    to_cc : ['charles.groome@biz2credit.com','guangyu.chen@biz2credit.com','corey.falkin@biz2credit.com','Matt.Elwell@HQ.CPA.com', 'Olivia.Row@HQ.CPA.com','pppfinancingtool@hq.cpa.com', 'michael.tryon@biz2credit.com', 'utkarsh.chaubey@biz2credit.com'],
    skip_payment_before_31jan: false, //feature flag for skip payment on selected plans till 31 jan 22
    post_pay: true,
    cms_mail_to: 'matt.elwell@hq.cpa.com',
    cms_mail_cc: ['corey.falkin@biz2credit.com','sarah.hillard@hq.cpa.com', 'Jessica.Clemons@HQ.CPA.com', 'PPPFinancingtool@HQ.CPA.com', 'Mary.Strahan@HQ.CPA.com', 'charles.groome@biz2credit.com', 'yuri.krym@biz2credit.com', 'claude.siambe@biz2credit.com', 'Mario.Ogu@HQ.CPA.com', 'Jeremiah.LaRue@HQ.CPA.com'],
    sba_mail_to: 'cpa@biz2credit.com',
    sba_mail_cc: ['Matt.Elwell@HQ.CPA.com', 'sarah.hillard@hq.cpa.com', 'Jessica.Clemons@HQ.CPA.com','PPPFinancingtool@HQ.CPA.com','Mary.Strahan@HQ.CPA.com','corey.falkin@biz2credit.com', 'yuri.krym@biz2credit.com', 'susie.moy@biz2credit.com', 'shubham.nair@biz2x.com', 'charles.groome@biz2credit.com','michael.tryon@biz2credit.com','rohit.agarwal@biz2credit.com', "Olivia.Row@HQ.CPA.com"],
    lead_sba_flag: false,
    // added for sba
    products_enabled: ['sbaloans'],
    client_folder: 'cpa_client',
    journeyType: 'customer-journey',
    asset_path: 'assets/',
    full_name: "full name",
    customerJourneyUrl: "https://cpaloanportal.com",
    copyright_text: new Date().getFullYear() + " PRO",
    mail_client_name: "Biz2X",
    project_name: "CPA Loans ",
    noreplymail: "donotreplybusinessloans@abcbank.com",
    privacy: 'https://www.biz2x.com/privacy-policy',
    terms: 'https://www.biz2x.com/terms-of-use',
    logo1_path: 'https://demo-portal-sba.b2cdev.com/assets/images/logo.png',
    logo2_path: 'https://demo-portal-sba.b2cdev.com/assets/images/logo.png',   // to be edited
    client_name: "Biz2X",
    cpa_directory_mail_to: 'cpa@biz2credit.com',
    cpa_directory_mail_cc: ['Matt.Elwell@HQ.CPA.com', 'sarah.hillard@hq.cpa.com', 'Jessica.Clemons@HQ.CPA.com','PPPFinancingtool@HQ.CPA.com','Mary.Strahan@HQ.CPA.com','corey.falkin@biz2credit.com','utkarsh.chaubey@biz2credit.com', 'susie.moy@biz2credit.com','jason.beckett@biz2credit.com','charles.groome@biz2credit.com','michael.tryon@biz2credit.com','Gerard.denoeux@biz2credit.com','rohit.agarwal@biz2credit.com','shubham.nair@biz2x.com'],
    cdnLogoUrl: 'https://d21f7feagphy64.cloudfront.net/',
    //email distribution list for plan activation emails.
    plan_active_emails : ['cpa@biz2credit.com','Matt.Elwell@HQ.CPA.com','sarah.hillard@hq.cpa.com','Jessica.Clemons@HQ.CPA.com', 'PPPFinancingtool@HQ.CPA.com', 'Mary.Strahan@HQ.CPA.com', "Olivia.Row@HQ.CPA.com"],
    app_env: "prod",
    KOPopUpEmail:"corey.falkin@biz2credit.com",
    //wordpress config variables
    wordpress_api_url: "https://cpacms.biz2x.com/index.php/wp-json/wp/v2/",
    wordpress_page_id: 103, //prod-stage ID
    wordpress_flag: false,
    environment_from: 'prod', //environment variable to forward SBA application to specific environment
    affiliate: 'cpa.com',
    track_name: 'CPA Portal API',
    uniqueLinkPrefix:"https://www.biz2credit.com/affiliate-quick-apply?firmname=",
    uniqueLinkSuffix:"&affiliate=cpa.com"
  };
  