<header rubiconScroll>
  <div class="container">
    <div id="logoRow" class="row pb-5 pt20 logoRow">
      <div class="col-1 col-sm-1 col-md-1 col-lg-1 pr-lg-0">
        <rubicon-navbar></rubicon-navbar>
      </div>
      <div class="col-5 col-sm-5 col-md-5 col-lg-5 pl-lg-0">
        <div class="b2cLogo">
          <a href="javascript:void(0)">
            <img src="assets/images/biz2creditLogo.svg" alt="logo" class="img-fluid" />
          </a>
        </div>
      </div>
      <div class="col-6 col-sm-6 col-md-6 col-lg-6 text-right">
        <div class="btn-group userManageBlock">
          <button type="button" class="btn btn-secondary dropdown-toggle bg-transparent border-0 white-color"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{ userData?.user.full_name }}
          </button>
          <div class="dropdown-menu dropdown-menu-right">
            <div class="dropdown-item">Dark View <span class="switchWrap ml-2 darkVchange">
                <label class="el-switch el-switch-sm" for="themechange">
                  <span class="sr-only">Dark View</span>
                  <input #themeRef id="themechange" (change)="setTheme()" type="checkbox" name="switch">
                  <span class="el-switch-style"></span>
                </label>
              </span></div>
            <a class="dropdown-item" routerLink="/account-setting">Account Setting</a>
            <a class="dropdown-item" routerLink="/change-password">Change Password</a>
            <a class="dropdown-item" (click)="logout()" href="javascript:void(0)">Logout</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>