import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'prefixValue'
})
export class PrefixValuePipe implements PipeTransform {

  transform(value: any): any {
    if(value){
      return `$${value}`;
    }
    else{
      return value;
    } 
  }

}
