// app-routing.module.ts
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RouteValidationGuard } from '@rubicon/utils_custom';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        loadChildren: () => import('./common-feature/common-features.module').then(m => m.CommonFeaturesModule)
      }
    ],
    canActivate: [RouteValidationGuard]     
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }