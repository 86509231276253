import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { TaskInfoService } from '@rubicon/utils';
import { of, merge, combineLatest, Observable } from 'rxjs';
import { map, catchError, startWith, takeUntil, distinctUntilChanged, debounceTime, switchMap, tap } from 'rxjs/operators';
import { KnockoutActionService } from './knockout-action.service';
import { conditionHelperService } from '../../../../../../core-utils/biz2xPro-config-engine';
@Injectable({
    providedIn: 'root'
})

export class KnockoutResolveService {

    constructor(private taskInfoService: TaskInfoService,
            private  knockoutActionService:KnockoutActionService){

    }

    getKnockoutFiltersMaster(appConfig){
        let modelName = appConfig?.knockoutConfig?.modelName;
        let knockout_filter_type = appConfig?.knockoutConfig?.type;
        if(modelName && knockout_filter_type && appConfig?.knockoutConfig?.required){
            return this.taskInfoService.getTaskInfo({slug: modelName, knockout_filter_type }).pipe(
                map((response)=>{
                    if(response?.response_data?.[modelName]?.data?.data?.[0]){
                        return response?.response_data?.[modelName]?.data?.data;
                    }
                }),
                catchError(()=>{
                    return null;
                })
            );
        }
        return of(null);
    }

    applyValueChangesKnockouts(compDestroyed$, knockoutConfig, knockoutFilters, forms, business_id){
        if(!knockoutFilters){
            return of({continue: true});
        }
        for(let knockoutFilter of knockoutFilters){
            let valueChanges = knockoutFilter?.valueChanges;
            if(valueChanges && Array.isArray(valueChanges) && knockoutFilter?.config?.knockOutOn?.includes("valueChanges")){
                for(let config of valueChanges){
                    if(config.onFields && Array.isArray(config.onFields)){
                        this.listenToValueChange(business_id, compDestroyed$,forms, config, knockoutFilter, knockoutConfig);
                    }
                }
            }
        }
    }
 
    resolveKnockoutConditions(params, action, knockoutConfig, knockoutFilters, data, saveDataApi$):Observable<any>{
        let knockoutFlags = {};
        let resolvedApi;
        if(knockoutFilters){
            for(let knockoutFilter of knockoutFilters){
                let filter_conditions = knockoutFilter?.filter_conditions;
                if(knockoutFilter?.config?.knockOutOn?.includes(action) && filter_conditions && Array.isArray(filter_conditions)){
                    let resolverOb = conditionHelperService.resolve(data, filter_conditions);
                    knockoutFlags[knockoutFilter.value] = {resolverOb, knockoutFilter};
                    if(!resolvedApi && resolverOb.resolved){
                        resolvedApi = this.knockoutActionService.resolveActionType(params, resolverOb, knockoutFilter, action, knockoutConfig, knockoutFlags, saveDataApi$, data);
                    }
                }
            }
            if(!resolvedApi){
                resolvedApi = saveDataApi$.pipe(
                    this.knockoutActionService.getUpdateKnockoutFlagOperator(params,{continue: true, knockoutFlags, knockoutFlag:data.knockoutFlag}, knockoutFilters[0])
                );
            }
        }
        if(!resolvedApi){
            resolvedApi = saveDataApi$.pipe(
                map((res:any)=>{
                    return {continue: true, saveDataApiRes: res, knockoutFlag: {}};
                })
            );
        }
        return resolvedApi;
    }

    listenToValueChange(business_id, compDestroyed$, forms, config, filterConfig, knockoutConfig){
        combineLatest(...config.onFields.map(fieldConfig=>{
            let formControl = forms[fieldConfig.onForm]?.get(fieldConfig.name);
            let operators = [];
            // if(fieldConfig.startWith){
            //     operators.push(startWith(formControl.value))
            // }
            if(fieldConfig.debounceTime){
                operators.push(debounceTime(fieldConfig.debounceTime))
            }
            return formControl?.valueChanges?.pipe(...operators,takeUntil(compDestroyed$));
        })).pipe(
            map((values)=>{
                let valuesObj = {};
                for(let i=0;i<values.length;i++){
                    let field_config = config.onFields[i];
                    valuesObj[field_config.name] = values[i];
                }
                return valuesObj;
            }),
            distinctUntilChanged((prev:any, next:any)=>{
                let keysNext = Object.keys(next);
                let keysPrev = Object.keys(prev);
                let setArr = Array.from(new Set([...keysNext,...keysPrev]));
                return keysNext.length == keysPrev.length && setArr.every(key=>next?.[key] == prev?.[key]);
            }),
            takeUntil(compDestroyed$)
        ).subscribe((valuesObj)=>{
            if(Array.isArray(filterConfig?.filter_conditions)){
                let resolverOb = conditionHelperService.resolve(valuesObj, filterConfig.filter_conditions);
                if(resolverOb.resolved){
                    this.knockoutActionService.resolveActionType({business_id}, resolverOb, filterConfig, "valueChanges", knockoutConfig, {[filterConfig.value]:resolverOb.resolved}, of({nextTask: true}), {...valuesObj, knockoutFlag: forms.knockoutFlag}).subscribe();
                }
            }
        });
    }

    calculateAge(birthday) { // birthday is a date
        var ageDifMs = Date.now() - birthday.getTime();
        var ageDate = new Date(ageDifMs); // miliseconds from epoch
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    }

    getData(loanFormData){
        return {
            "total_loan_purpose_amount":loanFormData.total
        }
    }

    // FSB Consent Mail condition
    isConsentRequired(data, filter_conditions){
        return conditionHelperService.resolve(data, filter_conditions);
    }

    getKnockoutInfo(business){
        let knockoutFlag = business?.knockoutFlag;
        if(!knockoutFlag?.is_knockout){
            return of(null);
        }
        return this.taskInfoService.getTaskInfo({slug: "master_data_optimised", model:"knockout_filter"}).pipe(
            map((response)=>{
                let knockoutFilters = response?.response_data?.master_data_optimised?.data?.data || [];
                let knockoutInfo = {};
                let knockoutMasterMap = {};

                for(let knockoutFilter of knockoutFilters){
                    let {type, value} = knockoutFilter;
                    knockoutMasterMap[type+"#"+value] = knockoutFilter;
                }

                for(let type in knockoutFlag){
                    if(typeof knockoutFlag[type] == "object"){
                        for(let value in knockoutFlag[type]){
                            if(knockoutFlag[type][value]){
                                let knockoutFilter = knockoutMasterMap[type+"#"+value];
                                if(!knockoutInfo[type]){
                                    knockoutInfo[type] = [];
                                }
                                knockoutInfo[type].push({
                                    type, value, description: knockoutFilter?.config?.description
                                });
                            }
                        }
                    }
                }
                return knockoutInfo;
            })
        );
    }
}