
<!-- <iframe style="height: 500px; width: 100%;" #iframe (load)="onLoad(iframe)"></iframe> -->

<lib-document-management *ngIf="config" [config]="config" (manualUploadAccount)="openModal2(template)" (selectedDocEvent)="onSelectedDocs($event)"
    (linkBankAccount)="openModal(template)" (errorCheckEvent)="errorCheck($event)" (downloadFormEvent)="downloadFormEvent($event)" 
    (requestDocTypeUpdateEvent)="requestDocTypeUpdate(template,$event)" (downloadDocTypeEvent)="downloadDocType($event)"></lib-document-management>

    <ng-template #template>
        <!-- <ng-container *ngIf= "flag"> -->
        <ng-container *ngIf="yodleeModal">
            <div class="modal-header border-0 ">
                <button type="button" class="close" aria-label="Close" (click)="closeModal()">
                    <span aria-hidden="true"></span>
                </button>
            </div>
            <div class="yodleeWidget" style="min-height:165px">
    
            </div>
        </ng-container>
        <ng-container *ngIf="!yodleeModal">
            <div class="modal-header pb-1">
                <h4 class="modal-title modalRequest pull-left">Are you sure you want to request an update?
                </h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body pt-2">
                <div class="row">
                    <div class="col-12">
                        <div class="fs14 fwm pb-3">An email will be sent out to the customer for uploading this document
                            again. Enter your comment below to include in this email.</div>
                    </div>
                    <div class="col-auto">
                        <div class="p-label">Comment: </div>
                    </div>
                    <div class="col">
                        <div class="form-group mb-0">
                            <textarea class="form-control" style="height: 80px;" row="2" col="5" name="comment" id="comment"
                                [(ngModel)]="comment" placeholder="Enter here..." (input)=commentChange($event) maxlength="250"></textarea>
                            <span *ngIf="mandatoryCommentErr" class="error-msg error fs-16">Comment is required.</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="text-right pt-1">
                    <a class="btn btn-default mr-3" href="javascript:void(0);" (click)="closeModal()">Cancel</a>
                    <button type="button" class="btn btn-primary" (click)="sendMail()">Confirm</button>
                </div>
            </div>
        </ng-container>
    </ng-template>