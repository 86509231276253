import { Injectable, Inject } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { take, map, switchMap, takeWhile, filter, first, reduce, concatMap } from "rxjs/operators";
import { TaskInfoService, CommonService } from "@rubicon/utils";
import { from, of, Subject, throwError } from "rxjs";
import * as _ from 'lodash';
import { AccessValidatorService, conditionHelperService, EvaluatorHelperService } from '../../../../../core-utils/biz2xPro-config-engine';
import { WorkflowActionsService } from '../services/workflow-actions.service';
import { GenerateUploadLetterService } from './generate-upload-letter.service';
import { TranslateService } from '@ngx-translate/core';
import { EcoaService } from './ecoa.service';
import { TaskTrackerService } from './task-tracker.service';
import { ManageLoansService } from './manage-loans.service';

@Injectable({
    providedIn: "root"
})
export class UpdateStatusService {
  backendUserData;
    constructor(private store: Store<any>, private taskInfoService: TaskInfoService,
        private common: CommonService, @Inject('CONSTANTS') public CONSTANTS,
        private accessValidatorService: AccessValidatorService,
        private workflowActionsService:WorkflowActionsService,
        private generateUploadLetterService:GenerateUploadLetterService,
        public translate: TranslateService,
        private ecoaService:EcoaService,
        private manageLoansService:ManageLoansService,
        @Inject('environment') private environment,
        private taskTrackerService: TaskTrackerService,
        private evaluatorHelperService: EvaluatorHelperService
        
    ){
          this.store.pipe(select('app')).subscribe(rootState => {
              if(this.environment.journeyType != 'customer-journey'){
              this.backendUserData = rootState.userData;  
              }
            });
    }

    getSubStatuses(appData, subStatusesMaster){
        return this.store.select('app').pipe(
            take(1),
            map(rootState => {
                let userData = rootState.userData;
                let userRoleStageAccess = userData.role_stage_mapping;
                let allowedSubStatuses = [];
                let allowedSubStatusesIds = [];
                for(let role_stage of userRoleStageAccess){
                    if(role_stage.stage_id === appData?.stage_id && role_stage.role_id === userData?.role_id && role_stage.status_id === appData?.status_id){
                        if(Array.isArray(role_stage.sub_status)){
                            allowedSubStatusesIds.push(...role_stage.sub_status);
                        }
                    }
                }
                allowedSubStatusesIds = Array.from(new Set(allowedSubStatusesIds));
                let statusIdStatuMasterMap = {};
                for(let status of subStatusesMaster){
                    statusIdStatuMasterMap[status.id] = status;
                }
                for(let status_id of allowedSubStatusesIds){
                    if(statusIdStatuMasterMap[status_id] && statusIdStatuMasterMap[status_id].visible){
                        allowedSubStatuses.push(statusIdStatuMasterMap[status_id]);
                    }
                }
                return allowedSubStatuses;
            })
        );
           
    }

    getFilterConditionEvaluatedData(applicationData, config?){
      let offer_management_ref, conditional_approval_decision_ref, uploaded_conditional_approval_letter, caf_saved_ref;
      if(applicationData?.business_references?.length){
        for(let ref of applicationData?.business_references){
          switch(ref?.type){
            case "offer_management": offer_management_ref = ref;
              break;
            case "conditional_approval_decision": conditional_approval_decision_ref = ref;
              break;
            case "caf_saved" : caf_saved_ref = ref;
            break;
            case "document-management":
              if(ref?.response?.type === "conditional_approval" && ref?.action_type === "upload_letter") {
                uploaded_conditional_approval_letter = ref;
              }
            break;
          }
        }
      }
      if(config?.exclude_caf_saved_if_latest_offer_management){
        let caf_saved_ref_date = new Date(caf_saved_ref?.created_at*1000);
        let offer_management_ref_date = new Date(offer_management_ref?.created_at*1000);
        if(caf_saved_ref_date?.getTime() <= offer_management_ref_date?.getTime()) {
          caf_saved_ref = undefined;
        }
      }
      return {offer_management_ref, conditional_approval_decision_ref, caf_saved_ref, uploaded_conditional_approval_letter}
    }

    updateSubStatus(subStatusToUpdate, form_data, applicationData, backendUserData, dateTimeZone) {
      //data to return
      let action_allowed = true;
      if(this.taskTrackerService.isTaskCompleted(applicationData.appId, "update_status")){
        return of({});
      }
      let evaluatedValuesApi = of({});
      let evaluatedValues = {};
      if (subStatusToUpdate?.config?.evaluate_key_filter_conditions?.length) {
        evaluatedValuesApi = this.evaluatorHelperService.evaluateMultipleTypes(subStatusToUpdate?.config?.evaluate_key_filter_conditions, applicationData, new Subject());
      }
      // config check filter_condition
      if(subStatusToUpdate?.config?.filter_conditions){
        evaluatedValuesApi = evaluatedValuesApi.pipe(
          switchMap((_evaluatedValues)=>{
            evaluatedValues = _evaluatedValues;
            let filterEvaluatedData = this.getFilterConditionEvaluatedData(applicationData, subStatusToUpdate?.config);
            let filterData = {...applicationData, ...filterEvaluatedData, evaluatedValues};
            let result = conditionHelperService.resolve(filterData, subStatusToUpdate.config.filter_conditions, 'AND', true);
            if (!result?.resolved) {
              let conditionFailed = result?.status?.find(({resolved}) => resolved === false);
              this.common.popToast('error', '', conditionFailed?.errorMessage || 'Update status not allowed.');
              return throwError(conditionFailed);
            }
            return of(evaluatedValues);
        }))
      }
      //
      let workflow_action: any =  this.workflowActionsService.getWorkflowActions("update_status");
      return evaluatedValuesApi.pipe(
        switchMap(()=>{
          return this.taskInfoService.getTaskInfo({
            slug: 'app_detail_optimized',
            child_ref:"app_assignment,business",
            app_id: applicationData.appId,
            user_id: applicationData.userId
          });
        }),
        switchMap((res)=>{
          let appData = res?.response_data?.app_detail_optimized?.data?.data?.[0] || {};
          appData = {...appData, evaluatedValues};
          let businessData = appData?.business?.[0] || {};
          let subStatusConfig = subStatusToUpdate?.config;
          if(subStatusConfig?.conditional_resolve_params) {
            for (let i = 0; i < subStatusConfig.conditional_resolve_params.length; i++) {
              let condition_data = subStatusConfig.conditional_resolve_params[i];
              let result = conditionHelperService.resolve(appData, condition_data.conditions);
              if (result.resolved) {
                subStatusConfig = {...subStatusConfig, ...condition_data.params};
                break;
              }
            }
          }
          if(subStatusConfig){
            subStatusToUpdate = {...subStatusToUpdate, config: subStatusConfig};
          }
          if(this.environment.journeyType !== 'customer-journey' && !subStatusToUpdate?.config?.skip_check_access){
            action_allowed = this.accessValidatorService.checkAccessValidate(applicationData, workflow_action?.config?.validate_type , workflow_action);
          }
          if(action_allowed){
              const uniqueStageBankers = this.getBankerName(applicationData.app_assignment);
              if(subStatusToUpdate?.config?.append_form_data){
                form_data = {...(form_data|| {}), ...subStatusToUpdate?.config?.append_form_data};
              }
              let data:any = {
                  "app_id": applicationData.appId,
                  "user_id": applicationData.userId,
                  "role": backendUserData?.role_slug,
                  "sub_status_id": subStatusToUpdate.id,
                  "status_id": subStatusToUpdate.app_status_id,
                  "backend_user_id": this.backendUserData?.user_id,
                  "status_banker_emails": uniqueStageBankers.map(tup => tup.email_address).filter((elem, index, self) => {
                    return index === self.indexOf(elem);
                  }),
                  "status_banker_names": uniqueStageBankers.map(tup => tup.name).filter((elem, index, self) => {
                    return index === self.indexOf(elem);
                  }),
                  "loan_id": applicationData.loan_id,
                  "business_name": businessData.business_name || "-",
                  "action_from": "backend",
                  "dateshared": dateTimeZone.transform(new Date(), 'MM-dd-yyyy'),
                  ...this.getMailTempCommonData(),
                };
                if(subStatusToUpdate?.config?.body){
                  Object.assign(data, subStatusToUpdate.config.body);
                }
                if (form_data.decline_reason) {
                  if(!Array.isArray(form_data.decline_reason)){
                    form_data.decline_reason = [form_data.decline_reason];
                  }
                  if (form_data.decline_reason.includes("Other")) {
                    form_data.decline_reason[form_data.decline_reason.indexOf("Other")] = "Other - " + form_data.other_decline_reason;
                  }
                  data['note'] = Array.isArray(form_data.decline_reason) ? form_data.decline_reason.join(",&nbsp") : form_data.decline_reason;
                  data['user_name'] = 'System';
                  data['activity'] = subStatusToUpdate?.config?.isAppHardDecline ? 'Application_HARD_DECLINED':'Application_SOFT_DECLINED';
                }
                else if (form_data.withdraw_reason) {
                  if(!Array.isArray(form_data.withdraw_reason)){
                    form_data.withdraw_reason = [form_data.withdraw_reason];
                  }
                  if (form_data.withdraw_reason.includes("Other")) {
                    form_data.withdraw_reason[form_data.withdraw_reason.indexOf("Other")] = "Other - " + form_data.other_withdraw_reason;
                  }
                  data['note'] = Array.isArray(form_data.withdraw_reason) ? form_data.withdraw_reason.join(",&nbsp") : form_data.withdraw_reason;
                  data['user_name'] = 'System';
                  data['activity'] = subStatusToUpdate?.config?.isAppHardWithdrawn ? 'Application_HARD_WITHDRAWN':'app_soft_withdrawn';
                }
                let pushNextStageBankersApi = of(null);
                let isNextStageBankerAssigned =  appData?.app_assignment?.find(assign=>(assign.stage == subStatusToUpdate?.config?.next_stage_id && assign.assigned_to));
                if(subStatusToUpdate?.config?.next_sub_status_id){
                  if(subStatusToUpdate?.config?.update_next_sub_status_on_assignment){
                    if(isNextStageBankerAssigned){
                      data["sub_status_id"] = subStatusToUpdate?.config?.next_sub_status_id;
                    }
                  }else{
                    data["sub_status_id"] = subStatusToUpdate?.config?.next_sub_status_id;
                  }
                }
  
                if(subStatusToUpdate?.config?.next_status_id){
                  if(subStatusToUpdate?.config?.update_next_status_on_assignment){
                    if(isNextStageBankerAssigned){
                      data["status_id"] = subStatusToUpdate?.config?.next_status_id;
                    }
                  }else{
                    data["status_id"] = subStatusToUpdate?.config?.next_status_id;
                  }
                }
                
                
  
                pushNextStageBankersApi = this.checkNextStageMovement(appData, subStatusToUpdate, data);
  
                return this.validateChecklist(applicationData, subStatusToUpdate).pipe(
                  switchMap((res_data)=>{
                    if(!res_data.error){
                      return pushNextStageBankersApi;
                    }
                    return of(res_data);
                  }),
                  switchMap((res_data:any)=>{
                    if(!res_data?.error){
                      if(subStatusToUpdate?.config?.letter_type) {
                        // applicationData.decline_reason = form_data?.decline_reason;
                        // applicationData.withdraw_reason = form_data?.withdraw_reason;
                        return this.generateUploadLetterService.uploadLetters(form_data, subStatusToUpdate?.config, dateTimeZone, applicationData);
                      }
                    }
                    return of(res_data);
                  }),
                  switchMap((res: any)=>{
                    if(!res?.error){
                      return this.taskInfoService.saveTaskInfo({ slug: 'loan-sub-status' }, data).pipe(
                        map((response)=>{
                          if(!response.error){
                            this.taskTrackerService.taskCompleted(applicationData.appId, "update_status")
                            let sub_status_value = (subStatusToUpdate?.config?.log_actual_sub_status) ? (this.workflowActionsService.app_sub_status?.find(sub_status=>sub_status.id === data.sub_status_id)?.value || subStatusToUpdate?.value):(subStatusToUpdate?.value);
                            this.addActivityLog(applicationData, 'application_status_updated', sub_status_value);
                          }
                          return response;
                        }),
                        switchMap((response)=>{
                          if(!response.error && form_data.note){
                            let bankerDetails:any=this.workflowActionsService.getBankerData(applicationData?.app_assignment, applicationData?.stage_id,'all_assigned_banker');
                            let payload = {
                              note: form_data.note,
                              app_id: applicationData?.appId,
                              backend_user_id: this.backendUserData?.user_id,
                              type: 'case_note',
                              header_logo_path_1: bankerDetails?.header_logo_path_1,
                              senders_name: bankerDetails?.senders_name,
                              copyright_text: bankerDetails?.copyright_text,
                              client_name: bankerDetails?.client_name,
                              siteurl: bankerDetails?.banker_siteurl,
                              loan_id: applicationData.loan_id,
                              to: bankerDetails?.to,
                              to_name:bankerDetails?.to_name,
                              privacy: bankerDetails?.privacy,
                              terms: bankerDetails?.terms
                            };
                            return this.taskInfoService.saveTaskInfo({ slug: "get_notes" }, payload).pipe(
                              map((_response) => {
                                if (_response?.create_loan_notes?.data?.code === 201) {
                                  this.common.popToast('success', '', this.translate.instant('loan_actions.toasts.note_success'));
                                  this.addActivityLog(applicationData, 'notes_added');
                                  this.manageLoansService.notesAdded(true);
                                }
                                return response;
                              }));
                          }else{
                            return of(response);
                          }
                        }),
                        switchMap((response: any)=>{
                            if(!response.error && subStatusToUpdate?.config?.task_after_status_update){
                              let body = {
                                "app_id": applicationData.appId,
                                "user_id": applicationData.userId,
                                "auto_id": applicationData.loan_id,
                                ...(subStatusToUpdate?.config?.task_after_status_update?.body || {})
                              };
                              let query = {
                                "app_id": applicationData.appId,
                                "user_id": applicationData.userId,
                                "business_id":businessData._id,
                                ...(subStatusToUpdate?.config?.task_after_status_update?.query || {})
                              };
                              return this.taskInfoService.saveTaskInfo(query, body);
                            }else{
                              return of(response);
                            }
                        })
                      );
                    } else return of(res);
                  }))
          }else{
            this.common.popToast('error','',this.translate.instant('loan_actions.toasts.action_not_allowed'));
            return throwError(res);
          }
        }),
        switchMap((res: any)=>{
          if(!res.error){
            return this.ecoaService.checkEcoaActions(subStatusToUpdate?.config, applicationData);
          }
          return of(res);
        }),
        map((res)=>{
          if(typeof res == "object"){
            return {...res, evaluatedValues};
          }
          return res;
        })
      );
    }

    checkNextStageMovement(appData, currentSubStatus, data){
        let pushNextStageBankersApi = of(null);
        let subStatusConfig = currentSubStatus?.config;
        if (subStatusConfig?.next_stage_id) {
            data["next_stage_id"] = subStatusConfig.next_stage_id;
            data["unassign_stage"] =  data["next_stage_id"];      
            if(subStatusConfig?.next_stage_role_id){
              data["next_role_id"] = subStatusConfig?.next_stage_role_id;
              data["unassign_stage_role_id"] =  data["next_stage_id"];
            }
            let assignedUser;
            let stageIdOrder = {};
            let all_stages = this.workflowActionsService.getAllStages();
            for(let stage of all_stages){ 
              stageIdOrder[stage.id] = stage.order;
            }
            let ordered_app_assignment = (appData.app_assignment || []).sort((ob1, ob2)=>stageIdOrder[ob1.stage] - stageIdOrder[ob2.stage]);
            if(subStatusConfig?.existing_user_assigment?.chronological_order?.length){
              let chronological_order = subStatusConfig.existing_user_assigment.chronological_order;
              //finding user from assignment history
              pushNextStageBankersApi = from(chronological_order).pipe(
              concatMap((user_from_config)=>{
                return this.getMatchedUserAssignment({stageIdOrder,ordered_app_assignment}, appData, subStatusConfig, user_from_config);
              }),
              first((_assignedUser)=>!!_assignedUser, null),
              switchMap((_assignedUser)=>{
                assignedUser = _assignedUser;
                if(assignedUser){
                  if(subStatusConfig?.next_status_id){
                    data["status_id"] = subStatusConfig?.next_status_id;
                  }
                  if(subStatusConfig?.next_sub_status_id){
                    data["sub_status_id"] = subStatusConfig?.next_sub_status_id;
                  }
                  data["assigned_to"] = assignedUser.assigned_to;
                  //fetching assigned user for assignment mail
                  return this.getBankerbyIdsMap([data["assigned_to"]]).pipe(map(bankerMap=>{
                    if(bankerMap){
                        let banker_emails = [];
                        let banker_names = [];
                        for(let id in bankerMap){
                            banker_emails.push(bankerMap[id].email_address);
                            banker_names.push(bankerMap[id].name);
                        }
                        data["next_role_id"] = bankerMap[data["assigned_to"]]?.role_id;
                        data["unassign_stage_role_id"] = data["next_role_id"];
                
                        data.banker_emails = banker_emails;
                        data.banker_names = banker_names;
                    }
                    return bankerMap;
                  }));
                }else{
                  //completed all userChecks fallback condition
                  //fetching users of  next role_id for assignment mail
                  if(data["next_role_id"]){
                    return this.pushBankersByRole(data["next_role_id"], data);
                  }
                }
                return of(null);
              }));
            }else{
              if(data["next_role_id"]){
                pushNextStageBankersApi = this.pushBankersByRole(data["next_role_id"], data);
              }
            }
            pushNextStageBankersApi = pushNextStageBankersApi.pipe(
              map(()=>{
                if(data["next_role_id"]){
                  //finding next stage user to unassign
                  let unassignUserNextStage;
                  for(let assign of ordered_app_assignment){
                    if(assign.stage == data["next_stage_id"]){
                      unassignUserNextStage = assign;
                    }
                  }
                  if(unassignUserNextStage){
                    data["unassign_stage_role_id"] = unassignUserNextStage["role_id"];
                  }
                }
                return data;
              }));

          }
          return pushNextStageBankersApi;
    }

    getMatchedUserAssignment({stageIdOrder,ordered_app_assignment}, appData, subStatusConfig, user_from_config){
      let {match, check_stages} = user_from_config;
      let currentStageOrder = stageIdOrder[appData.stage_id];
      let nextStageOrder = stageIdOrder[subStatusConfig.next_stage_id];
      return this.getMatchParams(match, subStatusConfig).pipe(map((matchParams)=>{
        if(!matchParams){
          return null;
        }
        let keyStageUserMap = {};
        for(let assign of ordered_app_assignment){
          let stage_order = stageIdOrder[assign.stage];
          if(Object.keys(matchParams)?.every(key=>Array.isArray(matchParams[key])?matchParams[key].includes(assign[key]):assign[key] == matchParams[key]) && assign.assigned_to){
            keyStageUserMap["any"] = assign;
            if(stage_order == currentStageOrder){
              keyStageUserMap["current"] = assign;
            }else if(stage_order == nextStageOrder){
              keyStageUserMap["next"] = assign;
            }else if(stage_order < nextStageOrder){
              keyStageUserMap["prev"] = assign;
            }
          }
        }
  
        if(check_stages?.length){
          for(let stage_key of check_stages?.split(",")){
            if(keyStageUserMap[stage_key]){
              return keyStageUserMap[stage_key];
            }
          }
          return null;
        }
        return keyStageUserMap["any"];
      }));
    }

    getMatchParams(match, subStatusConfig){
      if(match && Object.keys(match)?.length){
        return from(Object.keys(match)).pipe(
          switchMap((key)=>{
            switch(match[key]){
              case "next_stage_allowed_roles": 
                return this.getStageAllowedRoleIds(subStatusConfig.next_stage_id).pipe(
                  map((value)=>{
                    return {key, value};
                  })
                );
              default:
                if(subStatusConfig && subStatusConfig.hasOwnProperty(match[key])){
                  return of({key, value:subStatusConfig?.[match[key]]});
                }else{
                  return of({key, value:match[key]});
                }
            }
          }),
          reduce((matchParams, param)=>{
            matchParams[param.key] = param.value;
            return matchParams;
          },{})
        );
      }
      return of(null);
    }

    pushBankersByRole(role_id, data){
        return this.taskInfoService.getTaskInfo({slug:"banker_by_role_id", role_id}).pipe(
            map((res)=>{
              if(res?.response_data?.get_banker_by_role?.data?.data?.length){
                let next_stage_bankers = res?.response_data?.get_banker_by_role?.data?.data;
                data.banker_emails =  next_stage_bankers.map(tup => tup.email_address);
                data.banker_names = next_stage_bankers.map(tup => tup.name);
              }else{
                this.common.popToast("error","", "Next stage bankers not Available");
                throw res;
              }
        }));
    }

    getStageAllowedRoleIds(stage_id){
      return this.taskInfoService.getTaskInfo({slug: "stage_permissions", stage_id: stage_id}).pipe(
        map(response=>{
          let role_ids = response.response_data.assign_stage.data.data?.map(d=> {
            return d.role_id
          });
          role_ids  = Array.from(new Set(role_ids));
          return role_ids;
        })
      )
    }

    getBankerbyIdsMap(banker_user_ids) {
        const uniqueIds = new Set(banker_user_ids);
        let bankerMap = {};
        if (uniqueIds.size) {
          return this.taskInfoService.saveTaskInfo({ slug: 'banker-by-ids' }, { user_ids: Array.from(uniqueIds.values()) }).pipe(map((res) => {
            const bankers = _.get(res, 'get_banker_by_ids.data.data') || [];
            bankers.forEach((bu) => {
              if (!bankerMap[bu.id]) {
                bankerMap[bu.id] = bu;
              }
            });
            return bankerMap;
          }));
        }
        return of(null);
    }

    getBankerName(app_assignment) {

      const uniqueNames = [];
  
      app_assignment.forEach((b) => {
        if (b.stage) {
          const index = uniqueNames.findIndex((un) => un.stage === b.stage);
          if (index !== -1) {
            uniqueNames[index] = b;
          } else {
            uniqueNames.push(b);
          }
        } else {
          uniqueNames.push(b);
        }
      })
  
      return uniqueNames.filter(ob=>ob.assigned_to);
    }

    getMailTempCommonData(){
      return {
        banker_siteurl: this.environment.banker_url,
        header_logo_path_1: this.environment.logo1_path,
        header_logo_path_2: this.environment.logo1_path,
        client_name: this.CONSTANTS.MAIL_TEMPLATE.project_name,
        senders_name: this.CONSTANTS.MAIL_TEMPLATE.senders_name,
        copyright_text: this.CONSTANTS.MAIL_TEMPLATE.copyright_text,
        terms: this.environment.terms,
        privacy: this.environment.privacy
      }
    }

    validateChecklist(applicationData, subStatusToUpdate){
      let validate_checklist = subStatusToUpdate?.config?.validate_checklist;
      if(!validate_checklist){
        return of({error: false});
      }
      return this.taskInfoService.getTaskInfo({
        slug: 'checklist',
        master_data: true,
        get_checklist:true,
        stage: applicationData.stage_id,
        app_id: applicationData.appId,
        user_id: applicationData.userId
      })
      .pipe(switchMap((checklist_res) => {
          const checklistResult = this.checklistCheck(checklist_res?.response_data, applicationData);
          // && (subStatusToUpdate?.config?.next_status_id || subStatusToUpdate?.config?.next_sub_status_id || subStatusToUpdate?.config?.next_stage_id)
          if(!checklistResult.nextStageCheck){
            return of({error: true, checkListNotUpdated: true,
              message: this.CONSTANTS?.CHECKLIST_DOCUMENT_MESSAGE?.['checklist_error']
            })
          }
          return of({error: false});
        })
      );
    }

    private checklistCheck(res, applicationData) {
      let checklistMasterList = _.get(res, 'get_master_checklist.data.data').find(el => el.stage == applicationData?.stage_id)?.checklist || [];
      let mandatoryChecklistIds = [];
      checklistMasterList = checklistMasterList.filter(el => el.mandatory === true);
      if (checklistMasterList && checklistMasterList.length) {
        mandatoryChecklistIds = checklistMasterList.map(el => { return el.checklist_id });
        // checklistMasterList = checklistMasterList.filter(res=>res.product.includes(this.applicationData.businessCategoryId));
        let stageSaveChecklist = _.get(res, 'get_checklist.data.data[0].checklist');
        if (!stageSaveChecklist) {
          return { nextStageCheck: false };
        }
        let stageProduct = _.get(res, 'get_checklist.data.data[0].product');
        // if(stageProduct !== this.applicationData.businessCategoryId){
        //   return {nextStageCheck: false} //checking product save in db is equal to current product
        // }
        let IncompleteChecklist = stageSaveChecklist.find(checklist => mandatoryChecklistIds.includes(checklist.checklist_id) && checklist.checklist_value === null);
        if (IncompleteChecklist) {
          return { nextStageCheck: false };
        }
      }
      return { nextStageCheck: true };
    }
    //checking status of document for current stage
    documentCheck(res) {
      let documentMasterList = _.get(res, 'document_type_post.data.data');
      if (documentMasterList && documentMasterList.length) {
        //for filter out parent components , we dont show them on banker portal
        documentMasterList = documentMasterList.filter(doc => doc.key !== 'documents_credit_approval' && doc.key !== 'documents_loan_closing');
        let documentNotes = _.get(res, 'get_notes.data.data');
        if (!documentNotes) {
          return { nextStageCheck: false };
        }
        let documentKeys = documentNotes.map(doc => doc.doc_type_key);
        for (let i = 0; i < documentMasterList.length; i++) {
          if (!documentKeys.includes(documentMasterList[i].key)) {
            return { nextStageCheck: false };
          }
        }
      }
      return { nextStageCheck: true }
    }

    
  addActivityLog(applicationData, activity, note?, user_name?) {
    let log_data;
    if (activity === 'ecoa_reset' || activity === 'ecoa_stop') {
      log_data = {
        role_slug: this.backendUserData?.role_slug,
        app_id: applicationData.appId,
        backend_user_id: this.backendUserData?.user_id,
        user_name: 'system',
        activity: activity
      };
    } else {
      log_data = {
        role_slug: this.backendUserData?.role_slug,
        app_id: applicationData.appId,
        backend_user_id: this.backendUserData?.user_id,
        user_name: user_name || this.backendUserData?.full_name || 'system',
        activity: activity
      };
    }
    if (note) {
      log_data['note'] = Array.isArray(note) ? note.join(", ") : note;
    }
    this.common.addActivityLog(log_data);
  }
}