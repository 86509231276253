<ng-container [formGroup]="group">
    <label [ngClass]="(field?.css?.labelClass)? field?.css?.labelClass :'fs14 fwsb' " *ngIf="slug + '.label.' + field.name | translate"
        [for]="field.name + '_' + indexVal" [innerHTML]='slug + ".label." + field.name | translate'></label>
    <ng-container *ngIf="field?.css?.tooltip && field?.css?.tooltip[field.name]">
        <a href="javascript:void(0)" [tooltip]="popTemplateth"
            [triggers]="(field?.css?.tooltip[field.name+ '_trigger'])?field?.css?.tooltip[field.name+ '_trigger']:'hover'"
            [ngClass]="field?.css?.tooltip[field.name]?field?.css?.tooltip[field.name]:'ml-1'">
            <img src="{{environment?.asset_path}}images/info-icon-n.svg" alt="tooltip" class="infoimg" />
        </a>
        <ng-template #popTemplateth>
            <div class="fs12 text-left fwr my-1 gtColor" [innerHTML]='slug + ".tooltip."+ field.name | translate'></div>
        </ng-template>
    </ng-container>
    <div [ngClass]="field?.css && field?.css?.childdiv?field.css.childdiv:'form-group'">
        <textarea [placeholder]="slug + '.placeholder.' + field.name | translate"
            [ngClass]="(field?.css?.field)? field?.css?.field :'form-control' " [formControlName]="field.name"
            [maxlength]="(field?.format?.maxlength)?field.format.maxlength:null"
            [id]="field.name + '_' + indexVal" style="min-height: 127px;"  [readonly]="field?.readonly"></textarea>

        <div *ngIf="group.get(field.name).invalid && (group.get(field.name).dirty || group.get(field.name).touched)"
            class="error-msg">
            <ng-container *ngFor="let validation of field.validations;">
                <label class="error" *ngIf="group.get(field.name).hasError(validation.name)">
                    {{slug + ".errorMessage." + field.name + "." + validation.name | translate}}
                </label>
            </ng-container>
        </div>
    </div>
</ng-container>
