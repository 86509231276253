import { Component, OnInit, ChangeDetectorRef, OnDestroy, Inject } from '@angular/core';
import { TaskInfoService, CommonService } from '@rubicon/utils';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
@Component({
  selector: 'application-progress',
  templateUrl: './application-progress.component.html',
  styleUrls: ['./application-progress.component.scss']
})
export class ApplicationProgressComponent implements OnInit, OnDestroy {
  businessItemList = [];
  mainBusinessItemList= [];
  step_not_required: string[];
  step:number = 0;
  stepSubscription: Subscription
  sba_initiated_by : string;
  maxStep: any = 0;

  constructor(public taskInfoService: TaskInfoService,
              public commonService: CommonService,
              public changeDetectionRef: ChangeDetectorRef,
              public store: Store<any>,
              @Inject('environment') public environment,
              @Inject('CONSTANTS') public CONSTANTS) { }

  ngOnInit(): void {
    (sessionStorage.getItem("maxStep")) ? this.maxStep = sessionStorage.getItem("maxStep") : '';
    this.store.select('app').subscribe((rootState) => {
      if(this.environment.journeyType === 'banker-journey'){
        this.step_not_required = rootState?.appData?.step_not_required ;
        this.sba_initiated_by = rootState?.appData?.sba_initiated_by;
      }else{
        this.step_not_required = rootState?.userData?.step_not_required
      }
       
      if (this.step_not_required) {
        this.businessItemList = this.mainBusinessItemList.filter(val => !this.step_not_required.includes( val.name ))
      }
    })
  }

  ngAfterViewInit() {
    this.stepSubscription = this.commonService.updatedStep
      .subscribe(data => {
        this.step = data;
        (this.step > this.maxStep) ? (this.maxStep = this.step) && sessionStorage.setItem("maxStep", this.step.toString()) : this.maxStep;
        if(this.step !== 0 && this.businessItemList.length === 0){
          this.taskInfoService.getTaskInfo({slug:'application-progress'}).subscribe(response => {
            this.mainBusinessItemList = response.response_data.progress_list.data.data;
            this.businessItemList =  [...this.mainBusinessItemList];
            if(!this.step_not_required || this.sba_initiated_by === "banker") {
              this.businessItemList = this.mainBusinessItemList.filter(val => val.display === 1)
            } else if (this.step_not_required.length > 0) {
              this.businessItemList = this.mainBusinessItemList.filter(val => !this.step_not_required.includes( val.name ))
            }
          })
        }
        this.changeDetectionRef.detectChanges();
      });
  }

  ngOnDestroy() {
    this.stepSubscription.unsubscribe();
  }

  clickable(step, item) {    
    if(!this.maxStep) return false;
    (step > this.maxStep) ? (this.maxStep = step) && sessionStorage.setItem("maxStep", step) : this.maxStep;
    if(item.step <= this.maxStep) this.commonService.navigate(Object.keys(this.CONSTANTS.APP_STEP).find(e=>this.CONSTANTS.APP_STEP[e]===item.step))
  }

}
