import { Component, EventEmitter, Input, OnInit, Output, Inject, ViewChild, ElementRef,TemplateRef, OnDestroy } from '@angular/core';
import { CommonService, FormGenerateService, MasterDataPipe, TaskInfoService } from '@rubicon/utils';
import { FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { take, switchMap, map, catchError, takeUntil } from 'rxjs/operators';
import { ManageLoansService } from '../services/manage-loans.service';
import { forkJoin, Observable, of, ReplaySubject, Subscription, throwError } from 'rxjs';
import * as  _ from 'lodash';
import { DeclineLetterService } from '../services/decline-letter.service';
import { DateTimezonePipe } from '@rubicon/libraries/core-utils/shared-lazy';

import { AccessValidatorService, conditionHelperService } from '../../../../../core-utils/biz2xPro-config-engine';
import { UpdateStatusService } from '../services/update-status.service';
import { EcoaService } from '../services/ecoa.service';
import { TranslateService } from '@ngx-translate/core';
import { GenerateUploadLetterService } from '../services/generate-upload-letter.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { WorkflowActionsService } from '../services/workflow-actions.service';
import { TaskTrackerService } from '../services/task-tracker.service';
import { ActionResolveService, TypeKeyActionStatus } from '../services/action-resolve.service';
import { AssignmentService } from '../services/asssignment.service';

@Component({
  selector: 'loan-actions',
  templateUrl: './loan-actions.component.html',
  styleUrls: ['./loan-actions.component.scss']
})
export class LoanActionsComponent implements OnInit, OnDestroy {
  
  @ViewChild("stageConfirmModalTemplate") stageConfirmModalTemplate: TemplateRef<any>;
  @ViewChild('declineLetter') declineLetterTemplate: ElementRef<any>;
  @Input() applicationData: any;
  rootStateSub: Subscription;
  actionType: string;
  @Output() closeModal = new EventEmitter<boolean>();
  actionConfig = [];
  actionForm: FormGroup;
  slug: string;
  backend_user_id: string;
  showAlert = false;
  caf_generated = false;
  backendUserData: any;
  selectListData: any = [];
  statusData: any = [];
  product_category: any = [];
  states = [];
  actionDestroyed$;
  // author_name: string;
  // appData: any = null;
  // productData: any;
  // currentProduct: any;
  // stage_data = [];
  // current_stage: any;
  // backendUsers: any;
  // businessData: any;
  // assigneeTitle;
  // current_risk: any;
  // documentation_prepared_by: any;
  // payment_stream_type: any;
  // pmt_frequency: any;
  // product_type: any;
  // business_structure: any;
  // collateral: any;
  // loan_purpose: any;
  // currentStageAllowed: boolean;

  // banker_name: string;
  // banker_email: string;
  customer_name:string;
  leadInfo: any;
  action_allowed: boolean;
  modalTitle;
  alert_message = "You do not have permission to perform this action.";
  all_stages: any;
  role_stage_mapping: any;
  stageConfirmModalRef;
  stageIdForChangeBanker;
  stageIdOrderMap = {};
  allowedStageIds: any[];
  current_workflow_action: any;
  changeBankerDestroy$ = new ReplaySubject();
  constructor(
    private common: CommonService,
    private taskInfoService: TaskInfoService,
    private formGenerate: FormGenerateService,
    private manageLoansService: ManageLoansService,
    private store: Store<any>,
    private dateTimeZone: DateTimezonePipe,
    private masterDataPipe: MasterDataPipe,
    @Inject('environment') private environment,
    @Inject('CONSTANTS') public CONSTANTS,
    // private cafService: CafService,
    private declineLetterService: DeclineLetterService,
    private accessValidatorService: AccessValidatorService,
    private updateStatusService: UpdateStatusService,
    private ecoaService: EcoaService,
    public translate: TranslateService,
    private generateUploadLetterService: GenerateUploadLetterService,
    public modalService: BsModalService,
    public workflowActionsService: WorkflowActionsService,
    private taskTrackerService: TaskTrackerService,
    private actionResolveService: ActionResolveService,
    private assignmentService : AssignmentService
  ) {
  }

  ngOnInit(): void {
    /* EXample to use accessValidatorService
      let app_data = {
        status_id: '5c20bf7e27105c78ad7f9289',
        stage_id: '62dfeea75ff851001195a316'
      }
      if(this.accessValidatorService.checkAccessValidate(app_data, 'validateAccess','62d9582b01276d47984187a0')) {
        console.log(true);
      } else {
        console.log(false);
      }
    */

    this.actionType = this.applicationData.actionType;
    this.modalTitle = this.actionType;
    // this.current_stage = this.applicationData.currentStage;
    this.rootStateSub = this.store.pipe(select('app'), take(1)).subscribe(rootState => {
      this.backend_user_id = rootState.userData.user_id;
      this.backendUserData = rootState.userData;
      // this.author_name = rootState.userData.full_name;
      // this.banker_name = rootState?.userData?.full_name;
      // this.banker_email=rootState?.userData?.email_address;
    })

    let slugData = {
      slug: this.CONSTANTS.SLUG['loan-actions-data'],
      app_id: this.applicationData.appId
    }
    this.taskInfoService.getTaskInfo(slugData).subscribe(res => {
      const businessData = res?.response_data?.application_detail?.data?.data[0]?.business[0];
      const app_data = res?.response_data?.application_detail?.data?.data[0];
      const user_assignments = this.manageLoansService.getUserAssignments(app_data, this.applicationData?.bankerMap);
      let primaryOwnerData = this.primary_owner(app_data);
      this.applicationData = {
        ...this.applicationData,
        loan_id: app_data.auto_id,
        stage_id: app_data.stage_id,
        status_id: app_data.status_id,
        sub_status_id: app_data.sub_status_id,
        businessId: businessData._id,
        business: businessData,
        owners: app_data.owners,
        businessCategoryId: businessData?.product,
        business_structure: businessData?.business_structure,
        app_activity: app_data?.app_activity,
        app_assignment: user_assignments,
        step_not_required: businessData.step_not_required || [],
        business_references: app_data?.business_references,
        owner_data: app_data?.owner_data,
        primary_owner_name: primaryOwnerData?.name || undefined,
        business_address: {
          name: businessData?.business_name,
          street_no: businessData?.street_no,
          street_name: businessData?.street_name,
          city: businessData?.city,
          state: businessData?.state,
          zip_code: businessData?.zip_code
        }
      }
      this.all_stages =  _.clone(this.backendUserData?.workflowInfo?.stages);
      this.role_stage_mapping = _.clone(this.backendUserData?.role_stage_mapping);
      this.current_workflow_action = this.applicationData?.workflow_action;
      this.all_stages?.forEach((stage: any) => {
        if(stage?.order || stage?.order==0)
          this.stageIdOrderMap[stage.id] = stage.order
      })
      this.allowedStageIds = Array.from(new Set(this.role_stage_mapping?.map(stage => stage?.stage_id)));
      this.allowedStageIds?.sort((a: any, b: any) => { return this.stageIdOrderMap[a] - this.stageIdOrderMap[b] });
      this.action_allowed = this.accessValidatorService.checkAccessValidate(this.applicationData, this.current_workflow_action?.config?.validate_type , this.current_workflow_action) || false;
      if(this.action_allowed){
        this.getActionData();
      }
    })
    // const currentStageBanker = this.applicationData.app_assignment?.find((item) => item.stage === this.current_stage.type)?.backend_user_id;
    // this.currentStageAllowed = true; //currentStageBanker === this.backendUserData.user_id && isStageActionAllowed(this.current_stage.type, this.backendUserData.role_slug);
  }

  getActionData(){
    let slugData: any = {};
    switch (this.actionType) {
      case 'Update Status':
        this.getSubStatusData();
        return;
        // slugData = { slug: this.CONSTANTS.SLUG['loan-sub-status'], visible: true, stage: this.current_stage.type };
        break;
      case 'Add Note':
        slugData = {
          slug: this.CONSTANTS.SLUG['get_notes']
        };
        break;
      case 'Connect To Customer':
          slugData = {
            slug: this.CONSTANTS.SLUG['connect_banker_customer'],
            user_id: this.applicationData.userId,
            app_id: this.applicationData.appId,
            source:"banker"
          };
          break;
      case 'Update Loan Product':
        slugData = {
          slug: this.CONSTANTS.SLUG['update_product'],
          app_id: this.applicationData.appId
        };
        break;
      case 'Change Banker':
        let paramsStageId = this.applicationData.stage_id;
        let stageOrder = this.stageIdOrderMap[this.applicationData?.stage_id];
        this.allowedStageIds?.every(stage_id => {
          let stageDataOrder = this.stageIdOrderMap[stage_id];
          if (stageOrder === stageDataOrder) {
            paramsStageId = stage_id;
            return false;
          } else {
            if (stageOrder > stageDataOrder){
              paramsStageId = stage_id;
            }
            return true;
          }
        //   else if (stageOrder > stageDataOrder){
        //     paramsStageId = stage_id;
        //     return true;
        //   } 
        //   else if (stageOrder < stageDataOrder) {
        //     let nextAssignedData = this.applicationData?.app_assignment.find(({stage}) => stage === stage_id);
        //     if(nextAssignedData?.assigned_to) {
        //       paramsStageId = stage_id;
        //     }
        //     return true;
        //   }
        })
        
        this.stageIdForChangeBanker = paramsStageId;
        slugData = { slug: this.CONSTANTS.SLUG['assign_to'], stage_id: paramsStageId}
        break;
    }

    if (slugData.slug) {
      this.taskInfoService.getTaskInfo(slugData).subscribe(response => {
        this.actionConfig = response.form_fields;
        this.slug = response.task_slug;
        this.actionForm = this.formGenerate.createControl(this.actionConfig);
        if (this.actionType === 'Update Loan Product') {
          const currentProduct = response?.response_data?.get_products?.data?.data[0];
          const productData = response?.response_data?.product_category.data.data;
          this.common.sendMasterDataToFields(this.actionConfig, response.response_data);
          this.common.sendMasterDataToFields(this.actionConfig, {}, 'products_type');
          this.formGenerate.setFormValues(this.actionForm, { product_category: currentProduct?.sub_product_type });
          this.showAlertMessage();
        }
        else if (this.actionType === 'Change Banker') {
          this.setBankerList(response, slugData?.stage_id);
        }
        else if (this.actionType === 'Connect To Customer'){
          this.leadInfo=response ?.response_data ?.get_user ?.data ?.data;
          this.customer_name= [(this.leadInfo?.first_name || ''), (this.leadInfo?.last_name || '')].join(' ');
        }
      })
    }
  }

  getSubStatusData(){
    this.slug = "loan-sub-status";
        let slugResponse:any;
        let model = "app_status,app_sub_status,decline_reasons,app_withdrawn_reasons,states";
        this.taskInfoService.getTaskInfo({slug: this.slug, model}).pipe(
          switchMap((res)=>{
           slugResponse = res;
           let master_data_optimised = res?.response_data?.master_data_optimised?.data?.data;
            if(master_data_optimised){
              return this.updateStatusService.getSubStatuses(this.applicationData, master_data_optimised?.app_sub_status || []);
            }else{
              throw res;
            }
          })
        ).subscribe((sub_statuses)=>{
          let master_data_optimised = slugResponse?.response_data?.master_data_optimised?.data?.data;
          if(!sub_statuses?.length){
            this.alert_message = "No statuses available to update.";
            return;
          }
          if(master_data_optimised){
            this.statusData = sub_statuses;
            this.actionConfig = slugResponse.form_fields;
            this.actionForm = this.formGenerate.createControl(this.actionConfig);
            let app_soft_withdrawn_status = (master_data_optimised?.app_status || []).find(status=>status.type == "app_soft_withdrawn");
            let application_soft_decline_status = (master_data_optimised?.app_status || []).find(status=>status.type == "application_soft_decline");
            let send_master_data:any = {};
            master_data_optimised.app_sub_status = sub_statuses || [];
            for(let key of model.split(",")){
              send_master_data[key] = {
                data:{
                  data:master_data_optimised[key] || []
                }
              };
            }
            this.common.sendMasterDataToFields(this.actionConfig, send_master_data);
            const formData = { sub_status_id: this.applicationData?.sub_status_id };
            const withdraw_reasons = master_data_optimised.app_withdrawn_reasons || [];
            this.states = master_data_optimised.states || [];
            if (this.applicationData?.status_id === app_soft_withdrawn_status.id) {
              let withdraw_note = this.applicationData.app_activity[this.applicationData?.app_activity.map(item => item?.hasOwnProperty("note") && item.status_id === app_soft_withdrawn_status?.id)?.lastIndexOf(true)]?.note;
              if (withdraw_note) {
                if (withdraw_reasons.some(masterData => masterData?.value === withdraw_note)) {
                  formData['withdraw_reason'] = withdraw_note;
                }
                else {
                  formData['withdraw_reason'] = 'Other';
                  formData['other_withdraw_reason'] = withdraw_note;
                }
              }
            }
            this.formGenerate.setFormValues(this.actionForm, formData);
            if (this.applicationData?.status_id === application_soft_decline_status.id) {
              const note = this.applicationData.app_activity[this.applicationData?.app_activity.map(item => item?.hasOwnProperty("note") && item.status_id === application_soft_decline_status.id)?.lastIndexOf(true)]?.note;
              const decline_reason = (note?.includes(',&nbsp')) ? (note?.split(",&nbsp")) : [note];
              this.actionForm.patchValue({ decline_reason: decline_reason });
            }
          }
        });
  }

  setBankerList(response: any, stage_id) {
    let role_id = response.response_data.assign_stage.data.data?.map(d=> {
      return d.role_id
    });
    role_id = Array.from(new Set(role_id)).join(",");
    let slugData = {slug:"banker_by_role_id", role_id};
    const bankerFilterCondition = this.current_workflow_action?.config?.stages?.[stage_id]?.bankerFilters;
    if(bankerFilterCondition) {
      for (let i = 0; i < bankerFilterCondition.length; i++) {
        let condition_data = bankerFilterCondition[i];
        let result = conditionHelperService.resolve(this.applicationData, condition_data.conditions);
        if (result.resolved) {
          slugData =  {...slugData, ...condition_data.params};
          break;
        }
      }
    }
    this.taskInfoService.getTaskInfo(slugData).subscribe((res)=>{
        if(res?.response_data?.get_banker_by_role?.data?.data?.length){
          let stage_bankers = res?.response_data?.get_banker_by_role?.data?.data;
          let assignedBanker = this.applicationData.app_assignment?.find((data) => stage_id === data.stage);
          this.selectListData = stage_bankers.map((backend_user) => {
            let isBankerVisible = this.manageLoansService.isBnkrVsblInAsgnLst(backend_user, this.backendUserData);
            let roles_data = {
              role_name: backend_user?.roles?.role_name,
              role_slug: backend_user?.roles?.role_slug,
              banker_name: backend_user.name,
              id: backend_user.id,
              role_id: backend_user?.roles?.id,
              email_address: backend_user.email_address,
              app_assignment: isBankerVisible
            }
            if (backend_user?.email_address === this.backendUserData.email_address) {
              roles_data['banker_name'] = backend_user.name + ' (Assign to me)';
              roles_data['bankerName'] = backend_user.name;
            }
            // if (backend_user.id === this.backendUserData.user_id && assign_to_me) return undefined;
            // else if (this.applicationData.app_assignment.some((data) => data.id === backend_user.id)) return undefined;
            if (backend_user.id === assignedBanker?.id) return undefined;
            return roles_data
          })?.filter(data => data !== undefined && data.app_assignment);
          this.common.sendMasterDataToFields(this.actionConfig, { assign_to: { data: { data: this.selectListData } } });
        }else{
          this.common.popToast("error","", this.translate.instant('loan_actions.toasts.banker_not_avl'));
          throw res;
        }
      })
    /* this.backendUsers = response?.response_data?.get_banker_by_role?.data?.data || [];
    let assign_to_me, app_assignment = this.applicationData?.app_assignment;
    assign_to_me = app_assignment.some((data) => data.id === this.backendUserData.user_id);
    this.selectListData = this.backendUsers.map((backend_user) => {
      let roles_data = {
        role_name: backend_user?.roles?.role_slug === this.CONSTANTS.ROLE_SLUGS.senior_UW ? `${backend_user?.roles?.role_name} ${this.CONSTANTS.UW_BANKER_TYPE[backend_user?.banker_type].default}` : backend_user?.roles?.role_name,
        role_slug: backend_user?.roles?.role_slug,
        banker_name: backend_user.name,
        id: backend_user.id,
        role_id: backend_user?.roles?.id,
        email_address: backend_user.email_address,
      }
      if (backend_user?.email_address === this.backendUserData.email_address) {
        roles_data['banker_name'] = backend_user.name + ' (Assign to me)';
        roles_data['bankerName'] = backend_user.name;
      }
      if (this.current_stage.type == "underwriting") {
        if (backend_user?.roles?.role_slug == "underwriter_l1") {
          let uwConfig = this.CONSTANTS.UW_BANKER_TYPE[backend_user.banker_type];
          if (!uwConfig) {
            return undefined;
          }
          let exposureValue = this.calculateExposureValue(this.businessData?.loan_info_values, this.businessData?.purpose_arr);
          if (!(uwConfig.rangeAmount.min <= exposureValue && exposureValue <= uwConfig.rangeAmount.max)) {
            return undefined;
          }
        }

      }
      if (backend_user.id === this.backendUserData.user_id && assign_to_me) return undefined;
      else if (app_assignment.some((data) => data.id === backend_user.id)) return undefined;
      return roles_data;
    }).filter(data => data !== undefined); */
  }

  // getCurrentStage() {
  //   if (this.applicationData.status_id === this.CONSTANTS.APPLICATION_STATUS.app_soft_withdrawn) {
  //     return throwError({ soft_withdrawn: true, message: this.CONSTANTS.DECLINE_WITHDRAW_MESSAGE.soft_withdrawn })
  //   }
  //   else if (this.applicationData.status_id === this.CONSTANTS.APPLICATION_STATUS.application_soft_decline) {
  //     return throwError({ soft_declined: true, message: this.CONSTANTS.DECLINE_WITHDRAW_MESSAGE.soft_decline })
  //   }
  //   return this.taskInfoService.getTaskInfo({
  //     slug: 'get-current-stage',
  //     app_id: this.applicationData.appId,
  //     user_id: this.applicationData.userId
  //   })
  // }

  showAlertMessage(): void {
    if (!this.applicationData.status_id || this.applicationData.status_id === this.CONSTANTS?.APPLICATION_STATUS?.application_in_progress) {
      this.showAlert = true
    }
  }
  onSubmit() {
    if (this.formGenerate.validateCustomFormFields(this.actionForm, "continue", this.actionConfig)) {
      switch (this.actionType) {
        case 'Update Status':
          if (!this.actionForm.value.sub_status_id) { return; }
          this.openStageConfirmationModal();
          break;
        case 'Add Note':
          this.addLoanNote();
          break;
        case 'Connect To Customer':
            this.onCustomerConnect();
            break;
        case 'Change Banker':
          this.changeBanker();
          break;
      }
    }
  }

  addActivityLog(activity, note?, user_name?) {
    let log_data;
    if (activity === 'ecoa_reset' || activity === 'ecoa_stop') {
      log_data = {
        role_slug: this.backendUserData?.role_slug,
        app_id: this.applicationData.appId,
        backend_user_id: this.backendUserData?.user_id,
        user_name: 'system',
        activity: activity
      };
    } else {
      log_data = {
        role_slug: this.backendUserData?.role_slug,
        app_id: this.applicationData.appId,
        backend_user_id: this.backendUserData?.user_id,
        user_name: user_name || this.backendUserData?.full_name,
        activity: activity
      };
    }
    if (note) {
      log_data['note'] = Array.isArray(note) ? note.join(", ") : note;
    }
    this.common.addActivityLog(log_data);
  }

  addLoanNote() {
    this.action_allowed = this.accessValidatorService.checkAccessValidate(this.applicationData, this.current_workflow_action?.config?.validate_type , this.current_workflow_action);
    if(this.action_allowed && this.current_workflow_action?.type == "add_note"){
      if (this.formGenerate.validateCustomFormFields(this.actionForm, 'continue', this.actionConfig)) {
        let bankerDetails:any=this.workflowActionsService.getBankerData(this.applicationData?.app_assignment,this.applicationData?.stage_id,'all_assigned_banker');
        let payload = {
          note: this.actionForm.value.note,
          app_id: this.applicationData?.appId,
          backend_user_id: this.backend_user_id,
          type: 'case_note',
          header_logo_path_1: bankerDetails?.header_logo_path_1,
          senders_name: bankerDetails?.senders_name,
          copyright_text: bankerDetails?.copyright_text,
          client_name: bankerDetails?.client_name,
          siteurl: bankerDetails?.banker_siteurl,
          loan_id: this.applicationData?.loan_id,
          to: bankerDetails?.to,
          to_name:bankerDetails?.to_name,
          privacy: bankerDetails?.privacy,
          terms: bankerDetails?.terms
        };
        this.taskInfoService.saveTaskInfo({ slug: this.slug }, payload).subscribe(response => {
          if (response?.create_loan_notes?.data?.code === 201) {
            this.common.popToast('success', '', this.translate.instant('loan_actions.toasts.note_success'));
            this.addActivityLog('notes_added');
            this.manageLoansService.notesAdded(true)
            this.closeModal.emit(false);
          }
        })
      }
    } else {
      this.closeModal.emit(false);
      this.common.popToast('error', 'Error', this.translate.instant('loan_actions.toasts.action_not_allowed'));
    }
  }

  onCustomerConnect(){
    this.action_allowed=this.accessValidatorService.checkAccessValidate(this.applicationData, this.current_workflow_action?.config?.validate_type , this.current_workflow_action);
    if(this.action_allowed){
      if(this.formGenerate.validateCustomFormFields(this.actionForm, "continue", this.actionConfig)) {
        let payload = {
          note: this.actionForm.value.comment,
          type: 'connect_banker_customer',
          source: "banker",
          app_id: this.applicationData.appId,
          backend_user_id: this.backend_user_id,
          user_id: undefined,
          role: this.backendUserData?.role,
          role_slug: this.backendUserData?.role_slug,
          loan_officer_id:undefined
        }
        let rmNumber,rmName;
        // const phoneNumber = this.CONSTANTS.SMS_TEMPLATE.ISD_CODE_FOR_SMS_TRIGGER + Number(this.appData['primaryOwnerPhone_no']);
        const phoneNumber = '+1' + Number(this.leadInfo?.phone);
        const smsBody =`Thank you for choosing bank for your financial needs. Your recent query has been responded to by  one of our bankers. Please log onto ${this.environment.customerJourneyUrl} to view the same.`;
        let mail_payload = {
          source: "banker",
          to: this.leadInfo?.email_address ,
          to_name: this.customer_name, //  in 'to' in mail
          first_name: this.leadInfo?.first_name || '',  // name in mail body
          loan_id: this.applicationData?.loan_id,
          lo_name: rmName || 'Banker',
          customer_name: this.customer_name,
          phone_number: rmNumber || '(000) 000-0000',
          siteurl: this.environment?.customerJourneyUrl,
          header_logo_path_1: this.environment.logo1_path,
          header_logo_path_2: this.environment.logo2_path,
          senders_name: this.CONSTANTS.MAIL_TEMPLATE.senders_name,
          copyright_text: this.CONSTANTS.MAIL_TEMPLATE.copyright_text,
          terms: this.environment.terms,
          privacy: this.environment.privacy,
          phoneNumber: [phoneNumber],
          smsBody: smsBody
        };
        this.taskInfoService.saveTaskInfo({slug: "connect_banker_customer"}, {...payload, ...mail_payload}).subscribe((response) => {
          if (response && response.nextTask != "error") {
            this.closeModal.emit(false);
            this.common.popToast("success","", this.translate.instant('loan_actions.toasts.inquiry'));
          }
        });
      }
    }
    else {
      this.closeModal.emit(false);
      this.common.popToast('error', 'Error', this.translate.instant('loan_actions.toasts.action_not_allowed'));
    }
  }

  onCancel() {
    this.closeModal.emit(false);
  }

  // changeBanker() {
  //   const is_current_stage_banker_change = this.stageIdForChangeBanker == this.applicationData?.stage_id;
  //   this.action_allowed = this.accessValidatorService.checkAccessValidate(this.applicationData, this.current_workflow_action?.config?.validate_type , this.current_workflow_action);
  //   if (this.action_allowed && this.current_workflow_action?.type == "change_banker") {
  //     if (!this.actionForm.value.assign_to) { return; }
  //     const assignedToUserData = this.selectListData.find((user) => user.id === this.actionForm.value.assign_to);
  //     if (!assignedToUserData) { return; }
  //     let next_stage_id;
  //     if (this.applicationData.stage_id) {
  //       let currentStageActionConfig = is_current_stage_banker_change ?this.current_workflow_action?.config?.stages?.[this.applicationData?.stage_id]:null; //action config required only when app current stage banker is changing
  //       let extra_assignment_data:any = {};

  //       if(currentStageActionConfig) {
  //         currentStageActionConfig = _.cloneDeep(currentStageActionConfig);
  //         if(currentStageActionConfig?.conditional_resolve_params){
  //           for (let i = 0; i < currentStageActionConfig.conditional_resolve_params.length; i++) {
  //             let condition_data = currentStageActionConfig.conditional_resolve_params[i];
  //             let result = conditionHelperService.resolve(this.applicationData, condition_data.conditions);
  //             if (result.resolved) {
  //               currentStageActionConfig = {...currentStageActionConfig, ...condition_data.params};
  //             }
  //           }
  //         }
  //       }
  //       if(currentStageActionConfig && is_current_stage_banker_change){
  //         if(currentStageActionConfig?.next_stage_id){
  //           next_stage_id = currentStageActionConfig?.next_stage_id;
  //           extra_assignment_data.stage_id = currentStageActionConfig?.next_stage_id;
  //           extra_assignment_data.stage = currentStageActionConfig?.next_stage_id;
  //         }
  //         if(currentStageActionConfig?.status_update_on_no_assignment || currentStageActionConfig?.sub_status_update_on_no_assignment){
  //           let isAnyAssignment = false;
  //           for(let assign of (this.applicationData.app_assignment || [])){
  //             if(this.applicationData?.stage_id === assign.stage && assign.assigned_to){
  //               isAnyAssignment = true;
  //             }
  //           }
  //           if(!isAnyAssignment){
  //             if(currentStageActionConfig?.status_update_on_no_assignment){
  //               extra_assignment_data.status_id = currentStageActionConfig?.status_update_on_no_assignment;
  //             }
  //             if(currentStageActionConfig?.sub_status_update_on_no_assignment){
  //               extra_assignment_data.sub_status_id = currentStageActionConfig?.sub_status_update_on_no_assignment;
  //             }

  //           }

  //         }
  //       }
  //       /* Unassign last banker when moving in same stage */
  //       // let assignedStage = (this.applicationData.app_assignment || []).find((assign: any) => next_stage_id === assign.stage);
        
  //       let assignData: any = {
  //         app_id: this.applicationData.appId,
  //         role: this.backendUserData.role_slug,
  //         assigned_to: assignedToUserData.id,
  //         assignment_date: this.dateTimeZone.transform(new Date(), 'MM-dd-yyyy'),
  //         role_id: assignedToUserData.role_id,
  //         // last_assignee_role_id: assignedStage?.role_id,
  //         backend_user_id: this.backendUserData.user_id,
  //         first_name: assignedToUserData.bankerName ? assignedToUserData.bankerName : assignedToUserData.banker_name,
  //         email_address: assignedToUserData.email_address,
  //         case_id: this.applicationData.loan_id,
  //         banker_url: this.environment.banker_url,
  //         stage: next_stage_id,
  //         unassign: true,
  //         mail_client_name: this.CONSTANTS.MAIL_TEMPLATE.project_name,
  //         ...this.getMailTempCommonData()
  //       };
  //       if (this.applicationData?.business_address?.name) {
  //         assignData.business_name_template = `&nbsp;${this.applicationData.business_address.name}<br/>`;
  //       } else {
  //         assignData.business_name_template = "<p></p>";
  //       }
  //       let updateLeadAssignmentParams = {
  //         slug: this.CONSTANTS.SLUG['lead-assignment'],
  //         user_id: this.applicationData.userId
  //       };
  //       let updateLeadAssignmentPayload = {
  //         assigned_to: assignedToUserData.id,
  //         banker_user_id: this.backendUserData.user_id,
  //         user_id: this.applicationData.userId
  //       }
  //       let taskCall;
  //       let currentStageOrder = this.all_stages.find(({id}) => id === this.applicationData.stage_id)?.order;
  //       if (!this.applicationData?.lead_details?.assigned_to && (currentStageOrder === 1 || currentStageOrder === 0)) {
  //         taskCall = this.taskInfoService.saveTaskInfo(updateLeadAssignmentParams, updateLeadAssignmentPayload);
  //       } else {
  //         taskCall = of(false);
  //       }
  //       taskCall.pipe(
  //         switchMap((res) => {
  //           let paramsData = {
  //             slug: this.CONSTANTS.SLUG['role-stage-mapping'],
  //             role_id: assignedToUserData.role_id
  //           }
  //           return (currentStageActionConfig?.next_stage_id) ? of(false) : this.taskInfoService.getTaskInfo(paramsData)
  //         }),
  //         switchMap((response: any) => {
  //           if(response) {
  //             let assigned_role_mapping = response.response_data?.role_stage_mapping?.data?.data;
  //             // assigned_role_mapping = Array.from(new Set(assigned_role_mapping.map(e => e?.stage_id))); 
  //             // assigned_role_mapping?.sort((a: any, b: any) => { return this.stageIdOrderMap[a] - this.stageIdOrderMap[b] });
  //             // let stages_change_banker_datas = [];
  //             // get banker higher stage according to app assignment
  //             // let higherStage;
  //             // let higherStageOrder;
  //             // let stage_assignment_mapping = {};
  //             // assigned_role_mapping.forEach(stage_id => {
  //             //   let stageAssignment = (this.applicationData.app_assignment || []).find(assign=>assign.stage == stage_id);
  //             //   if(stageAssignment) {
  //             //     if (!higherStageOrder || (higherStageOrder < this.stageIdOrderMap[stage_id])) {
  //             //       higherStageOrder = this.stageIdOrderMap[stage_id];
  //             //       higherStage = stage_id;
  //             //       stage_assignment_mapping[stage_id] = stageAssignment;
  //             //     }
  //             //   }
  //             // })

  //             // get banker higher stage acc. to the current stage
  //             let stages_change_banker_datas = [];
  //             let higherStage;
  //             let stage_assignment_mapping = {};
  //             assigned_role_mapping.forEach(({stage_id}) => {
  //               let stageAssignment = (this.applicationData.app_assignment || []).find(assign=>assign.stage == stage_id);
  //               if(this.stageIdOrderMap[stage_id] <= this.stageIdOrderMap[this.applicationData?.stage_id]) {
  //                 if (!higherStage || this.stageIdOrderMap[higherStage] < this.stageIdOrderMap[stage_id]) {
  //                   higherStage = stage_id;
  //                 }
  //                 stage_assignment_mapping[stage_id] = stageAssignment;
  //               }
  //             });

  //             const higherStageActionConfig = this.current_workflow_action?.config?.stages?.[higherStage];
  //             const lower_assignment_role_ids = higherStageActionConfig?.lower_assignment_role_ids;
  //             if (lower_assignment_role_ids && lower_assignment_role_ids.includes(assignedToUserData.role_id)) {
  //               for (const [stage_id, value] of Object.entries(stage_assignment_mapping)) {
  //                 let stageAssignment = _.clone(value);
  //                 let unassign_role_id = stageAssignment?.role_id;
  //                 let stageAssignData = {...assignData, unassign_role_id, stage: stage_id};
  //                 currentStageOrder === this.stageIdOrderMap[stage_id] ? stageAssignData = {...stageAssignData, ...extra_assignment_data} : '';
  //                 stages_change_banker_datas.push([{ slug: 'change-banker' }, stageAssignData]);
  //               }
  //             } else {
  //               let unassign_role_id = stage_assignment_mapping[higherStage]?.role_id;
  //               let stageAssignData = {...assignData, stage: higherStage, unassign_role_id, ...extra_assignment_data};
  //               stages_change_banker_datas.push([{ slug: 'change-banker' }, stageAssignData]);
  //             }
  //             if(stages_change_banker_datas.length > 0){
  //               //only triggering assignment mail single time
  //               for(let i=0;i<stages_change_banker_datas.length-1;i++){
  //                 stages_change_banker_datas[stages_change_banker_datas.length-1][0].no_assignment_mail = true;
  //               }
  //             }
  //             return forkJoin(stages_change_banker_datas.map((params)=>this.taskInfoService.saveTaskInfo(params[0], params[1])));
  //           } else {
  //             return this.taskInfoService.saveTaskInfo({ slug: 'change-banker' }, {...assignData,...extra_assignment_data})
  //           }
  //         })
  //       ).subscribe((res) => {
  //         if (!res.notAssigned && !res.err) {
  //           // success and assigned to assignedToUserData.name
  //           this.common.popToast('success', 'Success', `Case APP ${this.applicationData.loan_id} has been assigned to ${assignedToUserData.bankerName ? 'self' : assignedToUserData.banker_name} (${assignedToUserData.email_address}).`);
  //           let assign_to_name = assignedToUserData?.bankerName ? assignedToUserData.bankerName + "(" + assignedToUserData?.role_name + ")" : assignedToUserData.banker_name + "(" + assignedToUserData?.role_name + ")";
  //           this.addActivityLog('application_assigned_to', assign_to_name);
  //           this.closeModal.emit(true);
  //           this.manageLoansService.changeAssignTo(true);
  //           return;
  //         }
  //         // error could not assign application
  //         if (!res.err) {
  //           this.common.popToast('error', 'Error', `Case APP ${this.applicationData.loan_id} could not be assigned to ${assignedToUserData.banker_name === 'Assign to me' ? 'self' : assignedToUserData.banker_name} (${assignedToUserData.email_address}).`);
  //         }
  //         this.closeModal.emit();
  //       });
  //     }
  //   } else {
  //     this.closeModal.emit(true);
  //     this.common.popToast('error', 'Error', this.translate.instant('loan_actions.toasts.action_not_allowed'));
  //   }

  // }


  changeBanker() {
    const commonData = this.getCommonData();
    const assignedToUserData = commonData?.selectListData.find((user) => user.id === commonData?.actionForm.value.assign_to);
    this.assignmentService.changeBanker(commonData).pipe(takeUntil(this.changeBankerDestroy$)).subscribe((res) => {
      if (!res.notAssigned && !res.err) {
        // success and assigned to assignedToUserData.name
        this.common.popToast('success', 'Success', `Case APP ${this.applicationData.loan_id} has been assigned to ${assignedToUserData.bankerName ? 'self' : assignedToUserData.banker_name} (${assignedToUserData.email_address}).`);
        let assign_to_name = assignedToUserData?.bankerName ? assignedToUserData.bankerName + "(" + assignedToUserData?.role_name + ")" : assignedToUserData.banker_name + "(" + assignedToUserData?.role_name + ")";
        this.addActivityLog('application_assigned_to', assign_to_name);
        this.closeModal.emit(true);
        this.manageLoansService.changeAssignTo(true);
        return;
      }
      // error could not assign application
      if (!res.err) {
        this.common.popToast('error', 'Error', `Case APP ${this.applicationData.loan_id} could not be assigned to ${assignedToUserData.banker_name === 'Assign to me' ? 'self' : assignedToUserData.banker_name} (${assignedToUserData.email_address}).`);
      }

      if(res?.err && res?.message){
        this.common.popToast('error', 'Error', res?.message);
      }
      this.closeModal.emit();
    });

  }

  

  getCommonData(){
    return {
      applicationData : this.applicationData,
      currentWorkflowAction : this.current_workflow_action,
      stageIdForChangeBanker : this.stageIdForChangeBanker,
      selectListData : this.selectListData,
      actionForm : this.actionForm,
      envData : this.environment,
      mailTempCommonData : this.getMailTempCommonData(),
      allStages : this.all_stages,
      stageIdOrderMap : this.stageIdOrderMap,
      backendUserData : this.backendUserData

    }
  }

  onUpdateSubStatus() {
    const currentSubStatus = this.statusData.find((sd) => sd.id === this.actionForm.value.sub_status_id);
    const form_data = this.actionForm.value;
    if(this.current_workflow_action?.type == "update_status"){
      //callfunction
      this.taskTrackerService.initAppTask(this.applicationData.appId);
      this.updateStatusService.updateSubStatus(currentSubStatus, form_data, this.applicationData, this.backendUserData, this.dateTimeZone).pipe(
        switchMap((response:any)=>{
                  
          if(!response?.error && currentSubStatus?.config?.evaluate_key_types_after_status_update?.length){
              this.actionDestroyed$?.next();
              this.actionDestroyed$?.complete();
              let evaluatedValues = response?.evaluatedValues;
              let _applicationData = {...this.applicationData, evaluatedValues};
              this.actionDestroyed$ = new ReplaySubject();
              let typeKeyActionStatusObs:Observable<TypeKeyActionStatus> = this.actionResolveService.evaluateMultipleTypesAndAction(currentSubStatus?.config?.evaluate_key_types_after_status_update, _applicationData, this.actionDestroyed$);
              return typeKeyActionStatusObs.pipe(map(()=>{
                  this.actionDestroyed$.next();
                  this.actionDestroyed$.complete();
                  return response;
              }),
              catchError(()=>{
                  this.actionDestroyed$.next();
                  this.actionDestroyed$.complete();
                  return of(response);
              }));
          }
          return of(response);
        })
      ).subscribe((res) => {
        this.taskTrackerService.destroyAppTask(this.applicationData.appId);
        if (!res.error) {
          this.common.popToast('success', 'Success', this.translate.instant('loan_actions.toasts.success_status_update'));
          this.closeModal.emit(true);
          this.manageLoansService.changeAssignTo(true);
          return;
        }
        else if (res.error && res.checkListNotUpdated) {
          this.closeModal.emit(false);
          this.common.popToast('error', 'Error', `${res.message}`);
        }
        else {
          this.closeModal.emit(false);
          this.stageConfirmModalRef?.hide();
          this.common.popToast('error', 'Error', this.translate.instant('loan_actions.toasts.error_status_update'));
        }
      },()=>{
        this.closeModal.emit(false);
        this.stageConfirmModalRef?.hide();
      });
    }
  }

  // generate_cap_stream_payload(return_data?, cafData?) {

  //   const caf_data = cafData;
  //   const app_data = this.applicationData;
  //   const business_data = app_data?.business;
  //   const collateral_map = {};
  //   this.collateral.forEach(res => collateral_map[res.id] = res.type);
  //   let data = {
  //     provider: "CS01",
  //     tax_id: business_data?.app_biz_tax_id,
  //     request_id: String(app_data.loan_id),
  //     business_name: business_data?.business_name,
  //     state_code: business_data?.state,
  //     //loan_officers will have seperate ids provided by .
  //     loan_officer: "2838369015240665233",
  //     street_address: `${(business_data.street_no && typeof business_data.street_no !== "undefined") ? business_data.street_no + ', ' : ""}${(business_data.street_name && typeof business_data.street_name !== "undefined") ? business_data.street_name : ""}`,
  //     city: business_data?.city,
  //     zip_code: business_data?.zip_code,
  //     naics_code: business_data?.naics_code || business_data?.naics,
  //     business_structure: this.masterDataPipe.transform(business_data?.business_structure, this.business_structure, 'id', 'value') || undefined,
  //     //provided by
  //     branch_code: "186",
  //     branch_region_code: "KCM",

  //     joint_app_flag: this.CONSTANTS.YES_NO_MAP[caf_data?.additional_regulatory_info.joint_intent_applies]?.boolean || String(+ app_data?.owner_data.some(data => data.ownership >= 20)),
  //     finance_program_id: this.CONSTANTS.FINANCE_PRODUCT_MAPPING[app_data?.purpose_arr[0]?.product_type],
  //     asset_cost: String(app_data?.purpose_arr.reduce((sum: any, key: any) => sum + parseInt(key.amount_field) || 0, 0)),
  //     // ?
  //     product_type_code: "LOC_SUBLIMIT",
  //     purpose_code: this.masterDataPipe.transform(app_data?.purpose_arr?.[0]?.purpose, this.loan_purpose, 'id', 'value') || undefined,
  //     collateral_desc: app_data?.collateral.map(res => collateral_map[res.collateral]).join(','),
  //     // ?
  //     percent_20_code: this.CONSTANTS.YES_NO_MAP[caf_data?.additional_info?.real_estate_group_involved]?.name || undefined,
  //     umb_cred_req_code: this.CONSTANTS.YES_NO_MAP[caf_data?.additional_info?.request_includes_annual_review]?.name || undefined,
  //     payment_structure_code: this.masterDataPipe.transform(caf_data?.loan_details?.payment_structure, this.payment_stream_type, 'id', 'code') || undefined,
  //     prepared_by_code: this.masterDataPipe.transform(caf_data?.loan_details?.documentation_prepared_by, this.documentation_prepared_by, 'id', 'code') || undefined,
  //     trx_lgd_code: this.masterDataPipe.transform(caf_data?.loan_details?.loan_risk_rating, this.current_risk, 'id', 'code') || undefined,
  //     term_months: caf_data?.loan_details?.repayment_structure_term || undefined,
  //     pay_freq_code: (this.masterDataPipe.transform(caf_data?.loan_details?.repayment_structure_term_interest_payable, this.pmt_frequency, 'id', 'value') || "").toUpperCase() || undefined,
  //     maturity_date: this.dateTimeZone.transform(caf_data?.loan_details?.repayment_structure_maturity_date, 'dd/MM/yyyy') || undefined,
  //     struct_comments: caf_data?.loan_details?.term_structure_additional_comments || undefined,
  //     prepay_penalty_flag: this.CONSTANTS.YES_NO_MAP[caf_data?.additional_info?.real_estate_group_involved]?.boolean || undefined,

  //     owners: app_data?.owner_data.map((data: any) => {
  //       return {
  //         first_name: data?.first_name || data?.first_name_beneficial_owner,
  //         last_name: data?.last_name || data?.last_name_beneficial_owner,
  //         tax_id: data?.tax_id || data?.ssn,
  //         state_code: data?.state,
  //         zip_code: data?.zip_code,
  //         street_address: `${(data.streetNo && typeof data.streetNo !== "undefined") ? data.streetNo + ', ' : ""}${(data.streetName && typeof data.streetName !== "undefined") ? data.streetName : ""}`,
  //         city: data?.city,
  //         email_address: data?.email_address || data?.email_address_beneficial_owner,
  //         role_in_business: data?.role_in_business === 'Owner' ? 'owner' : 'non_owner',
  //         is_owner_a_business_entity: (data?.owner_type === 'corporate'),
  //         country: "USA",
  //         phone_number: String(data?.phone || data?.phone_benefecial_owner),
  //         business_name: data?.businessname || undefined
  //       }
  //     })
  //   }
  //   if (return_data) {
  //     return data;
  //   }
  //   else {
  //     // let params  = {
  //     //   slug: 'send-to-cap-stream',
  //     //   status_id : "6209549a403846e283d44d7d",
  //     //   sub_status_id : "60d9d5fc67b48ec7788b7b15",
  //     //   app_id : this.applicationData.appId,
  //     //   user_id : this.applicationData.userId,
  //     //   backend_user_id : this.backend_user_id
  //     //   }
  //     // this.taskInfoService.saveTaskInfo(params,data).subscribe(res => {
  //     //   let capStreamRef = res?.save_capital_stream_ref?.data?.data
  //     //   if(capStreamRef?.status != 'success'){
  //     //     this.common.popToast('error', 'Error','Send to Capital Stream Failed. Check Activities for Further Details.');
  //     //   }
  //     //   else{
  //     //     this.common.popToast('success', 'Success','Send to capital Stream Success');
  //     //   }
  //     //   this.addActivityLog(`cap_stream_${capStreamRef?.status}`, undefined, 'system');
  //     //   this.closeModal.emit(true);
  //     //   return;
  //     // })
  //   }
  // }

  // sendToCapStream() {
  //   let params = { slug: 'send-to-cap-stream' }
  //   let caf_data;
  //   if (this.applicationData.business_references && this.applicationData.business_references.length > 0) {
  //     const index = this.applicationData.business_references.map(elem => elem.type).lastIndexOf('offer_management');
  //     params['loan_offer_id'] = this.applicationData.business_references[index]?.ref_id;
  //     this.taskInfoService.getTaskInfo(params).pipe(
  //       switchMap((res: any) => {
  //         this.current_risk = res.response_data?.current_risk?.data?.data;
  //         this.documentation_prepared_by = res.response_data?.documentation_prepared_by?.data?.data;
  //         this.payment_stream_type = res.response_data?.payment_stream_type?.data?.data;
  //         this.pmt_frequency = res.response_data?.pmt_frequency?.data?.data;
  //         this.product_type = res.response_data?.product_type?.data?.data;
  //         this.business_structure = res.response_data?.business_structure?.data?.data;
  //         this.collateral = res.response_data?.collateral?.data?.data;
  //         this.loan_purpose = res.response_data?.loan_purpose?.data?.data;
  //         caf_data = res?.response_data?.get_caf_data?.data?.data?.loan_offer_data;
  //         let data = this.generate_cap_stream_payload(true, caf_data);
  //         let params = {
  //           slug: 'send-to-cap-stream',
  //           status_id: "6209549a403846e283d44d7d",
  //           sub_status_id: "60d9d5fc67b48ec7788b7b15",
  //           app_id: this.applicationData.appId,
  //           user_id: this.applicationData.userId,
  //           backend_user_id: this.backend_user_id,
  //           business_id: this.applicationData.businessId,
  //           send_from_cap_stream_failed_action: true
  //         }
  //         return this.taskInfoService.saveTaskInfo(params, data);
  //       }),
  //       // switchMap((res: any) => {
  //       //   let capStreamRef = res?.save_capital_stream_ref?.data?.data;
  //       //     if(capStreamRef?.status == 'failed'){
  //       //       this.common.popToast('error', 'Error','Send to Capital Stream Failed. Check Activities for Further Details.');
  //       //     }
  //       //     else{
  //       //       this.common.popToast('success', 'Success','Send to capital Stream Successful');
  //       //     }
  //       //   return of(res);
  //       //   })

  //     ).subscribe(res => {
  //       let capStreamRef = res?.save_capital_stream_ref?.data?.data;
  //       if (capStreamRef?.status === 'failed') {
  //         this.common.popToast('error', 'Error', this.translate.instant('loan_actions_toasts.error_send_to_capital'));
  //       }
  //       else {
  //         this.common.popToast('success', 'Success', this.translate.instant('loan_actions_toasts.success_send_to_capital'));
  //       }
  //       this.addActivityLog(`cap_stream_${capStreamRef?.status}`, capStreamRef?.response?.errors, 'system');
  //       this.closeModal.emit(true);
  //     })
  //   }
  // }


  otherActions() {
    return this.actionType !== 'Update Loan Product' && this.actionType !== 'Update Status' && this.actionType !== 'Add Note';
  }

  // allowFormRender() {
  //   let actionType = ['Update Status', 'Update Loan Product', 'Add Note'];
  //   if (
  //     (actionType.includes(this.actionType) && this.currentStageAllowed) ||
  //     (this.otherActions())) {
  //     return true;
  //   }

  // }

  // isCurrentStageInActive() {
  //   let actionType = ['Update Status', 'Update Loan Product', 'Add Note'];
  //   if (
  //     (actionType.includes(this.actionType) && !this.currentStageAllowed)) {
  //     return true;
  //   }
  // }

  //checking status of checklist for current stage

  calculateExposureValue(loan_info_values: any, purpose_arr: any) {
    let total_amount: number = 0;
    if (loan_info_values) {
      total_amount += Object.keys(loan_info_values).reduce((sum: any, key: any) => sum + parseInt(loan_info_values[key] || 0), 0);
    }
    if (purpose_arr && purpose_arr.length > 0) {
      total_amount += purpose_arr.reduce((sum: any, key: any) => sum + parseInt(key.amount_field) || 0, 0);
    }
    return total_amount;
  }

  onActionBtnClick(action_button){
    switch(action_button?.type){
      case "onCancel":
        this.onCancel();
        break;
      case  "onSubmit":
        this.onSubmit();
        break;
    }
  }

  getMailTempCommonData(){
    return {
      banker_siteurl: this.environment.banker_url,
      header_logo_path_1: this.environment.logo1_path,
      header_logo_path_2: this.environment.logo1_path,
      client_name: this.CONSTANTS.MAIL_TEMPLATE.project_name,
      senders_name: this.CONSTANTS.MAIL_TEMPLATE.senders_name,
      copyright_text: this.CONSTANTS.MAIL_TEMPLATE.copyright_text,
      terms: this.environment.terms,
      privacy: this.environment.privacy
    }
  }

  onCancelConfirmation(){
    this.stageConfirmModalRef.hide();
  }

  onConfirmationStageStatus(){
    switch (this.actionType) {
      case 'Update Status':
        this.onUpdateSubStatus();
        break;
    }
  }

  openStageConfirmationModal(){
    const currentSubStatus = this.statusData.find((sd) => sd.id === this.actionForm.value.sub_status_id);
    if(currentSubStatus?.config?.confirmation_on_next_stage){
      this.stageConfirmModalRef?.hide();
      this.stageConfirmModalRef = this.modalService.show(this.stageConfirmModalTemplate, {
        id: 905,
        class: 'modal-lg nextStageModal',
        backdrop: 'static',
      });
    }else{
      this.onConfirmationStageStatus();
    }
    
  }

  primary_owner(item) {
    let primary_owner = {
      phone: '',
      name: '',
      email: '',
    };
    if (item?.owners?.length > 0) {
      let primaryOwnerData = item.owners.find((e) => e.is_primary === true);
      if(!primaryOwnerData){return null}
      if (primaryOwnerData?.owner_type === 'individual') {
        primary_owner.phone = primaryOwnerData.phone;
        primary_owner.email = primaryOwnerData.email_address;
        primary_owner.name = primaryOwnerData.first_name;
      }
      else {
        primary_owner.phone = primaryOwnerData.phone_benefecial_owner;
        primary_owner.email = primaryOwnerData.email_address_beneficial_owner;
        primary_owner.name = primaryOwnerData.first_name_beneficial_owner;
      }
    }
    return primary_owner;
  }

  ngOnDestroy(){
    this.rootStateSub?.unsubscribe();
    this.stageConfirmModalRef?.hide();
    this.changeBankerDestroy$.next();
    this.changeBankerDestroy$.complete();
  }

}
