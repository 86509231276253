import { Component, OnInit } from '@angular/core';
import { ToasterConfig } from 'angular2-toaster';

@Component({
  selector: 'rubicon-default-toaster',
  templateUrl: './default-toaster.component.html',
  styleUrls: ['./default-toaster.component.css']
})
export class DefaultToasterComponent implements OnInit {

  config: ToasterConfig =
    new ToasterConfig({
      timeout: 7000,
      limit: 1
    });

  constructor() { }

  ngOnInit(): void {
  }

}
