import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ApiResponse, apiUrls } from '@rubicon/interface/task-info';
// import { environment } from '@env/environment';
import { FormGenerateService } from '../form/form-generate.service';

@Injectable({
  providedIn: 'root'
})
export class TaskInfoService {
  
  worldPayScriptLoaded = false;


  constructor(private httpClient: HttpClient,
    @Inject('environment') private environment,
    private formGenerate: FormGenerateService) { }

  /**
   * function to get task info
   * @param params 
   */
  getTaskInfo(params: any) {
    return this.httpClient
      .get(this.environment.orchUrl + apiUrls.executeTask, { params })
      .pipe(
        map((response: ApiResponse) => {
          if (response.data) {
            if (response.data)
              response.data.form_fields.sort(this.formGenerate.compareValues('order'));
            return response.data;
          }
          return null;
        })
      );
  }

  /**
   * function to save task info
   * @param slug 
   * @param payload 
   * @param headers 
   */
  saveTaskInfo(params: any, payload: any, headers?: any) {
    return this.httpClient
      .post(this.environment.orchUrl + apiUrls.executeTask, payload, { params, headers })
      .pipe(
        map((response: ApiResponse) => {
          return response.data.response_data;
        })
      );
  }
}
