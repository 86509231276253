import { Injectable } from '@angular/core';
import { UploadService } from '@rubicon/utils';
import { forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UploadDocumentsService {

  constructor(public uploadService: UploadService) {
  }

  uploadDocuments(form_data, data) {
    const uploadData_business = data;
    const progress = this.uploadService.uploadFile(
      form_data.file_data,
      { slug: 'upload_sba_docs', fileKey: 'file', form_name: data.form_name },
      uploadData_business,
      'upload_base64_pdf',
      data?.ref_api
    );
    const allProgressObservables = [];
    for (const key in progress) {
      allProgressObservables.push(progress[key].progress);
    }
    return forkJoin(allProgressObservables);
  }
}
