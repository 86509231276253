export const APP_ROUTES = {
    'about': 'sba/about-you',
    'loan-details': 'sba/loan-details',
    'sba-checklist': 'sba/additional-details',
    'offer': 'sba/offers',
    'document-listing': 'sba/documents',
    'thankyou' : 'thankyou',
    'owner-thankyou' : 'thankyou/owner',
    'thankyou-logout':'thankyou/logout',
    'addendum':"sba/additional-details/addendum",
    "business-details":"sba/business",
    "applicant-details":"sba/owners",
    "e-sign":"sba/e-sign",
}

export const APP_STEP = {
    'about': 1,
    'loan-details': 2,
    'offer': 3,
    'sba-checklist': 4,
    'e-sign':5,
    'document-listing': 6,
    'thankyou': 7
}
export const CONSTANTS={
    "yes":"5e8723158f2f4e3ac475fae6",
    "no":"5e8723158f2f4e3ac475fae7",
    BUSINESS_TYPES : ['5e8d97116b81935bc8af0300' ,'5e8d97116b81935bc8af0301'],
    BUSINESS_STRUCTURES : ['Individual' ,'Sole Proprietorship'],
    DOC8821: '8821_form',
    DOCUMENT_LIST: {
        COMPANY_DOCUMENTS: [
            'company_formation_documents',
            'federal_payroll_report_2019',
            'company_annual_report_2019',
            'document_receipt_health_insurance',
            'retirement_benifits',
            'form_8821',
            'form_941_944',
            'annual_form_940',
            'driving_license'
        ],
        INDIVIDUAL_DOCUMENTS: [
            'company_formation_documents',
            'all_1099_received_2019',
            'healthcase_and_retirement_benifits' ,
            'form_8821',
            'driving_license'
        ],

    },
    SUBMITTED_STATE:"5c20bf7e27105c78ad7f9280",
    NEW_STATE:'5c20bf7e27105c78ad7f9283',
    APPROVED_STATE: "5d1618f27823310fb44b9d4e",
    ADDENDUM_CSS:"*{margin:0;padding:0;-webkit-box-sizing:border-box;-webkit-print-color-adjust:exact;-moz-box-sizing:border-box;box-sizing:border-box}body{color:#000;font-family:\"Times New Roman\",Times,serif;font-style:normal;font-size:8pt;margin-bottom:0;margin-top:0}.clear{margin:0;padding:0;height:0;clear:both}div,p,li,ul,span,td,th,a{margin:0;padding:0}p{padding-bottom:10px;line-height:17px;color:#000}.wraperPage{width:840px;margin:0 auto}.newPage{width:100%;display:block}.wrap{width:100%;padding-bottom:10px}.wraperPage .header{padding-bottom:15px}.heading{padding:4px 6px;font-size:9pt;margin-bottom:12px;color:#fff;background:#034048;font-style:normal}.clearfix{margin:0;padding:0;height:0;clear:both}.div50{float:left;width:50%}.wrap{width:100%;display:inline-block;margin-bottom:2px}ul li{line-height:15px}th{color:#000}.addendum-header td{font-weight:bold;font-size:10pt;text-align:center;color:#000;padding-bottom:10px}.main-heading{font-size:12pt}p.addendum-p{padding:0 0 5px 0;text-align:left;}.tableContainer{padding-bottom:10px}.mainTable{border:1px solid #000;border-bottom:0}.mainTable th{border-bottom:1px solid #000;padding:3px;text-align:center;border-bottom:1px solid #000;border-right:1px solid #000}.mainTable td{border-bottom:1px solid #000;border-right:1px solid #000;padding:3px;text-align:left;height:40px}.explanation-p{padding:0 0 10px} .noWrap{white-space: nowrap;}.mt-20{margin-top:20px;}p.pb-20{padding-bottom:20px;}.pb-30{padding-bottom:30px;}",
    OWNER_STRUCT_CSS:"*{margin:0px;padding:0px; -webkit-box-sizing:border-box; -webkit-print-color-adjust: exact; -moz-box-sizing:border-box;box-sizing:border-box;}body {color: #000;font-family: \"Times New Roman\", Times, serif;font-style: normal; font-size:9pt; margin-bottom: 0; margin-top: 0;}div, p, li, ul, span, td, th, a { margin: 0; padding: 0;}p { padding-bottom: 10px;}.wraperPage { width:780px; margin: 0 auto;}.newPage { width: 100%; display: block;}.wrap { width: 100%; padding-bottom: 10px;}.wrap { width: 100%; display: inline-block; margin-bottom: 2px;} .heading{font-weight: bold; font-size: 11pt; text-align: center;}.right{text-align:right;}.mainTable{border: 1px solid #000;}.head-row{background: #d9d9d9;border-right: 1px solid #000;font-weight: bold; padding:3px 6px; }.center{text-align:center}.cell{border-right: 1px solid #000;border-top: 1px solid #000;}.padded-cell{padding:3px 6px;}.br-0{border-right:none;}"
}