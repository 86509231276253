import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterLevel'
})
export class FilterLevelPipe implements PipeTransform {

  transform(owners: any, level=1): any {
      if(Array.isArray(owners) && owners.length > 0){
          return owners.filter(owner=>(owner.level <= level && owner.parent_id == "0"));
      }
      return owners;
  }

}
