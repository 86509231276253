<div class="">
    <div class="row pb-4 mt-4 align-items-center">
        <div class="col-12 col-md-9 col-lg-9 col-xl-9 pt-0">
            <div class="page-title">Hi   {{userData?.full_name | titlecase}} </div>               
            <div class="fs16">Here are the statistics for the loans up to date.</div>                
        </div>
        <div class="col-12 col-md-3 col-lg-3 col-xl-3 pt-3 pt-sm-0 text-sm-right" *ngIf="isActionAllowed">
            <button class="btn btn-primary" (click)="openModal(createApplication)" type="button">Start an Application</button>
        </div>
    </div>
</div>

<ng-template #createApplication>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Create Application</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <rubicon-dynamic [fields]="bankerAppConfig" [group]="bankerCreateAppForm" [slug]="slug" class="row">
        </rubicon-dynamic>
    </div>
    <div class="modal-footer">
        <div class="col-12 col-md-9 col-lg-9 pr-0">
            <div class="text-right">
                <button type="button" class="btn btn-default mr-3" (click)="modalRef.hide()">Cancel</button>
                <button type="button" class="btn btn-primary" (click)="onSubmit('continue')">Submit</button>
            </div>
        </div>
    </div>
</ng-template>