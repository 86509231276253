import { Injectable } from '@angular/core';

@Injectable({
    providedIn: "root"
})
export class TaskTrackerService {
    taskTracker = {};

    initAppTask(app_id: string){
        this.taskTracker[app_id] = {};
    }
    
    taskCompleted(app_id:string, task:string){
        if(this.taskTracker[app_id]){
          this.taskTracker[app_id][task]="completed";
        }
    }

    isTaskCompleted(app_id:string, task:string){
        return this.taskTracker[app_id]?.[task] == "completed";
    }

    destroyAppTask(app_id: string){
        delete this.taskTracker[app_id];
    }
}