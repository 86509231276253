<div class="loginPage p-0">
    <footer>
        <div class="container">
            <div class="row">
                <div class="col-12 col-sm-8 col-md-8 col-lg-8 copyrightlink">
                    <ul class="clearfix">
                        <li><a href="https://www.biz2credit.com/privacy-policy" target="_blank">Privacy</a></li>
                        <li><a href="https://www.biz2credit.com/terms-of-use" target="_blank">Terms</a></li>
                        <li><a href="https://www.biz2credit.com/site-index" target="_blank">Sitemap</a></li>
                    </ul>
                    <div class="copyright mt-2">
                        <p>© <a href="https://www.biz2credit.com/">Biz2Credit Inc 2019</a>. All rights reserved.</p>
                    </div>
                </div>

                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                    <div class="entrustRight">
                        <a href="https://www.entrust.net/customer/profile.cfm?domain=www.biz2credit.com&amp;lang=en"
                            target="_blank"><img class="img-responsive" alt="entrust"
                                src="assets/images/entrust_site.png"></a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</div>