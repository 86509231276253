<ng-container [formGroup]="group">
    <ng-container *ngIf="field?.css?.dynamic; else normal">
        <div [ngClass]="(field?.css?.labelParent)? field?.css?.labelParent :'inlineCheck'">
            <div *ngIf="slug + '.label.' + field.name | translate"
                [ngClass]="(field?.css?.labelClass)? field?.css?.labelClass :'fs14 fwsb mb-0'"
                >{{slug + ".label." + field.name | translate}}
                <ng-container *ngIf="field?.css?.tooltip && field?.css?.tooltip[field.name]"><!--
                --><a  class='que_info-text' placement="right" [ngbTooltip]="tipContent"><!----></a><!--​
                --><ng-template #tipContent><span [innerHTML]='slug + ".tooltip."+ field.name | translate'></span></ng-template><!--
                --></ng-container> 
            </div> 
            
            <div *ngIf="field?.css?.box_checkbox; else normalCheckbox"
                [ngClass]="field?.css?.inputsParent ? field?.css?.inputsParent: 'row my-3'">
                <div [formArrayName]="field.name"
                    [ngClass]="field?.css?.inputParent ? field?.css?.inputParent: 'col-lg-auto'"
                    *ngFor="let val of field.options; let i = index">
                    <div [ngClass]="field?.css?.inputInnerParent ? field?.css?.inputInnerParent: 'form-check mb-2 pl-0'">
                        <div [ngClass]="field?.css?.inputGroupParent ? field?.css?.inputGroupParent: 'form-check mb-2 pl-0'" class="d-flex checWrap">
                            <input type="checkbox" [value]="val.id" (change)="onChange(val.id)" [checked]="group.get(field.name).value.includes(val.id)" [ngClass]="field?.css?.input?field?.css?.input:'form-check-input mt-2'" [id]="val.type + '_' + indexVal">
                            <label [ngClass]="field?.css?.inputLabel?field?.css?.inputLabel:'fs13 fwsb lh pt-1 clt'"  [for]="val.type + '_' + indexVal">{{slug + ".label." + val.type | translate}}</label>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #normalCheckbox>
                <div [formArrayName]="field.name"
                    [ngClass]="field?.css?.inputGroupParent ? field?.css?.inputGroupParent: 'form-check checWrap'"
                    *ngFor="let val of field.options; let i = index">
                    <input [ngClass]="field?.css?.input?field?.css?.input:'form-check-input'"  type="checkbox" (change)="onChange(val.id)"
                        [id]="val.type + '_' + indexVal" [checked]="group.get(field.name).value.includes(val.id)"
                        [value]="val.id">
                    <label [ngClass]="field?.css?.inputLabel?field?.css?.inputLabel:'form-check-label clt fs12'"
                        [for]="val.type + '_' + indexVal">{{slug + ".label." + val.type | translate}}</label>
                </div>
            </ng-template>
            
        </div>
    </ng-container>
    <ng-template #normal>
        <div class="inlineCheck">
            <div *ngIf="(slug + '.label.' + field.name | translate) && !field?.css?.hide_label" class="fs14 fwsb mb-0">
                {{slug + ".label." + field.name | translate}}
                <ng-container *ngIf="field?.css?.tooltip && field?.css?.tooltip[field.name]"><!--
                --><a  class='que_info-text' placement="right" [ngbTooltip]="tipContent"><!----></a><!--​
                --><ng-template #tipContent><span [innerHTML]='slug + ".tooltip."+ field.name | translate'></span></ng-template><!--
                --></ng-container>
            </div>
            <div [formArrayName]="field.name" class="form-check checWrap"
                *ngFor="let val of field.options; let i = index">
                <input class="form-check-input" type="checkbox" (change)="onChange(val.id)"
                    [id]="val.type + '_' + indexVal" [checked]="group.get(field.name).value.includes(val.id)"
                    [value]="val.id">
                <label class="form-check-label clt fs12"
                    [for]="val.type + '_' + indexVal">{{slug + ".label." + val.type | translate}}</label>
            </div>
        </div>
    </ng-template>
    <div *ngIf="group.get(field.name).invalid && (group.get(field.name).dirty || group.get(field.name).touched)"
        class="error-msg">
        <ng-container *ngFor="let validation of field.validations;">
            <label class="error" *ngIf="group.get(field.name).hasError(validation.name)">
                {{slug + ".errorMessage." + field.name + "." + validation.name | translate}}
            </label>
        </ng-container>
    </div>
</ng-container>