import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'rubicon-sba-v2-layout',
  templateUrl: './sba-layout-v2.component.html',
  styleUrls: ['./sba-layout-v2.component.scss']
})
export class SbaLayoutV2Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
