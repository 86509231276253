export const ERROR_MESSAGES = {
    200: {
        'signin': {
            check_email: {
                false: 'Email ID does not exists.',
            }
        },
        signup: {
            check_phone: {
                true: 'This email ID or Mobile Number already exists. Please login to the application to continue.'
            },
            check_email: {
                true: 'This email ID or Mobile Number already exists.Please login to the application to continue.'
            }
        },
    },
    201: {
        'aicpa-forgot-password': 'Please verify E-mail to proceed.',
        'ppp2-aicpa-forgot-password': 'Please verify E-mail to proceed.'
    },
    400: {
        'signin-mobile': {
            FIELD_INVALID: '',
            FIELD_REQUIRED: '',
            FIELD_DUPLICATE: '',
            check_phone: 'Phone number does not exists'
        },
        signup: {
            FIELD_DUPLICATE: 'This Email ID or Phone Number already exists.Please login to the application to continue.'
        },
        'forgot-password': {
            FIELD_INVALID: 'Entered Email Id is not verified'
        },
        'reset-password': {
            FIELD_REQUIRED: 'This action requires the field to be specified.',
            FIELD_INVALID: 'Please enter a valid password',
            FIELD_DUPLICATE: 'Entered Email Id is not verified'
        },
        'change-password': {
            FIELD_DUPLICATE: 'New Password should be different from last three password',
            FIELD_INVALID: {
                new_password: 'Enter a valid new password',
                password: 'Enter correct password'
            }
        },
        'user-profile': {
            FIELD_DUPLICATE: 'This email ID already exists',
        },
        'acl-update-new-role': {
            FIELD_DUPLICATE: {
                role_slug: "Role with the same name already exists, please enter different role."
            },
        },
        'acl-new-role': {
            FIELD_INVALID: {
                role_slug: "Role with the same name already exists, please enter different role."
            },
        },
        'aicpa-create-user':{
            FIELD_INVALID: {
                email_address: "A business with this e-mail ID already exists !",
                phone_number: "A business with this phone number already exists !",
            }
        },
        'acl-create-user':{
            FIELD_INVALID: {
                email_address: "A user with this e-mail ID already exists !",
                phone_number: "A user with this phone number already exists !",
            }
        },
        'aicpa-create-affiliate-user':{
            FIELD_INVALID: {
                email_address: "It looks like you are already registered on the platform, please login with your e-mail ID and password.",
                phone_number: "A user with this phone number already exists.",
            }
        },
        'cpabusiness-aicpa-create-affiliate-user':{
            FIELD_INVALID: {
                email_address: "It looks like you are already registered on the platform, please login with your e-mail ID and password.",
                phone_number: "A user with this phone number already exists.",
            }
        },
        'cpabusiness-aicpa-create-affiliate-user-solutions':{
            FIELD_INVALID: {
                email_address: "It looks like you are already registered on the platform, please login with your e-mail ID and password.",
                phone_number: "A user with this phone number already exists.",
            }
        },
        'aicpa-user-verify-hash': {
            FIELD_INVALID: {
                token: "Varification url has been expired/invalid."
            }
        },
        'sba_calculator_cpa': {
            FILE_EXTENSION_NOT_ALLOWED: "File type not allowed"
        },
        'ppp2-aicpa-login': {
            RESOURCE_NOT_FOUND: "Invalid Credentials or Email does not exist."
        },
        'cpabusiness-aicpa-create-user': {
            FIELD_INVALID: {
                user_plan_id_required: "You don't have a valid plan",
                features_key_required: "You have exhausted the for this feature",
                email_address: "A user with this e-mail ID already exists !",
                phone_number: "A user with this phone number already exists !"
            }
        },
        'cpabusiness-acl-create-user': {
            FIELD_INVALID: {
                user_plan_id_required: "You don't have a valid plan",
                features_key_required: "You have exhausted the limit for this feature",
                email_address: "A user with this e-mail ID already exists !",
                phone_number: "A user with this phone number already exists !"
            }
        },
        'cpabusiness-acl-new-role': {
            FIELD_INVALID: {
                role_slug: "A role with this name already exists in the system, please enter different role."
            }
        },
        'buy-plan': {
            PAYMENT_FAILED: {
                "payment": "Unable to complete the payment, Please try again later."
            }
        },
        'pay-using-saved-card': {
            PAYMENT_FAILED: {
                "payment": "Unable to complete the payment, Please try again later."
            }
        },
        'get-zip-from-link': {
            FIELD_INVALID: {
                hash: "Download Link Url is invalid'"
            }
        },
        'change_password': {
            FIELD_DUPLICATE: 'New Password should be different from last three password',
            FIELD_INVALID: {
                new_password: 'Enter a valid new password',
                password: 'Enter correct password',
                user_id: 'Something went wrong. Please try again later.'
            }
        },
        'ppp2-aicpa-create-user': {
            FIELD_INVALID: {
                email_address: 'A client with this e-mail already exists !'
            }
        },
        'mfa-verification': {
            FIELD_INVALID: 'Verification code is invalid or expired.'
        },
        FIELD_INVALID: 'The value of the field is invalid.',
        URL_ARGUMENT_NOT_SUPPORTED: 'Unable to process request.',
        SERVICE_UNAVAILABLE: 'Unable to process this request.'
    },
    401: {
        'owner-consent': {
            TOKEN_EXPIRED: 'Link has been expired'
        },
        'verify_hash': {
            TOKEN_EXPIRED: 'Link has been expired'
        }
    },
    404: {
        'owner-consent': {
            RESOURCE_NOT_FOUND: 'Link has been expired'
        },
        'verify_hash': {
            NOT_FOUND: 'Link has been expired'
        }
    },
    403: {
        'reset-password': {
            TOKEN_EXPIRED: 'The token is valid but has expired.',
            PERMISSIONS_DENIED: 'You do not have permissions to perform this action on this resource.'
        },
        'aicpa-login': {
            PERMISSIONS_DENIED: 'Invalid Credentials or Email not verified. For help contact techhelp@biz2credit.com.'
        },
        'ppp2-aicpa-login': {
            PERMISSIONS_DENIED: 'Invalid Credentials or Email not verified. For help contact techhelp@biz2credit.com.'
        },
        'change_password': {
            TOKEN_EXPIRED: 'The token is valid but has expired.',
            PERMISSIONS_DENIED: 'You do not have permissions to perform this action on this resource.'
        },
        'verifier-login': {
            PERMISSIONS_DENIED: 'Invalid Credentials.'
        },
        SESSION_EXPIRED: 'Your session expired.'
    },
    429: {
        signin: {
            AUTHENTICATION_LOCKED: 'Your account is locked. Please try after sometime.'
        },
        'forgot-password': {
            RATE_LIMIT_REACHED: 'You have exceeded the maximum number of resend attempts. Please try after sometime.'
        },
        'generate-owners-hash': {
            LIMIT_REACHED: "You have exceeded the maximum number of Owner Consent send attempts. Please try after sometime."
        },
        'create-ppp2-application': {
            LIMIT_REACHED: "You have exceeded the maximum number of PPP Applications."
        },
        'check-user-plan': {
            LIMIT_REACHED: "You have exceeded the limit for this feature."
        },
        'sba-ppp-form': {
            LIMIT_REACHED: "You have exceeded the maximum number of PPP Applications."
        },
        'sba-ppp-ez-form': {
            LIMIT_REACHED: "You have exceeded the maximum number of PPP Applications."
        },
        'cpabusiness-sba-ppp-ez-form': {
            LIMIT_REACHED: "You have exceeded the maximum number of PPP Applications."
        },
        'cpabusiness-sba-ppp-form': {
            LIMIT_REACHED: "You have exceeded the maximum number of PPP Applications."
        },
        'cpabusiness-acl-new-role': {
            LIMIT_REACHED: "You have exceeded the number of roles as per your current plan. Please contact support for further assistance."
            // LIMIT_REACHED: "You have exceeded the maximum number of custom Roles."
        },
        'cpabusiness-acl-permission-create': {
            LIMIT_REACHED: "You have exceeded the number of roles as per your current plan. Please contact support for further assistance."
            // LIMIT_REACHED: "You have exceeded the maximum number of custom Roles."
        },
        'cpabusiness-acl-create-user': {
            LIMIT_REACHED: "You have exceeded the number of users as per your current plan. Please contact support for further assistance."
        },
        AUTHENTICATION_LOCKED: 'You have exceeded the maximum number of OTP attempts. Please try after sometime.',
        RATE_LIMIT_REACHED: 'You have exceeded the maximum number of temporary passcode attempts.',
    },
    440: {
        error: 'Your session expired.'
    },
    454: {
        signin: {
            SESSION_LOADING_FAILED: 'Please enter correct Username or Password.'
        },
        'aicpa-login': {
            SESSION_LOADING_FAILED: 'Invalid Credentials',
            PERMISSIONS_DENIED: 'Invalid Credentials'
        },
        'ppp2-aicpa-login': {
            SESSION_LOADING_FAILED: 'Invalid Credentials',
            PERMISSIONS_DENIED: 'Invalid Credentials'
        },
        'verifier-login': {
            SESSION_LOADING_FAILED: 'Invalid Credentials',
            PERMISSIONS_DENIED: 'Invalid Credentials'
        },
        SESSION_LOADING_FAILED: 'Wrong OTP entered.'
    },
    500: {
        ACTION_FAILED: 'Unable to perform this action due to unknown reasons.',
        'get-zip-from-link': {
            ACTION_FAILED: "Download Link Url is invalid"
        }
    },
    503: {
        error: 'Service timeout.',
        'manual-upload-bank-statements': 'Upload Failed for '
    }
}