import { Inject, Injectable } from '@angular/core';
import { TaskInfoService } from '@rubicon/utils';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class DeclineLetterService{
    constructor(private taskInfoService: TaskInfoService,
        @Inject('environment') private environment,
    ){
    }

    uploadDeclineLetter(html, applicationData, backendUserData ) {
        const pdf_style = this.getDeclineLetterCss();
        return this.taskInfoService.saveTaskInfo({ slug: 'html-to-pdf' }, { content: html, style: pdf_style }).pipe(
            switchMap((res) => {
                if (res && res.html_to_pdf && res.html_to_pdf.data && res.html_to_pdf.data.data) {
                    let decline_letter_obj = null;
                    if (applicationData?.business_references?.length) {
                        decline_letter_obj = applicationData?.business_references.find(ele => {
                            return ((ele?.type === "document-management") && (ele?.response?.type == "decline_letter"));
                        });
                    }

                    const allAssignments = (applicationData.app_assignment || []).filter((assign) => assign.stage === "loan_officer");
                    const lastAssignedTo = allAssignments[allAssignments.length - 1];
                    const data = {
                        pdf_file: res.html_to_pdf.data.data,
                        filename: applicationData.loan_id + '_adverse_action_letter.pdf',
                        doc_type_key: 'decline_letter',
                        doc_type_id: '60db7c6122d89a762442d7c5',
                        backend_user_id: backendUserData.user_id,
                        app_id: applicationData.appId,
                        user_id: applicationData.userId,
                        ref_id: applicationData.loan_id,
                        response_to_be_saved: {
                            type: 'decline_letter',
                            filename: applicationData.loan_id + '_adverse_action_letter.pdf',
                            customer_url: this.environment.customerJourneyUrl,
                            copyright_text: this.environment.copyright_text,
                            senders_name: this.environment.client_name,
                            header_logo_path1: this.environment.logo1_path,
                            header_logo_path2: this.environment.logo2_path,
                            privacy: this.environment.privacy,
                            terms: this.environment.terms,
                            banker_phone_number: this.maskPhoneNumber(lastAssignedTo?.phone_no) || '(XXX) XXX-XXXX'
                        }
                    };
                    if (decline_letter_obj?._id) {
                        data['decline_letter_main_ref_id'] = decline_letter_obj._id;
                    }
                    return this.taskInfoService.saveTaskInfo({ slug: 'upload-decline-letter' }, data);
                }

                return of({ uploaded: false, failedToUpload: true });
            })
        );
    }

    getDeclineLetterCss(){
        return `body {
          -webkit-print-color-adjust: exact !important;
        }
    
        @media all {
          .page-break {
            display: none;
          }
        }
    
        @media print {
          .page-break {
            display: block;
            page-break-before: always;
          }
        }
    
        body {
          color: #000;
          font-family: Arial, Helvetica, sans-serif;
          font-style: normal;
          font-size: 9.4pt;
          line-height: 18px;
          margin-bottom: 0;
          margin-top: 0;
        }
    
        .clear {
          margin: 0;
          padding: 0;
          height: 0;
          clear: both;
        }
    
        div,
        p,
        li,
        ul,
        span,
        td,
        th,
        a {
          margin: 0;
          padding: 0;
          font-family: Arial, Helvetica, sans-serif;
        }
    
        p {
          padding-bottom: 5px;
        }
    
        .wraperPage {
          width: 100%;
          margin: 10px auto;
        }
    
        .pfsText {
          font-size: 20px;
          font-weight: 600;
          text-align: right;
        }
    
        .newPage {
          width: 100%;
        }
    
        .wrap {
          width: 100%;
          padding-bottom: 5px;
        }
    
        input[type="checkbox"] {
          margin: 0;
          padding: 2px;
        }
    
        input[type="text"] {
          margin: 0;
          padding: 1px 1%;
          width: 98%;
          border: 0;
          background: none
        }
    
    
    
        tr,
        td,
        ul {
          padding: 0;
          margin: 0;
          line-height: 10px;
        }
    
    
        .lebelText {
          font-size: 12px;
          color: #333;
          text-align: left;
          font-weight: bold !important;
          padding-bottom: 5px;
        }
    
        .valueText {
          font-size: 12px;
          color: #333;
          text-align: left;
          font-weight: normal !important;
          padding-bottom: 5px;
          line-height: 18px;
        }
    
        .table {
          border: 1px solid #003C6E;
          width: 100%;
          margin-bottom: 1rem;
          color: #333;
          border-collapse: collapse;
        }
    
        .GreenBgTitle {
          color: #fff;
          text-align: center;
          font-size: 20px;
          font-weight: bold;
          padding: 14px 0;
          margin-top: 20px;
          background-color: #003C6E;
        }
    
        .loanid {
          color: #666666;
          text-align: right;
          font-size: 15px;
          font-weight: bold;
          padding: 4px 0;
          border-top: 1px solid #003C6E;
          border-bottom: 1px solid #003C6E;
        }
    
        .subTitle2 {
          color: #666666;
          text-align: left;
          font-size: 16px;
          font-weight: bold;
          padding: 13px 0;
        }
    
        .subTitle3 {
          color: #666666;
          text-align: left;
          font-size: 14px;
          font-weight: bold;
          padding: 13px 0;
        }
    
        .table td,
        .table th {
          color: #666666;
          border-color: #003C6E;
          vertical-align: middle;
          font-size: 14px;
          padding: 14px 10px;
          font-weight: 500;
          border-bottom: 1px solid #003C6E;
          border-right: 1px solid #003C6E;
        }
    
        .table th {
          font-weight: bold;
        }
    
        .ogText td {
          border-top: 2px solid #ddd;
          padding-top: 30px;
        }
    
        .ogText .lebelText,
        .ogText .valueText {
          font-size: 20px;
          color: #666666
        }
    
        .tr {
          text-align: right;
        }
    
        .logoText td {
          padding-bottom: 9px;
        }
    
        .listStyle {
          margin-left: 30px;
        }
    
        .listStyle li {
          line-height: 18px;
          list-style-type: disc;
          color: #333;
          font-size: 12px;
          padding-bottom: 4px;
        }`;
    }

    maskPhoneNumber(num) {
        if(num && Number(num) !== NaN && Number(num) !== 0 && !isNaN(Number(num))) {
          num = num.toString();
          const phoneNumber = num.match(/(\d{3})(\d{3})(\d{4})/);
          return num.length === 10 ? `(${phoneNumber[1]}) ${phoneNumber[2]}-${phoneNumber[3]}` : '';
        }
        return '';
    }
}