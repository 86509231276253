export * from './group/group.component';
export * from './amount/amount.component';
export * from './checkbox/checkbox.component';
export * from './date/date.component';
export * from './file/file.component';
export * from './number/number.component';
export * from './password/password.component';
export * from './radio/radio.component';
export * from './select/select.component';
export * from './slider/slider.component';
export * from './switch/switch.component';
export * from './tel/tel.component';
export * from './text/text.component';
export * from './textarea/textarea.component';
export * from './label/label.component';
export * from './button/button.component';