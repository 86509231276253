import { Component, OnInit, Input } from '@angular/core';
import { CommonService } from '../../services/common/common.service';
import { Store } from '@ngrx/store';
import { addNextTask } from '../../+state/utils.action';

@Component({
  selector: 'rubicon-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.css']
})
export class ConfirmModalComponent implements OnInit {

  @Input() slug: string;
  @Input() backTask: string;

  constructor(private common: CommonService, private store: Store<any>) { }

  ngOnInit(): void {
  }

  navigate() {
    this.store.dispatch(addNextTask({ nextTask: this.backTask }));
    this.common.navigate(this.backTask);
  }

}
