import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'rubicon-other-layout',
  templateUrl: './other-layout.component.html',
  styleUrls: ['./other-layout.component.css']
})
export class OtherLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
