import { Component, OnInit, Inject, Input, TemplateRef, ViewChild, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { logout } from '../+state/ui-kit.action';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { addUserDetails, addAppID, addBusinessID, addNextTask, FeatureAccessService,  CommonService, TaskInfoService, NotificationService } from '@rubicon/utils_custom';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FAQa_LIST } from 'libs/affiliate-portal/dashboard/src/lib/constants';
import { PLAN_FEATURE_CONFIG } from 'libs/affiliate-portal/dashboard/src/lib/constants';
import {cloneDeep, times} from 'lodash';
import { Subscription } from 'rxjs';
import { ClientService } from 'libs/utils/src/lib/services/client/client.service';

declare let $

@Component({
  selector: 'rubicon-affiliate-header',
  templateUrl: './affiliate-header.component.html',
  styleUrls: ['./affiliate-header.component.scss']
})
export class AffiliateHeaderComponent implements OnInit,OnDestroy {

  isLoggedIn: boolean;
  manageUser: boolean;
  hideUpgrade: boolean;
  modalRef: BsModalRef;
  forgiveness_journey_url:string;
  affiliate_journey_url:string;
  @Input('questionLabel') questionLabel;
  @Input('showLoginBtn') showLoginBtn;
  @Input('hideSupportMailAndQuestion') hideMailAndQuestion;
  @Input('linkLogo') linkLogo = false;
  videoModalRef: BsModalRef;
  @ViewChild('featureVideoTemplate') featureVideoTemplate: TemplateRef<any>; 
  @ViewChild('leadsResultTemplate') leadsResultTemplate: TemplateRef<any>; 
  featureVideoRef: BsModalRef;
  leadsResultRef: BsModalRef;
  videoActiveURL:string;
  registration_flag=false;
  planType = false;
  plan = 'none';
  plan_id: any;
  supportMail;
  isCommissionRoute = false;
  showHeader = false;
  created_at: any;
  /** for notification center */
  backend_user:any;
  notifications:Array<any> = [];
  payload:Object={
    skip: 0,
    limit: 10,
    get_unread_count: true,
  }
  config = {
    animated: true,
    ignoreBackdropClick: true
  };
  planFeatureConfig = PLAN_FEATURE_CONFIG;
  unread_count:number=0;
  FAQs_list: any []= [];
  searchText : string = '';
  filter_result : boolean = false;
  myPlanActive : boolean;
  featureVideoHeading: string = '';
  featureVideoTitle: string = '';
  featureVideoUrl: string;
  videoNotifSubscription: Subscription;
  featureVideoTooltip: string = "Watch the video to understand how these new features can help your firm's advisory practice!"
  leadNotifSubscription: Subscription;
  leadsResultHeading: string = '';
  leadsResultTitle: string = '';
  leadsResults: any[] = [];
  successLeads: any[] = [];
  duplicateLeads: any[] = [];
  errorLeads: any[] = [];
  duplicateStatusText = 'Client already exists in Biz2credit. Cannot be referred to a Funding Specialist.';
  show_faq_btn: boolean = true;


show_upgrade_plan: any;
  show_resource_hub: any;

  constructor(
    private store: Store<any>,
    private router: Router,
    @Inject('environment') private environment,
    @Inject('config') private CONFIG,
    private clientService: ClientService,
    private activeRoute: ActivatedRoute,
    private modalService: BsModalService,
    private FeatureService: FeatureAccessService,
    private taskInfoService: TaskInfoService,
    private commonService: CommonService,
    private notificationService: NotificationService,
    @Inject("urls") public URLS) {
    this.manageUser = this.environment.user_management;
    this.forgiveness_journey_url = this.environment.forgiveness_journey_url;
    this.affiliate_journey_url = this.environment.affiliate_journey_url;
  }

  ngOnInit(): void {
    //initialization
    this.show_resource_hub = this.CONFIG?.features?.view?.header?.resource_hub;
    this.setupSupportMail();
    this.show_faq_btn = this.CONFIG?.features?.view?.header?.help_faq ?? true;
    this.show_upgrade_plan = this.CONFIG?.features?.view?.header?.upgrade_plan ?? true;
    this.myPlanActive = false;
    
    this.commonService.financingInquiryFlag.subscribe((data:any)=>{
      this.showHeader = data;
    })
    this.plan = this.FeatureService.getPlan()?this.FeatureService.getPlan().type:'none';

    this.checkPlan();
    this.plan_id = this.FeatureService.getPlan()?.id;
    if(this.plan == "enterprise" || this.plan === 'custom'){
      this.planType = true;
    }
    if(this.cpaClient() && (this.planType||this.plan==='premium')){
      this.supportMail='cpasupport-premier@biz2credit.com';
    }
    this.FeatureService.planType.subscribe((data:any)=>{
      this.plan_id = data.id;
      if(data.type == "enterprise"){
      this.planType = true;
    }
    if(this.cpaClient() && (this.planType||data.type ==='premium')){
      this.supportMail='cpasupport-premier@biz2credit.com';
    }
    })
    if((this.router.url+'').indexOf('plans') !== -1) {
      this.hideUpgrade = true;  
    }
    if((this.router.url+'').indexOf('terms-of-use') > -1||(this.router.url+'').indexOf('application-status') > -1) {
      this.hideUpgrade = true;
      this.showLoginBtn=true;
      this.questionLabel=true;
    }

    this.isCommissionRoute = false;
    if(this.router.url && this.router.url.indexOf('commission-report') !== -1) {
      this.isCommissionRoute = true;
    }
    
    this.router.events.forEach((event: any) => {
      if(event instanceof NavigationStart) {
        if((event.url+'').indexOf('plans') !== -1) {
          this.hideUpgrade = true;
        }
        else if((event.url+'').indexOf('terms-of-use') > -1) {
          this.hideUpgrade = true;
          this.questionLabel=true;
          this.showLoginBtn=true;
        }
         else {
          this.hideUpgrade = false;
          this.checkPlan();
        }
      }
      this.isCommissionRoute = false;
      if(event.url && event.url.indexOf('commission-report') !== -1) {
        this.isCommissionRoute = true;
      }
    });

    this.store.
      pipe(
        select('app'),
        take(1)
      ).subscribe(rootState => {
        if(rootState) {
          if(rootState && rootState.backendUserData && rootState.backendUserData.user){
            this.backend_user = rootState.backendUserData.user;
            this.created_at = rootState.backendUserData.user.created_at
          }
          if(rootState && rootState.backendUserData && rootState.backendUserData.id&&!this.showLoginBtn) {
            this.isLoggedIn = true;
             /**notification get */
            this.getNotifications();
            // this.notificationService.getMessage(rootState.backendUserData.id);
            
          }
          let newUser = this.commonService.cpaExistingOrNew(this.created_at);
          if(rootState && rootState.backendUserData && rootState.backendUserData.user && rootState.backendUserData.user.registration_type){
            if(rootState.backendUserData.user.registration_type == "normal"){
                   this.registration_flag = true;
              } 
          } 
        }
      });

      this.modalService.onShown.subscribe(() => {
        $("#faq").owlCarousel({
          loop: false,
          items: 2,
          margin: 10,
          navigation: false,
          responsiveClass: true,
          autoHeight: false,
          autoHeightClass: 'owl-height',
          dots: false,
          autoplay:false,
          rewind:false,
          mouseDrag: false,
          responsive: {
              0: {  
                  nav: true,
                  items: 1,
              },
              480: {
                  nav: true,
                  items: 1,
              },
              1024: {
                  nav: true,
                  autoHeight: true,
              }
          }
        });
      });

      
      /**reload notification and conunt for header */
      this.notificationService.updateNotificationSub.subscribe(reload=>{
        if(reload === true &&  this.isLoggedIn == true){
          this.getNotifications();
        }
      });

      this.videoNotifSubscription =  this.notificationService.showVideoNotification.subscribe(videoData=>{
        for(let video in videoData){
          if(video === 'DTC'){
            this.featureVideoHeading = ' Earn financing advisory revenue the easy way.';
            this.featureVideoTitle = 'Watch this 1-minute intro to get familiar with the newest Portal features.';
            this.featureVideoUrl = videoData[video];
            this.showDTCVideo();
          }
        }
      })

      this.leadNotifSubscription =  this.notificationService.showLeadNotification.subscribe(leadsData=>{
          this.leadsResultHeading = 'Here Are Your Results';
          this.leadsResults = leadsData.leads
          this.successLeads = [];
          this.errorLeads = [];
          this.duplicateLeads = [];
          for(let lead of this.leadsResults){
            if(lead.status === 'done'){
              this.successLeads.push(lead)
            } else if(lead.status === 'duplicate'){
              this.duplicateLeads.push(lead)
            } else{
              this.errorLeads.push(lead)
            }
          }
          this.showLeadsResult();
      })

      // this.notificationService.getApplicationUpdate().subscribe(notification=>{
      //   if(notification){
      //     this.notifications.pop();
      //     this.notifications.unshift(...this.notificationService.notificationBuilder([notification]));
      //     this.unread_count++;
      //   }
      // });

      //faq filter
      this.FAQs_list = cloneDeep(FAQa_LIST);
      this.filter_result = true;


      
    this.store.pipe(select('app'),).subscribe(rootState => {
      this.plan = this.FeatureService.getPlan()?this.FeatureService.getPlan().type:'none';
      this.checkPlan();
      this.plan_id = this.FeatureService.getPlan()?.id;
    });
  }


  setupSupportMail() {
    this.supportMail = this.CONFIG?.features?.text?.header?.support_mail;
  }

  cpaClient(): boolean {
    return this.clientService.isPartnerCPA();
  }

  onChange() {
    this.FAQs_list = cloneDeep(FAQa_LIST)
    if(!this.searchText) this.filter_result = true;
  }
  getNotifications(){
    try{
      this.payload = {
        ...this.payload,
        backend_user_id : this.backend_user.id,
        entity_name : this.backend_user.cpa_firm_name
      };
  
      this.notificationService.getNotifications(this.payload).subscribe(resp=>{
        if(resp.notifications){
          this.notifications = this.notificationService.notificationBuilder(resp.notifications);
          this.unread_count = resp.unread_count
        }
      }, err=>{
        console.log('err', err)
        this.commonService.popToast('error', 'Error', 'Something went wrong.');
      });
    }catch(err){
      console.log('err', err)
      this.commonService.popToast('error', 'Error', 'Something went wrong.');
    }
  }

  markAllRead() {
    if(!this.unread_count) return;
    this.taskInfoService.saveTaskInfo({
      slug: 'mark_all_read'
    }, {
      backend_user_id: this.backend_user.id
    }).subscribe(resp => {
      if (resp && resp.mark_all_read_notification && resp.mark_all_read_notification.data
        && resp.mark_all_read_notification.data.data && resp.mark_all_read_notification.data.data.nModified) {
        this.notificationService.updateNotificationSub.next(true);
      }
    }, err => {
      console.log('err', err)
      this.commonService.popToast('error', 'Error', 'Something went wrong.');
    })
  }

  markAsRead(notification: any, notificationId: string, index: number, is_read: boolean) {
    if (!is_read) {
      this.notificationService.updateReadStatus(notificationId, true).subscribe(resp => {
        if (resp && resp.update_notification && resp.update_notification.data && resp.update_notification.data.data) {
          const { nModified } = resp.update_notification.data.data;
          if (nModified) {
            this.notificationService.updateNotificationSub.next(true);
          }
        }
      }, err => {
        console.log('err', err)
        this.commonService.popToast('error', 'Error', 'Something went wrong.');
      })
    }
    if(notification?.type === 'feature_video'){
      let videoPopup = {
        [notification?.meta_data?.feature_name] : notification?.meta_data?.video_url
      }
      this.notificationService.showVideoNotification.next(videoPopup);
    }

    if(notification?.type === 'leads_notii'){
      let leadsPopup = {
        leads : notification?.meta_data?.leads
      }
      this.notificationService.showLeadNotification.next(leadsPopup);
    }
  }

  showDTCVideo(){
    this.featureVideoRef = this.modalService.show(this.featureVideoTemplate, {...this.config, keyboard:false, class:'featureClass'});
  }

  closeDTCvideo(){
    this.featureVideoRef.hide();
  }

  showLeadsResult(){
    this.leadsResultRef = this.modalService.show(this.leadsResultTemplate, {...this.config, keyboard:false, class:''});
    // this.commonService.stopLoad()
  }

  closeLeadsResult(){
    this.leadsResultRef.hide();
  }

  onClick(type) {
    switch (type) {
      case 'logout':
        this.store.dispatch(logout({}));
        this.isLoggedIn = false;
        setTimeout(() => {
          this.router.navigate(['/signin']);
        }, 0);
        break;
      case 'manage-user':
        setTimeout(() => {
          this.router.navigate(['/manage-user']);
        }, 0);
        break;
      case 'change-password':
        setTimeout(() => {
          this.router.navigate(['/change-password']);
        }, 0);
        break;
      case 'my-plan':
        setTimeout(() => {
          this.router.navigate(['/my-plan']);
        }, 0);
        break;

      case 'dashboard':
        this.store.dispatch(addUserDetails({ userData: null }));
        this.store.dispatch(addAppID({ appID: null }));
        this.store.dispatch(addBusinessID({ businessID: null }));
        this.store.dispatch(addNextTask({ nextTask: 'dashboard' }));
        setTimeout(() => {
          this.router.navigate(['/dashboard']);
        }, 0);
        break;
        case 'profile-setting':
          setTimeout(() => {
            this.router.navigate(['/profile-directory']);
          }, 0);
          break;
      default:
        setTimeout(() => {
          this.router.navigate(['/'+ type]);
        }, 0);
        break;
    }
  }
  
  openModalWithClass(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'gray modal-lg', keyboard: false,  backdrop: true,

      ignoreBackdropClick: true })
    );
  }

  openVideomodal(template: TemplateRef<any>, url) {
    this.videoActiveURL = url + '?autoplay=1&amp;modestbranding=1&amp;showinfo=0';
    this.videoModalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'noheader modal-lg' })
    );
  }

  fundingProgram() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/financing-inquiry'])
    );
    window.open(url, '_blank');
  }

  senLeadstoSBA(){
    let data = {
      formdata: this.errorLeads,
      entity_name: this.backend_user.cpa_firm_name,
      backend_user_id: this.backend_user.id,
      type: 'leads_notii',
    }

    this.taskInfoService.saveTaskInfo({ slug: 'leads_to_sba'}, data)
    .subscribe((res) => {
      let ref_id
      if(res && res.leads_to_sba && res.leads_to_sba.data && res.leads_to_sba.data.data && res.leads_to_sba.data.data.id)
      ref_id = res.leads_to_sba.data.data.id; 
      this.getLeadsProgress(ref_id);
    }, err => {
      this.commonService.popToast('error', 'Error', "Something went wrong.");
    })
  }

  getLeadsProgress(ref_id){
    this.taskInfoService.getTaskInfo({
      slug:  'get_leads_progress',
      id: ref_id
    }).subscribe((response) => {

     if(response && response.response_data && response.response_data.get_leads_progress.data && response.response_data.get_leads_progress.data.data){
        let res = response.response_data.get_leads_progress.data.data
        if(res.total_records != res.records_processed){
          this.getLeadsProgress(ref_id)
          this.commonService.popToast('warning', 'Warning', "Yoir client(s) have been submitted to funding specialist(s) but are still pending. We'll update you when the status changes.");
        }
        this.closeLeadsResult()
     }   
    }, err => {
      this.commonService.popToast('error', 'Error', "Something went wrong.");
    })
  }

  checkPlan(){
    if(this.plan === 'enterprise' || this.plan === 'premium' || this.plan === 'custom' || this.plan == "basic" || this.plan == "new_premium") {
      this.myPlanActive = true;
    }
  }

  ngOnDestroy(): void {
    this.videoNotifSubscription.unsubscribe();
    this.leadNotifSubscription.unsubscribe();
  }

}
