/// <reference types="@types/googlemaps" />
import {
  ComponentFactoryResolver,
  Directive,
  Input,
  ViewContainerRef,
  OnInit,
  OnDestroy,
  AfterViewInit
} from '@angular/core';
import { FormGroup } from '@angular/forms';
// import { } from 'googlemaps';
import { take, debounceTime, distinctUntilChanged } from 'rxjs/operators';

// Service
import { TaskInfoService, CommonService } from '@rubicon/utils_custom';


// Interfaces
import { FormFieldInterface } from '@rubicon/interface/form-field';

// Components
import {
  TextComponent, NumberComponent, PasswordComponent, RadioComponent,
  SliderComponent, CheckboxComponent, SwitchComponent, TelComponent, DateComponent,
  SelectComponent, GroupComponent, TextareaComponent, AmountComponent,MultiSelectComponent
} from '../components/form-fields';
import { FormService } from 'libs/dynamic-form/src/lib/services/form.service';

const componentMapper = {
  text: TextComponent,
  number: NumberComponent,
  tel_number: NumberComponent,
  email: TextComponent,
  password: PasswordComponent,
  radio: RadioComponent,
  range: SliderComponent,
  range_amount: SliderComponent,
  range_percentage: SliderComponent,
  checkbox: CheckboxComponent,
  switch: SwitchComponent,
  tel: TelComponent,
  date: DateComponent,
  select: SelectComponent,
  group: GroupComponent,
  textarea: TextareaComponent,
  amount: AmountComponent,
  hidden: null,
  'multi-select' : MultiSelectComponent
};

@Directive({
  selector: '[rubiconField]'
})
export class FieldDirective implements OnInit, OnDestroy, AfterViewInit {
  googleAddressdata: any = {}
  @Input() field: FormFieldInterface;
  @Input() group: FormGroup;
  @Input() slug: string;
  @Input() index:number;
  previousValue=null;
  taskMapping: any = {
    get: 'getTaskInfo',
    post: 'saveTaskInfo'
  }
  inputTypeMap = {
    select: 'select',
    textarea: 'textarea',
    text: 'input',
    switch:'input'
  };

  componentRef: any;
  eventField: any;
  addressFieldData = {
    lastStateId: ''
  };

  constructor(
    private common: CommonService,
    private resolver: ComponentFactoryResolver,
    private container: ViewContainerRef,
    private taskInfoService: TaskInfoService,
    private formService: FormService
  ) { }

  ngOnInit() {
    if (componentMapper[this.field.type]) {
      const factory = this.resolver.resolveComponentFactory(
        componentMapper[this.field.type]
      );
      this.componentRef = this.container.createComponent(factory);
      this.componentRef.instance.field = this.field;
      this.componentRef.instance.group = this.group;
      this.componentRef.instance.slug = this.slug;
      this.componentRef.instance.index = this.index;
      // if (this.field.event) {
        
      //   this.eventField = this.componentRef.location.nativeElement.querySelector(this.inputTypeMap[this.field.type]);
      //   console.log(this.eventField);
        
      //   if (this.eventField) {
      //     if (this.field.event.method === 'google') {
      //       this.eventField.addEventListener(this.field.event.type, this.geolocate.bind(this));
      //     } else {
      //       this.eventField.addEventListener(this.field.event.type, this.onEvent.bind(this));
      //     }
      //     if (this.field.event.type === 'change') {
      //       if(this.group.get(this.field.name).value !=this.previousValue || !this.group.get(this.field.name).value ){
      //         this.previousValue=this.group.get(this.field.name).value;
      //       this.group.get(this.field.name)
      //       .valueChanges
      //       .pipe(
      //       take(1)
      //       ).subscribe((val)=>{
      //         if(val){
      //         if ('createEvent' in document) {
      //           const evt = document.createEvent('HTMLEvents');
      //           evt.initEvent('change', false, true);
      //           this.eventField.dispatchEvent(evt);
      //         } else
      //           this.eventField.fireEvent('onchange');
      //          }  
      //          } );

      //     }
      //   }
      //   }
      // }
    }
  }

  ngAfterViewInit(): void {
    if (this.field.event) {
      this.eventField = this.componentRef.location.nativeElement.querySelector(this.inputTypeMap[this.field.type]);
      if (this.eventField) {
        if (this.field.event.method === 'google') {
          this.eventField.addEventListener(this.field.event.type, this.geolocate.bind(this));
        } else {
          this.eventField.addEventListener(this.field.event.type, this.onEvent.bind(this));
        }
        if (this.field.event.type === 'change') {
          if(this.group.get(this.field.name).value !=this.previousValue || !this.group.get(this.field.name).value ){
            this.previousValue=this.group.get(this.field.name).value;
          this.group.get(this.field.name)
          .valueChanges
          .pipe(
          take(1),
          debounceTime(500),
          distinctUntilChanged(),
          ).subscribe((val)=>{
            if(val){
            if ('createEvent' in document) {
              const evt = document.createEvent('HTMLEvents');
              evt.initEvent('change', false, true);
              this.eventField.dispatchEvent(evt);
            } else
              this.eventField.fireEvent('onchange');
             }  
             } );

        }
      }
      }
    }
  }

  geolocate(event) {

    const componentForm = {
      street_number: 'short_name',
      route: 'long_name',
      administrative_area_level_1: 'short_name',
      locality: 'long_name',
      postal_code: 'short_name'
    }
    const options = { componentRestrictions: { country: 'us' }, types: ['geocode'] };
    const autocomplete = new google.maps.places.Autocomplete(<HTMLInputElement>this.eventField, options);
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      let googleAddressdata:any={};
      for (let i = 0; i < place.address_components.length; i++) {
        const addressType = place.address_components[i].types[0];
        const val = place.address_components[i][componentForm[addressType]];
        googleAddressdata[addressType] = val;
      }
     
      this.group.patchValue({
        zip_code: googleAddressdata.postal_code,
        // city: googleAddressdata.locality,
        state: googleAddressdata.administrative_area_level_1
      });

      if(!this.field.event.nopupulate || (this.field.event.nopupulate && !this.field.event.nopupulate['city'])) {
        this.group.patchValue({
          city: googleAddressdata.locality,
        });
      }

      if (this.group.get('city')) {
        this.group.get(this.field.name).patchValue((googleAddressdata.street_number ? googleAddressdata.street_number + " " : '') + (googleAddressdata.route || ''));
      } else {
        this.group.get(this.field.name).patchValue(this.eventField.value);
      }
      const enableForSlugsArray = ['business-details-ppp2-1', 'applicant-details-ppp2', 'business-details-forgiveness-v2', 'term-loan-business-structure', 'term-loan-owners-details', 'owner_consent_config'];
      if (enableForSlugsArray.indexOf(this.slug) > -1) {
        this.group.get(this.field.name).patchValue(this.eventField.value);
        this.group.patchValue({ zip_code: googleAddressdata.postal_code });
        this.setSelectStateValue(googleAddressdata.administrative_area_level_1, googleAddressdata.locality);
        this.storeCityDataInStorage(googleAddressdata.locality);
      }
  });

  }

  onEvent(event) {

    if (!this.group.get(this.field.name).invalid && this.isValidKeyEvent(event,this.field.event.minChars)) {
      const payload = {};
      payload[this.field.name] = this.group.get(this.field.name).value;
      let params = {
        slug: this.field.event.action
      };
      if(this.field.event.method==='component'){
      this.common.sendEventListener({name:this.field.name,group:this.group,index:this.index,value:this.group.get(this.field.name).value,slug:this.slug});
      return;
    }
      if (this.field.event.method === 'get') {
        params = {
          ...params,
          ...payload
        }
      }
      this.taskInfoService[this.taskMapping[this.field.event.method]](params, payload).subscribe((data) => {
        if (this.field.event.method === 'get') {
          data = data.response_data;
        }
        if (data && data.nextTask) {
          if (data.nextTask.next === 'task') {
            if (data.nextTask.value === 'field') {
              this.common.sendEventCallData(this.field.name, this.field.event.action, data, { eventGroup:this.group, eventSlug: this.slug })
            } else {
              this.common.sendComponentCallData(this.field.name, this.field.event.action, data)
            }
          }
          if (data.nextTask.next === 'form' && data.nextTask.value === 'invalid') {
            this.group.controls[this.field.name].setErrors({ incorrect: true });
          }
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.eventField)
      this.eventField.removeEventListener(this.field.event.type, this.onEvent.bind(this));
  }

  isValidKeyEvent(event,minChars=3) {
    if (this.field.event.type.match('key')) {
      if (this.group.get(this.field.name).value.length >= minChars) {
        const code = (event.keyCode || event.which);

        // do nothing if it's an arrow key/special key
        if ((code >= 9 && code <= 45 && code !== 32) || (code >= 91 && code <= 93) || (code >= 112 && code <= 145)) {
          return false;
        }
        else {
          return true;
        }
      }
      else
        return false;
    }
    else {
      return true;
    }
  }

  setSelectStateValue(googleStateData: string, googleCityData: string) {
    const stateData = this.common.getDataFromStorage('states_data');
    const stateWithKey = stateData.find(state => state.key === googleStateData);
    if (stateWithKey) {
      this.group.patchValue({state: stateWithKey.id}, {emitEvent: true, onlySelf: false});
      if (this.addressFieldData.lastStateId === stateWithKey.id) {
        this.setCityFieldForSameState(googleCityData);
      }
      this.addressFieldData.lastStateId = stateWithKey.id;
    } else {
      this.group.patchValue({state: ''}, {emitEvent: true, onlySelf: false});
    }
  }

  storeCityDataInStorage(googleCityData: string) {
    if(googleCityData) {
      this.common.storeData('google_city_name', googleCityData);
    } else {
      this.common.storeData('google_city_name', '-');
    }
  }

  setCityFieldForSameState(cityName) {
    this.formService.groupFormEvent({data: {cityName}, group: this.group, index: this.index});
  }
}
