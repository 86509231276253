import { Injectable } from '@angular/core';
import { TaskInfoService } from '@rubicon/utils';
import * as _ from 'lodash';
import { from, Observable, of, Subject } from 'rxjs';
import { catchError, concatMap, map, mergeMap, reduce, switchMap, takeUntil } from 'rxjs/operators';
import { conditionHelperService } from './conditions.helper';

export interface TypeEvaluatorMap {
    [type: string]: any
}

@Injectable({
    providedIn: 'root'
})

export class EvaluatorHelperService {
    cacheEvaluateKeyConfig = {};
    constructor(private taskInfoService:TaskInfoService){
        
    }

    evaluationOperators:{[key:string]:(operand:any, operation:any, fromData:any)=>Observable<any>} = {
        'filter': (operand, operation, fromData) => {
            let result;
            if (operand?.length && operation?.condition?.length) {
                result = operand.filter((item) => {
                    let _item;
                    _item = { ...fromData, item };
                    return conditionHelperService.resolve(
                        _item,
                        operation.condition
                    ).resolved;
                });
            }
            return of(result);
        },
        'map': (operand, operation, fromData) => {
            let result;
            if (operand?.length) {
                result = _.map(operand, (item: any) => {
                    if (Array.isArray(operation?.filter_key) && operation?.filter_key.length > 0) {
                        return _.reduce(operation?.filter_key, (data: any, key: any) => {
                            data[key['request_key']] = _.get(item, key["request_value"]);
                            return data;
                        }, {})
                    } else {
                        return _.get(item, operation.filter_key);
                    }
                }); 
            }
            return of(result);
        },
        'join': (operand, operation, fromData) => {
            let result;
            if (operand?.length) {
                result = operation?.seperator ? (operand?.length > 0 ? operand.join(operation?.seperator) : operand) : operand.toString();
            }
            return of(result);
        },
        'find': (operand, operation, fromData) => {
            let result
            if (operand?.length && operation?.condition?.length) {
                result = operand.find((item) => {
                    let _item;
                    _item = { ...fromData, item };
                    return conditionHelperService.resolve(
                        _item,
                        operation.condition
                    ).resolved;
                });
            }
            return of(result);
        },
        'sort': (operand, operation, fromData)=>{
            let result:any;
            if (operand?.length && operation?.compareOperator) {
                result = operand.sort((item1, item2) => {
                    let orderedParams = [
                        item1[operation.operandA],
                        item2[operation.operandB],
                    ];
                    if (operation.reverse) {
                        orderedParams.reverse();
                    }
                    let result = conditionHelperService.compareOperators[
                        operation.compareOperator
                    ](...orderedParams);
                    return result;
                });
            }
            return of(result);
        },
        'get': (operand, operation, fromData) => {
            let result;
            if (typeof operand == "object") {
                let key = operation.key;
                if(operation.keyDynamic){
                    key = _.get(fromData, operation.keyDynamic);
                }
                result = _.get(operand, key);
            }
            return of(result);
        },
        'cloneDeep': (operand, operation, fromData) => {
            return of(_.cloneDeep(operand));
        },
        'setKey': (operand, operation, fromData) => {
            let value = ("staticValue" in operation)?operation.staticValue:_.get(fromData, operation.value);
            if(operation.keyDynamic){
                operand[_.get(fromData, operation.keyDynamic)] = value;
            }else{
                operand[operation.key] = value;
            }
            return of(operand);
        },
        'stringifyJSON': (operand, operation, fromData) => {
            return of(JSON.stringify(operand));
        },
        'parseJSON' : (operand, operation, fromData) => {
            return of(JSON.parse(operand));
        },
        'get_task_info': (operand, operation, fromData) => {
            let query = {};
            for (let key in operation?.query) {
                if (typeof operation?.query?.[key] == "object") {
                    query[key] = _.get(fromData, operation?.query?.[key]?.key);
                } else {
                    query[key] = operation?.query?.[key];
                }
            }
            let body = {};
            if(operation?.body){
                for (let key in operation?.body) {
                    if (typeof operation?.body?.[key] == "object") {
                        let value = _.get(fromData, operation?.body?.[key]?.key);
                        if(operation?.body?.[key]?.keyDynamic){
                            body[_.get(fromData, operation?.body?.[key]?.keyDynamic)] = value;
                        }else{
                            body[key] = value;
                        }
                    } else {
                        body[key] = operation?.body?.[key];
                    }
                }
            }
            return this.taskInfoService.getTaskInfo(query).pipe(
                map((response) => {
                    let value = _.get(response, operation.operand);
                    if(value === undefined){
                        return operation.defaultValue;
                    }
                    return value;
                }),
                catchError((response) => {
                    return of(null);
                }));
        }

    }

    getEvaluatedData(appData, evaluate_keys) {
        let evaluatedValues: any = {};
        if (evaluate_keys) {
            let fromData: any = {
                appData,
                evaluatedValues,
            };
            let task_keys = Object.keys(evaluate_keys);
            return from(task_keys).pipe(
                concatMap((task_key)=>{
                        let task = evaluate_keys[task_key];
                        if (task?.operations?.length) {
                            let previousOperatorData = {};
                            let tempOperatedValues = {};
                            fromData = { ...fromData, tempOperatedValues };
                            return this.performTaskOperations(task,fromData, previousOperatorData, tempOperatedValues).pipe(
                                map((result)=>{
                                    if (task?.keys_to_pick) {
                                        for (let config of task.keys_to_pick) {
                                            let key = config.key;
                                            if (config?.condition?.length) {
                                                if (
                                                    conditionHelperService.resolve(
                                                        { appData, tempOperatedValues, evaluatedValues },
                                                        config.condition
                                                    ).resolved
                                                ) {
                                                    evaluatedValues[key] = tempOperatedValues[key];
                                                }
                                            } else {
                                                evaluatedValues[key] = tempOperatedValues[key];
                                            }
                                        }
                                    }
                                    return result;
                                })
                            )
                        }
                }),
                reduce((acc: any, curr: any) => {
                    return evaluatedValues;
                }, {})
            )
        }
        return of(evaluatedValues);
    }

    performTaskOperations(task,fromData,previousOperatorData,tempOperatedValues){
        return from(task.operations).pipe(
            concatMap((operation:any)=>{
                let operand = operation.operand
                ? _.get(fromData, operation.operand)
                : previousOperatorData;
                let resolved = true;
                if (operation?.operation_validate?.length) {
                    resolved = conditionHelperService.resolve(
                            fromData,
                            operation.operation_validate
                        ).resolved;
                }
                if(resolved){
                    return this.evaluationOperators[operation.operator]?.(operand, operation, fromData).pipe(
                        map((result)=>{
                            previousOperatorData = result;
                            if (operation.result_key) {
                                tempOperatedValues[operation.result_key] = result;
                            }
                        }));
                }
                return of(resolved);

            }),
            reduce((acc: any, curr: any) => {
                return {};
            }, {})
        )
    }


    
    evaluateMultipleTypes(evaluate_key_types: string[], appData: any, actionDestroyed$: Subject<any>): Observable<TypeEvaluatorMap> {
        return from(evaluate_key_types).pipe(
            mergeMap((type)=>{
            if (this.cacheEvaluateKeyConfig[type]) {
                    let evaluateKeyConfig = this.cacheEvaluateKeyConfig[type];
                    let evaluateValueResolver: Observable<any> = this.evaluateKeyConfig(evaluateKeyConfig, appData, actionDestroyed$);
                    return evaluateValueResolver.pipe(
                        map((evaluatedValues)=>{
                            return {type, evaluatedValues}
                        })
                    );
                } else {
                    return this.taskInfoService.getTaskInfo({ slug: "evaluate_key_master", type }).pipe(
                    switchMap((res) => {
                        let evaluateKeyConfig = res?.response_data?.evaluate_key?.data?.data?.[0];
                        if (evaluateKeyConfig?.type == type) {
                            this.cacheEvaluateKeyConfig[type] = evaluateKeyConfig;
                            let evaluateValueResolver: Observable<any> = this.evaluateKeyConfig(evaluateKeyConfig, appData, actionDestroyed$);
                            return evaluateValueResolver.pipe(
                                map((evaluatedValues)=>{
                                    return {type, evaluatedValues}
                                })
                            );
                        }
                        return of({type, evaluatedValues:{error: true}});
                    }),
                    catchError((error)=>{
                        return of({type, evaluatedValues:{error: true}});
                    }));
                }
            }),
            reduce((acc: any, curr: any) => {
                acc[curr.type] = curr.evaluatedValues;
                return acc;
            }, {})
        )
    }

    evaluateKeyConfig(evaluateKeyConfig, appData, actionDestroyed$: Subject<any>): Observable<any> {
            let evaluatedValuesApi = of({});
            if (evaluateKeyConfig.evaluate_keys) {
                evaluatedValuesApi = this.getEvaluatedData(appData, evaluateKeyConfig.evaluate_keys).pipe(
                    takeUntil(actionDestroyed$)
                );
            }
            return evaluatedValuesApi;
    }

}