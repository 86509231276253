import { OnInit, Component, Input, SimpleChanges, OnChanges, ViewChild, TemplateRef, Output, EventEmitter, OnDestroy, Inject } from '@angular/core';
import { TaskInfoService } from '@rubicon/utils';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { of, ReplaySubject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'knockout-info-btn',
    templateUrl: './knockout-info-btn.component.html',
    styles: [".innerUl li:not(first-child){border-bottom: 1px solid gray;}"]
})
export class KnockoutInfoBtnComponent implements OnInit, OnChanges, OnDestroy {
    modalRef: BsModalRef;
    knockout_filter;
    pageLoaded = false;
    modalCss;
    @Input() business;
    @Input() fetch_business;
    @Input() app_id;
    @Input() btnCss;
    @ViewChild("knockoutInfoTemp") knockoutInfoTemp: TemplateRef<any>;
    @Output() modalClosed = new EventEmitter();
    compDestroyed$ = new ReplaySubject();
    knockoutMessages = {};
    pageTitles = {};
    modalSlug = "knockout_info";

    constructor(private taskInfoService: TaskInfoService,
        @Inject('cssList') public cssList,
        @Inject('environment' ) public environment,
        public modalService: BsModalService) {
    }

    ngOnInit(): void {
        if(this.environment.journeyType === 'banker-journey'){
            if (this.fetch_business && this.app_id) {
                this.getBusinessData();
            }
        }
        this.modalCss = this.cssList?.knockout_info || {};
    }

    ngOnChanges(simpleChanges: SimpleChanges) {
        if(this.environment.journeyType === 'banker-journey'){
            if (!simpleChanges?.app_id?.firstChange && simpleChanges?.app_id?.currentValue && this.fetch_business) {
                this.getBusinessData();
            }
        }
    }

    getKnockoutfilterMaster() {
        if (!this.knockout_filter && this.business?.knockoutFlag?.is_knockout) {
            return this.taskInfoService.getTaskInfo({ slug: "master_data_optimised", model: "knockout_filter" }).pipe(
                takeUntil(this.compDestroyed$)
            ).pipe(map(res => {
                this.knockout_filter = res?.response_data?.master_data_optimised?.data?.data?.knockout_filter;
                return res;
            }));
        }
        return of({});
    }

    getBusinessData() {
        if (!this.business) {
            this.taskInfoService.getTaskInfo({ slug: "app_detail_optimized", app_id: this.app_id, child_ref: "business" }).pipe(
                takeUntil(this.compDestroyed$)
            ).subscribe((res) => {
                this.business = res?.response_data?.app_detail_optimized?.data?.data?.[0]?.business?.[0];
                if (this.knockout_filter) {
                    this.createMapping();
                }
            });
        }
    }

    createMapping() {
        this.knockoutMessages = {};
        let knockoutFlag = this.business?.knockoutFlag || {};
        let knockout_values = [];
        for(let type in knockoutFlag){
            if(typeof knockoutFlag[type] == "object"){
                for(let value in knockoutFlag[type]){
                    if(knockoutFlag[type][value]){
                        knockout_values.push(value);
                    }
                }
            }
        }
        let messageKeyMap = {};
        if(this.knockout_filter){
          for(let item of this.knockout_filter){
              if(knockout_values.includes(item.value)){
                  if(item?.config?.input_fields?.length){
                      for(let {slug, field} of item?.config?.input_fields){
                          if(!this.knockoutMessages[slug]){
                              this.knockoutMessages[slug] = [];
                          }
                          let messageKey = `${slug}.${field}`;
                          if(!messageKeyMap[messageKey]){
                              messageKeyMap[messageKey] = true;
                              this.knockoutMessages[slug].push({field});
                          }
                      }
                  }
              }
          }
        }
        this.pageLoaded = true;
    }

    showKnockoutInfo() {
        if (!this.knockout_filter) {
            this.getKnockoutfilterMaster().subscribe(() => {
                this.createMapping();
                this.openModal();
            });
        } else {
            this.openModal();
        }
    }

    openModal() {
        this.modalRef?.hide();
        this.modalRef = this.modalService.show(this.knockoutInfoTemp, {
            id: 904,
            class: 'modal-lg knockoutInfoModal',
            backdrop: 'static',
        });

        this.modalRef.onHidden.pipe(
            takeUntil(this.compDestroyed$)
        ).subscribe(data => {
            this.modalClosed.emit();
        });
    }

    public closeModal() {
        this.modalRef?.hide();
    }

    ngOnDestroy() {
        this.compDestroyed$.next();
        this.compDestroyed$.complete();
    }


}