import { Component, EventEmitter, Input, OnInit, Output, Inject } from '@angular/core';


@Component({
  selector: 'ui-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
  @Input() total: number;
  @Input() limit: number;
  @Output() pageDetails = new EventEmitter<Object>();
  perPageRecords = [];
  paginationMaxSize = 5;
  currentPage = 1;
  perPageRecordChange = false;
  skip = 0;
  selectLimit: number;
  currentRecords: number;
  constructor(
    @Inject("CONSTANTS") public CONSTANTS
  ) { }
 
  ngOnInit(): void {
    this.selectLimit = this.limit;
    this.perPageRecords = this.CONSTANTS.PER_PAGE_RECORDS;
    this.calCurrentRecords();
  }
  ngOnChanges(): void {
    if(this.limit) {
      setTimeout(() => {
        this.currentPage = 1;
        this.calCurrentRecords();
      })
    } 
  }
 
  pageChanged(event) {
    if(this.limit === event.itemsPerPage && !this.perPageRecordChange) {
      this.currentPage = event.page - 1;
      this.skip = (event.page - 1)  * this.limit;
      this.calCurrentRecords();
      this.pageDetails.emit({limit: this.limit, skip: this.skip, perPageRecordChange: false});
    }
  }
 
  onPerPageChange(event) {
    if (this.limit !== event.value) {
      this.perPageRecordChange = true;
      this.limit = event.value;
      this.skip = 0;
      this.pageDetails.emit({limit: this.limit, skip: this.skip, perPageRecordChange: true});
      setTimeout(() => {this.perPageRecordChange = false;})
    }
  }

  calCurrentRecords() {
    let n = this.total - this.skip;
    if ( n > this.limit) {
      this.currentRecords = this.skip +  this.limit; 
    }  else {
      this.currentRecords = this.skip +  n;
    }
  }
 
}

