export interface SignupTaskInfoInterface {
    // process: string;
    // subprocess: string;
    onload_api: any[];
    task_slug: string;
    form_fields: any[];
  }

// export interface CollateralInfoInterface{
//   task_slug: string;
//   form_fields: any[];
//   response_data:any;
// }