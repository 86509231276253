<header>
    <div class="container">
        <div class="row">
            <div class="col-6">
                <a class="navbar-brand pr-lg-4" href="javascript:void(0)"><img [src]="cdnUrl+'logo.png'"
                        alt="" class="logoh"/></a>
            </div>
            <div class="col-6 text-right" *ngIf="fullName">
                <div class="d-flex float-right userInfo">
                    <div class="uname">Logged in as <strong>{{fullName}}</strong></div>                    
                    <div class="nav-item dropdownMenu">
                        <a class="nav-link dropdownlink userDoted" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> ...</a>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
                            <ul class="list-group list-group-flush navultop">
                            <li role="menuitem"><a class="dropdown-item" href="javascript:void(0)" (click)="logout()">Logout</a></li>
                            <!-- <li class="border-top py-2 mt-1" role="menuitem"><a class="dropdown-item" href="javascript:void(0)">Loan Forgiveness Calculator</a></li> -->
                        </ul>
                        </div>
                      </div>
                </div>
            </div>
        </div>
    </div>
    </header>