<ng-template #modalTemplate>
    <div [ngClass]="(modalCss?.header)? modalCss?.header :'modal-header pl-3'">
        <h4 [ngClass]="(modalCss?.title)? modalCss?.title :'modal-title pull-left'" [innerHTML]='(modalSlug + ".label.mdl_title" | translate) == (modalSlug + ".label.mdl_title")?default_knockout_modal.label.mdl_title:(modalSlug + ".label.mdl_title" | translate)'></h4>
        <button type="button" [ngClass]="(modalCss?.close)? modalCss?.close :'close pull-right'" aria-label="Close" (click)="onButtonClick('cancel')">
            <span  aria-hidden="true">&times;</span>
        </button>
    </div>
    <div [ngClass]="(modalCss?.css?.mdl_body)? modalCss?.css?.mdl_body :'modal-body pb-0'">
        <div [ngClass]="(modalCss?.message)? modalCss?.message :'fs18 pb-2'" [innerHTML]='knockoutFilter?.config?.message'></div>
    </div>
    
    <div [ngClass]="(modalCss?.footerWrapper)? modalCss?.footerWrapper :'modal-footer'">
        <div [ngClass]="(modalCss?.footerInnerWrapper)? modalCss?.footerInnerWrapper:'pl-1'" *ngIf="knockoutFilter?.config?.buttons">
            <button [ngClass]="(modalCss && modalCss[button_id])? modalCss[button_id]:'btn btn-default btn-md ml-3 mb-2'" *ngFor="let button_id of knockoutFilter.config.buttons"  [innerHTML]='(modalSlug + ".label."+button_id | translate) == (modalSlug + ".label."+button_id)?default_knockout_modal.label[button_id]:(modalSlug + ".label."+button_id | translate)' (click)="onButtonClick(button_id)"></button>
        </div>
    </div>
</ng-template>


<ng-template #declineConfirmModalTemplate>
    <div [ngClass]="(declineConfCssJson?.header)? declineConfCssJson?.header :'modal-header pl-3'">
        <h4 [ngClass]="(declineConfCssJson?.title)? declineConfCssJson?.title :'modal-title pull-left'" [innerHTML]='(declineConfirmModalSlug + ".label.confirm_mdl_title" | translate) == (declineConfirmModalSlug + ".label.confirm_mdl_title")?default_knockout_modal.label.confirm_mdl_title:(declineConfirmModalSlug + ".label.confirm_mdl_title" | translate)'></h4>
        <button type="button" [ngClass]="(declineConfCssJson?.close)? declineConfCssJson?.close :'close pull-right'" class="" aria-label="Close" (click)="closeDeclineConfirmModal()">
            <span  aria-hidden="true">&times;</span>
        </button>
    </div>
    <div [ngClass]="(declineConfCssJson?.mdl_body)? declineConfCssJson?.mdl_body :'modal-body pb-0'">
        <div [ngClass]="(declineConfCssJson?.message)? declineConfCssJson?.message :'fs18 pb-2'" [innerHTML]='(declineConfirmModalSlug + ".label.confirm_message" | translate) == (declineConfirmModalSlug + ".label.confirm_message")?default_knockout_modal.label.confirm_message:(modalSlug + ".label.confirm_message" | translate)'></div>
    </div>
    <div [ngClass]="(declineConfCssJson?.footerWrapper)? declineConfCssJson?.footerWrapper :'modal-footer'">
        <div [ngClass]="(declineConfCssJson?.footerInnerWrapper)? declineConfCssJson?.footerInnerWrapper:'pl-1'" *ngIf="knockoutFilter?.config?.declineConfirmButtons">
            <button [ngClass]="(modalCss && modalCss[button_id])? modalCss[button_id]:'btn btn-default btn-md ml-3 mb-2'"  *ngFor="let button_id of knockoutFilter.config.declineConfirmButtons" [innerHTML]='(declineConfirmModalSlug + ".label."+button_id | translate) == (declineConfirmModalSlug + ".label."+button_id)?default_knockout_modal.label[button_id]:(declineConfirmModalSlug + ".label."+button_id | translate)' (click)="onButtonClick(button_id)"></button>
        </div>
    </div>
</ng-template>