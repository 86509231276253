import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { ToasterModule } from 'angular2-toaster';
import { NumberDirective } from './directives/number/number.directive';
import { ScrollDirective } from './directives/scroll/scroll.directive';
import { AmountDirective } from './directives/amount/amount.directive';
import { SortAlphaPipe } from './pipes/sort-alpha.pipe';
import { SortListingPipe } from './pipes/sort-listing.pipe';
import { ConfirmModalComponent } from './modals/confirm-modal/confirm-modal.component';
import { StoreModule } from '@ngrx/store';
import { reducer } from './+state/utils.reducer'
import { BackNavigateGuard } from './guard/back-navigate.guard';
import { DateTimezonePipe } from './pipes/date-timezone.pipe';
import { InputFilledDirective } from './directives/input/input-filled.directive';
import { CanAccessDirective } from './guard/canAccess.directive';
import { MasterDataPipe } from './pipes/master-data.pipe';
import { SafePipe } from './pipes/safe-html.pipe';
import './interfaces/number';
import { BlockCopyPasteDirective } from './directives/copyPaste/block-copy-paste.directive';
import { SsnCommonPipe } from './pipes/ssn-common.pipe';
import { FilterPipe } from './pipes/faq-filter.pipe';
@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    ToasterModule.forRoot(),
    StoreModule.forFeature(
      'util', reducer
    )
  ],
  declarations: [NumberDirective, ScrollDirective, AmountDirective,BlockCopyPasteDirective, SortAlphaPipe, SortListingPipe,  ConfirmModalComponent, DateTimezonePipe, InputFilledDirective, CanAccessDirective,MasterDataPipe, SafePipe, BlockCopyPasteDirective, SsnCommonPipe, FilterPipe],
  exports: [NumberDirective, ScrollDirective, AmountDirective,BlockCopyPasteDirective, SortAlphaPipe, SortListingPipe,  ConfirmModalComponent, DateTimezonePipe, InputFilledDirective, CanAccessDirective,MasterDataPipe, SafePipe, SsnCommonPipe, FilterPipe]
})
export class UtilsModule { }