<footer>
    <div class="container">
        <div class="row">
            <div class="col-12 col-lg text-lg-left text-center">
                <ul>
                    <li><a target="_blank" href="https://www.biz2credit.com/privacy-policy/">Privacy</a></li>
                    <li><span class="sapretor">|</span><a target="_blank" href="/terms-of-use/">Terms</a></li>
                    <!-- <li><span class="sapretor">|</span><a target="_blank" href="https://www.biz2credit.com/site-index">Sitemap</a></li> -->
                </ul>
                <p>&copy; <a target="_blank" href="https://www.biz2credit.com/">Biz2Credit Inc 2023</a>. All rights reserved.</p> 
            </div>
            <div class="col-12 col-lg-auto text-center">
                <span class="img-fluid d-block mt-2"><img src="assets/images/cpa-logo.png" alt="logo" class="img-fluid" style="width: 190px;"></span>
            </div>

            <div class="col-12 col-lg text-center text-lg-right mt-2 pl-lg-0">
                <!-- <span class="d-sm-none d-inline-block mr-3"><img src="assets/images/cpa-logo.png" alt="logo" class="img-fluid" style="width: 210px;"></span> -->
                <a class="d-inline-block" href="https://biz2x.com/" target="_blank"><img src="assets/images/biz2x-Logo.svg" /></a>
            </div>
        </div>

    </div>
</footer>