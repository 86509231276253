import { Component, OnInit, ViewChild, ElementRef, Inject, AfterViewInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { CommonService } from '@rubicon/utils_custom';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { CUSTOMER_PORTAL } from '@rubicon/interface/task-info';
import { TaskInfoService } from '@rubicon/utils_custom';
import { logout } from '../+state/ui-kit.action';

@Component({
  selector: 'rubicon-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit {

  userData: any;
  rootStateSubscription: Subscription;

  @ViewChild('themeRef') themeRef: ElementRef;

  constructor(private store: Store<any>, @Inject(DOCUMENT) private document: Document,
    private common: CommonService, private taskInfoService: TaskInfoService) { }

  ngOnInit() {
    this.rootStateSubscription = this.store.pipe(select('app'))
      .subscribe(rootState => {
        if (rootState.userData && rootState.userData.user) {
          this.userData = rootState.userData;
          this.userData.user['full_name'] = rootState.userData.user.first_name + ' ' + rootState.userData.user.last_name;
        }
      });
  }

  ngAfterViewInit() {
    if (this.common.getDataFromStorage('theme')) {
      this.document.body.classList.add(this.common.getDataFromStorage('theme'));
      if (this.common.getDataFromStorage('theme') === 'dark-mode') {
        this.themeRef.nativeElement.checked = true;
      }
    } else {
      this.document.body.classList.add('light-mode');
    }
  }

  /**
   * function to set theme
   */
  setTheme() {
    if (this.themeRef.nativeElement.checked) {
      this.document.body.classList.remove('light-mode');
      this.document.body.classList.add('dark-mode');
      this.common.storeData('theme', 'dark-mode');
    } else {
      this.document.body.classList.remove('dark-mode');
      this.document.body.classList.add('light-mode');
      this.common.storeData('theme', 'light-mode');
    }
  }

  /**
   * function to logout
   */
  logout() {
    this.document.body.classList.remove('dark-mode');
    this.document.body.classList.add('light-mode');

    this.taskInfoService.saveTaskInfo({
      slug: CUSTOMER_PORTAL.logout
    }, {}).subscribe((res: any) => {
      this.common.setLogin(false);
      if (res && res.nextTask) {
        this.common.navigateByUrl(res.nextTask.value);
      }
      this.store.dispatch(logout({}));
    });
  }
}
