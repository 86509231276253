import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { UiKitComponent } from './ui-kit/ui-kit.component';


const routes: Routes = [
    {
        path: '',
        component: UiKitComponent,
        children: [
            {
                path: 'page-not-found',
                component: PageNotFoundComponent
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class UIKitRoutingModule { }
