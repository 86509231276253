import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CommonService, FormGenerateService } from '@rubicon/utils';

@Component({
  selector: 'filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {
  @Output() onSearch = new EventEmitter<Object>();
  @Input() filterConfig: any;
  @Input() slug: string;
  @Input() formValues: any;
  filterForm: FormGroup
  constructor(
    private formGenerate: FormGenerateService,
    private common: CommonService
  ) {}

  ngOnInit(): void {
    this.filterForm = this.formGenerate.createControl(this.filterConfig);
    if(this.formValues && !Object.keys(this.formValues).map(e=>this.formValues[e]).every(x => (x === null || x === ''))){
      this.formGenerate.setFormValues(this.filterForm, this.formValues);
    }
  }

  onFilter() {
    if(this.formGenerate.validateCustomFormFields(this.filterForm, 'continue', this.filterConfig )) {
      this.onSearch.emit(this.filterForm.value);
    }
  }

}
