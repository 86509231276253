import { Injectable } from '@angular/core';
import { conditionHelperService } from '../utils/conditions.helper';
@Injectable({
    providedIn: 'root'
})

export class CertificateLetterService {

  constructor() {}

  conditionConfig(config: any ){
    if(!config) return true;

    if(config?.enabled){
        if(config?.required){
            return true;
        }
        return false;
    }
    else{
        return false;
    }
  }
}
