import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldInterface } from '@rubicon/interface/form-field';
import { CommonService } from '@rubicon/utils_custom';
import { FormService } from '../../../services/form.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'rubicon-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss']
})
export class MultiSelectComponent implements OnInit {
  field: FormFieldInterface;
  group: FormGroup;
  slug: string;
  indexVal: number;
  index: number;
  dropdownSettings: IDropdownSettings;
  dropdownList = [];
  selectedItems = [];
  placeholder = '';
  isRequired: boolean = false;
  hoverValue :string = '';
  leftValue:any= '0';

  constructor(
    private common: CommonService,
    private formService: FormService
  ) { };

  ngOnInit() {
    let select_all = true;
    if(this.field && this.field.hasOwnProperty('selectAll') && this.field['selectAll']===false){
      select_all = false;
    }
    
      for(const ele of this.field.validations){
        if(ele.name=='required'){
          this.isRequired = true;
          break;
        }
      }
    
    
    this.loadDropDownSettings(select_all, this.field.max_limit);

    
    this.placeholder = 'Select ' + this.field.name;
    this.group.get(this.field.name).valueChanges.subscribe((data) => {
      this.formService.emit(data);
    });

    this.group.get(this.field.name).valueChanges.subscribe((data) => {
      this.formService.emitFieldEvent({ group: this.group, data, index: this.index });
    });

    this.indexVal = new Date().getTime();
    this.common.masterData
      .subscribe((data: any) => {
        if (data && data[this.field.name] && data[this.field.name].data && data[this.field.name].data.data) {
          this.field['options'] = data[this.field.name].data.data.map(item => {
            return {
              key: item.value,
              value: item.id,
              record_id: item.id 
            };
          });
          // if(data.multiSelectData && data.multiSelectData[this.field.name] &&Object.keys(data.multiSelectData).length){
          //   this.selectedItems = data.multiSelectData[this.field.name];
          // }
          // console.log('this.selectedItems',this.selectedItems);
          // this.group.get(this.field.name).patchValue(this.selectedItems);
         
        }
      });
      
      
  }

  loadDropDownSettings = (select_all,maxLimit?) => {    
    if(select_all===true){
      this.dropdownSettings = {
        singleSelection: false,
        textField: 'key',
        idField: 'value',
        selectAllText: '',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 0,
        allowSearchFilter: true,
        limitSelection:maxLimit
      };
    } else {
      this.dropdownSettings = {
        enableCheckAll:false,
        singleSelection: false,
        textField: 'key',
        idField: 'value',
        itemsShowLimit: 10,
        allowSearchFilter: true,
      };
    }

  };

 onItemSelect(item: any) {
    const index = this.selectedItems.findIndex((data) => data.value === item.value);
    if(index === -1) {
      this.selectedItems.push(item);
    }
    this.common.emitMultiSelectItem({field_name : this.field.name,items:[...this.selectedItems]});
  };


  onDeSelect(item: any) {
    const index = this.selectedItems.findIndex((data) => data.value === item.value);
    if(index > -1) {
      this.selectedItems.splice(index, 1);
    }
    this.common.emitMultiSelectItem({field_name : this.field.name,items:[...this.selectedItems]});
  }

  onSelectAll(items: any) {
    this.selectedItems = [...items];
    this.common.emitMultiSelectItem({field_name : this.field.name,items:[...this.selectedItems]});
  }

  onDeSelectAll(items: any) {
    this.selectedItems = [];
    this.common.emitMultiSelectItem({field_name : this.field.name,items:[...this.selectedItems]});
  }

  over(event){
    if(this.selectedItems.length > 1){
      if(event.target.outerText.trim() == this.selectedItems[0].key.trim()){
        this.hoverValue = event.target.outerText
        this.leftValue = "33";
      }else if(event.target.outerText.trim() == this.selectedItems[1].key.trim()){
        this.hoverValue = event.target.outerText
        this.leftValue = "60";
      }else{
        this.hoverValue = "";
      }
    }else if(this.selectedItems.length > 0){
      if(event.target.outerText.trim() == this.selectedItems[0].key.trim()){
        this.hoverValue = event.target.outerText
        this.leftValue = "47";
      }
    }else{
      this.hoverValue = ""
    }
  }

  out(){
    this.hoverValue = ""
  }
  
}
