<ng-container [formGroup]="group">
    <!-- <div [ngClass]="(field?.css?.labelClass)? field?.css?.labelClass :'fs15 fwb ptc pb-2'"
        [innerHTML]='slug + ".label." + field.name | translate'>
    </div>
    <span class="tooltipClass" *ngIf= "field?.css?.tooltip">
        <a href="javascript:void(0)" [tooltip]='slug + ".tooltip."+ field.name | translate'>
         <img src="{{environment?.asset_path}}base/images/info-icon-n.svg" alt="tooltip" width="12" /> 
        </a>
    </span> -->
    <div [ngClass]="(field?.css?.groupWrap)? field?.css?.groupWrap :''">
        <label [ngClass]="(field?.css?.labelClass)? field?.css?.labelClass :'fs15 fwb ptc'"
            [for]="field.name + '_' + indexVal">
            <span class="labelinspan" [innerHTML]='slug + ".label." + field.name | translate'></span>
            <ng-container *ngIf="field?.css?.tooltip && field?.css?.tooltip[field.name]">
                <a  href="javascript:void(0)"
                [tooltip]="popTemplateth" 
                [triggers]="(field?.css?.tooltip[field.name+ '_trigger'])?field?.css?.tooltip[field.name+ '_trigger']:'hover'" 
                [ngClass]="field?.css?.tooltip[field.name]?field?.css?.tooltip[field.name]:'ml-1'">
                  <img src="{{environment?.asset_path}}base/images/info-icon-n.svg" alt="tooltip"  class="infoimg" /> </a>
                <ng-template #popTemplateth>
                 <div class="fs12 text-left fwr my-1 gtColor" [innerHTML]='slug + ".tooltip."+ field.name | translate'></div>
            
            </ng-template>
              </ng-container>
        </label>
         
    <div [ngClass]="(field?.css?.wrapper)? field?.css?.wrapper :'row col-12 radioWrap'">
        <div *ngFor="let val of field.options" [ngClass]="field?.css?.inputParent ? field?.css?.inputParent: ''"
            [class.activeBox]="val[valueKey] === group.get(field.name).value">
            <label [ngClass]="(field?.css?.label)? field?.css?.label :'form-check-label rlt'"
                [for]="val.type +'_' + indexVal" [class]="val.type">
                <input [ngClass]="field?.css?.field? field.css.field:'form-check-input'" type="radio" [value]="val[valueKey]"
                    [formControlName]="field.name" [id]="val.type +'_' + indexVal">
                <div [ngClass]="(field?.css?.iconClass)? field?.css?.iconClass :''" [class]="val.type"></div>
                <span [ngClass]="(field?.css?.spanClass)? field?.css?.spanClass :'rlt'">
                    {{ val[displayKey] ? val[displayKey] : slug + ".label." + val.type | translate}}
                    <span class="radioIntooltip" *ngIf= "val?.tooltip">
                        <a href="javascript:void(0)" [tooltip]="val?.tooltip" class="ml-1 cpd">
                         <img src="{{environment.asset_path}}base/images/radio-info.svg" alt="tooltip"  class="infoimg" /> </a>
                    </span>
                </span>
                
              
            </label>
        </div>
    </div>
        <div *ngIf="group.get(field.name).invalid && (group.get(field.name).dirty || group.get(field.name).touched)"
        [ngClass]="(field?.css?.errorMes)? field?.css?.errorMes :'error-msg'" [ngClass]="(field?.css?.error)? field?.css?.error :''">
            <ng-container *ngFor="let validation of field.validations;">
                <label class="error" *ngIf="group.get(field.name).hasError(validation.name)">
                    {{slug + ".errorMessage." + field.name + "." + validation.name | translate}}
                </label>
            </ng-container>
        </div>
    </div>

</ng-container>