<header>
    <div class="headerinner">  
        <div class="container">
            <div class="row logoRow">
                <div class="col-12 col-sm">
                    <div>
                        <ng-template [ngTemplateOutlet]="landingLogo"></ng-template>
                    </div>
                </div>
                <!-- <div class="col-12 col-sm text-sm-right">
                    <div class="d-flex justify-content-end">
                        <span class="d-none d-md-inline-block mr-lg-4 mr-3 mt-2 pt-1" *ngIf="showHeader"><img src="/assets/base/images/headerlogo.svg" alt="logo" class="img-fluid" style="width: 108px;"></span>
                        <p class="fs10 pt-sm-4 mt-2" *ngIf="!showHeader">Developed by Biz2Credit. All rights reserved.​</p>
                    </div>
                </div> -->
                <!--<div class="col-12 col-sm text-center text-sm-right">
                    <div class="headRightLink">
                        <span *ngIf="showHeader" class="d-none d-md-inline-block cpalogo"><img src="/assets/base/images/headerlogo.svg" alt="logo" class="img-fluid" style="width: 108px;"></span>
                        <a class="btn btn-secondary fs16 ffm text-upper loginbtn fwb mt-sm-0 mt-2 ml-lg-4 ml-2 " href="javascript:void(0)" *ngIf="showLoginBtn"  [routerLink]="['/signin']">Login</a>
                         <span class="d-none d-md-inline-block mr-lg-4 mr-3 mt-2 pt-1" *ngIf="showHeader"><img
                            src="/assets/base/images/headerlogo.svg" alt="logo" class="img-fluid" style="width: 108px;"></span>
                            <div *ngIf="!showHeader">
                                <p class="fs10 mt-2 mb-1">Developed by Biz2Credit. All rights reserved.​</p>
                            </div>                        
                    </div>
                </div>-->
                <div class="col-md-12 col-lg-6 ml-auto text-lg-right pl-sm-0 text-center">
                    <div class="headRightLink d-flex h-100 align-items-center justify-content-end" *ngIf="questionLabel">
                        <!--<span class="d-none d-md-inline-block mr-lg-4 mr-2 cpalogo"><img src="/assets/base/images/headerlogo.svg" alt="logo" class="img-fluid" style="width: 108px;"></span>-->
                         <ng-container *ngIf="!hideMailAndQuestion">
                             <a>Questions?</a>
                             <span class="sapretor">|</span>
                             <a class="mr-3" target="_blank" href="mailto:{{supportMail}}"><span class="email2"></span>{{supportMail}}</a>   
                         </ng-container>
                         <!-- <br class="d-lg-none d-block">
                         <span class="sapretor d-none d-lg-inline-block">|</span>
                         <span class="stc fs12 fwsb pr-3"><span class="pcall mr-1"></span> 866.986.9323</span>                      -->
                         <a class="btn btn-secondary fs16 ffm text-upper loginbtn fwb mt-sm-0 mt-2" href="javascript:void(0)" *ngIf="showLoginBtn"  [routerLink]="['/signin']">Login</a>
                     </div>
         
                 <div class="d-flex justify-content-end mt-2 pt-1" *ngIf="isLoggedIn">
                     <!--<span class="d-none d-md-inline-block mr-lg-4 mr-2 mt-4 pt-1 fs12 fwm"><a href="javascript:void(0)" (click)="fundingProgram()" class="ptc uline">See Funding Programs​</a></span>-->
                     <!-- <span class="d-none d-md-inline-block mr-lg-4 mr-3 mt-2 pt-1"><img src="/assets/base/images/headerlogo.svg" alt="logo" class="img-fluid" style="width: 108px;"></span> -->
                     <ng-container *ngIf = "!planType && planFeatureConfig[plan_id]?.upgrade === true">
                     <span *ngIf="show_upgrade_plan" ><a *ngIf="!hideUpgrade && registration_flag" class="btn btn-primaryDark btn-sm mr-lg-2 mr-2 upgradebtn" href="javascript:void(0);" [routerLink]="['/plans']"><img src="/assets/base/images/UpgradeWhite.svg" alt="logo" class="img-fluid"> Upgrade</a></span>
                     </ng-container>
                     <div *ngIf="show_faq_btn">
                         <a class="px-2 mr-2 faqBtn" href="javascript:void(0);" (click)="openModalWithClass(template)">FAQs</a>
                     </div>
                     
                     <div class="notificationWrap" dropdown placement="bottom right">
                         <button id="button-alignment" dropdownToggle type="button" class="notiicon" aria-controls="dropdown-alignment" [ngClass]="{'active': unread_count > 0}">
                             <span *ngIf="10 >= unread_count  && unread_count > 0 " class="not-count">{{ (unread_count <= 10) ? unread_count: unread_count}}</span>
                             <span *ngIf="unread_count > 10 > 0" class="not-count">{{ '10+' }}</span>
 
                         </button>
                         <ul id="dropdown-alignment" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
                             aria-labelledby="button-alignment">
                             <li class="px-3 border-bottom pb-2">
                                 <div class="row">
                                     <div class="col-12 col-lg-6 text-left fwsb fs15">Activity</div>
                                     <div  *ngIf="notifications.length" class="col-12 col-lg-6 text-right"><a class="ptc fs12 fwsb" href="javascript:void(0)" (click)="markAllRead()" >Mark all as read</a></div>
                                 </div>
                             </li>
                             <li>
                                 <!-- <notification-listing [notifications]="notifications"></notification-listing> -->
                                 <ul class="subList">
                                     <ng-container  *ngFor="let notification of notifications">
                                         <!-- <notification-item [notification]="notification"></notification-item>    -->
                                         <li class="row px-3 py-2 border-bottom notification" [ngClass]="notification.is_read ? 'read-notification' : 'unread-notification'" (click)="markAsRead(notification, notification._id, i, notification.is_read)">
                                             <div class="col">
                                                 <div class="noti-head">{{notification.header}}</div>
                                                 <a class="fwsb cp">{{notification.title}}</a>
                                                 <div class="timel">{{notification.createdAt | date:'medium'  }}</div>
                                             </div>
                                         </li>
                                     </ng-container>
                                 </ul>
                                 <ul *ngIf="!notifications.length">
                                     <li class="notification " >
                                         <p class="fs13 mr-4 pt-3 text-center">No Notifications</p>
                                     </li>
                                 </ul>
                             </li>
                             <li class="px-3">
                                 <div class="row">
                                     <div  *ngIf="notifications.length" class="col-12 col-lg-12 text-left pt-2"><a class="ptc fs12 fwsb" href="javascript:void(0)"
                                             [routerLink]="['/notification-center/list']">View all</a></div>
                                 </div>
                             </li>
                         </ul>
                     </div>
 
                     <div class="btn-group" dropdown placement="bottom right">
                         <button id="button-alignment" dropdownToggle type="button" class="doted3 dropdown-toggle" aria-controls="dropdown-alignment">
                                 &nbsp;
                         </button>
                         <ul id="dropdown-alignment" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-alignment">
                            <!-- <li role="menuitem"><a class="dropdown-item cp" (click)="onClick('dashboard')">Dashboard</a></li> -->
                            <!-- <ng-container *appCanAccess="'master_manage_users'">
                                <li role="menuitem" *ngIf="manageUser"><a class="dropdown-item cp" (click)="onClick('manage-user')">Manage Users </a></li>
                            </ng-container> -->
                            <li *ngIf="registration_flag" role="menuitem"><a class="dropdown-item cp" (click)="onClick('profile-setting')">{{newUser? 'CPA Directory' :'Company Profile'}}</a></li>
                            <li role="menuitem"><a class="dropdown-item cp" (click)="onClick('account-setting')">Account Settings</a></li>
                            <ng-container *appCanAccess="'master_manage_users'">
                                <li role="menuitem" *ngIf="manageUser && myPlanActive"><a class="dropdown-item cp" (click)="onClick('manage-user')">Manage Users </a></li>
                            </ng-container>
                            <li role="menuitem" *ngIf="myPlanActive && registration_flag"><a class="dropdown-item cp" (click)="onClick('my-plan')">My plan / Reports</a></li>
                            <li role="menuitem"><a class="dropdown-item cp" (click)="onClick('change-password')">Change Password</a></li>
                            <li role="menuitem"><a class="dropdown-item cp" (click)="onClick('logout')">Logout</a></li>
                        </ul>
                     </div>

                     
                    <ng-container *ngIf="show_resource_hub">
                        <a class="btn resourceBtn mr-lg-2 mr-2 mt-3" href="javascript:void(0);" [routerLink]="['/resource-hub']">Resource Hub</a>
                    </ng-container>
                 </div>
             </div>
            </div>
        </div>
    </div>
    <!-- <div *ngIf="isLoggedIn" style="background: #6fb253; border: none; color: #ffffff!important;" class="alert alert-success fs11 alert-dismissible fade show radius0 m-0 px-0 px-lg-4" role="alert">
        <div class="container">            
            <div class="sbaAlert pl-0">
                <span class="fwb fs12">IMPORTANT UPDATE: Please Expect Longer Processing Times for Newly Submitted Applications </span> <span class="fwm fs12">We continue to make steady progress processing and funding PPP applications. Currently, Biz2Credit has posted the highest number of approved PPP loans with SBA through our funding subsidiary. Yet, in an ongoing effort to mitigate fraud, the SBA is issuing a significant number of validation requests (also known as error codes) on a high volume of loan applications. Due to the level of effort required to resolve these SBA validation requests, we are prioritizing loans that have already been submitted. We want to alert anyone submitting loan applications now to anticipate processing times of <span class="fwsb uline">12-14 business days</span> from date of submission. We recommend as you submit new loan applications, either through Biz2Credit or other lenders, that you use this and future updates to set the appropriate expectations for your clients as these delays are impacting all loan processors.</span>
            </div>
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>            
        </div>
      </div>  -->
      <!-- <div class="alert alert-success_DarkGreen fs12 alert-dismissible fade show radius0 m-0 px-0 px-lg-4" role="alert">
        <div class="container">
            <ng-container *ngIf="!isCommissionRoute">
                <span class="fwb">PPP Alert:</span>  As of May 31, 2021 the Paycheck Protection Program is now expired for all new applications. Applications that have been approved or are under review with the SBA may continue to process through June 30. Learn more by joining us at our PPP Live Service webcasts on Thursdays at 10am ET for the latest news.
            </ng-container>
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>     -->
    
    <!-- <div class="container" *ngIf="!showHeader">
        <div class="row logoRow">
            <div class="col pr-0">
                <h2 class="fs16 fwsb">CPA <span class="ptc">Business Funding</span> Portal​</h2>
            </div>
            
            

            <div class="col-auto text-right pl-0">
                <div class="b2cLogo ml-auto">
                    <div class="powered fs12">Powered by</div>
                    <div><img src="/assets/base/images/biz2x-logo.svg" alt="logo" class="img-fluid"></div>
                </div>
            </div>
        </div>
    </div> -->
</header>

<ng-template #viewModalRef>
    <div class="modal-header">
      <button type="button" class="close pull-right" aria-label="Close" (click)="videoModalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <ng-container *ngIf="videoActiveURL">
            <iframe class="embed-responsive-item" width="100%" height="435" [src]="videoActiveURL | safeHtml" id="video" allowscriptaccess="always" allow="autoplay"></iframe>
        </ng-container>
    </div>
</ng-template>
<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Help & Frequently Asked Questions</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h3 class="fs20 fwsb ffp">How-To Videos</h3>
    <div id="faq" class="carousel owl-carousel">
        <div class="item" (click)="openVideomodal(viewModalRef, 'https://player.vimeo.com/video/751853733')">
            <div class="videoSlider m-2">              
                <img class="img-fluid thumb" src="/assets/base/images/ertc_video_thum.png" alt="biz2credit">
                <span class="iconPlay"><img class="img-fluid" src="/assets/base/images/playIcon.svg" alt="play Video"></span>              
            </div>
        </div>
        <div class="item" (click)="openVideomodal(viewModalRef, 'https://player.vimeo.com/video/727095926')">
            <div class="videoSlider m-2">              
                <img class="img-fluid thumb" src="/assets/base/images/financing-Inquiries.png" alt="biz2credit">
                <span class="iconPlay"><img class="img-fluid" src="/assets/base/images/playIcon.svg" alt="play Video"></span>              
            </div>
        </div>
        <div class="item" (click)="openVideomodal(viewModalRef, 'https://player.vimeo.com/video/718491967')"> 
            <div class="videoSlider m-2">              
                <img class="img-fluid thumb" src="/assets/base/images/working_capital_financing_application.png" alt="biz2credit">
                <span class="iconPlay"><img class="img-fluid" src="/assets/base/images/playIcon.svg" alt="play Video"></span>              
            </div>   
        </div>
        <div class="item" (click)="openVideomodal(viewModalRef, 'https://player.vimeo.com/video/709740750')"> 
            <div class="videoSlider m-2">              
                <img class="img-fluid thumb" src="/assets/base/images/obtainCredit.png" alt="biz2credit">
                <span class="iconPlay"><img class="img-fluid" src="/assets/base/images/playIcon.svg" alt="play Video"></span>              
            </div>   
        </div>
        <div class="item" (click)="openVideomodal(viewModalRef, 'https://player.vimeo.com/video/709740768')"> 
            <div class="videoSlider m-2">              
                <img class="img-fluid thumb" src="/assets/base/images/underwriterNote.png" alt="biz2credit">
                <span class="iconPlay"><img class="img-fluid" src="/assets/base/images/playIcon.svg" alt="play Video"></span>              
            </div>   
        </div>
        <div class="item" (click)="openVideomodal(viewModalRef, 'https://player.vimeo.com/video/709740793')"> 
            <div class="videoSlider m-2">              
                <img class="img-fluid thumb" src="/assets/base/images/termLoan.png" alt="biz2credit">
                <span class="iconPlay"><img class="img-fluid" src="/assets/base/images/playIcon.svg" alt="play Video"></span>              
            </div>   
        </div>
        <div class="item" (click)="openVideomodal(viewModalRef, 'https://player.vimeo.com/video/709740817')"> 
            <div class="videoSlider m-2">              
                <img class="img-fluid thumb" src="/assets/base/images/uploadCocumentstermLoan.png" alt="biz2credit">
                <span class="iconPlay"><img class="img-fluid" src="/assets/base/images/playIcon.svg" alt="play Video"></span>              
            </div>   
        </div>
        <div class="item" (click)="openVideomodal(viewModalRef, 'https://player.vimeo.com/video/709740842')"> 
            <div class="videoSlider m-2">              
                <img class="img-fluid thumb" src="/assets/base/images/connectClient.png" alt="biz2credit">
                <span class="iconPlay"><img class="img-fluid" src="/assets/base/images/playIcon.svg" alt="play Video"></span>              
            </div>   
        </div>
        <div class="item" (click)="openVideomodal(viewModalRef, 'https://player.vimeo.com/video/663144519')"> 
            <div class="videoSlider m-2">              
                <img class="img-fluid thumb" src="/assets/base/images/digitalCPA.png" alt="biz2credit">
                <span class="iconPlay"><img class="img-fluid" src="/assets/base/images/playIcon.svg" alt="play Video"></span>              
            </div>   
        </div>
        <div class="item" (click)="openVideomodal(viewModalRef, 'https://player.vimeo.com/video/659397983?h=53c0c0a728')"> 
            <div class="videoSlider m-2">              
                <img class="img-fluid thumb" src="/assets/base/images/termsLoan.png" alt="biz2credit">
                <span class="iconPlay"><img class="img-fluid" src="/assets/base/images/playIcon.svg" alt="play Video"></span>              
            </div>   
        </div>
            <div class="item" (click)="openVideomodal(viewModalRef, 'https://player.vimeo.com/video/505927553')"> 
                <div class="videoSlider m-2">              
                    <img class="img-fluid thumb" src="/assets/base/images/video-esign.png" alt="biz2credit">
                    <span class="iconPlay"><img class="img-fluid" src="/assets/base/images/playIcon.svg" alt="play Video"></span>              
                </div>   
            </div>
        <div class="item" (click)="openVideomodal(viewModalRef, 'https://player.vimeo.com/video/468949998')"> 
            <div class="videoSlider m-2">              
                <img class="img-fluid thumb" src="/assets/base/images/video.png" alt="biz2credit">
                <span class="iconPlay"><img class="img-fluid" src="/assets/base/images/playIcon.svg" alt="play Video"></span>              
            </div>   
        </div>
        <div class="item" (click)="openVideomodal(viewModalRef, 'https://player.vimeo.com/video/468950093')">
            <div class="videoSlider m-2">              
                <img class="img-fluid thumb" src="/assets/base/images/video2.png" alt="biz2credit">
                <span class="iconPlay"><img class="img-fluid" src="/assets/base/images/playIcon.svg" alt="play Video"></span>              
            </div>
        </div><div class="item" (click)="openVideomodal(viewModalRef, 'https://player.vimeo.com/video/473230049')">
            <div class="videoSlider m-2">              
                <img class="img-fluid thumb" src="/assets/base/images/video3.png" alt="biz2credit">
                <span class="iconPlay"><img class="img-fluid" src="/assets/base/images/playIcon.svg" alt="play Video"></span>              
            </div>
        </div>
        <div class="item" (click)="openVideomodal(viewModalRef, 'https://player.vimeo.com/video/473230129')">
            <div class="videoSlider m-2">              
                <img class="img-fluid thumb" src="/assets/base/images/video4.png" alt="biz2credit">
                <span class="iconPlay"><img class="img-fluid" src="/assets/base/images/playIcon.svg" alt="play Video"></span>              
            </div>
        </div>
        <div class="item" (click)="openVideomodal(viewModalRef, 'https://player.vimeo.com/video/474284817')">
            <div class="videoSlider m-2">              
                <img class="img-fluid thumb" src="/assets/base/images/forgivenessCalculator.png" alt="biz2credit">
                <span class="iconPlay"><img class="img-fluid" src="/assets/base/images/playIcon.svg" alt="play Video"></span>              
            </div>
        </div>
        <div class="item" (click)="openVideomodal(viewModalRef, 'https://player.vimeo.com/video/474284853')">
            <div class="videoSlider m-2">              
                <img class="img-fluid thumb" src="/assets/base/images/FTECalculators.png" alt="biz2credit">
                <span class="iconPlay"><img class="img-fluid" src="/assets/base/images/playIcon.svg" alt="play Video"></span>              
            </div>
        </div>
        <!-- <div class="item" (click)="openVideomodal(viewModalRef, 'https://player.vimeo.com/video/488784458')">
            <div class="videoSlider m-2">              
                <img class="img-fluid thumb" src="/assets/base/images/videoimg5.png" alt="biz2credit">
                <span class="iconPlay"><img class="img-fluid" src="/assets/base/images/playIcon.svg" alt="play Video"></span>              
            </div>
        </div> -->
        <div class="item" (click)="openVideomodal(viewModalRef, 'https://player.vimeo.com/video/509121014')">
            <div class="videoSlider m-2">              
                <img class="img-fluid thumb" src="/assets/base/images/video6.png" alt="biz2credit">
                <span class="iconPlay"><img class="img-fluid" src="/assets/base/images/playIcon.svg" alt="play Video"></span>              
            </div>
        </div>
        
    </div>
    <div class="text-center pt-4 mt-2">
        <a class="btn btn-primaryDark fs15 px-5 lh tls" href={{URLS?.resourceHub_access_library_url}} target="_blank">Access Full Library</a>
    </div>
    <div class="pppfaq">
        <div class="d-flex pt-3">
            <h3 class="fs21 fwsb ffp pt-1 pr-3">FAQs</h3>
              <div class="searchwrap">
                  <input type="text" [(ngModel)]="searchText" placeholder="Search" class="form-control has-search" (ngModelChange)="onChange()">
              </div>
          </div>
        <article class="careActSBAFAQs">
            <p class="fs21 mt-4 ng-star-inserted pt-5 text-center mb-5 pb-5" style="color: lightslategray"  *ngIf="!filter_result">No results found.</p>
            <div class="justify-content-center" *ngFor="let list of FAQs_list | filter: searchText : this; let i = index">
                <div [innerHtml]="list.header_key" *ngIf="list.ques_ans.length"></div> 
                <div class="card" class="card" *ngFor="let queans of list.ques_ans; let j = index">
                    <div class="card-header">
                        <a id="sbaFAQoneTimePin{{i}}" class="btn-link collapsed" data-toggle="collapse" href="#sbaFAQoneSettingPortal{{i+''+j}}" role="button" aria-expanded="false">
                            <div class="row">
                                <div class="col-auto fwsb">Q.</div>
                                <div class="col pl-0" [innerHtml]="queans.ques_html">
                                    
                                </div>
                            </div>
                        </a>
                    </div>
                    <div id="sbaFAQoneSettingPortal{{i+''+j}}" class="sbaFAQcontents collapse">
                        <div class="card-body px-0">
                            <div class="row">
                                <div class="col-auto fwsb">A.</div>
                                <div class="col pl-0" [innerHtml]="queans.ans_html">
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                
                
                </div>
            
        </article>
    </div>
    
</div>
<div class="modal-footer">
    <div class="headRightLink p-0">
        <div class="d-inline-block fwsb mr-1 align-top">Tech support: </div>
        <a class="fwr align-top" target="_blank" href="mailto:techhelp@biz2credit.com"> techhelp@biz2credit.com</a>
        <div class="d-inline-block fwsb mr-1 align-top ml-4">Support:</div>
        <a class="fwr align-top" target="_blank" href="mailto:{{supportMail}}">{{supportMail}}</a>

    </div>     
</div>
</ng-template>

 <ng-template #landingLogo>
    <div class="b2cLogo d-inline-flex">
        <div><img src="assets/base/images/logo.svg" alt="logo" class="img-fluid" style="width: 180px;"></div>
        <a href="http://www.cpaloanportal.com/" style="margin-top: 3px;" *ngIf="cpaClient() && linkLogo">
            <span class="ml-2 cpalogo mt-2"><img src="assets/base/images/headerlogo.svg" alt="logo" class="img-fluid" style="width: 88px;"></span>  
        </a>
        <span class="ml-2 cpalogo mt-2" *ngIf="cpaClient() && !linkLogo"><img src="assets/base/images/headerlogo.svg" alt="logo" class="img-fluid" style="width: 88px;"></span>  
    </div>
    <br>
    <div class="pt-2 pb-1 pr-0 d-md-inline-block">
        <h2 class="fs20 fwsb"><span class="darkColor" *ngIf="cpaClient()" >CPA </span><span class="ptc">Business Funding</span> <span class="darkColor"> Portal </span></h2>
    </div>
</ng-template>

<ng-template #featureVideoTemplate>
    <div class="modal-header ffp">
        <span class="fs25 ml-2">{{ featureVideoHeading }}</span> 
        <button type="button" class="close pull-right featureCloseBtn" aria-label="Close" (click)="closeDTCvideo()">
            <span aria-hidden="true" placement="left" [ngbTooltip]="featureContent">&times;</span>
            <ng-template #featureContent>
                <span [innerHTML]="featureVideoTooltip"></span>
            </ng-template>
        </button>
    </div>
    <div class="modal-body">
        <div class="pb-3 pl-2">
            <span class="fs20 pt-2">{{ featureVideoTitle }}</span>
        </div>
        <iframe id="featureVideo" height="550px" width="1215px" [src]="featureVideoUrl | safeHtml"
            frameborder="3px">
        </iframe>
    </div>
</ng-template>

<ng-template #leadsResultTemplate>
    <div class="modal-header whiteHead border-0 pb-0">
        <h4 class="modal-title pull-left font18 fwb color616">{{ leadsResultHeading }}</h4>
        <button type="button" class="close pull-right" aria-label="Close" id="deleteItemPopup"
            (click)="closeLeadsResult()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body px-0 leads_results">
        <div class="failedBox" *ngIf="errorLeads && errorLeads.length > 0">
            <div class="row">
                <div class="col-10"><span class="status">Failed</span></div>
                <div class="col-2 text-right"><p class="pt-2 fs16 pr-3 colorred ">{{errorLeads.length}}</p></div>
            </div>
            <div class="row" *ngFor="let data of errorLeads">
                <div class="col-12 pb-2">{{data.lead_info.business_name}}</div>
            </div>
        </div>
        <div class="duplicateBox" *ngIf="duplicateLeads && duplicateLeads.length > 0">
            <div class="row">
                <div class="col-10">
                    <span class="status">Duplicate</span>
                    <img src="../assets/base/images/solid-info-icon.svg" style="width:16px" alt="" class="ml-2 cursorCls" [ngbTooltip]="tipContent" placement="right"/>
                    <ng-template #tipContent>
                        <span [innerHTML]="duplicateStatusText"></span>
                    </ng-template>
                </div>
                <div class="col-2 text-right"><p class="pt-2 fs16 pr-3 colorred ">{{duplicateLeads.length}}</p></div>
            </div>
            <div class="row" *ngFor="let data of duplicateLeads">
                <div class="col-12 pb-2">{{data.lead_info.business_name}}</div>
            </div>
        </div>
        <div class="successBox" *ngIf="successLeads && successLeads.length > 0">
            <div class="row">
                <div class="col-10"><span class="status">Success</span></div>
                <div class="col-2 text-right"><p class="pt-2 fs16 pr-3 colorred ">{{successLeads.length}}</p></div>
            </div>
            <div class="row" *ngFor="let data of successLeads">
                <div class="col-12 pb-2">{{data.lead_info.business_name}}</div>
            </div>
        </div>
    </div>
    <div class="modal-footer d-block">
        <div *ngIf="errorLeads && errorLeads.length > 0" class="text-right">
            <button type="submit" class="btn newAppBtn d-inline-block" (click)="senLeadstoSBA(errorLeads)">Retry Failed</button>
        </div>
    </div>
</ng-template>