import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { CommonService } from '@rubicon/utils';

@Component({
  selector: 'loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  loading: boolean;
  constructor(public common: CommonService, public cdRef:ChangeDetectorRef) {
  }

  ngOnInit() {
    this.common.pg_loaded.subscribe((val: any) => {
      setTimeout(()=>{
        this.loading = val;
        this.cdRef.detectChanges();
      },0)
    });
  }
}
