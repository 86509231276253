import { NgModule } from '@angular/core';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { LoaderComponent } from './loader/loader.component';
import { ToasterComponent } from './toaster/toaster.component';
import { SharedLazyModule, DateTimezonePipe } from '@rubicon/libraries/core-utils/shared-lazy';
import { CommonModule, DatePipe } from '@angular/common';
import { ApplicationProgressComponent } from './application-progress/application-progress.component';
import { FilterComponent } from './filter/filter.component';
import { AdditionalHeaderComponent } from './additional-header/additional-header.component';
import { RouterModule } from '@angular/router';
import { PaginationComponent } from './pagination/pagination.component';
import { BackendDocumentUploadComponent } from './backend-document-upload/backend-document-upload.component';
import { DocumentManagementModule } from 'document-management';
import { OwnershipTreeStructureComponent } from './ownership-tree-structure/ownership-tree-structure.component';
import { OwnersListComponent } from './owners-list/owners-list.component';
import { OrdinalPipe } from '@rubicon/libraries/core-utils/shared-lazy';
import { TreeConverterPipe } from '@rubicon/libraries/core-utils/shared-lazy';
import { MasterDataPipe } from '@rubicon/utils';
import { SaveExitComponent } from './save-exit/save-exit.component';
import { SkipStepComponent } from './skip-step/skip-step.component';
import { AgGridModule } from 'ag-grid-angular';
import { ActionCellRendererComponent } from './owners-list/ag-grid-renderer/action-cell-renderer/action-cell-renderer.component';
import { AnchorCellRendererComponent } from './owners-list/ag-grid-renderer/anchor-cell-renderer/anchor-cell-renderer.component';
import { CheckboxCellRenderer } from './owners-list/ag-grid-renderer/checkbox-cell-renderer';
import { LoanActionsComponent } from './loan-actions/loan-actions.component';
import { KnockoutModalActionComponent } from './knockout-actions/component/knockout-modal-action/knockout-modal-action.component';
import { DynamicModalComponent } from './dynamic-modal';
import { ToggleAppListingBtnComponent } from './toggle-app-listing-btn/toggle-app-listing-btn.component';
import { KnockoutInfoBtnComponent } from './knockout-actions/component/knockout-info-btn/knockout-info-btn.component';

@NgModule({
  imports: [
    SharedLazyModule,
    RouterModule,
    DocumentManagementModule,
    AgGridModule
  ],
  declarations: [
    HeaderComponent,
    FooterComponent,
    LoaderComponent,
    ToasterComponent,
    ApplicationProgressComponent,
    FilterComponent,
    AdditionalHeaderComponent,
    PaginationComponent,
    BackendDocumentUploadComponent,
    OwnershipTreeStructureComponent,
    OwnersListComponent,
    SaveExitComponent,
    SkipStepComponent,
    ActionCellRendererComponent,
    AnchorCellRendererComponent,
    CheckboxCellRenderer,
    LoanActionsComponent,
    KnockoutModalActionComponent,
    DynamicModalComponent,
    ToggleAppListingBtnComponent,
    KnockoutInfoBtnComponent
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    LoaderComponent,
    ToasterComponent,
    ApplicationProgressComponent,
    FilterComponent,
    AdditionalHeaderComponent,
    RouterModule,
    PaginationComponent,
    BackendDocumentUploadComponent,
    OwnershipTreeStructureComponent,
    OwnersListComponent,
    SaveExitComponent,
    SkipStepComponent,
    ActionCellRendererComponent,
    AnchorCellRendererComponent,
    LoanActionsComponent,
    KnockoutInfoBtnComponent
  ],
  providers: [
    OrdinalPipe,
    TreeConverterPipe,
    MasterDataPipe,
    DateTimezonePipe,
    DatePipe
  ],
  entryComponents:[KnockoutModalActionComponent, DynamicModalComponent]
})
export class UiKitModule {}
