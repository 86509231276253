import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TaskInfoService } from '@rubicon/utils';
import { of } from 'rxjs';
import { catchError,map, take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ExperianScoreService {
    orchConfig;
    constructor(private taskInfoService: TaskInfoService,
        private store: Store<any>){
            this.store.pipe(
                select('app')
            ).subscribe((rootState)=>{
                this.orchConfig = rootState?.configurations?.[0];
            });
    }

    getPrimaryOwnerExperianDetail(owners, owner_references){
        let primaryOwner = owners?.find(owner=>owner?.is_primary);
        let experian_ref;
        for(let owner_ref of owner_references){
            if(owner_ref?.owner_id == primaryOwner?._id && owner_ref?.type == "experian-individual"){
                experian_ref = owner_ref;
            }
        }
        if(experian_ref?.ref_id && experian_ref?.pull_type){
            return this.getExperianDetail(experian_ref.ref_id);
        }
        return of(null);
    }

    getExperianOwnerRef(owner_id, owner_references){
        return owner_references?.find(owner_ref=>owner_ref?.owner_id == owner_id && owner_ref?.type == "experian-individual");
    }

    getOwnerExperianDetail(owner_id, owner_references) {
        let experian_refs = owner_references?.filter(owner_ref=>owner_ref?.owner_id == owner_id && owner_ref?.type == "experian-individual");
        let latest_createdAt = Math.max(...experian_refs.map(ref => ref.created_at));
        let experian_ref = experian_refs.find(({created_at}) => created_at == latest_createdAt);
        if(experian_ref?.ref_id && experian_ref?.pull_type){
            return this.getExperianDetail(experian_ref.ref_id);
        }
        return of(null);
    }

    getExperianDetail(ref_id){
        let params:any = {slug:"get_experian_data", id:ref_id};
        let category = this.orchConfig?.settings?.creditReportConfig?.experian?.category;
        if(category){
            params["category"] = category;
        }
        return this.taskInfoService.getTaskInfo(params).pipe(
            map(res=>{
                return res?.response_data?.get_experian_data?.data?.data;
            }),
            catchError(res => {
                return of({error: "Unable to get Score"});
            })
        );
    }
}