import { Injectable } from '@angular/core';
@Injectable({
    providedIn: 'root'
})

export class PlaygroundFeatureService {
    public filter1 = [{
        "key": "stage",
        "benchmarkValue": "UW",
        "operand": "!includes"
    },
    {
        "key": "role",
        "benchmarkValue": ["admin", "superAdmin", "customer", "banker"],
        "operand": "inArray"
    },
    {
        "key": "OR",
        "benchmarkValue": [{
            "key": "applicant_type",
            "benchmarkValue": "OC"
        }, {
            "key": "applicant_type",
            "benchmarkValue": "EPC"
        }, {
            "key": "company_role",
            "benchmarkValue": "401K"
        }]
    },
    {
        "key": "AND",
        "benchmarkValue": [{
            "key": "ownership",
            "operand": ">=",
            "benchmarkValue": "20"
        }, {
            "key": "owner_type",
            "benchmarkValue": "individual"
        }, {
            "key": "OR",
            "benchmarkValue": [{
                "key": "use_of_proceed",
                "benchmarkValue": "rent"
            }, {
                "key": "loan_amount",
                "operand": "between",
                "benchmarkValue": [10000, 15000]
            }]
        }]
    }
    ];
    public filter2 = [{
        "key": "OR",
        "benchmarkValue": [{
            "key": "applicant_type",
            "benchmarkValue": "OC"
        }, {
            "key": "applicant_type",
            "benchmarkValue": "EPC"
        }, {
            "key": "company_role",
            "benchmarkValue": "401K"
        }]
    }]
    public filter3 = [{
        "key": "OR",
        "benchmarkValue": [{
            "key": "AND",
            "benchmarkValue": [{
                "key": "stage",
                "benchmarkValue": "RM"
            },
            {
                "key": "company_role",
                "benchmarkValue": "seller_entity"
            },
            {
                "key": "applicant_type",
                "operand": "!=",
                "benchmarkValue": "EPC"
            }
            ]
        },
        {
            "key": "AND",
            "benchmarkValue": [{
                "key": "stage",
                "benchmarkValue": "RM"
            },
            {
                "key": "applicant_type",
                "benchmarkValue": "EPC"
            },
            {
                "key": "company_role",
                "operand": "!=",
                "benchmarkValue": "seller_entity"
            }
            ]
        }
        ]
    }]
    public filter4 = [{
        "key": "OR",
        "benchmarkValue": [{
            "key": "stage",
            "benchmarkValue": ["RM", "LOAN", "A", "B", "C"]
        },
        {
            "key": "business_structure",
            "benchmarkValue": ["BAC", "XYZ", "DD"]
        }]
    }]
    public data = {
        "stage": ["EC", "RM"],
        "role": "admin",
        "business": [{ "car": "Alto", "bike": "Splendor" }, { "car": "Kwid", "bike": "Pulsar" }],
        "applicant_type": "EPCd",
        "company_role": "401K",
        "owner_type": "individual",
        "ownership": "20",
        "use_of_proceed": "rent",
        "loan_amount": "110000",
        "business_structure": "BAC"
    }
    public filter5 = [{
      "key": "includes",
      "benchmarkValue": [{
          "key": "required",
          "benchmarkValue": true
      }]
  }]
    constructor() {
     }

     getFilter(filtername = "filter1") {
         return this[filtername]
     }

     getData () {
         return this.data;
     }



}
