import { Inject, Injectable } from "@angular/core";


@Injectable({
    providedIn: 'root'
})
export class RouteValidationService {

    constructor(@Inject('INVALID_ROUTES') private invalid_routes) {}

    isValidRoutes(route: string) : boolean {
        return !this.invalid_routes.includes(route);
    }

}