<ng-container [formGroup]="group">
    <div [ngClass]="(field?.css?.labelClass)? field?.css?.labelClass :field?.format?.hideOnDisabled ? {'d-none': fieldControl.disabled, 'checWrap' : true} : 'checWrap'">
        <div [ngClass]="(field?.css?.checkboxlabelClass)? field?.css?.checkboxlabelClass :'fs14 fwm ptc'" *ngIf="field?.name" [innerHTML]="slug+'.label.'+field.name |translate"></div>
        <label [ngClass]="(field?.css?.switchLabelClass)? field?.css?.switchLabelClass :'el-switch el-switch-sm'"
            [for]="field.name + '_' + indexVal">
            <input type="checkbox" [ngClass]="(field?.css?.field)? field?.css?.field :'form-check-input'"
                [formControlName]="field.name" [id]="field.name + '_' + indexVal" 
                [checked]="(group.get(field.name).value === 1 || group.get(field.name).value === true) ? true : false" 
                (change)="onChange($event)" />
            <label [ngClass]="(field?.css?.label)? field?.css?.label :'clt'" [for]="field.name + '_' + indexVal">
                <span class="labelText" [innerHTML]="slug+'.placeholder.'+field.name | translate"></span> 
                <ng-container *ngIf="field?.css?.tooltip && field?.css?.tooltip[field.name]">
                    <a href="javascript:void(0)" [tooltip]="popTemplateth" [triggers]="(field?.css?.tooltip[field.name+ '_trigger'])?field?.css?.tooltip[field.name+ '_trigger']:'hover'" 
                    [ngClass]="field?.css?.tooltip[field.name]?field?.css?.tooltip[field.name]:'ml-1'">
                      <img src="{{environment?.asset_path}}images/info-icon-n.svg" alt="tooltip"  class="infoimg" /> </a>
                    <ng-template #popTemplateth>
                     <div class="fs12 text-left fwr my-1 gtColor" [innerHTML]='slug + ".tooltip."+ field.name | translate'></div>
                    </ng-template>
                </ng-container>
            </label>
            
            <span class="el-switch-style"></span> 
        </label>
    </div>
    <div *ngIf="group.get(field.name).invalid && (group.get(field.name).dirty || group.get(field.name).touched)"
    [ngClass]="(field?.css?.errorClass)? field?.css?.errorClass :'error-msg'" class="">
        <ng-container *ngFor="let validation of field.validations;">
            <label class="error" *ngIf="group.get(field.name).hasError(validation.name)">
                {{slug + ".errorMessage." + field.name + "." + validation.name | translate}}
            </label>
        </ng-container>
        <label class="error" *ngIf="group.get(field.name).hasError('incorrect')">
            {{slug + ".errorMessage." + field.name + ".incorrect" | translate}}
        </label>
    </div>
</ng-container>