import { Injectable, Inject } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError, ignoreElements } from 'rxjs/operators';
import { ERROR_MESSAGES } from '../constants/errorMessages';
import { CommonService } from '../services/common/common.service';
import { APP_ROUTES } from '@assets/routes/routes';
import { Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import Bugsnag from '@bugsnag/js'
import { CUSTOMER_PORTAL } from '@rubicon/interface/task-info';


@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  rootStateSubscription: Subscription;

  constructor(private common: CommonService,private store: Store<any>,@Inject('environment') private environment) { }
 
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request)
      .pipe(
        map((res: HttpResponse<any>) => {
          if(request.params.get('skip_error')) return res; 
          if (!request.url.includes('en.json')) {
            const slug = request.params.get('slug') || request.urlWithParams.split('?slug=').pop();
            if (res.body && res.body.data && res.body.data.response_data) {
              const { response_data: response } = res.body.data;
              if (response.nextTask) {
                if (response.nextTask && response.nextTask.value !== 'error') {
                  if (response.nextTask.value === 'invalid') {
                    for (const data in response) {
                      if (ERROR_MESSAGES[response[data].status] && typeof response[data].errors === 'string') {
                        this.common.popToast('error', 'Error', ERROR_MESSAGES[response[data].status].error);
                        throw response;
                      }
                    }
                  }
                } else {
                  for (const data in response) {
                    if (response.nextTask.value === 'invalid') {
                      if (ERROR_MESSAGES[response[data].status] && typeof response[data].errors === 'string') {
                        this.common.popToast('error', 'Error', ERROR_MESSAGES[response[data].status].error);
                        throw response;
                      }
                    } else if ((data === 'check_phone' || data === 'check_email') && response[data].status === 200 && slug) {
                      if (response[data] && response[data].data && response[data].data.data) {
                        const check = response[data].data.data.isExists;
                        if (ERROR_MESSAGES[response[data].status][slug][data][check]) {
                          this.common.popToast('error', 'Error', ERROR_MESSAGES[response[data].status][slug][data][check]);
                          throw response;
                        }
                      }
                    } else if (ERROR_MESSAGES[response[data].status] && response[data].errors && response[data].errors.errors
                      && Array.isArray(response[data].errors.errors)) {
                      for (const err of response[data].errors.errors) {
                        if (ERROR_MESSAGES[response[data].status][slug]) {
                          this.common.popToast('error', 'Error', ERROR_MESSAGES[response[data].status][slug][err.type][err.parameter]
                            || ERROR_MESSAGES[response[data].status][slug][err.type])
                          throw response;
                        } else {
                          if (response[data].status === 429 && slug === CUSTOMER_PORTAL.tl_document_listing && data === 'update_user_plan_consumed') {
                            continue;
                          }
                          this.common.popToast('error', 'Error', ERROR_MESSAGES[response[data].status][err.type]);
                          if (response[data].status === 403 || (err.type === 'RATE_LIMIT_REACHED' && APP_ROUTES[slug] !== 'login'))
                            this.common.navigateByUrl('login');
                          /**handle limit reached error for enterprise plan  */
                          throw response;
                        }
                      }
                    } else if (ERROR_MESSAGES[response[data].status] && ERROR_MESSAGES[response[data].status][slug]) {
                          if(request.body['get']){
                            this.common.popToast('error', 'Error', ERROR_MESSAGES[response[data].status][slug]
                          + request.body['get']('file').name);
                          }
                          if (ERROR_MESSAGES[response[data].status][slug]) {
                            if(response[data].errors.errors)
                            {
                            this.common.popToast('error', 'Error', response[data].errors.errors)
                            throw response[data].errors.errors;
                            }else if(response[data].errors){
                              this.sendNag(response[data],slug,data,request)
                              break
                            }
                          }
                          
                    } else if(res.body.data.response_data && res.body.data.response_data.otp_generate &&res.body.data.response_data.otp_generate.errors && res.body.data.response_data.otp_generate.errors.code === 429) {
                      this.common.popToast('error', 'Error',"You have exceeded the maximum number of OTP attempts. Please try after some-time.");
                    } else if(response.utility_parser_async && response.utility_parser_async.errors.errors.document_error){
                      continue
                    } else if( (response?.inbound_get_client_by_email || response?.inbound_get_client_by_phone || response?.inbound_get_client) && res?.body?.data?.response_data?.nextTask?.value === 'error') {
                      if(response?.inbound_get_client_by_email || response?.inbound_get_client_by_phone) {
                        this.common.popToast('error', 'Error', 'Record already exists with this email/mobile.');
                      } else if(response?.inbound_get_client) {
                        this.common.popToast('error', 'Error', 'Record already exists with this email/mobile.')
                      }
                    } else {
                      if (response.update_user_plan_consumed && response.update_user_plan_consumed.status === 429 && slug === CUSTOMER_PORTAL.tl_document_listing) {
                        continue;
                      }
                      this.common.popToast('error', 'Error', "We are sorry but there is some technical issue here. Don't worry we have collected error information and our teams are working to resolve this at the earliest. We request you to please try after sometime once this issue is resolved");
                      
                      if(response[data].errors){
                        this.sendNag(response[data],slug,data,request)
                        break
                      }
                    }
                  }
                }
              }
            }
          }
          return res;
        }),
        catchError(error => {
          // Client Side Error
          if (error && error.error instanceof ErrorEvent) {
          } else {  // Server Side Error
            if (error && error.error) {
              const err = error.error;
              if (ERROR_MESSAGES[err.status]) {
                this.common.popToast('error', 'Error', ERROR_MESSAGES[err.status].error);
                if (err.status === 440) {
                  this.common.navigateByUrl('signin');
                }
              } else {
                this.common.popToast('error', 'Error', "We are sorry but there is some technical issue here. Don't worry we have collected error information and our teams are working to resolve this at the earliest. We request you to please try after sometime once this issue is resolved");
                this.sendNag(error)
              }
            }
            return throwError(error.error || error);
          }
        })
      );
  }

  sendNag(res: any, slug?, api_name?, request?) {
    let bugsnag=this.environment.bugsnag;
    let backendUser: any;   
    this.rootStateSubscription = this.store.
      pipe(
        select('app'),
        take(1)
      ).subscribe(rootState => {
        if (rootState) {
          if (rootState.backendUserData && rootState.backendUserData.id) {
            backendUser = rootState.backendUserData.user;
            let error = {
              ...res,
              'SLUG': slug,
              'API NAME': api_name
            }
            
            let body: any = {
              cpa_firm_name: backendUser.cpa_firm_name,
              cpa_name: backendUser.first_name + ' ' + backendUser.last_name,
              cpa_email_address: backendUser.email_address,
              user_id: backendUser.id,
              app_id: rootState.appID ?rootState.appID:'NA',
              orch_url:request && request.url? request.url:'NA',
              request:request && request.urlWithParams?JSON.stringify(request.urlWithParams.split('&')):'NA',
              error: res && res.message ? res.message : JSON.stringify(error),
            }
            
            if(bugsnag && bugsnag==true){
              Bugsnag.notify(body)
            }
          
          }
        }
      });
  }
}
