import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldInterface } from '@rubicon/interface/form-field';
import { environment } from '@env/environment';

@Component({
  selector: 'rubicon-amount',
  templateUrl: './amount.component.html',
  styleUrls: ['./amount.component.scss']
})
export class AmountComponent implements OnInit {

  field: FormFieldInterface;
  group: FormGroup;
  slug: string;
  currency: string;
  indexVal: Number;
  constructor() { }

  ngOnInit(): void {
    this.indexVal=new Date().getTime();
    if (environment.region === "in") {
      this.currency = "Rs";
    }
    else if (environment.region === "usa")
      this.currency = "$";
    else
      this.currency = " ";
  }

  // focusin(event) {
  //   // remove all , from the amount when the element is focussed in 
  //   let amount = event.target.value;
  //   amount = amount.replace(/,/g, "");
  //   event.target.value = amount;
  // }

  // focusout(event) {
  //   // Adding , in between the numbers -- also included for decimal digits and adding $ or rs sign before 
  //   // it depending on country -- US -- $ IND -- RS
  //   const amount = event.target.value;
  //   const parts = amount.toString().split(".");
  //   parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  //   const get_res = parts.join(".");
  //   event.target.value = get_res;
  // }

}
