<!-- <div class="container-fluid"> for manage leed page-->
    <div class="row pt-4">
        <div class="col-12 col-lg-11 col-xl-11">
            <form [formGroup]="filterForm" *ngIf="filterConfig && filterConfig.length > 0">
                <rubicon-dynamic [group]="filterForm" class="row" [fields]="filterConfig" [slug]="slug"></rubicon-dynamic>   
            </form>
        </div>
        <div class="col-12 col-lg-1 col-xl-1 pl-lg-0 mb-3 mb-lg-0 searchBtn">                                
            <button class="btn btn-primary" (click)="onFilter()" type="button">Search</button>         
        </div>
    </div>
<!-- </div> -->
        