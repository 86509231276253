import { Component, OnInit } from '@angular/core';
import { FormFieldInterface } from '../../../interfaces/FormFieldInterafce';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'rubicon-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  field: FormFieldInterface;
  slug: String;
  group: FormGroup;
  constructor() { }

  ngOnInit(): void {
  }

}
