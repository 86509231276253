import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldInterface } from '../../../interfaces/FormFieldInterafce';
import { CommonService, ListSortPipe } from '@rubicon/utils';
import { FormService } from '../../../services/form.service';


@Component({
  selector: 'rubicon-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {
  field: FormFieldInterface;
  group: FormGroup;
  slug: string;
  indexVal: number;
  displayKey: string;
  parent: string;
  index: number;
  valueKey: string;
  options = [];
  oneLine = false;
  ngSelectDivWrap = false;
  virtualScroll = true;
  constructor(private common: CommonService, private formService: FormService,
    @Inject("environment") public environement) { }

  ngOnInit() {
    if (['collateral-details', 'business_financial'].includes(this.slug)) {
      this.ngSelectDivWrap = true;
    }
    this.displayKey = this.field.config && this.field.config.displayKey? this.field.config.displayKey: 'value';
    this.valueKey = this.field.config && this.field.config.valueKey? this.field.config.valueKey:'id';
    this.indexVal = new Date().getTime();
    if (this.field?.config?.resetValue) {
      this.group.get(this.field.name).valueChanges.subscribe(value => {
        if (value && value.includes(this.field.config.resetValue) && value.length !== 1) {
          this.group.get(this.field.name).patchValue([this.field.config.resetValue]);
        }
      })
    }
    if(!this.field?.config?.uniqueKey){
      this.options = this.field['options'];
      this.formService.optionsReceivedEmit({ options: this.options, field: this.field, slug: this.slug});
    }
    if(this.field?.config && 'virtualScroll' in this.field?.config) {
      this.virtualScroll = this.field?.config.virtualScroll;
    }
    this.common.masterData
      .subscribe((data: any) => {
        if (data && data[this.field.name]&& data[this.field.name].data &&
          (!('parent' in data[this.field.name]) || (data[this.field.name].parent === this.parent && data[this.field.name].index ===this.index) && (!this.field?.config?.uniqueKey || this.field?.config?.uniqueKey && data[this.field.name]?.group?.parent===this.group.parent))
        ) {
          if(!this.field?.config?.uniqueKey){
            this.field['options'] = data[this.field.name].data.data ||data[this.field.name].data;
          }
          this.options= data[this.field.name].data.data ||data[this.field.name].data;
          if (this.field?.config?.sorting !== false) {
            const listSortPipe = new ListSortPipe();
            let otherFound = this.options.find((option, index) => {
              if( option?.type?.toLowerCase() === 'other' || option?.type?.toLowerCase() === 'others' ) {
                this.options.splice(index, 1);
                return option;
              }
            });
            this.options= listSortPipe.transform(this.options, this.displayKey, 1, this.options, this.displayKey, this.displayKey);
            if (otherFound) {
              this.options.push(otherFound)
            }
          }
          let valueFound = this.options.find(ele => {
            if (Array.isArray(this.group.get(this.field.name).value)) {
              return this.group.get(this.field.name).value.includes(ele[this.valueKey])
            } else {
              return this.group.get(this.field.name).value === ele[this.valueKey]
            } 
          })
         if(this.group.get(this.field.name).value && !valueFound) {
            this.group.get(this.field.name).reset();
          }
          this.formService.optionsReceivedEmit({ options: this.options, field: this.field, slug: this.slug});
        }
      });
  }
  normalClass(){
    // setTimeout(() => {       
      if(this.field.css){
        this.field.css.field = this.field.css.field ? `${this.field.css.field } normalClass form-control `: 'normalClass form-control'
      }
    // },3000)
  }
}

