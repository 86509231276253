import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ReplaySubject } from 'rxjs';
import { take, takeUntil, takeWhile } from 'rxjs/operators';
import { updateToggleAppListType } from '../+state/header.actions';
import { selectToggleAppListType } from '../+state/header.selectors';
import { WorkflowActionsService } from '../services/workflow-actions.service';

@Component({
  selector: 'toggle-app-listing-btn',
  templateUrl: './toggle-app-listing-btn.component.html',
  styleUrls: []
})
export class ToggleAppListingBtnComponent implements OnInit, OnDestroy {
  compDestroyed$ = new ReplaySubject(1);
  slug = "dashboard_toggle";
  readonly app_listing_types;
  toggleAppListType;
  user_app_listing_type;
  constructor(private store: Store<any>,
    @Inject('cssList') public cssList,
    private workflowActionService: WorkflowActionsService) { }

  ngOnInit(): void {
    this.store.pipe(
      select('app'),
      takeWhile(rootState => !rootState?.userData, true),
      takeUntil(this.compDestroyed$)
    ).subscribe((rootState)=>{
      if (rootState?.userData) {
        let dashboard_app_listing_action: any = this.workflowActionService.getWorkflowActions("dashboard_app_listing");
        let assignment_listing = dashboard_app_listing_action?.config?.[rootState?.userData?.role_id]?.assignment_listing;
        let user_app_listing_type = assignment_listing?.types;
        if(user_app_listing_type?.length){
          this.user_app_listing_type = user_app_listing_type;
          this.store.pipe(select(selectToggleAppListType), take(1), takeUntil(this.compDestroyed$)).subscribe((toggleAppListType)=>{
            if(!toggleAppListType){
              this.store.dispatch(updateToggleAppListType({toggleAppListType: assignment_listing?.default_type}));
            }
          });
        }else{
          this.store.dispatch(updateToggleAppListType({toggleAppListType: null}));
          this.user_app_listing_type = null;
        }
      }
    });

    this.store.pipe(
      select(selectToggleAppListType),
      takeUntil(this.compDestroyed$)
    ).subscribe((toggleAppListType)=>{
      this.toggleAppListType = toggleAppListType;
    });

  }

  onAppListTypeChange(toggleAppListType){
    this.store.dispatch(updateToggleAppListType({toggleAppListType}));
  }

  ngOnDestroy() {
    this.compDestroyed$.next();
    this.compDestroyed$.complete();
  }
}
