import { Injectable, Inject } from "@angular/core";
import { Observable, of } from "rxjs";
import { TaskInfoService, CommonService } from "@rubicon/utils";
import { map } from "rxjs/operators";
import { Store, select } from "@ngrx/store";
import { conditionHelperService } from '../../../../../core-utils/biz2xPro-config-engine';
import { TaskTrackerService } from './task-tracker.service';


@Injectable({
    providedIn: "root"
})
export class EcoaService {
    backendUserData;
    orchConfig;
    constructor(@Inject("CONSTANTS") public CONSTANTS,
    private taskInfoService: TaskInfoService,
    private common: CommonService,
    private taskTrackerService: TaskTrackerService,
    private store: Store<any>){
        this.store.pipe(select('app')).subscribe(rootState => {
            this.backendUserData = rootState.userData;  
            this.orchConfig = rootState?.configurations?.[0];     
          });
    }

    getEcoaDaysTextConfig(business_references):{ecoa_text: string, showHazardIcon: boolean}{
        let ecoa_references = business_references?.filter(element=>element.type == 'ecoa-status');
        let pendingEcoaDays = this.getPendingEcoaDays(ecoa_references);
        let SLA_HAZARD_ICON_DAYS = this.orchConfig?.settings?.workflowConfig?.SLA_HAZARD_ICON_DAYS;
        let ecoa_text_hint = pendingEcoaDays >= 0? "day(s) left": "day(s) late";
        if(!ecoa_references?.length){
            return {ecoa_text: "-", showHazardIcon: false};
        }
        return {ecoa_text: `${pendingEcoaDays} ${ecoa_text_hint}`, showHazardIcon: pendingEcoaDays <= SLA_HAZARD_ICON_DAYS };  
    }

    getPendingEcoaDays(business_references) { //copied from hsbc 
        if (business_references) {
            let ecoa: any;
            business_references.forEach((element, key) => {
                if (element.type == 'ecoa-status') {
                    if (element.response.status == 'START' || element.response.status == 'RESET') {
                        var update: any = new Date(element.response.updated_at);
                        var signed: any = new Date();
                        const diffTime = Math.abs(signed - update);
                        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                        ecoa = this.CONSTANTS.ECOA_DAYS - diffDays + 1;
                    } else if (element.response.status == 'PAUSE' || element.response.status == 'STOP') {
                        ecoa = element.response.clockTime;
                    } else if(element.response.status == 'RESUME') {
                      var update:any = new Date(element.response.updated_at);  
                      var signed:any = new Date();  
                      const diffTime = Math.abs(signed - update);  
                      const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
                      ecoa = Number(element.response.clockTime) - diffDays;
                    }
                }
            });
            return ecoa;
        } else return false;
    }

    checkEcoaActions(configAction, applicationData){
      if(this.taskTrackerService.isTaskCompleted((applicationData.appId || applicationData.app_id || applicationData._id), "ecoa_action")){
        return of({ecoaUpdated: false});
      }
      if(configAction?.ecoa_filter_conditions){
        let result = conditionHelperService.resolve(applicationData, configAction?.ecoa_filter_conditions, 'AND', true);
        if (!result?.resolved) {
          return of({ecoaUpdated: false});
        }
      }
        let ecoa_action = configAction?.ecoa_action; 
        if(ecoa_action) {
            let businessReferences1 = applicationData.business_references;
            let ecaoReferences = [];
            if (businessReferences1.length > 0) {
              ecaoReferences = businessReferences1.filter(
                (data) => data.type === 'ecoa-status'
              );
              ecaoReferences.sort((a, b) => {
                return (+new Date(b.response.updated_at) - +new Date(a.response.updated_at));
              });
            }
            if(ecoa_action != "START" && !ecaoReferences?.length){
              return of({ecoaUpdated: false});
            }
  
            let current_ecoa_status = ecaoReferences.length > 0 ? ecaoReferences[0].response.status.toUpperCase():null;
            if(current_ecoa_status == "STOP"){
              return of({ecoaUpdated: false});
            }
            let next_ecoa_status;
            if(current_ecoa_status !== 'PAUSE' && ecoa_action == "PAUSE"){
                next_ecoa_status = "PAUSE";
            }
            else if(current_ecoa_status !== 'STOP' && ecoa_action == "STOP"){
                next_ecoa_status = "STOP";
            }
            else if(current_ecoa_status === "PAUSE" && ecoa_action == "RESET"){
                next_ecoa_status = "RESET";
            }
            else if(current_ecoa_status == "PAUSE" && ecoa_action == "RESUME"){
              next_ecoa_status = "RESUME";
            }
            else if(!current_ecoa_status && ecoa_action == "START"){
                next_ecoa_status = "START";
            }else{
              return of({ecoaUpdated: false});
            }
            return this.taskInfoService.saveTaskInfo({ slug: 'ecoa_status' },
              { status: next_ecoa_status, app_id: (applicationData.appId || applicationData.app_id || applicationData._id), user_id: (applicationData.userId || applicationData.user_id) })
              .pipe( map((res) => {
                this.taskTrackerService.taskCompleted((applicationData.appId || applicationData.app_id || applicationData._id), "ecoa_action");           
                let activity;
                if (next_ecoa_status == "PAUSE") {
                  activity = "ecoa_pause";
                }
                else if (next_ecoa_status == "STOP") {
                  activity = "ecoa_stop";
                } else if(next_ecoa_status == "RESET"){
                  activity = "ecoa_reset";
                }else if(next_ecoa_status == "START"){
                  activity = "ecoa_start";
                }else if(next_ecoa_status == "RESUME"){
                  activity = "ecoa_resume";
                }
                this.addActivityLog(applicationData ,activity);
                return res;
              })
            );
          }
          return  of({ecoaUpdated: false});
      }

      addActivityLog(applicationData, activity, note?) {
        let log_data;
        if (activity === 'ecoa_reset' || activity === 'ecoa_stop' || activity == "ecoa_pause" || activity === 'ecoa_start') {
          log_data = {
            role_slug: this.backendUserData?.role_slug,
            app_id: applicationData.appId || applicationData._id,
            backend_user_id: this.backendUserData?.user_id,
            user_name: 'system',
            activity: activity
          };
        } else {
          log_data = {
            role_slug: this.backendUserData?.role_slug,
            app_id: applicationData.appId || applicationData._id,
            backend_user_id: this.backendUserData?.user_id,
            user_name: this.backendUserData?.full_name,
            activity: activity
          };
        }
        if(note) {
          log_data['note'] = Array.isArray(note)? note.join(", ") : note;
        }
        this.common.addActivityLog(log_data);
      }
}