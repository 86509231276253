
export const FileValidator = {
  isFileAllowed: function (files: File[], allowedFileExt: any, allowedSize: number): string | boolean {
    const allowedFile = this.getContentType(allowedFileExt);
    // for (const file of files) {
    //   if (!this.isFileValid(file, allowedFile, allowedFileExt)) {
    //     return 'INVALID_FILE_TYPE';
    //   }
    //   if (!this.isFileSizeValid(file, allowedSize)) {
    //     return 'FILE_SIZE';
    //   }
    // }
    // for single file
    if(files) {
      if (!this.isFileValid(files['0'], allowedFile, allowedFileExt)) {
        return 'INVALID_FILE_TYPE';
      }
      if (!this.isFileSizeValid(files['0'], allowedSize)) {
        return 'FILE_SIZE';
      }
    }
    return false;
  },

  getContentType: function (allowedFileExt: any) {
    const allowedFile = [];
    if (typeof (allowedFileExt) === "string") {
      allowedFile.push("application/pdf");
      return allowedFile;
    }
    allowedFileExt.forEach(element => {
      switch (true) {
        case element === 'txt':
          allowedFile.push("text/plain")
          break;
        case element === 'jpg' || element === 'jpeg':
          allowedFile.push("image/jpeg")
          break;
        case element === 'png':
          allowedFile.push("image/png")
          break;
        case element === 'gif':
          allowedFile.push("image/gif")
          break;
        case element === 'tif':
          allowedFile.push("image/tiff")
          break;
        case element === 'pdf':
          allowedFile.push("application/pdf")
          break;
        case element === 'doc':
          allowedFile.push("application/msword")
          break;
        case element === 'docx':
          allowedFile.push("application/vnd.openxmlformats-officedocument.wordprocessingml.document")
          break;
        case element === 'ppt':
          allowedFile.push("application/vnd.ms-powerpoint")
          break;
        case element === 'pptx':
          allowedFile.push("application/vnd.openxmlformats-officedocument.presentationml.presentation")
          break;
        case element === 'xls':
          allowedFile.push("application/vnd.ms-excel")
          break;
        case element === 'xlsx':
          allowedFile.push("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
          break;
        case element === 'csv':
          allowedFile.push("text/csv")
          break;

        default:
          break;
      }
    });
    return allowedFile;
  },

  isFileValid: function (file: File, allowedFile: any, allowedFileExt: any) {
    if (file.type) {
      return allowedFile.indexOf(file.type) > -1;
    } else {
      const fileExtArr = file.name.split('.');
      const fileExt = fileExtArr[fileExtArr.length - 1];
      return allowedFileExt.indexOf(fileExt.toLowerCase()) > -1;
    }
  },

  isFileSizeValid: function (file: File, allowedSize: number) {
    return file.size < allowedSize;
  },

  getFileContent: function (filename) {
    const fileExtArr = filename.split('.');
    const fileExt = fileExtArr[fileExtArr.length - 1];
    let contentType = '';
    switch (fileExt) {
      case 'txt':
        contentType = "text/plain";
        break;
      case 'jpg' || 'jpeg':
        contentType = "image/jpeg";
        break;
      case 'png':
        contentType = "image/png";
        break;
      case 'gif':
        contentType = "image/gif";
        break;
      case 'tif':
        contentType = "image/tiff";
        break;
      case 'pdf':
        contentType = "application/pdf";
        break;
      case 'doc':
        contentType = "application/msword";
        break;
      case 'docx':
        contentType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
        break;
      case 'ppt':
        contentType = "application/vnd.ms-powerpoint";
        break;
      case 'pptx':
        contentType = "application/vnd.openxmlformats-officedocument.presentationml.presentation";
        break;
      case 'xls':
        contentType = "application/vnd.ms-excel";
        break;
      case 'xlsx':
        contentType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        break;
      default:
        break;
    }
    return contentType;
  }
}