import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Inject } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { FormFieldInterface } from '../../../interfaces/FormFieldInterafce';

@Component({
  selector: 'rubicon-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss']
})
export class SwitchComponent implements OnInit{
  @ViewChild('infoTip') infoTip: ElementRef;

  field: FormFieldInterface;
  group: FormGroup;
  slug: string;
  indexVal: Number;
  valueKey: any;
  constructor(
    @Inject("environment") public environment
  ) { }

  ngOnInit() {
    this.indexVal=new Date().getTime();
    if (this.field?.config?.number) {
      if(!this.group.get(this.field.name)?.value) {
        this.group.get(this.field.name).setValue(0);
      }
    }
    // this.valueKey = this.field.config && this.field.config.valueKey? this.field.config.valueKey:;
  }

  get fieldControl(){
    return this.group.get(this.field.name);
  }
  
  onChange(event) {
    if (this.field?.config?.number) {
      if(event.target.checked) {
        this.group.get(this.field.name).setValue(1);
      } else {
        this.group.get(this.field.name).setValue(0);
      }
    } 
  }

}
