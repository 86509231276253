import { Injectable, ComponentFactoryResolver, ApplicationRef, Injector, EmbeddedViewRef, Inject } from "@angular/core";
import { CommonService } from "@rubicon/utils";
import { DynamicModalComponent } from '../component/dynamic-modal/dynamic-modal.component';

@Injectable({
    providedIn: 'root'
})
export class DynamicModalActionService {
    modalArgumentMapping = {}
    constructor(private componentFactoryResolver: ComponentFactoryResolver,
        private appRef: ApplicationRef, private injector: Injector,
        @Inject('environment') public environment,
        public common: CommonService) {
    }

    openModal(actionConfig, compInput, actionCompleted$) {
        let modalId = actionConfig.action_key;
        if (this.modalArgumentMapping[modalId]) {
            return false;
        }
        else {
            this.modalArgumentMapping[modalId] = {};
        }
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(DynamicModalComponent);
        const componentRef = componentFactory.create(this.injector);
        this.appRef.attachView(componentRef.hostView);
        const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
        this.modalArgumentMapping[modalId].modalComp = componentRef;
        this.modalArgumentMapping[modalId].actionComplete$ = actionCompleted$;
        this.modalArgumentMapping[modalId].actionConfig = actionConfig;
        componentRef.instance.actionConfig = actionConfig;
        if(typeof compInput == "object"){
            Object.assign(componentRef.instance, compInput);
        }
        componentRef.instance.modalClosed$.subscribe(() => {
            actionCompleted$.next({ button_id:"close", close: true });
            actionCompleted$.complete();
            this.appRef.detachView(componentRef.hostView);
            componentRef.destroy();
            delete this.modalArgumentMapping[modalId];
        });
        document.body.appendChild(domElem);
        return true;
    }

    closeModal(actionConfig) {
        let modalId = actionConfig.action_key;
        let modalCompRef = this.modalArgumentMapping[modalId].modalComp;
        modalCompRef.instance.closeModal();
    }

    resolveButtonClick(button_id, actionConfig) {
        let modalId = actionConfig.action_key;
        let actionComplete$ = this.modalArgumentMapping[modalId].actionComplete$;
        switch (button_id) {
            case "cancel":
                this.closeModal(actionConfig);
                actionComplete$.next({ button_id, cancel: true });
                actionComplete$.complete();
                break;
            case "ok":
                this.closeModal(actionConfig);
                actionComplete$.next({ button_id, ok: true });
                actionComplete$.complete();
                break;
        }
    }


}