import { Action } from '@ngrx/store';
import { TaskInfoInterface } from '@rubicon/interface/task-info';

export enum AccountDetailsActionTypes {
  LoadAccountDetails = '[CPAProfile] Load AccountDetail',
  AccountDetailsLoaded = '[CPAProfile] AccountDetail Loaded',
  AccountDetailsLoadError = '[CPAProfile] AccountDetail Load Error',
  AccountDetailsFormSubmit = '[CPAProfile] AccountDetail Form Submit',
  AccountDetailsFormCompleted = '[CPAProfile] AccountDetail Form Completed',
  AccountDetailsFormReset = '[CPAProfile] AccountDetail Form Reset'

}

export class LoadAccountDetails implements Action {
  readonly type = AccountDetailsActionTypes.LoadAccountDetails;
  constructor(public payload: string) { }
}

export class AccountDetailsLoadError implements Action {
  readonly type = AccountDetailsActionTypes.AccountDetailsLoadError;
  constructor(public payload: any) {}
}

export class AccountDetailsLoaded implements Action {
  readonly type = AccountDetailsActionTypes.AccountDetailsLoaded;
  constructor(public payload: TaskInfoInterface) {}
}
export class AccountDetailsFormSubmit implements Action {
  readonly type = AccountDetailsActionTypes.AccountDetailsFormSubmit;

  constructor(public slug: string, public payload: any) { }
}

export class AccountDetailsFormCompleted implements Action {
  readonly type = AccountDetailsActionTypes.AccountDetailsFormCompleted;

  constructor(public payload: any) { }
}

export class AccountDetailsFormReset implements Action {
  readonly type = AccountDetailsActionTypes.AccountDetailsFormReset;

  constructor() { }
}
export type AccountDetailsAction =
  | LoadAccountDetails
  | AccountDetailsLoaded
  | AccountDetailsLoadError
  | AccountDetailsFormSubmit
  | AccountDetailsFormCompleted
  | AccountDetailsFormReset

export const fromAccountDetailsActions = {
  LoadAccountDetails,
  AccountDetailsLoaded,
  AccountDetailsLoadError,
  AccountDetailsFormSubmit,
  AccountDetailsFormCompleted,
  AccountDetailsFormReset
};

