<ng-container [formGroup]="group">
    <label [ngClass]="(field?.css?.labelClass)? field?.css?.labelClass :'fs14 fwsb' " *ngIf="slug + '.label.' + field.name | translate"
        [for]="field.name + '_' + indexVal" [innerHTML]='slug + ".label." + field.name | translate'></label>
    <div [ngClass]="field?.css && field?.css?.childdiv?field.css.childdiv:'form-group'">
        <div contenteditable="true" [format]="field?.format" [ngClass]="(field?.css?.field)? field?.css?.field :'form-control' " [formControlName]="field.name"
             [id]="field.name + '_' + indexVal" style="min-height: 127px;" [innerHTML]="field?.value"></div>

        <div *ngIf="group.get(field.name).invalid && (group.get(field.name).dirty || group.get(field.name).touched)"
            class="error-msg">
            <ng-container *ngFor="let validation of field.validations;">
                <label class="error" *ngIf="group.get(field.name).hasError(validation.name)">
                    {{slug + ".errorMessage." + field.name + "." + validation.name | translate}}
                </label>
            </ng-container>
        </div>
    </div>
</ng-container>
