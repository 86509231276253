import { Injectable } from '@angular/core';
import * as _ from 'lodash';
@Injectable({
    providedIn: 'root'
})
export class ExpressionEvaluatorHelper{
    variable_name_regex = /[a-zA-Z0-9\\_\\"]+$/;
	number_regex = /[0-9\\_]+$/;
	operators = ['+', '-', '/', '*', 'divide_to_fixed_2', '>string', '<string'];

	getDependentFields(expression){
		try{
			let tokens = expression.split(' ');
			let field_names = [];
			for (let i = 0; i < tokens.length; i++)
			{
				if(tokens[i].trim() == '' || tokens[i] == '(' || tokens[i] == ')' || this.operators.includes(tokens[i]) || this.isNumber(tokens[i])){

				}else if(this.variable_name_regex.test(tokens[i])){
					field_names.push(tokens[i]);
				}
			}
			return field_names;
		}catch{
			return [];
		}
	}

	evaluate(expression, valuesOb?:any)
	{
        try{
		let tokens = expression.split(' ');

		// Stack for numbers: 'values'
		let values = [];

		// Stack for Operators: 'ops'
		let ops = [];

		for (let i = 0; i < tokens.length; i++)
		{
			// Current token is a whitespace, skip it
			if (tokens[i] == ' ')
			{
				continue;
			}


			// Current token is an opening
			// brace, push it to 'ops'
			if (tokens[i] == '(')
			{
				ops.push(tokens[i]);
			}

			// Closing brace encountered,
			// solve entire brace
			else if (tokens[i] == ')')
			{
				while (ops[ops.length - 1] != '(')
				{
				values.push(this.applyOp(ops.pop(),
								values.pop(),
								values.pop()));
				}
				ops.pop();
			}

			// Current token is an operator.
			else if (this.operators.includes(tokens[i]))
			{
				
				// While top of 'ops' has same
				// or greater precedence to current
				// token, which is an operator.
				// Apply operator on top of 'ops'
				// to top two elements in values stack
				while (ops.length > 0 &&
						this.hasPrecedence(tokens[i],
									ops[ops.length - 1]))
				{
				values.push(this.applyOp(ops.pop(),
								values.pop(),
								values.pop()));
				}

				// Push current token to 'ops'.
				ops.push(tokens[i]);
            }
            else if (valuesOb ? this.variable_name_regex.test(tokens[i]): this.isNumber(tokens[i]))
			{
               if(valuesOb){
				    if(this.isNumber(tokens[i])){
						values.push(+tokens[i]);
					}
                    else if(this.isNumber(_.get(valuesOb, tokens[i]))){
                        values.push(_.get(valuesOb, tokens[i]));
                    }else{
                        values.push(null);
                    }
                }else{
                    ops.push(+tokens[i]);
                }
				
				
			}
		}

		// Entire expression has been
		// parsed at this point, apply remaining
		// ops to remaining values
		while (ops.length > 0)
		{
			values.push(this.applyOp(ops.pop(),
							values.pop(),
							values.pop()));
		}

		// Top of 'values' contains
		// result, return it
        return values.pop();
     }catch{
         return null;
     }
	}

	// Returns true if 'op2' has
	// higher or same precedence as 'op1',
	// otherwise returns false.
	hasPrecedence(op1, op2)
	{
		if (op2 == '(' || op2 == ')')
		{
			return false;
		}
		if ((op1 == '*' || op1 == '/' || op1 == 'divide_to_fixed_2' ) &&
			(op2 == '+' || op2 == '-'))
		{
			return false;
		}
		else
		{
			return true;
		}
	}

	// A utility method to apply an
	// operator 'op' on operands 'a'
	// and 'b'. Return the result.
	applyOp(op, b, a)
	{
		switch (op)
		{
		case '+':
			return a + b;
		case '-':
			return a - b;
		case '*':
            return a * b;
        case '/':
            return a / b;
		case 'divide_to_fixed_2':
			let result = (a/b);
			if(this.isNumber(result)){
				return (result).toFixed(2);
			}
			return null;
		case '<string':{
			let isLess = a < b;
			return isLess?'Yes':'No';
			}
		case '>string':{
			let isLess = a > b;
			return isLess?'Yes':'No';
			}
		}
		return 0;
	}
    
    isNumber(n: any) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

}