import { Inject, Injectable } from '@angular/core';
import { addUserDetails, TaskInfoService } from '@rubicon/utils';
import { WorkflowActionsInterface } from '../../../../interface/workflow-actions/workflow-actions.interface';
import { StageInterface } from '../../../../interface/stages/stages.interface';
import { RoleInterface } from '../../../../interface/roles/roles.interface';
import { Store } from '@ngrx/store';
import { map, switchMap, take } from 'rxjs/operators';
import { of } from 'rxjs';
@Injectable({
    providedIn: 'root'
})

export class WorkflowActionsService {

    WorkflowActions: Array<WorkflowActionsInterface>;
    stages: Array<StageInterface>;
    roles: Array<RoleInterface>
    userData: any;
    app_sub_status = [];
    constructor(
        private taskInfoService : TaskInfoService,
        public store: Store<any>,
        @Inject('CONSTANTS') public CONSTANTS,
        @Inject('environment') private environment,
        ) {
            store.select('app').pipe().subscribe(rootState => {
                this.userData=rootState?.userData;
                if(!this.WorkflowActions){
                    this.WorkflowActions = rootState?.userData?.workflowInfo?.WorkflowActions;
                    this.stages = rootState?.userData?.workflowInfo?.stages;
                    this.roles = rootState?.userData?.workflowInfo?.roles;
                    this.app_sub_status = rootState?.userData?.workflowInfo?.app_sub_status || [];
                }
            })
         }

    setWorkflowActions() {
      return this.store.select('app').pipe(take(1), switchMap(rootState => {
        this.userData=rootState?.userData;
        return this.taskInfoService.getTaskInfo({ slug: this.CONSTANTS.SLUG['get-stages-actions'],model: 'workflow_actions,app_sub_status,app_status' , no_encryption : true})
        .pipe(map(res => {
            let master_data_optimised = res.response_data?.master_data_optimised?.data?.data;
            this.stages = res.response_data?.get_stages?.data.data|| [];
            this.roles = res.response_data?.get_role_by_slug?.data.data|| [];
            this.WorkflowActions = master_data_optimised?.workflow_actions || [];
            let app_sub_status = master_data_optimised?.app_sub_status || [];
            this.app_sub_status = app_sub_status;
            let app_status = master_data_optimised?.app_status || [];;
            const wfData = { 
                roles: this.roles, 
                stages: this.stages, 
                WorkflowActions: this.WorkflowActions,
                app_status,
                app_sub_status
             }
            this.store.dispatch(addUserDetails({
              userData: {...this.userData, workflowInfo:wfData}
            }));
            return of({...this.userData, ...wfData})
        }));
      }));
    }

    getWorkflowActions(actionType: string): WorkflowActionsInterface {
        if (this.WorkflowActions ?.length ) {
            return this.WorkflowActions.find(elem => elem.type === actionType);
        }
    }

    getAllWorkflowActions(){
        return this.WorkflowActions || [];
    }

    getStages(key:string, value?: string|number): StageInterface {
        if (this.stages ?.length ) {
            return this.stages?.find(elem => elem[key] === value);
        }
    }

    getMultipleActions(params, value) {
        return this.WorkflowActions?.filter(elem => elem[params] === value);
    }

    getRolesById(id: string): RoleInterface {
        return this.roles?.find(elem => elem.id === id);
    }

    getAllStages(){
        return this.stages;
    }

    getBankerData(app_assignment:any,currentStage:any,type: string, value?: number) {
        const to = [];
        let noBankerFound=false;
        const phoneNo=[];
        const to_name = [];
        switch (type) {
            case 'all_assigned_banker':
                app_assignment?.forEach(item=>{
                    if(item?.assigned_to && item?.email_address && item?.name) {
                        to.push(item.email_address);
                        to_name.push(item.name);
                    }
                })
                break;
            case 'current_banker':
                let currentAssignedBanker = app_assignment?.find(({stage})=> stage === currentStage);
                if (currentAssignedBanker && currentAssignedBanker?.assigned_to && currentAssignedBanker?.email_address && currentAssignedBanker?.name) {
                    to.push(currentAssignedBanker.email_address);
                    to_name.push(currentAssignedBanker.name);
                }
                break;
            case 'logged_banker':
                to.push(this.userData?.email_address);
                to_name.push(this.userData?.full_name);
                break;
            case 'specific_stage_banker':
                let stageId = this.stages?.find(({order}) => order === value)?.id;
                let assignedBanker = app_assignment?.find(({stage})=> stage === stageId);
                if (assignedBanker && assignedBanker?.assigned_to && assignedBanker?.email_address && assignedBanker?.name) {
                    to.push(assignedBanker.email_address);
                    to_name.push(assignedBanker.name);
                    if(assignedBanker?.phone)
                        phoneNo.push(assignedBanker.phone);
                }
                else{
                    noBankerFound=true;
                }
                break;
        }
        let returnVal={
            to: Array.from(new Set(to)),
            to_name: Array.from(new Set(to_name)),
            phone_no:phoneNo,
            banker_siteurl: this.environment.banker_url,
            header_logo_path_1: this.environment.logo1_path,
            header_logo_path_2: this.environment.logo1_path,
            client_name: this.CONSTANTS.MAIL_TEMPLATE.project_name,
            senders_name: this.CONSTANTS.MAIL_TEMPLATE.senders_name,
            copyright_text: this.CONSTANTS.MAIL_TEMPLATE.copyright_text,
            terms: this.environment.terms,
            privacy: this.environment.privacy
        }
        if(noBankerFound){
            returnVal['noBankerFound']=noBankerFound;
        }
        return returnVal;
    }

    getRoleMapping() {
        let ROLE_SLUG = {};
        if (this.roles?.length) {
            for (let role of this.roles) {
                let {role_slug, name} = role;
                if(role_slug){
                    let short_slug = role["short_slug"] || name; 
                    ROLE_SLUG[role_slug] = {
                        [role_slug]: short_slug,
                        "default" : short_slug,
                        "onAppListing": short_slug
                    }
                }
            }
        }
        return ROLE_SLUG;
    }
}
