import { Injectable } from "@angular/core";
// import { Buffer } from 'buffer';


@Injectable({
    providedIn: "root"
})
export class ReferralSourceService {
    constructor() { }

    encodeBase64(data) {
        // return Buffer.from(data).toString('base64');
        try {
            return btoa(data);
        } catch (e) {
            return undefined;
        }
    }
    decodeBase64(data) {
        //     return Buffer.from(data, 'base64').toString('ascii');
        try {
            return atob(data);
        } catch (e) {
            return undefined;
        }
    }

}