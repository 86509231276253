import { Component, OnInit, Output, Input, EventEmitter, Inject, TemplateRef, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { switchMap, take, map } from 'rxjs/operators';
import { CommonService, TaskInfoService } from '@rubicon/utils_custom';
import { FormGroup } from '@angular/forms';
import { FormFieldInterface } from '@rubicon/interface/form-field';
import { FormGenerateService } from 'libs/utils/src/lib/services/form/form-generate.service';
import { SignupStateFacade } from 'libs/affiliate-portal/signup/src/lib/+state/signup-state.facade';
import { TryLoadingSignupTaskInfo, SignupFormSubmit } from 'libs/affiliate-portal/signup/src/lib/+state/signup-state.actions'
import { Subscription } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
@Component({
  selector: 'rubicon-dynamic-signup',
  templateUrl: './dynamic-signup.component.html',
  styleUrls: ['./dynamic-signup.component.scss']
})
export class DynamicSignupComponent implements OnInit {
  signupForm: FormGroup;
  signupConfig: FormFieldInterface[] = [];
  @Input('slug') slug;
  REACH_DATA = [
      {id: 1, value: "CPA.com Website"}, 
      {id: 2, value: "Informational Webinar"}, 
      {id: 3, value: "AICPA Town Hall"},
      {id: 4, value: "CPA.com Account Representative"},
      {id: 5, value: "LinkedIn Ad or Post"},
      {id: 6, value: "Google / Bing / Search"},
      {id: 7, value: "Biz2Credit Website"},
      {id: 8, value: "From Another CPA Firm"},
      {id: 9, value: "A Colleague at My Firm"},
      {id: 10, value: "I Don't Remember"},
      {id: 11, value: "Email"},
      {id: 12, value: "Other"}
  ];
  signupStateSubscription: Subscription;
  signupResponseSubs: Subscription;
  firmData: any;
  userRequestRef: BsModalRef;
  @ViewChild('userSuccess', {static: true}) userRequestTemplate: TemplateRef<any>;
  newBoardingPopUpVariable:boolean=true;
  
  constructor( 
      private store: Store<any>,
      @Inject('environment') private environment,
      private taskInfoService: TaskInfoService,
      private formGenerate: FormGenerateService,
      private common: CommonService,
      private signupStateFacade: SignupStateFacade,
      private modalService: BsModalService
      ){
      }
      
  ngOnInit(): void {
    this.signupStateFacade.dispatch(new TryLoadingSignupTaskInfo(this.slug));
    this.store.
      pipe(
        select('app'),
        take(1)
      ).subscribe(rootState => {
        if(rootState) {
          if (rootState.nextTask) {
          }
        }
      });

      this.signupStateSubscription = this.signupStateFacade.allSignupState$.subscribe(signupState => {
        if (signupState) {
          this.signupConfig = signupState.taskInfo.form_fields;
          this.signupForm = this.formGenerate.createControl(this.signupConfig);
          this.common.sendMasterDataToFields(this.signupConfig, {
            reach_source: { 
              data: {
                data: this.REACH_DATA,
              }
            }
          });
          this.signupForm.get('cpa_licensee_no').valueChanges.subscribe(val=>{
            if(val.length){
              this.signupForm.get('isCpaLicenseeNo').setValue(true);
            }else{
              this.signupForm.get('isCpaLicenseeNo').setValue(false);
            }
          })
        }
      });
  
      this.signupStateFacade.getErrorState$.subscribe((errors) => {
        if(errors) {
          if(errors.affiliate_create_backend_user &&
            errors.affiliate_create_backend_user.errors &&
            errors.affiliate_create_backend_user.errors.errors) {
              const signupError = errors.affiliate_create_backend_user.errors.errors;
              const emailError = signupError.find((err) => err.type === 'FIELD_INVALID' && err.parameter === 'email_address');
              if(emailError) {
                this.common.navigateByUrl('/signin');
                return;
              }
          }
        }
      });
  
      this.signupResponseSubs = this.signupStateFacade.submitSignupState$.subscribe((result) => {
        if(result) {
          if(result.ppp2_aicpa_user_verification_mail &&
            result.ppp2_aicpa_user_verification_mail.data &&
            result.ppp2_aicpa_user_verification_mail.data.code === 200) {
              this.signupForm.reset();
              sessionStorage.setItem('verification_mail', 'Verification mail sent to '+ result.aicpa_backend_email_hash.data.data.email_address);
              this.common.navigateByUrl('/signin/verification-msg');
            }
  
          if(result.aicpa_backend_email_hash &&
            result.aicpa_backend_email_hash.data &&
            result.aicpa_backend_email_hash.data.data.user_id) {
              // save tracking params
              this.common.startLoad();
              this.common.getQueryTrackingParamsObservable().pipe(
                switchMap((trackingParams:any)=>{
                  return this.taskInfoService.saveTaskInfo({slug: 'user_track'},{
                    ...trackingParams,
                    user_id: result.aicpa_backend_email_hash.data.data.user_id,
                    register: 'new',
                    type : 'backend'
                  });
                })
              ).subscribe((response) => {
                this.common.stopLoad();
              },
              () => {
                this.common.stopLoad();
              },
              () => {
                this.common.stopLoad();
              });
            }
        }
    });
  }


  onSubmit() {
    if (!this.signupForm.invalid) {
     let body ={
      "track_info": {
      "firmname": this.signupForm.value.cpa_firm_name,
      "source": this.signupForm.value.cpa_firm_name,
      "subsource":this.signupForm.value.cpa_firm_name,
      "affiliate": "CPA.com",
      "track_name": "CPA Portal API",
     }}

      const data = { ...this.signupForm.value, registration_type: 'normal', newBoardingPopUp:this.newBoardingPopUpVariable,portal: 'cpabusinessloans', ...body};
      data.password = this.signupForm.value.passwords.password;
      data.retypepassword = this.signupForm.value.passwords.retypepassword;
      if(this.signupForm.get('passwords').get('retypepassword')) {
        if(data.password !== data.retypepassword) {
          this.common.popToast('error','Error', 'Password & Confirm Password did not match.');
          return;
        }

        data.retypepassword = "";
      }

      if(data.first_name && data.last_name) {
        data.name = this.ucFirst(data.first_name + ' '+ data.last_name);
      }
      this.checkMemberID(this.signupForm.value.registration_code).subscribe((res: any) => {
        if (res.response_data.get_user_member_id.data.data.length) {
          this.signupForm.get('registration_code').reset();
          this.common.popToast('error','Error', 'The AICPA Member Number entered is already in use. Please provide a correct AICPA Member Number.');
        } else {
        this.taskInfoService.getTaskInfo({ slug: 'get-cpa-firm', cpa_firm_name: data.cpa_firm_name })
        .subscribe((result) => {
          if (result.response_data &&
            result.response_data &&
            result.response_data.get_firm_user &&
            result.response_data.get_firm_user.data &&
            result.response_data.get_firm_user.data.data &&
            result.response_data.get_firm_user.data.data.length > 0) {

              const firm = result.response_data.get_firm_user.data.data.find(i=>i.registration_type === "normal");
              this.firmData = firm.name;
              this.userRequestRef = this.modalService.show(
                this.userRequestTemplate,
                Object.assign({},)
              );
              
            
              if(firm.is_verified === true ){
                const data = { user_email_address: this.signupForm.value.email_address, admin_name: firm.name, email_address: firm.email_address, backend_user_id: firm.id, cpa_firm_name: firm.cpa_firm_name, phone_number: this.signupForm.value.phone_number, first_name: this.signupForm.value.first_name, last_name: this.signupForm.value.last_name, logo1_path: this.environment.cdnURL + 'cpa-sig-logo-hed120.png', header_logo_path: this.environment.cdnURL + 'biz2credit-logo.png', frontend_url: "https://cpaloanportal.com" };
                this.taskInfoService.saveTaskInfo({ slug: 'create-inactive-user'}, data)
                .subscribe((result) => {
                }) 
                return;
              }
          } else {
            if( data.passwords) delete data.passwords;
            this.signupStateFacade.dispatch(new SignupFormSubmit(this.slug, data));
          }
        });
    }})
    } else {
          this.formGenerate.validateAllFormFields(this.signupForm);
    }
  }

  ucFirst(str) {
    const array = str.split(" ");
    let updatedStr = "";
    array.forEach(a => {
      updatedStr += a.substring(0,1).toUpperCase() + a.substring(1).toLowerCase() + " ";
    });

    return updatedStr.trim();
  }
  
  checkMemberID(member_id) {
    return this.taskInfoService.getTaskInfo({
      slug: 'get-user-member-id',
      registration_code: member_id
    })
      .pipe(
        map((data) => {
          return data
        }))
  }
}