import { Directive, HostListener, ElementRef, Host } from '@angular/core';

@Directive({
  selector: '[rubiconAmount]'
})
export class AmountDirective {

  inputElement: HTMLElement;


  constructor(public el: ElementRef) {
    this.inputElement = el.nativeElement;
  }

  @HostListener('change', ['$event'])
  onChange(e) {
    if (!isNaN(+e.target.value))
      return Number(e.target.value).toLocaleString();

    return e.target.value;
  }
}
