<div [ngClass]="field.css.fieldCol? field.css.fieldCol:''" [formGroup]="group">
    <ng-container *ngIf="field?.css?.floatingLabel; else normal">
        <div [ngClass]="field.css.swithParent? field.css.swithParent:'floating-label'">
            <input 
                mask='(000) 000-0000' 
                [type]="field.type" 
                [ngClass]="field.css.inputClass? field.css.inputClass:'floating-input'"
                placeholder="&nbsp;" 
                [formControlName]="field.name" 
                [id]="field.name">
            <span class="highlight"></span>
            <label [ngClass]="(field?.css?.labelClass)? field?.css?.labelClass :'labelText'" [for]="field.name" >{{slug +".label."+field.name | translate}}</label>
            <div *ngIf="group.get(field.name).invalid && (group.get(field.name).dirty || group.get(field.name).touched)"
                class="error-msg">
                <ng-container *ngFor="let validation of field.validations;">
                    <label class="error" *ngIf="group.get(field.name).hasError(validation.name)">
                        {{slug +".errorMessage."+field.name+"."+validation.name | translate}}
                    </label>
                </ng-container>
            </div>
        </div>
    </ng-container>

    <ng-template #normal>
        <!-- <div class="col-lg-12" *ngIf="slug +'.label.'+field.name | translate">
            <div class="form-group m-0">
                <label [ngClass]="(field?.css?.labelClass)? field?.css?.labelClass :'secondary-label'" [for]="field.name">{{slug +".label."+field.name | translate}}</label>
            </div>
        </div>    -->
        <!-- <div [ngClass]="field?.css?.childdiv || 'col-12'"> -->
                <!-- <label [ngClass]="(field?.css?.labelClass)? field?.css?.labelClass :'sr-only'" >{{slug +".label."+field.name | translate}}</label> -->
                <label *ngIf="slug + '.label.' + field.name | translate"
        [ngClass]="(field?.css?.labelClass)? field?.css?.labelClass :'p-label'"
        [for]="field.name" [innerHTML]='slug + ".label." + field.name | translate'>
    </label>
                <input mask='(000) 000-0000' [type]="field.type" [placeholder]="slug+'.placeholder.'+field.name | translate" class="form-control"
                    [formControlName]="field.name" [id]="field.name"  >
                <div *ngIf="group.get(field.name).invalid && (group.get(field.name).dirty || group.get(field.name).touched)"
                    class="error-msg">
                    <ng-container *ngFor="let validation of field.validations;">
                        <label class="error" *ngIf="group.get(field.name).hasError(validation.name)">
                            {{slug +".errorMessage."+field.name+"."+validation.name | translate}}
                        </label>
                    </ng-container>
                </div>
        <!-- </div> -->
    </ng-template>
</div>
