<ng-container [formGroup]="group">
    <label [for]="field.name+ '_' + indexVal" [ngClass]="(field?.css?.labelClass)? field?.css?.labelClass :'sr-only'"
    [innerHTML]='slug + ".label." + field.name | translate'>
        <span href="javascript:void(0)" *ngIf="slug + '.description.' + field.name | translate" class="infoTip" #infoTip
            data-placement="top" data-toggle="tooltip" [title]="slug + '.description.' + field.name | translate">
        </span>
    </label>
    <input class="form-control" [placeholder]="slug + '.placeholder.' + field.name | translate" [bsConfig]="{ showWeekNumbers:false,dateInputFormat: dateFormat }" #dp="bsDatepicker" [bsConfig]="{ adaptivePosition: true }" bsDatepicker type="text"
        [formControlName]="field.name" [id]="field.name + '_' + indexVal" [minDate]="minDate" [maxDate]="maxDate">
    <button class="calendar" (click)="dp.toggle()" [attr.aria-expanded]="dp.isOpen" type="button"><span class="sr-only">calendar</span></button>
    <div *ngIf="group.get(field.name).invalid && (group.get(field.name).dirty || group.get(field.name).touched)"
        class="error-msg">
        <ng-container *ngFor="let validation of field.validations;">
            <label class="error" *ngIf="group.get(field.name).hasError(validation.name)">
                {{slug + ".errorMessage." + field.name + "." + validation.name | translate}}
            </label>
        </ng-container>
        <label class="error" *ngIf="group.get(field.name).hasError('incorrect')">
            {{slug + ".errorMessage." + field.name + ".incorrect" | translate}}
        </label>
    </div>
</ng-container>