<ng-container [formGroup]="group">
    <ng-container>
        <div [ngClass]="(field?.css?.wrapper)? field?.css?.wrapper :''">
            <label [ngClass]="(field?.css?.labelClass)? field?.css?.labelClass :'p-label'"
                [for]="field.name + '_' + indexVal" [innerHTML]='slug + ".label." + field.name | translate'>
            </label>
             <ng-container *ngIf="field?.css?.tooltip && field?.css?.tooltip[field.name]">
                <a href="javascript:void(0)" [tooltip]="popTemplateth" [triggers]="(field?.css?.tooltip[field.name+ '_trigger'])?field?.css?.tooltip[field.name+ '_trigger']:'hover'" 
                [ngClass]="field?.css?.tooltip[field.name]?field?.css?.tooltip[field.name]:'ml-1'">
                  <img src="{{environement?.asset_path}}images/info-icon-n.svg" alt="tooltip"  class="infoimg" /> </a>
                <ng-template #popTemplateth>
                 <div class="fs12 text-left fwr my-1 gtColor" [innerHTML]='slug + ".tooltip."+ field.name | translate'></div>
            
            </ng-template>
              </ng-container>
              
            <ng-select *ngIf="!ngSelectDivWrap" [ngClass]="(field?.css?.field)? field?.css?.field :'form-control'"
                [groupBy]="field?.css?.groupkey || null" [maxSelectedItems]="field?.config?.max_limit"
                [id]="field.name + '_' + indexVal" [items]="this.options" [virtualScroll]="virtualScroll"
                [formControlName]="field.name" [multiple]="field.multiple" bindLabel="{{ displayKey | translate  }}"
                [clearable]="field?.config?.clearable? field.config.clearable : false" [bindValue]="valueKey"
                placeholder="{{slug + '.default_label.' + field.name | translate }}"
                (click)="normalClass()"
                >
                <ng-template ng-optgroup-tmp let-item="item">
                    {{item[field?.css?.groupkey] || ''}}
                </ng-template>
            </ng-select>

            <div class="{{slug+field.name + '_' + ngForIndex}}" *ngIf="ngSelectDivWrap" [ngClass]="(field?.css?.ngSelectAWrapClass)? field?.css?.ngSelectAWrapClass :'d-inline'">
                <ng-select [ngClass]="(field?.css?.field)? field?.css?.field :'form-control'"
                    [groupBy]="field?.css?.groupkey || null" [maxSelectedItems]="field?.config?.max_limit"
                    [id]="field.name + '_' + indexVal" [items]="this.options" [virtualScroll]="virtualScroll"
                    [formControlName]="field.name" [multiple]="field.multiple" bindLabel="{{ displayKey | translate  }}"
                    [clearable]="field?.config?.clearable? field.config.clearable : false" [bindValue]="valueKey"
                    placeholder="{{slug + '.default_label.' + field.name | translate }}"
                    (click)="normalClass()"
                    >
                    <ng-template ng-optgroup-tmp let-item="item">
                        {{item[field?.css?.groupkey] || ''}}
                    </ng-template>
                </ng-select>
            </div>
        </div>
        <div *ngIf="group.get(field.name).invalid && (group.get(field.name).dirty || group.get(field.name).touched)"
         [ngClass]="(field?.css?.errorMes)? field?.css?.errorMes :'error-msg'" [ngClass]="(field?.css?.error)? field?.css?.error :''">
            <ng-container *ngFor="let validation of field.validations;">
                <label class="error" *ngIf="group.get(field.name).hasError(validation.name)">
                    {{slug + ".errorMessage." + field.name + "." + validation.name | translate}}
                </label>
                
            </ng-container>
           
            <label class="error" *ngIf="group.get(field.name).hasError('incorrect')">
                {{slug + ".errorMessage." + field.name + ".incorrect" | translate}}
            </label>
        </div>
     
    </ng-container>
</ng-container>