export const CONFIG = {
    partner: 'CPA',
    show_plans: 'CPA', // remove this code. only applicable when BFP has no plans.
    "client_tenant_id": "65d5d6000bca1cf0e1398957",
    "features": {
        "text" : {
            "header": {
                "firm_name": "CPA",
                "support_mail": "cpasupport@biz2credit.com"
            },
            "footer" : {
                "support" : "cpasupport-premier@biz2credit.com",
                "policy" : "CPA Policy"
            },
            "manage_users" : {
                "new_user_creation_support_link" : "cpasupport-premier@biz2credit.com"
            }
        },
        "view" : {
            "header": {
                "upgrade_plan" : true,
                "help_faq" : true,
                "notification" : true,
                "resource_hub": true,
            },
            "signup": {
                
                "benefits_and_features" : true,
                "client_qualifications" : true,
                "financing_solution" : true,
                "testimonials" : true,
                "subscription_pricing" : true,
                "resource_hub" : true,
            },
            "login": {
                "signup_text": true
            },
            "dashboard": {
                "accountant_finder" : true,
                "news_resources" : true,
                "affiliate_link" : true,
                "application_list" : true,
                "application_search" : true, // input field and clear filter button
                "application_new" : true,
                "portal-demo": true,
                "onboarding_welcome_popup":true,
                "onboarding_representative_popup":true,
                "updated_terms_condition": true
            },
            "my_client" : {
                "affiliate_link" : true,
                "client_list" : true,
                "client_search" : true, // input field and clear filter button
                "client_new" : true,
                "client_upload" : true,
                "prequal_cal": true, // in create client component.
            },
            "my_plan": {
                "current_plan" : true,
                "details_section":true,
                // "progress_bar" : false,
                "upgrade_plan" : true,
                "usage_dashboard" : true,
                // "addons" : true,
                "commission_report" : true,
                "funnel_report": true,
                "payment_invoices" : true
            },
            "products": {
                "sba": true,
                "working_capital": true,
                "term_loan": true,
                "ertc_loan": true,
                "loan_forgiveness": true,
                "ppp":true
            },
            "subscription_plan": {
                "basic": true,
                "new_premium": true
            },
            "usage_dashboard":{
                "termloan_product_application":true,
                "working_capital_product_application":true,
                "ertc_product_application":true,
                "sba_product_application":true,
                "custom_roles":true,
                "users":true,
                "forgiveness_application_purchase_btn":true
            },
            "commission_report":{
                "commission_text":true,
                "download_excel_btn":true,
                "report_table":true,
                "list_text":true,
                "setup_bank_accout_payments_text_and_btn":true,
                "submit_w9form_text_and_btn":true,
            },
            "funnel_report":{
                "funnel_text":true,
                "application_ppp" : true,
                "application_forgiveness": true,
                "application_term_loan" : true,
                "application_working_capital":true,
                "application_ertc":true,
                "application_sba" : true
            },
            "profile_directory" : {
                // "create_firm_profile_text" : false,
                "disclaimer": true,
            },
            "pre_qualification":{
                "download_tl_checklist": true,
                "download_rbf_checklist": false,
            },
            "application_submission": {
                "disclaimer_cpa": true,
                "disclaimer_bfp": false,
            }
        }
    },
    // dynamic plans page for multi-tenant
    plans: {
        enum: {
            FREE: { type: 'free', id: '5f48d55a00df162a64a8a57a' },
            PREMIUM: {type: 'premium', id: '5e43ab3deeed3a3c3092c40c' },
            ENTERPRISE: { type: 'enterprise', id: '5f3fa4d5b0ab674488f010e7'},
            BASIC: { type:'basic', id: '6566ffe20fbc390097f1c772' },
            NEW_PREMIUM: {type: 'new_premium', id: '659f7fd5ce7e57cd1b19290d'}
        },
        retroPartnerPlans: ['free', 'premium', 'enterprise'],
        currentPartnerPlans: ['free', 'basic', 'new_premium'],
        showPlans: {
            free: ['free','premium','enterprise'],
            basic: ['basic', 'new_premium'],
            new_premium: ['basic','new_premium'],
            premium: ['basic','new_premium'],
            enterprise: ['basic','new_premium']
        },
        premiumSupportMail : ['enterprise','premium'],
        premiumSupportMailId: "cpasupport-premier@biz2credit.com",
        moveToDashboard: ['new_premium'],
    },
    coupons: {
        new_user_coupons : ['CPAWELCOME','ENGAGE20'],
        old_user_coupons : ['CPASPECIAL'],
        premium_coupons : ['CPAPREMIUM'],
        enterprise_coupons : ['CPAENTERPRISE'],
        new_coupons : ['CPA10NEW', 'CPA20NEW'],
    },
    source: {
        'case manager' : 'Funding Specialist',
        'Case Manager' : 'Funding Specialist'
    },
    redirect: { 
        /** do not keep the route where key and value names are same.
         * It will create a loop and the page will not load.
         */
      
    },
    OWNER_CONSENT:{
        SBA7A: true,
        ACH_PRODUCTS: true
    },
    PRODUCT_TYPE_CONFIG:{
        "5cf61ae8a567c9873cbab4d5": 
            {
            "name": "forgiveness",
            "owner_percentage": 100,
            },
        "5f40fb82b365b23a003e3708": 
            {
            "name": "ppp2",
            "owner_percentage": 100
            },
        "616415139c0b772898330eec": 
            {
            "name": "term_loan",
            "owner_percentage": 100,
            "document_config":{
                required_docs: [
                    "5ef88ad2b3aba0284c3f479b",
                    "5efaefb8918474c308cd37fd",
                    "4efaefb8918474c408ce37f9",
                    "5ef89ad2b3aba0274c3f47a1",
                    "5ef89ad3b3aba0284c3f47a1",
                    "5ef19ad3b3abb0284c3f47a1",
                    ]
                }
            },
        "62260b1e7cdf3e5be1eb6be4": 
            {
            "name": "working_capital",
            "owner_percentage": 100,
            "tax_docs_required": true,
            "document_config":{
                required_docs: [
                    "5ef88ad2b3aba0284c3f479b",
                    "5efaefb8918474c308cd37fd",
                    "5ef89ad3b3aba0284c3f47a1",
                    "5ef19ad3b3abb0284c3f47a1",
                    ]
                }
            },
        "62b17842a2d8a20494cf846c": 
            {
            "name": "ertc",
            "owner_percentage": 100,
            "document_config":{
                required_docs: [
                    "62c7fb4edb4b00a8528b13d7",
                    "62b2a68b31ab171bb19c199a",
                    "62b2a68231ab171bb19c1999",
                    "5ef88ad2b3aba0284c3f479b",
                    "62b2a65231ab171bb19c1998",
                    "62baeb6452695772fa3fe8a3",
                    "62baf7d052695772fa3fe8a5",
                    // "5efaefb8918474c308cd37fd",
                    ],
                    optional_docs: [
                        "62c7fc96db4b00a8528b13ee",
                        "62baf7d052695772fa3fe8a5",
                        "617267518a90b6f2a9a8a26b",
                        "654b121d0ef58c1e442a246f",
                        "654b1e52c4235d476bffc125",
                        "654c65561350d562484ae95f",
                        "654c65886d800a18d14f078c",
                        "654c65be4c558f86f439bfc6",
                        "654c65e89c292c314df5c505"
                    ]
                }
            },
        "63a0485a27f0b23bf982e256": 
            {
            "name": "sba",
            "owner_percentage": 100
            }
    }
}

