<ng-container [formGroup]="group">
    <ng-container *ngIf="field?.css?.floatingLabel; else normal">
            <!-- [class.twoline]="field.name == 'alt_covered_start_date'" -->
        <div class="dobicon floating-label" [ngClass]="(field?.css?.containerClass)? field?.css?.containerClass :''" [class.focusActive]="isFilled">

                <!-- <input type="text" 
                        bsDatepicker 
                        [bsConfig]="{containerClass:'theme-dark-blue'}" 
                        class="btn btn-info btn-lg btn14 pr" 
                        placeholder="Date of appointment" 
                        [id]="field.name + '_' + indexVal" 
                        [formControlName]="field.name"
                        [ngClass]="(field?.css?.inputClass)? field?.css?.inputClass :'floating-input'"
                        /> -->
            <input 
                type="text" 
                tabindex="-1" 
                [ngClass]="(field?.css?.inputClass)? field?.css?.inputClass :'floating-input'"
                [id]="field.name + '_' + indexVal" 
                [formControlName]="field.name"
                placeholder="&nbsp;" ngbDatepicker #d="ngbDatepicker" 
                [minDate]="minDate"
                [maxDate]="maxDate"
                >
                <!-- <label [ngClass]="(field?.css?.labelClass)? field?.css?.labelClass :'labelText'" [for]="field.name + '_' + indexVal">{{slug + ".label." + field.name | translate}} -->   
                    <ng-container *ngIf="field?.css?.tooltip && field?.css?.tooltip[field.name]"><!--
                        --><a  [ngClass]="(field?.css?.calendarTooltipClass)? field?.css?.calendarTooltipClass :''" class='que_info-text' placement="right" [ngbTooltip]="tipContent"><!----></a><!--​
                        --><ng-template #tipContent><span [innerHTML]='slug + ".tooltip."+ field.name | translate'></span></ng-template><!--
                        --></ng-container>
                    <!-- </label> -->
                <div class="input-group-append">
                    <button class="calicon calendar" (click)="d.toggle()" type="button" [id]="'calendar_' + indexVal"></button>
                </div>
            <span class="highlight"></span>
            <label
                [ngClass]="(field?.css?.labelClass)? field?.css?.labelClass :'labelText'" 
                [for]="field.name+ '_' + indexVal">{{slug + ".label." + field.name | translate}} </label>
            <i class="fs10 fwsb" *ngIf="field.name == 'alt_covered_start_date'">
                    {{slug + ".label." + field.name+"_help_text" | translate}}
            </i>
                <div *ngIf="group.get(field.name).invalid && (group.get(field.name).dirty || group.get(field.name).touched)"
                    class="error-msg">
                    <ng-container *ngFor="let validation of field.validations;">
                        <label class="error" *ngIf="group.get(field.name).hasError(validation.name)">
                            {{slug + ".errorMessage." + field.name + "." + validation.name | translate}}
                        </label>
                    </ng-container>
                    <label class="error" *ngIf="group.get(field.name).hasError('incorrect')">
                        {{slug + ".errorMessage." + field.name + ".incorrect" | translate}}
                    </label>
                </div>    
            </div>
    </ng-container>
    
    <ng-template #normal>
        <div>
            <label [for]="field.name+ '_' + indexVal"
            [ngClass]="(field?.css?.labelClass)? field?.css?.labelClass :'primary-label fs14 fwsb'"
            >
                {{slug + ".label." + field.name | translate}} 
                <span href="javascript:void(0)" *ngIf="slug + '.description.' + field.name | translate" class="infoTip" #infoTip data-placement="top" data-toggle="tooltip"
                    [title]="slug + '.description.' + field.name | translate" >
                </span>
            </label>
        </div>
        <label class="pr d-block" [for]="'calendar_' + indexVal">
            <input type="text" class="form-control" tabindex="-1" [id]="field.name + '_' + indexVal" [formControlName]="field.name"
                placeholder="mm/dd/yyyy" ngbDatepicker #d="ngbDatepicker" 
                [minDate]="minDate"
                [maxDate]="maxDate"
                >
            <div class="input-group-append">
                <button class="calicon calendar" (click)="d.toggle()" type="button" [id]="'calendar_' + indexVal"></button>
            </div>
        </label>
        <div *ngIf="group.get(field.name).invalid && (group.get(field.name).dirty || group.get(field.name).touched)"
            class="error-msg">
            <ng-container *ngFor="let validation of field.validations;">
                <label class="error" *ngIf="group.get(field.name).hasError(validation.name)">
                    {{slug + ".errorMessage." + field.name + "." + validation.name | translate}}
                </label>
            </ng-container>
        </div>
    </ng-template>
    
    
</ng-container>

<div *ngIf="field.css && field.css.condition" class="row mt40 mb40 pb20">
    <div class="col">
        <div class="boototmline"></div>
    </div>
</div>