import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { take, filter } from 'rxjs/operators';
import { Router,NavigationStart, Event as NavigationEvent   } from '@angular/router';

@Component({
  selector: 'platform-core-save-exit',
  templateUrl: './save-exit.component.html',
  styleUrls: ['./save-exit.component.scss']
})
export class SaveExitComponent implements OnInit {

  @Output() buttonClicked: EventEmitter<string> = new EventEmitter;
  currentRoute: string;
  event$;
  buttonVisible: boolean = false;

  constructor(
    public store: Store<any>,
    private router: Router,
    @Inject('CONSTANTS') public CONSTANTS
  ) {
    this.currentRoute = router.url
  }

  ngOnInit(): void {
    this.store.select('app').pipe(take(1)).subscribe(data => {
      let current_step;
      for (const [key, value] of Object.entries(this.CONSTANTS.APP_ROUTES)) {
        if(this.currentRoute === value){
          current_step = key;
        }
      }
      if(data?.configurations){
        let steps = data?.configurations[0]?.settings.saveAndExit.steps;
        if(steps.globalAccess === true){
          this.buttonVisible = steps[current_step] === false ? false : true;
        } else {
          this.buttonVisible = steps[current_step] === true ? true : false;
        }
      }
      this.buttonVisible = current_step === undefined ? false : this.buttonVisible;
    })
  }

  onSave() {
    this.buttonClicked.emit();
  }

}
