import { createAction, props } from '@ngrx/store';

export const addNextTask = createAction('[UtilState] Add Next Task', props<{ nextTask: string }>());
export const addUserDetails = createAction('[UtilState] Add User Details', props<{ userData: any }>());
export const addBackendUserDetails = createAction('[UtilState] Add Backend User Details', props<{ userData: any }>());
export const addAppID = createAction('[UtilState] Add App ID', props<{ appID: string }>());
export const addBusinessID = createAction('[UtilState] Add Business ID', props<{ businessID: string }>());
export const onReview = createAction('[UtilState] App on Review', props<{ reviewTask: string }>());
export const updateOtpEmail = createAction('[UtilState] Update OTP Email Address', props<{email_address: string}>());
export const addEmailStatus = createAction('[UtilState] Add Email Status', props<{ is_payroll_processor_sent: number, is_payroll_processor_linked: number, is_bank_statement_sent: number, is_bank_statement_linked: number, bank_statement_hash: string ,is_data_source_linked: boolean,is_data_source_sent: number}>());
export const addSkipState = createAction('[UtilState] Add Skip State', props<{ skipStep: string}>());
export const addParentUser = createAction('[UtilState] Add Parent User', props<{ parentUser: any}>());
export const addPlanCheckoutData = createAction('[UtilState] Add Plan Checkout Data', props<{ planCheckoutData: any}>());
export const addPaymentPlanData = createAction('[UtilState] Add Plan Data For Payment', props<{ planData: any}>());
export const addVerifierSetupData = createAction('[UtilState] Add verifier setup', props<{ verifierProfileData: any}>());
export const addMasterData = createAction('[UtilState] add Master Data', props<{ masterData: any }>());
export const addReplicatedAppData = createAction('[UtilState] add Replicated App Data', props<{ replicatedAppData: any }>());
export const addAppDetails = createAction('[UtilState] Add App Details', props<{ appData: any }>());
export const addProductType = createAction('[UtilState] Add Product Type', props<{ productType: string }>());
export const SetCheckVerifyCount = createAction('[UtilState] Set Check Verify Count', props<{ checkVerifyCount: boolean }>());
export const SetSbaProductConfig = createAction('[UtilState] Set SBA Product Config', props<{ configurations: any }>());



