<ng-container *ngIf="group" [formGroup]="group">
    <ng-container *ngFor="let field of fields; let i = index">
        <!-- Showing the fields if show-field key is present in the form -->
        <ng-container *ngIf="field.visible; else showField">
            <!-- Checking if the value is an array then iterating that value-->
            <!-- <div *ngIf = "checkArray(field.show_field.value); else stringshowField">
                 <div *ngFor = "let value_show of field.show_field.value"> -->
            <!-- {{field.visible | json}} -->
            <div class="form-group" *ngIf="checkValue(group,field)"
            [attr.id]="field.name"
            [ngClass]="field?.css && field?.css?.parent ? field?.css?.parent[field.name]||field?.css?.parent:'form-group'">
                <ng-container rubiconField [index]="index" [parent]="parent" [field]="field" [group]="group" [slug]="slug">
                </ng-container>
            </div>
            <div *ngIf="field.css && field.css.condition" class="clearfix w-100"></div>
            <!-- </div>    
            </div> -->
        </ng-container>

        <!-- If there is no show field in the form-->
        <ng-template #showField>
            <!-- {{ field.css.condition | json}} -->

            <div *ngIf="field.css && field.css.condition" class="clearfix w-100">
                <!-- {{field.name}} -->
            </div>
            <div class="form-group"
                [attr.id]="field.name+'_rubiconField'"
                [ngClass]="field?.css && field?.css?.parent ? field?.css?.parent[field.name]||field?.css?.parent:'form-group'">
                <ng-container rubiconField [index]="index" [ngForIndex]="i" [parent]="parent" [field]="field" [group]="group" [slug]="slug">
                </ng-container>
            </div>
        </ng-template>
    </ng-container>
</ng-container>