<ng-container [formGroup]="group">
    <ng-container *ngIf="!field.multiple;else multipleCase">
        <div [ngClass]="field?.css.heading? field?.css?.heading:''" *ngIf="field?.css?.heading" [innerHTML]='slug + ".label." + field.name +".title" | translate'>
            <ng-template #popContentTitle2>
                <div class="fs12 fwsb">Instructions</div>
            </ng-template>

            <ng-template #popContent2>
                <div [innerHTML]="slug + '.label.' + field.name+'_tooltip' | translate"></div>
            </ng-template>

            <button type="button" class="infoTip border-0" ngbdata-toggle="popover" ngbdata-html="true"
                ngbdata-placement="right" [ngbPopover]="popContent2" triggers="mouseenter:mouseleave"
                [popoverTitle]="popContentTitle2" style="background: transparent;">
                <img  class="infoimg" src="{{environment?.asset_path}}images/info-icon-n.svg" alt="">
            </button>

        </div>
        <div [ngClass]="field?.css?.wrapper? field.css.wrapper:''">
            <rubicon-dynamic class="row norowsba" [fields]="field.group_fields" [group]="group.get(this.field.name)" [slug]="slug">
            </rubicon-dynamic>
        </div>

    </ng-container>
    <ng-template #multipleCase>
        <div [ngClass]="field?.css.heading? field.css.heading:''" *ngIf="field?.css?.heading" [innerHTML]='slug + ".label." + field.name +".title" | translate'>
            <ng-template #popContentTitle2>
                <div class="fs12 fwsb">Instructions</div>
            </ng-template>

            <ng-template #popContent2>
                <div [innerHTML]="slug + '.label.' + field.name+'_tooltip' | translate"></div>
            </ng-template>

            <button type="button" class="infoTip border-0" ngbdata-toggle="popover" ngbdata-html="true"
                ngbdata-placement="right" [ngbPopover]="popContent2" triggers="mouseenter:mouseleave"
                [popoverTitle]="popContentTitle2" style="background: transparent;">
                <img src="{{environment?.asset_path}}images/info-icon-n.svg" alt=""  class="infoimg">
            </button>
            <div class='fs10 fwm' [innerHTML]="slug + '.label.' + field.name +'.help_text'| translate">
            </div>

        </div>
        <ng-container [formArrayName]="field.name" *ngFor="let item of control; let i = index;">
            <ng-container [formGroupName]="i">
                <div class="{{slug + field.name}}" [ngClass]="field?.css?.wrapper? field.css.wrapper:''">
                    <rubicon-dynamic [fields]="field.group_fields" [ngClass]="field?.css?.field? field.css.field:'row norowsba'"
                        [group]="item" [slug]="slug" [index]="i">
                    </rubicon-dynamic>
                    <div [ngClass]="field?.css?.deletewrap? field.css.deletewrap:'d-flex justify-content-end'">
                    <div *ngIf="group.status!=='DISABLED' && (control.length>1 && field?.config?.remove !== false)"
                        (click)="removeForm(i, control.length)" [ngClass]="field?.css?.deleteBtn ? field?.css?.deleteBtn: 'deleteIcon'">
                        {{slug + ".label." + field.name +".remove" | translate}}
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>
        <div *ngIf="totalType === 'topAlign'"  class="col-12 deletePfs">
            <div [ngClass]="field?.css.totalWrapper? field.css.totalWrapper:''">
                        <div *ngFor="let item of field.total; let i = index" [ngClass]="field.css['totalFieldColClass_' + i]? field.css['totalFieldColClass_' + i]:''">
                            <div class="row">
                            <div [ngClass]="field.css['totalLabelClass_' + i]? field.css['totalLabelClass_' + i]:''"><div class="pt-2 mt-1">Total</div></div>
                            <div [ngClass]="field.css['totalFieldwrapClass_' + i]? field.css['totalFieldwrapClass_' + i]:''"><input type="text" value="$ {{total(item)}}" class="form-control" [ngClass]="field.css['totalFieldClass_' + i]? field.css['totalFieldClass_' + i]:''" disabled></div>
                        </div>
                    </div>
            </div>
        </div>
        <div [ngClass]="field?.css?.addMore? field?.css?.addMore:'addMoreBtn w-100 mt-1'" *ngIf="group.status!=='DISABLED' && ((control.length<field.max_limit && saturationReached() && !field?.config?.hideBtn) || (!field.max_limit && !field?.config?.hideBtn))">
            <div class="text-right">
                <button (click)="addMore()" [ngClass]="field?.css?.addButton? field?.css?.addButton:''" type="button"
                    class="btn btn-primary"><em class="addIcon"></em>{{slug + '.label.' + field.name + '.button' | translate}}</button>
            </div>
        </div>

        <div class="totalRow row" *ngIf="totalType=== 'cfa'" [ngClass]="field?.css.totalWrapper? field?.css.totalWrapper:''">
            <div *ngFor="let item of field.total; let i = index" class="form-group col-12" [ngClass]="field?.css['totalFieldColClass_' + i]? field.css['totalFieldColClass_' + i]:''">
                <div class="row aic" [ngClass]="field?.css['totalFieldwrapClass_' + i]? field?.css['totalFieldwrapClass_' + i]:''">
                    <label *ngIf="field.name === 'adjustments'" class="p-label col-7 fwb">Total Adjustments</label>
                    <label *ngIf="field.name === 'new_purpose_debt_l'" class="p-label col-7 fwb">Total NPD</label>
                    <label *ngIf="field.name === 'sensitised_proposed_debt_l'" class="p-label col-7 fwb">Total Sensitized Proposed Debt</label>
                    <label *ngIf="field.name === 'other_debt_l'" class="p-label col-7 fwb">Total Other Debt</label>
                    <label *ngIf="field.name === 'existing_debt'" class="p-label col-7 fwb">Total Existing Debt</label>
                    <div class="col-5 col-lg-5">
                        <input type="text" value="$ {{total(item)}}" class="form-control" [ngClass]="field?.css['totalFieldClass_' + i]? field.css['totalFieldClass_' + i]:''" disabled>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="totalType === 'bottomAlign'" [ngClass]="field?.css?.totalParent ? field?.css?.totalParent : 'loanBlockRow gbg'">
            <div class="row" *ngFor="let item of field.total; let i = index">
                <div class="col-12 col-lg-6">
                    <div [ngClass]="field?.css?.totalLabelClass ? field?.css.totalLabelClass : ''" class="">Total Amount</div>
                </div>
                <div class="col-12 col-lg-5">
                    <div class="d-inline">
                        <input type="text" value="$ {{total(item)}}" [ngClass]="field?.css?.totalFieldClass ? field?.css?.totalFieldClass:'form-control'" disabled>
                    </div>
                </div>
            </div>
        </div>

    </ng-template>

</ng-container>
<!-- Creating a modal for popping up on clicking remove -->
<div class="modal fade" [id]="field.name+'-remove'">
    <div class="modal-dialog">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">{{slug+".popup."+field.name+".title" | translate}}</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                {{slug+".popup."+field.name+".message" | translate}}
            </div>

            <!-- Modal footer -->
            <div class="modal-footer">
                <button *ngIf="group.status!=='DISABLED'" type="button" class="btn btn-primary btn-sm" data-dismiss="modal"
                    (click)="removeForm(index_field, length)">{{slug+".popup."+field.name+".ok" | translate}}</button>
                <button type="button" class="btn btn-secondary btn-sm"
                    data-dismiss="modal">{{slug+".popup."+field.name+".cancel" | translate}}</button>
            </div>

        </div>
    </div>
</div>