import { Injectable, ComponentFactoryResolver, ApplicationRef, Injector, EmbeddedViewRef, Inject } from "@angular/core";
import { CommonService } from "@rubicon/utils";
import { KnockoutModalActionComponent } from "../component/knockout-modal-action/knockout-modal-action.component";

@Injectable({
    providedIn: 'root'
})
export class KnockoutModalActionService {
    modalArgumentMapping = {}
    constructor(private componentFactoryResolver: ComponentFactoryResolver,
        private appRef: ApplicationRef, private injector: Injector,
        @Inject('environment' ) public environment,
        public common: CommonService) {
    }

    openModal(params, resolverOb, knockoutFilter, knockoutOn, actionCompleted$, knockoutConfig) {
        let modalId = knockoutFilter.value;
        if(knockoutOn == "valueChanges"){
            if(Object.keys(this.modalArgumentMapping)?.length){
                return false;
            }
        }
        if (this.modalArgumentMapping[modalId]) {
            return false;
        }
        else {
            this.modalArgumentMapping[modalId] = {};
        }
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(KnockoutModalActionComponent);
        const componentRef = componentFactory.create(this.injector);
        this.appRef.attachView(componentRef.hostView);
        const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
        this.modalArgumentMapping[modalId].modalComp = componentRef;
        this.modalArgumentMapping[modalId].actionComplete$ = actionCompleted$;
        this.modalArgumentMapping[modalId].resolverOb = resolverOb;
        this.modalArgumentMapping[modalId].params = params;
        this.modalArgumentMapping[modalId].knockoutConfig = knockoutConfig;
        componentRef.instance.knockoutFilter = knockoutFilter;
        componentRef.instance.knockoutOn = knockoutOn;
        componentRef.instance.modalClosed$.subscribe(() => {
            this.appRef.detachView(componentRef.hostView);
            componentRef.destroy();
            delete this.modalArgumentMapping[modalId];
        });
        document.body.appendChild(domElem);
        return true;
    }

    openDeclineConfirmationModal(knockoutFilter) {
        let modalId = knockoutFilter.value;
        let modalCompRef = this.modalArgumentMapping[modalId].modalComp;
        modalCompRef.instance.openDeclineConfirmationModal();
    }

    closeModal(knockoutFilter) {
        let modalId = knockoutFilter.value;
        let modalCompRef = this.modalArgumentMapping[modalId].modalComp;
        modalCompRef.instance.closeModal();
    }

    closeDeclineConfirmModal(knockoutFilter) {
        let modalId = knockoutFilter.value;
        let modalCompRef = this.modalArgumentMapping[modalId].modalComp;
        modalCompRef.instance.closeDeclineConfirmModal();
    }

    resolveButtonClick(button_id, knockoutFilter) {
        let modalId = knockoutFilter.value;
        let params = this.modalArgumentMapping[modalId].params;
        let resolverOb = this.modalArgumentMapping[modalId].resolverOb;
        let actionComplete$ = this.modalArgumentMapping[modalId].actionComplete$;
        let dataSaveApi$ = this.modalArgumentMapping[modalId].dataSaveApi;
        switch (button_id) {
            case "cancel":
                this.closeModal(knockoutFilter);
                actionComplete$.next({ button_id, continue: false, cancel: true, decline: false });
                actionComplete$.complete();
                break;
            case "proceedWithFlag":
                // this.updateKnockoutFlags(params, resolverOb, knockoutFilter).subscribe((action_data) => {
                    this.closeModal(knockoutFilter);
                    actionComplete$.next({ button_id, continue: true });
                    actionComplete$.complete();
                // });
                break;
            case "confirmBeforeDecline":
                this.openDeclineConfirmationModal(knockoutFilter);
                break;
            case "confirm_cancel":
                this.closeDeclineConfirmModal(knockoutFilter);
                break;
            case "confirm_decline":
                // this.updateKnockoutFlags(params, resolverOb, knockoutFilter, true).subscribe((action_data) => {
                    this.closeDeclineConfirmModal(knockoutFilter);
                    this.closeModal(knockoutFilter);
                    actionComplete$.next({ button_id, decline: true });
                    actionComplete$.complete();
                // });
                break;
            case "declineWithoutConfirm":
                // this.updateKnockoutFlags(params, resolverOb, knockoutFilter, true).subscribe((action_data) => {
                    this.closeModal(knockoutFilter);
                    actionComplete$.next({ button_id, decline: true });
                    actionComplete$.complete();
                // });
                break;
        }
    }


}