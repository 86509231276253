import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DocumentsService {
  filterReqKey = new Subject();
  constructor() {
  }

  docTypeSegregation(stageDocType, ownerData, businessData) {
    let loanPurpose = businessData?.purpose_arr?.map((tup) => {
      return tup.purpose;
    });
    //segregation respective to owners
    let stageDocumentTypes = _.cloneDeep(stageDocType);
    let ownerDocTypes = [];
    let docTypes = [];
    let affiliate_entity = false; //ownerData['ownership_of_20'] === "Yes"; //not checking affiliate as have seperate section only for affiliates
    let franchise_check = ownerData['franchise_check'] === "Yes";
    let perm_resid_alien = ownerData['citizen_type'] === "lawful_resident";
    let primary_business = ownerData["primary_business"];
    let app_biz_legal_name = ownerData["primary_business"] ? (ownerData?.sba?.app_biz_legal_name):(ownerData?.app_biz_legal_name);
    let is_entity = ownerData.owner_type === 'corporate' || ownerData.primary_business;
    let is_individual = ownerData.owner_type === 'individual';
    stageDocumentTypes.forEach((element) => {
      if(is_entity && element.filter_conditions?.bypass_entity_doc){
        docTypes.push(element);
      }
      if(is_individual && element.filter_conditions?.bypass_indiv_doc){
        docTypes.push(element);
      }
      if(element.filter_conditions.non_primary_business && primary_business){
        return false;
      }
      else if(element.filter_conditions.primary_business){
        if(primary_business){
          docTypes.push(element);
        }
      }
      else if(element.filter_conditions.franchise_check){
        if(franchise_check){
          docTypes.push(element);
        }
      }
      else if(element.filter_conditions.perm_resid_alien){
        if(perm_resid_alien){
          docTypes.push(element);
        }
      }
      else if (element?.filter_conditions?.company_role) {
        if (
          element.filter_conditions.company_role === '401k' &&
          ownerData.company_role === '5c91e94d3a2ba0198ce86a39'
        ) {
          docTypes.push(element);
        } else if (
          element.filter_conditions.company_role === 'trust' &&
          ownerData.company_role === '5c91e94d3a2ba0298ce84a39'
        ) {
          docTypes.push(element);
        } else if (
          element.filter_conditions.company_role === 'key_employee' &&
          ownerData.company_role === '5c91e94d3a2ba0289ce71a44'
        ) {
          docTypes.push(element);
        }
      } else {
        loanPurpose.forEach((loan_pur) => {
          if (
            element.filter_conditions?.loan_purpose?.includes(loan_pur) ||
            element?.filter_conditions
              ?.bypass_business_structure_and_loan_purpose === true
          ) {
            docTypes.push(element);
          }
        });
        if (
          element?.filter_conditions
            ?.bypass_business_structure_and_loan_purpose === false &&
          element?.filter_conditions.business_structure?.length !== 0
        ) {
          if (
            element.filter_conditions.business_structure.includes(
              ownerData.business_structure
            )
          ) {
            docTypes.push(element);
          }
        }
      }
    });
    docTypes.forEach((element) => {
      if(is_entity && element.filter_conditions?.bypass_entity_doc){
        ownerDocTypes.push(element);
      }
      if(is_individual && element.filter_conditions?.bypass_indiv_doc){
        ownerDocTypes.push(element);
      }
      if (element.filter_conditions?.bypass_ownership) {
        ownerDocTypes.push(element);
      }
      if(affiliate_entity && element.filter_conditions.affiliate_entity){
        ownerDocTypes.push(element);
      }
      if (
        ownerData.company_role === '5c91e94d3a2ba0289ce71a44' &&
        (+ownerData.ownership) < 20 &&
        (+ownerData.ownership) >= 0
      ) {
        // key employee
        if (element.filter_conditions?.ownership?.includes('0-19')) {
          ownerDocTypes.push(element);
        }
      } else if (
        ownerData.company_role === '5c91e94d3a2ba0289ce68a98' &&
        (+ownerData.ownership) >= 20 &&
        ownerData.owner_type === 'individual'
      ) {
        //owner seller 20+
        if (element.filter_conditions?.seller_owner) {
          ownerDocTypes.push(element);
        }
      } else if (
        ownerData.company_role === '5c91e94d3a2ba0289ce68a98' &&
        ownerData.owner_type === 'corporate'
      ) {
        //entity seller (in OR EPC/OC)
        if (element.filter_conditions?.seller_entity) {
          ownerDocTypes.push(element);
        }
        if (app_biz_legal_name === 'oc') {
          if (element.filter_conditions?.business_type?.includes('oc')) {
            ownerDocTypes.push(element);
          }
        }
        // ownerData?.sba?.app_biz_legal_name === '602f71232410192bf46bae91'
        if (app_biz_legal_name === 'epc') {
          if (element.filter_conditions?.business_type?.includes('epc')) {
            ownerDocTypes.push(element);
          }
        }
      } else if (
        ownerData.company_role !== '5c91e94d3a2ba0289ce71a44' &&
        ownerData.company_role !== '5c91e94d3a2ba0289ce68a98'
      ) {
        if (
          (+ownerData.ownership) >= 20 &&
          ownerData.owner_type === 'individual'
        ) {
          //indi 20+
          if (
            element.filter_conditions?.ownership?.includes('20') &&
            element.filter_conditions?.owner_type?.includes('individual')
          ) {
            ownerDocTypes.push(element);
          }
        }
        if (
          (+ownerData.ownership) < 20 &&
          (+ownerData.ownership) >= 0 &&
          ownerData.owner_type === 'individual'
        ) {
          //indi 0  // ownership less than 20 and greater than 0
          if (
            element.filter_conditions?.ownership?.includes('0') &&
            element.filter_conditions?.owner_type?.includes('individual')
          ) {
            if (
              element.key !== 'Samgov_Search' &&
              element.key !== 'Samgov_Search_Agents' &&
              element.key !== 'Tax_Transcripts'
            )
              ownerDocTypes.push(element);
          }
        }
        if (
          (+ownerData.ownership) < 20 &&
          (+ownerData.ownership) >= 0 &&
          ownerData.owner_type === 'corporate'
        ) {
          // ownership less than 20 and greater than 0
          if (
            element.filter_conditions?.ownership?.includes('0') &&
            element.filter_conditions?.owner_type?.includes('entity')
          ) {
            ownerDocTypes.push(element);
          }
        }
        if ((+ownerData.ownership) >= 20 && ownerData.owner_type === 'corporate') {
          if (
            element.filter_conditions?.ownership?.includes('20') &&
            element.filter_conditions?.owner_type?.includes('entity')
          ) {
            ownerDocTypes.push(element);
          }
        }
        // ownerData?.sba?.app_biz_legal_name === '602f71232410192bf46bae90'
        if (app_biz_legal_name === "oc") {
          if (element.filter_conditions?.business_type?.includes('oc')) {
            ownerDocTypes.push(element);
          }
        }
        // ownerData?.sba?.app_biz_legal_name === '602f71232410192bf46bae91'
        if (app_biz_legal_name === 'epc') {
          if (element.filter_conditions?.business_type?.includes('epc')) {
            ownerDocTypes.push(element);
          }
        }
      } 
      // else if (
      //   ownerData.company_role === '5c91e94d3a2ba0289ce71a44' &&
      //   (+ownerData.ownership) >= 20
      // ) {
      //   //key emp 20+
      //   if (
      //     element.filter_conditions?.ownership?.includes('20') &&
      //     element.filter_conditions?.owner_type?.includes('individual')
      //   ) {
      //     ownerDocTypes.push(element);
      //   }
      // }
    });

    var unique = Array.from(
      new Set(ownerDocTypes.map((i) => JSON.stringify(i)))
    ).map((i) => JSON.parse(i));
    return unique;
  }

  filterPrimaryJourneyDocs(doc_ref_type, documentTypes, businessData, ownerData?) {
    switch (doc_ref_type) {
      // case "business": return documentTypes.filter(item => {
      //   let franchise_check = businessData['franchise_check'] === "Yes";
      //   if (item.filter_conditions ?.bypass_entity_doc) {
      //     return true;
      //   }
      //   if (!item.filter_conditions ?.non_primary_business && item.filter_conditions ?.business_type ?.includes(businessData?.sba?.app_biz_legal_name)) {
      //     if (item.filter_conditions ?.franchise_check) {
      //       return franchise_check;
      //     }
      //     return true;
      //   }
      //   return false;
      // });
      //   break;
      // case "owner":
      //   let franchise_check = businessData['franchise_check'] === "Yes";
      //   let perm_resid_alien = ownerData['citizen_type'] === "lawful_resident";
      //   let affiliate_entity = ownerData['ownership_of_20'] === "Yes";
      //   return documentTypes.filter(item => {
      //     if (item.filter_conditions ?.bypass_indiv_doc) {
      //       return true;
      //     }
      //     if (item.filter_conditions ?.perm_resid_alien_default) {
      //       return perm_resid_alien;
      //     }
      //     if (item.filter_conditions ?.owner_type ?.includes("individual")) {
      //       if (item.filter_conditions ?.perm_resid_alien || item.filter_conditions ?.franchise_check || item.filter_conditions ?.affiliate_entity) {
      //         if (item.filter_conditions ?.perm_resid_alien && perm_resid_alien) {
      //           return true;
      //         }
      //         if (item.filter_conditions ?.franchise_check && franchise_check) {
      //           return true;
      //         }
      //         if (item.filter_conditions ?.affiliate_entity && affiliate_entity) {
      //           return true;
      //         }
      //         return false;
      //       }
      //       return true;
      //     }
      //     return false;
      //   });
      //   break;
      case "affiliate":
        return documentTypes.filter(item => {
          if (item.filter_conditions?.affiliate_entity) {
            return true;
          }
        });
        break;
    }
    return [];
  }

  filterAdditionalAffDocs(documentTypes, ownerData, businessData? ){
    return documentTypes.filter(item => {
      if(item.filter_conditions?.affiliate_entity){
        return true;
      }
    });
  }

  getData(stage, journey, business, owner, collaterals, category_type?) {
    let use_of_proceeds_total = business?.purpose_arr ? business?.purpose_arr.reduce((sum : any,key: any)=> sum+parseInt(key.amount_field)||0,0) : 0;
    let obj:any = {
      stage_key: stage,
      document_category: category_type || 'all',
      journey_type: journey,
      loan_amount: use_of_proceeds_total,
      collateral: collaterals ? collaterals : [],
      loan_purpose: business?.purpose_arr?.map(el => { return el.purpose }) || [],
      business_structure: owner.business_structure ? owner.business_structure : business.business_structure,
      owner_type: owner.is_business ? 'corporate' : owner.owner_type,
      business_name: owner.is_business ? business.sba?.app_biz_legal_name : (owner.app_biz_legal_name || ''),
      ownership: owner.is_business ? 0 : owner.ownership,
      company_role: owner.company_role || '',
      seller: owner.is_seller || false,
      veteran:owner.is_business ? '' : (owner.veteran?.toString() || owner?.text_1?.veteran?.toString()|| owner?.form_1919?.gua_venteran?.toString() || "")
    };
    if (!obj?.ownership && (obj?.company_role === '5c91e94d3a2ba0289ce68a94') && (obj?.owner_type === "corporate") && (!obj.business_name || obj.business_name === '')) { // coborrower
      obj.ownership = 0;
    }
    if (owner.is_business) {
      obj.primary_business = true;
    } else {
      obj.primary_business = false;
    }
    return obj;
  }
}
