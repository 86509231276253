import { Component, OnInit, Inject, Input, TemplateRef } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { logout } from '../+state/ui-kit.action';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { addUserDetails, addAppID, addBusinessID, addNextTask, FeatureAccessService,  CommonService } from '@rubicon/utils_custom';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
declare let $

@Component({
  selector: 'rubicon-verifier-header',
  templateUrl: './verifier-header.component.html',
  styleUrls: ['./verifier-header.component.scss']
})
export class VerifierHeaderComponent implements OnInit {

  isLoggedIn: boolean;
  manageUser: boolean;
  hideUpgrade: boolean;
  modalRef: BsModalRef;
  forgiveness_journey_url:string;
  affiliate_journey_url:string;
  @Input('questionLabel') questionLabel;
  @Input('showLoginBtn') showLoginBtn;
  @Input('hideSupportMailAndQuestion') hideMailAndQuestion;
  videoModalRef: BsModalRef;
  videoActiveURL:string;
  registration_flag=false;
  planType = false;
  plan = 'none';
  supportMail='cpasupport@biz2credit.com';
  isCommissionRoute = false;
  constructor(private store: Store<any>, private router: Router, @Inject('environment') private environment,
  private activeRoute: ActivatedRoute, private modalService: BsModalService,private FeatureService: FeatureAccessService,
  private commonService: CommonService) { 
    this.manageUser = this.environment.user_management;
    this.forgiveness_journey_url =  this.environment.forgiveness_journey_url;
    this.affiliate_journey_url = this.environment.affiliate_journey_url;
  }

  ngOnInit(): void {
    
    this.plan = this.FeatureService.getPlan()?this.FeatureService.getPlan().type:'none';
    if(this.plan == "enterprise" || this.plan === 'custom'){
      this.planType = true;
    }
    if(this.planType||this.plan==='premium'){
      this.supportMail='cpasupport-premier@biz2credit.com';
    }
    this.FeatureService.planType.subscribe((data:any)=>{
      if(data.type == "enterprise"){
      this.planType = true;
    }
    if(this.planType||data.type ==='premium'){
      this.supportMail='cpasupport-premier@biz2credit.com';
    }
    })
    if((this.router.url+'').indexOf('plans') !== -1) {
      this.hideUpgrade = true;  
    }
    if((this.router.url+'').indexOf('terms-of-use') > -1||(this.router.url+'').indexOf('application-status') > -1) {
      this.hideUpgrade = true;
      this.showLoginBtn=true;
      this.questionLabel=true;
    }

    this.isCommissionRoute = false;
    if(this.router.url && this.router.url.indexOf('commission-report') !== -1) {
      this.isCommissionRoute = true;
    }
    
    this.router.events.forEach((event: any) => {
      if(event instanceof NavigationStart) {
        if((event.url+'').indexOf('plans') !== -1) {
          this.hideUpgrade = true;
        }
        else if((event.url+'').indexOf('terms-of-use') > -1) {
          this.hideUpgrade = true;
          this.questionLabel=true;
          this.showLoginBtn=true;
        }
         else {
          this.hideUpgrade = false;
        }

      }
      this.isCommissionRoute = false;
      if(event.url && event.url.indexOf('commission-report') !== -1) {
        this.isCommissionRoute = true;
      }
    });

    this.store.
      pipe(
        select('app'),
        take(1)
      ).subscribe(rootState => {
        if(rootState) {
          if(rootState && rootState.backendUserData && rootState.backendUserData.id&&!this.showLoginBtn) {
            this.isLoggedIn = true;
          }
          if (rootState.nextTask) {
            // this.businessFacade.dispatch(new LoadBusinessDetails(this.slug));
          }
          if(rootState && rootState.backendUserData && rootState.backendUserData.user && rootState.backendUserData.user.registration_type){
            if(rootState.backendUserData.user.registration_type == "normal"){
                   this.registration_flag = true;
              } 
          } 
        }
      });

      this.modalService.onShown.subscribe(() => {
        $("#faq").owlCarousel({
          loop: false,
          items: 2,
          margin: 10,
          navigation: false,
          responsiveClass: true,
          autoHeight: false,
          autoHeightClass: 'owl-height',
          dots: false,
          autoplay:false,
          rewind:false,
          mouseDrag: false,
          responsive: {
              0: {  
                  nav: true,
                  items: 1,
              },
              480: {
                  nav: true,
                  items: 1,
              },
              1024: {
                  nav: true,
                  autoHeight: true,
              }
          }
        });
      })
  }
  

  onClick(type) {
    switch (type) {
      case 'logout':
        this.store.dispatch(logout({}));
        setTimeout(() => {
          this.router.navigate(['']);
        }, 0);
        break;
      default:
        setTimeout(() => {
          this.router.navigate(['/'+ type]);
        }, 0);
        break;
    }
  }
  
  openModalWithClass(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'gray modal-lg' })
    );
  }

  openVideomodal(template: TemplateRef<any>, url) {
    this.videoActiveURL = url + '?autoplay=1&amp;modestbranding=1&amp;showinfo=0';
    this.videoModalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'noheader modal-lg' })
    );
  }
}
