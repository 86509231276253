<ng-container [formGroup]="group">

    <ng-container *ngIf="field?.css?.floatingLabel else normal">

        <div [ngClass]="(field?.css?.labelParent)? field?.css?.labelParent :'floating-label'">

            <input type="{{password_input_type}}" placeholder="&nbsp;" autocomplete="new-password"
                [ngClass]="(field?.css?.field)? field?.css?.field :'form-control'" [formControlName]="field.name"
                [id]="field.name + '_' + indexVal" [mask]="field.format?field.format.type:null"
                [thousandSeparator]="field.format&&field.format.delimiter?field.format.delimiter:''"
                [maxlength]="(field?.format?.maxlength)?field.format.maxlength:null"
                >

            <span class="highlight"></span>
            <label [ngClass]="(field?.css?.labelClass)? field?.css?.labelClass :'labelText'"
                [for]="field.name + '_' + indexVal">{{slug + ".label." + field.name | translate}}
            </label>
            <i [ngClass]="isPasswordVisible ? 'eye' : 'eye eyeclose'"
                (click)="showPassword(isPasswordVisible)">&nbsp;</i>
            <div *ngIf="group.get(field.name).invalid && (group.get(field.name).dirty || group.get(field.name).touched)"
                class="error-msg">
                <ng-container *ngFor="let validation of field.validations;">
                    <label class="error" *ngIf="group.get(field.name).hasError(validation.name)">
                        {{slug + ".errorMessage." + field.name + "." + validation.name | translate}}
                    </label>
                </ng-container>
                <label class="error" *ngIf="group.get(field.name).hasError('incorrect')">
                    {{slug + ".errorMessage." + field.name + ".incorrect" | translate}}
                </label>
            </div>
        </div>

    </ng-container>

    <ng-template #normal>
        <label *ngIf="slug + '.label.' + field.name | translate"
            [ngClass]="(field?.css?.labelClass)? field?.css?.labelClass :'p-label'" [for]="field.name + '_' + indexVal">
            {{slug + ".label." + field.name | translate}}
        </label>
        <input type="{{password_input_type}}" 
            [placeholder]="slug + '.placeholder.' + field.name | translate"
            class="form-control" 
            [formControlName]="field.name" 
            [id]="field.name + '_' + indexVal"
            [maxlength]="(field?.format?.maxlength)?field.format.maxlength:null"
        >
        <i [ngClass]="isPasswordVisible ? 'eye' : 'eye eyeclose'" (click)="showPassword(isPasswordVisible)">&nbsp;</i>
        <div *ngIf="group.get(field.name).invalid && (group.get(field.name).dirty || group.get(field.name).touched)"
            class="error-msg">
            <ng-container *ngFor="let validation of field.validations;">
                <label class="error" *ngIf="group.get(field.name).hasError(validation.name)">
                    {{slug + ".errorMessage." + field.name + "." + validation.name | translate}}
                </label>
            </ng-container>
        </div>
        <div class="passpolicy pt-3" #passpolicy [innerHTML]="slug+'.policy.'+field.name|translate">
        </div>
    </ng-template>


</ng-container>