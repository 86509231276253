import { Injectable, Inject } from "@angular/core";
import { TaskInfoService } from "@rubicon/utils";
import { AccessValidatorService, conditionHelperService } from "../../../../../core-utils/biz2xPro-config-engine";
import * as _ from 'lodash';
import { forkJoin, of, Subject } from "rxjs";
import { switchMap } from "rxjs/operators";
import { DateTimezonePipe } from '@rubicon/libraries/core-utils/shared-lazy';
import { TranslateService } from "@ngx-translate/core";


@Injectable({
    providedIn: "root"
})
export class AssignmentService {

    constructor(
      @Inject('CONSTANTS') public CONSTANTS,
      private taskInfoService: TaskInfoService,
      private accessValidatorService: AccessValidatorService,
      private dateTimeZone: DateTimezonePipe,
      private translate: TranslateService
    ){
    }

    changeBanker(obj, bankerData?) {
      const {applicationData, currentWorkflowAction, stageIdForChangeBanker, selectListData, actionForm, envData, mailTempCommonData, allStages, stageIdOrderMap} = obj;
      const is_current_stage_banker_change = stageIdForChangeBanker == applicationData?.stage_id;
      const action_allowed = this.accessValidatorService.checkAccessValidate(applicationData, currentWorkflowAction?.config?.validate_type , currentWorkflowAction);
      if (action_allowed && currentWorkflowAction?.type == "change_banker") {
        if(!bankerData){
          if (!actionForm.value.assign_to) { return; }
        }
        let assignedToUserData;
        if(bankerData){
          assignedToUserData = bankerData;
        }else{
          assignedToUserData = selectListData.find((user) => user.id === actionForm.value.assign_to);
        }
        if (!assignedToUserData) { return; }
        let next_stage_id;
        if (applicationData.stage_id) {
          let currentStageActionConfig = is_current_stage_banker_change ?currentWorkflowAction?.config?.stages?.[applicationData?.stage_id]:null; //action config required only when app current stage banker is changing
          let extra_assignment_data:any = {};
  
          if(currentStageActionConfig) {
            currentStageActionConfig = _.cloneDeep(currentStageActionConfig);
            if(currentStageActionConfig?.conditional_resolve_params){
              for (let i = 0; i < currentStageActionConfig.conditional_resolve_params.length; i++) {
                let condition_data = currentStageActionConfig.conditional_resolve_params[i];
                let result = conditionHelperService.resolve(applicationData, condition_data.conditions);
                if (result.resolved) {
                  currentStageActionConfig = {...currentStageActionConfig, ...condition_data.params};
                }
              }
            }
          }
          if(currentStageActionConfig && is_current_stage_banker_change){
            if(currentStageActionConfig?.next_stage_id){
              next_stage_id = currentStageActionConfig?.next_stage_id;
              extra_assignment_data.stage_id = currentStageActionConfig?.next_stage_id;
              extra_assignment_data.stage = currentStageActionConfig?.next_stage_id;
            }
            if(currentStageActionConfig?.status_update_on_no_assignment || currentStageActionConfig?.sub_status_update_on_no_assignment){
              let isAnyAssignment = false;
              for(let assign of (applicationData.app_assignment || [])){
                if(applicationData?.stage_id === assign.stage && assign.assigned_to){
                  isAnyAssignment = true;
                }
              }
              if(!isAnyAssignment){
                if(currentStageActionConfig?.status_update_on_no_assignment){
                  extra_assignment_data.status_id = currentStageActionConfig?.status_update_on_no_assignment;
                }
                if(currentStageActionConfig?.sub_status_update_on_no_assignment){
                  extra_assignment_data.sub_status_id = currentStageActionConfig?.sub_status_update_on_no_assignment;
                }
  
              }
  
            }
          }
          /* Unassign last banker when moving in same stage */
          // let assignedStage = (applicationData.app_assignment || []).find((assign: any) => next_stage_id === assign.stage);
          
          let assignData: any = {
            app_id: applicationData.appId || applicationData.app_id,
            role: obj?.backendUserData?.role_slug,
            assigned_to: assignedToUserData.id,
            assignment_date: this.dateTimeZone.transform(new Date(), 'MM-dd-yyyy'),
            role_id: assignedToUserData.role_id,
            // last_assignee_role_id: assignedStage?.role_id,
            backend_user_id: obj?.backendUserData.user_id,
            first_name: assignedToUserData.bankerName ? assignedToUserData.bankerName : assignedToUserData.banker_name,
            email_address: assignedToUserData.email_address,
            case_id: applicationData.loan_id,
            banker_url: envData.banker_url,
            stage: next_stage_id,
            unassign: true,
            mail_client_name: this.CONSTANTS.MAIL_TEMPLATE.project_name,
            ...mailTempCommonData
          };
          if (applicationData?.business_address?.name) {
            assignData.business_name_template = `&nbsp;${applicationData.business_address.name}<br/>`;
          } else {
            assignData.business_name_template = "<p></p>";
          }
          let updateLeadAssignmentParams = {
            slug: this.CONSTANTS.SLUG['lead-assignment'],
            user_id: applicationData.userId
          };
          let updateLeadAssignmentPayload = {
            assigned_to: assignedToUserData.id,
            banker_user_id: obj?.backendUserData.user_id,
            user_id: applicationData.userId
          }
          let taskCall;
          let currentStageOrder = allStages.find(({id}) => id === applicationData.stage_id)?.order;
          if (!applicationData?.lead_details?.assigned_to && (currentStageOrder === 1 || currentStageOrder === 0)) {
            taskCall = this.taskInfoService.saveTaskInfo(updateLeadAssignmentParams, updateLeadAssignmentPayload);
          } else {
            taskCall = of(false);
          }
          return taskCall.pipe(
            switchMap((res) => {
              let paramsData = {
                slug: this.CONSTANTS.SLUG['role-stage-mapping'],
                role_id: assignedToUserData.role_id
              }
              return (currentStageActionConfig?.next_stage_id) ? of(false) : this.taskInfoService.getTaskInfo(paramsData)
            }),
            switchMap((response: any) => {
              if(response) {
                let assigned_role_mapping = response.response_data?.role_stage_mapping?.data?.data;
                // assigned_role_mapping = Array.from(new Set(assigned_role_mapping.map(e => e?.stage_id))); 
                // assigned_role_mapping?.sort((a: any, b: any) => { return stageIdOrderMap[a] - stageIdOrderMap[b] });
                // let stages_change_banker_datas = [];
                // get banker higher stage according to app assignment
                // let higherStage;
                // let higherStageOrder;
                // let stage_assignment_mapping = {};
                // assigned_role_mapping.forEach(stage_id => {
                //   let stageAssignment = (applicationData.app_assignment || []).find(assign=>assign.stage == stage_id);
                //   if(stageAssignment) {
                //     if (!higherStageOrder || (higherStageOrder < stageIdOrderMap[stage_id])) {
                //       higherStageOrder = stageIdOrderMap[stage_id];
                //       higherStage = stage_id;
                //       stage_assignment_mapping[stage_id] = stageAssignment;
                //     }
                //   }
                // })
  
                // get banker higher stage acc. to the current stage
                let stages_change_banker_datas = [];
                let higherStage;
                let stage_assignment_mapping = {};
                assigned_role_mapping.forEach(({stage_id}) => {
                  let stageAssignment = (applicationData.app_assignment || []).find(assign=>assign.stage == stage_id);
                  if(stageIdOrderMap[stage_id] <= stageIdOrderMap[applicationData?.stage_id]) {
                    if (!higherStage || stageIdOrderMap[higherStage] < stageIdOrderMap[stage_id]) {
                      higherStage = stage_id;
                    }
                    stage_assignment_mapping[stage_id] = stageAssignment;
                  }
                });
                const higherStageActionConfig = currentWorkflowAction?.config?.stages?.[higherStage];
                const lower_assignment_role_ids = higherStageActionConfig?.lower_assignment_role_ids;
                if (lower_assignment_role_ids && lower_assignment_role_ids.includes(assignedToUserData.role_id)) {
                  for (const [stage_id, value] of Object.entries(stage_assignment_mapping)) {
                    let stageAssignment : any = _.clone(value);
                    if (stageAssignment?.assigned_to) {
                      let unassign_role_id = stageAssignment?.role_id;
                      let stageAssignData = {...assignData, unassign_role_id, stage: stage_id};
                      currentStageOrder === stageIdOrderMap[stage_id] ? stageAssignData = {...stageAssignData, ...extra_assignment_data} : '';
                      stages_change_banker_datas.push([{ slug: 'change-banker' }, stageAssignData]);
                    }
                  }
                } else {
                  let unassign_role_id = stage_assignment_mapping[higherStage]?.role_id;
                  let stageAssignData = {...assignData, stage: higherStage, unassign_role_id, ...extra_assignment_data};
                  stages_change_banker_datas.push([{ slug: 'change-banker' }, stageAssignData]);
                }
                if(stages_change_banker_datas.length > 0){
                  //only triggering assignment mail single time
                  for(let i=0;i<stages_change_banker_datas.length-1;i++){
                    stages_change_banker_datas[stages_change_banker_datas.length-1][0].no_assignment_mail = true;
                  }
                }
                return forkJoin(stages_change_banker_datas.map((params)=>this.taskInfoService.saveTaskInfo(params[0], params[1])));
              } else {
                return this.taskInfoService.saveTaskInfo({ slug: 'change-banker' }, {...assignData,...extra_assignment_data})
              }
            })
          )
          // .subscribe((res) => {
          //   if (!res.notAssigned && !res.err) {
          //     // success and assigned to assignedToUserData.name
          //     this.common.popToast('success', 'Success', `Case APP ${applicationData.loan_id} has been assigned to ${assignedToUserData.bankerName ? 'self' : assignedToUserData.banker_name} (${assignedToUserData.email_address}).`);
          //     let assign_to_name = assignedToUserData?.bankerName ? assignedToUserData.bankerName + "(" + assignedToUserData?.role_name + ")" : assignedToUserData.banker_name + "(" + assignedToUserData?.role_name + ")";
          //     this.addActivityLog('application_assigned_to', assign_to_name);
          //     this.closeModal.emit(true);
          //     this.manageLoansService.changeAssignTo(true);
          //     return;
          //   }
          //   // error could not assign application
          //   if (!res.err) {
          //     this.common.popToast('error', 'Error', `Case APP ${applicationData.loan_id} could not be assigned to ${assignedToUserData.banker_name === 'Assign to me' ? 'self' : assignedToUserData.banker_name} (${assignedToUserData.email_address}).`);
          //   }
          //   this.closeModal.emit();
          // });
        }
      } else {
        return of({err: true, message: this.translate.instant('loan_actions.toasts.action_not_allowed')})
        // this.closeModal.emit(true);
        // this.common.popToast('error', 'Error', this.translate.instant('loan_actions.toasts.action_not_allowed'));
      }
  
    }


    getMailTempCommonData(env, constant){
      return {
        banker_siteurl: env?.banker_url,
        header_logo_path_1: env?.logo1_path,
        header_logo_path_2: env?.logo1_path,
        client_name: constant?.MAIL_TEMPLATE.project_name,
        senders_name: constant?.MAIL_TEMPLATE.senders_name,
        copyright_text: constant?.MAIL_TEMPLATE.copyright_text,
        terms: env?.terms,
        privacy: env?.privacy
      }
    }

    getAllStagesAndStageIdOrderMap(backend_user_data){
      let allStages =  _.clone(backend_user_data?.workflowInfo?.stages);
      let stageIdOrderMap = {};
      allStages?.forEach((stage: any) => {
        if(stage?.order || stage?.order==0)
        stageIdOrderMap[stage.id] = stage.order
      })
      return {
        allStages, stageIdOrderMap
      }
    }
}