    <ng-container [formGroup]="group">
    <div class="inlineCheck">
        <div *ngIf="(slug + '.label.' + field.name | translate) && !field?.css?.hide_label" class="fs14 fwsb mb-0">
            {{slug + ".label." + field.name | translate}}
        </div>
        <div [formArrayName]="field.name" class="form-check checWrap"
            *ngFor="let val of field.options; let i = index">
            <input class="form-check-input" type="checkbox" (change)="onChange(val.id)" [id]="val.type + '_' + indexVal"
                [checked]="group.get(field.name).value.includes(val.id)" [value]="val.id">
            <label class="form-check-label clt fs12"
                [for]="val.type + '_' + indexVal">{{slug + ".label." + val.type | translate}}</label>
        </div>
    </div>
    <div *ngIf="group.get(field.name).invalid && (group.get(field.name).dirty || group.get(field.name).touched)"
        class="error-msg">
        <ng-container *ngFor="let validation of field.validations;">
            <label class="error" *ngIf="group.get(field.name).hasError(validation.name)">
                {{slug + ".errorMessage." + field.name + "." + validation.name | translate}}
            </label>
        </ng-container>
    </div>
</ng-container>