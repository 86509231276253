<div class="container-fluid mainmenu border-0" [ngClass]="isTabView ? 'px-0 no-bg' : ''">
    <div class="row">
        <div class="col-12">
            <div class="container"  *ngIf="!isTabView">
                <nav class="navbar navbar-expand-md navbar-light">
                    <div class=" navbar-collapse" id="navbarSupportedContent1">
                        <ul class="navbar-nav mr-auto">
                            <li class="nav-item" [routerLinkActive]="'active'" style="cursor: pointer;"><a class="nav-link active" (click)="dashboardClicked()"><img src="/assets/base/images/dashboard.svg" class="pr-2" alt="Mobile" />My Applications</a></li>
                            <!-- <li class="nav-item ml-4" [routerLinkActive]="'active'"><a class="nav-link" [routerLink]="['/my-clients']"><img src="../assets/images/client.svg" class="pr-2" alt="Mobile" />My Clients</a></li> -->
                        </ul>
                        <div class="pt-md-0 pt-2 dashRightBtn">
                            <ng-container *ngIf="createUser && currentState !== 'My Clients'"></ng-container> 
                        </div>   
                        <div class="cpa-purpalBox ml-lg-3" *ngIf="cpaClient() && !createUser">
                            <div class="cpa-purpalBox-inner">
                                <p class="m-0"><a class="wtc" href={{cpa_com}} target="_blank">CPA.com</a> and Biz2Credit have partnered to provide access to business financing solutions for CPA firms and their clients.</p>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
            <!--IF isTabView -->
            <nav class="navbar navbar-expand-md navbar-light" *ngIf="isTabView">  
                <div class="navbar-collapse appClint" id="navbarSupportedContent1">
                    <ul class="navbar-nav mr-auto">
                        <li class="nav-item" [routerLinkActive]="'active'">
                            <a class="nav-link active" [routerLink]="['/dashboard']" (click)="dashboardClicked()">
                                Applications
                            </a>
                        </li>
                        <li class="nav-item" [routerLinkActive]="'active'">
                            <a class="nav-link" [routerLink]="['/my-clients']">
                                Clients (NEW)
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>  
            <!--IF isTabView -->
        </div>
    </div>
</div>
