export const environment = {
  production: false,
  // orchUrl: '//localhost:3007/',
  orchUrl: '//orchestrator-sbaloan-dev.b2cdev.com/',
  region: 'usa',
  appLang: 'en',
  isdCode: '+1',
  secure: false,
  ownershipPerLimit: 100,
  skipOtp: true,
  google_drive: {
    client_id: '979373805330-r52plpig5io3no03qtng6d1bd6g61ted.apps.googleusercontent.com',
    secret_key: 'LmWHJrLCfsT6DfLwgsJMJQ1R',
    app_id: '979373805330'
  },
  client: 'ABC Bank',
  cdnURL: 'https://d21f7feagphy64.cloudfront.net/demo/',
  isNTBEnabled: true,
  plaid_public_key: 'efb1ebcc0b1c9218471a67c57b45ca',
  widgetCDN: "https://d21f7feagphy64.cloudfront.net/servicewidgets/widget-hybrid.service.min.js",
  timezone: 'EDT'
};
