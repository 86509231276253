export const APP_ROUTES = {
    'signin-otp-verification': 'otp-verification-login',
    'otp-verification': 'otp-verification',
    login: 'login',
    'lending-needs': 'business/lending',
    'business-details': 'business/details',
    'business-financial': 'business/finance',
    'applicant-details': 'business/applicant',
    'change-password': 'change-password',
    collateral: 'business/collateral',
    'user-profile': 'profile',
    review: 'review',
    'submit-application': 'submit-application',
    dashboard: 'dashboard/home',
    listing: 'dashboard/app-listing',
    analyzer: 'dashboard/biz-analyzer',
    'doc-vault': 'dashboard/doc-vault',
    faq: 'dashboard/faq',
    contactus: 'dashboard/contactus'
}

export const APP_STEP = {
    'lending-needs': 1,
    'business-details': 2,
    'business-financial': 3,
    'applicant-details': 4,
    collateral: 5
}