import { Component, OnInit, Output, Input, EventEmitter, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { FeatureAccessService } from '@rubicon/utils_custom';

@Component({
  selector: 'rubicon-verifier-header-inner',
  templateUrl: './verifier-header-inner.component.html', 
  styleUrls: ['./verifier-header-inner.component.scss']
})
export class VerifierHeaderInnerComponent implements OnInit {
  @Input() non_verified_count;
  @Input() template;
  @Output() clickEvent: EventEmitter<any> = new EventEmitter();
  @Output('dashboardClicked') dashboardClickedEvent: EventEmitter<any> = new EventEmitter();
  @Input() createUser;
  @Input() currentState;
  isBulKUploadEnable: boolean = false;
  manageUser: boolean;
  planType = false;
  plan = 'none';
  // storeSubscription: any;
  myPlanActive: boolean;
  @Input() isAdmin;
  @Input() user_name;
  constructor( 
    private store: Store<any>,
    @Inject('environment') private environment,
    private featureService: FeatureAccessService
    ) {
      this.isBulKUploadEnable = this.featureService.featureValue('pp1_forgiveness.excel_parse');
      // this.isBulKUploadEnable = environment.CUSTOMER_BULK_UPLOAD;
      // this.manageUser = this.environment.user_management;
      this.manageUser = this.featureService.featureExists('acl.user_count') || this.featureService.featureExists('acl.role_count');
    
    }

  ngOnInit(): void {
    this.plan = this.featureService.getPlan()?this.featureService.getPlan().type:'none';
    if(this.plan == "enterprise"){
      this.planType = true; 
    }
    if(this.plan == "basic"||this.plan == "free"){
       this.manageUser = false; 
    }

    if(this.plan === 'enterprise' || this.plan === 'premium' || this.plan === 'custom') {
      this.myPlanActive = true;
    }
    
    this.featureService.planType.subscribe((data:any)=>{
      if(data.type == "enterprise"){
      this.planType = true;
    }
      if(data.type == "basic"||this.plan == "free"){
       this.manageUser = false; 
      }

      if(data.type === 'enterprise' || data.type === 'premium') {
        this.myPlanActive = true;
      }
    })
    // this.storeSubscription = this.store.
    // pipe(
    //   select('app'),
    //   take(1)
    // ).subscribe(rootState => {
    //   if(rootState) {
    //     if (rootState.nextTask) {
    //     }
    //   }
    // });
  }

  dashboardClicked(){
    this.dashboardClickedEvent.emit();
  }

  openModal() {
    this.clickEvent.emit(this.template);
  }
}
