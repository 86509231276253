import { Injectable } from '@angular/core';

declare let $: any;

@Injectable({
  providedIn: 'root'
})
export class JQueryService {

  constructor() { }

  /**
   * function to toggle tooltip
   * @param toggleItem 
   */
  toggleTooltip(toggleItem) {
    $(toggleItem).tooltip({ sanitize: false, sanitizeFn: content => content });
  }

  /**
   * function to toggle class
   */
  toggleClass(selector: string, className: string) {
    return $(selector).toggleClass(className);
  }

  /**
   * function to get element
   * @param selector 
   */
  getElement(selector: string) {
    return $(selector);
  }

  /**
   * function to add class to a element
   * @param selector 
   * @param className 
   */
  addClass(selector: string, className: string) {
    return $(selector).addClass(className)
  }

  /**
   * function to get parent of a element
   * @param selector 
   */
  getParentElement(selector: string) {
    return $(selector).parent();
  }

  /**
   * function to get next element on basis of class name
   * @param element 
   * @param className 
   */
  getNextElement(element: any, className: string) {
    return element.next(className)
  }

  /**
   * 
   * @param selector 
   * @param className 
   */
  getSiblings(selector: string, className: string) {
    return $(selector).addClass(className).siblings();
  }

  /**
   * function to remove class
   * @param selector 
   * @param className 
   */
  removeClass(selector: string, className: string) {
    $(selector).removeClass(className);
  }

  /**
   * function to set text
   * @param selector 
   * @param text 
   */
  setText(selector: string, text: string) {
    return $(selector).text(text);
  }

  /**
   * function to get text
   */
  getText(selector: string) {
    return $(selector).text();
  }
   /**
   * function to show modal
   */
  showModal(selector: string):void {
    $(selector).modal('show');
  }
   /**
   * function to hide modal
   */
  hideModal(selector: string):void {
    $(selector).modal('hide');
  }
}
