import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DynamicFormModule } from "@rubicon/dynamic-form-custom";
import { UtilsModule } from '@rubicon/utils_custom';
import { ToasterModule } from 'angular2-toaster';
import { NgxMaskModule, MaskPipe } from 'ngx-mask'
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    DynamicFormModule,
    UtilsModule,
    TranslateModule.forChild(),
    ToasterModule.forChild(),
    NgxMaskModule.forChild(),
    TooltipModule,
    BsDropdownModule
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgbModule,
    DynamicFormModule,
    UtilsModule,
    ToasterModule,
    MaskPipe,
    NgxMaskModule,
    BsDropdownModule,
    TooltipModule
  ]
})
export class SharedLazyModule { }
