import { Inject, Injectable } from '@angular/core';
import { MasterDataPipe } from '@rubicon/utils';
import { TaskInfoService } from '@rubicon/utils';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class DocuSignService {
  constructor(
    private masterDataPipe: MasterDataPipe,
    public taskInfoService: TaskInfoService,
    @Inject('environment') public environment
  ) {}

  form1920payload(bankers, url, doc_id, data, signer1, signer2) {
    const receipents = [];
    let i = 0;
    const sign_tabs_pos_final = [];
    let date_tabs_pos_final = [];
    let banker_sorted_list = [];
    if(bankers?.length === 1){
      banker_sorted_list = bankers;
    } else {
      banker_sorted_list[0] = bankers.find(el=>el.id===signer1);
      banker_sorted_list[1] = bankers.find(el=>el.id===signer2);
    }
    banker_sorted_list.forEach(banker => {
      let sign_tabs_pos;
      if (banker_sorted_list?.length == 1) {
        sign_tabs_pos = [
          {
            x_position: 206,
            y_position: 585,
            page_number: 9,
          },
          {
            x_position: 206,
            y_position: 72,
            page_number: 10,
          }
        ];
      }
      else {
        if (i === 0) {
          sign_tabs_pos = [{
            x_position: 206,
            y_position: 585,
            page_number: 9,
          }];
        }
        if (i === 1) {
          sign_tabs_pos = [{
            x_position: 206,
            y_position: 72,
            page_number: 10,
          }];
        }
      }
      receipents.push(
        {
          signer_email: banker.email_address,
          signer_name: banker?.name,
          recipient_id: (i + 1).toString(),
          routing_order: (i + 1).toString(),
          signer_client_id: (i + 1).toString(),
        },
      );
      sign_tabs_pos_final.push(sign_tabs_pos);
      i = i + 1;
    });
    if(banker_sorted_list?.length == 1){
      date_tabs_pos_final = [
        [
          {
            x_position: 417,
            y_position: 604,
            page_number: 9
          },
          {
            x_position: 417,
            y_position: 90,
            page_number: 10
          }
        ],
      ]
     } else {
      date_tabs_pos_final = [
        [
          {
            x_position: 417,
            y_position: 604,
            page_number: 9
          }
        ],
        [
          {
            x_position: 417,
            y_position: 90,
            page_number: 10
          }
        ]
      ]
     }
    const payload = {
      doc_name: `form1920`,
      user_id: data?.app_data?.user_id,
      app_id: data.app_id,
      presigned_url: url,
      business_id: data.business_data?.business_id,
      email_subject: 'Esign SBA Form 1920',
      is_multiple_recipients: true,
      recipients: receipents,
      brand_id: this.environment.brand_id,
      sign_tabs_pos: sign_tabs_pos_final,
      '1920_pdf_id': doc_id,
      type:'esign_sba_1920_doc',
      return_url :`${this.environment?.orchUrl}v2/callback/esign_sba_1920_form?internal=true`,
      web_callback_url: `${this.environment?.orchUrl}v2/callback/esign_sba_1920_form?internal=true`,
      date_tabs_pos: date_tabs_pos_final,
      initials_tabs_pos: [
        [],
        []
      ],
      text_tabs_pos: [
        [],
        []
      ],
      send_mail: true
    };
    return {payload}
  }

  form159payload(signers, file_data, doc_id, data){
    let i = 0;
    const sign_tabs_pos_final =  [
      [
        {
          x_position: 111,
          y_position: 160,
          page_number: 3
        }
      ],
      [
        {
          x_position: 111,
          y_position: 404,
          page_number: 3
        }
      ],
      [
        {
          x_position: 111,
          y_position: 621,
          page_number: 3
        }
      ]
    ]
    const receipents = [];
    signers.forEach(signer => {
      receipents.push({
        signer_email: signer.email_address,
        signer_name: (!signer.owner_type) ? (signer?.name || signer?.first_name) : (`${signer.first_name}${signer.middle_name ? ' ' + signer.middle_name : ''} ${signer.last_name}`),
        recipient_id: (i + 1).toString(),
        routing_order: (i + 1).toString(),
      })
      i = i + 1;
    });
    const payload = {
      doc_name: `form159`,
      user_id: data.app_data.user_id,
      app_id: data.app_id,
      // presigned_url: url,
      business_id: data.business_id,
      file_data: file_data,
      email_subject: 'Esign SBA Form 159',
      is_multiple_recipients: true,
      recipients: receipents,
      brand_id: this.environment.brand_id,
      sign_tabs_pos: sign_tabs_pos_final,
      '159_pdf_id': doc_id,
      type:'esign_sba_159_doc',
      return_url :`${this.environment?.orchUrl}v2/callback/esign_sba_159_form?internal=true`,
      web_callback_url: `${this.environment?.orchUrl}v2/callback/esign_sba_159_form?internal=true`,
      date_tabs_pos: [
        [
          {
              x_position: 348,
              y_position: 177,
              page_number: 3
          }
      ],
      [
          {
              x_position: 348,
              y_position: 423,
              page_number: 3
          }
      ],
      [
          {
              x_position: 348,
              y_position: 638,
              page_number: 3
          }
      ]
      ],
      initials_tabs_pos: [
          [],
          [],
          []
      ],
      text_tabs_pos: [
          [],
          [],
          []
      ],
    };
    return {payload}
  }

  formLandlordPayload(signers, file_data, doc_id, form_data, data){
    const receipents = [
        {
          signer_email: form_data.landlord_email_1,
          signer_name: form_data.landlord_name_1,
          recipient_id: '1',
          routing_order: '1',
        },
      ];
      let i = 1;
      const sign_tabs_pos_final = [
        [
            {
                x_position: 154,
                y_position: 101,
                page_number: 4
            }
        ],
        [
            {
                x_position: 154,
                y_position: 250,
                page_number: 4
            }
        ],
        [
            {
                x_position: 154,
                y_position: 369,
                page_number: 4
            }
        ]
      ];
      signers.forEach(signer => {
        receipents.push({
          signer_email: signer?.email_address,
          signer_name: (!signer.owner_type) ? (signer?.name || signer?.first_name) : (`${signer.first_name}${signer.middle_name ? ' ' + signer.middle_name : ''} ${signer.last_name}`),
          recipient_id: (i + 1).toString(),
          routing_order: (i + 1).toString(),
        })
        i = i + 1;
      });
      const payload = {
        doc_name: `Landlord_waiver`,
        user_id: data.app_data.user_id,
        app_id: data.app_id,
        // presigned_url: url,
        business_id: data.business_id,
        file_data: file_data,
        email_subject: 'Esign SBA Form Landlord waiver',
        is_multiple_recipients: true,
        recipients: receipents,
        brand_id: this.environment.brand_id,
        sign_tabs_pos: sign_tabs_pos_final,
        landlord_pdf_id: doc_id,
        type:'esign_sba_landlord_doc',
        return_url :`${this.environment?.orchUrl}v2/callback/esign_sba_landlord_form?internal=true`,
        web_callback_url: `${this.environment?.orchUrl}v2/callback/esign_sba_landlord_form?internal=true`,
        date_tabs_pos: [
         [],
         [],
         []
        ],
        initials_tabs_pos: [
          [],
          [],
          []
        ],
        text_tabs_pos: [
          [],
          [],
          []
        ],
      };
      return {payload}
  }

  getSignersList(form_name, config){
    const banker_list = {};
    if(config.banker_list=='roles'){
      const params = {
        slug:'get_bankers_list'
    };
    params[form_name+'_esign_status']= 'enabled'
    return this.taskInfoService.getTaskInfo(params).pipe(switchMap(res => {
          const bankers = res?.response_data?.get_banker_by_role?.data?.data;
          const banker_roles = res?.response_data?.get_role_by_slug?.data?.data;
          const data = banker_roles.filter(ele=> ele?.sbaDocEsign && ele?.sbaDocEsign[form_name])

          banker_list['signer_1'] = bankers.filter(ele => ele?.roles?.sbaDocEsign && ele?.roles?.sbaDocEsign[form_name]?.signer_level_1 === 'yes');
          banker_list['signer_2'] = bankers.filter(ele => ele?.roles?.sbaDocEsign && ele?.roles?.sbaDocEsign[form_name]?.signer_level_2 === 'yes');
          banker_list['signer_3'] = bankers.filter(ele => ele?.roles?.sbaDocEsign && ele?.roles?.sbaDocEsign[form_name]?.signer_level_3 === 'yes');
          return of(banker_list);
        }))
      }
    }
}

