import { Component, OnInit, AfterViewInit, OnDestroy, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldInterface } from '../../../interfaces/FormFieldInterafce';
import { CommonService, JQueryService } from '@rubicon/utils';
import { BehaviorSubject, ReplaySubject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'rubicon-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss']
})
export class RadioComponent implements OnInit {

  field: FormFieldInterface;
  group: FormGroup;
  slug: string;
  indexVal: number;
  displayKey:string;
  valueKey:string;
  compDestroyed$ = new ReplaySubject(1);
  constructor(private common: CommonService,
    @Inject("environment") public environment) { }

  ngOnInit() {
    this.indexVal = (new Date().getTime()) * this.getRandomInt();
    this.displayKey = this.field.config && this.field.config.displayKey? this.field.config.displayKey:'value';
    this.valueKey = this.field.config && this.field.config.valueKey? this.field.config.valueKey:'id';
    this.common.masterData.pipe(takeUntil(this.compDestroyed$))
      .subscribe((data: any) => {
        if (data && data[this.field.name]) {
          this.field.options = data[this.field.name].data.data;
          this.checkforResetValue();
        }
      });
    if(this.field?.config?.value) {
      let val = this.group.get(this.field.name).value || this.field.config.value;
      this.group.get(this.field.name).patchValue(val, {emitEvent: false})
    }
    this.group.get(this.field.name).valueChanges.pipe(takeUntil(this.compDestroyed$)).subscribe(data => {
      this.checkforResetValue();
    })
  }

  checkforResetValue() {
    if(this.field?.options?.length) {
      let valueFound = this.field?.options?.find(ele => {
        return this.group.get(this.field.name).value === ele[this.valueKey]
      })
    if(this.group.get(this.field.name).value && !valueFound) {
        this.group.get(this.field.name).reset();
      }
    }
  }

  getRandomInt() {
    return Math.floor(Math.random() * 1000);
  }

  ngOnDestroy() {
    this.compDestroyed$?.next();
    this.compDestroyed$?.complete();
  }
}

