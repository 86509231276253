import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
@Injectable({
    providedIn: 'root'
})

export class WorkFlowManagerService {
    userData: any;
    constructor(
        public store: Store<any>
    ) {
        this.store.select('app').subscribe(rootState => {
            this.userData = rootState?.userData;
        });
    }

    validateAccess(appData, userRoleStageAccess, currentAction?: any) {
        if (!this.userData || appData?.funded_application || appData?.blocked_application) {
            return false;
        }
        let validateCurrentAssignment;
        let actions;
        let stageIdOrderMap: any={};
        let highestStageId;
        // let allStages=this.workflowActionsService.getAllStages();
        let allStages = _.clone(this.userData?.workflowInfo?.stages)

        allStages?.forEach((stage: any) => {
            if(stage?.order || stage?.order==0)
                stageIdOrderMap[stage.id] = stage.order
        })
        let allowedStageIds: any[] = Array.from(new Set(userRoleStageAccess?.map(stage => stage?.stage_id)));
        allowedStageIds?.sort((a: any, b: any) => { return stageIdOrderMap[a] - stageIdOrderMap[b] });
        let currentStageOrder = stageIdOrderMap[appData.stage_id];
        if (currentAction?.config?.last_accessible_stage && !allowedStageIds?.includes(appData.stage_id)) {
            let cceo = stageIdOrderMap[currentAction.config.last_accessible_stage];
            if (currentStageOrder <= cceo) {
                for (const stageId of allowedStageIds) {
                    if (stageIdOrderMap[stageId] <= cceo && currentStageOrder > stageIdOrderMap[stageId])
                        highestStageId = stageId;
                    else
                        break;
                }
                let checkPermission = this.userData?.workflowInfo?.app_status.find(({id}) => id ===appData.status_id)?.config?.permission_for_last_accessible_stage;
                if (checkPermission) {
                    actions = userRoleStageAccess?.filter((item) => item?.stage_id === highestStageId && item?.status_id === appData.status_id );
                } else {
                    actions = userRoleStageAccess?.filter(({ stage_id }) => stage_id === highestStageId);
                }
                actions = actions?.map(({ actions }) => actions)?.flatMap(num => num);
            }
        }
        else {
            actions = userRoleStageAccess?.find((filterStage) => filterStage?.stage_id === appData.stage_id && filterStage?.status_id === appData.status_id)?.actions;
        }

        const filterAction = actions?.find(action => action === currentAction?.id);
        if (currentAction?.config?.check_current_assignment) {
            validateCurrentAssignment = this.userData?.ignore_assignment ? this.validateCurrentAssignment(appData, highestStageId, true) : this.validateCurrentAssignment(appData, highestStageId); 
        }
        if (actions == undefined && filterAction == undefined) {
            if (this.userData?.admin_privilage) return true;
            return false;
        } else {
            if (!actions) return false;
            else if (!filterAction) return false;
            // else if (currentAction?.config?.check_current_assignment && !validateCurrentAssignment) return false;
            else if (currentAction?.config?.check_current_assignment) return validateCurrentAssignment;
            else return true;
        }
    }

    validateStage(appData, userRoleStageAccess) {
        const filterStage = userRoleStageAccess.find(({ stage_id }) => stage_id === appData.stage_id);
        let is_allowed = this.adminPrivilageCheck(filterStage);
        return is_allowed;
    }

    validateStatus(appData, userRoleStageAccess) {
        const filterStatus = userRoleStageAccess.find(({ status_id }) => status_id === appData.status_id);
        let is_allowed = this.adminPrivilageCheck(filterStatus);
        return is_allowed;
    }

    validateAction(appData, userRoleStageAccess, currentAction) {
        const filterAction = userRoleStageAccess.find((filterStage) => filterStage?.stage_id === appData.stage_id && filterStage?.actions?.includes(currentAction?.id));
        let is_allowed = this.adminPrivilageCheck(filterAction);
        return is_allowed;
    }

    validateCurrentAssignment(appData, stageId? : string, checkAssignedTo?:boolean, checkUserId?: string) {
        let currentStageAssignment;
        if (appData?.app_assignment) {
            if (checkUserId) {
                currentStageAssignment = appData?.app_assignment?.find(({assigned_to}) => assigned_to === checkUserId);
            } else {
                let stage_id = stageId ? stageId : appData.stage_id;
                for (let assignment of appData?.app_assignment) {
                    if (assignment.stage === stage_id) {
                        currentStageAssignment = assignment;
                        break;
                    }
                }
            }
        }
        if (checkAssignedTo) return currentStageAssignment?.assigned_to ? true : false;
        if (this.userData?.admin_privilage && currentStageAssignment?.assigned_to) return true;
        if(currentStageAssignment?.assigned_to == this.userData?.user_id) return true;
        return false;
    }

    validateUserRestrict(userData) {
        return userData?.restricted ? false : true;
    }

    validateDefaultVisible(appData, action) {
        let default_visible_config = action?.config?.default_visible_config;
        if (default_visible_config?.restricted_status?.includes(appData?.status_id) || 
            default_visible_config?.restricted_sub_status?.includes(appData?.sub_status_id)) {
                return false;
        }
        return true;
    }

    allowedStage(userRoleStageAccess) {
        let stagesSet = new Set(userRoleStageAccess.map(item => item.stage_id));
        return stagesSet;
    }

    adminPrivilageCheck(value) {
        if ((value == undefined && this.userData?.admin_privilage) || value) {
            return true;
        } else {
            return false;
        }
    }
}