import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldInterface } from '@rubicon/interface/form-field';
import { CommonService } from '@rubicon/utils_custom';
import { Subscription } from 'rxjs';

@Component({
  selector: 'rubicon-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss']
})
export class TextComponent implements OnInit,OnDestroy {

  @Input() field: FormFieldInterface;
  @Input() group: FormGroup;
  @Input() slug: string;
  autoData: any;
  indexVal: number;
  empty_data = []
  show_search_list = false;
  show_no_items_found = false;
  eventSubscription:Subscription;
  constructor(private common: CommonService) { }

  ngOnInit() {
    this.common.sendAddressData(this.empty_data);
    this.common.sendEventCallData(this.field.name, '', this.empty_data);
    this.indexVal = new Date().getTime();
    this.eventSubscription=this.common.eventDataObservable
      .subscribe((data: any) => {
        if (data && Object.keys(data).length > 0) {
          const dataStep = data.data.data;
          if (dataStep && this.field.name === data.data.name) {
            this.field['autoCompleteList'] = dataStep.data.result ? dataStep.data.result : dataStep.data;
            this.autoData = dataStep.data.result ? dataStep.data.result : dataStep.data;
            if(this.field.name==='naics'){
              if(this.field['autoCompleteList']  && this.field['autoCompleteList'].length == 0){
                this.group.get(this.field.name).setErrors({ pattern: true });
                this.group.patchValue({industry:"no-record"});
              }
              else{  
              this.group.get(this.field.name).setErrors({ incorrect: true });
              this.group.patchValue({industry:"incorrect"});
              }
            }
            if(this.field.name==='city'){
              if(data && data.data && data.data.eventGroup) {
                (data.data.eventGroup as FormGroup).get(this.field.name).setErrors({ incorrect: true });
              } 
              else {
                this.group.get(this.field.name).setErrors({ incorrect: true });
              }
            }
          }
        }
      });
  }

  getAddress(index: number) {
    let addressData: any;
    addressData = this.autoData[index].best;
    const companyName = this.autoData[index].best.company_name_info
    this.common.sendAddressData(addressData);
    this.field['autoCompleteList'] = []
    this.autoData = []
  }

  getLender(index: number) {
    let lenderData: any;
    lenderData = this.autoData[index];
    this.common.sendLenderData(lenderData);
    this.field['autoCompleteList'] = []
    this.autoData = []
  }
  getNaicsCode(index: number) {
    let naicsData: any;
    naicsData = this.autoData[index];
    this.group.get(this.field.name).patchValue(naicsData.naics_code + ' - ' + naicsData.value);
    this.group.patchValue({industry:naicsData.value});
    this.field['autoCompleteList'] = []
    this.autoData = []
  }
  getTitle(value){
    this.group.get(this.field.name).patchValue(value);
    this.field['autoCompleteList'] = [];
    this.autoData = [];
  }
  showSearchList(){
    this.show_search_list = true;
  }

  getCity(value){
    this.group.get(this.field.name).patchValue(value);
    this.field['autoCompleteList'] = [];
    this.autoData = [];
  }

  hideSearchList(){
    let control = this.group.get(this.field.name)
    if(typeof control.value === 'string' && (control.valid || control.hasError('pattern'))) {
     control.setValue(control.value.trim())
    }
    setTimeout(()=>{
      this.show_search_list = false;
      this.show_no_items_found = false;
    },500);
  }
  ngOnDestroy(): void {
    this.eventSubscription.unsubscribe();
  }

}
