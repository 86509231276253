import { Component, OnInit, Injectable, ViewChild, ElementRef, AfterViewInit, Inject } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { FormFieldInterface } from '../../../interfaces/FormFieldInterafce';
import { ReplaySubject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
@Component({
  selector: 'rubicon-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
})

export class DateComponent implements OnInit {
  @ViewChild('infoTip') infoTip: ElementRef;
  isFilled;
  field: FormFieldInterface;
  group: FormGroup;
  slug: string;
  date: { year: number, month: number };
  indexVal: Number;
  today = new Date();
  currentMonth = this.today.getMonth();
  currentDate = this.today.getDate();
  currentYear = this.today.getFullYear();
  maxDate: any;
  minDate: any = new Date(1900,0,1);
  compDestroyed$ = new ReplaySubject(1);
  dateFormat = this.CONSTANTS.DATE_FORMAT.default_format || 'MM-DD-YYYY';
  private _isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?Z$/;
  constructor(
    public store: Store<any>,
    @Inject('environment' ) public environment,
    @Inject("CONSTANTS") public CONSTANTS,
  ) {
  }

  ngOnInit() {
    this.store.select('app').pipe(take(1)).subscribe(data => {
      this.dateFormat=data?.configurations[0]?.settings?.dateFormat || this.dateFormat;
    });
    if(typeof this.group.get(this.field.name).value === 'string') {
      const date = this.getFormattedDate(this.group.get(this.field.name).value)
      this.group.patchValue({[this.field.name]: new Date(date)}); 
    }
    this.group.get(this.field.name).valueChanges.subscribe((value)=>{
      if(value == 'Invalid Date'){
        this.group.controls[this.field.name].setErrors({ 'incorrect': true });
      }
      if(typeof value === 'string' && value !="Invalid Date") {
        const date = this.getFormattedDate(this.group.get(this.field.name).value)
        this.group.patchValue({[this.field.name]: new Date(date)});
      } 
      if (value && value != "Invalid Date") {
        if (this.field.validations[0]?.maxDate) {
          if (new Date(value) > new Date(this.field.validations[0].maxDate)) {
            this.group.controls[this.field.name].setValue(new Date(this.field.validations[0].maxDate));
          }
        }
        if (this.field.validations[0]?.minDate) {
          if (new Date(value) < new Date(this.field.validations[0].minDate)) {
            this.group.controls[this.field.name].setValue(new Date(this.field.validations[0].minDate));
          }
        }
      }
    })
    const currentDate = new Date();
    this.indexVal = currentDate.getTime();
    this.field.validations.forEach((validation) => {
      let date = currentDate;
      switch (validation.validations) {
        case 'tommorow':
          date.setDate(date.getDate()+1);
          break;
        case 'yesterday':
            date.setDate(date.getDate()-1);
          break;
        case 'another':
          date = new Date(validation.date);
          break;
      }
      switch (validation.name) {
        case 'max':
          this.maxDate = date;
          this.field.validations[0].maxDate = date;
          if(validation.date_field){
            let max_field_control: FormControl = this.group.get(validation.date_field) as FormControl;
            if(max_field_control){
              this.checkForMaxDate(max_field_control.value, validation.days_by);
              max_field_control.valueChanges.pipe(
                takeUntil(this.compDestroyed$)
              ).subscribe((value)=>{
                this.checkForMaxDate(value, validation.days_by);
              });
            }
          }
          break;
        case 'min':
          this.minDate = date;
          this.field.validations[0].minDate = date;
          if(validation.date_field){
            let min_field_control: FormControl = this.group.get(validation.date_field) as FormControl;
            if(min_field_control){ 
              this.checkForMinDate(min_field_control.value, validation.days_by || 0);
              min_field_control.valueChanges.pipe(
                takeUntil(this.compDestroyed$)
              ).subscribe((value)=>{
                this.checkForMinDate(value, validation.days_by || 0);
              });
            }
          }
          break;
      }
    });
  }

  checkForMinDate(dateString: string, days_by = 0){
    let min_date;
    let min_date_validation;
    if(dateString){
      min_date = this.getDaysAhead(dateString, days_by);
      if(this.maxDate ){
        let max_date_converted  = new Date(this.maxDate);
        if(min_date.getTime() > max_date_converted.getTime()){
             min_date = max_date_converted;
        }
      }
      min_date_validation = { year: min_date.getFullYear(), month: min_date.getMonth() + 1, day: min_date.getDate() };
      if(!this.minDate){
        this.minDate = min_date_validation;
      } else{
        let min_date_converted  = new Date(this.minDate);
        // if(min_date && min_date.getTime() < min_date_converted.getTime()){
          this.minDate = min_date_validation;
        // }
      } 
    }
  }

  checkForMaxDate(dateString: string, days_by = 0){
    let max_date;
    let max_date_validation;
    if(dateString){
      max_date = this.getDaysAhead(dateString, days_by);
      if(this.minDate){
        let min_date_converted  = new Date(this.minDate);
        if(max_date.getTime() < min_date_converted.getTime()){
             max_date = min_date_converted;
        }
      }
      max_date_validation = { year: max_date.getFullYear(), month: max_date.getMonth() + 1, day: max_date.getDate() };
      if(!this.maxDate){
        this.maxDate = max_date_validation;
      } else{
        let max_date_converted  = new Date(this.maxDate);
        // if(max_date.getTime() < max_date_converted.getTime()){
          this.maxDate = max_date_validation;
        // }
      } 
    }
  }

  getDaysAhead(dateString, days){
    let date;
    date = new Date(dateString);
    date.setDate(date.getDate() + days);
    return date;
  }

  getFormattedDate(date) {
    if(!this.isIsoDateString(date) && typeof date === "string" && date.split("-")[0].length === 4) {
      const updatedDate = date.split("-");
      return `${updatedDate[0]}/${updatedDate[1]}/${updatedDate[2]}`
    }
    return date;
  }

  isIsoDateString(value: any): boolean {
    if (value === null || value === undefined) {
      return false;
    }
    if (typeof value === 'string'){
      return this._isoDateFormat.test(value);
    }    return false;
  }

  ngOnDestroy(){
    this.compDestroyed$.next();
    this.compDestroyed$.complete();
  }

}
