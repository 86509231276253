<div class="bggradient">
    <div id="mainDiv" class="mainDiv">
        <header>
            <div class="container">
                <div class="row pb-5 pt20 logoRow">
                    <div class="col-1 col-sm-1 col-md-1 col-lg-1 pr-lg-0"></div>
                    <div class="col-5 col-sm-5 col-md-5 col-lg-5 pl-lg-0">
                        <div class="b2cLogo">
                            <a href="javascript:void(0)">
                                <img src="assets/images/biz2creditLogo.svg" alt="logo" class="img-fluid" />
                            </a>
                        </div>
                    </div>
                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 text-right">
                    </div>
                </div>
            </div>
        </header>
            <router-outlet></router-outlet>
        <rubicon-footer></rubicon-footer>
    </div>
</div>
