<header [ngClass]="environment.journeyType=== 'banker-journey' ? (userData && userData?.id) ? '': 'border-bottom' : 'border-bottom'">
    <a class="skipContent" href="#SkipMain">Skip to main content</a>
    <div [ngClass]="environment.journeyType=== 'banker-journey' ? 'container' : 'container customer'">
        <div class="row logosWrap">
            <div class="col-12 col-md-7 col-lg-6">
                <div class="row">
                    <div class="col-12 col-md-12 col-lg-12">
                        <div class="logo-fhb text-lg-left text-md-left text-center py-3 py-lg-4 "><a class="cp logo1 d-inline-block"
                            href="javascript:void(0)"><img class="img-fluid cp" src='{{logo1_path}}' alt="Biz2X"
                                (click)="logoNavigation()"></a>
                                <span *ngIf="logo2_path" class="secondLogo"><img class="img-fluid" src='{{logo2_path}}' alt="First Savings Bank"></span>
                            </div>                                    
                    </div>
                    
                </div>
            </div>
            <div class="col-12 col-md-5 col-lg-6 userActionWrap">
                <div class="text-lg-right text-md-right text-center h-100 align-items-center d-md-flex d-lg-flex justify-content-end">                    
                    <span class="uname" *ngIf="userData && userData?.id && (environment.journeyType=='customer-journey')">{{userData?.full_name | titlecase}}</span>
                    <ng-container *ngIf="showSignIn">
                        <button type="button"
                            class="btn btn-default btn-md mr-3 mb-3 mb-md-0 mb-lg-0" (click)="onNavigate('signin')">Sign
                            In</button>
                    </ng-container>
                    <ng-container *ngIf="userData && userData?.id">
                        <span *ngIf="environment.journeyType=== 'banker-journey'" class="fs14">Logged in as <span
                                class="fwb">{{userData?.full_name | titlecase}}</span></span>
                        <div class="btn-group h-100 pr-4" dropdown placement="bottom right">
                            <button id="button-alignment" dropdownToggle type="button"
                                class="userIcon dropdown-toggle py-2 text-lg-right pr-lg-0" aria-controls="dropdown-alignment">
                                <span class="caret sr-only">User dropdown</span>
                            </button>
                            <ul id="dropdown-alignment" *dropdownMenu class="dropdown-menu dropdown-menu-right"
                                role="menu" aria-labelledby="button-alignment">
                                <li role="menuitem" *ngIf="showDashboard"><a class="dropdown-item cp" href="javascript:void(0)"
                                        (click)="submit('dashboard')">Dashboard</a></li>
                                <!-- <li *ngIf="environment.journeyType=== 'banker-journey'" role="menuitem"><a
                                        class="dropdown-item cp" href="javascript:void(0)"
                                        (click)="submit('change_password')">Change Password</a></li> -->
                                <li role="menuitem"><a class="dropdown-item cp" href="javascript:void(0)"
                                        (click)="submit('logout')">Logout</a></li>
                            </ul>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
    <div class="gbg border-top" *ngIf="environment.journeyType=== 'banker-journey' && userData?.id">
        <div [ngClass]="environment.journeyType=== 'banker-journey' ? 'container' : 'container'">
            <div class="row align-items-center py-3">
                <div class="col-sm col-12 col-lg-4">
                    <div class="fs14 fwsb ptc">
                        <ul class="d-flex manNav">
                            <li class="dashIcon" *ngIf="showDashboard" [routerLinkActive]="['active']"><a class="cp" href="javascript:void(0)" [routerLink]="['/dashboard']">Dashboard</a></li>
                            <!-- <li class="leadsIcon"><a href="javascript:void(0)" class="cp" (click)="commonService.popToast('info', 'Coming Soon', 'This feature is not available yet.')">Leads</a></li> -->
                            <li class="leadsIcon" *ngIf="showManageLeads" [routerLinkActive]="['active']"><a href="javascript:void(0)" class="cp" [routerLink]="['/manage-leads']">Leads</a></li>
                            <!-- <li class="freshCellsIcon"><a href="javascript:void(0)" class="cp" [routerLink]="['/manage-loans']">Fresh Cells</a></li> -->
                            <li class="adminIcon" *ngIf="showAdmin" [routerLinkActive]="['active']"><a class="cp" href="javascript:void(0)" [routerLink]="['/admin']">Admin</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-sm-auto col-12 col-lg-8">
                    <!-- <div class="dateIcon">January 1, 2022 to April 13, 2022</div> -->
                    <div class="seDateWrap" *ngIf="dateRangeFilter">
                        <form *ngIf="dateRangeConfig && dateRangeConfig.length > 0" class="form-inline justify-content-end" [formGroup]="dateRangeForm">
                            <span class="pr-2">Select Between</span>
                            <rubicon-dynamic [fields]="dateRangeConfig" [group]="dateRangeForm" [slug]="dateRangeSlug" class="row">
                            </rubicon-dynamic>
                            <button class="btn btn-primary fs15 searchBtnh" type="button" *ngIf="dateRangeConfig" (click)="applyDateFilter()">Search</button>
                            <toggle-app-listing-btn></toggle-app-listing-btn>
                          </form>
                        </div>
                </div>
            </div>
        </div>
    </div>
</header>