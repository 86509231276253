import { Injectable } from '@angular/core';
import { TaskInfoService } from '@rubicon/utils';
import * as _ from 'lodash';
import { FormService } from './form.service';
@Injectable()
export class GoogleAddressService {
    constructor(private taskinfoservice: TaskInfoService, private formService: FormService) { }

    fetchCityFromZip(group, field, cityOptions) {
        let populate_fields = field?.event?.populate_fields;
        let state_config = populate_fields?.state, city_config = populate_fields?.city, zip_config = populate_fields?.zip;
        let city_control = group.get(city_config.field_name);
        let state_control = group.get(state_config.field_name);
        let zip_control = group.get(zip_config.field_name);
        if (cityOptions && cityOptions?.length && zip_control && city_control && state_control?.value && !city_control.value) {
            this.taskinfoservice.getTaskInfo({ slug: 'get-started', state_id: state_control.value })
                .subscribe(response => {
                    const cityList = _.get(response, 'response_data.check_zip_code.data.data');
                    if (cityList && cityList?.length) {
                        let foundCityFromZip = cityList.find(city=>(city.state == state_control.value && zip_control.value == city.zipcode));
                        if(foundCityFromZip){
                            let cityFoundInOptions = cityOptions.find(option=>option.value.toUpperCase() == foundCityFromZip.city);
                            if(cityFoundInOptions){
                                city_control.setValue(cityFoundInOptions._id);
                            }else{
                                city_control.setValue(null);
                            }
                            this.formService.runFormDetectChanges();
                        }
                    }
                });
        }
    }
}