import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedLazyModule } from '@rubicon/shared-lazy';

import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { LoginHeaderComponent } from './login-header/login-header.component';
import { LoginFooterComponent } from './login-footer/login-footer.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { UiKitComponent } from './ui-kit/ui-kit.component';
import { UIKitRoutingModule } from './customer-portal-ui-kit-routing.module';
import { DefaultToasterComponent } from './toasters/default-toaster/default-toaster.component';
import { DefaultLoaderComponent } from './loaders/default-loader/default-loader.component';
import { StoreModule } from '@ngrx/store';
import * as uiKitState from './+state/ui-kit.reducers';
import { StaticPagesComponent } from './static-pages/static-pages.component';
import { SbaHeaderComponent } from './sba-header/sba-header.component';
import { SbaFooterComponent } from './sba-footer/sba-footer.component';
import { SbaHeaderV2Component } from './sba-header-v2/sba-header-v2.component';
import { SbaFooterV2Component } from './sba-footer-v2/sba-footer-v2.component';
import { AffiliateHeaderComponent } from './affiliate-header/affiliate-header.component';
import { AffiliateFooterComponent } from './affiliate-footer/affiliate-footer.component';
import { AffiliateHeaderInnerComponent } from './affiliate-header-inner/affiliate-header-inner.component';
import { VerifierHeaderComponent } from './verifier-header/verifier-header.component';
import { VerifierHeaderInnerComponent } from './verifier-header-inner/verifier-header-inner.component';
import { VerifierFooterComponent } from './verifier-footer/verifier-footer.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { DynamicSignupComponent } from './dynamic-signup/dynamic-signup.component';
import { SignupStateFacade } from 'libs/affiliate-portal/signup/src/lib/+state/signup-state.facade';
@NgModule({
  imports: [
    CommonModule,
    SharedLazyModule,
    UIKitRoutingModule,
    ModalModule.forRoot(),
    StoreModule.forFeature(
      uiKitState.UI_KIT_KEY,
      uiKitState.uiKitReducer)
  ],
  declarations: [
    HeaderComponent,
    FooterComponent,
    NavbarComponent,
    LoginHeaderComponent,
    LoginFooterComponent,
    PageNotFoundComponent,
    UiKitComponent,
    DefaultToasterComponent,
    DefaultLoaderComponent,
    StaticPagesComponent,
    SbaHeaderComponent,
    SbaFooterComponent,
    SbaHeaderV2Component,
    SbaFooterV2Component,
    AffiliateHeaderComponent,
    AffiliateFooterComponent,
    AffiliateHeaderInnerComponent,
    VerifierHeaderComponent,
    VerifierHeaderInnerComponent,
    VerifierFooterComponent,
    DynamicSignupComponent
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    NavbarComponent,
    LoginHeaderComponent,
    LoginFooterComponent,
    DefaultToasterComponent,
    DefaultLoaderComponent,
    StaticPagesComponent,
    SbaHeaderComponent,
    SbaFooterComponent,
    SbaHeaderV2Component,
    SbaFooterV2Component,
    AffiliateHeaderComponent,
    AffiliateFooterComponent,
    AffiliateHeaderInnerComponent,
    VerifierHeaderComponent,
    VerifierHeaderInnerComponent,
    VerifierFooterComponent,
    DynamicSignupComponent
  ],
  providers: [SignupStateFacade]
})
export class CustomerPortalUiKitModule { }
