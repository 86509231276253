import { Inject, Injectable } from "@angular/core";


@Injectable({
    providedIn: 'root'
})
export class CpaService {
    currentPartnerPlanConfiguration: any;

    constructor(
        @Inject('config') private CONFIG
    ) {
        this.currentPartnerPlanConfiguration = this.CONFIG?.plans; // retrive all plans for current client
    }


    // Check Form Fields present in the config coming from Tasks.js
    formFieldContains(config, formFieldNameList?: string[]): boolean {
        if(formFieldNameList) {
          return  config.filter((formField) => (formFieldNameList.includes(formField?.name))).length > 0;
        }
        return null;
    }


    currentPartner() { return this.CONFIG.partner; }

    // check current client in cpa 
    isPartnerCPA = () => { return this.CONFIG.partner === 'CPA'; }

    // check current plan is FREE
    isCPAFree(data) {
        return (this.isPartnerCPA() && (
            data?.plan?.type === this.CONFIG.plans.enum.FREE.type ||
            data?.type === this.CONFIG.plans.enum.FREE.type
        ));
    }

    // check current plan is NON FREE
    isCPANonFree = (activeUserPlan?: any) => {
        return (this.isPartnerCPA() && (
            activeUserPlan?.plan?.type !== this.currentPartnerPlanConfiguration.enum.FREE.type
        ));
    }

    // check current plan is BASIC
    isCPABasic = (data?: any) => {
        return (this.isPartnerCPA() && (
            data?.plan?.type === this.currentPartnerPlanConfiguration.enum.BASIC.type ||
            data?.type === this.currentPartnerPlanConfiguration.enum.BASIC.type
        ));
    }

    // check current plan is NEW PREMIUM
    isCPANewPremium = (data?: any) => {
        return (this.isPartnerCPA() && (
            data?.plan?.type === this.currentPartnerPlanConfiguration.enum.NEW_PREMIUM.type || 
            data?.type === this.currentPartnerPlanConfiguration.enum.NEW_PREMIUM.type
        ));
    }

    // check current plan is Enterprise
    isCPAEnterprise = (data?: any) => {
        return (this.isPartnerCPA() && (
            data?.plan?.type === this.currentPartnerPlanConfiguration.enum.ENTERPRISE.type ||
            data?.type === this.currentPartnerPlanConfiguration.enum.ENTERPRISE.type
        ));
    }

    // check current plan is Enterprise or New Premium
    isCPAEnterpriseOrNewPremium = (data?: any) => {
        return (this.isPartnerCPA() && ((
            data?.plan?.type === this.currentPartnerPlanConfiguration.enum.ENTERPRISE.type || 
            data?.plan?.type === this.currentPartnerPlanConfiguration.enum.NEW_PREMIUM.type
          ) || 
          (
            data?.type === this.currentPartnerPlanConfiguration.enum.ENTERPRISE.type || 
            data?.type === this.currentPartnerPlanConfiguration.enum.NEW_PREMIUM.type  
          )
        ));
    }


}