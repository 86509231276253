import { Component, OnInit, Input, OnChanges, Inject } from '@angular/core';
import { FormFieldInterface } from '@rubicon/interface/form-field';
import { FormGroup, FormArray } from '@angular/forms';

declare let require: any;

@Component({
  selector: 'rubicon-dynamic',
  templateUrl: './dynamic.component.html',
  styleUrls: ['./dynamic.component.scss']
})

export class DynamicComponent implements OnInit, OnChanges {
  @Input() fields: FormFieldInterface[] = [];
  @Input() group: FormGroup;
  @Input() slug: string;
  @Input() index:number;
  pageCssJson: any;
  inc = 0;

  constructor(@Inject('cssList') private cssList) { }

  ngOnChanges() {
    this.pageCssJson = this.cssList[this.slug];
    for (const field of this.fields) {
      if (this.pageCssJson) {
        const fieldCss = this.pageCssJson[field.type];
        if (fieldCss) {
          if (fieldCss[field.name]) {
            field.css = fieldCss[field.name];
          } else if (fieldCss['common']) {
            field.css = fieldCss['common'];
          } else {
            field.css = fieldCss['parent'];
          }
        }
      } else {
        field.css = "";
      }
    }
  }

  ngOnInit() {
  }

  checkArray(val) {
    return Array.isArray(val);
  }

  checkValue(group: FormGroup, field: FormFieldInterface) {
    if (this.checkArray(field.visible.value) && (field.visible.value.indexOf(group.get(field.visible.key).value) > -1)) {
     return true;
    } else if (this.checkArray(group.get(field.visible.key).value) && group.get(field.visible.key).value.includes(field.visible.value)) {
      return true;
    } else if (field.visible && field.visible.value === group.get(field.visible.key).value) {
      return true;
    } else {
       if(field.name==='ssn' && group.get('tin')  && group.get('tin').value){
         group.get('ssn').patchValue(group.get('tin').value);
       }
       else  if(field.name==='tin' && group.get('ssn') && group.get('ssn').value){
        group.get('tin').patchValue(group.get('ssn').value);
      }
       else{
      group.get(field.name).reset(field.value);
       }
      if (field.multiple) {
        const formArr = group.get(field.name) as FormArray;
        for (let i = formArr.controls.length; i > 0; i--) {
          formArr.removeAt(i);
        }
      }
      return false;
    }
  }
}
