<div class="topTextBanner">
    <div class="container">
        <div class="row logoRow pt-3">
            <div class="col-12">
                <div class="fs12 fwm lh18 pb-3">Additional guidance for PPP loan forgiveness is still pending. Borrowers and Advisors can get started while waiting on additional guidance from Treasury and SBA. But we recommend waiting for the final rules to be issued before generating the final SBA Form 3508 or submitting the application packet to a lender.</div>
            </div>
        </div>
    </div>
</div>
<header>
    <div class="container">
        <div class="row logoRow">
            <div class="col-4 col-sm-6 col-md-6 col-lg-6">
                <div class="b2cLogo">
                    <!-- [src]="cdnUrl+'logo.png'" -->
                    <a href="javascript:void(0)" (click)="navToDashboard()"><img src="assets/images/ppplogo.svg" alt="logo" class="img-fluid ppplogo"></a>
                </div>
            </div>
            <!-- *ngIf="fullName" -->
            <div class="col-8 col-sm-6 col-md-6 col-lg-6 text-right">
                <div class="d-flex justify-content-end">
                    <!-- <div class="py-2 py-md-4 pr-4 fs12 ptc">Logged in as <span class="fwsb">{{fullName}}</span></div> -->
                    <div class="btn-group" dropdown placement="bottom right">
                        <button id="button-alignment" dropdownToggle type="button" class="doted3 dropdown-toggle" aria-controls="dropdown-alignment">
                                &nbsp;
                        </button>
                        <ul id="dropdown-alignment" *dropdownMenu class="dropdown-menu dropdown-menu-right" [class.dash-h]="isSingleUserMultiApps" role="menu" aria-labelledby="button-alignment">
                            <!-- <li role="menuitem"><a class="dropdown-item" href="#">Edit Profile</a></li>
                            <li role="menuitem"><a class="dropdown-item" href="#">Change Password</a></li> -->
                            <li role="menuitem" *ngIf="isSingleUserMultiApps"><a class="dropdown-item cp stc" href="javascript:void(0);" (click)="navToDashboard()">Dashboard</a></li>
                            <li role="menuitem"><a class="dropdown-item cp stc" href="javascript:void(0);" (click)="logout()">Logout</a></li>

                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>




<!-- <header>
    <div class="container">
        <div class="row">
            <div class="col-6">
                <a class="navbar-brand pr-lg-4" href="javascript:void(0)"><img [src]="cdnUrl+'logo.png'"
                        alt="" class="logoh"/></a>
            </div>
            <div class="col-6 text-right" *ngIf="fullName">
                <div class="d-flex float-right userInfo">
                    <div class="uname">Logged in as <strong>{{fullName}}</strong></div>                    
                    <div class="nav-item dropdownMenu">
                        <a class="nav-link dropdownlink userDoted" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> ...</a>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
                            <ul class="list-group list-group-flush navultop">
                            <li role="menuitem"><a class="dropdown-item" href="javascript:void(0)" (click)="logout()">Logout</a></li>
                        </ul>
                        </div>
                      </div>
                </div>
            </div>
        </div>
    </div>
    </header> -->