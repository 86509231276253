import { SignupStateAction, SignupStateActionTypes, CheckVerificationTokenSuccess } from './signup-state.actions';

export const SIGNUPSTATE_FEATURE_KEY = 'affiliateSignup';

/**
 * Interface for the 'SignupState' data used in
 *  - SignupStateState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

export interface SignupState {
  loaded: boolean; // has the SignupState list been loaded
  loading: boolean;
  taskInfo: any;
  response?: any;
  errors?: any;
  formData?: any;
}

export interface SignupStatePartialState {
  readonly [SIGNUPSTATE_FEATURE_KEY]: SignupState;
}

export const initialState: SignupState = {
  loaded: false,
  taskInfo: undefined,
  loading: false
};

export function reducer(
  state: SignupState = initialState,
  action: SignupStateAction
): SignupState {
  switch (action.type) {
    case SignupStateActionTypes.TryLoadingSignupTaskInfo:
      {
        state = {
          ...state,
          response: null,
          loading: true
        };
      }
      break;
    case SignupStateActionTypes.LoadSignupTaskInfoSuccess:
      {
        state = {
          ...state,
          loading: false,
          loaded: true,
          taskInfo: action.payload
        };
      }
      break;
    case SignupStateActionTypes.SignupFormSubmit: {
      state = {
        ...state,
        loaded: false,
        loading: true,
        formData: action.payload
      };
    }
      break;
    case SignupStateActionTypes.SignupFormCompleted:
    case SignupStateActionTypes.DoLoginSuccess:
    case SignupStateActionTypes.SendVerificationMailSuccess:
    case SignupStateActionTypes.CheckVerificationTokenSuccess:
    case SignupStateActionTypes.VerifyTokenSuccess: {
      state = {
        ...state,
        loaded: true,
        loading: false,
        response: action.payload
      };
    }
    case SignupStateActionTypes.SendRegisterationMailSuccess: {
      state = {
        ...state,
        loaded: true,
        loading: false,
        response: action.payload
      };
    }
      break;
    case SignupStateActionTypes.LoadSignupTaskInfoError:
    case SignupStateActionTypes.DoLoginError:
    case SignupStateActionTypes.CheckVerificationTokenError:
    case SignupStateActionTypes.VerifyTokenError: {
      state = {
        ...state,
        loaded: false,
        loading: false,
        errors: action.payload
      };
    }
    case SignupStateActionTypes.SendRegisterationMailError: {
      state = {
        ...state,
        loaded: false,
        loading: false,
        errors: action.payload
      };
    }
  }
  return state;
}
