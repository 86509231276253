import { Inject, Input, OnDestroy, TemplateRef, ViewChild, ElementRef, ComponentRef, ComponentFactoryResolver, ViewContainerRef, EventEmitter, Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { CommonService, DownloadService, TaskInfoService } from '@rubicon/utils';
import { DocumentManagementComponent } from 'document-management';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription, forkJoin, Observable, throwError } from 'rxjs';
import { BankStatementService } from '../services/bank-statement.service';
import { Store, select } from '@ngrx/store';
import { take, switchMap, map } from 'rxjs/operators';
declare let yodleeManualScript: any;
declare let yodleeAutoScript: any;
declare const initScript: any;
declare let yodleePDFGenerator: any;

@Component({
  selector: 'backend-document-upload',
  templateUrl: './backend-document-upload.component.html',
  styleUrls: ['./backend-document-upload.component.scss'],
})
export class BackendDocumentUploadComponent implements OnInit, OnDestroy {
  @Input() config: any;
  @Input() appID: string;
  @Input() userID: string;
  @Input() backend_user_id: string;
  @Input() lead_ref_id: any;
  @Input() owner_id: any;
  @Input() ownerData: any;
  @Input() email_address: any;
  @Output() docListRefresh = new EventEmitter<any>();
  @Output() selectedDocEvent = new EventEmitter();

  @ViewChild("iframe", { static: false }) iframe: ElementRef;
  @ViewChild('template') template: TemplateRef<any>;
  compRef: ComponentRef<DocumentManagementComponent>;
  modalRef: BsModalRef;
  scriptLoaded = false;
  yodleeModal = true;
  documentType: any;
  scriptNode: any;
  yodleeRequestData;
  yodlee_flag = true;
  message: any;
  comment: any;
  user: any;
  storeSub: Subscription;
  configSub: Subscription;
  responseSub: Subscription;
  getDocSub: Subscription;

  @Input() businessData: any;
  @Input() appData: any;
  mandatoryCommentErr = false;
  doc;
  constructor(
    private bankStatementService: BankStatementService,
    private common: CommonService,
    private modalService: BsModalService,
    @Inject('environment') public environment,
    private download: DownloadService,
    @Inject('CONSTANTS') public CONSTANTS,
    @Inject('productConfig') public productConfig,
    private resolver: ComponentFactoryResolver,
    private vcRef: ViewContainerRef,
    private taskInfoService: TaskInfoService,
    private store: Store<any>,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    // if (this.environment.X_TENANT_ID) {
    //   this.config.task.headers['x-tenant-id'] = this.environment.X_TENANT_ID;
    // } else {
    //   delete this.config.task.headers['x-tenant-id'];
    // }
    this.bankStatementService.loadWidgetScript();

    this.storeSub = this.store
      .pipe(
        select('app'),
        take(1)
      ).subscribe(rootState => {
        if (rootState && rootState.nextTask) {
          if(rootState.userData && rootState.userData.user) {
            this.user = rootState.userData.user;
          }
        }
      }
      );
  }

  onLoad(iframe){
    this.doc = iframe.contentDocument || iframe.contentWindow;
    this.createComponent();
  }


  createComponent() {
    const compFactory = this.resolver.resolveComponentFactory(DocumentManagementComponent);
    this.compRef = this.vcRef.createComponent(compFactory);
    this.compRef.location.nativeElement.id ="DocumentManagementComponent";
    (<DocumentManagementComponent>this.compRef.instance).config = this.config;
    // (<DocumentManagementComponent>this.compRef.instance).emitOutput.subscribe(response => {
    // console.log(response);
    // });
    this.doc.body.appendChild(this.compRef.location.nativeElement);
    }

  openModal(template: TemplateRef<any>) {
    this.yodleeModal = true;
    this.checkWidgetLoaded(0);
    this.common.startLoad();
    setTimeout(() => {
      this.common.stopLoad();
    }, 3000);
    this.modalRef = this.modalService.show(template, {
      class: 'bankLoginModal linkAccount',
      ignoreBackdropClick: true,
    });
  }
  openModal2(template: TemplateRef<any>) {
    this.yodleeModal = true;
    this.checkWidgetLoaded2(0);
    this.common.startLoad();
    setTimeout(() => {
      this.common.stopLoad();
    }, 3000);
    this.modalRef = this.modalService.show(template, {
      class: 'manualBankStateModal',
      ignoreBackdropClick: true,
    });
  }

  checkWidgetLoaded2(count) {
    let self = this;
    setTimeout(() => {
      if (typeof initScript != undefined) {
        self.manualUpload();
      } else {
        if (count < 5) {
          self.checkWidgetLoaded2(count + 1);
        }
      }
    }, 2000);
  }
  checkWidgetLoaded(count) {
    let self = this;
    setTimeout(() => {
      if (typeof initScript != undefined) {
        self.yodleeLoad();
      } else {
        if (count < 5) {
          self.checkWidgetLoaded(count + 1);
        }
      }
    }, 2000);
  }

  requestDocTypeUpdate(template: TemplateRef<any>, event) {
    // if (this.ownerData?.value?.is_affiliate) {
    //   this.common.popToast('info', 'Coming Soon', 'This feature is not available yet for affiliates.')
    //   return;
    // }
    if (!event?.documentType?.filter_conditions?.filter?.find(el => el.key === "journey_type")?.benchmarkValue?.includes("customer-journey")) {
      this.common.popToast(
        'error',
        '',
        'The action cannot be taken because this document belongs to the banker. Please upload the file by yourself.'
      );
      return;
    }
    this.yodleeModal = false;
    this.documentType = event;
    this.modalRef = this.modalService.show(template,
      Object.assign({}, { class: 'modal-md-lg' })
    );
  }
  commentChange(event) {
    if (this.comment) this.comment.trim();
    
    if (this.comment && this.comment !== '') {
      this.mandatoryCommentErr = false;
    } else {
      this.mandatoryCommentErr = true;
    }
  }

    expireHash(expiry) {
    const payload = {
      action_type: "save",
      app_id: this.appID,
      user_id: this.userID,
      business_id: this.businessData._id,
      is_bank_statement_linked: expiry
    };
    this.taskInfoService.saveTaskInfo({ slug: "update_email_link_status" }, payload)
      .subscribe(res => {
        this.commonService.popToast("success", "Success", "Link successfully sent to customer.");
      });
  }

  sendMail() {
    if (this.comment) {
      this.comment = this.comment?.trim();
    }
    let allStages;
    if (this.comment && this.comment !== '' && this.comment !== ' ') {
    this.taskInfoService.getTaskInfo({ slug: "master_data_optimised", "model": "document_stages" }).subscribe((res) => {
      if (res?.response_data?.master_data_optimised?.data?.data?.document_stages) {
        allStages = res?.response_data?.master_data_optimised?.data?.data?.document_stages.find(el => el.type === 'stage-config-banker').config_types;
      }
      let apis = [];
      let payload: any = {
        app_id: this.appID,
        user_id: this.userID,
        owner_id: this.documentType.is_business ? null : this.ownerData.value._id || this.ownerData.value.owner_id,
        hash_for: 'owner_documents',
        business_id: this.businessData._id,
        email_address: this.documentType.is_business ? this.email_address : (this.ownerData.value.email_address || this.ownerData.value.email_address_beneficial_owner || this.ownerData.value.biz_email_address),
        to_name: this.documentType.is_business ? this.businessData.business_name : (this.ownerData.value.first_name || this.ownerData.value.first_name_beneficial_owner || this.ownerData.value.businessname),
        header_logo_path1: this.environment.logo1_path,
        header_logo_path2: this.environment.logo1_path,
        client_name: this.CONSTANTS.MAIL_TEMPLATE.project_name,
        senders_name: this.CONSTANTS.MAIL_TEMPLATE.senders_name,
        copyright_text: this.CONSTANTS.MAIL_TEMPLATE.copyright_text,
        frontend_url: `${this.environment.customerJourneyUrl}/${this.productConfig[this.environment.products_enabled[0]]?.route}`,
        terms: this.environment.terms,
        privacy: this.environment.privacy,
        mail_type: '5e874c31e2c8e935d455d242',
        role: this.documentType.is_business ? 'Primary Business' : 'Owner',
        loan_id: this.appData.loan_id,
        table_content: this.getMailTemplate([this.documentType.documentType], allStages),
      };

      const params: any = {
        slug: 'request_doc_business',
        app_id: this.appID,
        user_id: this.userID,
        business_id: this.businessData._id,
        skip_error: true
      };
      if (!this.documentType.is_business) {
        params.owner_id = this.ownerData.value._id || this.ownerData.value.owner_id;
      }
      apis.push(this.taskInfoService.saveTaskInfo(params, payload));

      let payload2: any = {
        business_id: this.businessData._id,
        is_docs_submitted: 0,
        app_id: this.appID,
      };
      if (!this.documentType.is_business) {
        delete payload2.business_id;
        payload2.owner_id = this.ownerData.value._id || this.ownerData.value.owner_id;
        apis.push(
          this.taskInfoService.saveTaskInfo(
            { slug: 'update_owner_docs' },
            payload2
          )
        );
      } else {
        apis.push(
          this.taskInfoService.saveTaskInfo(
            { slug: 'update_business_docs' },
            payload2
          )
        );
      }
        this.mandatoryCommentErr = false;
        if (apis.length > 0) {
          forkJoin(apis).subscribe((res) => {
            this.updateStatus('requested');
            this.common.popToast(
              'success',
              '',
              'Document mail sent successfully.'
            );
            this.modalRef.hide();
            this.comment = null;
            // this.docListRefresh.emit(true);
          });
        }
    });
  } else {
    this.mandatoryCommentErr = true;
  }
  }
  closeModal() {
    this.modalRef.hide();
    this.comment = null;
    this.mandatoryCommentErr = false;
  }
  updateStatus(status) {
      let payload;
      let params;
      // if (!this.documentType.putRequest) {
      //   if (this.documentType.notes.length > 0) {
      //     this.documentType.notes.forEach((element) => {
      //       if (element.status) {
      //         this.documentType.putRequest = true;
      //         this.documentType.noteId = element._id;
      //       }
      //     });
      //   } else {
      //     this.documentType.putRequest = false;
      //   }
      // }
      if (this.documentType.documentType.noteId) {
        this.documentType.putRequest = true;
      }

      if (this.documentType?.putRequest) {
        params = {
          ...this.config.task.update_status.params,
          id: this.documentType.documentType.noteId,
        };
      } else {
        params = { ...this.config.task.notes.params };
      }
      payload = {
        app_id: this.config.app_id,
        doc_type_key: this.documentType.documentType.key,
        type: 'document',
        status: status,
        note: 'Status updated.',
      };
      if (this.config?.is_affiliate) {
        payload.owner_id = this.config.id;
      } else if(this.config?.owner_id){
        payload.owner_id = this.config.owner_id;
      }
      if (this.config?.currentStage) {
        payload.stage = this.config.currentStage;
      }
      
      this.taskInfoService.saveTaskInfo(
        params,
        payload,
      ).subscribe((res) => {
        if (res?.nextTask?.value !== 'error') {
          if (!params.id) {
            this.documentType.documentType.noteId =
              res[params.slug].data.data._id;
          }
          this.documentType.documentType.status = status;
          this.documentType.documentType.status_updated_at = res[params.slug].data.data.updated_at;
          if (this.config.allNotes) {
            if (this.documentType.putRequest) {
              this.config.allNotes.forEach((element) => {
                if (
                  element.doc_type_key === this.documentType.documentType.key &&
                  element.status
                ) {
                  element.status = status;
                  element.status_updated_at = res[params.slug].data.data.updated_at;
                  this.documentType.documentType.noteId =
                    res[params.slug].data.data._id;
                }
              });
            } else {
              this.config.allNotes.push(
                {...res[params.slug].data.data, status_updated_at: res[params.slug].data.data.updated_at}
              );
            }
          }
          this.documentType.putRequest = true;
          if (!status || status === 'pending') {
            this.docListRefresh.emit(true);
          }
        }
      });
  }
  downloadDocType(event) {
    let documentType = this.config.documentTypes.find(el => el.key === event.documentType.key);
    if (documentType.documents.length === event.document.documents?.length) {
      if (event.document.documents?.length > 0) {
        let ids = event.document.documents
          .map((tup) => tup.doc_id)
          .join(',');
        let request_params = {
          slug: this.CONSTANTS.SLUG.download_all,
          ids: ids,
        };
        if (ids) {
          this.taskInfoService
            .getTaskInfo(request_params)
            .subscribe((response: any) => {
              const data =
                response.response_data.download_document_all.data.data.fileData;
              this.download.showPdf(data, `${event.documentType.value[0]}.zip`);
            });
        }
      } else {
        this.common.popToast('error', 'Error', 'No document(s) found.');
      }
    }
  }
  getMailTemplate(mailArrData, allStages) {
    let array = [];
    let string = '';
    allStages = Object.keys(allStages).map(function(k){return allStages[k]});
    mailArrData.forEach((element) => {
      let stages = element?.filter_conditions?.filter.find(el => el.key === 'stage_key')?.benchmarkValue;
      if (stages?.length) {
        element.folder_type = allStages.find(el=> el.value === stages[stages.length - 1])?.label;
      }      
      array.push(
        `<tr> <td style=\"color:#575756;font-size:14px;font-family:Arial,Helvetica,sans-serif;text-align:left;margin:0;padding:5px 10px;line-height:22px;border-bottom:1px solid #eee;border-right:1px solid #eee\">${element.folder_type? this.humanize(element.folder_type): "-"}</td> <td align=\"left\" style=\"color:#575756;font-size:14px;font-family:Arial,Helvetica,sans-serif;text-align:left;margin:0;padding:5px 10px;line-height:22px;border-bottom:1px solid #eee;border-right:1px solid #eee\">${element?.value?.length? element.value[0]: element.value}</td> <td align=\"left\" style=\"color:#575756;font-size:14px;font-family:Arial,Helvetica,sans-serif;text-align:left;margin:0;padding:5px 10px;line-height:22px;border-bottom:1px solid #eee\">${this.comment}</td> </tr>`
      );
    });
    string = array.join('');
      return string;
  }
  
  humanize(str) {
    if (str) {
      var i, frags = str.split('_');
      for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
      }
      return frags.join(' ');
    } else {
      return str;
    }
  }
  
  yodleeLoad() {
    this.yodleeRequestData = {
      success_message: 'Thanks for submitting!',
      yodleeAuto: {
        class: 'yodleeWidget',
        user_id: this.userID,
        app_id: this.appID,
        doc_type_id: '5ef9aad1dc5f783eccbb85e0',
        type: 'bank-statement-auto',
        provider: {
          auto: '5d6377f93b138633f8ca077f',
          manual: '5d6377f93b138633f8ca077e',
          'document-management': '5d53fe9fc82e7f05bca235f1',
        },
        // "bank_names": true,
        css: {
          yodleeWrapper: {
            display: 'block',
          },
          doneMessageID: {
            float: 'left',
            'margin-right': '20px',
          },
        },

        html: {
          loader:
            "<div  class='loaderWrap'> <div class='lds-ripple'><div></div><div></div></div></div>",
          auto: {
            label: ' Connect Electronically to your Bank Account',
            header: 'none',
            "switchActionButtons": "1",
            "customOptions": {
              "cancelButton": {
                "class": "btn btn-default customCancelClass",
                "text": "Cancel"
              },
              "submitButton": {
                "class": "btn btn-primary customSubmitClass",
                "text": "Submit"
              },
            },
            content: `<div class="textLeft">
    <div class="row">
    <div class="col-12">
        <p class="pb20">By selecting this option we will use your online banking logs to access business account statements for the chosen covered period.</p>
</div>
    </div>
    <div class="row">
    <div class="col-12">
    </div>
</div>

        </div>`,
          },
        },
      },
      // },
    };

    let initScriptObj = {
      widgetType: 'yodlee_auto',
      widget_service_api: this.environment.orchUrl + 'v2/',
      slugs: {
        'yodlee-auto': 'widget_yodlee',
        yodlee: 'wgt_yodlee_template',
        downloadFile: 'wgt_download_file',
        // 'delete-uploaded-docs-manual-reference':
        // 'wgt_delete_uploaded_docs_manual_reference',
        // deleteUploadedDocsBusiness: 'wgt_delete_uploaded_docs_business',
        // deleteUploadedDocsManual: 'wgt_delete_uploaded_docs_manual',
        'classify-uploaded-docs': 'wgt_classify_uploaded_docs',
        // 'yodlee-manual-template': 'wgt_yodlee_manual_template',
        'yodlee-document-upload': 'wgt_yodlee_document_upload',
        'analyser-uploaded-docs': 'wgt_analyser_uploaded_docs',
        openYodleeModel: 'wgt_open_yodlee_model',
        'classify-docs': 'wgt_classify_docs',
        submitYodleeForm: 'wgt_submit_yodlee_form',
        yodleeTransactionIds: 'wgt_yodlee_transaction_ids',
      },
      body: this.yodleeRequestData,
      api_urls: {
        img_src: 'https://cdn-platform-widget.b2cdev.com/images/',
      },
    };
    if (this.environment.X_TENANT_ID) {
      initScriptObj['x_tenant_id'] = this.environment.X_TENANT_ID;
    }
    var c = initScript.getTemplate(initScriptObj);
    yodleeAutoScript.widgetPromise.then((res) => {
      if (res.data.data.response_data.wgt_classify_docs.errors) {
        this.message =
          res.data.data.response_data.wgt_classify_docs.errors.message;
        this.yodlee_flag = true;
        // this.expireHash();
      } else {
        this.yodlee_flag = false;
        this.message = '';
        if (res.type === 'auto' && res.yodlee_transaction_ids_response) {
          this.uploadYodleePDF(
            res.yodlee_transaction_ids_response.transaction_uid
          );
        }
        //  this.expireHash(1);
      }
      // this.yodlee_flag = false;
      this.bankStatementService.yodlee_page.next({
        yodlee_flag: this.yodlee_flag,
        message: this.message,
      });
      //console.log("Yodlee auto response: ", res);
    });

    this.bankStatementService.loadCSS();
  }

  uploadYodleePDF(transactionsIds) {
    if (!transactionsIds || !transactionsIds.length) return;
    // for (let i = 0; i < transactionsIds.length; ++i) {
      this.generateYodleePDF(transactionsIds);
    // }
  }
  generateYodleePDF(transactionsIds) {
    let initScriptObj = {
      widget_service_api: this.environment.orchUrl + 'v2/',
      x_tenant_id: this.environment.x_tenant_id,
      widgetType: 'yodlee_pdf',
      slugs: {
        'yodlee-pdf': 'wgt_yodlee_pdf',
      },
      body: {
        user_id: this.userID,
        app_id: this.appID,
        doc_type_id: '5ef9aad1dc5f783eccbb85e0',
        doc_type_key: 'bank_statements_business_six_months',
        provider: {
          'yodlee-pdf': '5dc93a59a8a2496ef1ced889',
        },
        service_config: {
          doc_api_version: 'v3',
          v3_ref_id: this.lead_ref_id,
          backend_user_id: this.backend_user_id,
          "references_info": {
            owner_id: this.owner_id
          }
        },
        "pdfOptions": {
          "yodlee_logo": this.environment.yodlee_logo,
          "logo": this.environment.logo1_path,
        },
        header:
          '<div class="yodlee_pdf_header" style="margin-bottom: 20px;float:left;width:100%">\n\t\t\t<span class="left-head" style="float:left;"><img src="images/logo.svg"  style="width:121px;"></span>\n\t\t\t<span class="left-head" style="float:right;"><img src="images/logo-b2c.png"  style="width:121px; margin-top:-17px;"></span>\t\n\t\t</div>',
        tables: {
          'dagbank-dagbank_account_details': {
            value: 'DAGBANK-DAGBANK ACCOUNT DETAILS',
            cols: [
              [
                {
                  key: 'bank_name',
                  value: '',
                  "style": {"parentLevel": "width:25%" }
                },
                {
                  key: 'statement_period',
                  value: 'Statement Period (mm-dd-yyyy) :',
                  text: '',
                  "style": {"parentLevel": "width:75%" }
                },
              ],
              [
                {
                  key: '',
                  value: '',
                  "style": {"parentLevel": "width:25%" }
                },
                {
                  key: 'bank_account_id',
                  value: 'Bank Account ID:',
                  text: '',
                  "style": {"parentLevel": "width:75%" }
                },
              ],
              [
                {
                  key: '',
                  value: '',
                  "style": {"parentLevel": "width:25%" }
                },
                {
                  key: 'account_number',
                  value: 'Primary Account#:',
                  text: '',
                  "style": {"parentLevel": "width:75%" }
                },
              ],
              [
                {
                  key: '',
                  value: '',
                  "style": {"parentLevel": "width:25%" }
                },
                {
                  key: 'account_type',
                  value: 'Account Type:',
                  text: 'OTHER',
                  "style": {"parentLevel": "width:75%" }
                },
              ],
            ],
          },
          summary: {
            value: 'SUMMARY',
            order: 2,
            cols: [
              [
                {
                  key: 'opening_balance',
                  value: 'Beginning Balance ($)',
                },
                {
                  key: 'minimum_daily_balance',
                  value: 'Minimum Daily Balance ($)',
                },
              ],
              [
                {
                  key: 'deposits',
                  value: 'Deposits & Credits (+) ($)',
                },
                {
                  key: 'avg_daily_balance',
                  value: 'Average Daily Balance ($)',
                },
              ],
              [
                {
                  key: 'withdrawals',
                  value: 'Withdrawals (-) ($)',
                },
                {
                  key: 'no_of_deposits',
                  value: 'No. of Deposits',
                },
              ],
              [
                {
                  key: 'closing_balance',
                  value: 'Ending Balance ($)',
                },
                {
                  key: 'no_of_nsf',
                  value: 'NSF Count',
                },
              ],
              [
                {
                  key: 'deposit_transfers',
                  value: 'Deposit Transfers ($)',
                },
                {
                  key: 'net_transfers',
                  value: 'Net Transfers ($)',
                },
              ],
              [
                {
                  key: 'withdrawals_transfers',
                  value: 'Withdrawal Transfers ($)',
                },
                {
                  key: 'net_cash_flow',
                  value: 'Net Cash Flow ($)',
                },
              ],
              [
                {
                  key: '',
                  value: '',
                },
                {
                  key: 'no_of_days_with_negative_balance',
                  value: 'No. of Days with Negative Balance',
                },
              ],
            ],
          },
          daily_ending_balance: {
            value: 'DAILY ENDING BALANCE',
            cols: [
              [
                {
                  key: 'date',
                  value: '<b>DATE</b>',
                  text: '<b>AMOUNT ($)</b>',
                },
                {
                  key: 'date',
                  value: '<b>DATE</b>',
                  text: '<b>AMOUNT ($)</b>',
                },
                {
                  key: 'date',
                  value: '<b>DATE</b>',
                  text: '<b>AMOUNT ($)</b>',
                },
              ],
            ],
          },
          transaction_detail: {
            value: 'TRANSACTION DETAILS',
            cols: [
              [
                {
                  key: 'transaction_date',
                  value: '<b>DATE</b>',
                },
                {
                  key: 'description',
                  value: '<b>Particulars</b>',
                },
                {
                  key: 'transaction_type',
                  value: '<b>Deposits ($)</b>',
                },
                {
                  key: 'transaction_type',
                  value: '<b>Withdrawals ($)</b>',
                },
                {
                  key: 'running_balance',
                  value: '<b>Balance ($)</b>',
                },
              ],
            ],
          },
        },
        footer: '',
        transaction_id: transactionsIds,
        security: {
          headers: {
            dom: 345333,
            // "x-session-id": tokenId,
            mint: 121221,
          },
        },
      },
    };
    if (this.environment.X_TENANT_ID) {
      initScriptObj['x_tenant_id'] = this.environment.X_TENANT_ID;
    }
    initScript.getTemplate(initScriptObj);
    yodleePDFGenerator.widgetPromise.then(res => {
      if (res.data.response_data.nextTask.value !== 'error') {
        this.documentType = { documentType: this.config.documentTypes.find(el => el.key === 'bank_statements') };
        if ((this.environment.journeyType === 'customer-journey') || (this.environment.journeyType === 'banker-journey' && this.documentType?.documentType?.status !== 'requested')) {
          this.updateStatus('pending');
        }
        setTimeout(() => {
          window.location.reload();
        }, 2500);
      }
    });
  }
  manualUpload() {
    this.yodleeRequestData = {
      // },
      yodleeManual: {
        class: 'yodleeWidget',
        user_id: this.userID,
        app_id: this.appID,
        doc_type_id: '5ef9aad1dc5f783eccbb85e0',
        analyser_status: {
          ignoreParsingError: true,
          // "hideManualDiv": true
        },
        type: 'data',
        provider: {
          auto: '5d6377f93b138633f8ca077f',
          manual: '5d6377f93b138633f8ca077e',
          'document-management': '5d53fe9fc82e7f05bca235f1',
        },
        service_config: {
          doc_api_version: 'v3',
          v3_ref_id: this.lead_ref_id,
          backend_user_id: this.backend_user_id,
          "references_info": {
            owner_id: this.owner_id
          }
        },
        css: {},
        html: {
          // "label": 'none',
          header: 'Manual bank statement upload',
          uploaded_doc_error_message: 'Only PDF file format is allowed.',
          classification_success_message: 'Thanks for submitting!',
          loader:
            "<div  class='loaderWrap'> <div class='lds-ripple'><div></div><div></div></div></div>",
          content: `<h6 class="fs16 fwsb pt-3 ptc">Conventional approach</h6>
                <ul  class="ml-3 gList">
                <li ><p >Please upload only native pdfs (Pdfs received or downloaded from your Bank as-is)</p></li>
                <li ><p >We support native pdf statements from most leading Banks</p></li>
                </ul>
            `,
        },
      },
    };

    let initScriptObj = {
      widgetType: 'yodlee_manual',
      // widget_service_api: 'http://localhost:3007/v2/' ,
      widget_service_api: this.environment.orchUrl + 'v2/',
      //document_upload_url: this.environment.orchUrl + 'v2/',
      // + apiUrls.executeTask,
      slugs: {
        'yodlee-auto': 'widget_yodlee',
        yodlee: 'wgt_yodlee_template',
        downloadFile: 'wgt_download_file',
        'delete-uploaded-docs-manual-reference':
          'wgt_delete_uploaded_docs_manual_reference',
        deleteUploadedDocsBusiness: 'wgt_delete_uploaded_docs_business',
        deleteUploadedDocsManual: 'wgt_delete_uploaded_docs_manual',
        'classify-uploaded-docs': 'wgt_classify_uploaded_docs',
        'yodlee-manual-template': 'wgt_yodlee_manual_template',
        'yodlee-document-upload': 'wgt_document_upload',
        'analyser-uploaded-docs': 'wgt_analyser_uploaded_docs',
        openYodleeModel: 'wgt_open_yodlee_model',
        'classify-docs': 'wgt_classify_docs',
        submitYodleeForm: 'wgt_submit_yodlee_form',
        yodleeTransactionIds: 'wgt_yodlee_transaction_ids',
      },
      body: this.yodleeRequestData,
      api_urls: {
        img_src: 'https://cdn-platform-widget.b2cdev.com/images/',
      },
    };
    if (this.environment.X_TENANT_ID) {
      initScriptObj['x_tenant_id'] = this.environment.X_TENANT_ID;
    }
    var c = initScript.getTemplate(initScriptObj);

    yodleeManualScript.widgetPromise.then((res) => {
      if (res.data && res.data.data) {
        if (res.data.data.response_data.wgt_classify_uploaded_docs.errors) {
          this.message =
            res.data.data.response_data.wgt_classify_uploaded_docs.errors.message;
          this.yodlee_flag = true;
          // this.expireHash();
        } else {
          this.documentType = { documentType: this.config.documentTypes.find(el => el.key === 'bank_statements') };
          if ((this.environment.journeyType === 'customer-journey') || (this.environment.journeyType === 'banker-journey' && this.documentType?.documentType?.status !== 'requested')) {
            this.updateStatus('pending');
          }
          this.yodlee_flag = false;
          this.message = '';
          setTimeout(() => {
           window.location.reload();
          }, 2500);
          //  this.expireHash(1);
        }
      } else {
        this.documentType = { documentType: this.config.documentTypes.find(el => el.key === 'bank_statements') };
        if ((this.environment.journeyType === 'customer-journey') || (this.environment.journeyType === 'banker-journey' && this.documentType?.documentType?.status !== 'requested')) {
          this.updateStatus('pending');
        }
        setTimeout(() => {
          window.location.reload();
        }, 2500);
      }
      // this.yodlee_flag = false;
      this.bankStatementService.yodlee_page.next({
        yodlee_flag: this.yodlee_flag,
        message: this.message,
      });
      //console.log("Yodlee response: ", res);
    });
    this.bankStatementService.loadCSS();
  }
  errorCheck(event) {
    switch (event.type) {
      case 'upload_success':
        this.documentType = { documentType: this.config.documentTypes.find(el => el.key === event.response.data.data.doc_type_key) };
        if (this.documentType?.documentType?.documents?.length && ((this.environment.journeyType === 'customer-journey') || (this.environment.journeyType === 'banker-journey' && this.documentType?.documentType?.status !== 'requested'))) {
          this.updateStatus('pending');
        }
        this.common.popToast('success', '', 'Document(s) uploaded successfully.');
        break;
      case 'upload_error':
        this.common.popToast('error', 'Error', 'Document upload error. Allowed types:' + event.allowed_type + ' Allowed size:' + event.allowed_size + 'MB');
        break;
      case 'rename_success':
        this.common.popToast('success', '', 'Document renamed successfully.');
        break;
      case 'rename_error':
        this.common.popToast('error', 'Error', 'Failed to rename document.');
        break;
      case 'notes_success':
        this.common.popToast('success', '', 'Note added successfully.');
        break;
      case 'notes_error':
        this.common.popToast('error', 'Error', 'Failed to add note.');
        break;
      case 'status_success':
        this.common.popToast('success', '', 'Status updated successfully.');
        break;
      case 'status_error':
        this.common.popToast('error', 'Error', 'Failed to update status.');
        break;
      case 'delete_success':
        this.documentType = { documentType: this.config.documentTypes.find(el => el.key === event.response.data.response_data?.delete_business_ref?.data?.data?.response?.doc_type_key) }
        if (!this.documentType.documentType) {
          this.documentType = { documentType: this.config.documentTypes.find(el => el.key === event.response.data.response_data?.delete_owner_ref?.data?.data?.response?.doc_type_key) }
        }
        if (this.documentType?.documentType?.documents?.length === 0 && this.documentType?.documentType?.status !== 'requested') {
          this.updateStatus(null);
        }
        this.common.popToast('success', '', 'Document deleted successfully.');
        break;
      case 'delete_error':
        this.common.popToast('error', 'Error', 'Failed to delete document.');
        break;
    }

}

  downloadFormEvent(event) {
    // let link = document.createElement("a");
    // link.download = "Debt_Schedule.xlsx";
    // link.href = "assets/pdf/Debt_Schedule.xlsx";
    // link.click();
    this.yodleeModal = true;
    if (event.type === ("bank_statements_business_six_months" || "bank_statement_personal_six_months")) {
     // this.openModal(this.template);
      this.sendYodleeEmail();
    }
  }

    private sendEmail(body) {
    return this.taskInfoService.saveTaskInfo({ slug: "connect_sources_link" }, body).pipe(
      map((res: any) => {
        if (res && res.bank_statement_upload_email && res.bank_statement_upload_email.data) {
          return res;
        }
        throw res;
      })
    );
  }
  
  sendConnectSourcesMail(email_data: {
    mail_type: string,
    app_id: string,
    user_id: string,
    "from": string, "from_name": string,
    business_id: string,
    connect_sources_email_date: Date,
    to: string, to_name: string,
    cpa_firm_name?: string,
    end_point?: string,
    hash_for?: string,
    business_name ?:string
  }): Observable<any> {
    let hash_body = {
      app_id: email_data.app_id,
      user_id: email_data.user_id,
      business_id: email_data.business_id,
      hash_for: email_data.hash_for
    }
    return this.taskInfoService.saveTaskInfo({
      slug: "generate_hash"
    }, hash_body)
      .pipe(
        switchMap((response: any) => {
          if (response && response.generate_hash
            && response.generate_hash.data && response.generate_hash.data.data) {
            const body = {
              ...email_data,
              header_logo_path: this.environment.cdnURL + "biz2credit-logo.png",
              logo2_path: this.environment.cdnURL + "cpa-sig-logo-hed120.png",
              logo1_path: this.environment.cdnURL + "cpa-sig-logo-hed120.png",
              action_type: 'save',
              connect_payroll_source_url: `${this.environment.affiliate_journey_url}/${email_data.end_point}/${response.generate_hash.data.data.encrypted_hash}`,
            }
            return this.sendEmail(body)
          }
          return throwError(response);
        })
      );
  }

  sendYodleeEmail() {
    let to_name = this.user.name;
    let to = this.user.email_address;
    let from = this.environment.from;
    let from_name = this.environment.from_name;
    let connect_sources_email_date = new Date();
    try {
          this.sendConnectSourcesMail({
              mail_type: "5f56d9130e0f8280c0787127",
              app_id: this.appID,
              user_id: this.userID,
              from, from_name,
              business_id: this.businessData._id,
              to, to_name,
              cpa_firm_name: this.user.cpa_firm_name,
              connect_sources_email_date,
              end_point: "connect-bank-statement",
              hash_for: "connect_data_sources",
              business_name: this.businessData.business_name
    }).subscribe(() => {
      this.commonService.popToast("success", "Success", "Link successfully sent to customer.");
      this.expireHash(0);
    }, () => {
      this.commonService.popToast("error", "Failure", "Failed to send Link");
    });
    } catch (err) {
      this.commonService.popToast("error", "Failure", "Failed to send Link");
    }

  }

  onSelectedDocs(docs){
    this.selectedDocEvent.emit(docs);
  }

  ngOnDestroy() {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }
}
