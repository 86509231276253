import {
    Directive,
    ElementRef, forwardRef, HostBinding, HostListener, Input, OnInit, Renderer2
} from '@angular/core';
  
  import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
  
  @Directive({
    selector: '[contenteditable]',
    providers:
    [
      { provide: NG_VALUE_ACCESSOR, 
        useExisting: forwardRef(() => ContentEditableFormDirective), 
        multi: true 
      },
      {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => ContentEditableFormDirective), 
        multi: true
      }
    ]
  })
  export class ContentEditableFormDirective implements ControlValueAccessor, OnInit, Validator {
    @HostBinding('attr.contenteditable') enabled = true;
    @Input('format') format;
    private onChange (value: string) { this.adjust(); };
    private onTouched: () => void;
    private removeDisabledState: () => void;
  
    constructor(private elementRef: ElementRef, private renderer: Renderer2) { }
    
    validate(control: AbstractControl): ValidationErrors| null {
      return this.elementRef.nativeElement.textContent.trim().length > (this.format?.maxlength | 5000) ? { max: true } : null;
    }
    registerOnValidatorChange?(fn: () => void): void {
      this.onTouched = fn;
    }
    ngOnInit(): void {
      this.renderer.setAttribute(this.elementRef.nativeElement, 'max', '');
    }
  
    @HostListener('input') onInput(): void {
      this.elementRef.nativeElement.innerHTML.replace('<br>', '<br>\r\n'); // ensure next line in pdf template
      this.onChange(this.elementRef.nativeElement.innerHTML);
    }

    // copy-paste
    // @HostListener('keypress', ['$event']) 
    // onKeypress(e): void {
    //   console.log(this.elementRef.nativeElement.textContent.length);
    //   if(this.elementRef.nativeElement.textContent.length >= 5000 && e.keyCode !== 13){
    //     e.preventDefault();
    //   }
    // }
  
    @HostListener('blur') onBlur(): void {
      this.onTouched();
      if (this.format?.trimContent) {
        this.trimInnerHTML();
      }
      if (this.elementRef.nativeElement.textContent.trim().length === 0)
        this.elementRef.nativeElement.innerHTML = "";
      this.onChange(this.elementRef.nativeElement.innerHTML);
    }
    
    trimInnerHTML() {
      let starts_ends_w_nbsp = /(?:^(&nbsp;)+)|(?:(&nbsp;)+$)/g, 
        starts_ends_w_br = /(?:^(<br>)+)|(?:(<br>)+$)/g, 
        empty_div_w_br = /(?:^(<div>(?:<br>)+<\/div>)+)|(?:(<div>(?:<br>)+<\/div>)+$)/g, 
        empty_div_w_nbsp = /(?:^(<div>(?:&nbsp;)+<\/div>)+)|(?:(<div>(?:&nbsp;)+<\/div>)+$)/g, 
        ends_w_nbsp_div_end = /(?:((?:&nbsp;)+<\/div>)+$)/g;
      this.elementRef.nativeElement.innerHTML = this.elementRef.nativeElement.innerHTML.replaceAll('&nbsp; ', '&nbsp;&nbsp;');
      while (this.elementRef.nativeElement.innerHTML.match(starts_ends_w_nbsp)
        || this.elementRef.nativeElement.innerHTML.match(starts_ends_w_br)
        || this.elementRef.nativeElement.innerHTML.match(empty_div_w_br)
        || this.elementRef.nativeElement.innerHTML.match(empty_div_w_nbsp)
        || this.elementRef.nativeElement.innerHTML.match(ends_w_nbsp_div_end)) {
        this.elementRef.nativeElement.innerHTML = this.elementRef.nativeElement.innerHTML.replace(starts_ends_w_nbsp, '')
          .replace(starts_ends_w_br, '').replace(empty_div_w_br, '').replace(empty_div_w_nbsp, '').replace(ends_w_nbsp_div_end, '</div>');
      }
    }

    @HostListener('paste', ['$event'])
    onPaste(event) { 
      event.preventDefault();
      document.execCommand('inserttext', false, event.clipboardData.getData('text/plain')); 
    }
  
    writeValue(value: string): void {
      this.renderer.setProperty(this.elementRef.nativeElement, 'innerHTML', value || '');
    }
  
    registerOnChange(onChange: (value: string) => void): void {
      this.onChange = onChange;
    }
  
    registerOnTouched(onTouched: () => void): void {
      this.onTouched = onTouched;
    }
  
    setDisabledState(disabled: boolean): void {
      this.enabled = !disabled;
    }

    adjust(): void{
        this.elementRef.nativeElement.style.overflow = 'hidden';
        this.elementRef.nativeElement.style.height = 'auto';
    }

    ngAfterContentChecked(): void{
        this.adjust();
    }
  }