import { Injectable, Renderer2 } from '@angular/core';
import { environment } from '@env/environment';
import { forkJoin, of, throwError, BehaviorSubject } from 'rxjs';
import { CommonService } from '../common/common.service';
import { switchMap, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
declare let gapi: any;
declare let google: any;
@Injectable({
  providedIn: 'root'
})
export class GoogleDriveService {
  pickerApiLoaded: boolean;
  oauthToken: boolean;
  fileSubject$ = new BehaviorSubject<any>(null);
  scope = [
    'https://www.googleapis.com/auth/drive',
    'https://www.googleapis.com/auth/drive.file',
    'https://www.googleapis.com/auth/drive.readonly'];
  constructor(private common: CommonService, private httpClient: HttpClient) {

  }
  // google drive api process
  // Use the Google API Loader script to load the google.picker script.
  loadPicker() {
    gapi.load('auth', { callback: this.onAuthApiLoad.bind(this) });
    gapi.load('picker', { callback: this.onPickerApiLoad.bind(this) });
    gapi.client.load('drive', 'v2', () => {
    });
  }
  onAuthApiLoad() {
    const scope = this.scope;
    gapi.auth.authorize(
      {
        client_id: environment.google_drive.client_id,
        scope,
        immediate: false
      },
      this.handleAuthResult.bind(this));
  }
  onPickerApiLoad() {
    this.pickerApiLoaded = true;
    this.createPicker();
  }
  handleAuthResult(authResult) {
    if (authResult && !authResult.error) {
      this.oauthToken = authResult.access_token;
      this.createPicker();
    }
  }
  // Create and render a Picker object for searching images.
  createPicker() {
    if (this.pickerApiLoaded && this.oauthToken) {
      const view = new google.picker.View(google.picker.ViewId.DOCS);
      view.setMimeTypes('application/pdf');
      const picker = new google.picker.PickerBuilder()
        .enableFeature(google.picker.Feature.NAV_HIDDEN)
        .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
        .setAppId(environment.google_drive.app_id)
        .setOAuthToken(this.oauthToken)
        .addView(view)
        .addView(new google.picker.DocsUploadView())
        .setDeveloperKey(environment.google_drive.secret_key)
        .setCallback(this.pickerCallback.bind(this))
        .build();
      picker.setVisible(true);
    }
  }
  // A simple callback implementation.
  pickerCallback(data) {
    function getFileDescription(fileId) {
      let resolve;
      const request = gapi.client.drive.files.get({
        fileId
      });
      const promise = new Promise((res, rej) => {
        resolve = res;
      });
      request.execute((resp) => {
        resolve({
          title: resp.title,
          downloadUrl: resp.downloadUrl
        });
      });
      return promise;
    }
    if (data.action === google.picker.Action.PICKED) {
      if (!data.docs || data.docs.length === 0) {
        this.common.popToast('error', 'Error', 'No files selected');
        return;
      }
      forkJoin(
        data.docs.map(doc => {
          const fileId = doc.id;
          const name = doc.name;
          return this.getFileFromDrive('https://www.googleapis.com/drive/v3/files/'
            + fileId + '?alt=media&key=' + environment.google_drive.secret_key, name, this.oauthToken)
        })).subscribe((files: File[]) => {
          files = files.filter(file => file);
          if (files.length == 0) {
            this.common.popToast('error', 'Error', 'No files found');
            return;
          }
          this.fileSubject$.next(files);

        }, (error) => {
          this.common.popToast('error', 'Error', 'No files found');
          return;
        });
    }
  }
  getFileFromDrive(downloadUrl, fileName, token) {
    return of(1).pipe(
      switchMap((res: any) => {
        if (!downloadUrl) {
          return throwError(res);
        }
        return this.httpClient.get(downloadUrl, {
          responseType: 'blob',
          headers: {
            Authorization: 'Bearer ' + token
          }
        }).pipe(
          map(u8arr => {
            let file;
            if (!navigator.msSaveBlob) { // detect if not Edge
              file = new File([u8arr as BlobPart], fileName, { type: 'application/pdf' });
            } else {
              const blob = new Blob([u8arr as BlobPart], { type: 'application/pdf' });
              file = this.blobToFile(blob, fileName);
            }
            return file
          }));
      }));
  }

  public blobToFile(theBlob: Blob, fileName: string) {
    const b: any = theBlob;
    b.lastModifiedDate = new Date();
    b.name = fileName;
    b.filename = fileName;
    return b as File;
  }
}
