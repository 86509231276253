
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldInterface } from '../../../interfaces/FormFieldInterafce';

@Component({
  selector: 'rubicon-number',
  templateUrl: './number.component.html',
  styleUrls: ['./number.component.scss']
})
export class NumberComponent implements OnInit {

  field: FormFieldInterface;
  group: FormGroup;
  slug: string;
  indexVal: Number;
  decimal_places: string;
  constructor() { }

  ngOnInit() {
    this.indexVal=new Date().getTime();
    this.numberConfig();
  }

  numberConfig() {
    this.field.validations.forEach((validation) => {
      switch (validation.name) {
        case 'decimal_places':
          this.decimal_places = validation.validations;
          break;
      }
    });
  }

}
