<header>
    <div class="container">
        <div class="row logoRow bggradient">
            <div class="col-12">
                <div class="b2cLogo">
                    <a href="javascript:void(0)">
                        <img src="assets/images/biz2creditLogo.svg" alt="logo" class="img-fluid" />
                    </a>
                </div>
            </div>
        </div>
    </div>
</header>
<router-outlet></router-outlet>