import { Injectable } from '@angular/core';
import { SecurityService } from '../security/security.service';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FeatureAccessService {
  planType = new Subject();
    
    constructor(private Security: SecurityService) {}

    setFeature(features) {
        const encrypted = this.Security.set(this.Security.modifyData, JSON.stringify(features));
        sessionStorage.setItem('features', encrypted);
        this.planType.next(features);
    }

    getPlan() {
        const features = sessionStorage.getItem('features');
        if(features) {
          const decrypted = this.Security.get(this.Security.modifyData, features);
          return JSON.parse(decrypted);
        }
    }

    getFeatures(keys:any = []) {
        let allFeatures = [];
        if(this.getPlan()) {
          allFeatures = this.getPlan().all_features
        }

        if(!keys.length) return allFeatures;

        const keysMap = {};

        keys.map((k) => keysMap[k] = true);

        return allFeatures.filter((feature) => keysMap[feature.key]);
    }

    featureAllowed(key: string, value: any, operator: any = '=') {
        const allFeatures = this.getFeatures();

        const feature = allFeatures.find((f) => f.key === key);

        if(!feature) return false;

        if(feature.value === -1) return true;

        switch(operator) {
            case '=': return value === feature.value;
            case '<': return value < feature.value;
            case '>': return value > feature.value;
            case '<=': return value <= feature.value;
            case '>=': return value >= feature.value;
            default: return false;
        }
    }

    featureExists(key: string) {
      const allFeatures = this.getFeatures();

      const feature = allFeatures.find((f) => f.key === key);

      if(!feature) return false;

      return true;
    }

    featureValue(key: string) {
      const allFeatures = this.getFeatures();

      const feature = allFeatures.find((f) => f.key === key);

      if(!feature) return 0;

      return feature.value;
    }

    addValueToFeaturesValue(features: {key: string, value: number}[]) {
      const plan = this.getPlan();
      const all_features = [];
      const tobeUpdatedfeatureMap = {};
      let addonPurchased = false;
      features.forEach((f) => {
        if(tobeUpdatedfeatureMap[f.key] === undefined) {
          tobeUpdatedfeatureMap[f.key] = f.value;
        } else {
          tobeUpdatedfeatureMap[f.key] += f.value;
        }
      });

      plan.all_features.forEach((feature) => {
        let updatedFeature = {
          ...feature
        };

        if(tobeUpdatedfeatureMap[feature.key] !== undefined) {
          const valueToBeAdded = tobeUpdatedfeatureMap[feature.key];
          addonPurchased = true;
          updatedFeature = {
            ...updatedFeature,
            value: feature.value === -1 ? valueToBeAdded: ((+feature.value) + (+valueToBeAdded))
          }
        }

        all_features.push(updatedFeature);
      });

      plan.all_features = all_features;
      plan.addon_purchased = addonPurchased;

      this.setFeature(plan);
    }

    orderPlans(plans, activeUserPlan?) {
        const orderedPlans = [];
        plans.forEach((plan) => {
          const p = {...plan};
          if(p.type === 'free') {
            p.classType = 'card mb-0 mb-3 mb-lg-0';
            p.buttonClass = '';
          } else if(p.type === 'new_premium') {
            p.classType = 'card purple mb-3 mb-lg-0 min700';
            p.buttonClass = 'btn btn-secondary fwb fs18 px-5 text-upper';
          } else {
            p.classType = 'card mb-0 blue mb-3 mb-lg-0';
            p.buttonClass = 'btn btn-secondary px-5 fwb fs18 text-upper';
          }
          

          p.price = p.type === 'free' ? '' :p.price;

          p.altPrice = '';
          if(p.type === 'premium') {
            p.altPrice = '';
          } else if(p.type === 'enterprise') {
            p.altPrice = '';
          }


          p.active_user_plan = activeUserPlan && activeUserPlan.plan && activeUserPlan.plan.id === p.id;
          p.buttonClass = p.active_user_plan ? 'btn btn-secondary px-5 fwb fs18 ' :'btn btn-secondary fwb fs18';


          const globalfeatures = plan.features.global;
          const features = plan.features;

          p.all_features = [];

          Object.keys(features).forEach((key) => {

            if(key !== 'global') {

              const subFeatures = features[key];

              Object.keys(subFeatures).forEach((subKey) => {
                p.all_features.push({key: key +'.'+subKey , value: features[key][subKey], all_features: []});

              });
            } else {

              Object.keys(globalfeatures).forEach((gKey) => {

                const subFeatures = globalfeatures[gKey];
                if(typeof subFeatures === 'object' && subFeatures !== null) {
                  Object.keys(subFeatures).forEach((skey) => {
                    p.all_features.push({key: gKey + '.' + skey, value: subFeatures[skey], all_features: []});
                  });
                } else {
                  p.all_features.push({key: gKey, value: subFeatures, all_features: []});
                }
              });
              if(plan.total_app_limit){
                p.all_features.push({key: 'total_app_limit', value: plan.total_app_limit, all_features: []});
              }
              
            }
          });

     
          if(plan.client_commission && plan.client_commission.cpa){
            Object.keys(plan.client_commission?.cpa).forEach((gKey) => {

              const subCommFeatures = plan.client_commission.cpa[gKey];

              if(typeof subCommFeatures === 'object' && subCommFeatures !== null) {
                Object.keys(subCommFeatures).forEach((skey) => {
                  p.all_features.push({key: gKey + '.' + skey, value: (subCommFeatures[skey]).toFixed(2), all_features: []});
                });
              } else {
                p.all_features.push({key: gKey, value: (subCommFeatures*100).toFixed(2), all_features: []});
              }
            });
          }
          p.filtered_features = this.orderFeature(p.all_features);
          orderedPlans.push(p);
        });

        return orderedPlans;
    }

      orderFeature(features: any) {
        const orderState = {
          // "pp2.application_hard_limit": {id: 13, key: "pp2.application_hard_limit", order: 1, parent: 0},
          // "pp1_forgiveness.application_hard_limit":  {id: 12, key: "pp1_forgiveness.application_hard_limit", order: 2, parent: 0},
          // "term_loan.application_hard_limit":  {id: 15, key: "pp1_forgiveness.application_hard_limit", order: 3, parent: 0},
          // "working_capital.application_hard_limit":  {id: 17, key: "working_capital.application_hard_limit", order: 3, parent: 0},
          // "sba_7a.application_hard_limit": {id: 19, key: "sba_7a.application_hard_limit", order: 3, parent: 0},
          // "financing.application_hard_limit":  {id: 18, key: "financing.application_hard_limit", order: 2, parent: 0},
          // "ertc.application_hard_limit": { id: 16, key: "ertc.application_hard_limit", order: 3, parent: 0},
          "total_app_limit": {id: 13, key: "total_app_limit", order: 1, parent: 0},
          "working_capital_comission":  {id: 15, key: "working_capital_comission", order: 3, parent: 0},
          "ertc_commission":  {id: 17, key: "ertc_commission", order: 3, parent: 0},
          "term_loan_commission":   { id: 16, key: "term_loan_commission", order: 3, parent: 0},
          "sba_commission":  {id: 19, key: "sba_commission", order: 3, parent: 0},

          "acl.user_count": {id: 20, key: "acl.user_count", order: 4, parent: 0},
          // "acl.role_count": {id: 2, key: "acl.role_count", order: 0, parent: 0},
          "admin_center": {id: 7, key: "admin_center", order: 5, parent: 0},
          "exclusive_rate_discount": {id: 4, key: "exclusive_rate_discount", order: 6, parent: 0},
          "import_contacts": {id: 2, key: "import_contacts", order: 7, parent: 0},
          "access_to_marketing_resources": {id: 5, key: "access_to_marketing_resources", order: 8, parent: 0},
          "unique_landing_page": {id: 11, key: "unique_landing_page", order: 9, parent: 0},
          "bulk_email_campaigns": {id: 12, key: "bulk_email_campaigns", order: 10, parent: 0},
          "dedicated_account_manager": {id: 14, key: "access_to_email_support", order: 11, parent: 0},
          "access_to_email_support": {id: 18, key: "access_to_email_support", order: 12, parent: 0},
        };

        const filteredFeatures = [];
        features.forEach(f => {
          if(orderState[f.key]) {
            filteredFeatures.push({ ...orderState[f.key], ...f });
          } else {
            filteredFeatures.push({ ...f });
          }
        });


       let filteredOrderFeatures =  filteredFeatures.filter(f => {
          return f.hasOwnProperty("order")
        })
        
        filteredOrderFeatures.sort((a, b) => {
          return a.order - b.order;
        });
        return this.createFeatureTree(filteredOrderFeatures, 'filtered_features');
      }

      orderAddOns(addOns) {
        const orderedAddOns = [];
        addOns.forEach((addOn) => {
          const p = {...addOn};

          p.price = p.type === 'free' ? '' :p.price;
          p.selected = false;

          const globalfeatures = addOn.features.global;
          const features = addOn.features;

          p.all_features = [];

          Object.keys(features).forEach((key) => {

            if(key !== 'global') {

              const subFeatures = features[key];
              Object.keys(subFeatures).forEach((subKey) => {
                p.all_features.push({key: key +'.'+subKey , value: features[key][subKey], all_features: []});
              });
            } else {

              Object.keys(globalfeatures).forEach((gKey) => {
                const subFeatures = globalfeatures[gKey];
                if(typeof subFeatures === 'object' && subFeatures !== null) {
                  Object.keys(subFeatures).forEach((skey) => {
                    p.all_features.push({key: gKey + '.' + skey, value: subFeatures[skey], all_features: []});
                  });
                } else {
                  p.all_features.push({key: gKey, value: subFeatures, all_features: []});
                }
              });

            }
          });

          p.filtered_features = this.orderFeature(p.all_features);
          orderedAddOns.push(p);
        });

        return orderedAddOns;
    }

      createFeatureTree(dataset, key) {
        const hashTable = Object.create(null);
        dataset.forEach( aData => hashTable[aData.id] = { ...aData, [key] : [] } );
        const dataTree = [];
        dataset.forEach( aData => {
          if (aData['parent'] && aData['parent'] !== 0) {
            hashTable[aData['parent']][key].push(hashTable[aData.id]);
          } else {
            dataTree.push(hashTable[aData.id]);
          }
        });
    
        return dataTree;
      }
}