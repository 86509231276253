<ng-container [formGroup]="group">

    <!-- <ng-container *ngIf="field?.css?.floatingLabel; else normal">
        <div  [ngClass]="(field?.css?.floatingLabelClass)? field?.css?.floatingLabelClass :'floating-label'">
            <input 
                type="text" 
                placeholder="&nbsp;"
                [ngClass]="(field?.css?.field)? field?.css?.field :'form-control'" 
                [formControlName]="field.name"
                [id]="field.name + '_' + indexVal"  
                mask="separator.2" thousandSeparator="," prefix="$ "
                >

            <span class="highlight"></span>
            <label 
                [ngClass]="(field?.css?.labelClass)? field?.css?.labelClass :'labelText'" 
                [for]="field.name + '_' + indexVal">{{slug + ".label." + field.name | translate}}</label>

            <div *ngIf="group.get(field.name).invalid && (group.get(field.name).dirty || group.get(field.name).touched)"
                class="error-msg">
                <ng-container *ngFor="let validation of field.validations;">
                    <label class="error" *ngIf="group.get(field.name).hasError(validation.name)">
                        {{slug + ".errorMessage." + field.name + "." + validation.name | translate}}
                    </label>
                </ng-container>
            </div>
        </div>
    </ng-container> -->


    <ng-container>
        <ng-container *ngIf="!ifsLabel">
            <label [ngClass]="(field?.css?.labelClass)? field?.css?.labelClass :'p-label'" 
            [for]="field.name + '_' + indexVal" [innerHTML]='slug + ".label." + field.name | translate' ></label>  
            
            <ng-container *ngIf="field?.css?.tooltip && field?.css?.tooltip[field.name]">
                <a href="javascript:void(0)" [tooltip]="popTemplateth" [triggers]="(field?.css?.tooltip[field.name+ '_trigger'])?field?.css?.tooltip[field.name+ '_trigger']:'hover'" 
                [ngClass]="field?.css?.tooltip[field.name]?field?.css?.tooltip[field.name]:'ml-1'">
                  <img class="infoimg" src="{{environment?.asset_path}}images/info-icon-n.svg" alt="tooltip" /> </a>
                <ng-template #popTemplateth>
                 <div class="fs12 text-left fwr my-1 gtColor" [innerHTML]='slug + ".tooltip."+ field.name | translate'></div>
                </ng-template>
            </ng-container>
        </ng-container>
            <div class="{{slug+field.name + '_' + ngForIndex}}" *ngIf="ifsLabel" [ngClass]="(field?.css?.labelWrapClass)? field?.css?.labelWrapClass :''">
                <label [ngClass]="(field?.css?.labelClass)? field?.css?.labelClass :'p-label'" 
                [for]="field.name + '_' + indexVal" [innerHTML]='slug + ".label." + field.name | translate' ></label>  
                
                <ng-container *ngIf="field?.css?.tooltip && field?.css?.tooltip[field.name]">
                    <a href="javascript:void(0)" [tooltip]="popTemplateth" [triggers]="(field?.css?.tooltip[field.name+ '_trigger'])?field?.css?.tooltip[field.name+ '_trigger']:'hover'" 
                    [ngClass]="field?.css?.tooltip[field.name]?field?.css?.tooltip[field.name]:'ml-1'">
                      <img  class="infoimg" src="{{environment?.asset_path}}images/info-icon-n.svg" alt="tooltip" /> </a>
                    <ng-template #popTemplateth>
                     <div class="fs12 text-left fwr my-1 gtColor" [innerHTML]='slug + ".tooltip."+ field.name | translate'></div>
                    </ng-template>
                </ng-container>
            </div>

            <div class="{{slug+field.name + '_' + ngForIndex}}" [ngClass]="(field?.css?.inputAWrapClass)? field?.css?.inputAWrapClass :'d-inline'">
                <input type="text" mask="separator.2" [allowNegativeNumbers]="true" 
                [maxlength]="(field?.format?.maxlength)?field.format.maxlength:null" 
                thousandSeparator="," [readonly]="field?.readonly" [separatorLimit]="field?.format?.separatorLimit ? field.format.separatorLimit : ''"
                [placeholder]="slug + '.placeholder.' + field.name | translate" [ngClass]="(field?.css?.field)? field?.css?.field :'form-control'" 
                [formControlName]="field.name" [id]="field.name + '_' + indexVal" prefix="$ " (paste)="onPaste($event)">
                    
            <div *ngIf="group.get(field.name).invalid && (group.get(field.name).dirty || group.get(field.name).touched)"
                class="error-msg" [ngClass]="(field?.css?.error)? field?.css?.error :''">
                <ng-container *ngFor="let validation of field.validations;">
                    <label class="error" *ngIf="group.get(field.name).hasError(validation.name)">
                        {{slug + ".errorMessage." + field.name + "." + validation.name | translate}}
                        {{validation.name==="max"? (group.get(field.name).errors.max.max | currency : 'USD' : 'symbol' : '1.0-0' )+".":""}}
                    </label>
                </ng-container>
                <label class="error" *ngIf="group.get(field.name).hasError('incorrect')">
                    {{slug + ".errorMessage." + field.name + ".incorrect" | translate}}
                </label>
            </div>
        </div>

    </ng-container>
    
</ng-container>