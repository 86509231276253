import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { environment } from '@env/environment';
import { TaskInfoService, CommonService, addUserDetails, addAppID, addBusinessID, addNextTask } from '@rubicon/utils_custom';
import { CUSTOMER_PORTAL } from '@rubicon/interface/task-info';
import { logout } from 'libs/customer-portal/ui-kit/src/lib/+state/ui-kit.action';
import { Router } from '@angular/router';
import { MultiUserAppsService } from 'libs/utils/src/lib/services/api/mulit-user-apps.service';


@Component({
  selector: 'sba-header-v2',
  templateUrl: './sba-header-v2.component.html',
  styleUrls: ['./sba-header-v2.component.scss']
})
export class SbaHeaderV2Component implements OnInit, OnDestroy {
  userData: any;
  rootStateSubscription: Subscription;
  fullName = '';
  isSingleUserMultiApps = false;
  cdnUrl: string = environment.cdnURL;
  constructor(private store: Store<any>, private taskInfoService: TaskInfoService, private common: CommonService,
    private router: Router, private multiUserAppsService: MultiUserAppsService) { 
      this.isSingleUserMultiApps = this.multiUserAppsService.isSingleUserMultiApps()
    }

  ngOnInit() {
    this.rootStateSubscription = this.store.pipe(select('app'))
      .subscribe(rootState => {
        if (rootState.userData && rootState.userData.user) {
          this.fullName = rootState.userData.user.first_name + ' ' + rootState.userData.user.last_name;
        }
      });
  }

  /**
   * function to logout
   */
  logout() {
    this.taskInfoService.saveTaskInfo({
      slug: CUSTOMER_PORTAL.logout
    }, {}).subscribe((res: any) => {
      this.common.setLogin(false);
      if (res && res.nextTask) {
        location.href = location.origin;
      }
    }, ()=>{
      location.href = location.origin;
    });
  }

  navToDashboard(){
      if(!this.isSingleUserMultiApps){
        return;
      }
      this.store.dispatch(addAppID({ appID: null }));
      this.store.dispatch(addBusinessID({ businessID: null }));
      this.store.dispatch(addNextTask({ nextTask: 'dashboard' }));
      setTimeout(() => {
        this.router.navigate(['/dashboard']);
      }, 0);
  }

  ngOnDestroy() {
    this.rootStateSubscription.unsubscribe();
  }
}
