import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
@Component({
  selector: 'platform-core-anchor-cell-renderer',
  template: `
  <a  href="javascript:void(0)" [ngClass]="{'sentBtn sent statusIcon' : ( owner.consent !== 'received' && ( owner.is_primary)), 'icon confirmed statusIcon1': (owner.consent === 'received' && owner.is_primary), 'sentBtn nrIcon' :  !owner.is_primary}">
    <span *ngIf="(owner.consent === 'received' && owner.is_primary)">Confirmed</span>
    <span *ngIf="owner.consent === 'sent' && owner.is_primary">Request Sent</span>
    <span *ngIf="showIcon('pendingIcon', owner)"> {{ this.config?.componentType!=='owner-verification'? 'Pending':'Request Pending'}}</span>
    <span *ngIf="!showIcon('pendingIcon', owner) && owner.consent === 'pending' && owner.is_primary">Pending</span>
    <span *ngIf="!showIcon('pendingIcon', owner) && !owner.is_primary">Consent Not Required</span>
  </a>
  <!-- <ng-template #not_req>
  <span class="sentBtn sent statusIcon">Not Required</span>
  </ng-template> -->
  `
})

export class AnchorCellRendererComponent implements ICellRendererAngularComp {
  params: any;
  config: any;
  bankerJourney = 'banker';
  is_authorized_banker: boolean;
  app_data: any;
  company_role_obj = {
    'guarantor': '5c91e94d3a2ba0289ce68a95',
    'key_employee': '5c91e94d3a2ba0289ce71a44',
    'co_borrower' : '5c91e94d3a2ba0289ce68a94',
    'trust': '5c91e94d3a2ba0298ce84a39',
    '401k': '5c91e94d3a2ba0198ce86a39'
  };
  owner: any;
  
  agInit(params): void {
    this.params = params;
    this.config = this.params.config;
    this.is_authorized_banker = this.params.is_authorized_banker;
    this.app_data = params.app_data;
    this.owner = this.params.data;
  }


  refresh(params?: any): boolean {
    return true;
  }

  onClick($event, action) {
    if (this.params.onClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params,
        action
      }
      this.params.onClick(params);
    }
  }

  showIcon(icon: string, owner?: any): boolean{
    let checkUserAssigned = (this.config?.journey != 'banker' || (this.config?.journey === 'banker' && this.is_authorized_banker));
    let levelCondition = owner?.parent_id === this.config?.app_data?.parent_id;
    switch (icon) {
      case 'deleteIcon':
        if(!owner?._id) return true;
        return checkUserAssigned && (levelCondition && owner.consent === 'pending' && owner.is_secondary && this.config.journey !== this.bankerJourney);

      case 'sendIcon' : 
        if(this.config.journey === this.bankerJourney && checkUserAssigned) return owner.consent === 'pending'; 
        return checkUserAssigned && checkUserAssigned && this.config?.componentType!=='owner-verification' && owner?._id && levelCondition && owner.consent === 'pending';

      case 'pendingIcon':
        return this.config?.componentType =='owner-verification'  && owner.consent === 'pending';
      case 'refreshIcon':
        return this.config?.componentType!=='owner-verification' && owner.consent === 'sent' || (owner.consent === 'pending' && owner.parent_id !== this.app_data?.parent_id && this.config.journey !== this.bankerJourney);

      case 'editIcon' :
        if((!owner?._id) || (this.config.journey === this.bankerJourney && checkUserAssigned)) {
          return  true
        };
        return checkUserAssigned && ((owner?.parent_id === this.app_data?.parent_id && owner?.consent === 'pending') || (owner ?.is_primary));

      case 'resendIcon' : 
        return checkUserAssigned && this.config?.componentType!=='owner-verification' && this.config.journey === this.bankerJourney && owner.consent === 'sent'; 
        
      case 'addOwnerInAccordian' :
        return this.config.show_add_owner_option_in_accordian && owner.owner_type == 'corporate' && owner?.level < 4 && owner.consent == "received"; 

      case 'addOwnerInCompanyDetails' : 
        return this.config.show_add_owner_option_in_company_details;

      case 'addOwnerInBottom' : 
        return this.config.show_add_owner_option_in_bottom;
        
      case 'businessOwnerShipPercentageIcon' :
        return checkUserAssigned && this.config.showBusinessOwnerShipPercentage && !(owner?.owner_type == "corporate" && owner?.company_role==this.company_role_obj?.co_borrower);  
      default:  
        break;
    }
  }

}
