import { Component, Inject, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldInterface } from '../../../interfaces/FormFieldInterafce';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '@rubicon/utils';

@Component({
  selector: 'rubicon-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss']
})
export class TextComponent implements OnInit {

  @Input() field: FormFieldInterface;
  @Input() group: FormGroup;
  @Input() slug: string;
  autoData: any;
  indexVal: number;
  empty_data = []
  show_search_list = false;
  ngForIndex: number = 0;
  cashFlowAnalysisInput = false;
  ReplaceTextIn = ['create-profile', 'start_an_application', 'collateral-extra-info-form']
  constructor(
    private common: CommonService, 
    private translate: TranslateService,
    @Inject('environment') private environment
    ) { }

  ngOnInit() {
    if (['business_financial','personal_financial','gdscr','aba-valuation-form','real-estate-valuation-form'].includes(this.slug)) {
      this.cashFlowAnalysisInput = true;
    }
    this.ReplaceTextIn.find((value :any) => {
      if(value === this.slug){
        let createProfileTransalation = {
            [value]: {
            "label": {
              "have_a_loan": this.translate.instant(`${value}.label.have_a_loan`,{client_name : this.environment.client_name }),
              "is_the_appraiser_on_bank_approved_list" : this.translate.instant(`${value}.label.is_the_appraiser_on_bank_approved_list`,{client_name : this.environment.client_name }),
            },
            "placeholder" : {
              "have_a_loan": this.translate.instant(`${value}.placeholder.have_a_loan`,{client_name : this.environment.client_name })
            }
          }
        }
        this.translate.setTranslation("en",createProfileTransalation, true);
        this.translate.use("en");
      } 
    }) 

    this.common.sendAddressData(this.empty_data);
    this.common.sendEventCallData(this.field.name, '', this.empty_data);
    this.indexVal = new Date().getTime();
    this.common.eventDataObservable
      .subscribe((data: any) => {
        if (data && Object.keys(data).length > 0) {
          const dataStep = data.data.data;
          if (dataStep && this.field.name === data.data.name) {
            this.field['autoCompleteList'] = dataStep.data.result ? dataStep.data.result : dataStep.data;
            this.autoData = dataStep.data.result ? dataStep.data.result : dataStep.data;
          }
        }
      });
  }

  getAddress(index: number) {
    let addressData: any;
    addressData = this.autoData[index].best;
    const companyName = this.autoData[index].best.company_name_info
    this.common.sendAddressData(addressData);
    this.field['autoCompleteList'] = []
    this.autoData = []
  }

  getLender(index: number) {
    let lenderData: any;
    lenderData = this.autoData[index];
    this.field['autoCompleteList'] = []
    this.autoData = []
  }

  showSearchList(){
    this.show_search_list = true;
  }

  hideSearchList(){
    let control = this.group.get(this.field.name)
    if(typeof control.value === 'string' && (control.valid || control.hasError('pattern')) && !this.field?.css?.maskHiddenInput) {
    control.setValue(control.value.trim(), {emitEvent: false })
    }
    setTimeout(()=>{
      this.show_search_list = false;
    },500);
  }
  getNaicsCode(index: number) {
    let naicsData: any;
    naicsData = this.autoData[index];
    this.group.get(this.field.name).setErrors({incorrect: null});
    this.group.get(this.field.name).patchValue(naicsData.naics_code + ' - ' + naicsData.value, {
      emitEvent: false
    });
    this.field['autoCompleteList'] = []
    this.autoData = []
  }

  changeVal(){
    if(this.field?.config?.replaceTextIn){
      let val = this.group.get(this.field.name)?.value?.replace(`${this.field.config.replaceTextIn}`|| '\\', `${this.field?.config?.replaceTextWith}` || '');
      this.group.get(this.field.name).setValue(val);
    }
    if(this.field?.format?.type === 'percent' && this.group.get(this.field.name)?.value === '100.'){
      let val = this.group.get(this.field.name)?.value?.replace('.','');
      this.group.get(this.field.name).setValue(val);
    }
  }
}
