import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { GridApi } from 'ag-grid-community';

@Component({
    selector: 'platform-core-action-cell-renderer',
    template: `
        <span class="switchWrap checWrap ml-4"
            *ngIf="config?.componentType==='owner-verification'">
            <label for="company" tooltip="Select to send consent request." container="body" class="el-switch el-switch-sm"
                for="ownerCheck{{index}}">
                <input type="checkbox" class="form-check-input" id="ownerCheck{{index}}" [value]="index" [checked]="owner.send_consent_request"
                (change)="onClick($event)" [disabled]="!((!owner?.consent ||( owner.consent==='pending')))" />
                <label class="fs12 fwsb mt-1 clt pr-0 pl-3 mb-0" for="ownerCheck{{index}}">
                <span class="label-text">&nbsp;</span>
                </label>
                <span class="el-switch-style"></span>
            </label>
        </span>
  `,
})
export class CheckboxCellRenderer implements ICellRendererAngularComp {

    params: any;
    config: any;
    owner: any;
    index: number;

    agInit(params): void {
        this.params = params;
        this.config = this.params.config;
        this.owner = this.params.data;
        this.index = this.params.rowIndex;
    }

    refresh(params?: any): boolean {
        return true;
    }

    onClick($event) {
        if (this.params.onClick instanceof Function) {
            this.params.onClick($event);
        }
    }

}