import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'rubicon-login-header',
  templateUrl: './login-header.component.html',
  styleUrls: ['./login-header.component.scss']
})
export class LoginHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
