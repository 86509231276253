import { Directive, OnInit, OnDestroy, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { CanAccessService } from '../services/can-access/can-access.service';

@Directive({
    selector: '[appCanAccess]'
})
export class CanAccessDirective implements OnInit, OnDestroy {
    @Input('appCanAccess') appCanAccess: string;
    private permission$: Subscription;
    private access: string = 'view';
    public tab: string;
    constructor(private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private canAccessEvents: CanAccessService) {
    }

    ngOnInit(): void {
        this.tab = this.appCanAccess;
        if(Array.isArray(this.appCanAccess)) {
            this.access = this.appCanAccess[1] || 'view' ;
            this.tab = this.appCanAccess[0];
        }
        
        this.applyPermission();
    }

    private applyPermission(): void {
        this.permission$ = this.canAccessEvents
            .checkAuthorization(this.tab, this.access)
            .subscribe(authorized => {
                if (authorized) {
                    this.viewContainer.createEmbeddedView(this.templateRef);
                } else {
                    this.viewContainer.clear();
                }
            });
    }

    ngOnDestroy(): void {
        if(this.permission$) {
            this.permission$.unsubscribe();
        }
    }

}