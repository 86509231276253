<footer>
    <div [ngClass]="environment.journeyType=== 'banker-journey' ? 'container py-3' : 'container py-3'">
        <div class="row h-100 align-items-center">
            <div class="col-12 col-sm-6 col-md-6 col-lg-6 copyrightlink">
                <ul>
                    <li><a target="_blank" href="{{environment.privacy}}" class="colorWhite" [innerHTML]="'footer.html.privacy' | translate">Privacy</a></li>
                    <li><a target="_blank" href="{{environment.terms}}" class="colorWhite" [innerHTML]="'footer.html.terms' | translate">Terms</a></li>
                    <li><a target="_blank" href="{{environment.siteMap}}" class="colorWhite" [innerHTML]="'footer.html.sitemap' | translate">Sitemap</a></li>
                    <li *ngFor="let comp of ui_component">
                        <a target="{{comp.config.target}}" href="{{comp.config.href}}" class="colorWhite">{{comp.config.value}}</a>
                    </li>
                </ul>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-6 copyright">
                <p>&copy; {{footerText}} <span class="poweredByLogo"><img class="img-fluid poweredByLogoimg" src='{{powered_by_logo}}' alt="Biz2X"></span></p>
            </div>
        </div>
    </div>
</footer>