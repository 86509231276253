import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldInterface } from '@rubicon/interface/form-field';
@Component({
  selector: 'rubicon-tel',
  templateUrl: './tel.component.html',
  styleUrls: ['./tel.component.scss']
})
export class TelComponent implements OnInit {
  field: FormFieldInterface;
  group: FormGroup;
  slug: string;

  constructor() { }

  ngOnInit() {
  }
}
