<section class="mainCont">
    <div class="container">
        <div class="row twocollayout mb-4">
            <div class="col-1 col-sm-1 col-md-1 col-lg-1 pr-lg-0"></div>
            <div class="col-7 col-sm-7 col-md-7 col-lg-7 pl-lg-0">
                <h1 class="prime-exl-text mb-5 pb-5">{{slug + ".title" | translate}}</h1>                        
            </div>
            <div class="col-3 col-sm-3 col-md-3 col-lg-3 pl-lg-0 text-right"></div>
            <div class="col-1 col-sm-1 col-md-1 col-lg-1 pr-lg-0"></div>
        </div>
    </div> 

    <div class="wbg p-0 rounded-0 mainCont">
        <div class="container">
            <div class="row">
                <div class="col-1 col-sm-1 col-md-1 col-lg-1 pr-lg-0"></div>
                <div class="col-10 col-sm-10 col-md-10 col-lg-10 pl-lg-0">
                    <div class="wbg whDbg pb-5" style="min-height:280px; margin-top:-70px;">
                        <div class="row">
                            <div class="col-12">
                                <div class="sm-text fwsb">Last Updated: December 13, 2019</div>  
                                <div class="onecolumnfull fullContent mt-4 sm-text" 
                                [innerHTML]="slug+'.main_content' |translate">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
