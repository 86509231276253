import { Pipe, PipeTransform } from '@angular/core';
    
    @Pipe({
        name: 'filter',
    })
    export class FilterPipe implements PipeTransform {
        
        transform(values: any[], searchText: any, that: any): any {
            if (searchText.length === 0) {
                return values;
            }
            that.filter_result = false;
            for (let i = 0; i < values.length; i++) {
                let filtered_qa = [];
                for (let j = 0; values[i].ques_ans && j < values[i].ques_ans.length; j++) {
                    let q_a = values[i].ques_ans[j];
                    let match_qes = q_a.ques_html && q_a.ques_html.toLowerCase().indexOf(searchText.toLowerCase());
                    let match_ans = q_a.ans_html && q_a.ans_html.toLowerCase().indexOf(searchText.toLowerCase());

                    if (match_qes !== -1 || match_ans !== -1) {
                        that.filter_result = true;
                        filtered_qa.push(values[i].ques_ans[j])
                    }
                }
                values[i].ques_ans = filtered_qa;
            }
            return values;
        }

      }