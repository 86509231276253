import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class FormService {
    selectEvent: Subject<any> = new Subject<any>();
    SelectFieldEvent: Subject<any> = new Subject<any>();
    GroupFormEvent: Subject<any> = new Subject<any>();

    selectListener() {
        return this.selectEvent.asObservable();
    }

    emit(value) {
       this.selectEvent.next(value);
    }

    selectFieldListener() {
        return this.SelectFieldEvent.asObservable();
    }

    emitFieldEvent(value) {
       this.SelectFieldEvent.next(value);
    }

    /**Emit event related to from group */
    groupFormListener() {
        return this.GroupFormEvent.asObservable();
    }

    groupFormEvent(value) {
       this.GroupFormEvent.next(value);
    }
}