import {
  AccountDetailsAction,
  AccountDetailsActionTypes
} from './account-details.actions';

export const PPP_LOAN_INFO_FEATURE_KEY = 'accountDetails';

/**
 * Interface for the 'BusinessDetails' data used in
 *  - BusinessDetailsState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */

export interface AccountDetailsState {
  loaded: boolean; // has the LendingNeeds list been loaded
  error?: any; // last none error (if any)
  loading: boolean;
  taskInfo: any;
  response?: any;
  errors?: any;
  formData?: any;
}

export interface AccountDetailsPartialState {
  readonly [PPP_LOAN_INFO_FEATURE_KEY]: AccountDetailsState;
}

export const initialState: AccountDetailsState = {
  loaded: false,
  loading: false,
  taskInfo: null,
  response: null
  
};

export function reducer(
  state: AccountDetailsState = initialState,
  action: AccountDetailsAction
): AccountDetailsState {
  switch (action.type) {
  
    case AccountDetailsActionTypes.LoadAccountDetails:
      {
        state = {
          ...state,
          response: null,
          loading: true
        };
      }
      break;
    case AccountDetailsActionTypes.AccountDetailsLoaded:
      {
        state = {
          ...state,
          taskInfo: action.payload,
          response: null,
          loaded: true
        };
      }
      break;
    case AccountDetailsActionTypes.AccountDetailsFormSubmit: {
      state = {
        ...state,
        loaded: false,
        loading: true,
        formData: action.payload
      };
    }
      break;
    case AccountDetailsActionTypes.AccountDetailsFormCompleted: {
      state = {
        ...state,
        loaded: true,
        loading: false,
        response: action.payload,
        taskInfo:null
      };
    }
      break;
      case AccountDetailsActionTypes.AccountDetailsFormReset: {
        state = {
          ...state,
          loaded: false,
          loading: false,
          response: null,
          taskInfo:null
        };
      }
        break;
    case AccountDetailsActionTypes.AccountDetailsLoadError: {
      state = {
        ...state,
        loaded: false,
        loading: false,
        errors: action.payload
      };
    }
  }
  return state;
}
