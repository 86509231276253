import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx'; 

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  constructor() { }
  showPdf(data, fileName: string ) {

    let pdfdata = data;

    if (window.navigator.msSaveBlob) { // // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
        const b64toBlob = (b64Data = pdfdata, contentType = 'application/pdf', sliceSize = 512) => {
            const byteCharacters = atob(b64Data);

            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                const slice = byteCharacters.slice(offset, offset + sliceSize);

                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    try {
                        byteNumbers[i] = slice.charCodeAt(i);
                    }
                    catch (e) {
                    }
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }

            const blob = new Blob(byteArrays, { type: contentType });
            return blob;
        }
        const blob = b64toBlob(pdfdata, 'application/pdf');
            window.navigator.msSaveOrOpenBlob(blob, fileName);

    } else {
        const linkSource = 'data:application/pdf;base64,' + pdfdata;
        const downloadLink = document.createElement("a");
        // const fileName = "";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();

    }
}

exportexcel(id,fileName): void 
    {
       /* table id is passed over here */   
       let element = document.getElementById(id); 
       const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

       /* generate workbook and add the worksheet */
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

       /* save to file */
       XLSX.writeFile(wb, fileName);
			
    }
}
