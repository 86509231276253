import { Injectable } from '@angular/core';
import {
    HttpResponse,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { CommonService } from '../common/common.service';
import { map, catchError, finalize } from 'rxjs/operators';

@Injectable()
export class LoaderInterceptorService implements HttpInterceptor {
    private requests: HttpRequest<any>[] = [];
    constructor(private loaderService: CommonService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const loaderNotRequired=req.params.get('no_loader');
        if(!loaderNotRequired){
            this.loaderService.startLoad();
        }
        return next.handle(req)
                .pipe(
                    map((event: HttpResponse<any>) => {
                        // if (event instanceof HttpResponse) {
                        //     if(!loaderNotRequired){
                        //    this.loaderService.stopLoad();
                        //     }
                        // }
                        return event;
                    }), 
                    catchError(error => {
                        // if(!loaderNotRequired){

                        //         this.loaderService.stopLoad();
                        // }
                        return throwError(error);
                    }),
                    finalize(() => {
                        if (!loaderNotRequired) {
                            this.loaderService.stopLoad();
                        }
                    })
                );

            
                }
    }
