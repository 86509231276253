import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CheckVerifiedService {
  constructor(@Inject('environment') private environment) { }

  checkVerification(sessionData:any){
    if((this.cpaVerifiedCheckAllowed(sessionData) || (sessionData.user.firm_resubmission && sessionData.user?.cpa_verified != 'Y') )) {
      return true;
    }
    return false;
  }

  cpaVerifiedCheckAllowed(sessionData:any) {
    if(this.environment.cpa_verified_date && (!sessionData.user.skip_verified_login || sessionData.user.cpa_verified == "N")) {
      const created_time = isNaN(sessionData.user.created_at) ? new Date(sessionData.user.created_at).getTime()/1000 : +sessionData.user.created_at;
      if((created_time >= this.environment.cpa_verified_date || sessionData.user.skip_verified_login == true) && sessionData.user.cpa_verified !== 'Y' && sessionData.user.cpa_verified !== 'O') {
        return true;
      }
    }
  }

}
