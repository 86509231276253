export const EMAIL_TEMPLATE = [
    {
      "verify-user": {
        "send_otp_mail": "5e874c31e2c8e935d455d21c"
      }
    },
    {
      "verify-backend-user": {
        "send_backend_otp_mail": "5e874c31e2c8e935d455d21c"
      }
    },
    {
      "send-owners-mail": {
        "send_owner_consent": "5e874c31e2c8e935d455d21a"
      }
    },
    {
      "aicpa-create-affiliate-user": {
        "cpabusiness_registration_mail": "5f55d9130d0f8280c0787126"
      }
    },
    {
      "cpabusiness_registration_mail": {
        "cpabusiness_registration_mail": "5f55d9130d0f8280c0787126"
      }
    },
    {
      "aicpa-forgot-password": {
        "aicpa_user_reset_mail": "5f194b3603af04f66ef1c518"
      }
    },
    {
      "aicpa-user-verification": {
        "aicpa_user_verification_mail": "5ca49d66f593192f740d1621"
      }
    },
    {
      "documents-mail": {
        "send_documents_mail": "5f1949c420e028806469cbba",
        "send_free_documents_mail": "5f1949c420e028806469cbb6"
      }
    },
    {
      "acl-create-user": {
        "ppp2_aicpa_verification_mail_password": "5f5e04aee263e22764661776"
      }
    },
    {
      "buy-plan": {
        "send_plan_confirmation_mail": "5f55d9130d0f8280c0787124",
        "send_plan_support_mail": "5f55d9130d0f8280c0787125",
        "send_payment_confirmation_mail": "5f96cad7d7bc743ffcbdd093"
      }
    },
    {
      "ppp2-aicpa-user-verification": {
        "ppp2_aicpa_user_verification_mail": "5f5e04aee263e22764661773"
      }
    },
    {
      "resend-user-verification": {
        "resend_user_verification_mail": "5f5e04aee263e22764661773"
      }
    },
    {
      "ppp2-aicpa-forgot-password": {
        "ppp2_aicpa_user_reset_mail": "5f5e04aee263e22764661774"
      }
    },
    {
      "cpabusiness-documents-mail": {
        "cpabusiness_send_documents_mail": "5f5e04aee263e22764661775"
      }
    },
    {
      "cpabusiness-acl-create-user": {
        "ppp2_aicpa_verification_mail_password": "5f5e04aee263e22764661776"
      }
    },
    {
      "update-firm-name": {
        "plan_not_renewed": "61efec7f0941947e332a41b2"
      }
    },
    {
      "callback_ppp_app_lead": {
        "application_status_update_mail": "61a9060afa350145677402ed",
        "application_status_approved_mail": "5fdb4ef47ddd142ae45e1449",
        "application_status_funded_mail": "5fdb4ef47ddd142ae45e144a",
        "uw_case_note_mail": "606d9cfc1adacd51ec55447d",
        "application_previous_status_update_mail": "634e602c2e17d2cc5c1169a4",
        "first_loan_funded": "6368f1e41ef6f804bb245d31",
        "case_funded_wired": "6369e0321ef6f804bb245d33",
        "each_loan_condition_wired": "6369e0321ef6f804bb245d33",
        "uw_case_note_sba_mail": "63ef4e5ca7bdb2c38d118702",
        "application_rejected_status_update": "64fed5c79c55f6de6a930814",
        "application_previous_status_update": "634e602c2e17d2cc5c1169a4",
        "application_status_update_mail_tloan1": "6504a727d78f735a3185e431",
        "application_previous_status_update_mail_tloan": "6504a7166c2c17b4d77b3246",
        "first_loan_funded_tloan": "6504a7439cae5f5e0d6e960e",
        "each_loan_condition_wired_tloan": "6504a7591c2af35183ded410",
        "case_funded_wired_tloan": "6504a7591c2af35183ded410"
      }
    },
    {
      "buy-addon": {
        "cpa_addon_payment_confirmation": "5fabe77f61e8243aa4a91502",
        "send_addon_support_mail": "5fbe6b98d6f12eae34824271"
      }
    },
    {
      "pay-using-saved-card": {
        "cpa_addon_payment_confirmation": "5fabe77f61e8243aa4a91502",
        "send_addon_support_mail": "5fbe6b98d6f12eae34824271"
      }
    },
    {
      "thank_email_ppp": {
        "send_thank_email_ppp": "5fdb4ef47ddd142ae45e1447"
      }
    },
    {
      "pay-fee": {
        "send_plan_confirmation_mail": "5f55d9130d0f8280c0787124",
        "send_plan_support_mail": "5f55d9130d0f8280c0787125",
        "cpa_verification_process_mail": "6044e7a9d66ffb097857c1d5",
        "send_fee_payment_confirmation_mail": "604b5174d642dbb3f06b9ca6"
      }
    },
    {
      "verifier_verification_mail": {
        "cpabusiness_verification_mail": "5f55d9130d0f8280c0787126"
      }
    },
    {
      "assign_ppp_to_customer": {
        "submit_PPP_customer_mail": "60acab5a6ab21e472e05beee"
      }
    },
    {
      "financing-inquiry": {
        "financing_inquiry_mail": "61262597047f6817c081a20d"
      }
    },
    {
      "owner_consent_mail_term_loan": {
        "term_loan_owner_consent_mail": "618aa4cdc1523329444c33dc"
      }
    },
    {
      "thank_email_tl": {
        "send_thank_email_tl": "613aa4cdc1523329444c33dd"
      }
    },
    {
      "update_plan_opt_out_flag": {
        "plan_cancellation_mail": "6384bd8db7f6d158059ff24d"
      }
    },
    {
      "plan_payment_with_saved_card": {
        "auto_payment_failure_mail": "61efd5a70941947e332a41b1",
        "auto_payment_sucess_mail": "63bf949c2e1edde7eee9a34f"
      }
    },
    {
      "plan_create_and_payment": {
        "auto_payment_failure_mail": "61efd5a70941947e332a41b1",
        "auto_payment_sucess_mail": "63bf949c2e1edde7eee9a34f"
      }
    },
    {
      "plan_create_and_pay_now": {
        "send_plan_support_mail": "5f55d9130d0f8280c0787125",
        "send_payment_confirmation_mail": "5f96cad7d7bc743ffcbdd093",
        "send_plan_upgrade_confirmation_mail": "6363877506e17c30133642e7"
      }
    },
    {
      "plan_renew_same_day": {
        "plan_renew_same_day_mail": "61efc9ba0941947e332a41af"
      }
    },
    {
      "ertc-loan-inquiry": {
        "ertc_inquiry_mail": "628c725448507b5d23b8429f"
      }
    },
    {
      "inquiry-form": {
        "cms_inquiry_mail": "634572ab6e8a7dc2b7818e2a"
      }
    },
    {
      "create-inactive-user": {
        "user_request_mail": "62a98ac126b61e5067d82863"
      }
    },
    {
      "verifier_decline_mail": {
        "verification_decline_mail": "mail_type"
      }
    },
    {
      "direct_customer_assign_decline_mail": {
        "direct_customer_decline_mail": "mail_type"
      }
    },
    {
      "decline_mail": {
        "login_decline_mail": "mail_type"
      }
    },
    {
      "active_plan_mail": {
        "active_plan_code_mail": "mail_type"
      }
    },
    {
      "user-to-admin-notify": {
        "user_to_admin_notify_mail": "mail_type"
      }
    },
    {
      "get_verified_users_by_date": {
        "send_plan_select_mail": "63491aa7d6fd2877b74f1546"
      }
    },
    {
      "first_application_submitted": {
        "first_app_submission": "mail_type"
      }
    },
    {
      "admin-not-used-portal-mail": {
        "admin_not_used_portal_mail": "63732bf30cad16c5384c50f0"
      }
    },
    {
      "user-not-used-portal-mail": {
        "user_not_used_portal_mail": "63733b510cad16c5384c50f1"
      }
    },
    {
      "monthly-commision-summary-report": {
        "monthly_commision_summary_report_mail": "6375cded2e6ad708f6191e15"
      }
    },
    {
      "application-admin-lapse-mail": {
        "send_application_lapse_admin": "637b582e304bc6cbe34da747",
        "send_application_lapse_admin_without_commission": "6398165dbc407d1c07595a02"
      }
    },
    {
      "application-childuser-lapse-mail": {
        "send_application_lapse_child_user": "63882db0b7f6d158059ff250"
      }
    },
    {
      "send_renewal_reminder_31": {
        "send_renewal_reminder": "63a00818f6519a85f868b331"
      }
    },
    {
      "send_renewal_reminder_day_before": {
        "send_renewal_reminder_day_before": "63a014ac1933b50f3933498c"
      }
    },
    {
      "send_sba7a_waitlist_email": {
        "send_sba7a_announcement_mail": "63bdd683c49dd661408b1cc5"
      }
    },
    {
      "send_sba7a_activation_email": {
        "send_sba7a_activation_mail": "mail_type"
      }
    },
    {
      "sba-waitlist-submission": {
        "sba_waitlist_submission": "63b7c38ae590aaae11f62446"
      }
    },
    {
      "sba-lead-submit-mail": {
        "sba_lead_application_mail": "mail_type"
      }
    },
    {
      "owner-details": {
        "send_owner_consent_sba_cpa": "mail_type",
        "send_owner_consent_sba_cpa_new": "mail_type"
      }
    },
    {
      "request_owners_consent": {
        "send_owner_consent_sba_cpa": "mail_type"
      }
    },
    {
      "verify-owner-consent": {
        "consent_recived": "63ec9f44ebaba70a71d230f9"
      }
    },
    {
      "update-owner-detail": {
        "send_consent_request_to_additional_owners": "63dd41e5824df17495525734"
      }
    },
    {
      "verify-owner-consent-primary": {
        "consent_recived": "63ec9f44ebaba70a71d230f9"
      }
    },
    {
      "thank_email_sba": {
        "send_thank_email_sba": "63ecc9c5169a60037ab5a077"
      }
    },
    {
      "plan_create_pay_later": {
        "auto_sucess_mail": "63cfdeac5c701d490127757a"
      }
    },
    {
      "renewal_reminder_for_opt_out_users": {
        "renewal_reminder_for_opt_out_users": "63ec7f3924d21841bb962042"
      }
    },
    {
      "mid-way-survey-mail": {
        "send_mid_way_survey_mail_without_data": "63f5c0f28a072eb6791ef99b",
        "send_mid_way_survey_mail": "63f5c0ab3b5c3881a66c724e"
      }
    },
    {
      "pay_later_due_amount_payment": {
        "auto_payment_failure_mail": "61efd5a70941947e332a41b1",
        "due_payment_sucess_mail": "644b6cf4bfa53dc043428333"
      }
    },
    {
      "update-firm-profile-account-directory": {
        "update_cpa_firm_mail": "643527c2720a7de0e876d511"
      }
    },
    {
      "sba-loan-inquiry": {
        "sba_inquiry_mail": "649d427781eda21c948c8463"
      }
    },
    {
      "credit-consent-reminder-mail": {
        "send_consent_reminder_mail": "64f06ff72fa113a48a71a653"
      }
    }
]