<ng-container>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{modalTitle}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="onCancel()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <!-- <div class="fs16 fwm border-bottom pb-2 mb-4" *ngIf="actionType === 'Add Note'">
            Author Name: {{author_name}}
        </div>
        <div class="fs16 fwm border-bottom pb-2 mb-4" *ngIf="actionType === 'Connect To Customer'">
            Customer Name: {{customer_name}}
        </div>
        <div class="fs16 fwm border-bottom pb-2 mb-4" *ngIf="actionType === 'Send To Laserpro' || actionType === 'Credit Modification Completed' ">
            Are you sure ?
        </div> -->
        <form [formGroup]="actionForm" *ngIf="action_allowed && actionConfig && actionConfig.length > 0 && applicationData?.workflow_action?.config?.allowFormRender">
            <rubicon-dynamic [fields]="actionConfig" [group]="actionForm" [slug]="slug" class="row">
            </rubicon-dynamic>
        </form>
        <div role="alert" class="alert alert-danger errmsg" *ngIf="action_allowed == false">
            {{alert_message}}<br>
        </div>
        <div role="alert" class="alert alert-danger errmsg" *ngIf="showAlert">
            Application is incomplete. Please complete your application.<br>
        </div>
        <div role="alert" class="alert alert-danger errmsg" *ngIf="caf_generated">
            CAF is already generated, cannot change the loan product.<br>
        </div>
        <!-- <div role="alert" class="alert alert-danger errmsg" *ngIf="isCurrentStageInActive()">
            You do not have permission to perform this action.<br>
        </div> -->
        
    </div>
    <div class="modal-footer" *ngIf="action_allowed && applicationData?.workflow_action?.config?.action_buttons">
        <div class="col-12 col-md-12 col-lg-12 text-right pr-lg-2">
        <button type="button" *ngFor="let action_button of applicationData?.workflow_action?.config?.action_buttons" [ngClass]="action_button.class || 'btn btn-primary mr-3'" (click)="onActionBtnClick(action_button)">{{action_button.value}}</button>
            <!-- <button type="button" class="btn btn-default btn-lg ml-3" (click)="onCancel()">Cancel</button>
            <button *ngIf="actionType === 'Send To Laserpro' || actionType === 'Credit Modification Completed' " type="button" class="btn btn-default btn-lg ml-3" (click)="onSubmit()">Submit</button>
            <button *ngIf="actionType === 'Send to Capital Stream'" type="button" class="btn btn-default btn-lg ml-3" (click)="sendToCapStream()">Send</button>
            <button *ngIf="actionType === 'Assign To Next Stage'" type="button" class="btn btn-primary btn-lg ml-3" (click)="onSubmit('continue')">Assign</button>
            <button *ngIf="actionType !== 'Send To Laserpro' && actionType !== 'Assign To Next Stage' && actionType !== 'Credit Modification Completed' && actionType !== 'Send to Capital Stream' && actionType!=='Update Status'" type="button" class="btn btn-primary btn-lg ml-3" (click)="onSubmit('continue')" [disabled]="showAlert || caf_generated">Update</button>
            <button *ngIf="actionType === 'Update Status'" type="button" class="btn btn-primary btn-lg ml-3"  (click)="onSubmit('continue')">Update</button> -->

        </div>
    </div>
    
</ng-container>

<div class="row" *ngIf="actionForm?.value?.decline_reason" style="display: none !important;" #declineLetter>
    <decline-letter [declineReason]="actionForm?.value?.decline_reason" [appData]="applicationData" [states]="states"></decline-letter>
</div>

<ng-template #stageConfirmModalTemplate>
    <div class="modal-header pl-3">
        <h4 class="modal-title pull-left" [innerHTML]="'next_stage_confirmation.header' | translate">
        </h4>
    </div>
    <div class="modal-body pb-0">
        <div class="fs18 pb-2" [innerHTML]="'next_stage_confirmation.body' | translate">
        </div>
    </div>
    <div class="modal-footer">
        <div class="pl-1">
            <button class="btn btn-default btn-md ml-3 mb-2" (click)="onCancelConfirmation()">{{"next_stage_confirmation.cancelBtn" | translate }}</button>
            <button class="btn btn-primary btn-md ml-3 mb-2" (click)="onConfirmationStageStatus()">{{"next_stage_confirmation.confirmBtn" | translate }}</button>
        </div>
    </div>
</ng-template>