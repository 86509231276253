import { Component, OnInit } from '@angular/core';
import { FormFieldInterface } from '../../../interfaces/FormFieldInterafce';
import { FormGroup, FormArray, FormControl } from '@angular/forms';
import { CommonService, FormGenerateService } from '@rubicon/utils';

@Component({
  selector: 'rubicon-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {

  field: FormFieldInterface;
  group: FormGroup;
  slug: string;
  indexVal: number;
  constructor(private common: CommonService, private formGenerate: FormGenerateService) { }

  ngOnInit() {
    this.indexVal = new Date().getTime();
    this.common.masterData
      .subscribe((data: any) => {
        if (data && data[this.field.name]) {
          this.field.options = data[this.field.name].data.data;
        }
      });
  }

  /**
   * function to set checkbox values
   * @param event 
   */
  onChange(id: string) {
    const invalidFields = this.formGenerate.findInvalidControls(this.group);
    const formArray: FormArray = this.group.get(this.field.name) as FormArray;
    formArray.markAsDirty();
    if (formArray.value.includes(id)) {
      formArray.removeAt(formArray.value.indexOf(id));
    } else {
      formArray.push(new FormControl(id));
    }
    for (const val of invalidFields) {
      if (val.name !== this.field.name) {
        this.group.get(val.name).setErrors(val.errors);
      }
    }
  }

}
