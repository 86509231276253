<div *ngIf="!consentAction">
    <!-- <div *ngIf="showIcon('refreshIcon', owner)" tooltip="Refresh" (click)="onClick($event, 'refresh')"
        class="refreshIcon  mr-2 d-inline-block"></div> -->
    <div class="editIcon2" tooltip="Edit" *ngIf="showIcon('editIcon', owner)" (click)="onClick($event, 'edit')">&nbsp;
    </div>
    <div class="dropleft position-static">
        <div class="threeDote" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
            *ngIf="showIcon('deleteIcon', owner) || showIcon('sendIcon', owner) || showIcon('resendIcon', owner) || showIcon('businessOwnerShipPercentageIcon',owner)">
            <span class="dote"></span>
            <span class="dote"></span>
            <span class="dote"></span>
        </div>
        <div class="dropdown-menu">
            <ng-container>
                <a class="dropdown-item flex cp" *ngIf="showIcon('deleteIcon', owner)"
                    (click)="onClick($event, 'delete')"><span
                        class="deleteIcon2 d-inline-block align-top"></span>Delete</a>
                <div class="dropdown-divider my-0" *ngIf="showIcon('deleteIcon', owner)"></div>
                <a class="dropdown-item flex cp" *ngIf="showIcon('sendIcon', owner)"
                    (click)="onClick($event, 'consent')"><span class="sendConsentIcon d-inline-block align-top"></span>Send
                    Consent</a>
            </ng-container>
            <ng-container>
                <!-- <a class="dropdown-item flex" *ngIf="showIcon('refreshIcon', owner)" tooltip="Refresh"
                (click)="refresh(owner)">Refresh <div class="refreshIcon d-inline-block"></div></a> -->
                <a class="dropdown-item flex" *ngIf="showIcon('resendIcon', owner)" tooltip="Resend Consent Request"
                    (click)="onClick($event, 'resendConsent')"><div class="sendConsentIcon d-inline-block align-top mr-0"></div> Resend Consent</a>
                <a class="dropdown-item flex" *ngIf="showIcon('businessOwnerShipPercentageIcon',owner)"
                    tooltip="Business Ownership percentage" (click)="onClick($event, 'ownershipPercent')">
                    Ownership Structure <div class="busiorperIcon d-inline-block"></div>
                </a>
            </ng-container>
        </div>
    </div>
</div>
<div *ngIf="consentAction" class="fs14 text-right d-flex">
    <div class="fs12 fwm cp d-flex lh mt-1" *ngIf="(owner.consent === 'received')">
        <span tooltip="Confirmed" class="rightTickIcon d-block mr-1"></span>
        Confirmed
    </div>
    <div *ngIf="owner.consent === 'sent'" class="fs12 fwm cp d-flex lh mt-1 text-nowrap btc">
        <span tooltip="Request Sent" class="sentIcon d-block mr-1"></span>
        Sent
    </div>
    <ng-container>

        <div *ngIf="showIcon('pendingIcon', owner)" class="fs14 otc fwb cp d-flex">
            <span tooltip="Request Pending" class="sentIcon d-block mr-1"></span>Request Pending
        </div>
        <div *ngIf="showIcon('refreshIcon', owner)" tooltip="Refresh" (click)="onClick($event, 'refresh')"
            class="refreshIcon  ml-3 mt-1"></div>
        <div *ngIf="showIcon('resendIcon', owner)" tooltip="Resend Consent Request" class="sendConsentIcon ml-3 mt-1"
            (click)="onClick($event, 'resendConsent')"></div>
        <div *ngIf="showIcon('editIcon', owner)" tooltip="Edit Owner" class="editIcon2" (click)="onClick($event, 'edit')">
        </div>
        <div *ngIf="showIcon('deleteIcon', owner)" tooltip="Delete Owner" container="body" class="deleteIcon2 ml-3 addownerdelete"
            (click)="onClick($event, 'delete')"></div>

    </ng-container>
</div>