<ng-container *ngIf="group" [formGroup]="group">
    <ng-container *ngFor="let field of fields">
        <!-- Showing the fields if show-field key is present in the form -->
        <ng-container *ngIf="field.visible; else showField">
            <!-- Checking if the value is an array then iterating that value-->
            <!-- <div *ngIf = "checkArray(field.show_field.value); else stringshowField">
                 <div *ngFor = "let value_show of field.show_field.value"> -->
            <!-- {{field.visible | json}} -->
            <div [ngClass]="field?.css?.groupClass || 'form-group'" *ngIf="checkValue(group,field)"
                [ngClass]="field?.css && field?.css?.parent && field?.css?.parent[field.name] ? field?.css?.parent[field.name]:'col-12'">
                <ng-container rubiconField [field]="field" [group]="group" [slug]="slug" [index]="index">
                </ng-container>    
            </div>
            <div *ngIf="field.css && field.css.condition" class="clearfix w-100"></div>
            <!-- </div>    
            </div> -->
        </ng-container>

        <!-- If there is no show field in the form-->
        <ng-template #showField>
            <!-- {{ field.css.condition | json}} -->

            <div *ngIf="field?.css && field.css.condition" class="clearfix w-100">
                <!-- {{field.name}} -->
            </div>
            <div
                [ngClass]="[field?.css && field?.css?.parent && field?.css?.parent[field.name] ? field?.css?.parent[field.name]:'col-12', field?.css?.groupClass || 'form-group']">
                <ng-container rubiconField [field]="field" [group]="group" [slug]="slug" [index]="index">
                </ng-container>
            </div>
            
            <div *ngIf="field?.css?.connect_button" [ngClass]="field?.css?.connect_button_style || []">
                <a href="javascript:void(0);" id="payrollBtn" class="btn btn-primary fs14 mt-lg-0 my-3">+ Connect to Payroll Account</a>
            </div>

        </ng-template>
    </ng-container>
</ng-container>