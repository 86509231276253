import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { OwnerListService } from '../services/owner-list.service';

@Component({
  selector: 'ownership-tree-structure',
  templateUrl: './ownership-tree-structure.component.html',
  styleUrls: ['./ownership-tree-structure.component.scss']
})
export class OwnershipTreeStructureComponent implements OnInit {
  @Input() config : any;
  ownersList : any;
  owner_type : any;
  company_role : any;
  company_role_obj = {
    co_borrower : '63e9e42eebaba70a71d230f2'
  };
  constructor(
    private ownersListService: OwnerListService
  ) { }

  ngOnInit(): void {
    if(this.config.ownerData){
        let business = _.cloneDeep(this.config.businessData);
        this.owner_type = this.config.owner_type;
        this.company_role = this.config.company_role;
        this.ownersList = this.ownersListService.getOwnersForTreeStructure(business[0], this.config.ownerData, this.company_role_obj);
    }
  }

  getOwnerName(owner : any) {
   return owner.owner_type === 'individual' ? `${owner.first_name +' '+(owner.middle_name?owner.middle_name+' ':'')+ owner.last_name}` : owner.businessname;
  }

}
