import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SIGNUPSTATE_FEATURE_KEY, SignupState } from './signup-state.reducer';

// Lookup the 'SignupState' feature state managed by NgRx
const getSignupState = createFeatureSelector<SignupState>(
  SIGNUPSTATE_FEATURE_KEY
);

const getLoaded = createSelector(
  getSignupState,
  (state: SignupState) => state.loaded
);
const getError = createSelector(
  getSignupState,
  (state: SignupState) => state.errors
);

const getAllSignupState = createSelector(
  getSignupState,
  getLoaded,
  (state: SignupState, isLoaded) => {
    return (!state.response && isLoaded) ? state : null;
  }
);

const getSignupResponseState = createSelector(
  getSignupState,
  getLoaded,
  (state: SignupState) => {
    return state.response;
  }
);

export const signupStateQuery = {
  getLoaded,
  getError,
  getAllSignupState,
  getSignupResponseState
};
