import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldInterface } from '@rubicon/interface/form-field';
import { CommonService, JQueryService } from '@rubicon/utils_custom';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
  selector: 'rubicon-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss']
})
export class RadioComponent implements OnInit {

  field: FormFieldInterface;
  group: FormGroup;
  slug: string;
  indexVal: number;
  is_hidden: boolean;
  constructor(private common: CommonService) { }

  ngOnInit() {
    this.indexVal = new Date().getTime();
    this.common.masterData
      .subscribe((data: any) => {
        if (data && data[this.field.name] && data[this.field.name].data && data[this.field.name].data.data) {
          this.field.options = data[this.field.name].data.data;
        }
      });

    this.common.radioData.subscribe((data:any)=>{
        if(data.action === 'hide' && data.field_name === this.field.name ){
          this.is_hidden = true;
        }
        
        if(data.action === 'show'  &&  data.field_name === this.field.name){
          this.is_hidden = false;
        }

        if(data.action === 'option_add_custom_class' && data.field_name === this.field.name && data.field_option_type.length && data.field_option_class.length){
          this.field.options = this.field.options.map(opt=>{

            if(data.field_option_type === opt.type){
              if(opt['option_custom_class']) opt['option_custom_class'] = (opt['option_custom_class'] + ' ' +data.field_option_class)
              else opt['option_custom_class'] =  data.field_option_class;
            }
            return opt;
          })
        }
        
        if(data.action === 'option_remove_class' && data.field_name === this.field.name && data.field_option_type.length && data.field_option_class.length){
          this.field.options = this.field.options.map(opt=>{
            if(data.field_option_type === opt.type){
              let curr_classes =  opt['option_custom_class'].split(' ')
              opt['option_custom_class'] = curr_classes.filter(c => c !== data.field_option_class).join(' ');
            }
            return opt;
          })
        }
    })
  }
}

