<ng-container [formGroup]="group">
    <div [ngClass]="field.css.fieldCol? field.css.fieldCol:''">
        <div [ngClass]="field.css.labelClass? field.css.labelClass:''">
            <label class="p-label pb-2" [for]="field.name">{{slug + ".label." + field.name | translate}}</label>
        </div>
        <div class="col-lg-3" *ngIf="field.type !== 'range_percentage';">
            <div class="form-group">
                <fieldset>
                    <ng-container class="error-msg mt40"
                        *ngIf="group.get(field.name).invalid && (group.get(field.name).dirty || group.get(field.name).touched)">
                        <ng-container *ngFor="let validation of field.validations;">
                            <label class="error" *ngIf="group.get(field.name).hasError(validation.name)">
                                {{slug + ".errorMessage." + field.name + "." + validation.name | translate}}
                            </label>
                        </ng-container>
                        <label class="error" *ngIf="group.get(field.name).hasError('incorrect')">
                            {{slug + ".errorMessage." + field.name + ".incorrect" | translate}}
                        </label>                        
                    </ng-container>
                </fieldset>
            </div>
        </div>
    </div>
    <div class="row amountSlider">
        <div [ngClass]="(field?.css?.sliderClass)? field?.css?.sliderClass :'col-12 col-lg-10'">
            <ng5-slider [options]="newOptions" [(value)]="value" (userChange)="storeAmount(value)">
            </ng5-slider>
        </div>
        <div [ngClass]="(field?.css?.inputClass)? field?.css?.inputClass :'col-12 col-lg-2'" *ngIf="field.type === 'range_percentage';">
            <div class="form-group">
            <input [id]="field.name" class="form-control" type="text"
            [formControlName]="field.name" mask="0*" suffix=" %"
            (ngModelChange)="value=manualChangeAmount($event)" />
            </div>
            <ng-container class="error-msg mt40"
            *ngIf="group.get(field.name).invalid && (group.get(field.name).dirty || group.get(field.name).touched)">
            <ng-container *ngFor="let validation of field.validations;">
                <label class="error" *ngIf="group.get(field.name).hasError(validation.name)">
                    {{slug + ".errorMessage." + field.name + "." + validation.name | translate}}
                </label>
            </ng-container>
            <label class="error" *ngIf="group.get(field.name).hasError('incorrect')">
                {{slug + ".errorMessage." + field.name + ".incorrect" | translate}}
            </label>                        
        </ng-container>
            </div>
    </div>
</ng-container>