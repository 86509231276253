import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})

export class conditionHelperService {
  constructor() {
    // console.log("instance called.")
  }
  static compareOperators = {
    '==': (operandA: any, operandB: any) => (operandA == operandB),
    '!=': (operandA: any, operandB: any) => (operandA != operandB),
    '===': (operandA: any, operandB: any) => (operandA === operandB),
    '!==': (operandA: any, operandB: any) => (operandA !== operandB),
    '>=': (operandA: any, operandB: any) => (operandA >= operandB),
    '>': (operandA: any, operandB: any) => (operandA > operandB),
    '<=': (operandA: any, operandB: any) => (operandA <= operandB),
    '<': (operandA: any, operandB: any) => (operandA < operandB),
    'between': (operandA, operandB) => (operandA > operandB[0] && operandA < operandB[1]),
    'range': (operandA, operandB) => (operandA >= operandB[0] && operandA <= operandB[1]),
    "includes": (operandA, operandB) => { return operandA?.filter(e => e === operandB).length > 0 },
    "!includes": (operandA, operandB) => { return operandA?.filter(e => e === operandB).length === 0 },
    "inArray": (operandA, operandB) => { return operandB?.filter(e => e === operandA).length > 0 },
    "!inArray": (operandA, operandB) => { return operandB?.filter(e => e === operandA).length === 0 },
    "includesAll": (operandA, operandB) => { return operandB?.every(value => { return operandA?.includes(value); }); },
    "includesAny": (operandA, operandB) => { return operandB?.some(value => { return operandA?.includes(value); }); },
    "!includesAll": (operandA, operandB) => { return !operandB?.every(value => { return operandA?.includes(value); }) },
    "!includesAny": (operandA, operandB) => { return !operandB?.some(value => { return operandA?.includes(value); }) },
    "includesKeyValue": (operandA, operandB, obj) => { return operandA?.filter(e => _.get(e,obj.keyValue) === operandB).length > 0 },
    "includesKeyValueInArray": (operandA, operandB, obj) => { return operandA?.some(e => Array.isArray(_.get(e,obj.keyValue)) && _.get(e,obj.keyValue).includes(operandB)) },
    "!includesKeyValue": (operandA, operandB, obj) => { return operandA?.filter(e => _.get(e,obj.keyValue) == operandB).length > 0 ? false : true },
    "inKeyValue": (operandA, operandB, obj) => { return operandB?.some(value => operandA?.some((e)=>_.get(e,obj.keyValue) === value)) },
    '-': (operandA: any, operandB: any) => (operandA - operandB),
    'exists': (operandA: any, operandB: any) => ((operandA && typeof operandA == "object" && operandA?.hasOwnProperty(operandB)?true:false)),
    "hasValue": (operandA: any, operandB: any) => ((operandA && typeof operandA == "object" && _.get(operandA,operandB)?true:false)),
    'isArray': (operandA: any, operandB: any) => (Array.isArray(operandA)),
    '!isArray': (operandA: any, operandB: any) => (!Array.isArray(operandA)),
    'isNumber': (operandA: any) => (conditionHelperService.isNumber(operandA)),
    "includeObjectsKeyValue": (operandA, operandB, obj) => { return (Object.values(operandA || {})?.some(e => _.get(e,obj.keyValue) === operandB) || false)},
    "!includeObjectsKeyValue": (operandA, operandB, obj) => { return !(Object.values(operandA || {})?.some(e => _.get(e,obj.keyValue) === operandB) || false)},
    "!includeAllObjectsKeyValue": (operandA, operandB, obj) => { return !((Object.values(operandA)?.length > 0 && Object.values(operandA)?.every(e => _.get(e,obj.keyValue) === operandB)) || false)},
    "includeAllObjectsKeyValue": (operandA, operandB, obj) => { return ((Object.values(operandA)?.length > 0 && Object.values(operandA)?.every(e => _.get(e,obj.keyValue) === operandB)) || false)},
    "includeAllInArrayByKeyValue" : (operandA, operandB, obj) => { return (operandA?.every(value => { return _.get(value,obj.keyValue) == _.get(value,operandB) })|| false); }
  }

  static operatorConfig(formValues: any, obj: any) {
    if (obj.compare) {
      return this.compareConditionConfig(formValues, obj);
    } else if (obj.range) {
      return true;
    }
  }

  static compareConditionConfig(formValues: any, obj: any) {
    let operandA = this.isNumber(formValues?.[obj.formField]) ? Number(formValues?.[obj.formField]) : formValues?.[obj.formField];
    let operandB = this.isNumber(obj?.value) ? Number(obj?.value) : obj?.value;
    // console.log( this.compareOperators[obj?.compare]?.(operandA,operandB), `${formValues?.[obj.formField]} ${obj.compare} ${obj.value}` )
    return this.compareOperators[obj?.compare]?.(operandA, operandB, obj);
  }

  static isNumber(n: any) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  static conditionResolver = {
    "AND": (n) => n.filter(e => e.resolved === false).length === 0,
    "OR": (n) => n.filter(e => e.resolved === true).length > 0
  }

  static match(compareValue, matchValue, operand = "==", obj) {
    let operandA = this.isNumber(compareValue) ? Number(compareValue) : compareValue;
    let operandB = this.isNumber(matchValue) ? Number(matchValue) : matchValue;
    if (Array.isArray(operandA)) {
      operandA.forEach((el,i) => {
        operandA[i] = this.isNumber(el) ? Number(el) : el
      });
    }
    if (Array.isArray(operandB)) {
      operandB.forEach((el,i) => {
        operandB[i] = this.isNumber(el) ? Number(el) : el
      });
    }
    return this.compareOperators[operand]?.(operandA, operandB, obj);
  }

  static resolve(data, filter, conditionGroup = "AND", history=false, errorMessage = '') { // data object, array,  return 
    var resolvee = [];
    // console.log(data, filter, conditionGroup, history, errorMessage);
    // debugger;
    for (var i = 0; i < filter.length; i++) {
      if (filter[i]?.key === "AND" || filter[i]?.key === "OR") {
        resolvee.push(this.resolve(data, filter[i].benchmarkValue, filter[i]?.key, history, filter[i].errorMessage))
      } else {
        let benchmarkValue = filter[i]?.isBenchmarkValueDynamic ? _.get(data, filter[i]?.benchmarkValue) : filter[i]?.benchmarkValue;
        var res = this.match(_.get(data, filter[i]?.key), benchmarkValue, filter[i]?.operand, filter[i])
        var obj = {}
        obj["resolved"] = res;
        if (history) {
          obj[filter[i]["key"]] = _.get(data, filter[i]?.key);
          obj["benchmarkValue"] = benchmarkValue;
          obj["operand"] = (filter[i]?.operand) ? filter[i]?.operand : "==";
        }
        resolvee.push(obj)
      }
    }
    if(history){
      return {
        "resolved": this.conditionResolver[conditionGroup]?.(resolvee),
        "conditionType": conditionGroup,
        "status": resolvee,
        "errorMessage": errorMessage
      }
    }
    else return { "resolved": this.conditionResolver[conditionGroup]?.(resolvee) }
  }


}