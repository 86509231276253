import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sba-footer',
  templateUrl: './sba-footer.component.html',
  styleUrls: ['./sba-footer.component.scss']
})
export class SbaFooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
