<ng-container [formGroup]="group">
    <ng-container *ngIf="field?.css?.floatingLabel; else normal">
        <div [ngClass]="(field?.css?.floatingLabelClass)? field?.css?.floatingLabelClass :'floating-label'">
            <input type="text" placeholder="&nbsp;" [ngClass]="(field?.css?.field)? field?.css?.field :'form-control'"
            [formControlName]="field.name" [id]="field.name + '_' + indexVal" [mask]="decimal_places ? 'separator.' + decimal_places : 'separator'" thousandSeparator=",">

            <span class="highlight"></span>
            <label [ngClass]="(field?.css?.labelClass)? field?.css?.labelClass :'labelText'"
                [for]="field.name + '_' + indexVal">{{slug + ".label." + field.name | translate}}</label>
                <ng-container *ngIf="field?.css?.tooltip && field?.css?.tooltip[field.name]"><!--
                --><a  class='que_info' placement="right" [ngbTooltip]="tipContent"><!----></a><!--​
                --><ng-template #tipContent><span [innerHTML]='slug + ".tooltip."+ field.name | translate'></span></ng-template><!--
                --></ng-container>

            <div *ngIf="group.get(field.name).invalid && (group.get(field.name).dirty || group.get(field.name).touched)"
                class="error-msg">
                <ng-container *ngFor="let validation of field.validations;">
                    <label class="error" *ngIf="group.get(field.name).hasError(validation.name)">
                        {{slug + ".errorMessage." + field.name + "." + validation.name | translate}}
                        {{validation.name==="max"? (group.get(field.name).errors.max.max | currency: 'USD' : 'symbol' : '1.0-0' )+".":""}}
                    </label>
                </ng-container>
            </div>
        </div>
    </ng-container>
    <ng-template #normal>
        <div class="col">
            <div [ngClass]="(field?.css?.labelClass)? field?.css?.labelClass :'fs14 fwsb mb-0'">
                {{slug + ".label." + field.name | translate}}</div>
        </div>
        <div class="col-12">
            <input class="form-control fs22 fwb" rubiconNumber type="text" [id]="field.name + '_' + indexVal"
                [formControlName]="field.name" [placeholder]="slug + '.placeholder.' + field.name | translate">
        </div>
        <div *ngIf="group.get(field.name).invalid && (group.get(field.name).dirty || group.get(field.name).touched)"
            class="error-msg">
            <ng-container *ngFor="let validation of field.validations;">
                <label class="error" *ngIf="group.get(field.name).hasError(validation.name)">
                    {{slug + ".errorMessage." + field.name + "." + validation.name | translate}}
                    {{validation.name==="max"? (group.get(field.name).errors.max.max | currency: 'USD' : 'symbol' : '1.0-0' )+".":""}}
                </label>
            </ng-container>
        </div>
    </ng-template>

</ng-container>