<ng-container [formGroup]="group">

    <ng-container *ngIf="field?.css?.floatingLabel else normal">

        <div [ngClass]="(field?.css?.labelParent)? field?.css?.labelParent :'floating-label'" >
                
            <input type="{{password_input_type}}" placeholder="&nbsp;" [autocomplete]="field.css?(field.css.autocomplete?field.css.autocomplete:'new-password'):'new-password'"
            [ngClass]="(field?.css?.field)? field?.css?.field :'form-control'" [formControlName]="field.name"
            [id]="field.name + '_' + indexVal"  [mask]="field.format?field.format.type:null" [thousandSeparator]="field.format&&field.format.delimiter?field.format.delimiter:''">

            <span class="highlight"></span>
            <label 
                [ngClass]="(field?.css?.labelClass)? field?.css?.labelClass :'labelText'" 
                [for]="field.name + '_' + indexVal">{{slug + ".label." + field.name | translate}}
            </label>
            <i  *ngIf="!field?.css?.passwordPolicyTooltip" [ngClass]="isPasswordVisible ? 'eye' : 'eye eyeclose'" (click)="showPassword(isPasswordVisible)">&nbsp;</i>
            <ng-container *ngIf="field?.css?.passwordPolicyTooltip">
                <button type="button" class="infoTip border-0" ngbdata-toggle="popover" ngbdata-html="true" ngbdata-placement="right"  
                [ngbPopover]="popContent2" triggers="mouseenter:mouseleave" [popoverTitle]="popContentTitle2">i</button>
                <ng-template #popContentTitle2><div class="fs12 fwsb">Password should have:</div></ng-template>
                <ng-template #popContent2>
                    <ul class="blueulList">
                        <li class="fs10">Minimum 8 characters and a maximum 50 characters</li>
                        <li class="fs10">Mix of uppercase alphabet, lowercase alphabet and digits</li>
                        <li class="fs10">Atleast one special character (only @, $, !, %, *, ? and & are
                            allowed)</li>
                        <li class="fs10">No spaces</li>
                    </ul></ng-template>
            </ng-container>
            <div *ngIf="group.get(field.name).invalid && (group.get(field.name).dirty || group.get(field.name).touched)"
                class="error-msg">
                <ng-container *ngFor="let validation of field.validations;">
                    <label class="error" *ngIf="group.get(field.name).hasError(validation.name)">
                        {{slug + ".errorMessage." + field.name + "." + validation.name | translate}}
                    </label>
                </ng-container>
                <label class="error" *ngIf="group.get(field.name).hasError('incorrect')">
                    {{slug + ".errorMessage." + field.name + ".incorrect" | translate}}
                </label>
            </div>
        </div>

    </ng-container>

    <ng-template #normal>
        <label *ngIf="slug + '.label.' + field.name | translate"
            [ngClass]="(field?.css?.labelClass)? field?.css?.labelClass :'secondary-label'"
            [for]="field.name + '_' + indexVal">
            {{slug + ".label." + field.name | translate}}
        </label>
        <input [type]="field.type" [placeholder]="slug + '.placeholder.' + field.name | translate" [ngClass]="(field?.css?.field)? field?.css?.field :'form-control'"
            [formControlName]="field.name" [id]="field.name + '_' + indexVal" [autocomplete]="field.css?(field.css.autocomplete?field.css.autocomplete:'new-password'):'new-password'">
            <button type="button" class="infoTip border-0" ngbdata-toggle="popover" ngbdata-html="true" ngbdata-placement="right"  
                        [ngbPopover]="popContent2" triggers="mouseenter:mouseleave" [popoverTitle]="popContentTitle2">i</button>
                        <ng-template #popContentTitle2><div class="fs12 fwsb">Password should have:</div></ng-template>
                        <ng-template #popContent2>
                            <ul class="blueulList">
                                <li class="fs10">Minimum 8 characters and a maximum 50 characters</li>
                                <li class="fs10">Mix of uppercase alphabet, lowercase alphabet and digits</li>
                                <li class="fs10">Atleast one special character (only @, $, !, %, *, ? and & are
                                    allowed)</li>
                                <li class="fs10">No spaces</li>
                            </ul></ng-template>
        <div class="passpolicy" #passpolicy [innerHTML]="slug+'.policy.'+field.name|translate">
        </div>
        <div *ngIf="group.get(field.name).invalid && (group.get(field.name).dirty || group.get(field.name).touched)"
            class="error-msg">
            <ng-container *ngFor="let validation of field.validations;">
                <label class="error" *ngIf="group.get(field.name).hasError(validation.name)">
                    {{slug + ".errorMessage." + field.name + "." + validation.name | translate}}
                </label>
            </ng-container>
        </div>
    </ng-template>
    

</ng-container>