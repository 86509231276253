import { TaskInfoService } from './task-info.service';
import { Injectable, Inject } from '@angular/core';

@Injectable({
    providedIn: 'root'
  })
export class MultiUserAppsService {
  
    constructor(private taskInfoService: TaskInfoService, @Inject('environment') private environment) { }

    isSingleUserMultiApps(){
        return this.environment.multiple_user_apps ? true: false;
    }
}