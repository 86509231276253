<ng-container [formGroup]="group">
<!-- <div [ngClass]="(field?.css?.wrapper)? field?.css?.wrapper :''"> -->
    <label *ngIf="slug + '.label.' + field.name | translate"
        [ngClass]="(field?.css?.labelClass)? field?.css?.labelClass :'p-label'"
        [for]="field.name + '_' + indexVal" [innerHTML]='slug + ".label." + field.name | translate'>
    </label>
    <ng-container *ngIf="field?.css?.tooltip && field?.css?.tooltip[field.name]">
        <a href="javascript:void(0)" [tooltip]="popTemplateth" [triggers]="(field?.css?.tooltip[field.name+ '_trigger'])?field?.css?.tooltip[field.name+ '_trigger']:'hover'"
        [ngClass]="field?.css?.tooltip[field.name]?field?.css?.tooltip[field.name]:'ml-1'">
          <img src="{{environment?.asset_path}}base/images/info-icon-n.svg" alt="tooltip"  class="infoimg" /> </a>
        <ng-template #popTemplateth>
         <div class="fs12 text-left fwr my-1 gtColor" [innerHTML]='slug + ".tooltip."+ field.name | translate'></div>
        </ng-template>
    </ng-container>
    <!-- <div [ngClass]="(field?.css?.inputAWrapClass)? field?.css?.inputAWrapClass :''"> -->
    <input type="text" *ngIf="!cashFlowAnalysisInput" [placeholder]="slug + '.placeholder.' + field.name | translate" [readonly]="field?.readonly"
    [allowNegativeNumbers]="field?.format?.allowNegativeNumbers || false"
    [ngClass]="(field?.css?.field)? field?.css?.field :'form-control'" [formControlName]="field.name"
    [id]="field.name + '_' + indexVal" [mask]="field.format?field.format.type:null"
    [maxlength]="(field?.format?.maxlength)?field.format.maxlength:null"
    [suffix]="(field?.format?.suffix)?field.format.suffix:''"
    [thousandSeparator]="field.format&&field.format.delimiter?field.format.delimiter:''"
    [dropSpecialCharacters]="field.format&&field.format.includeSpecialCharacters?(!field.format.includeSpecialCharacters):true"
    (focus)="showSearchList();" (focusout)="hideSearchList();"
    (input)="changeVal()"
    >


    <div class="{{slug+field.name + '_' + ngForIndex}}" *ngIf="cashFlowAnalysisInput" [ngClass]="(field?.css?.inputAWrapClass)? field?.css?.inputAWrapClass :'d-inline'">
    <input type="text" [placeholder]="slug + '.placeholder.' + field.name | translate" [readonly]="field?.readonly"
        [ngClass]="(field?.css?.field)? field?.css?.field :'form-control'" [formControlName]="field.name"
        [id]="field.name + '_' + indexVal" [mask]="field.format?field.format.type:null"
        [maxlength]="(field?.format?.maxlength)?field.format.maxlength:null"
        [dropSpecialCharacters]="field.format&&field.format.includeSpecialCharacters?(!field.format.includeSpecialCharacters):true"
        [thousandSeparator]="field.format&&field.format.delimiter?field.format.delimiter:''">
    </div>

    <div *ngIf="group.get(field.name).invalid && (group.get(field.name).dirty || group.get(field.name).touched)"
        class="error-msg" [ngClass]="(field?.css?.error)? field?.css?.error :''">
        <ng-container *ngFor="let validation of field.validations;">
            <label class="error" *ngIf="group.get(field.name).hasError(validation.name)">
                {{slug + ".errorMessage." + field.name + "." + validation.name | translate}}
            </label>
        </ng-container>
        <label class="error" *ngIf="group.get(field.name).hasError('incorrect')">
            {{slug + ".errorMessage." + field.name + ".incorrect" | translate}}
        </label>
    </div>
<!-- </div> -->

<!-- </div> -->
    <!--
        <div *ngIf="group.get(field.name).invalid && (group.get(field.name).dirty || group.get(field.name).touched)"
            class="error-msg">
            <ng-container *ngFor="let validation of field.validations;">
                <label class="error" *ngIf="group.get(field.name).hasError(validation.name)">
                    {{slug + ".errorMessage." + field.name + "." + validation.name | translate}}
                </label>
            </ng-container>
            <label class="error" *ngIf="group.get(field.name).hasError('incorrect')">
                {{slug + ".errorMessage." + field.name + ".incorrect" | translate}}
            </label>
        </div> -->
</ng-container>
<div class="search-result" *ngIf="autoData?.length > 0 && !group.get(field.name).hasError('required')" [class.d-none]="!show_search_list">
    <ul class="srList">
        <ng-container *ngFor="let data of field.autoCompleteList; let i = index">
            <ng-container *ngIf="data.best && data.best.company_name_info.company_name;else lender">
                <li (click)="getAddress(i)"><a>{{data.best.company_name_info.company_name}}</a></li>
            </ng-container>
            <ng-container *ngIf="data.naics_code">
                <li (click)="getNaicsCode(i)"><a>{{data.naics_code}} - {{data.value}}</a></li>
            </ng-container>
            <ng-template *ngIf="data.lender_name">
                <li (click)="getLender(i)"> <a>{{data.lender_name}} ({{data.city}})</a></li>
            </ng-template>
        </ng-container>
        <!-- <li *ngFor="let data of field.autoCompleteList; let i = index" (click)="getAddress(i)">
            <ng-container *ngIf="data.best && data.best.company_name_info.company_name;else lender">
                <a (click)="getAddress(i)">{{data.best.company_name_info.company_name}}</a>
            </ng-container>

            <ng-template #lender>
                <a (click)="getLender(i)">{{data.lender_name}}</a>
            </ng-template>
        </li> -->
    </ul>
</div>
