import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonService } from '@rubicon/utils_custom';

@Component({
  selector: 'sba-footer-v2',
  templateUrl: './sba-footer-v2.component.html',
  styleUrls: ['./sba-footer-v2.component.scss']
})
export class SbaFooterV2Component implements OnInit, OnDestroy {
  stepSubscription = new Subscription();
  step = 0;
  imagesFooter = [
    'assets/images/footerimg.svg',
    'assets/images/footerimg2.svg',
    'assets/images/footerimg3.svg'
  ]
  constructor(private common: CommonService, private changeDetectionRef: ChangeDetectorRef) { }

  ngOnInit(): void {

    this.stepSubscription = this.common.updatedStep
      .subscribe(data => {
        this.step = data;
        this.changeDetectionRef.detectChanges();
      });
  }


  ngOnDestroy(){
    this.stepSubscription.unsubscribe();
  }
}
