import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { BeneficiaryOwnerFeatureService } from './feature/beneficiary-owner.feature';
import { PlaygroundFeatureService } from './feature/playground.feature';
import { AccessValidatorService } from './feature/access-validator.service';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild()
  ],
  declarations: [
  ],
  exports: [
  ],
  providers:[BeneficiaryOwnerFeatureService, PlaygroundFeatureService, AccessValidatorService],
  entryComponents: []
})
export class CustomizationModule {}
