import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef, TemplateRef, Inject } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { KnockoutModalActionService } from '../../services/knockout-modal-action';
@Component({
  selector: 'knockout-modal-action',
  templateUrl: './knockout-modal-action.component.html'
})
export class KnockoutModalActionComponent implements OnInit {
  @Input() knockoutFilter;
  @Input() knockoutOn;
  modalRef: BsModalRef;
  declineConfirmModalRef: BsModalRef;
  form;
  modalCss;
  declineConfCssJson;
  modalSlug = "knockout_modal";
  declineConfirmModalSlug = "knockout_decline_confirm";

  @Output() modalClosed$ = new EventEmitter();
  @ViewChild("modalTemplate") modalTemplate: TemplateRef<any>;
  @ViewChild("declineConfirmModalTemplate") declineConfirmModalTemplate: TemplateRef<any>;
  default_knockout_modal = {
    "label":{
      "mdl_title": "&nbsp;",
      "cancel": "Cancel",
      "proceedWithFlag": "Continue with this loan application",
      "confirmBeforeDecline": "Quit this loan application",
      "declineWithoutConfirm": "Quit this loan application",
      "confirm_mdl_title": "Confirmation",
      "confirm_message": "This application will be closed and there will be no further actions. Are you sure you want to quit this loan application?",
      "confirm_cancel": "Cancel", 
      "confirm_decline": "Confirm"
    },
 } 

  
  constructor(public modalService: BsModalService,
    @Inject('cssList') private cssList,
    private modalActionService: KnockoutModalActionService) { }

  ngOnInit(): void {
    if(this.knockoutFilter?.config?.modalSlug){
      this.modalSlug = this.knockoutFilter?.config?.modalSlug;
    }
    this.modalCss = this.cssList[this.modalSlug];
    if(!this.modalCss){
      this.modalCss = {
        "cancel": "btn btn-default btn-md ml-3 mb-2",
        "proceedWithFlag": "btn btn-primary btn-md ml-3 mb-2",
        "confirmBeforeDecline": "btn btn-danger btn-md ml-3 mb-2",
        "declineWithoutConfirm": "btn btn-danger btn-md ml-3 mb-2",
        "confirm_cancel": "btn btn-default btn-md ml-3 mb-2", 
        "confirm_decline": "btn btn-danger btn-md ml-3 mb-2"
      }
    }
    this.declineConfCssJson = this.cssList[this.declineConfirmModalSlug];
  }

  ngAfterViewInit(){
    this.modalRef = this.modalService.show(this.modalTemplate, {
        id: 904,
        class: 'modal-lg knockoutModal',
        backdrop: 'static',
    });
    
    this.modalRef.onHidden.subscribe(data => {
        this.modalClosed$.emit();
    });
  }

  onButtonClick(button_id){
    this.modalActionService.resolveButtonClick(button_id, this.knockoutFilter);
  }

  closeModal(){
    this.modalRef.hide();
  }

  closeDeclineConfirmModal(){
    this.declineConfirmModalRef.hide();
  }

  openDeclineConfirmationModal(){
    this.declineConfirmModalRef = this.modalService.show(this.declineConfirmModalTemplate, {
      id: 905,
      class: 'modal-lg thankyouRegister',
      backdrop: 'static',
    });
  }

}
