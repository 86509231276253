import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ToasterModule } from 'angular2-toaster';
import { UtilsModule } from '@rubicon/utils';
import {​​​​​ NgxMaskModule, MaskPipe, IConfig  }​​​​​ from'ngx-mask'
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { DynamicFormModule } from '../../../dynamic-form';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { NgSelectModule } from '@ng-select/ng-select';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { LoanAmountPipe } from './pipes/loan-amount.pipe';
import  {DateTimezonePipe } from './pipes/date-timezone.pipe';
import { PrefixValuePipe } from './pipes/prefix-value.pipe';
import { CommaSeparatorPipe } from './pipes/comma-separator.pipe';
import { TreeConverterPipe } from './pipes/tree-conerter.pipe';
import { OrdinalPipe } from './pipes/ordinal.pipe';
import { FilterLevelPipe } from './pipes/filter-level.pipe';
import { DayLightSavingTimePipe } from './pipes/day-light-saving-time.pipe';

const maskConfig: Partial<IConfig> = {
  allowNegativeNumbers: true
};
@NgModule({
  declarations:[
     LoanAmountPipe,
     DateTimezonePipe,
     DayLightSavingTimePipe,
     PrefixValuePipe,
     CommaSeparatorPipe,
     TreeConverterPipe,
     OrdinalPipe,
     FilterLevelPipe],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    ToasterModule.forChild(),
    NgxMaskModule.forRoot(maskConfig),
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    PaginationModule.forRoot(),
    TabsModule.forRoot(),
    AccordionModule.forRoot(),
    DynamicFormModule,
    NgSelectModule
    ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    UtilsModule,
    ToasterModule,
    MaskPipe,
    DynamicFormModule,
    NgxMaskModule,
    BsDropdownModule,
    TooltipModule,
    PaginationModule,
    NgSelectModule,
    AccordionModule,
    TabsModule,
    LoanAmountPipe,
    DateTimezonePipe,
    DayLightSavingTimePipe,
    PrefixValuePipe,
    CommaSeparatorPipe,
    TreeConverterPipe,
    OrdinalPipe,
    FilterLevelPipe
    ]
  
})
export class SharedLazyModule {
  static forRoot(): ModuleWithProviders<SharedLazyModule> {
    return {
      ngModule: SharedLazyModule,
      providers: [ DayLightSavingTimePipe ]
    };
  }
}
