import { Component, OnInit, Output, EventEmitter, Inject, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { take, filter } from 'rxjs/operators';
import { Router,NavigationStart, Event as NavigationEvent   } from '@angular/router';
import { CommonService } from '@rubicon/utils';

@Component({
  selector: 'platform-core-skip-step',
  templateUrl: './skip-step.component.html'
})
export class SkipStepComponent implements OnInit {
  @Input() appConfig: any;
  @Input() businessID: any = false;
  @Output() buttonClicked: EventEmitter<string> = new EventEmitter;
  currentRoute: string;
  event$;
  buttonVisible: boolean = false;

  constructor(
    public store: Store<any>,
    public router: Router,
    @Inject('CONSTANTS') public CONSTANTS,
    @Inject('environment') public environment,
    public common: CommonService
  ) {
    this.currentRoute = router.url
  }

  ngOnInit(): void {
    if(this.appConfig?.skip === true && this.environment?.journeyType === "customer-journey"){
      this.buttonVisible = true;
    }
    else if(this.appConfig?.force_skip === true){
      this.buttonVisible = true;
    }
    
  }

  onSkip() {
    if(this.buttonClicked.observers.length) this.buttonClicked.emit();
    else {
      if(this.businessID) this.common.navigate(this.appConfig?.redirects?.next, this.businessID)
      else this.common.navigate(this.appConfig?.redirects?.next)
    }
  }

}
