import { Injectable } from '@angular/core';
import { conditionHelperService, EvaluatorHelperService } from '../../../../../core-utils/biz2xPro-config-engine';
import { TaskInfoService } from '@rubicon/utils';
import { Subject, of, Observable, forkJoin, from, throwError } from 'rxjs';
import { catchError, concatMap, map, reduce, switchMap, take, takeUntil, mergeMap } from 'rxjs/operators';
import * as _ from 'lodash';
import { UpdateStatusService } from './update-status.service';
import { WorkflowActionsService } from './workflow-actions.service';
import { DateTimezonePipe } from '@rubicon/libraries/core-utils/shared-lazy';
import { select, Store } from '@ngrx/store';
import { DynamicModalActionService } from '../dynamic-modal';
import { ManageLoansService } from './manage-loans.service';
import { TaskTrackerService } from './task-tracker.service';
export interface ActionStatus {
    action_type: string, resolved: boolean, error: boolean
}
export interface KeyActionStatus {
    [key: string]: ActionStatus
}

interface TypeKeyActionStatusObservable {
    [type: string]: Observable<KeyActionStatus>
}

export interface TypeKeyActionStatus {
    [type: string]: KeyActionStatus
}



@Injectable({
    providedIn: "root"
})
export class ActionResolveService {
    cacheEvaluateKeyConfig = {};
    userData;
    constructor(private taskInfoService: TaskInfoService,
        private WorkflowActionsService: WorkflowActionsService,
        private dateTimezonePipe: DateTimezonePipe,
        private store: Store<any>,
        private updateStatusservice: UpdateStatusService,
        private manageLoansService: ManageLoansService,
        private taskTrackerService: TaskTrackerService,
        private evaluatorHelperService:EvaluatorHelperService,
        private dynamicModalActionService: DynamicModalActionService) {
        this.store.pipe(select('app')).subscribe(rootState => {
            this.userData = rootState?.userData;
        });
    }

    evaluateMultipleTypesAndAction(evaluate_key_types: string[], appData: any, actionDestroyed$: Subject<any>): Observable<TypeKeyActionStatus> {
        return from(evaluate_key_types).pipe(
            mergeMap((type)=>{
                if (this.cacheEvaluateKeyConfig[type]) {
                    let evaluateKeyConfig = this.cacheEvaluateKeyConfig[type];
                    let actionResolver: Observable<KeyActionStatus> = this.evaluateKeyConfigAction(evaluateKeyConfig, appData, actionDestroyed$);
                    return actionResolver.pipe(
                        map((typeResponse)=>{
                            return {type, typeResponse}
                        })
                    );
                } else {
                    return this.taskInfoService.getTaskInfo({ slug: "evaluate_key_master", type }).pipe(
                    switchMap((res) => {
                        let evaluateKeyConfig = res?.response_data?.evaluate_key?.data?.data?.[0];
                        if (evaluateKeyConfig?.type == type) {
                            this.cacheEvaluateKeyConfig[type] = evaluateKeyConfig;
                            let actionResolver: Observable<KeyActionStatus> = this.evaluateKeyConfigAction(evaluateKeyConfig, appData, actionDestroyed$);
                            return actionResolver.pipe(
                                map((typeResponse)=>{
                                    return {type, typeResponse}
                                })
                            );
                        }
                        return of({type, typeResponse:{error: true}});
                    }),
                    catchError((error)=>{
                        return of({type, typeResponse:{error: true}});
                    }));
                }
            }),
            reduce((acc: any, curr: any) => {
                acc[curr.type] = curr.typeResponse;
                return acc;
            }, {})
        )
    }

    evaluateKeyConfigAction(evaluateKeyConfig, appData, actionDestroyed$: Subject<any>): Observable<KeyActionStatus> {
        if (evaluateKeyConfig.action_to_perform) {
            let evaluatedValuesApi = of({});
            if (evaluateKeyConfig.evaluate_keys) {
                evaluatedValuesApi = this.evaluatorHelperService.getEvaluatedData(appData, evaluateKeyConfig.evaluate_keys);
            }
            return evaluatedValuesApi.pipe(
                switchMap((evaluatedValues)=>{
                    return this.resolveAction(evaluateKeyConfig.action_to_perform, { appData, evaluatedValues }, actionDestroyed$);
                }));
        }
        return of({});
    }

    resolveAction(action_to_perform, data, actionDestroyed$: Subject<any>): Observable<KeyActionStatus> {
        if (typeof action_to_perform?.length) {
            let actionResponses = {};
            let actionQueueResolver$ = from(action_to_perform).pipe(
                concatMap((actionConfig: any) => {
                    let action_type = actionConfig?.action_type;
                    data.actionResponses = actionResponses;
                    let action_key = actionConfig?.action_key;
                    let isActionValid = (actionConfig?.action_validate?.length) ? conditionHelperService.resolve(data, actionConfig.action_validate).resolved : true;
                    if (isActionValid) {
                        return this.onAction(action_type, actionConfig, data, actionDestroyed$);
                    }
                    return of({ action_type, action_key, resolved: false, error: false, isActionValid });
                })
            );
            return actionQueueResolver$.pipe(
                reduce((acc: any, curr: any) => {
                    acc[curr.action_key] = curr;
                    actionResponses[curr.action_key] = curr;
                    return acc;
                }, {})
            );
        }
        return of({});
    }

    onAction(action_type: string, actionConfig, data: any, actionDestroyed$: Subject<any>) {
        let action_key = actionConfig.action_key;
        switch (action_type) {
            case "save_task_info":
                let query = {};
                for (let key in actionConfig?.query) {
                    if (typeof actionConfig?.query?.[key] == "object") {
                        query[key] = _.get(data, actionConfig?.query?.[key]?.key);
                    } else {
                        query[key] = actionConfig?.query?.[key];
                    }
                }
                let body = {};
                for (let key in actionConfig?.body) {
                    if (typeof actionConfig?.body?.[key] == "object") {
                        let value = _.get(data, actionConfig?.body?.[key]?.key);
                        if(actionConfig?.body?.[key]?.keyDynamic){
                            body[_.get(data, actionConfig?.body?.[key]?.keyDynamic)] = value;
                        }else{
                            body[key] = value;
                        }
                    } else {
                        body[key] = actionConfig?.body?.[key];
                    }
                }
                return this.taskInfoService.saveTaskInfo(query, body).pipe(
                    map((response) => {
                        return { action_type, action_key, resolved: true, error: false, isActionValid: true, response };
                    }),
                    catchError((response) => {
                        return of({ action_type, action_key, resolved: false, error: true, isActionValid: true, response });
                    }));
            case "update_status":
                return this.store.pipe(select('app'), take(1), takeUntil(actionDestroyed$)).pipe(
                    switchMap((rootState => {
                        let appData = data.appData;
                        let appStoreData = rootState?.appData;
                        let app_sub_statuses: any = this.WorkflowActionsService.app_sub_status;
                        let currentSubStatus = app_sub_statuses?.find(res => res.id === actionConfig?.next_sub_status_id);
                        if (currentSubStatus) {
                            if (typeof actionConfig?.status_config == "object") {
                                currentSubStatus = { ...currentSubStatus, config: currentSubStatus.config ? { ...currentSubStatus.config, ...actionConfig.status_config } : actionConfig.status_config }
                            }
                            this.taskTrackerService.initAppTask(appData?._id);
                            return this.updateStatusservice.updateSubStatus(currentSubStatus, actionConfig?.formData || {}, { appId: appData?._id, userId: appData?.user_id, ...appData, ...appStoreData, actionConfig }, {}, this.dateTimezonePipe).pipe(
                                switchMap((response: any) => {
                                    if (!response?.error) {
                                        this.taskTrackerService.destroyAppTask(appData?._id);
                                        return of({ action_type, action_key, resolved: true, error: false, isActionValid: true, response });
                                    }
                                    return throwError(response);
                                }),
                                catchError((response: any) => {
                                    this.taskTrackerService.destroyAppTask(appData?._id);
                                    return of({ action_type, action_key, resolved: false, error: true, isActionValid: true, response });
                                }));
                        } else {
                            return of({ action_type, action_key, resolved: false, error: false, isActionValid: true });
                        }
                    })));
            case "open_modal":
                let modalActionCompleted$ = new Subject();
                this.dynamicModalActionService.openModal(actionConfig, actionConfig.config, modalActionCompleted$);
                if(actionConfig?.config?.wait_for_modal_action){
                    return modalActionCompleted$.pipe(
                        map(response=>{
                            return { action_type, action_key, resolved: true, error: false, isActionValid: true, response };
                        }),
                        catchError((response)=>{
                            return of({ action_type, action_key, resolved: false, error: true, isActionValid: true, response });
                        })
                    );
                }else{
                    modalActionCompleted$.complete();
                    return of({ action_type, action_key, resolved: true, error: false, isActionValid: true, response:{} });
                }
            case "event_changeAssignTo":
                this.manageLoansService.changeAssignTo(true);
                return of({ action_type, action_key, resolved: true, error: false, isActionValid: true, response:{} });
            case "event_updateAppSummary":
                this.manageLoansService.updateAppSummary(true);
                return of({ action_type, action_key, resolved: true, error: false, isActionValid: true, response:{} });
            default:
                return of({ action_type, action_key, resolved: false, error: false, isActionValid: true });
        }
    }

}