<ng-container *ngIf="!additionalOwnerOpen && config?.showTopLevel">
<div class="col-12 mt-4 gridView">
  <div class="row align-items-center headRow border tableHeadWrap">
    <div class="col col-md-6 col-lg-5 py-2 py-sm-3">
      <div class="title fs16 fwsb ffr stc lh37 headTitle" [innerHTML]="'owner-list.html.tableHeading' | translate"></div>
    </div>
    <div class="col-auto col-md-6 col-lg-7 text-right py-2 py-sm-3">
        <div class="btn-group commonDrop onerListDropdown1" dropdown>
          <button id="button-animated" dropdownToggle type="button" class="btn btn-primary btn-md"
            aria-controls="dropdown-animated" placement="bottom right"> <span [innerHTML]="'owner-list.html.addRow' | translate"></span>
          </button>
          <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu dropdown-menu-right " role="menu"
            aria-labelledby="button-animated" style="min-width: 224px;">
            <ng-container *ngFor="let roles of company_role_new">
              <li *ngIf="roles?.id !== company_role_obj.co_borrower && config?.componentType === 'owner-verification' ;"><a
                  class="dropdown-item cp" (click)="addOwner(roles?.id)">Add {{roles?.value}}</a></li>
              <li *ngIf="config?.componentType !== 'owner-verification'"><a class="dropdown-item cp"
                  (click)="addOwner(roles?.id)">Add {{roles?.value}}</a></li>
            </ng-container>
          </ul>
        </div>
        <div class="ml-2 ml-lg-4 d-inline-block" tooltip="Company Organization Chart">
              <i class="treeIcon"  (click)="viewTreeStructure()">&nbsp;</i>
          </div>
        <div tooltip="Refresh" (click)="refreshPage()" class="refreshIcon ml-2 ml-lg-4 d-inline-block align-middle"></div>
    </div>
  </div>
<div class="row contentRow">
  <div class="col-12 px-0 agGridWrap">
      <!-- AG_GRID -->
      <ag-grid-angular
          id="myGrid"
          #agGrid
          style="width: 100%; height: 100%"
          class="ag-theme-balham"
          [rowData]="ownerData"
          [columnDefs]="columnDefs"
          [domLayout]="domLayout"
          (gridReady)="onGridReady($event)"
          [overlayNoRowsTemplate]="noRowsTemplate"
          [gridOptions]="gridOptions">
      </ag-grid-angular>
  </div>
</div>
</div>
<div class="row mb-5" *ngIf="showActions() && showIcon('addOwnerInBottom')">
  <div class="col-12 col-md-12 col-lg-9 col-xl-9 pt-2 mt-1" *ngIf="showOwnerhipTooltip">
    <div class="fs12 btc"><span class="fwsb">Please note:</span> 100% ownership participation must be reflected to process your credit requested.</div>
  </div>
</div>
</ng-container>
<ng-container *ngIf="!additionalOwnerOpen && !config?.showTopLevel">
<div class="gbtblr col-12" *ngIf="config?.showCompanyDetails">
  <div class="row">
    <div class="col-12 col-sm-6 col-lg-6 greenbg">
      <div class="fs16 fwm ffr wtc py-2 my-1"> Company Details</div>
    </div>
    <div class="col-12 col-sm-6 col-lg-6 text-right greenbg">
      <div class="d-flex justify-content-end eyeTooltip">
        <ng-container>
          <div class="btn-group mt-2 mr-3 commonDrop onerListDropdown2" dropdown *ngIf="(config?.journey != 'banker' || (config?.journey === 'banker' && is_authorized_banker)) && showActions() && showIcon('addOwnerInCompanyDetails')">
            <a id="button-animated" dropdownToggle type="button"
              class="btn btn-default dropdown-toggle btn-md wtc wbc mr-1" aria-controls="dropdown-animated"
              placement="bottom right">Add Owners<span class="caret"></span>
            </a>
            <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
              aria-labelledby="button-animated">
              <ng-container *ngFor="let roles of company_role_new">
                <li *ngIf="(config?.journey === 'banker' || roles?.id !== company_role_obj.co_borrower)"><a
                    class="dropdown-item cp" (click)="addOwner(roles?.id, null ,true)">Add {{roles?.value}}</a></li>
              </ng-container>
            </ul>
          </div>
        </ng-container>
        <div class="eyeIcon viewTextEye" style="margin-top: 10px" tooltip="Company Organization Chart">
          <i class="treeIcon" style="position: static;" (click)="viewTreeStructure()">&nbsp;</i>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngFor="let business of businessData">
    <div class="row">
      <div class="col-12 col-md-6 col-lg-3 pr-0 py-3" [ngClass]="config.journey !== 'banker' ? 'col-xl-2' :'col-xl-2'">
        <div class="fs16 fwb ffr">{{business.business_name}}</div>
        <div class="fs12 ptc ffr mr-3 eyeIcon viewEyeInBusinessTax">
          Tax ID:
          {{business.app_biz_tax_id ? business.showhideSsnTaxId ?(business.app_biz_tax_id | mask: '00-0000000') : 'XX-XXXX'+business?.app_biz_tax_id.substring(6,10) : '-'}}
          <i [ngClass]="business.app_biz_tax_id ? business.showhideSsnTaxId ? 'eye': 'eye eyeclose' : ''"
            (click)="showhideSsnTaxId(business)">&nbsp;</i>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-2 py-3" *ngIf="config.journey === 'banker'"
        [ngClass]="config.journey !== 'banker' ? 'col-xl-4' :'col-xl-2'">
        <div class="fs12 ptc ffr">Company Status
          <a tooltip="Status with your Secretary of State">
            <img src="{{environment?.asset_path}}images/info-icon-n.svg" alt="tooltip"  class="infoimg" />
          </a>
        </div>
        <div class="fs14 fwb ffr otc">{{business.company_status| masterData:company_status:'id':'value'}}</div>
      </div>
      <div class="col-12 col-md-6 col-lg-2 py-3" [ngClass]="config.journey !== 'banker' ? 'col-xl-4' :'col-xl-2'">
        <div class="fs12 ptc ffr">Business Structure</div>
        <div class="fs14 fwb ffr">
          {{business.business_structure | masterData:business_structure_data:'id':'value'}}</div>
      </div>
      <div class="col-12 col-md-6 col-lg-2 col-xl-2 py-3">
        <div class="fs12 ptc ffr">
          {{config.journey === 'banker' ? 'Date Established or Incorporated' : 'Incorporated on'}}</div>
        <div class="fs14 fwb ffr">{{(business.business_incorporation_date | dateTimezone: 'MM-dd-yyyy')||'-'}}</div>
      </div>
      <div class="col-12 col-md-6 col-lg-2 col-xl-2 py-3" *ngIf="config.journey === 'banker'">
        <div class="fs12 ptc ffr">Business Phone Number</div>
        <div class="fs14 fwb ffr">{{(business.biz_phone_no | mask: '(000) 000-0000')|| '-'}}</div>
      </div>
    </div>
    <ng-container *ngIf="config.journey === 'banker'">
      <div class="row">
        <!-- <div class="col-12 col-md-6 col-lg-3 col-xl-2 pr-lg-0 py-3">
          <div class="fs12 ptc ffr">State of Incorporation/Organization</div>
          <div class="fs14 fwb ffr">{{(business.state_of_incorporation | masterData:state: 'id':'value') || "-"}}</div>
        </div> -->
        <!-- <div class="col-12 col-md-6 col-lg-3 col-xl-2 py-3">
          <div class="fs12 ptc ffr">Are you operating for less than 2 years?</div>
          <div class="fs14 fwb ffr">{{business.operating_years}}</div>
        </div> -->
        <!-- <div class="col-12 col-md-6 col-lg-3 col-xl-2 py-3">
          <div class="fs12 ptc ffr">Is the Applicant a</div>
          <div class="fs14 fwb ffr">{{(business?.is_applicant?.is_applicant_coopeartive)?"Cooperative":"" }} {{(business?.is_applicant?.is_applicant_esop)?"ESOP":"" }}  {{(business?.is_applicant?.is_applicant_401plan)?"401(k) Plan":"" }} {{(business?.is_applicant?.is_applicant_trust)?"Trust":"" }} {{(business?.is_applicant?.is_applicant_other)?"Other":"" }}  {{(business?.is_applicant?.is_applicant_na)?"NA":"" }}</div>
        </div>
        <div class="col-12 col-md-6 col-lg-3 col-xl-3 py-3">
          <div class="fs12 ptc ffr">Do you plan to use a 401(K) Plan (including a Rollover for Business Start Up (ROBS) Plan) for equity?</div>
          <div class="fs14 fwb ffr">{{business?.use_plan_for_equity}}</div>
        </div> -->
        <div class="col-12 col-md-6 col-lg-2 col-xl-2 py-3">
          <div class="fs12 ptc ffr">Are you Operating Company (OC) or Eligible Passive Company (EPC)?</div>
          <div class="fs14 fwb ffr">
              <!-- | masterData:applicant_business_name:'id':'type' -->
              {{((business.sba?.app_biz_legal_name) | masterData:applicant_business_name:'type':'value')||'-'}}</div>
        </div>
        <div class="col-12 col-md-6 col-lg-2 col-xl-2 py-3">
          <div class="fs12 ptc ffr">Is your OC or EPC a “For Profit“ Business?</div>
          <div class="fs14 fwb ffr">{{(business?.sba?.is_compony_for_profit)|| '-'}}</div>
        </div>
        <div class="col-12 col-md-6 col-lg-2 col-xl-2 py-3">
          <div class="fs12 ptc ffr">Does your Business have a Franchise, License, Dealer, Jobber, or Similar Agreement in place?</div>
          <div class="fs14 fwb ffr">{{business.franchise_check}}</div>
        </div>
        <div class="col-12 col-md-6 col-lg-2 col-xl-2 py-3" *ngIf="business.franchise_check == 'Yes'">
          <div class="fs12 ptc ffr">SBA Franchise Identifier Code:</div>
          <div class="fs14 fwb ffr">{{business.franchise_name}}</div>
        </div>
        <div class="col-12 col-md-6 col-lg-2 col-xl-2 py-3">
          <div class="fs12 ptc ffr">Industry/NAICS code</div>
          <div class="fs14 fwb ffr">
              <div class="fs14" *ngIf="business?.industry_naics">{{business?.industry_naics}}</div>
              <div class="fs14" *ngIf="!business?.industry_naics">{{business?.naics || ''}} - {{(( business?.sub_industry) | masterData:business_industry:'id':'value')||''}}</div>
          </div>
        </div>

      </div>
      <div class="row">
        <div class="col-12 col-md-6 col-lg-2 col-xl-2 py-3">
          <div class="fs12 ptc ffr">DBA or Trade Name</div>
          <div class="fs14 fwb ffr">{{(business.trade_name) || '-'}}</div>
        </div>
        <div class="col-12 col-md-6 col-lg-2 col-xl-2 py-3">
          <div class="fs12 ptc ffr">Business Address</div>
          <div class="fs14 fwb ffr">{{getAddress(business, 'primary_business')}}</div>
        </div>
        <div class="col-12 col-md-6 col-lg-2 col-xl-2 pr-0 py-3"  *ngIf="business?.business_structure === business_structure_obj.franchise">
          <div class="fs12 ptc ffr">Franchise Name</div>
          <div class="fs14 fwb ffr">{{businessDetails.franchise_check}}</div>
        </div>
        <!-- <div class="col-12 col-md-6 col-lg-3 col-xl-4 pr-0 py-3">
          <div class="fs12 ptc ffr">Industry</div>
          <div class="fs14 fwb ffr">{{(business.industry | masterData:business_industry:'id':'value')|| '-'}}</div>
        </div>
        <div class="col-12 col-md-6 col-lg-3 col-xl-3 py-3">
          <div class="fs12 ptc ffr">Sub Industry</div>
          <div class="fs14 fwb ffr">
            {{(( business.sub_industry) | masterData:sub_industry:'_id':'business_name')||'-'}}</div>
        </div> -->

      </div>
    </ng-container>
    <div class="row">
      <div class="col-12">
        <div class="gbb"></div>
      </div>
    </div>
  </ng-container>
</div>
<form [formGroup]="additional_owner_form" *ngIf="!config?.showBottomTableLevel;else bottomTableLevelTmp">
  <div class="pt-5" *ngIf="ownerData?.length">
    <accordion class="ownerListAccordion">
      <ng-container *ngFor="let owner of ownerData; let i = index">
        <accordion-group #accordion [isOpen]="true" [ngClass]="'levelwrap' + (owner.level)">
          <div accordion-heading>
            <div class="row">
              <div class="col-12 col-lg-8">
                <div class="d-flx">
                  <span class="switchWrap checWrap" *ngIf="config?.componentType==='owner-verification' && (!owner?.consent ||( owner.consent==='pending'))">
                    <label for="company" tooltip="Select to send consent request." class="el-switch el-switch-sm" for="ownerCheck{{i}}">
                      <input type="checkbox" class="form-check-input" id="ownerCheck{{i}}" [value]="i"
                        (change)="onCheckboxChange($event, i)" />
                      <label class="fs12 fwsb mt-1 clt pr-0 pl-4 mb-0" for="ownerCheck{{i}}">
                        <span class="label-text">&nbsp;</span>
                      </label>
                      <span class="el-switch-style"></span>
                    </label>
                  </span>
                  <span *ngIf="owner.company_role !== company_role_obj.co_borrower || owner.is_primary; else coBorrower"
                    class="d-inline-block fs16 fwm wtc pr" style="top:2px;">Level {{owner?.level}}:
                    {{owner?.ordinalCount | ordinal}} Owner of
                    {{ owner?.parent_id != 0 ? (config?.business_name && config?.componentType==='owner-verification') ? config?.business_name : (owner?.parent_id| masterData:ownerData:'_id':'businessname') : businessData[0]?.business_name}}</span>
                </div>
                <ng-template #coBorrower><span class="d-inline-block fs16 fwm wtc">Level {{owner?.level}}:
                    {{owner?.ordinalCount | ordinal}} Borrower Detail</span></ng-template>
              </div>
              <div class="col-12 col-lg-4 text-right">
                <ng-container>
                  <div class="btn-group commonDrop onerListDropdown3" dropdown *ngIf="(config?.journey != 'banker' || (config?.journey === 'banker' && is_authorized_banker)) && showActions() && showIcon('addOwnerInAccordian', owner)">
                    <a id="button-animated" (click)="$event.stopPropagation();" dropdownToggle type="button" index=1
                      class="btn btn-default dropdown-toggle btn-md wtc wbc mr-1" aria-controls="dropdown-animated"
                      placement="bottom right">Add Owners <span class="caret"></span>
                    </a>
                    <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
                      aria-labelledby="button-animated">
                      <ng-container *ngFor="let roles of company_role_new">
                        <li *ngIf="roles?.id !== company_role_obj.co_borrower"><a class="dropdown-item cp"
                            (click)="addOwner(roles?.id, owner)">Add {{roles?.value}}</a></li>
                      </ng-container>
                    </ul>
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="plusMinusIcon">
              <span *ngIf="accordion?.isOpen" class="plus">&#8722;</span>
              <span *ngIf="!accordion.isOpen" class="minus">&#43;</span>
            </div>
          </div>
          <div class="content px-3">
            <div class="row">
              <div class="col-12 col-md-6 col-lg-2 col-xl-2 pr-0 py-3">
                <div class="fs12 ptc ffr" *ngIf="owner.is_primary || owner?.owner_type !== 'corporate'">Full Name</div>
                <div class="fs12 ptc ffr" *ngIf="owner.is_secondary && owner?.owner_type == 'corporate'">Applicant Legal Name For Business
                </div>
                <div *ngIf="!owner.fullname" class="fs14 fwb ffr">{{owner.first_name}}
                  {{(owner.middle_name?owner.middle_name:'')}} {{owner.last_name}}</div>
                <div *ngIf="owner.fullname" class="fs14 fwb ffr">{{owner.fullname}}</div>
                <div *ngIf="owner.businessname" class="fs14 fwb ffr">{{owner.businessname}}</div>
              </div>
              <ng-container *ngIf="owner?.ownership!=undefined || owner?.ownership!=null">
                <div class="col-12 col-md-6 col-lg-2 col-xl-2 py-3 owncolwrap">
                  <div class="fs12 ptc ffr text-nowrap">Ownership (%)</div>
                  <div class="fs14 fwb ffr">{{owner.ownership}}%</div>
                </div>
              </ng-container>
              <div class="col-12 col-md-6 col-lg-2 col-xl-2 py-3 pr-2">
                <div class="fs12 ptc ffr pl-lg-0">Owner/Guarantor Type</div>
                <div *ngIf="owner.is_primary" class="fs14 fwb ffr pl-lg-0">
                  {{owner.company_role| masterData:company_role:'id':'value'}}</div>
                <div *ngIf="owner.is_secondary" class="fs14 fwb ffr pl-lg-0">
                  {{owner.owner_type| masterData:owner_type:'type':'value'}}</div>
              </div>
              <div class="col-12 col-md-6 col-lg-3 col-xl py-3">
                <div class="fs12 ptc ffr">
                  {{config.journey === 'banker' ? owner.owner_type === 'individual' ? 'Personal Address' : 'Business Address' : 'Mailing address' }}
                </div>
                <div class="fs14 fwb ffr">{{getAddress(owner)}}</div>
              </div>
              <div class="col-12 col-md-6 col-lg-3 col-xl-auto py-3 pl-lg-3 pl-xl-0">
                <div *ngIf="showActions()"
                  [ngClass]="config.journey === 'banker' ? 'pl-0 d-flex' :'pl-3 d-flex pl-lg-3 pl-xl-0 pt-2'">
                  <div class="fs13 fwm cp d-flex lh mt-1" *ngIf="(owner.consent === 'received')">
                    <span tooltip="Confirmed" class="rightTickIcon d-block mr-1"></span>
                    Confirmed
                  </div>
                  <ng-container>
                    <div *ngIf="showIcon('deleteIcon', owner)" tooltip="Delete Owner" class="deleteIcon2"
                      (click)="deleteOwner(owner, i)"></div>
                    <div *ngIf="showIcon('sendIcon', owner)" tooltip="Send Consent Request" class="sendConsentIcon ml-4 mt-2"
                      (click)="sendOwnerConsent(owner)"></div>
                  </ng-container>
                  <div *ngIf="owner.consent === 'sent'" class="fs13 fwm cp d-flex lh mt-1 text-nowrap">
                    <span tooltip="Request Sent" class="sentIcon d-block mr-1"></span></div>
                  <ng-container>
                      <a href="javascript:void(0)" *ngIf="owner['pfs_status'] === 'sent' && owner['pfs_status'] !== 'completed' && owner.is_secondary && owner.owner_type === 'individual'" tooltip="Re-send PFS" class="pfsIcon ml-3 fs12 fwsb"  (click)="sendPFS(owner)"><span class="sr-only">Re-send PFS</span></a>
                      <a href="javascript:void(0)" *ngIf="owner['pfs_status'] === 'sent' && owner['pfs_status'] !== 'completed' && owner.is_secondary && owner.owner_type === 'corporate'" tooltip="Re-send Documents" class="docIcon ml-3 fs12 fwsb mt-1 mt-1"  (click)="sendPFS(owner)"><span class="sr-only">Re-send Documents</span></a>
                     
                    <div *ngIf="showIcon('pendingIcon', owner)" class="fs14 otc fwb cp d-flex">
                      <span tooltip="Request Pending" class="sentIcon d-block mr-1"></span>Request Pending</div>
                    <div *ngIf="showIcon('refreshIcon', owner)" tooltip="Refresh" (click)="refresh(owner)"
                      class="refreshIcon  ml-3 mt-1"></div>
                    <div *ngIf="showIcon('resendIcon', owner)" tooltip="Resend Consent Request"
                      class="sendConsentIcon ml-3 mt-1" (click)="sendOwnerConsent(owner,'resend')"></div>
                    <div *ngIf="showIcon('editIcon', owner)" tooltip="Edit Owner" class="editIcon ml-3 mt-1"
                      (click)="editOwner(owner, i)"></div>
                    <div *ngIf="showIcon('businessOwnerShipPercentageIcon',owner)"
                      tooltip="Business Ownership percentage" class="busiorperIcon ml-3"
                      (click)="businessOwneship('openBusinessOwnershipModal',owner)">b</div>
                  </ng-container>
                </div>
              </div>
            </div>
            <ng-container *ngIf="config.journey === 'banker'">
              <div class="row">
                <div class="col-12 col-md-6 col-lg-2 col-xl-2 pr-0 py-3" *ngIf="owner.owner_type == 'individual'">
                  <div class="fs12 ptc ffr">Date of Birth</div>
                  <div class="fs14 fwb ffr">{{(owner?.dob | dateTimezone:'MM-dd-YYYY')|| '-'}}</div>
                </div>
                <div class="col-12 col-md-6 col-lg-2 col-xl-2 pr-0 py-3" *ngIf="owner.date_of_incorporation">
                  <div class="fs12 ptc ffr">Date of Incorporation</div>
                  <div class="fs14 fwb ffr">{{(owner.date_of_incorporation  | dateTimezone:'MM-dd-YYYY') || "-"}}</div>
                </div>
                <div class="col-12 col-md-6 col-lg-2 col-xl-2 pr-1 py-3"
                  *ngIf="config.journey === this.bankerJourney && !(owner?.owner_type==='corporate' && owner?.company_role===company_role_obj?.co_borrower)">
                  <div class="fs12 ptc ffr">Business Ownership (%)</div>
                  <div class="fs14 fwb ffr">
                    {{(owner?.business_ownership!=undefined||owner?.business_ownership!=null) ?( owner.business_ownership+'%') : "-"}}
                  </div>
                </div>
                <div class="col-12 col-md-6 col-lg-2 col-xl-2 pr-0 py-3">
                  <div class="fs12 ptc ffr">{{owner.owner_type === 'individual' ? 'SSN' : 'Tax ID'}}</div>
                  <div class="fs14 fwb ffr eyeIcon viewEyeInSsnTax" *ngIf="owner.owner_type === 'individual'">
                    {{owner.ssn ? owner.showhideSsnTaxId ? (owner.ssn | mask: '000-00-0000') : 'XXX-XX-'+owner.ssn.substring(5,10) : '-'}}
                    <i [ngClass]="owner.ssn ? owner.showhideSsnTaxId ? 'eye': 'eye eyeclose' : '' "
                      (click)="showhideSsnTaxId(owner)">&nbsp;</i>
                  </div>
                  <div class="fs14 fwb ffr eyeIcon viewEyeInSsnTax" *ngIf="owner.owner_type === 'corporate'">
                    {{owner.tax_id ? owner.showhideSsnTaxId ?(owner.tax_id | mask: '00-0000000') : 'XX-XXXX'+owner?.tax_id.substring(6,10) : '-'}}
                    <i [ngClass]="owner.tax_id ? owner.showhideSsnTaxId ? 'eye': 'eye eyeclose' : ''"
                      (click)="showhideSsnTaxId(owner)">&nbsp;</i>
                  </div>
                </div>
                <div class="col-12 col-md-6 col-lg-2 col-xl-2 pr-0 py-3">
                  <div class="fs12 ptc ffr">Email Address</div>
                  <div class="fs14 fwb ffr">{{owner.email_address}}</div>
                </div>
                <div class="col-12 col-md-6 col-lg-2 col-xl-2 pr-0 py-3" *ngIf="owner.is_secondary">
                  <div class="fs12 ptc ffr">Applicant Type</div>
                  <div class="fs14 fwb ffr">{{(owner.company_role | masterData:company_role:'id':'value')||'-'}}</div>
                </div>
                <div class="col-12 col-md-6 col-lg-2 col-xl-2 pr-0 py-3">
                  <div class="fs12 ptc ffr">
                    {{owner.owner_type === 'individual' ? 'Phone Number' : 'Business Phone Number'}}</div>
                  <div class="fs14 fwb ffr">{{((owner.phone || owner.biz_phone) | mask: '(000) 000-0000')||'-'}}</div>
                </div>
              </div>
              <div class="row" *ngIf="owner.ownership>=20 && owner.owner_type === 'individual'">
                  <div class="col-12 col-md-6 col-lg-2 col-xl-2 pr-0 py-3">
                    <div class="fs12 ptc ffr">Title at Company</div>
                    <div class="fs14 fwb ffr">{{(owner?.title_at_company | masterData:professional_title: 'id':'value')||'-'}}</div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-2 col-xl-2 pr-0 py-3">
                    <div class="fs12 ptc ffr">Primary Business Responsibility</div>
                    <div class="fs14 fwb ffr">{{(owner?.primary_business_responsibility | masterData:business_responsibility:'type':'value')||'-'}}</div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-2 col-xl-2 pr-0 py-3">
                    <div class="fs12 ptc ffr">Years at Borrower</div>
                    <div class="fs14 fwb ffr">{{(owner?.years_exp_at_borrower)||'-'}}</div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-2 col-xl-2 pr-0 py-3">
                    <div class="fs12 ptc ffr">Years in the Industry</div>
                    <div class="fs14 fwb ffr">{{(owner?.years_exp_at_borrowers_industry)||'-'}}</div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-2 col-xl-2 pr-0 py-3">
                    <div class="fs12 ptc ffr">Are you, or any business you control, presently involved in any legal action (including divorce)?</div>
                    <div class="fs14 fwb ffr">{{(owner?.is_active_process_divorce | masterData:yes_no:'type':'value')||'-'}}</div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-2 col-xl-2 pr-0 py-3">
                    <div class="fs12 ptc ffr">Please confirm that the liquidity of owners, their spouses and minor children, and the Applicant itself is unavailable to fund the revenue-based financing request</div>
                    <div class="fs14 fwb ffr">{{(owner?.is_confirm_liquidity_unavailable =="yes" ?"Yes - Liquidity is Unavailable":(owner?.is_confirm_liquidity_unavailable =="no"?"No – Liquidity is Available Elsewhere":"-"))||'-'}}</div>
                  </div>
              </div>
              <div class="row" *ngIf="owner.owner_type === 'individual'">
                <div class="col-12 col-md-6 col-lg-2 col-xl-2 pr-0 py-3">
                  <div class="fs12 ptc ffr">Gender</div>
                  <div class="fs14 fwb ffr">{{(owner?.text_1?.gender | masterData:gender:'type':'value')||'-'}}</div>
                </div>
                <div class="col-12 col-md-6 col-lg-2 col-xl-2 pr-0 py-3">
                  <div class="fs12 ptc ffr">Veteran Status</div>
                  <div class="fs14 fwb ffr">{{(owner?.text_1?.veteran | masterData:veteran:'type':'value')||'-'}}</div>
                </div>
                <div class="col-12 col-md-6 col-lg-2 col-xl-2 pr-0 py-3">
                  <div class="fs12 ptc ffr">Race</div>
                  <div class="fs14 fwb ffr">{{ owner?.text_1?.race | masterData:race:'type':'value' || '-'}}</div>
                  <!-- <ng-container *ngIf="owner?.text_1?.race?.length; else no_race">
                    {{owner?.text_1?.race}}
                    <ng-template ngFor let-owner_race [ngForOf]="owner?.text_1?.race">{{owner_race}}
                      <div class="fs14 fwb ffr">{{ owner_race | masterData:race:'type':'value' || '-'}}</div>
                    </ng-template>
                  </ng-container>
                  <ng-template #no_race>
                    <div class="fs14 fwb ffr">{{ owner?.text_1?.race | masterData:race:'type':'value' || '-'}}</div>
                  </ng-template> -->
                </div>
                <div class="col-12 col-md-6 col-lg-2 col-xl-2 pr-0 py-3">
                  <div class="fs12 ptc ffr">Ethnicity</div>
                  <div class="fs14 fwb ffr">{{(owner?.text_1?.ethnicity | masterData:ethnicity:'type':'value')||'-'}}</div>
                </div>
                <div class="col-12 col-md-6 col-lg-2 col-xl-2 pr-0 py-3">
                  <div class="fs12 ptc ffr">Citizen type</div>
                  <div class="fs14 fwb ffr">{{(owner?.citizen_type | masterData:citizenship:'type':'value')||'-'}}</div>
                </div>
                <div *ngIf="owner.citizen_type === 'lawful_resident'" class="col-12 col-md-6 col-lg-2 col-xl-2 pr-0 py-3">
                    <div class="fs12 ptc ffr">USCIS Registration Number:</div>
                    <div class="fs14 fwb ffr">{{owner?.regi_no ||'-'}}</div>
                </div>
                <div *ngIf="owner.citizen_type === 'lawful_resident' || owner.citizen_type === 'not_a_us_citizen_or_lawful_resident'" class="col-12 col-md-6 col-lg-2 col-xl-2 pr-0 py-3">
                    <div class="fs12 ptc ffr">Country of Citizenship:</div>
                    <div class="fs14 fwb ffr">{{owner?.country_of_citizen ||'-'}}</div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-6 col-lg-2 col-xl-2 pr-0 py-3"
                  *ngIf="owner.company_role !== company_role_obj.co_borrower">
                  <div class="fs12 ptc ffr">Parent Tax Id</div>
                  <div class="fs14 fwb ffr eyeIcon viewEyeInSsnTax">
                    {{getParentTaxId(owner) ? owner.parentTaxid ?(getParentTaxId(owner) | mask: '00-0000000') : 'XX-XXXX'+getParentTaxId(owner).substring(6,10) : '-'}}
                    <i [ngClass]="getParentTaxId(owner)  ? owner.parentTaxid ? 'eye': 'eye eyeclose' : ''"
                      (click)="showhideSsnTaxId(owner, 'forParent')">&nbsp;</i>
                  </div>
                </div>
                <div class="col-12 col-md-6 col-lg-2 col-xl-2 pr-0 py-3" *ngIf="owner.company_type">
                  <div class="fs12 ptc ffr">Is this Business an EPC or OC?</div>
                  <div class="fs14 fwb ffr">
                      <!-- | masterData:applicant_business_name:'id':'type' -->
                    {{((owner?.app_biz_legal_name ) | uppercase)||'-'}}</div>
                </div>
                <div class="col-12 col-md-6 col-lg-2 col-xl-2 pr-0 py-3" *ngIf="owner.residence_type">
                  <div class="fs12 ptc ffr">Do you own or rent your residence?</div>
                  <div class="fs14 fwb ffr">{{(owner.residence_type | masterData:residence_type:'id':'value')||'-'}}
                  </div>
                </div>
                <div class="col-12 col-md-6 col-lg-2 col-xl-2 pr-0 py-3" *ngIf="owner.authorized_signatory">
                    <div class="fs12 ptc ffr">Is Signer?</div>
                    <div class="fs14 fwb ffr">{{owner.authorized_signatory ? 'Yes' : 'No'}}</div>
                  </div>
                <div class="col-12 col-md-6 col-lg-2 col-xl-2 pr-0 py-3" *ngIf="owner.business_structure">
                  <div class="fs12 ptc ffr">Business Structure</div>
                  <div class="fs14 fwb ffr">
                    {{owner.business_structure | masterData:business_structure_data:'id':'value'}}</div>
                </div>
                <div class="col-12 col-md-6 col-lg-2 col-xl-2 pr-0 py-3" *ngIf="owner.trade_name">
                  <div class="fs12 ptc ffr">DBA or Trade Name</div>
                  <div class="fs14 fwb ffr">{{(owner.trade_name) || '-'}}</div>
                </div>
                <div class="col-12 col-md-6 col-lg-2 col-xl-2 pr-0 py-3" *ngIf="owner.state_of_incorporation">
                  <div class="fs12 ptc ffr">State of Incorporation/Organization</div>
                  <div class="fs14 fwb ffr">{{(owner.state_of_incorporation  | masterData:state: 'id':'value') || "-"}}
                  </div>
                </div>
                <div class="col-12 col-md-6 col-lg-2 col-xl-2 pr-0 py-3" *ngIf="owner?.is_esop_or_coop">
                  <div class="fs12 ptc ffr">Is this application for an ESOP or COOP process?</div>
                  <div class="fs14 fwm ffr">{{owner?.is_esop_or_coop | masterData:yes_no:'id':'value'}}</div>
                </div>
                <div class="col-12 col-md-6 col-lg-2 col-xl-2 pr-0 py-3"
                  *ngIf="owner?.is_esop_or_coop==='5e8723158f2f4e3ac475fab6'">
                  <div class="fs12 ptc ffr">Entity type</div>
                  <div class="fs14 fwm ffr">{{owner?.entity_type | masterData:applicant_type:'id':'value'}}</div>
                </div>
                <div class="col-12 col-md-6 col-lg-2 col-xl-2 pr-0 py-3"
                  *ngIf="owner?.is_limited_guaranter && (owner?.company_role===company_role_obj?.guarantor)">
                  <div class="fs12 ptc ffr">Is this a Limited Guarantor?</div>
                  <div class="fs14 fwb ffr">{{owner.is_limited_guaranter==='5e8723158f2f4e3ac475fab6' ? 'Yes' : 'No'}}
                  </div>
                </div>
                  <div class="col-12 col-md-6 col-lg-2 col-xl-2 py-3"
                  *ngIf="owner?.business_structure === business_structure_obj.franchise">
                  <div class="fs12 ptc ffr">Franchise Name</div>
                  <div class="fs14 fwb ffr">{{owner.franchise_check}}</div>
                </div>
                <div class="col-12 col-md-6 col-lg-2 col-xl-2 py-3"
                  *ngIf="owner?.business_structure === business_structure_obj.franchise ">
                  <div class="fs12 ptc ffr">Business Franchise Code</div>
                  <div class="fs14 fwb ffr">{{owner.business_franchise_code}}</div>
                </div>
              </div>
            </ng-container>
          </div>
        </accordion-group>
      </ng-container>
    </accordion>
  </div>
</form>
<ng-template #bottomTableLevelTmp>
  <form [formGroup]="additional_owner_form" *ngIf="ownerData?.length">
    <div class="mt-5 mb-5">
      <div class="col-12 mt-4 gridView">
      <!-- <div class="row align-items-center greenbg tableHeadWrap">
        <div class="col-6 col-md-6 col-lg-6 greenbg py-3">
          <div class="title fs20 fwsb ffr wtc lh37">Level {{config?.level}} Owners</div>
        </div>
        <div class="col-6 col-md-6 col-lg-6 text-right greenbg py-3">
            <div class="btn-group commonDrop onerListDropdown4" dropdown>
              <button id="button-animated" dropdownToggle type="button" class="btn btn-default2 btn-md"
                aria-controls="dropdown-animated" placement="bottom right"><em class="addIcon"></em> Add Owner / Guarantor
              </button>
              <ul style="min-width: 16.3rem;" id="dropdown-animated" *dropdownMenu class="dropdown-menu dropdown-menu-right oVeriDropdown" role="menu"
                aria-labelledby="button-animated">
                <ng-container *ngFor="let roles of company_role_new">
                  <li *ngIf="roles?.id !== company_role_obj.co_borrower && config?.componentType === 'owner-verification' ;"><a
                      class="dropdown-item cp" (click)="addOwner(roles?.id)">Add {{roles?.value}}</a></li>
                  <li *ngIf="config?.componentType !== 'owner-verification'"><a class="dropdown-item cp"
                      (click)="addOwner(roles?.id)">Add {{roles?.value}}</a></li>
                </ng-container>
              </ul>
            </div>
        </div>
      </div> -->
      <div class="row align-items-center headRow border tableHeadWrap">
        <div class="col col-md-6 col-lg-6 py-3">
          <div class="title fs16 fwsb ffr stc lh37 headTitle">Level {{config?.level}} Owners</div>
        </div>
        <div class="col-auto col-md-12 col-lg-6 text-right py-3">
            <div class="btn-group commonDrop onerListDropdown4" dropdown>
              <button id="button-animated" dropdownToggle type="button" class="btn btn-primary btn-md"
                aria-controls="dropdown-animated" placement="bottom right"> Add Owner / Guarantor
              </button>
              <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu dropdown-menu-right oVeriDropdown w-100" role="menu"
                aria-labelledby="button-animated">
                <ng-container *ngFor="let roles of company_role_new">
                  <li *ngIf="roles?.id !== company_role_obj.co_borrower && config?.componentType === 'owner-verification' ;"><a
                      class="dropdown-item cp" (click)="addOwner(roles?.id)">Add {{roles?.value}}</a></li>
                  <li *ngIf="config?.componentType !== 'owner-verification'"><a class="dropdown-item cp"
                      (click)="addOwner(roles?.id)">Add {{roles?.value}}</a></li>
                </ng-container>
              </ul>
            </div>
        </div>
      </div>
      <div class="row contentRow">
        <div class="col-12 px-0 agGridWrap">
        <!-- AG_GRID -->
        <ag-grid-angular
        id="myGrid"
          #agGridagGridLvl2
          style="width: 100%; height: 100%"
          class="ag-theme-balham"
          [rowData]="ownerData"
          [columnDefs]="lvl2ColDefs"
          [domLayout]="domLayout"
          (gridReady)="onGridReady($event)"
          [overlayNoRowsTemplate]="noRowsTemplate"
          [gridOptions]="gridOptions">
        </ag-grid-angular>
      </div>
    </div>
    </div>
    
    <!-- <div class="row" *ngIf="showActions()">
        <div class="col-12 col-md-12 col-lg-12 col-xl-12 pt-2">
          <div *ngIf="showOwnerhipTooltip">
          <div class="fs12 btc"><span class="fwsb">Please note:</span> 100% ownership participation is required to process your credit requested.</div>
        </div>
        </div>
      </div> -->
    </div>
  </form>
</ng-template>

<div class="row" *ngIf="showActions() && showIcon('addOwnerInBottom') &&(!config?.showBottomTableLevel || (config?.showBottomTableLevel && !ownerData?.length))">
  <div class="col-12 col-md-12 col-lg-12 col-xl-12 pt-2">
    <div *ngIf="showOwnerhipTooltip">
    <div class="fs12 btc"><span class="fwsb">Please note:</span> 100% ownership participation is required to process your credit requested.</div>
  </div>
  </div>
  <!-- <div class="ownerPartci" *ngIf="showOwnerhipTooltip">
    100% ownership participation must be reflected to process your credit requested.
  </div> -->
  <div class="col-12 col-md-12 col-lg-3 col-xl-12 text-lg-right">
    <div class="btn-group commonDrop onerListDropdown5" dropdown>
      <button id="button-animated" dropdownToggle type="button" class="btn btn-primary btn-md dropdown-toggle"
        aria-controls="dropdown-animated" placement="bottom right"> Add Owner / Guarantor <span class="caret"></span>
      </button>
      <ul id="dropdown-animated" style="min-width: 234px;" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
        aria-labelledby="button-animated">
        <ng-container *ngFor="let roles of company_role">
          <li *ngIf="roles?.id !== company_role_obj.co_borrower && config?.componentType === 'owner-verification'"><a
              class="dropdown-item cp" (click)="addOwner(roles?.id)">Add {{roles?.value}}</a></li>
          <li *ngIf="config?.componentType !== 'owner-verification'"><a class="dropdown-item cp"
              (click)="addOwner(roles?.id)">Add {{roles?.value}}</a></li>
        </ng-container>
      </ul>
    </div>
  </div>
</div>
<div class="row" *ngIf="config?.componentType !== 'owner-verification'">
  <div class="col-12 col-md-12 col-lg-6 col-xl-6  pt-5">
    <button class="btn btn-default" (click)="viewTreeStructure()">Company Organization Chart</button>
  </div>
</div>

</ng-container>

<div *ngIf="additionalOwnerOpen" [ngClass]="config?.componentType === 'owner-verification' ? '' : ''">
  <rubicon-dynamic [fields]="addChildOwnersFormConfig" [group]="addChildOwnersForm" [slug]="form_slug" class="row">
  </rubicon-dynamic>
  <!-- <div class="row" *ngIf="addChildOwnersForm?.getRawValue()?.owners?.owner_type">
    <div class="col-12 col-lg-12 col-xl-12">
      <div class="secureInfo my-4 d-flex">
        <div class="stc"><span class="lock2 d-inline-block mr-2 vas"></span> <span>Your information
            is secure with 256-bit encryption</span></div>
      </div>
    </div>
  </div> -->
  <div class="row py-3 py-lg-5 bottomPartition">
      <div class="col-12">
          <div class="border-bottom"></div>
      </div>
  </div>
  <div class="row">
      <div class="col-4 col-sm-3 col-md-3 col-lg-3">
          <div class="text-left pt-lg-2 pt-md-1">
              <button type="button" class="btn-md-link ptc px-0 btn backIcon" (click)="onCloseAdditOwner()">Previous</button>
          </div>
      </div>
      <div class="col-8 col-sm-9 col-md-9 col-lg-9 btnMultib">
          <div class="text-right">
              <button type="button" class="btn btn-primary" (click)="submitOwner('continue')">Continue</button>
          </div>
      </div>
  </div>
  <!-- <div class="col-12 col-md-9 col-lg-9">
    <div class="text-right">
      <button type="button" class="btn btn-primary" (click)="submitOwner('continue')">Continue</button>
    </div>
  </div> -->
</div>
<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Owner Details</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <rubicon-dynamic [fields]="addChildOwnersFormConfig" [group]="addChildOwnersForm" [slug]="form_slug" class="row">
    </rubicon-dynamic>
    <div class="row" *ngIf="addChildOwnersForm?.getRawValue()?.owners?.owner_type">
      <div class="col-12 col-lg-12 col-xl-12">
        <div class="secureInfo my-4 d-flex">
          <div class="stc"><span class="lock2 d-inline-block mr-2 vas"></span> <span>Your information
              is secure with 256-bit encryption</span></div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer" *ngIf="addChildOwnersForm?.getRawValue()?.owners?.owner_type">
    <div class="col-12 col-md-9 col-lg-9">
      <div class="text-right">
        <button type="button" class="btn btn-primary" (click)="submitOwner('continue')">Confirm</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #alertConfirmation>
  <div class="modal-header pl-3">
      <h4 class="modal-title pull-left">Notification</h4>
      <!-- <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
      </button> -->
  </div>
  <div class="modal-body">
      <div class="fs18 ptc pb-2">{{errorMessage}}</div>
  </div>
  <div class="sbaConfirmation-footer">
      <div class="pt-3 text-right w-100">
          <button type="button" (click)="onalertConfirmation('yes')" class="btn btn-primary">Ok</button>
          <!-- <button type="button" (click)="onalertConfirmation('no')"  class="btn btn-primary">No</button> -->
      </div>
  </div>
</ng-template>

<ng-template #businessOwnershipTemplate>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Business Ownership Percentage</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="businessOwnershipModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" *ngIf="businessOwnershipConfig && businessOwnershipConfig.length">
    <rubicon-dynamic [fields]="businessOwnershipConfig" [group]="businessOwnershipForm" [slug]="businessOwnershipSlug">
    </rubicon-dynamic>
  </div>
  <div class="modal-footer">
    <div class="col-12 col-md-9 col-lg-9">
      <div class="text-right">
        <button type="button" class="btn btn-primary"
          (click)="businessOwneship('submitBusinessOwnership')">Submit</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #treeStructureTemplate>
  <div class="modal-header">
    <h4 class="modal-title pull-left text-capitalize fwsb">Organization Chart</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="businessOwnershipModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ownership-tree-structure [config]="treeConfig"></ownership-tree-structure>
  </div>
</ng-template>
