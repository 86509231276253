import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, of, Subject } from "rxjs";
import * as _ from 'lodash';
import { AccessValidatorService } from '../../../../../core-utils/biz2xPro-config-engine';
import { WorkflowActionsService } from './workflow-actions.service';
import { TaskInfoService } from '@rubicon/utils';
import { catchError, map, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { HttpBackend, HttpClient } from "@angular/common/http";
import { get, includes } from "lodash";
import { ToasterService, Toast } from 'angular2-toaster';

@Injectable({
   providedIn: 'root'
})
export class ManageLoansService {
   private httpBackend: HttpClient;
   public appSummaryData = new Subject();
   public notes_added = new BehaviorSubject(false);
   private updatedProduct = new Subject();
   public updateAssignTo = new Subject();
   private sbaInitiated = new Subject();
   public docHeight = new Subject();
   data$ = this.notes_added.asObservable();
   referrerRoute;
   isCustomerJourney;
   backendUser;
   constructor(private accessValidatorService: AccessValidatorService,
      private workflowActionService: WorkflowActionsService,
      private taskInfoService: TaskInfoService,
      private store: Store<any>,
      private handler: HttpBackend,
      private toasterService: ToasterService,
      @Inject("environment") private environment){
         this.httpBackend = new HttpClient(handler);
      this.isCustomerJourney = this.environment.journeyType == "customer-journey";
      this.store.select('app').subscribe(rootState => {
         if (this.isCustomerJourney) {
            this.referrerRoute = rootState?.appData?.referrerRoute || "banker-dashboard";
         } else {
            this.backendUser = rootState?.userData;
            this.referrerRoute = rootState?.appData?.referrerRoute || "banker-dashboard";
         }
      });
   }

   notesAdded(data: boolean) {
      this.notes_added.next(data);
   }

   changesProduct(data){
      this.updatedProduct.next(data);
   }

   productChangeEvent(){
      return this.updatedProduct.asObservable();
   }

   changesbaInitiated(data){
      this.sbaInitiated.next(data);
   }

   sbaInitiatedEvent(){
      return this.sbaInitiated.asObservable();
   }

   changeAssignTo(data){
      this.updateAssignTo.next(data);
   }

   AssignToChangeEvent(){
      return this.updateAssignTo.asObservable();
   }

   getUserAssignments(application, bankerMap){
      const allAssignment = application?.app_assignment;
      let user_assignments = [];
      if(allAssignment && allAssignment.length){
        user_assignments = allAssignment.map((assignment)=>{
          let user = bankerMap[assignment.assigned_to];
          if(user){
            return { 
               ...assignment,
              id: user.id,
              backend_user_id : user.id,
              name: user.name,
              role: user.roles.name,
              role_slug: user.roles.role_slug,
              short_slug: user.roles.short_slug,
              email_address: user.email_address,
              phone_no: user.phone_number,
              is_active: user.is_active,
              delete: user.delete
            }
          }
          return {
             ...assignment,
            role_id: assignment.role_id,
            stage: assignment.stage,
          };
        }).filter(user=>user); 
      }
      return user_assignments;
   }

   getActionsDDL(appData) {
      let workflow_actions: any = _.cloneDeep(this.workflowActionService.getAllWorkflowActions() || []);
      return workflow_actions.filter(action => {
         action.isActionVisible = action.visible && this.accessValidatorService.checkAccessValidate(appData, action?.config?.validate_type, action);
         return action.isActionVisible;
      });
   }

   statusToShow(status_id, sub_status_id, subStatusMap, statusValue){
      let statusToShow = statusValue;
      let sub_status = subStatusMap[sub_status_id];
      let value_key = this.environment.journeyType == "customer-journey"?"client_value":"value";
      if(sub_status?.show_sub_status){
         return sub_status[value_key];
      }else{
         let isSubStatusExists = false;
         for(let sub_status in subStatusMap){
            if(subStatusMap[sub_status].app_status_id == status_id){
              isSubStatusExists = true;
              break;
            }
         }
         if(isSubStatusExists && sub_status){
            statusToShow = sub_status[value_key];
         }
      }
      if(!statusToShow){
         statusToShow = "Not Available";
      }
      return statusToShow;
    }

    prepareStatusMap(app_statuses){
      let statusMap = {};
      app_statuses = app_statuses || [];
      if (app_statuses && app_statuses.length) {
         app_statuses.forEach((status) => {
            statusMap[status.id] = status;
        });
      }
      return statusMap;
    }

    prepareSubStatusMap(app_sub_status) {
       let subStatusMap = {};
      const subStatuses = app_sub_status || [];
      if (subStatuses && subStatuses.length) {
        subStatuses.forEach((status) => {
          subStatusMap[status.id] = { value: status.value, type: status.type, app_status_id: status.app_status_id, client_value:  status?.client_value, show_sub_status:status?.config?.show_sub_status}
        });
      }
      return subStatusMap;
    }
   
   updateAppSummary(data) {
      this.appSummaryData.next(data);
   } 

   getRoleListingFilterParams(user_data){
      let listingAction = this.workflowActionService.getWorkflowActions("dashboard_app_listing");
      let roleListingConfig = _.get(listingAction, `config.${user_data?.role_id}`);
      return roleListingConfig?.filter_params || {};
   }

   prepareBankerMap(app){
      let user_ids = [];
      let bankerMap = {};
      app?.app_assignment?.forEach(element => {
         if(element?.assigned_to){
           user_ids.push(element.assigned_to);
         }
       });
      if (user_ids.length) {
         const uniqueIds = Array.from(new Set(user_ids));
         return this.taskInfoService.saveTaskInfo({slug: 'banker-by-ids'}, {user_ids: uniqueIds }).pipe(map((res) => {
           const bankers = _.get(res, 'get_banker_by_ids.data.data') || [];
           bankers.forEach((bu) => {
             if(!bankerMap[bu.id]) {
               bankerMap[bu.id] = bu;
             }
           });
           return bankerMap;
         }));
       }
       return of({});
   }

   isBnkrVsblInAsgnLst(assignmentBanker, loggedInBanker){
      let isBankerVisible = true;
      if(!assignmentBanker.is_active){
         return false;
      }
      else if(assignmentBanker?.roles?.restricted_app_assignment){
         isBankerVisible = false;
       }else if(assignmentBanker?.roles?.hasOwnProperty("self_assignment")){
         switch(assignmentBanker?.roles?.self_assignment){
           case "self": 
             //only backend_user can assign to himself
             isBankerVisible = assignmentBanker.id === loggedInBanker.user_id;
             break;
           case "default":
             //only loggedin banker with same role of backend_user can assign
             isBankerVisible = assignmentBanker?.roles?.id === loggedInBanker.role_id;
             break;
         }
       }
       return isBankerVisible;
   }

   getNextTaskOnPageSubmit(action, appConfig, defaultPage, resNextTask){
      let nextTask;
      if(this.isCustomerJourney){
         let next = (appConfig?.redirects?.next) ? appConfig?.redirects?.next : (resNextTask?.value || defaultPage);
         nextTask = action === 'continue' ? next : this.referrerRoute;
      }else{
         nextTask = action === 'continue' ? (resNextTask?.value || defaultPage)  : this.referrerRoute;
      }
      return nextTask;
   }

   getReferrerNextTask(){
      return this.referrerRoute;
   }

   getPrimaryOwnerBasicDetail(app) {
      let primary_owner = {
        phone: '',
        name: '',
        email: '',
      };
      if (app?.owners?.length > 0) {
        let primaryOwnerData = app?.owners.find((e) => e.is_primary === true);
        if(!primaryOwnerData){return null}
        if (primaryOwnerData?.owner_type === 'individual') {
          primary_owner.phone = primaryOwnerData.phone;
          primary_owner.email = primaryOwnerData.email_address;
          primary_owner.name = primaryOwnerData.first_name;
        }
        else {
          primary_owner.phone = primaryOwnerData.phone_benefecial_owner;
          primary_owner.email = primaryOwnerData.email_address_beneficial_owner;
          primary_owner.name = primaryOwnerData.first_name_beneficial_owner;
        }
      }
      return primary_owner;
    }

    getBankerName(banker){
      if(banker?.assigned_to){
         if(banker?.name){
            let symbol = {"is_delete": "**", "not_is_active": "*"};
            if(banker?.delete || !banker?.is_active){
               return banker?.name + symbol[banker?.delete ? "is_delete":"not_is_active"];
            }else{
               return banker?.name;
            }
         }else{
            return 'Unknown Banker';
         }
      }else{
         return 'Unassigned';
      }      
    }

    getAssignmentTypeAppListingParams(params, user_data, toggleAppListType){
      if(toggleAppListType){
         switch(toggleAppListType){
            case "no_filter": break;
            case "my_apps":delete params.role_id_or; delete params.rolestage; params['assigned_to'] = user_data.user_id; 
               break;
            case "my_role_apps": delete params.rolestage; delete params.assigned_to; params.role_id_or = user_data.role_id; params['is_deleted_assigned'] = false;
               break;
            case "allowed_stages_apps":delete params.assigned_to; delete params.role_id_or; params.rolestage = Array.from(this.accessValidatorService.getUserAllowedStage()).join(',');
               break;
            case "all_apps":
               delete params.assigned_to; delete params.role_id_or; delete params.rolestage;
               break;
            default:  
               let dynamic_key = toggleAppListType?.split("params:")?.[1];
               if(dynamic_key){
                  let dashboard_app_listing_action: any = this.workflowActionService.getWorkflowActions("dashboard_app_listing");
                  let role_config_app_listing = dashboard_app_listing_action?.config?.[user_data?.role_id];
                  let dynamic_key_config = role_config_app_listing?.[dynamic_key];
                  if(dynamic_key_config?.static && typeof dynamic_key_config?.static == "object"){
                     delete params.rolestage; delete params.assigned_to; params['is_deleted_assigned'] = false;
                     Object.assign(params, dynamic_key_config?.static);
                  }
               }
               break;

         }
      }
      return params;
   }

   getAssignmentTypeStatusPipelineParams(params, user_data, toggleAppListType){
      if(toggleAppListType){
         switch(toggleAppListType){
            case "no_filter": break;
            case "my_apps":delete params.role_id; delete params.rolestage; params['assigned_to'] = user_data.user_id; params['is_deleted_assigned'] = false;
               break;
            case "my_role_apps": delete params.rolestage; delete params.assigned_to; params.role_id = user_data.role_id; params['is_deleted_assigned'] = false;
               break;
            case "allowed_stages_apps":delete params.assigned_to; delete params.role_id; params['is_deleted_assigned'] = false; params.rolestage = Array.from(this.accessValidatorService.getUserAllowedStage()).join(',');
               break;
            case "all_apps":
               delete params.assigned_to; delete params.role_id; delete params.rolestage;params['is_deleted_assigned'] = false;
                  break;
            default:
               let dynamic_key = toggleAppListType?.split("params:")?.[1];
               if (dynamic_key) {
                  let dashboard_app_listing_action: any = this.workflowActionService.getWorkflowActions("dashboard_app_listing");
                  let role_config_app_listing = dashboard_app_listing_action?.config?.[user_data?.role_id];
                  let dynamic_key_config = role_config_app_listing?.[dynamic_key];
                  if (dynamic_key_config?.static && typeof dynamic_key_config?.static == "object") {
                     delete params.rolestage; delete params.assigned_to; params['is_deleted_assigned'] = false;
                     Object.assign(params, dynamic_key_config?.static);
                  }
               }
               break;
         }
      }
      return params;
   }

   getQueryTrackingParamsObservable() {
      let raw_url = sessionStorage.getItem("raw_url") ? sessionStorage.getItem("raw_url") : ((location && location.href) ? location.href : '');
      let tracking_params: any = {
         raw_url,
         user_agent: (window && window.navigator && window.navigator.userAgent) ? window.navigator.userAgent : ''
      }
      return this.httpBackend.get('https://api.ipify.org/?format=json').pipe(
         map((res: any) => {
            if (res && res.ip) {
               tracking_params.ip = res.ip;
            }
            return tracking_params;
         }, catchError((error) => of(tracking_params))
         ));
   }

   submitApp(config) {
      const { request_params, url } = config.payload;
      // tslint:disable-next-line: max-line-length
      return this.taskInfoService.saveTaskInfo(request_params.params, { ...request_params.body, ...config.payload.body, app_id: config.payload.request_params.params.app_id }, { headers: config.payload.request_params.headers, params: config.payload.request_params.params });

   }

   handleSBAErrors(response) {
      const sbaErrorMessage = get(response, 'submit_sbaloan_to_marketplace.errors.errors.message');
      if (sbaErrorMessage && typeof sbaErrorMessage === 'object') {
         for (var key in sbaErrorMessage) {
            if (includes(key, 'owner_info')) {
               this.handleOwnerInfoErrors(sbaErrorMessage, key);
            } else if (includes(key, 'business_info')) {
               this.popToast('error', 'Business Info Error', sbaErrorMessage[key].message);
            } else if (includes(key, 'lead_info')) {
               this.handleLeadInfoErrors(sbaErrorMessage, key, 'SBA Loan');
            } else {
               this.popToast('error', 'Error', sbaErrorMessage[key].message);
            }
         }
      } else if (sbaErrorMessage && typeof sbaErrorMessage === 'string') {
         if (sbaErrorMessage == 'You have already submitted this application') {
            let applicationType = 'SBA Loan';
            this.popToast('error', 'Error', 'You have already submitted a ' + applicationType + ' application under this business name');
         } else {
            this.popToast('error', 'Error', "SBA Loan " + sbaErrorMessage);
         }
      } else {
         this.popToast('error', 'Error', 'Unable to submit this SBA Loan Application');
      }
   }

   handleOwnerInfoErrors(termLoanErrorMessage, key) {
      if ((includes(key, 'first_name') || includes(key, 'last_name'))) {
         if (termLoanErrorMessage[key].rule == 'alphaNumeric') {
            this.popToast('error', 'Owner Info Error', `Owner's first and last name should not contain any special characters. Please correct the owner information to submit.`);
            return;
         } else {
            this.popToast('error', 'Owner Info Error', 'Invalid first name or last name in owners. Please correct the owner information to submit.');
            return;
         }
      } else if (key == 'biz_legal_name') {
         if (termLoanErrorMessage[key].rule == 'alphaNumeric') {
            this.popToast('error', 'Owner Info Error', `Owner's Business name should not contain any special characters. Please correct the owner information to submit.`);
            return;
         } else {
            this.popToast('error', 'Owner Info Error', 'Invalid Business Name in owners. Please correct the owner information to submit.');
            return;
         }
      } else if (key == 'owner_info') {
         this.popToast('error', 'Owner Info Error', termLoanErrorMessage[key].message);
      } else {
         this.popToast('error', 'Owner Info Error', 'Please enter the correct owner info details');
      }
   }


   handleLeadInfoErrors(termLoanErrorMessage, key, applicationType?) {
      if ((includes(key, 'first_name') || includes(key, 'first_name'))) {
         if (termLoanErrorMessage[key].rule == 'alphaNumeric') {
            this.popToast('error', 'Logged-In User Info Error', `Logged-In user's first and last name should not contain any special characters`);
            return;
         } else {
            this.popToast('error', 'Logged-In User Info Error', 'Invalid first name or last name in Logged-In user name');
            return;
         }
      } else if (key == 'lead_info') {
         this.popToast('error', 'Logged-In User Info Error', termLoanErrorMessage[key].message);
      } else {
         this.popToast('error', 'Error', 'Something went wrong. Unable to submit this ' + (applicationType ? applicationType : '') + ' application. Please check your internet connection and try again.');
      }
   }

   /**
     * function to show toaster
     */
   popToast(type: string, title: string, body: string) {
      const toast: Toast = {
         type,
         title,
         body
      };
      return this.toasterService.pop(toast);
   }

}