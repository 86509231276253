import { Component, OnInit } from '@angular/core';
import { CommonService } from '@rubicon/utils_custom';

@Component({
  selector: 'rubicon-default-loader',
  templateUrl: './default-loader.component.html',
  styleUrls: ['./default-loader.component.css']
})
export class DefaultLoaderComponent implements OnInit {

  loading: boolean;
  constructor(private common: CommonService) {
  }

  ngOnInit() {
    this.common.pgLoaded.subscribe((val: any) => {
      this.loading = val;
    });
  }
}
