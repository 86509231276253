export const SUBMITTED_APP_STATUS = '5c20bf7e27105c78ad7f9280';
export const REJECTED_APP_STATUS='5d9ede710b0be3290070fc1b';
export const PRODUCTP_TYPE = {
    ppp2: '5f40fb82b365b23a003e3708',
    forgiveness: '5cf61ae8a567c9873cbab4d5',
    term_loan : '616415139c0b772898330eec',
    ertc: '62b17842a2d8a20494cf846c',
    sba: '63a0485a27f0b23bf982e256',
    working_capital: '62260b1e7cdf3e5be1eb6be4',
}
export const PRODUCT_TYPE_PAYLOAD = {
  ppp2: '5f40fb82b365b23a003e3708',
  forgiveness: '5cf61ae8a567c9873cbab4d5',
  termloan : '616415139c0b772898330eec',
  ach: '62260b1e7cdf3e5be1eb6be4',
  ertc: '62b17842a2d8a20494cf846c',
  sba: '63a0485a27f0b23bf982e256'
}

export const PLAN_FEATURE_CONFIG = {
  "6566ffe20fbc390097f1c772": {
      "id":'6566ffe20fbc390097f1c772',
      "type": "basic",
      "upgrade": true,
      "customRoleupgrade": true,
      "upgrade_to": ["659f7fd5ce7e57cd1b19290d"],
      "downgrade": false,
      "cancel": false,
      "newUserPlan": true,
      "worldpay": false,
      "subscriptionExpiredPopup":false
  },
  "659f7fd5ce7e57cd1b19290d": {
      "id":'659f7fd5ce7e57cd1b19290d',
      "type": "new_premium",
      "upgrade": false,
      "customRoleupgrade": false,
      "downgrade": true,
      "cancel": false,
      "newUserPlan": true,
      "worldpay": true,
      "subscriptionExpiredPopup":false
  },
  "5e43ab3deeed3a3c3092c40c": {
      "id":'5e43ab3deeed3a3c3092c40c',
      "type": "premium",
      "upgrade": false,
      "customRoleupgrade": false,
      "upgrade_to": ["5f3fa4d5b0ab674488f010e7"],
      "downgrade": false,
      "cancel": true,
      "newUserPlan": false,
      "worldpay": true,
      "subscriptionExpiredPopup":false
  },
  "5f3fa4d5b0ab674488f010e7": {
      "id":'5f3fa4d5b0ab674488f010e7',
      "type": "enterprise",
      "upgrade": false,
      "customRoleupgrade": false,
      "downgrade": false,
      "cancel": true,
      "newUserPlan": false,
      "worldpay": true,
      "subscriptionExpiredPopup":false
  },
  "5fdb264922e0203a8c4c12a2": {
    "id":'5fdb264922e0203a8c4c12a2',
    "type": "custom",
    "upgrade": false,
    "customRoleupgrade": false,
    "downgrade": false,
    "cancel": false,
    "newUserPlan": false,
    "worldpay": true,
    "subscriptionExpiredPopup":false
},
"5f48d55a00df162a64a8a57a": {
  "id":'5f48d55a00df162a64a8a57a',
  "type": "free",
  "upgrade": false,
  "customRoleupgrade": false,
  "downgrade": false,
  "cancel": false,
  "newUserPlan": false,
  "worldpay": true,
  "subscriptionExpiredPopup":false
},
}

export const CONFIRMATION_MESSAGE = {
    delete_user: 'Are you sure you want to delete this client? All information entered for this client will be lost.',
    delete_application: 'Are you sure you want to delete this application? All saved data will be lost.'
}
export const CONFIRMATION_MESSAGE_NEW = {
    delete_user: 'Are you sure you want to delete this client? This action is not reversible. The client information and any unsubmitted applications for this client will be deleted.'
}
export const  CURRENT_STEP_MAP = {
    ppp2: {
        'eligibility': 'eligibility',
        'eligibility-question': 'eligibility',
        'payroll-details': 'eligibility',
        'employee-details': 'eligibility',
        'eligibility-values': 'eligibility',
        'offer-details': 'offer-details',
        'revenue-reduction':'eligibility',
        'borrower-business': 'borrower-business',
        'payroll-info': 'payroll-info',
        'forgiveness-eligibility': 'forgiveness-eligibility',
        'business-details-ppp2-1': 'borrower-business',
        'business-details-ppp2-2': 'borrower-business',
        'applicant-details-ppp2': 'applicant-details-ppp2',
        'loan-purpose':'loan-purpose',
        'government-declaration': 'government-declaration',
        'government-declaration-step2': 'government-declaration',
        'ppp2-esign': 'ppp2-esign',
        'document-listing-ppp2-1': 'document-listing-ppp2-1',
        'document-listing-ppp2-2': 'document-listing-ppp2-1',
        'before-you-start': 'before-you-start',
        'application-view': 'application-view',
        'ppp-loans': 'ppp-loans'
    },
    forgiveness: {
        'loan-info': 'loan-info',
        'about-you': 'about-you',
        'bank-statement': 'bank-statement',
        'question-answer': 'question-answer',
        'payroll-schedule': 'payroll-schedule',
        'eligible-forgiveness': 'eligible-forgiveness',
        'documents': 'documents',
        'application-view-v2': 'application-view-v2',
    },
    term_loan : {        
        "term-loan-business-structure":"business-structure",
        "term-loan-owners-details":"owner-details",
        "term-loan-business-info":"business-info",
        "term-loan-details": "loan-details",
        "term-loan-document-management": "document-management",
        'application-view': 'application-view',
        'pre_qualification': 'pre-qualification'
    },
    working_capital : {        
        "wc-business-structure":"business-structure",
        "wc-owners-details":"owner-details",
        "wc-business-info":"business-info",
        "wc-loan-details": "loan-details",
        "wc-document-management": "document-management",
        'application-view': 'application-view',
        'pre_qualification': 'pre-qualification'
    },
    ertc : {        
        "ertc-business-structure":"business-structure",
        "ertc-owners-details":"owner-details",
        "ertc-business-info":"business-info",
        "ertc-loan-details": "loan-details",
        "ertc-document-management": "document-management",
        'application-view': 'application-view',
        'pre_qualification': 'pre-qualification'
    },
    sba : {        
        "sba-loan-request":"loan-request",
        "sba-owner-affiliates-details":"owner-affiliates-details",
        "sba-business-details":"business-details",
        "sba-eligibility-questions": "eligibility-questions",
        "sba-form-1919": "form-1919",
        'sba-upload-documents': 'upload-documents',
        'sba-financial-statements' : 'financial-statements',
        'collateral-details' : 'collateral-details',
        "another-owner-detail": "another-owner-detail",
        "borrower-information": "borrower-information",
        "about-business": "about-business",
        "owner-details":"owner-details",
        "sba-primary":"sba-primary",
        "sba-documents":"documents",
        "funding-information":"funding-information",
        "pfs-form": "pfs-form"
    }
}

export const FICO_SCORE_QUALIFIED = [
    '61cc1855183e141fc8d94f12', '61cc1855183e141fc8d94f13', '61cc1855183e141fc8d94f14', '61cc1855183e141fc8d94f15', '61cc1855183e141fc8d94f16', '61cc1855183e141fc8d94f17', '61cc1855183e141fc8d94f18'
]
export const TIME_IN_BUSINESS_QUALIFIED = ['61cc185a183e141fc8d96f7f', '61cc185a183e141fc8d96f80', '61cc185a183e141fc8d96f81', '61cc185a183e141fc8d96f7e'];
export const TIME_IN_BUSINESS_QUALIFIED_PREQUAL_POPUP = ['61cc185a183e141fc8d96f7f', '61cc185a183e141fc8d96f80', '61cc185a183e141fc8d96f81', '61cc185a183e141fc8d96f7e'];

export const TO_SHOW_OPTIONS_FOR_FRANCHISE = ['5e8722da3a85d212e476bae7', '5e8722da3a85d212e476bae8', '5e8722da3a85d212e476baea', '61a0bb25d45f0072c0622e4d',
    '61a0bb25d45f0072c0622e4e', '61a07b73de1570755c00e539'];
export const TL_QUALIFIED_REV = ["61cc113eff959d3dfcd7202a", "61cc113eff959d3dfcd7202b", "61cc113eff959d3dfcd72029"];
export const Prequal_qualified_range =["61cc113eff959d3dfcd7202a", "61cc113eff959d3dfcd7202b", "61cc113eff959d3dfcd72026", "61cc113eff959d3dfcd72027", "61cc113eff959d3dfcd72028", "61cc113eff959d3dfcd72029"]

export const productTypeMap = {
    "5cf61ae8a567c9873cbab4d5" : 'Forgiveness',
    "5f40fb82b365b23a003e3708" : 'PPP',
    "616415139c0b772898330eec" : 'Term Loan',
    '62260b1e7cdf3e5be1eb6be4' : 'Revenue-Based Financing',
    '62b17842a2d8a20494cf846c' : 'ERTC',
    '63a0485a27f0b23bf982e256' : 'SBA Loan'
}

export const FAQa_LIST = [
    {
         "header_key" : `<h3 class="fs18 fwb pt-4 mb-1"><u>Setting Up an Account on the Portal:</u></h3>`,
         "ques_ans":[
             {
               "ques_html": `<div>What is the CPA Business Funding Portal?</div>`,
               "ans_html": `<p>The CPA Business Funding Portal, (accessed at: <a href="/" target="_blank" class="uline ptc">CPALoanPortal.com</a>), is the place online where CPA firms can help their small business clients apply for financing, including government-backed relief programs. The Portal is provided by CPA.com and fintech partner, Biz2Credit, to make it possible for CPA firms to provide financing options to their small business clients directly. The platform offers access to a wider range of funding opportunities and will be kept up to date as new funding programs become available, with exclusive benefits provided to CPA firms who subscribe to the Portal.</p>`,
             },
             {
               "ques_html": `<div>Is there a cost to access the CPA Business Funding Portal?</div>`,
               "ans_html": `<p>There are different subscription plans in order to access the Portal. The CPA Business Funding Portal is a fully featured software platform available for client management purposes for firms to use when delivering financing advisory services. The platform requires significant technology and hosting resources to provide to firms as a cloud-based solution. Banks and online lenders often pay tens or hundreds of thousands of dollars for similar platforms to run their business lending operations. As a result, the subscription plans offered on the Portal help to pay for the range of benefits provided and cover the costs to provide the service to firms in the first place.</p>`,
             },
             {
               "ques_html": `<div>What are the subscription options available for the Portal and how do they differ?</div>`,
               "ans_html": `<p>Details on updated pricing and specs for different Portal subscription plans can be found at: <a href="/" target="_blank" class="uline ptc">CPALoanPortal.com</a>. Your firm’s subscription plan will determine the features and benefits that your firm can access. All firms must have an active subscription in order to provide financing services to their clients via the Portal.</p>`,
             },
             {
               "ques_html": `<div>What is the firm verification process and why is it required for my firm to use the Portal?</div>`,
               "ans_html": `<p>Users must be independently verified by Biz2Credit and CPA.com prior to creating or submitting applications through the CPA Business Funding Portal. The Portal is specifically developed for CPA firms, Accounting Firms, Tax Professionals, Attorneys, Enrolled Agents (EA), and Certified Financial Planners (CFP) actively supporting their clients in connecting to a trusted source of financing. The tool was developed in part to ensure adherence by verified users to the rules, guidelines and standards established by the Small Business Administration (SBA) as part of the Paycheck Protection Program (PPP) application process. These standards continue to be enforced to maintain the integrity of the Portal.</p>`,
             },
             {
               "ques_html": `<div>I don’t have a CPA at my firm, is my firm still eligible for the Portal?</div>`,
               "ans_html": `<p>Yes, firms without a CPA on staff can qualify for access to the Portal if they perform accounting, advisory, financial planning, tax, or other related business services for clients, subject to the firm verification process.</p>`,
             },
             {
               "ques_html": `<div>What are the usage policies for the Portal?</div>`,
               "ans_html": `<p>All Portal users must be verified in accordance with the terms of the user agreement. If Biz2Credit and CPA.com are unable to verify a user as a CPA, Tax Preparer, Accountant, or other appropriately licensed professional or adviser, you will not be granted access to the platform. Applications for financing that are submitted on the Portal are monitored for their quality, integrity, and legitimacy. Suspicious or questionable activity related to your account will result in the immediate suspension of your applications and possible account de-activation. For Premium and Enterprise users, a subscription fee refund will be granted upon request. Once a refund has been issued, your account will be deemed ineligible to receive commission payments. To request a refund, please email: <a href="mailto:cpasupport@biz2credit.com">cpasupport@biz2credit.com</a>`,
             },
             {
               "ques_html": `<div>How long do Portal subscriptions last and how are they renewed?</div>`,
               "ans_html": `<p>Portal subscription plans are set annually and will renew automatically unless your firm Super Admin requests to cancel the plan renewal from the My Plan section of the Portal. The annual subscription period starts on the first date of account activation and lasts until the day before the anniversary of that date.</p>`,
             },
             {
               "ques_html": `<div>How do I change my subscription payment method?</div>`,
               "ans_html": `<p>Payment methods may be modified via the Portal under the My Plan section by the Super Admin staff user. Credit card details are stored securely through a PCI-compliant card processor, WorldPay part of FIS Global (NYSE: FIS).</p>`,
             },
             {
               "ques_html": `<div>How do I opt-out of my subscription?</div>`,
               "ans_html": `<p>To opt out of your firm’s renewal prior to your renewal date, you may email: <a href="mailto:cpasupport@biz2credit.com">cpasupport@biz2credit.com</a>.</p>`,
             },
             {
               "ques_html": `<div>How do I change my subscription level?</div>`,
               "ans_html": `<p>From the My Plan page, you will have the ability to upgrade your account to a higher subscription plan level, or to cancel your ongoing subscription at the next date of renewal. To opt out of your firm’s renewal prior to your renewal date, you may email: <a href="mailto:cpasupport@biz2credit.com">cpasupport@biz2credit.com</a>.</p>`,
             },
             {
               "ques_html": `<div>Does the Portal support the payment of agent fees or commissions?</div>`,
               "ans_html": `<p>Yes. As part of the user agreement, CPAs or other advisors are entering into an agreement with Biz2Credit and are eligible to receive commission payments. Only paid subscribers of the Portal are eligible to receive commissions for applications that are funded directly through Biz2Credit, subject to the application limits of the selected subscription plan. </p>`,
             },
             {
               "ques_html": `<div>If my firm doesn’t want to receive commissions, can we opt-out?</div>`,
               "ans_html": `<p>Yes, firms will have an option to ‘opt-out’ of receiving commission payments on any given funded application if they so choose.</p>`,
             }         
         ]
    },
    {
        "header_key" :`<h3 class="fs18 fwb pt-4 mb-1"><u>Portfolio of Financing Products and the Application Process:</u></h3>`,
        "ques_ans":[
            {
              "ques_html": `<div>What are the different financing products available to my clients?</div>`,
              "ans_html": `<p>The latest on different financing products available to clients like Term Loan, Revenue-Based Financing and more, is available at: <a href="/" target="_blank" class="uline ptc">CPALoanPortal.com</a>.</p>`,
            },
            {
                "ques_html": `<div>What are the rates, terms, and associated costs for each financing product?</div>`,
                "ans_html": `<p>General information on financing terms, amounts, and repayment structure can be found at: <a href="/" target="_blank" class="uline ptc">CPALoanPortal.com</a>. Specific terms and rates depend on business qualifications and are to be shared during the application and underwriting process.</p>`,
            },
            {
                "ques_html": `<div>Who is responsible for processing and funding the financing applications submitted on the Portal?</div>`,
                "ans_html": `<p>All applications submitted via the CPA Business Funding Portal are processed by Biz2Credit. Biz2Credit may submit these financing applications to various lenders or affiliated entities in accordance with the Business Finance Terms and Conditions. In most cases, Biz2Credit's direct funding affiliate, Itria Ventures, LLC, is the party of first resort for reviewing a funding application to increase efficiency and reliability for subscribing firms. Application submissions through the CPA Business Funding Portal will be processed by Biz2Credit and Itria Ventures, LLC. In certain cases, Biz2Credit may also engage SBA-licensed third parties to process applications as well. Biz2Credit is not a lender.</p>`,
            },
            {
                "ques_html": `<div>What are the initial qualification requirements for each financing product?</div>`,
                "ans_html": `<p>Most clients can qualify for financing if they have annual revenue greater than $250,000, have a credit score of 660 or higher, and have been in business at least 18 months. Qualifications vary by product and are subject to final underwriting. We encourage CPA firms to apply on behalf of their clients as long as the client has been in business at least 6 months and has generated at least $8,000 in monthly revenues during that timeframe.</p>`,
            },
            {
                "ques_html": `<div>Are there any industries that are restricted from financing on the Portal?</div>`,
                "ans_html": `<p>Businesses in the following industries are restricted from financing on the Portal: Cannabis, Firearms, Gambling or Gaming, Oil/Fracking, Bail Bonds, Adult Entertainment, Mining, and potentially others.</p>`,
            },
            {
                "ques_html": `<div>How will the qualification process affect my client’s credit score?</div>`,
                "ans_html": `<p>CPAs can prequalify a client without requiring a credit pull for select products including Revenue-Based Financing and Term Loans. Upon submission of the application, there will be a soft credit pull that is used to pre-approve clients for financing, and at the time of the final underwriting, there will be a hard credit pull for owners of the business.</p>`,
            },
            {
                "ques_html": `<div>Is there a lien applied to businesses that take financing through the Portal?</div>`,
                "ans_html": `<p>When applying for a financing product funded by Biz2Credit, a UCC lien is filed at the time the client is funded. For other financing products like SBA loans, it will be up to the individual lender.</p>`,
            },
            {
                "ques_html": `<div>Do all partners or shareholders need to consent for approval to apply for financing?</div>`,
                "ans_html": `<p>All owners with at least a 20% stake in the business must be added to the application during the owners’ consent step. That includes beneficial owners of any parent entities that own the business.</p>`,
            },
            {
                "ques_html": `<div>What are the steps involved in the process that my firm is responsible for, and what are the steps that my client is responsible for?</div>`,
                "ans_html": `<p>Firms can lead their clients through the application preparation process but cannot act as a substitute for the client’s participation following submission of the application. For a detailed walkthrough of the application process, see the How-to videos on the <a href="/resource-hub" target="_blank" class="uline ptc">Resource Hub.</a></p>`,
            },
            {
                "ques_html": `<div>What stages does a financing application go through on the Portal?</div>`,
                "ans_html": `<p>First an application must be prepared by the CPA on the Portal. This usually involves a series of steps where the CPA must fill out details such as the company legal structure, basic details like address, ownership structure, financial information, and the details of the financing request such as financing amount, purpose, and duration. After the ownership structure is outlined, the CPA must obtain client’s credit consent through the Portal. This can be assigned to a client while the CPA continues to work on the next step in the process which is documentation. At that point, the CPA must provide all necessary documentation to support the application details previously entered. Finally, once all steps are complete, the CPA can submit the application to the lender digitally through the Portal.</p>

                <p>At this point, the application will now proceed to the lender for initial review and underwriting. If a decision can be made based on the application, then the lender will issue the decision and instruct on the specific closing requirements (in the event of an approval decision). If more information is needed, the lender will request this from the CPA and/or client through digital communications via email and the Portal.</p>
                
                <p>Once all closing requirements are met, the client will receive their funds via ACH into the account that was used on the application. Throughout the process the CPA can follow along with the status of the application from the Portal dashboard where a status for each application is indicated.</p>`,
            },
            {
                "ques_html": `<div>How can I make a change to an application that has already submitted to the lender?</div>`,
                "ans_html": `<p>Once an application has been submitted, the best way to request updates to the application information or documentation submitted is through the Underwriting Notes feature of your Portal account. A member of the lender’s financing team will send a message through this mechanism requesting additional information or documents. Then you can post a message directly back to that lending specialist with the corrections or adjustments needed. You will also receive an email notification whenever there is an Underwriting Note that requires your attention.</p>`,
            },
            {
                "ques_html": `<div>Why isn’t the credit consent status being updated although the client said it’s complete?</div>`,
                "ans_html": `<p>Clients must be sure to push the button to confirm the details in the credit consent, not simply open the link included in the email. If the client has already pressed that confirmation button, then the CPA can click on the ‘refresh’ icon in order to sync the status of the credit consent with their account. If the client has completed the activity, then it will resync. It may take 5-10 minutes before the system can pull in the updated consent status, so just be aware that you may experience a delay accordingly.</p>`,
            },
            {
                "ques_html": `<div>What is needed to set up ACH payment for my clients?</div>`,
                "ans_html": `<p>Small business owners can set up their account for ACH payment within their Biz2Credit account, or by providing the key ACH details to their small business financing specialist.</p>`,
            },
            {
                "ques_html": `<div>Do my clients need a Biz2Credit account to be funded through Biz2Credit?</div>`,
                "ans_html": `<p>All clients whose financing applications are submitted through the CPA Business Funding Portal to Biz2Credit will have a Biz2Credit account created automatically for them so they can continue to access their information and manage their financing at any time.</p>`,
            },
            {
                "ques_html": `<div>How will my client receive the funds?</div>`,
                "ans_html": `<p>Clients will receive funds to their business bank account via ACH or wire transfer.</p>`,
            },
            {
                "ques_html": `<div>How long is the approval process?</div>`,
                "ans_html": `<p>Approval and underwriting processes will vary depending on the qualifications of the business and the product being sought. However, for some products, underwriting and approval can be completed within 48 hours on business days.</p>`,
            },
            {
                "ques_html": `<div>How long until my client receives the funds in their account?</div>`,
                "ans_html": `<p>Once your client has completed the final funding process, funds are typically available in their account subject to processing times at the bank’s side, and usually within 72 hours during business days.</p>`,
            },
            {
                "ques_html": `<div>Who can I contact for support with financing products and applications?</div>`,
                "ans_html": `<p>For assistance with financing product and applications, please email: <a href="mailto:cpasupport@biz2credit.com">cpasupport@biz2credit.com</a> and a representative will be in touch as soon as possible.</p>`,
            }        
        ]
    },
    {
        "header_key" : `<h3 class="fs18 fwb pt-4 mb-1"><u>Portal Functionality:</u></h3>`,
        "ques_ans":[
            {
              "ques_html": `How can I add a new staff or client user?</div>`,
              "ans_html": `<p>As the Super Admin staff user, to create new staff and manage existing staff, click on the Manage Users tab. Find detailed directions on how to set up new staff users, visit the User Management How-to video on the <a href="/resource-hub" target="_blank" class="uline ptc">Resource Hub.</a></p>`,
            },
            {
              "ques_html": `<div>How can I start a new client financing application?</div>`,
              "ans_html": `<p>To begin a new financing application, from the dashboard, click on the Create a new application icon under the Action column for that client. For a detailed walkthrough of the application process, visit the Financing Applications How-to video on the <a href="/resource-hub" target="_blank" class="uline ptc">Resource Hub.</a></p>`,
            },
            {
              "ques_html": `<div>What is the “prequalification tool” and what is it used for?</div>`,
              "ans_html": `<p>At the start of financing applications on the Portal, the prequalification tool allows you to check if your client may be qualified for funding based on basic information. By submitting your client’s annual revenue, desired funding amount, time in business, and credit score range, you can identify a projected funding amount available without impacting the client’s credit score.</p>`,
            },
            {
              "ques_html": `<div>Where can I see the statuses of my different client’s applications?</div>`,
              "ans_html": `<p>Firms will be able to see a status update on their CPA Business Funding Portal dashboard. Clients will be able to see a status update on their Biz2Credit account dashboard.</p>`,
            },
            {
              "ques_html": `<div>What are the different communications my client can expect to receive during and after the application process?</div>`,
              "ans_html": `<p>During the application process, prior to submitting, an email will be sent to the client for a consent to a credit pull coming from: <a href="mailto:cpa@biz2credit.com">cpa@biz2credit.com</a>. During the underwriting process, clients may receive emails from Biz2Credit staff with an email format like this: <a href="mailto:first.last@biz2credit.com">first.last@biz2credit.com,</a> some cases for this may be to request additional information, or clarification.</p>`,
            },
            {
              "ques_html": `<div>Where can I learn more about how to best utilize the Portal and its features?</div>`,
              "ans_html": `<p>You can find help content, user guides, required documents checklists, how-to videos, marketing templates, and more on the <a href="/resource-hub" target="_blank" class="uline ptc">Resource Hub.</a></p>`,
            },
            {
              "ques_html": `<div>When will my pending commissions be paid?</div>`,
              "ans_html": `<p>Commissions for all qualified financing deals are paid in arrears at the end of each billing month. For most financings, the commission is paid once the first payment from the client has been received. This is typically within 45 days of funding, which means that payment on pending commissions may take place between 15 days and 45 days after the end of the month in which the application was funded. your firm must have a w9 form on file and a bank account added to your profile in order for the commission payments to be processed timely. Please note: if your firm elected to pay your subscription fee via commissions, all subscription costs must be completely paid off before commission payments are made.</p>`,
            }                      
        ]
    },
    {
        "header_key" : `<h3 class="fs18 fwb pt-4 mb-1"><u>Data and Security:</u></h3>`,
        "ques_ans":[
            {
              "ques_html": `How does Biz2Credit use my data?</div>`,
              "ans_html": `<p>Any information or data you submit on the CPA Business Funding Portal is for the purposes of providing you access to the Portal and ensuring security. Your information is stored in a secure, encrypted server and where it is only accessible to Biz2Credit. Biz2Credit does not share your data or your client's data with any third parties unless you authorize the data to be shared for the purpose of providing a service to you.</p>`,
            },
            {
              "ques_html": `<div>What is the data privacy policy?</div>`,
              "ans_html": `<p>You can read Biz2Credit’s Privacy Policy on their website at <a href="https://www.biz2credit.com/privacy-policy" target="_blank" class="uline ptc">biz2credit.com/privacy-policy.</a> If you wish your data to be removed, you may make your request in writing and submit it to <a href="mailto:help@cpaloanportal.com">help@cpaloanportal.com.</a></p>`,
            },
            {
              "ques_html": `<div>What is the level of data security?</div>`,
              "ans_html": `<p>Biz2Credit runs its software using the most advanced data security and encryption standards in the financial services industry. They take the security of your sensitive financial data very seriously. All information or data submitted on the site is stored in an encrypted database and cannot be accessed by any unauthorized third party. The site conforms with ISO-27001 and SOC-2 compliance standards and is regularly tested for compliance with these standards.</p>`,
            },
            {
              "ques_html": `<div>What third parties does the Portal integrate with?</div>`,
              "ans_html": `<p>The CPA Business Funding Portal integrates into select third party data processing services to improve the application experience for CPAs. This includes payroll, credit bureau and bank account data integrations, among others. When connecting a client's account directly to a third-party service, the CPA will be required to send a verification to the client to complete the process. Due to compliance reasons, the link to connect the account has to be sent to the customer to authenticate and this feature cannot be bypassed.</p>`,
            },
            {
              "ques_html": `<div>How can my firm and my clients control their data?</div>`,
              "ans_html": `<p>Firms and small business owners can request access to their data subject to the Biz2Credit Privacy Policy. You can reach out to <a href="mailto:cpasupport@biz2credit.com">cpasupport@biz2credit.com</a> or <a href="mailto:privacy@biz2credit.com">privacy@biz2credit.com</a> with any questions or requests related to use of your firm’s or client’s data.</p>`,
            }
        ]
    },            
    {
        "header_key" : `<h3 class="fs18 fwb pt-4 mb-1"><u>Financing Advisory Services:</u></h3>`,
        "ques_ans":[
            {
              "ques_html": `<div>What is Financing Advisory Services?</div>`,
              "ans_html": `<p>Financing Advisory Services is a subset of Client Advisory Services or CAS that focuses on cash flow management, lending assistance, and financial and strategic planning. By combining economic and financial expertise, access to data, and advanced technology, accounting firms can fulfill the role of a trusted business advisor to their clients.</p>`,
            },
            {
              "ques_html": `<div>By offering Financing Advisory Services, will we jeopardize our independence?</div>`,
              "ans_html": `<p>In general, providing financing advisory services, including lending assistance and access to alternative lending through fintech partnerships does not inherently impair independence.<br/><br/>Each firm is responsible for assessing and monitoring their independence requirements based on services they’re providing. CPAs and other professional staff may undertake a fiduciary obligation to clients when they assume specific roles. For firms providing attest services, receiving commissions on funded loans may impair independence. A firm's receipt of a commission from an attest client is a violation of the Code of Professional Conduct.<br/><br/>For additional information or inquiries regarding independence, you may review the AICPA’s guidance at: <a href="https://www.aicpa.org/" target="_blank" class="uline ptc">AICPA.org</a>, or e-mail the AICPA Professional Ethics Division at <a href="mailto:ethics@aicpa.org." class="uline ptc">ethics@aicpa.org.</a></p>`,
            },
            {
              "ques_html": `<div>Does financing advisory require a separate engagement letter?</div>`,
              "ans_html": `<p>It depends on the firm. Some firms bake it into the engagement letter for existing CAS engagements. Other firms have separate engagements requiring separate engagement letters. While an engagement letter may not be a requirement, for firms receiving commissions, disclosure is often necessary.</p>`,
            },
            {
              "ques_html": `<div>How are CPA firms billing their clients for financing advisory services?</div>`,
              "ans_html": `<p>Each firm is different and thus have different billing strategies. Some package Financing Advisory Services with other advisory and accounting services like CAS. Other firms offer Financing Advisory Services as a stand-alone or ad-hoc service. Some firms do both, while other firms are offsetting client fees using commissions earned on funded applications. There is no universal answer. To speak with a CPA.com representative on financing advisory billing strategies, visit: <a href="https://www.cpa.com/contact?form=sales&product=business-funding" target="_blank" class="uline ptc">CPA.com Contact Us</a>.</p>`,
            },
            {
              "ques_html": `<div>How are CPA firms advertising these services to their clients?</div>`,
              "ans_html": `<p>Often this happens one of two ways: 1) By leveraging one-to-many channels like firm newsletters, email blasts, social media, webpages, etc. 2) By leveraging one-to-one channels and asking intentional questions of each individual client based on their situation. To gain access to resources and templates that can help your firm get started marketing and selling its own Financing Advisory Services, visit the <a href="/resource-hub" target="_blank" class="uline ptc">Resource Hub.</a></p>`,
            },
            {
              "ques_html": `<div>Where can I learn more about practice development for Financing Advisory Services?</div>`,
              "ans_html": `<p>Biz2Credit and CPA.com hold numerous webinars which cover key practice development topics for firms to optimize their Financing Advisory Services and to make the most of the tools available to them. To view on-demand webinars, visit: <a href="https://www.cpa.com/webinars?partner_id=866&category_id=All" target="_blank" class="uline ptc">CPA.com Webinars</a>. To register for upcoming monthly service webinars, visit: <a href="https://register.gotowebinar.com/rt/6230800872579603215?source=CPA+Portal+Website" target="_blank" class="uline ptc">Monthly Service Update Webinar Registration</a>.</p>`,
            }                   
        ]
    },
    {
        "header_key" : `<h3 class="fs18 fwb pt-4 mb-1"><u>SBA 7(a) Loan :</u></h3>`,
        "ques_ans":[
            {
              "ques_html": `<div>What is an SBA 7(a) loan?</div>`,
              "ans_html": `<p>An SBA 7(a) loan is a type of business loan provided by approved lenders and guaranteed by the U.S. Small Business Administration (SBA). It is designed to help small businesses access financing for various purposes.</p>`,
            },
            {
              "ques_html": `<div>How does the SBA 7(a) loan program work?</div>`,
              "ans_html": `<p>Under the SBA 7(a) loan program, the SBA guarantees a portion of the loan amount provided by approved lenders, reducing the risk for lenders. This encourages lenders to provide loans to small businesses that may not qualify for traditional financing.</p>`,
            },
            {
              "ques_html": `<div>What are the eligibility requirements for an SBA 7(a) loan?</div>`,
              "ans_html": `<p>Eligibility requirements for an SBA 7(a) loan include being a for-profit business operating in the United States, meeting size standards defined by the SBA, demonstrating the ability to repay the loan, and having exhausted other financing options.</p>`,
            },
            {
              "ques_html": `<div>What can an SBA 7(a) loan be used for?</div>`,
              "ans_html": `<p>An SBA 7(a) loan can be used for various purposes, including revenue-based financing, purchasing inventory, acquiring real estate or equipment, refinancing existing debt, and funding business expansion or acquisitions.</p>`,
            },
            {
              "ques_html": `<div>What are the advantages of obtaining an SBA 7(a) loan?</div>`,
              "ans_html": `<p>Advantages of an SBA 7(a) loan include longer repayment terms, lower down payments, competitive interest rates, access to larger loan amounts, and the SBA guarantee which reduces the risk for lenders.</p>`,
            },
            {
              "ques_html": `<div>What documents are needed to apply for an SBA 7(a) loan?</div>`,
              "ans_html": `<p>Necessary documentation includes business/affiliate documents (e.g., Full IRS Tax Returns, Bank Statements, Business Debt Schedule) and individual documents (a valid Government-issued ID, Personal Financial Statements). A full list of necessary documentation can be found in the <a href="assets/files/CPA_SBA_Checklist.pdf" download = "CPA_SBA_Checklist.pdf" target="_blank" class="uline ptc">SBA Checklist</a> located on the <a href="/resource-hub" target="_blank" class="uline ptc">Business Funding Portal Resource Hub</a>.</p>`,
            },
            {
              "ques_html": `<div>What information should I collect from my client ahead of time?</div>`,
              "ans_html": `<p>Please refer to our <a href="assets/files/SBA_Form_1919_Questionnaire.pdf" download = "SBA_Form_1919_Questionnaire.pdf" target="_blank" class="uline ptc">SBA Loan Questionnaire</a> located on our <a href="/resource-hub" target="_blank" class="uline ptc">Resource Hub.</a> for a list of relevant questions that your client will need to provide answers for.</p>`,
            },
            {
              "ques_html": `<div>Can an SBA 7(a) loan be used for revenue-based financing or expansion purposes?</div>`,
              "ans_html": `<p>Yes, SBA 7(a) loans can be used for revenue-based financing, business expansion, purchasing inventory, acquiring equipment, and other legitimate business purposes. The flexibility of SBA 7(a) loans allows borrowers to allocate funds based on their business needs.</p>`,
            },        
            {
              "ques_html": `<div>Are there any special considerations or benefits for veterans or minority-owned businesses?</div>`,
              "ans_html": `<p>Yes, the SBA provides special considerations and benefits for veterans, service-disabled veterans, and minority-owned businesses. These may include reduced fees, targeted programs, and additional resources to support these specific groups of entrepreneurs.</p>`,
            },         
            {
              "ques_html": `<div>How can I apply for an SBA 7(a) loan through the CPA Business Funding Portal?</div>`,
              "ans_html": `<p>You can begin an application for an SBA 7(a) loan just as you would for any of our other financial offering on our portal. Important reminder that the SBA application is open only to the portal subscriber, so make sure to log in to your account and select a plan (Premium or Enterprise) so you can begin applying for your clients. To begin a 7(a)-loan application, you have two options: first, either select an existing client, or select “Create New Client.” You will then be able to begin the application where you will fill in Business Name, Contact Name, Phone number and email address. Then select “SBA Loan” and hit submit.</p>`,
            },         
            {
              "ques_html": `<div>How long does it typically take to get approved for an SBA 7(a) loan?</div>`,
              "ans_html": `<p>The time it takes to get approved for an SBA 7(a) loan can vary depending on several factors, such as the complexity of the loan application and the lender's processing times. Typically, the SBA recommends that lenders take no more than 10 business days to process and make a decision on an SBA loan application.</p>`,
            },        
            {
              "ques_html": `<div>What is the role of my CPA firm in the SBA 7(a) loan application process?</div>`,
              "ans_html": `<p>As the CPA firm, you will complete the SBA 7(a) loan application on behalf of your client. To ensure that you have all the necessary information to fill out the SBA 1919 form, we recommend <a href="assets/files/SBA_Form_1919_Questionnaire.pdf" download = "SBA_Form_1919_Questionnaire.pdf" target="_blank" class="uline ptc">downloading a copy of it</a> from our <a href="/resource-hub" target="_blank" class="uline ptc">Resource Hub</a>. You can provide this form to your client and ask them to fill it out and return it to you before you begin the application process. This will help you expedite the application and ensure that all the required information is provided accurately. Once all necessary documentation and consents have been provided, please do a final review of the loan application to ensure its accuracy. When finished, click “submit” to complete your application. After submission you’ll be able to return to the application to view all the information that you provided. You will continue to receive updates about the application through case notes in your CPA Business Funding Portal account.</p>`,
            }         
        ]
   }
 ]

export const PREQUALIFICATION_MIN_REQUIREMENT = {
  value: 74999
}

export const tool_tip_values = {
  "myClient_tooltip_content" : "Prequalification estimates only apply to Revenue-Based Financings and are contingent on verification of the information provided. Prequalification is not a financing approval, just an estimate of how much funding your client might receive based on the limited information you submitted. Not all applicants will prequalify for financing. If your client prequalifies, you will need to submit additional documents to complete the application, which will then be reviewed based on the documents and information in the completed application, funding eligibility criteria, other  <a class='btn-link uline cp wtc' href=\"https://www.sba.gov/document/support-sba-franchise-directory\" target=\"_blank\">terms and conditions</a>, and a signed final financing agreement. All commercial financings are made at the sole and final discretion of the lender."
}


