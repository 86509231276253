import { Component, OnInit, ViewChild, ElementRef, ViewChildren, Inject } from '@angular/core';
import { FormFieldInterface } from '../../../interfaces/FormFieldInterafce';
import { FormGroup, FormArray } from '@angular/forms';
import { FormGenerateService, CommonService, JQueryService, NumberDirective } from '@rubicon/utils';
import { Store, select } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { isArray } from 'ngx-bootstrap/chronos';

@Component({
  selector: 'rubicon-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss']
})

export class GroupComponent implements OnInit {
  field: FormFieldInterface;
  group: FormGroup;
  slug: string;
  index: number;
  index_field: number;
  length: number;
  totalType: string;
  // show_fields = 1;
  fields: any = [];
  thisIsMeSelected = false;
  forAddMoreGroup = [];
  constructor(
    private formGenerate: FormGenerateService,
    private jqueryService: JQueryService,
    private store: Store<any>,
    private common: CommonService,
    @Inject("environment") public environment
  ) { }

  ngOnInit(): void {
    if (this.slug === 'personal_financial' || this.slug === 'business_financial') {
      this.store.select('app').pipe(take(1)).subscribe(rootState => {
        if(rootState?.appData) {
          this.forAddMoreGroup = rootState.appData?.addMoreData;
          this.totalType = this.field?.total?.length ? 'cfa' : '';
        }
      })
    } else if (this.slug === 'loan-type') {
      this.totalType = this.field?.total?.length ? 'bottomAlign' : '';
    } else {
      this.totalType = this.field?.total?.length ? 'topAlign' : '';
    }
    if(isArray(this.field?.config) && this.field.config.length > 0) {
      let name = this.field.name;
      this.group.controls[name].valueChanges.subscribe(data => {
        setTimeout(() => {this.changeOtherValues(this.field.config)}, 0);
      })
    }
    if(this.field?.multiple_default && this.field?.multiple ){
      let index = 0
      for (index = 0; index < this.field?.multiple_default; index++) {
        this.addMore()
      }
    }
  }

  changeOtherValues(configArray) {
    configArray.forEach(grpItem => {
      let calculatedValue: any = 0;
      let anyInputValue = [];
        if (grpItem.sameGroup) {
          grpItem.valuesArray.forEach((element, index) => {
            let value = this.group.controls[grpItem.groupName].get(element).value;
            let operationType = grpItem.operationType;
            anyInputValue.push(value ? true : false);
            value = value ? parseFloat(value): 0;
            if (index === 0) {
              calculatedValue = value;
            } else {
              calculatedValue = this.calculatedVal(operationType, calculatedValue, value);
            }
          })
        } else {
          grpItem.valuesArray.forEach((element, index) => {
          let value = this.group.controls[element.valueGroup].get(element.valueKey).value;
          let operationType = element.operationType;
          anyInputValue.push(value ? true : false);
          value = value ? parseFloat(value): 0;
          if (index === 0) {
            calculatedValue = value;
          } else {
            calculatedValue = this.calculatedVal(operationType, calculatedValue, value);
          }
          })
        }
        if (grpItem.type === 'ratio') {
          calculatedValue = calculatedValue ? (parseFloat(calculatedValue).toFixed(2)) : '';
        } else {
          calculatedValue = (calculatedValue || calculatedValue === 0) ? parseFloat(calculatedValue?.toFixed(2)) : '';
        }

        if (grpItem.percentage) {
          let net_sales_amt_value = this.group.controls['income_statement'].get('net_sales_amt').value;
          if (grpItem.groupName === 'income_statement' && !net_sales_amt_value) {
            this.group.controls[grpItem.groupName].get(grpItem.keyName).setValue('', { emitEvent: false });
          } else {
            if (anyInputValue.every(val => val === true)) {
              let calVal = parseFloat((calculatedValue*100).toFixed(2));
              this.group.controls[grpItem.groupName].get(grpItem.keyName).setValue(`${calVal}%`, { emitEvent: false });
            }
            else {
              this.group.controls[grpItem.groupName].get(grpItem.keyName).setValue('', { emitEvent: false });
            }
        }
        }
        else if (anyInputValue.some(val => val === true)) {
          if (grpItem.keyName === 'income_tax_expense_amt' && !anyInputValue.every(val => val === true)) {
            calculatedValue = '';
          }
          this.group.controls[grpItem.groupName].get(grpItem.keyName).setValue(calculatedValue, { emitEvent: false});
        }
        else {
          this.group.controls[grpItem.groupName].get(grpItem.keyName).setValue('', { emitEvent: false});
        }
    })
  }

  calculatedVal(operation, val1, val2, toFrom?: boolean) {
    if (operation === 'sum') {
      return val1 + val2;
    }
    else if (operation === 'subtract') {
        if ((val1 < 0 && val2 < 0) || (val1 > 0 && val2 > 0) || (val1 < 0 && val2 >= 0) || (val1 === 0 && val2 >= 0) || (val1 === 0 && val2 < 0)) {
          return val1 - val2;
        } else {
          return val1 + val2;
        }
    }
    else if (operation === 'divide') {
      if(val1 && val2) {
          return val1 / val2;
      } else {return ''; }
    }
    else if (operation === 'multiply') {
      return val1 * val2;
    }
  }

  /**
   * function to get controls of a form array
   */
  get control(): any {
    return (this.group.get(this.field.name) as FormGroup).controls;
  }

  /**
   * function to add controls to a formarray
   */
  addMore() {
    if ((this.slug === 'personal_financial' || this.slug === 'business_financial') && this.forAddMoreGroup.length > 0) {
      this.forAddMoreGroup.forEach(addMoreVal => {
        if(addMoreVal.relatedGroup === this.field.name) {
        let getKeyValue = this.group.controls[addMoreVal.value].value;
        getKeyValue = getKeyValue ? getKeyValue+1: 1;
        this.group.controls[addMoreVal.value].setValue(`add_${getKeyValue}`);
        }
      })
    } else {
      const formArray = this.group.get(this.field.name) as FormArray;
      let group_fields = this.field.group_fields;
      formArray.push(this.formGenerate.createControl(group_fields));
      if (this.slug === 'applicant-details') {
        let owenership = 0;
        this.group.get(this.field.name).value.forEach(field => {
          if (field.ownership)
            owenership += field.ownership;
        });
        formArray.controls[formArray.length-1].get('ownership').patchValue(100-owenership);
      }
    }
  }

  /**
   * function to get formarray
   */
  get fieldArray(): FormArray {
    return this.group.controls[this.field.name] as FormArray;
  }

  /**
   * function to remove controls to a formarray
   * @param index
   */
  showPopup(index, length) {
    this.index_field = index;
    // Saving the length of the form field -- so that if it is equal to 0 , the form can be reset
    this.length = length;
    this.jqueryService.showModal('#' + this.field.name + '-remove');
  }

  removeForm(i, len) {

    /**Feature added to detect changes from any component that array delete in form group*/
    if(this.field?.config?.event_on_remove){
      setTimeout(()=>{
        this.common.sendEventListener({ name: this.field.name, group: this.group, index: this.index, value: this.group.get(this.field.name).value, slug: this.slug, is_deleted: true });
      },0);
    }

    if ((this.slug === 'personal_financial' || this.slug === 'business_financial')) {
      this.forAddMoreGroup.forEach(addMoreVal => {
        if(addMoreVal.relatedGroup === this.field.name) {
          this.group.controls[addMoreVal.value].setValue(`remove_${i}`);
        }
      })
    } else {
      if (len === 1) {
        this.group.reset();
      } else {
      //   if (this.slug === 'loan-info') {
      //   const control = (this.group.get('purpose_of_loan') as FormArray).controls[i];
      //   if (control.get('is_primary').value === true) {
      //     this.thisIsMeSelected = false;
      //   }
      // }
        this.fieldArray.removeAt(i);
      }
    }
  }
  /**
   * function to get total of a field
   */
  total(item: string) {
    let total: any = 0;
    let valuePresent = false;
    for (const group of this.control) {
      let value = 0;
      if (group.get(item).value) {
        valuePresent = true;
        value = (typeof group.get(item).value == "string" && group.get(item).value.includes('$ ')) ? (group.get(item).value).replace('$ ', '').replace(/,/g, '') : group.get(item).value;
      }
      total += +value;
    }
    if (!valuePresent) {
      total = '';
    }
    if ((this.slug === 'personal_financial' || this.slug === 'business_financial') ) {
      let temp = this.field.config.save_total_group;
      let keyValue;
      if (temp) {
        keyValue = this.group.controls[this.field.config.save_total_group]?.get(this.field.config?.save_total)?.value;
      }else {
        keyValue = this.group.value[this.field.config.save_total];
      }
      if (!isNaN(total) && (total !== keyValue)) {
        if (temp){
          this.group.controls[this.field.config.save_total_group].get(this.field.config?.save_total).setValue(total, { emitEvent: false });
        }else {
          this.group.controls[this.field.config?.save_total].setValue(total, { emitEvent: true });
        }
        if (this.field.config.changeAlso) {
          setTimeout(() => {this.changeOtherValues(this.field.config?.changeAlso)}, 50);
        }
      }
    }
    return total.toLocaleString();
  }

  saturationReached() {
    if (this.slug === 'applicant-details') {
      let owernership = 0;
      this.group.get(this.field.name).value.forEach(field => {
        if (field.ownership)
          owernership += field.ownership;
      });
      return owernership < 100;
    } else {
      return true;
    }
  }

  setOwnerValue(event, index) {
    if (event && event.target.checked) {
      this.store.pipe(
        select('app'),
        take(1),
      ).subscribe(rootState => {
        if (rootState && rootState.userData) {
          const controls = (this.group.get('owners') as FormArray).controls;
          controls[index].patchValue(rootState.userData.user);
          controls[index].get('applicant_phone').patchValue(rootState.userData.user.phone_number);
          controls[index].get('is_primary').patchValue(event.target.checked);
          this.thisIsMeSelected = true;
        }
      });
    }
  }
  isPrimarySelected(index) {
      this.thisIsMeSelected=this.thisIsMeSelected || (this.group.get('owners')as FormArray).controls[index].get('is_primary').value;
      return this.thisIsMeSelected;
  }
}

