import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { environment } from '@env/environment';
import { TaskInfoService, CommonService } from '@rubicon/utils_custom';
import { CUSTOMER_PORTAL } from '@rubicon/interface/task-info';
import { logout } from 'libs/customer-portal/ui-kit/src/lib/+state/ui-kit.action';

@Component({
  selector: 'sba-header',
  templateUrl: './sba-header.component.html',
  styleUrls: ['./sba-header.component.scss']
})
export class SbaHeaderComponent implements OnInit, OnDestroy {
  userData: any;
  rootStateSubscription: Subscription;
  fullName = '';
  cdnUrl: string = environment.cdnURL;
  constructor(private store: Store<any>, private taskInfoService: TaskInfoService, private common: CommonService) { }

  ngOnInit() {
    this.rootStateSubscription = this.store.pipe(select('app'))
      .subscribe(rootState => {
        if (rootState.userData && rootState.userData.user) {
          this.fullName = rootState.userData.user.first_name + ' ' + rootState.userData.user.last_name;
        }
      });
  }

  /**
   * function to logout
   */
  logout() {
    this.taskInfoService.saveTaskInfo({
      slug: CUSTOMER_PORTAL.logout
    }, {}).subscribe((res: any) => {
      this.common.setLogin(false);
      if (res && res.nextTask) {
        this.common.navigate(res.nextTask.value);
      }
      this.store.dispatch(logout({}));
    });
  }

  ngOnDestroy() {
    this.rootStateSubscription.unsubscribe();
  }
}
