import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldInterface } from '@rubicon/interface/form-field';

@Component({
  selector: 'rubicon-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit {
  @ViewChild('passpolicy') passpolicy: ElementRef;
  isPasswordVisible: boolean = false;
  password_input_type: string = 'password';
  field: FormFieldInterface;
  group: FormGroup;
  slug: string;
  indexVal: Number;
  constructor() { }

  ngOnInit() { 
  //    if(!this.passpolicy.nativeElement.innerHTML){
  //   this.passpolicy.nativeElement.hidden=true;
  // }
    this.indexVal=new Date().getTime();
  }

  showPassword(isPasswordVisible){
    this.isPasswordVisible = !isPasswordVisible;
    this.isPasswordVisible ? this.password_input_type = 'text' : this.password_input_type = 'password';
  }
}
