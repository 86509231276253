import { Component, OnInit, ElementRef, ViewChild, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldInterface } from '../../../interfaces/FormFieldInterafce';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
@Component({
  selector: 'rubicon-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit {
  @ViewChild('passpolicy') passpolicy: ElementRef;
  isPasswordVisible: boolean = false;
  password_input_type: string = 'password';
  field: FormFieldInterface;
  group: FormGroup;
  slug: string;
  indexVal: Number;
  passwordTimerConfig: any;
  constructor(
    public store: Store<any>,
    @Inject('environment' ) public environment
  ) { }

  ngOnInit() { 
      this.store.select('app').pipe(take(1)).subscribe(data => {
        try{
          this.passwordTimerConfig=data?.configurations[0]?.settings?.passwordConfig;
        }
        catch(err){}
      })
    
    this.indexVal=new Date().getTime();
  }

  showPassword(isPasswordVisible){
    this.isPasswordVisible = !isPasswordVisible;
    this.isPasswordVisible ? this.password_input_type = 'text' : this.password_input_type = 'password';
    if(this.passwordTimerConfig && this.passwordTimerConfig?.timer){
      setTimeout(()=>{
        this.isPasswordVisible = !this.isPasswordVisible;
        this.isPasswordVisible ? this.password_input_type = 'text' : this.password_input_type = 'password';
      },
      this.passwordTimerConfig?.timer_value*1000);
    }
  }
}
