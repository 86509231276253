import { Injectable, Inject } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { TaskInfoService } from '@rubicon/utils';
import { DOCUMENT } from '@angular/common';
declare let initScript: any;
@Injectable({
    providedIn: 'root'
})
export class BankStatementService {
    yodlee_page : Subject<any> = new Subject<any>();
    scriptLoaded: boolean = false;
    yodleeStyle: string = `
    .yodleeContainerWrapper {
        // opacity: 0.5;
        // background: darkgray;
    }
    
    
    .col-container {
        display: table;
        width: 100%;
    }
    .col {
        //display: table-cell;
       // padding: 16px;
    }
    .manually, .electronically {
        background-color: #FFFFFF;
        flex: 1;
        border: 1px solid #e7e7e6;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;    
    }
    
    .manually label, .electronically label {
        font-size: 14px; font-weight: 600;
    }
    
    .manually input[type=radio], .electronically input[type=radio] {
        width: 18px;
        height: 18px;
        position: relative !important;   
        margin-right: 1px; vertical-align: top;
    }
    
    .manually .radio, .electronically .radio {
        margin:0px;
    }
    
    .yodleeWrapper {
        
    }
    
    .autoDocumentSection h3, .manualDocumentSection h3 {
        background-color: #d0d0d0;
        /*font-size: 0.875rem;*/
        color: white;
        padding: .625rem 1.25rem;
        line-height: 24px;
        margin-bottom: 10px ;
        font-weight: 400;
    }
    
    .autoDocumentSection ul, .manualDocumentSection ul {
        list-style: disc;
        margin-left: 2.1875rem;
        font-size: 16px;
        line-height: 24px;
    }
    .manually {
        margin-top:40px;
    }
    .autoDocumentSection, .manualDocumentSection {
        color: #2a363b;
        background: #fff; font-size:12px;
        padding-bottom: 25px;
    }
    
    .documentSection ul li {
        line-height: 1.8rem;
    }
    
    .autoDocumentSection  h3.autoActiveSection, .manualDocumentSection h3.manualActiveSection  {
        background-color: #0062cc;
        font-size: 20px;
    }
    
    .justify-content-md-start {
        -webkit-box-pack: start !important;
        justify-content: flex-start !important
    }
    
    .justify-content-md-end {
        -webkit-box-pack: end !important;
        justify-content: flex-end !important
    }
    
    .justify-content-md-center {
        -webkit-box-pack: center !important;
        justify-content: center !important
    }
    
    .justify-content-md-between {
        -webkit-box-pack: justify !important;
        justify-content: space-between !important
    }
    
    .justify-content-md-around {
        justify-content: space-around !important
    }
    
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box
    }
    
    a:focus, a:hover {
        text-decoration: none
    }
    
    ol, ul {
        list-style: none
    }
    
    ul.list-disc {
        list-style: disc;
        padding: 0;
        margin: 0 0 0 20px
    }
    
    ul.list-disc li {
        padding: 0;
        font-size: .75rem;
        color: #4a4a4a;
        line-height: 21px;
        margin: 0 0 12px
    }

    li{
        font-size:14px;
    }

    .manualDocumentSection ul {
        list-style: disc;
        margin-left: 16px;
        font-size: 16px;
        line-height: 20px;
    }
    
    .modal-content {
        border-radius: 0
    }
    
    /*.modal-footer {
        background: #f6f7f9;
        border-radius: 0
    }
    
    .modal-header.brand-gradiant {
        color: #fff
    }
    
    .modal-header {
        font-size: 1.5rem;
        border-radius: 0
    }*/
    #Failure,#doneMessageID{margin: 12px;}
    
    .modal-title {
        font-size: 1.5rem;
        padding-right: 25px;
        color: #fff
    }
    .modal .modal-header { min-height: 45px; }    
    .documentUpload {
        padding: 20px 0 70px;
        color: #575756
    }
    
    .documentUpload .text-sm-left {
        text-align: left;
        padding-left: 14px;
    }
    
    .documentUpload h6 {
        font-size: 16px;
        font-weight: bold;
    }
    
    .documentUpload .uploadBox .font-weight-bold {
        border-bottom: 1px solid #dbdbdb;
        padding-bottom: 5px;padding-top: 5px;
        margin-bottom: 11px;
        font-weight: bold;
    }
    
    .documentUpload .uploadBox .fileName {
        margin-bottom: 10px;
    }
    
    .documentUpload .uploadBox .progress-bar {
        background-color: #db0011;
    }
    
    button.close {
        margin-left: 7px;
    }
    
    .border-bottom-1-5 {
        border-bottom: 1.5px solid #d0d0d0
    }
    
    
    .py-20 {
        padding: 1.25rem 0
    }
    
    .documentUpload p {
        margin-bottom: 5px
    }
    
    .mt-20 {
        margin-top: 1.25rem
    }
    
    .mt-40 {
        margin-top: 40px
    }
    
    .dragBox {
        background: #fff;
        padding: 0;
        outline: #b6b6b6 dashed 2px;
        outline-offset: -2px;
        margin-right: 0;
        position: relative
    }
    
    .fileTypeLabel {
        width: 100%;
        height: 100%;
        text-align: center;
        padding-top: 64px;
    }
    .fileTypeLabel img {
        color: #db0011;
    }
    
    .fileTypeLabel input.fileType[type=file] {
        opacity: 0;
        height: 1px;
        width: 1px
    }
    
    .closeYodleeManualUploadModal {
        color: #aaa;
        float: right;
        font-size: 28px;
        font-weight: bold;
    }
    
    .closeYodleeManualUploadModal:hover, .closeYodleeManualUploadModal:focus {
        color: black;
        text-decoration: none;
        cursor: pointer;
    }
    .actionContainer {margin-top: 30px;float: right;}
    `;

    // background: #2880C9 radial-gradient(circle, transparent 1%, #2880c7 1%) center/15000%;

    scriptNode: any;

    constructor(
        @Inject(DOCUMENT) public document: Document,
        @Inject("environment") public environment, 
        public taskInfoService: TaskInfoService
    ) { }

    getProviders(): Observable<any> {
        let slug = "service_providers";
        return this.taskInfoService.getTaskInfo({ "slug": slug });
    }

    loadWidgetScript() {
        if(!this.scriptLoaded){
            this.scriptNode = document.createElement('script');
            this.scriptNode.src = this.environment.widgetCDN;
            this.scriptNode.type = 'application/javascript';
            this.scriptNode.async = true;
            this.scriptNode.charset = 'utf-8';
            document.getElementsByTagName('body')[0].appendChild(this.scriptNode);
            this.scriptLoaded=true;
        }
    }

    removeScript(){
        if(this.scriptLoaded){
            this.document.getElementsByTagName('body')[0].removeChild(this.scriptNode);
            this.scriptLoaded=false;
        }
    }

    loadCSS(){
        const node2 = document.createElement('style');
        node2.innerHTML = this.yodleeStyle;
        document.getElementsByTagName('head')[0].appendChild(node2);
    }
}
