import { HttpBackend, HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TaskInfoService } from '@rubicon/utils';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})

export class IpService {
    userIP: string;
    private httpBackend: HttpClient;
    constructor(
        private http: HttpClient,
        private handler: HttpBackend,
        @Inject('CONSTANTS') private CONSTANTS,
        private taskInfoService: TaskInfoService
    ) {
        this.httpBackend = new HttpClient(handler);
    }

    getIP() {
        return this.httpBackend.get('https://ipv4.jsonip.com').pipe(switchMap((res: any) => {
            this.userIP = res.ip;
            if (!res?.ip) {
                return this.httpBackend.get('https://api.ipify.org/?format=json').pipe(map((value: any) => {
                    this.userIP = value.ip;
                }, (error) => console.log(error)
                ));
            }
            return of(res);
        }));
    }

    onSaveTaskInfo(obj) {
        console.log(obj);
        
        this.getIP().subscribe(() => {
            this.saveMetaData(obj);
        }, err => {
            this.saveMetaData(obj);
        })
    }

    saveMetaData(obj) {
        const user_agent = (window && window.navigator && window.navigator.userAgent) ? window.navigator.userAgent : '';
        const raw_url = (location && location.href) ? location.href : '';
        let payload = {
            ip: this.userIP,
            raw_url: raw_url,
            user_agent: user_agent,
            ...obj
        }
        console.log(this.CONSTANTS.SLUG.user_track);
        
        this.taskInfoService.saveTaskInfo({ slug: this.CONSTANTS.SLUG.user_track }, payload).subscribe((res) => {
            // console.log(res)
        })
    }
}