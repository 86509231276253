import { Component, OnInit } from '@angular/core';
import { CommonService } from '@rubicon/utils_custom';

@Component({
  selector: 'rubicon-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

  constructor(private common: CommonService) {
  }

  ngOnInit(): void {
    this.common.setLogin(false);
  }

}
