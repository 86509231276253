<ng-template #knockoutInfoTemp>
    <div [ngClass]="(modalCss?.header)? modalCss?.header :'modal-header pl-3'">
        <h4 [ngClass]="(modalCss?.title)? modalCss?.title :'modal-title pull-left'"
            [innerHTML]='(modalSlug + ".label.mdl_title" | translate) == (modalSlug + ".label.mdl_title")?"Knockout Condition":(modalSlug + ".label.mdl_title" | translate)'>
        </h4>
        <div [ngClass]="modalCss?.help_text" [innerHTML]='(modalSlug + ".label.help_text" | translate) == (modalSlug + ".label.help_text")?"The fields/questions provided here show the knockout condition met originally in customer journey. Please note that the view will remain the same even if any changes are applied in the banker portal.":(modalSlug + ".label.mdl_title" | translate)'></div>
        <button type="button" [ngClass]="(modalCss?.close)? modalCss?.close :'close pull-right'" aria-label="Close"
            (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div [ngClass]="(modalCss?.css?.mdl_body)? modalCss?.css?.mdl_body :'modal-body pb-0'">
        <div class="row" [ngClass]="modalCss?.wrapperRow" *ngIf="pageLoaded">
            <div class="col" [ngClass]="modalCss?.wrapperCol">
                <div *ngIf="business?.knockoutFlag?.is_knockout">
                    <ul [ngClass]="modalCss?.parent_ul">
                        <li *ngFor="let item of knockoutMessages | keyvalue" [ngClass]="modalCss?.parent_li">
                            <div [ngClass]="modalCss?.page_title">{{item.key+".html.pageName" | translate}}</div>
                            <ul [ngClass]="modalCss?.child_ul">
                                <li *ngFor="let inputField of item.value" [ngClass]="modalCss?.field_group"
                                        [innerHTML]="(item.key+'.label.'+inputField.field) | translate">
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div [ngClass]="modalCss?.no_knockout_msg" *ngIf="!business?.knockoutFlag?.is_knockout">
                    Knockout condition not found.
                </div>
            </div>
        </div>
    </div>
    <div [ngClass]="(modalCss?.footerWrapper)? modalCss?.footerWrapper :'modal-footer'">
        <div [ngClass]="(modalCss?.footerInnerWrapper)? modalCss?.footerInnerWrapper:'pl-1'">
            <button [ngClass]="(modalCss?.ok) ? modalCss.ok:'btn btn-primary btn-md ml-3 mb-2'"  [innerHTML]='(modalSlug + ".label.ok" | translate) == (modalSlug + ".label.ok")?"Ok":(modalSlug + ".label.ok" | translate)' (click)="modalRef.hide()"></button>
        </div>
    </div>
</ng-template>
<button *ngIf="environment?.journeyType === 'banker-journey' && business?.knockoutFlag?.is_knockout" type="button" [ngClass]="btnCss" (click)="showKnockoutInfo()">
    <div #ref>
        <ng-content></ng-content>
    </div>
    <span *ngIf="!ref.innerHTML?.length">
        Knockout Condition
    </span>
</button>