/// <reference types="@types/googlemaps" />

import {
  ComponentFactoryResolver,
  Directive,
  Input,
  ViewContainerRef,
  OnInit,
  OnDestroy,
  AfterViewInit,
  SimpleChanges,
  ChangeDetectorRef
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as _ from 'lodash';

// import { } from 'googlemaps';
import { take, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

// Service
import { TaskInfoService, CommonService } from '@rubicon/utils';


// Interfaces
import { FormFieldInterface } from '../interfaces/FormFieldInterafce';

// Components
import {
  TextComponent, NumberComponent, PasswordComponent, RadioComponent,
  SliderComponent, CheckboxComponent, SwitchComponent, TelComponent, DateComponent,
  SelectComponent, GroupComponent, TextareaComponent, AmountComponent, FileComponent,
  LabelComponent, ButtonComponent
} from '../components/form-fields';

import { FormService } from '../services/form.service';
import { GoogleAddressService } from '../services/google-address.service';
import { ReplaySubject } from 'rxjs';
import { EditableDivComponent } from '../components/form-fields/editable-div/editable-div.component';

const componentMapper = {
  text: TextComponent,
  number: NumberComponent,
  tel_number: NumberComponent,
  email: TextComponent,
  password: PasswordComponent,
  radio: RadioComponent,
  range: SliderComponent,
  range_amount: SliderComponent,
  range_percentage: SliderComponent,
  checkbox: CheckboxComponent,
  switch: SwitchComponent,
  tel: TelComponent,
  date: DateComponent,
  select: SelectComponent,
  group: GroupComponent,
  textarea: TextareaComponent,
  amount: AmountComponent,
  file: FileComponent,
  label: LabelComponent,
  submit: ButtonComponent,
  button: ButtonComponent,
  contentEditable: EditableDivComponent,
  hidden: null
};

@Directive({
  selector: '[rubiconField]'
})

export class FieldDirective implements OnInit, OnDestroy, AfterViewInit {
  @Input() index: number;
  @Input() parent: string;
  googleAddressdata: any = {}
  @Input() field: FormFieldInterface;
  @Input() group: FormGroup;
  @Input() slug: string;
  @Input() ngForIndex: number;

  previousValue = null;
  taskMapping: any = {
    get: 'getTaskInfo',
    post: 'saveTaskInfo'
  }
  inputTypeMap = {
    select: 'input',
    textarea: 'textarea',
    text: 'input',
    amount: 'input',
    switch:'input'
  };

  componentRef: any;
  eventField: any;

  autoCompletEvent;
  stateCityMapping:any = {};
  compDestroyed$ = new ReplaySubject(1);
  controlFieldElement;

  ngOnChanges(simpleChanges: SimpleChanges){
    if(simpleChanges && simpleChanges.index && this.componentRef){
      this.componentRef.instance.index = simpleChanges.index.currentValue;
    }
  }

  constructor(
    private common: CommonService,
    private resolver: ComponentFactoryResolver,
    private container: ViewContainerRef,
    private taskInfoService: TaskInfoService,
    private formService: FormService,
    private googleAddressService: GoogleAddressService
  ) { }

  ngOnInit() {
    if (componentMapper[this.field.type]) {
      const factory = this.resolver.resolveComponentFactory(
        componentMapper[this.field.type]
      );
      this.componentRef = this.container.createComponent(factory);
      this.componentRef.instance.field = this.field;
      this.componentRef.instance.group = this.group;
      this.componentRef.instance.slug = this.slug;
      this.componentRef.instance.index = this.index;
      this.componentRef.instance.parent = this.parent;
      if(this.ngForIndex){
        this.componentRef.instance.ngForIndex = this.ngForIndex;
      }
    }
  }

  ngAfterViewInit(): void {
    this.controlFieldElement = this.componentRef?.location?.nativeElement?.querySelector(this.inputTypeMap[this.field.type]);
    if (this.field.event) {
      this.eventField = this.componentRef.location.nativeElement.querySelector(this.inputTypeMap[this.field.type]);
      if (this.eventField) {
        if (this.field.event.method === 'google') {
          this.listenOptionsRecieved();
          // this.eventField.addEventListener(this.field.event.type, this.geolocate.bind(this));
          this.geolocate();
        } else if (this.field.type === "select") {
          this.group.get(this.field.name).valueChanges.subscribe(data => {
            if (data) {
              this.onEvent(this.index, {})
            }
          })
        } else {
          this.eventField.addEventListener(this.field.event.type, this.onEvent.bind(this, this.index));
        }
        if (this.field.event.type === 'change') {
          if (this.group.get(this.field.name).value != this.previousValue || !this.group.get(this.field.name).value) {
            if (this.previousValue === null && this.group.get(this.field.name).value) {
              this.onEvent(this.index, {})
            } else {
              this.previousValue = this.group.get(this.field.name).value;
              this.group.get(this.field.name)
                .valueChanges
                .pipe(
                  take(1),
                  // debounceTime(500),
                  distinctUntilChanged(),
                ).subscribe((val) => {
                  if (val) {
                    if ('createEvent' in document) {
                      const evt = document.createEvent('HTMLEvents');
                      evt.initEvent('change', false, true);
                      this.eventField.dispatchEvent(evt);
                    } else
                      this.eventField.fireEvent('onchange');
                  }
                });
            }
          }
        }
      }
    }
  }

  listenOptionsRecieved(){
    let populate_fields = this.field?.event?.populate_fields;
    let state_config = populate_fields?.state, city_config = populate_fields?.city, zip_config = populate_fields?.zip;;
    let state_control = this.group.get(state_config?.field_name);
    let city_control = this.group.get(city_config?.field_name);
    let field_control = this.group.get(this.field.name);
    let zip_control = this.group.get(zip_config?.field_name);
    if(!city_control){
      return ;
    }
    this.formService.optionsReceivedListen().pipe(
      takeUntil(this.compDestroyed$)
    ).subscribe((data)=>{
      if(state_config?.field_name == data?.field?.name){
        localStorage.setItem("states_data", JSON.stringify(data.options));
      }
      if(populate_fields){
        let field = data.field;
        let options = data.options;
        if(city_config.is_ddl && field.name == city_config.field_name && this.slug == data.slug && options?.length > 0){
          let city_value = this.stateCityMapping.city_value;
          if(city_value){
            if(this.stateCityMapping.state == state_control.value && this.stateCityMapping.search_value == field_control.value){
              let valueFound = options.find(option=>option.value.toUpperCase() == city_value.toUpperCase());
              if(valueFound){
                city_control.setValue(valueFound._id, {emitEvent:false});
              }else{
                city_control.setValue(null, {emitEvent:false})
              }
              this.formService.runFormDetectChanges();
            }
          }else{
            if(Object.keys(this.stateCityMapping)?.length && zip_control.value){
              this.googleAddressService.fetchCityFromZip(this.group, this.field, options);
            }
          }
          this.stateCityMapping = {};
        }
      }
    });
  }

  geolocate() {
    if (this.autoCompletEvent && this.field?.event?.registerOneTime) {
      return;
    }
    let populate_fields = this.field?.event?.populate_fields;
    let state_config = populate_fields?.state, city_config = populate_fields?.city,
    zip_config = populate_fields?.zip;
    let state_control = this.group.get(state_config?.field_name);
    let city_control = this.group.get(city_config?.field_name);
    let field_control = this.group.get(this.field.name);

    const componentForm = {
      street_number: 'short_name',
      route: 'long_name',
      administrative_area_level_1: 'short_name',
      locality: 'long_name',
      postal_code: 'short_name',
      sublocality_level_1: "long_name"
    }
    const options = { componentRestrictions: { country: 'us' }, types: ['geocode'] };
    const autocomplete = new google.maps.places.Autocomplete(<HTMLInputElement>this.eventField, options);
    this.autoCompletEvent = autocomplete;
    // if(populate_fields){
      google.maps.event.addListener(autocomplete, 'place_changed', () => {
        const place = autocomplete.getPlace();
        let googleAddressdata: any = {};
        for (let i = 0; i < place.address_components.length; i++) {
          const addressType = place.address_components[i].types[0];
          const val = place.address_components[i][componentForm[addressType]];
          googleAddressdata[addressType] = val;
        }
        if (!googleAddressdata["locality"]) {
          if (googleAddressdata["sublocality_level_1"]) {
            googleAddressdata["locality"] = googleAddressdata["sublocality_level_1"];
          }
        }
  
        let state_value = "", city_value = "", zip_value = "";
        let field_value = "";
            
        if(state_config){
          state_value = googleAddressdata.administrative_area_level_1;
          if(state_config.is_ddl){
            if(state_control){
              state_control.setValue(state_value);
            }
          }
        }

        if(city_config){
          city_value = googleAddressdata.locality;
          field_value = this.eventField.value;
            if(city_config?.is_ddl){
              if(city_control){
                city_control.setValue(city_value);
              }
            }
        }

        if(city_config || state_config){
          field_value = (googleAddressdata.street_number ? googleAddressdata.street_number + " " : '') + (googleAddressdata.route || '');
          field_control.setValue(field_value);
        }else{
          field_control.setValue(this.eventField.value);
        }

        if(zip_config){
          zip_value = googleAddressdata.postal_code;
          let zip_control = this.group.get(zip_config.field_name);
          if(zip_control){
            zip_control.setValue(zip_value);
          }
        }

        // if(state_control && state_config.is_ddl){
        //   const stateData = JSON.parse(localStorage.getItem('states_data'));
        //   if(!stateData){
        //     return ;
        //   }
        //   const stateWithKey = stateData.find(state => state.id === state_value);
        //   if (stateWithKey) {
        //     this.stateCityMapping = {search_value: field_control.value,state:stateWithKey?.id, city_value:city_value};
        //     state_control.setValue(stateWithKey.id);
        //   }else{
        //     this.stateCityMapping = {search_value: field_control.value,state:null,city:null};
        //     state_control.setValue(null);
        //     city_control.setValue(null);
        //   }
        // }else{
        //   this.stateCityMapping = {search_value: field_control.value,state:null,city:null};
        // }
        
      });
    // }

  }

  onEvent(index, event) {
    if ((!this.group.get(this.field.name).invalid || this.field.event.method==='component') && this.isValidKeyEvent(event)) {
      const payload = {};
      payload[this.field.name] = this.group.get(this.field.name).value;
      let params = {
        slug: this.field.event.action
      };
      if(this.field.event.method==='component'){
        setTimeout(()=>{
          this.common.sendEventListener({ name: this.field.name, group: this.group, index: this.index, value: this.group.get(this.field.name).value, slug: this.slug });	
        },0);	
        return;	
      }
      if (this.field.event.method === 'get') {
        params = {
          ...params,
          ...payload
        }
      }
      this.taskInfoService[this.taskMapping[this.field.event.method]](params, payload).subscribe((data) => {
        if (this.field.event.method === 'get') {
          data = data.response_data;
        }
        if (data && data.nextTask) {
          if (data.nextTask.next === 'task') {
            if (data.nextTask.value === 'field') {
              this.common.sendEventCallData(this.field.name, this.field.event.action, data)
            } else {
              this.common.sendMasterDataToFields(null, { data: _.get(data, this.field.event.data_key || this.field.event.action), parent: this.parent, group: this.group, index: this.index }, this.field.event.field || this.field.event.action);
            }
          }
          if (data.nextTask.next === 'form' && data.nextTask.value === 'invalid') {
            this.group.controls[this.field.name].setErrors({ incorrect: true });
          }
        }
        this.formService.runFormDetectChanges();
      });
    }
  }

  ngOnDestroy() {
    this.compDestroyed$.next();
    this.compDestroyed$.complete();

    if (this.eventField)
      this.eventField.removeEventListener(this.field.event.type, this.onEvent.bind(this, this.index));
  }

  isValidKeyEvent(event) {
    if (this.field.event.type.match('key')) {
      if (this.group.get(this.field.name).value.length > 2) {
        const code = (event.keyCode || event.which);

        // do nothing if it's an arrow key/special key
        if ((code >= 9 && code <= 45 && code !== 32) || (code >= 91 && code <= 93) || (code >= 112 && code <= 145)) {
          return false;
        }
        else {
          return true;
        }
      }
      else
        return false;
    }
    else {
      return true;
    }
  }
}
