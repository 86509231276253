import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[rubiconScroll]'
})
export class ScrollDirective {

  constructor() { }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    if (window.pageYOffset > 11) {
      document.getElementById('logoRow').classList.add('sticky')
      document.getElementById('mainCont').style.paddingTop = '100px,slow';
    } else {
      document.getElementById('logoRow').classList.remove('sticky')
      document.getElementById('mainCont').style.paddingTop = '0,slow';
    }
  }
}
