import { Renderer2, Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { TaskInfoService } from '@rubicon/utils';


@Injectable({
  providedIn: 'root'
})
export class TagLoaderService {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject("environment") private environment,
    private taskInfoService: TaskInfoService
  ) { }

  loanInitialTags(renderer: Renderer2) {
    this.taskInfoService
      .getTaskInfo({ slug: "master_data_optimised", "model":"pro_html_tag" })
      .subscribe((response) => {
        let tags = response?.response_data?.master_data_optimised?.data?.data?.pro_html_tag || [];
        tags.filter(tagConfig=>tagConfig.status && tagConfig?.journeyTypes?.includes(this.environment?.journeyType) && tagConfig?.instances?.includes(this.environment?.instance)).forEach((tagConfig)=>{
          this.loadTag(null, renderer, tagConfig);
        });
      });
  }

  public loadTag(parentElement, renderer: Renderer2, tagConfig: any): Promise<any> {
    let resolveFn, rejectFn, jsPromise, element;
    if(!parentElement){
      jsPromise = new Promise((resolve, reject) => {
        resolveFn = resolve;
        rejectFn = reject;
      });
    }
    try{
      if(!parentElement){
        if(tagConfig?.attributes?.id){
          element = document.getElementById(tagConfig.attributes.id);
        }
      }
      if(!element){
        element = renderer.createElement(tagConfig.tagName);
      }
      for(let attribute in tagConfig.attributes){
        element[attribute] = tagConfig.attributes[attribute];
      }
      if(tagConfig.innerHTML){
        element.innerHTML = tagConfig.innerHTML;
      }
      if(tagConfig?.children?.length){
        for(let childTagConfig of tagConfig.children){
          this.loadTag(element, renderer, childTagConfig);
        }
      }
      if(parentElement){
        parentElement.appendChild(element);
      }else{
        renderer.appendChild(this.document[tagConfig.position || "body"], element);
        element.onload = () => {
          resolveFn();
        }
        element.onerror = (err) => {
          rejectFn(err);
        }
      }
    }catch(err){
      rejectFn(err);
    }
    return jsPromise;
  }
}