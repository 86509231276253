import { Component, OnInit } from '@angular/core';
import { FeatureAccessService } from '@rubicon/utils_custom';

@Component({
  selector: 'rubicon-verifier-footer',
  templateUrl: './verifier-footer.component.html',
  styleUrls: ['./verifier-footer.component.scss']
})
export class VerifierFooterComponent implements OnInit {
  supportMail='cpasupport@biz2credit.com';

  constructor(private FeatureService: FeatureAccessService) { }

  ngOnInit(): void {
    let plan = this.FeatureService.getPlan()?this.FeatureService.getPlan().type:'none';
    if(plan == "enterprise" || plan === 'custom'|| plan==='premium'){
      this.supportMail='cpasupport-premier@biz2credit.com';
    }
    this.FeatureService.planType.subscribe((data:any)=>{
      if(data.type == "enterprise" || data.type ==='premium'){
      this.supportMail='cpasupport-premier@biz2credit.com';
    }
    })
  }

}
