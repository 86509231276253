import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { environment } from '@env/environment';

@Pipe({
  name: 'dateTimezone'
})
export class DateTimezonePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}
  transform(value: Date|string|number|null|undefined, format: string = 'mediumDate') :  string|null {
    let timezone = environment.timezone;
    let dateArr = (value + '').split('-');
    if(dateArr[0].length < 4) {
      value = dateArr[2]+'-'+dateArr[1]+'-'+dateArr[0];
    }
    try{
      return this.datePipe.transform(value, format, timezone);
    }catch{
      return '';
    }
  }

}
