import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class BackNavigateGuard implements CanDeactivate<any> {
  constructor(private store: Store<any>) { }
  canDeactivate(component: any, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    // show alert if user navigated away by clicking back
    console.log('called');
    
    return this.confirmAlert();
  }

  confirmAlert() {
    const isConfirm = confirm('If you will click on Back or Refresh button, session will get expired');
    if (isConfirm) {
      // this.api.logout();
      return false;
    } else {
      return false;
    }
  }

}
