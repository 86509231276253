import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortListing'
})
export class SortListingPipe implements PipeTransform {

  transform(value: any[]) : any[] {
    value = value || [];
    value.sort(function(alc,blc){
    return alc.type > blc.type ? 1 : alc.type < blc.type ? -1 : 0;
 });
  
    return value;
  }

}
