<ng-container [formGroup]="group">

    <ng-container *ngIf="field?.css?.floatingLabel; else normal">
        <div  [ngClass]="(field?.css?.floatingLabelClass)? field?.css?.floatingLabelClass :'floating-label'">
            <input 
                type="text" 
                placeholder="&nbsp;"
                [ngClass]="(field?.css?.field)? field?.css?.field :'form-control'" 
                [formControlName]="field.name"
                [id]="field.name + '_' + indexVal"  
                [mask]="field.format?field.format.type:'separator.2'" thousandSeparator="," prefix="$ "
                >

            <span class="highlight"></span>

            <label 
                [ngClass]="(field?.css?.labelClass)? field?.css?.labelClass :'labelText'" 
                [for]="field.name + '_' + indexVal">{{slug + ".label." + field.name | translate}}</label>            
                <ng-container *ngIf="field?.css?.tooltip && field?.css?.tooltip[field.name]"><!--
                --><a  class='que_info' placement="right" [ngbTooltip]="tipContent"><!----></a><!--​
                --><ng-template #tipContent><span [innerHTML]='slug + ".tooltip."+ field.name | translate'></span></ng-template><!--
                --></ng-container><!--
                --><div *ngIf="group.get(field.name).invalid && (group.get(field.name).dirty || group.get(field.name).touched)"
                class="error-msg">
                <ng-container *ngFor="let validation of field.validations;">
                    <label class="error" *ngIf="group.get(field.name).hasError(validation.name)">
                        {{slug + ".errorMessage." + field.name + "." + validation.name | translate}}
                        {{validation.name==="max"? (group.get(field.name).errors.max.max | currency : 'USD' : 'symbol' : '1.0-0' )+".":""}}
                    </label>
                </ng-container>
                <label class="error" *ngIf="group.get(field.name).hasError('incorrect')">
                    {{slug + ".errorMessage." + field.name + ".incorrect" | translate}}
                </label>
            </div>
        </div>
    </ng-container>

    <ng-template #normal>
        <div class="form-row mt40" *ngIf="field.name === 'monthly_income';">
            
            <div class="col-lg-12">
                <div class="form-group mb-0">
                    <label class="primary-label">Personal Income & Expenditures</label> </div>
            </div>
        </div>

        <!-- <div class="form-group col-12 m-0"> -->
        <label *ngIf="slug + '.label.' + field.name | translate"
            [ngClass]="(field?.css?.labelClass)? field?.css?.labelClass :'secondary-label'"
            [for]="field.name+ '_' + indexVal">
            {{slug + ".label." + field.name | translate}}
        </label>

        <fieldset>
            <div class="input-group mb-2">
                <div class="input-group-prepend">
                    <div class="input-group-text">{{currency}}</div>
                </div>
                <input [mask]="field.format?.type?field.format.type:'separator.2'" thousandSeparator="," type="text"
                    [placeholder]="slug + '.placeholder.' + field.name | translate" class="form-control"
                    [separatorLimit]="field?.format?.separatorLimit ? field.format.separatorLimit : ''"
                    [formControlName]="field.name" [id]="field.name + '_' + indexVal">
            </div>
        </fieldset>
    
        <div *ngIf="group.get(field.name).invalid && (group.get(field.name).dirty || group.get(field.name).touched)"
            class="error-msg">
            <ng-container *ngFor="let validation of field.validations;">
                <label class="error" *ngIf="group.get(field.name).hasError(validation.name)">
                    {{slug + ".errorMessage." + field.name + "." + validation.name | translate}}
                    {{validation.name==="max"? (group.get(field.name).errors.max.max | currency : 'USD' : 'symbol' : '1.0-0' )+".":""}}
                </label>
            </ng-container>
        </div>
    </ng-template>
    


    <!-- </div> -->
</ng-container>