import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'commaSeparator'
})
export class CommaSeparatorPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    if(!isNaN(Number(value))){
      return Number(value).toLocaleString('en-US', { maximumFractionDigits: 10 });
    }
    else return value;
  }

}
