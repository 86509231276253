<ng-container [formGroup]="group">

    <div [ngClass]="(field?.css?.labelParent)? field?.css?.labelParent :''">
        <label [ngClass]="(field?.css?.labelClass)? field?.css?.labelClass :'primary-label'">{{slug + ".label." +
            field.name | translate}}
        </label>

        <!-- <div class="tooltip-bg" [ngStyle]="{'left': leftValue + '%' }">
            <span *ngIf="hoverValue" class="hoverText tooltip-bgInner">{{hoverValue}}</span>
        </div> -->

        <ng-multiselect-dropdown  
            [settings]="dropdownSettings"
            [data]="this.field['options']" 
            [formControlName]="field.name"
            (onSelect)="onItemSelect($event)" 
            (onSelectAll)="onSelectAll($event)" 
            (onDeSelect)="onDeSelect($event)"
            (onDeSelectAll)="onDeSelectAll($event)"
            (mouseover)='over($event)' 
            (mouseout)='out()'> 

        </ng-multiselect-dropdown>
        
        <div *ngIf="group.get(field.name).invalid && (group.get(field.name).dirty || group.get(field.name).touched)"
            class="error-msg">
            <ng-container *ngFor="let validation of field.validations;">
                <label class="error" *ngIf="group.get(field.name).hasError(validation.name)">
                    {{slug + ".errorMessage." + field.name + "." + validation.name | translate}}
                </label>
            </ng-container>
        </div>
    </div>
</ng-container>