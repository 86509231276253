import { Component, OnInit, Input, OnChanges, Inject, ChangeDetectorRef, OnDestroy  } from '@angular/core';
import { FormFieldInterface } from '../../interfaces/FormFieldInterafce';
import { FormGroup, FormArray } from '@angular/forms';
import { FormService } from '../../services/form.service';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';

declare let require: any;

@Component({
  selector: 'rubicon-dynamic',
  templateUrl: './dynamic.component.html',
  styleUrls: ['./dynamic.component.scss']
})

export class DynamicComponent implements OnInit, OnChanges, OnDestroy {
  @Input() fields: FormFieldInterface[] = [];
  @Input() group: FormGroup;
  @Input() slug: string; 
  @Input() parent: string;
  @Input() index = 0;
  @Input() ngForIndex:number = 0;
  pageCssJson: any;
  inc = 0;

  compDestroyed$ = new ReplaySubject(1);

  constructor(@Inject('cssList') private cssList, private formService: FormService, private cdRef: ChangeDetectorRef){ }

  ngOnChanges() {
    if (!this.parent) {
      this.parent = this.slug;
    }
    this.pageCssJson = this.cssList[this.slug];
    for (const field of this.fields) {
      if (this.pageCssJson) {
        const fieldCss = this.pageCssJson[field.type];
        if (fieldCss) {
          if (fieldCss[field.name]) {
            field.css = fieldCss[field.name];
          } else if (fieldCss['common']) {
            field.css = fieldCss['common'];
          } else {
            field.css = fieldCss['parent'];
          }
        }
      } else {
        field.css = "";
      }
    }
  }

  ngOnInit() {
    this.formService.formDetectChanges().pipe(
      takeUntil(this.compDestroyed$)
    ).subscribe(()=>{
      this.cdRef.detectChanges();
    });
  }

  checkArray(val) {
    return Array.isArray(val);
  }

  checkValue(group: FormGroup, field: FormFieldInterface) {
    if (this.checkArray(field.visible.value) && (field.visible.value.indexOf(group.get(field.visible.key).value) > -1)) {
     return true;
    } else if (this.checkArray(group.get(field.visible.key).value) && group.get(field.visible.key).value.includes(field.visible.value)) {
      return true;
    } else if (field.visible && field.visible.value === group.get(field.visible.key).value) {
      return true;
    } else {
       if(field.name==='ssn' && group.get('tin')  && group.get('tin').value){
         group.get('ssn').patchValue(group.get('tin').value);
       }
       else  if(field.name==='tin' && group.get('ssn') && group.get('ssn').value){
        group.get('tin').patchValue(group.get('ssn').value);
      }
       else{
         if((field.value === undefined && group.get(field.name).value !== null) || (field.value !== undefined && group.get(field.name).value !== field.value)){
           group.get(field.name).reset(field.value);
         }
       }
      if (field.multiple) {
        const formArr = group.get(field.name) as FormArray;
        for (let i = formArr?.controls?.length; i > 0; i--) {
          formArr.removeAt(i);
        }
      }
      return false;
    }
  }

  ngOnDestroy(){
    this.compDestroyed$.next();
    this.compDestroyed$.complete();
  }

}
