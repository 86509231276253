<footer>
    <div class="container">
        <div class="row">
            <div class="col-12 col-lg text-lg-left text-center">
                <ul>
                    <li><a target="_blank" href="{{footer_privacy_url}}">Privacy</a></li>
                    <li><span class="sapretor">|</span><a target="_blank" href="{{footer_terms_url}}">Terms</a></li>
                    <li *ngIf="isCpaClient"><span class="sapretor">|</span><a href="/non-cpa">Non-CPA Policy</a></li>
                    <li><span class="sapretor">|</span><a href="javascript:void(0)">About Prequalification <img src="/assets/base/images/infoIcon.svg"  class="imgCls ml-2" [ngbTooltip]="tooltipContent" container="body" placement="top" triggers="click" /></a></li>
                    <ng-template #tooltipContent>
                        <span>Prequalification estimates only apply to Revenue-Based Financings and are contingent on verification of the information provided. Prequalification is not a financing approval, just an estimate of how much funding your client might receive based on the limited information you submitted. Not all applicants will prequalify for financing. If your client prequalifies, you will need to submit additional documents to complete the application, which will then be reviewed based on the documents and information in the completed application, funding eligibility criteria, other  <a class='btn-link uline cp wtc'  href={{URLS?.business_finance_servicesterms}}>terms and conditions</a>, and a signed final financing agreement. All commercial financings are made at the sole and final discretion of the lender.</span>   
                    </ng-template>
                </ul>
                <p>&copy; <a target="_blank" href="{{biz2credit_url}}">Biz2Credit Inc {{getYear()}}</a>. All rights reserved.</p> 
            </div>
         
            <div class="col-12 col-lg-auto text-center" *ngIf="isCpaClient">
                <span class="img-fluid d-block mt-2"><img src="/assets/base/images/cpa-logo.png" alt="logo" class="img-fluid" style="width: 190px;"></span>
            </div>

            <div class="col-12 col-lg text-center text-lg-right mt-2 pl-lg-0">
                <div class="headRightLink d-inline-block align-top mt-1 mr-lg-3 text-center text-lg-left">
                    <div class="d-inline-block fwm mr-1" *ngIf="show_support_mail">
                        Support:<a target="_blank" href="mailto:{{supportMail}}">{{supportMail}}</a>
                    </div>  
                    <br>
                    <div class="d-inline-block fwm ">
                        Tech support:<a class="fs13" target="_blank" href="mailto:techhelp@biz2credit.com"> techhelp@biz2credit.com</a>
                    </div>  
                    <!-- <span class="sapretor d-none d-lg-inline-block">|</span>
                    <br class="d-block d-lg-none">
                    <span class="stc fs12 fwsb pr-lg-3"><span class="pcall mr-1"></span> 866.986.9323</span>                       -->
                </div>
                <!-- <span class="d-sm-none d-inline-block mr-3"><img src="/assets/base/images/cpa-logo.png" alt="logo" class="img-fluid" style="width: 210px;"></span> -->
                <a class="d-inline-block" href="https://biz2x.com/" target="_blank"><img src="/assets/base/images/biz2x-Logo.svg" /></a>
            </div>
        </div>

    </div>
</footer>
 

<ng-template #templatepolicyfooter>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  <div class="modal-body px-sm-5 py-4">
      <div class="head">
          <div class="row">
            <div class="col-sm-auto m-auto py-lg-2">
                <img src="/assets/base/images/logo.svg" alt="logo" class="img-fluid m-1 mx-lg-3" style="width: 180px;">
                <img src="/assets/base/images/headerlogo.svg" alt="logo" class="img-fluid m-1 mx-lg-3" style="width: 108px;">
            </div>
          </div>
          <div class="fs30 fwr ffp pb-lg-3 text-center">CPA <span class="ptc fwsb">Business Funding</span> Portal</div>
      </div>
      <div class="px-sm-5 py-4">
      <div class="card">
          <h5 class="card-header fwsb modal-header">UPDATED APPLICATION POLICY FOR NON-CPA USERS</h5>
          <div class="card-body">
              <h5 class="card-title uline fs14 fwb">Portal Access Requirements</h5>
              <p class="card-text lh">Revised: July 1, 2022. Non-CPA users may apply for and be approved to access the CPA Business Funding Portal, subject to verification by CPA.com and a review of their controls related to client qualification and document gathering. </p>
              <p class="card-text lh">All firms that do not have a licensed CPA principal must agree to the <a href="https://www.cpaloanportal.com/terms-of-use/"><span  class="fwb uline">Terms & Conditions</span></a> that govern non-CPA users of the Portal, and must remain in good standing by complying with all standard policies enforceable under the Portal Terms & Conditions. Access to the Portal remains dependent on the firm abiding by all such conditions as well as continuation of the controls in place related to client qualification and document gathering used to verify the firm’s access to the Portal in the first place.</p>
          </div>
        </div>
    </div>
    <!-- <div class="card">
        <h5 class="card-header fwsb modal-header">UPDATED APPLICATION POLICY FOR NON-CPA USERS</h5>
        <div class="card-body">
            <h5 class="card-title uline fs14 fwb">New Applications</h5>
            <p class="card-text lh">Effective April 2, 2021, non-CPA users are no longer permitted to submit new client applications without a review of their controls related to client qualification and document gathering. (We will only perform a controls review for verified users.)</p>
            <ul class="list">
                <li><p class="lh">Please <a href="mailto:cpasuport@biz2credit.com" target="_blank" class="ptc uline">contact</a> us if you would like to proceed with a review of your controls related to client qualification and document gathering. We are going to engage a CPA firm to perform this review. There will be an additional charge for this controls review which will be deducted from your agent fees. The cost for the controls review will be no less than $1,500.</p></li>
                <li><p class="lh">Another option is to hire or partner with a CPA to submit your applications.</p></li>
            </ul>
            <h5 class="card-title uline fs14 fwb">Previously Submitted Applications</h5>
            <ul class="list pb-0">
                <li> 
                    <p class="lh">Effective immediately, we are requiring additional documentation for any client applications that have not been funded. Clients are required to complete at least one of the following: </p>
                    <p class="lh">1) E-sign a 4506-C form or 2) Complete Online Account Verification by connecting their ACH deposit account (via Yodlee). </p>
                    <p class="lh">Some clients may be required to complete both requests. Clients will receive email notifications asking them to complete these steps. No applications submitted by non-CPA firms will be funded without completing the minimum requirements set forth by Biz2Credit's funding subsidiary.</p>
                </li>
                <li><p class="lh">If you have any pending applications for clients whom you do not know well, we recommend withdrawing their applications. You may <a href="mailto:cpasuport@biz2credit.com" target="_blank" class="ptc uline">contact us</a> to request these applications be withdrawn. If we do find you have submitted a number of questionable applications, we may stop processing all pending applications attached to your account. Your CPA Business Funding Portal account may also be suspended as a result.</p></li>
            </ul>
        </div>
      </div> -->
  </div>
</ng-template>
