<div class="hv-wrapper coborrowerTrue">
  <ng-template #treeTemplate let-item>
    <ng-container *ngIf="!item.items.length; else Component">
      <p class="ptc childBox" [ngClass]="item?.company_role === company_role_obj.co_borrower ? 'coclass' : ''">
        <span class="text-nowrap d-block child-heading text-center fwsb">
          <span *ngIf="item.owner_type == 'individual'" class="icon iconindividual"></span>
          <span *ngIf="item.owner_type == 'corporate'" class="icon iconbusiness"></span> 
          {{ getOwnerName(item) | uppercase}}</span>

        <span class="chartinnerdetails lh-12">
          <span class="row no-gutters mb-2" *ngIf="!item.is_primary_business && (item.ownership!=undefined || item?.ownership!=null)">
            <span class="col-5 gtc fwr">Ownership: </span>
            <span class="col-7 fs12 fwsb">{{item.ownership || item.ownership === 0 ? item.ownership + '%' : '-'}}</span>
          </span>

          <!-- <span class="row no-gutters mb-2" *ngIf="!item.is_primary_business && !(item.company_role===company_role_obj.co_borrower && item?.owner_type==='corporate')">
            <span class="col-5 gtc fwr">Business Ownership: </span>
            <span class="col-7 fs12 fwsb">{{item.business_ownership || item.business_ownership === 0 ? item.business_ownership + '%': '-'}}</span> 
          </span> -->
          
          <span class="row no-gutters mb-2" *ngIf="!item.is_primary_business">
            <span class="col-5 gtc fwr">Role:</span>
            <span class="col-7 fs12 fwsb">{{item.company_role ? (item.company_role| masterData:company_role:'id':'value') : '-'}}</span> 
          </span>

          <span class="row no-gutters mb-2" >
            <span class="col-5 gtc fwr">Type:</span>
            <span class="col-7 fs12 fwsb">
              <span class="fs13" *ngIf="item.owner_type">{{'('+(item.owner_type| masterData:owner_type:'type':'value') }}</span>
              <i class="fs12">{{item.authorized_signatory?'- Signer':''}}</i>
              <span class="fs12" *ngIf="item.owner_type">)</span>

              <span *ngIf="item.is_primary_business && !item.company_role" class="d-block text-center py-4 px-3 fs12 fwsb">(Primary Business)</span>
            </span>
          </span>
      </span>
      </p>
    </ng-container>
    <ng-template #Component>
      <div class="hv-item">
        <div class="hv-item-parent">
          <p class="ptc" [ngClass]="(item?.company_role === company_role_obj.co_borrower) ? 'coclass childBox' : item.is_primary_business ? 'ownerTreeprimaryBusinessHeight' : 'childBox'">

            <span class="text-nowrap text-center" [ngClass]="item.is_primary_business ? 'firstParent' : 'd-block child-heading fwsb'">
              <span *ngIf="item.owner_type == 'individual'" class="icon iconindividual"></span>
              <span *ngIf="item.owner_type == 'corporate'" class="icon iconbusiness"></span> 
              <span *ngIf="item.is_primary_business" class="icon icon-company"></span>
              {{ getOwnerName(item) | uppercase}}
            </span>

            <span class="parnetinner lh-12">
              <span class="row no-gutters mb-2" *ngIf="!item.is_primary_business && (item.ownership!=undefined || item?.ownership!=null)">
                <span class="col-5 gtc fwr">Ownership:</span>
                <span class="col-7 fs12 fwsb">{{item.ownership || item.ownership === 0 ? item.ownership + '%' : '-'}}</span> 
              </span>

              <!-- <span class="row no-gutters mb-2" *ngIf="!item.is_primary_business && !(item.company_role===company_role_obj.co_borrower && item?.owner_type==='corporate')">
                <span class="col-5 gtc fwr">Business Ownership:</span>
                <span class="col-7 fs12 fwsb">{{item.business_ownership || item.business_ownership === 0 ? item.business_ownership +
                  '%' : '-'}}</span> 
              </span> -->

              <span class="row no-gutters mb-2" *ngIf="!item.is_primary_business">
                <span class="col-5 gtc fwr">Role:</span>
                <span class="col-7 fs12 fwsb">{{item.company_role ? (item.company_role| masterData:company_role:'id':'value') : '-'}}</span> 
              </span>

              
              <span class="row no-gutters mb-2" *ngIf="item.owner_type || (item.is_primary_business && !item.company_role)">
                <span class="col-5 gtc fwr" *ngIf="!item.is_primary_business">Type:</span>
                <span class="fs12 fwsb" [ngClass]="item.is_primary_business ? 'col-12 text-center pt-4': 'col-7'">
                  <span *ngIf="item.owner_type">
                    <span class="fs13 fwm">{{'('+(item.owner_type| masterData:owner_type:'type':'value')}}</span>
                    <i class="fs12 fwm">{{item.authorized_signatory?'- Signer':''}}</i><span class="fs13 fwm">)</span>
                  </span>
                  <span *ngIf="item.is_primary_business && !item.company_role" class="fs13 fwm">(Primary Business)</span>
                </span>
              </span>
              
            </span>
          </p>
        </div>
        <div class="hv-item-children">
          <div class="hv-item-child" *ngFor="let child of item.items"
            [ngClass]="child?.company_role === company_role_obj.co_borrower ? 'coborrower' : ''">
            <ng-container *ngTemplateOutlet="treeTemplate; context: { $implicit: child }"></ng-container>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-template>
  <div *ngIf="ownersList && ownersList.length > 0">
    <ng-container *ngFor="let child of ownersList | treeconverter">
      <ng-container *ngTemplateOutlet="treeTemplate; context: { $implicit: child }"></ng-container>
    </ng-container>
  </div>
</div>