import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class FormService {
   private optionsRecieved$ = new Subject<any>();
   private detectChangeEvent: Subject<any> = new Subject<any>();
   optionsReceivedListen() {
      return this.optionsRecieved$.asObservable();
   }

   optionsReceivedEmit(data) {
      this.optionsRecieved$.next(data);
   }

   runFormDetectChanges() {
      this.detectChangeEvent.next();
   }

   formDetectChanges() {
      return this.detectChangeEvent.asObservable();
   }

}