<ng-container [formGroup]="group">
    <div [ngClass]="(field?.css?.labelParent)? field?.css?.labelParent :'floating-label'">
        <label [ngClass]="(field?.css?.labelClass)? field?.css?.labelClass :'labelTextarea'" *ngIf="slug + '.label.' + field.name | translate"
            [for]="field.name + '_' + indexVal">{{slug + ".label." + field.name | translate}}</label>
        <div [ngClass]="field?.css && field?.css?.childdiv?field.css.childdiv + ' ' 
        +
        ( 
            this.field?.format?.limit_count && group.get(field.name).invalid  && (group.get(field.name).dirty || group.get(field.name).touched) 
            ? 
            'limiterror'
            : ''
        )
        :'form-group' + (this.field?.format?.limit_count && group.get(field.name).invalid 
        && (group.get(field.name).dirty || group.get(field.name).touched) ? 'limiterror': '')">

            <textarea [placeholder]="slug + '.placeholder.' + field.name | translate"
                [ngClass]="(field?.css?.field)? field?.css?.field :'form-control' " [formControlName]="field.name"
                [id]="field.name + '_' + indexVal" rows="3"></textarea>
            <span class="limits" > {{char_count}}/{{field?.format?.maxlength}} characters</span>
            
            <div *ngIf="group.get(field.name).invalid && (group.get(field.name).dirty || group.get(field.name).touched)"
                class="error-msg">
                <ng-container *ngFor="let validation of field.validations;">
                    <label class="error" *ngIf="group.get(field.name).hasError(validation.name)">
                        {{slug + ".errorMessage." + field.name + "." + validation.name | translate}}
                    </label>
                </ng-container>
            </div>
        </div>
    </div>
</ng-container>