import { Injectable } from '@angular/core';

import { select, Store, Action } from '@ngrx/store';
import { appStateQuery } from './app.selectors';
import { AppState } from './app.reducers';

@Injectable()
export class AppStateFacade {
    accessToken$ = this.store.pipe(select(appStateQuery.getAccessToken));
    allAppState$ = this.store.pipe(select(appStateQuery.getAllApp));
    nextTask$ = this.store.pipe(select(appStateQuery.getNextTask));
    userData$ = this.store.pipe(select(appStateQuery.getUserData));

    constructor(private store: Store<AppState>) { }

    dispatch(action: Action) {
        return this.store.dispatch(action);
    }
}
