import { Injectable, ChangeDetectorRef, ApplicationRef, Inject, Optional } from '@angular/core';
import { FormBuilder, Validators, FormArray, ValidatorFn, FormControl, FormGroup, AbstractControl, ValidationErrors } from '@angular/forms';
import * as _ from 'lodash';
import { timer, of, Observable } from 'rxjs';
import { mapTo, catchError, switchMap, map } from 'rxjs/operators';
import { FORBIDDEN_NAMES_SERVICE, ForbiddenNamesService } from '../api/forbidden.injector';

@Injectable({
  providedIn: 'root'
})
export class FormGenerateService {
  continue = {};
  save = {};
  constructor(private fb: FormBuilder, private changeDetection: ApplicationRef,
    @Optional() @Inject(FORBIDDEN_NAMES_SERVICE) private forbiddenNamesService: ForbiddenNamesService) { 
    }

  /**
   * function to sort array
   * @param key key to sort array
   * @param order order of sort
   */
  compareValues(key?, order = 'asc') {
    return (a, b) => {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }
      const varA = (typeof a[key] === 'string') ? a[key].toUpperCase() : a[key];
      const varB = (typeof b[key] === 'string') ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return (
        (order === 'desc') ? (comparison * -1) : comparison
      );
    };
  }

  /**
   * function to set form data
   * @param formData
   */
  setFormValues(group: FormGroup, formData) {
      for (const val in formData) {
        if (group.controls[val]) {
          if (!Array.isArray(formData[val])) {
            group.get(val).patchValue(formData[val]);
          } else if (Array.isArray(formData[val])) {
            const formArray: FormArray = group.get(val) as FormArray;
            if (Array.isArray(formArray.controls) && formArray.controls.length > 0) {
              formData[val].forEach((value: any, index: number) => {
                const newFormGroup: any = _.cloneDeep(formArray.controls[0]);
                newFormGroup.reset();
                if (index === 0) {
                  formArray.controls.splice(0, 1);
                  formArray.insert(0, newFormGroup);
                  formArray.controls[index].patchValue(value);
                } 
                else {
                  formArray.push(newFormGroup);
                  formArray.controls[index].patchValue(value);
                }
              });
  
            } else {
              formData[val].forEach(value => {
                if (value)
                  formArray.push(new FormControl(value));
              });
            }
          }
        }
      }
  }

  /**
   * function to create form controls
   * @param fields
   */
  createControl(fields, group?: FormGroup) {
    const customFields = JSON.parse(JSON.stringify(fields));
    if (!group)
      group = this.fb.group({});
    customFields.forEach(field => {
      if (field.type === 'radio' || field.type === 'select') {
        field.validations = this.removePatternValidation(field.validations);
      }
      if (field.type === 'button') return;

      if (field.type === 'checkbox') {
        const control = this.fb.array([], this.minSelectedCheckboxes(1));
        this.bindValidations(field.validations, field.name);
        group.addControl(field.name, control);
      } else if (field.type === 'group' && !field.multiple) {
        group.addControl(field.name, this.createControl(field.group_fields));
      } else if (field.type === 'group' && field.multiple) {
        group.addControl(field.name, this.fb.array([this.createControl(field.group_fields)]));
      }
      else {
        let value = field.value;
        if (field.disabled === true) {
          value = { value: field.value, disabled: true }
        }
        const control = this.fb.control(
          value,
          this.bindValidations(field.validations || [], field.name),
          this.bindValidationsAsync(field.validations || [], field.name)
        );
        group.addControl(field.name, control);
      }
    });
    return group;
  }

  /**
   *
   * function to bind validations to form controls
   * @param validations: validation array
   * @param name: field name
   */
  bindValidations(validations: any, name: string) {
    if (validations.length > 0) {
      const validList = [];
      validations.forEach(valid => {
        if(valid.async){
          return;
        }
        if (Array.isArray(valid.action) && valid.action.length > 0) {
          if (valid.action.includes('continue')) {
            this.createActionData('continue', name, valid.name);
          }
          if (valid.action.includes('save')) {
            this.createActionData('save', name, valid.name);
          }
        }
        if (valid.name !== 'step' && valid.name !== 'decimal_places' && valid.name !== 'ngbDate') {
          valid.name === 'pattern'
            ? validList.push(Validators.pattern(valid.validations.replace(/^\/|\/$/g, '')))
            : valid.name === 'required'
              ? validList.push(Validators[valid.validations])
              : valid.name === 'matchWith'
                ? validList.push(this.matchWithValidator(valid.validations,validations))
                : valid.name === 'forbiddenNames'
                  ? validList.push(this.forbiddenNameValidator(valid.validations))
                  : validList.push(Validators[valid.name](valid.validations));
        }
      });
      return Validators.compose(validList);
    }
    return null;
  }


  bindValidationsAsync(validations: any, name: string) {
    if (validations.length > 0) {
      const validList = [];
      validations.forEach(valid => {
        if(!valid.async){
          return;
        }
        if (Array.isArray(valid.action) && valid.action.length > 0) {
          if (valid.action.includes('continue')) {
            this.createActionData('continue', name, valid.name);
          }
          if (valid.action.includes('save')) {
            this.createActionData('save', name, valid.name);
          }
        }
     
        valid.name === 'forbiddenNames'
          ? validList.push(ForbiddenNamesAsync.forbiddenNames(this.forbiddenNamesService))
          : '';

      });
      return validList;
    }
    return null;
  }

  /**
   * function to create save and continue action data
   * @param action
   * @param fieldName
   * @param validationName
   */
  createActionData(action: string, fieldName: string, validationName: string) {
    if (this[action][fieldName]) {
      this[action][fieldName].push(validationName);
    } else {
      this[action][fieldName] = [];
      this[action][fieldName].push(validationName);
    }
  }

  /**
   * function to remove validations
   * @param validations
   */
  removePatternValidation(validations) {
    validations.forEach((val, key) => {
      if (val.name === 'pattern') {
        validations.splice(key, 1);
      }
    });
    return validations;
  }

  /**
   * function to add validation for minimum selected checkboxes
   * @param min
   */
  minSelectedCheckboxes(min = 1) {
    const validator: ValidatorFn = (formArray: FormArray) => {
      const totalSelected = formArray.controls.length;
      return totalSelected >= min ? null : { required: true };
    };
    return validator;
  }

  /**
  * function to validate form fields
  * @param fields: formfields array
  * @param form: Formgroup object
  * @param validateArr: array to validate
  */
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      control.markAsTouched({ onlySelf: true });
    });
    const formGroupInvalid = document.body.querySelectorAll('input.ng-invalid, select.ng-invalid');
    (formGroupInvalid[0] as HTMLInputElement).focus();
  }

  /**
   * function to find invalid controls
   * @param group
   */
  findInvalidControls(group: FormGroup) {
    const invalid = [];
    const controls = group.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push({ name, errors: controls[name].errors });
      }
    }
    return invalid;
  }

  /**
   * function to validate form fields on basis of action
   * @param group: Formgroup
   * @param action: save/continue
   */
  validateCustomFormFields(group: FormGroup, action: string, fields: any): boolean {
    let counter = 0;
    let fieldCounter = 0;
    // tslint:disable-next-line: forin
    for (const control in group.controls) {
      if (group.get(control).hasError('incorrect')) {
        return false;
      } else if (fields[fieldCounter]) {
        if (fields[fieldCounter].type === 'group') {
          if (this.isFormVisible(group, fields[fieldCounter])) {
            if (fields[fieldCounter].multiple) {
              const form = group.get(control) as FormArray;
              for (const val of form.controls) {
                if (!this.validateCustomFormFields(val as FormGroup, action, fields[fieldCounter].group_fields))
                  counter++;
              }
            } else if (!fields[fieldCounter].multiple) {
              if (!this.validateCustomFormFields(group.get(control) as FormGroup, action, fields[fieldCounter].group_fields))
                counter++;
            }
          }
        } else {
          if (this.checkValidation(this[action][control], group.get(control) as FormControl)
            && group.get(control).enabled && !group.get(control).valid
            && this.isFormVisible(group, fields[fieldCounter])) {
            group.get(control).markAsTouched();
            group.get(control).markAsDirty();
            counter++;
          } else {
            group.get(control).markAsUntouched();
            group.get(control).markAsPristine();
          }
        }
        fieldCounter++;
      }
    }
    if (counter > 0) {
      const formGroupInvalid = document.body.querySelectorAll('input.ng-invalid, select.ng-invalid');
      (formGroupInvalid[0] as HTMLInputElement).focus();
      return false;
    }
    return true;
  }

  /**
   * function to check if error exists on form control
   * @param validationArray
   * @param control
   */
  checkValidation(validationArray, control: FormControl) {
    if (validationArray) {
      for (const valid of validationArray) {
        if (control.hasError(valid))
          return true;
      }
    }
    return false;
  }

  /**
   * check form validation if control dependent on other control
   * @param group
   * @param field
   */
  isFormVisible(group: FormGroup, field: any) {
    if (field && field.visible && field.visible.key) {
      if (Array.isArray(field.visible.value)) {
        return field.visible.value.includes(group.get(field.visible.key).value);
      }
      if (Array.isArray(group.get(field.visible.key).value)) {
        return group.get(field.visible.key).value.includes(field.visible.value);

      }

      return group.get(field.visible.key).value === field.visible.value;

    } else {
      return true;
    }
  }

  /**
  * function to add validation to match two fields
  * @param min
  */
 matchWithValidator(toControlName: string,validations) {
  let ctrl: FormControl;
  let toCtrl: FormControl;
  let validation=validations.find(validation=>validation.name==='required')
  return function matchWith(control: FormControl): { [key: string]: any } {
    if (!control.parent) {
      return null;
    }
    if (!ctrl) {
      ctrl = control;
      toCtrl = control.parent.get(toControlName) as FormControl;
      if (!toCtrl) {
        return null;
      }
      toCtrl.valueChanges.subscribe(() => {
        ctrl.updateValueAndValidity();
      });
    }
    if (!validation ||((ctrl.value !== null&& ctrl.value !== '') &&  (toCtrl.value !==null&&toCtrl.value!==''))) {
      if (toCtrl.value !== ctrl.value) {
        if(ctrl.value!=='' && ctrl.value!==null){
          ctrl.markAsDirty()
        }
        return {
          matchWith: true
        }
      }
    }
    return null;
  }
}

  forbiddenNameValidator(forbiddenNames: string[]): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const forbidden = forbiddenNames.includes(control.value);
      return forbidden ? {'forbiddenNames': {value: control.value}} : null;
    };
  }

}


export class ForbiddenNamesAsync {
  static forbiddenNames(service: { forbiddenNames: (name: string) => Observable<any> }) {
    let prev_value =  null;
    let prev_status = null;
    return (control: AbstractControl):Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      if(prev_value === control.value){
        return of(prev_status);
      }
      prev_value = control.value;
      if(!control.value){
        prev_status = null;
        return of(null);
      }
      const value = control.value;
      return timer(1000).pipe(
        switchMap(() => {
          return service.forbiddenNames(value).pipe(
            map(()=>{
              prev_status = null;
              return null;
            }),
            catchError(err => {
              prev_status = { forbiddenNames: { value: control.value } };
              return of(prev_status);
            })
          )
        }
        ));
    }
  }
}
