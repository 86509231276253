import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'platform-core-action-cell-renderer',
  templateUrl: './action-cell-renderer.component.html',
})
export class ActionCellRendererComponent implements ICellRendererAngularComp {

  params: any;
  config: any;
  bankerJourney = 'banker';
  is_authorized_banker: boolean;
  app_data: any;
  company_role_obj = {
    'guarantor': '5c91e94d3a2ba0289ce68a95',
    'key_employee': '5c91e94d3a2ba0289ce71a44',
    'co_borrower' : '5c91e94d3a2ba0289ce68a94',
    'trust': '5c91e94d3a2ba0298ce84a39',
    '401k': '5c91e94d3a2ba0198ce86a39'
  };
  owner: any;
  consentAction: boolean;
  
  agInit(params): void {
    this.params = params;
    this.config = this.params.config;
    this.is_authorized_banker = this.params.is_authorized_banker;
    this.app_data = params.app_data;
    this.owner = this.params.data;
    this.consentAction = this.params.consentAction ?? false;
  }

  refresh(params?: any): boolean {
    return true;
  }

  onClick($event, action) {
    if (this.params.onClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params,
        action
      }
      this.params.onClick(params);
    }
 }

  showIcon(icon: string, owner?: any): boolean{
    if(this.config){
      let checkUserAssigned = (this.config?.journey != 'banker' || (this.config?.journey === 'banker' && this.is_authorized_banker));
      let levelCondition = owner?.parent_id === this.config?.app_data?.parent_id;
      switch (icon) {
        case 'deleteIcon':
          if(!owner?._id) return true;
          return checkUserAssigned && (levelCondition  && owner.is_secondary && this.config.journey !== this.bankerJourney);
  
        case 'sendIcon' : 
          if(this.config?.journey === this.bankerJourney && checkUserAssigned) return owner.consent === 'pending'; 
          return checkUserAssigned && checkUserAssigned && this.config?.componentType!=='owner-verification' && owner?._id && levelCondition && owner.consent === 'pending' && owner?.is_primary;
  
        case 'pendingIcon':
          return this.config?.componentType!=='owner-verification' && owner.parent_id !== this.app_data?.parent_id && owner.consent === 'pending' && this.config?.journey !== this.bankerJourney;
        case 'refreshIcon':
          return this.config?.componentType!=='owner-verification' && owner.consent === 'sent' || (owner.consent === 'pending' && owner.parent_id !== this.app_data?.parent_id && this.config?.journey !== this.bankerJourney);
  
        case 'editIcon' :
          if((!owner?._id) || (this.config?.journey === this.bankerJourney && checkUserAssigned)) {
            return  true
          };
          return checkUserAssigned && ((owner?.parent_id === this.app_data?.parent_id && ( owner?.consent === 'pending' || owner?.consent === 'sent' || owner?.consent === 'received')));
  
        case 'resendIcon' : 
          return checkUserAssigned && this.config?.componentType!=='owner-verification' && owner.consent === 'sent' && owner?.is_primary === true; 
          
        case 'addOwnerInAccordian' :
          return this.config.show_add_owner_option_in_accordian && owner.owner_type == 'corporate' && owner?.level < 4 && owner.consent == "received"; 
          
        case 'addOwnerInCompanyDetails' : 
          return this.config.show_add_owner_option_in_company_details;
  
        case 'addOwnerInBottom' :
          return this.config.show_add_owner_option_in_bottom;
          
        case 'businessOwnerShipPercentageIcon' :
          return checkUserAssigned && this.config.showBusinessOwnerShipPercentage && !(owner?.owner_type == "corporate" && owner?.company_role==this.company_role_obj?.co_borrower);  
        default:  
          break;
      }
    }
    return false;
  }

}