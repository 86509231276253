import { Action } from '@ngrx/store';
import { TaskInfoInterface } from '@rubicon/interface/task-info';

export enum SignupStateActionTypes {
    LoadSignupState = '[AffiliateSignupState] Load SignupState',
    SignupStateLoaded = '[AffiliateSignupState] SignupState Loaded',
    SignupStateLoadError = '[AffiliateSignupState] SignupState Load Error',
    TryLoadingSignupTaskInfo = '[CreateSignupState] Try Loading Signup TaskInfo',
    LoadSignupTaskInfoSuccess = '[CreateSignupState] Load Signup TaskInfo Success',
    LoadSignupTaskInfoError = '[CreateSignupState] Load Signup TaskInfo Error',
    SetSignupTaskInfoState = '[CreateSignupState] Set Signup State',
    SignupFormSubmit = '[CreateSignupState] Signup Form Submit',
    SignupFormCompleted = '[CreateSignupState] Signup Form Completed',
    DoLogin = '[DoLogin] Do Login',
    DoLoginSuccess = '[DoLogin] Do Login Success',
    DoLoginError = '[DoLogin] Do Login Error',
    SendVerificationMail = '[SendVerificationMail] Verification Mail',
    SendVerificationMailSuccess = '[SendVerificationMail] Verification Mail Success',
    SendVerificationMailError = '[SendVerificationMail] Verification Mail Error',
    CheckVerificationToken = '[CheckVerificationToken] Check Token',
    CheckVerificationTokenSuccess = '[CheckVerificationToken] Check Token Success',
    CheckVerificationTokenError = '[CheckVerificationToken] Check Token Error',
    VerifyToken = '[VerifyToken] Verify Token',
    VerifyTokenSuccess = '[VerifyToken] Verify Token Success',
    VerifyTokenError = '[VerifyToken] Verify Token Error',
    SendRegisterationMail = '[SendRegisterationMail] Verify Token',
    SendRegisterationMailSuccess = '[SendRegisterationMail] Verify Token Success',
    SendRegisterationMailError = '[SendRegisterationMail] Verify Token Error'
};


export class TryLoadingSignupTaskInfo implements Action {
    readonly type = SignupStateActionTypes.TryLoadingSignupTaskInfo;

    constructor(public payload: string) { }
}

export class LoadSignupTaskInfoSuccess implements Action {
    readonly type = SignupStateActionTypes.LoadSignupTaskInfoSuccess;

    constructor(public payload: TaskInfoInterface) { }
}

export class LoadSignupTaskInfoError implements Action {
    readonly type = SignupStateActionTypes.LoadSignupTaskInfoError;

    constructor(public payload: any) { }
}

export class SignupFormSubmit implements Action {
    readonly type = SignupStateActionTypes.SignupFormSubmit;

    constructor(public slug: string, public payload: any) { }
}

export class SignupFormCompleted implements Action {
    readonly type = SignupStateActionTypes.SignupFormCompleted;

    constructor(public payload: any) { }
}

export class DoLogin implements Action {
    readonly type = SignupStateActionTypes.DoLogin;

    constructor(public payload: any) { }
}

export class DoLoginSuccess implements Action {
    readonly type = SignupStateActionTypes.DoLoginSuccess;

    constructor(public payload: any) { }
}

export class DoLoginError implements Action {
    readonly type = SignupStateActionTypes.DoLoginError;

    constructor(public payload: any) { }
}

export class SendVerificationMail implements Action {
    readonly type = SignupStateActionTypes.SendVerificationMail;

    constructor(public payload: any) { }
}

export class SendVerificationMailSuccess implements Action {
    readonly type = SignupStateActionTypes.SendVerificationMailSuccess;

    constructor(public payload: any) { }
}

export class SendVerificationMailError implements Action {
    readonly type = SignupStateActionTypes.SendVerificationMailError;

    constructor(public payload: any) { }
}

export class CheckVerificationToken implements Action {
    readonly type = SignupStateActionTypes.CheckVerificationToken;

    constructor(public payload: any) { }
}

export class CheckVerificationTokenSuccess implements Action {
    readonly type = SignupStateActionTypes.CheckVerificationTokenSuccess;

    constructor(public payload: any) { }
}

export class CheckVerificationTokenError implements Action {
    readonly type = SignupStateActionTypes.CheckVerificationTokenError;

    constructor(public payload: any) { }
}

export class VerifyToken implements Action {
    readonly type = SignupStateActionTypes.VerifyToken;

    constructor(public payload: any) { }
}

export class VerifyTokenSuccess implements Action {
    readonly type = SignupStateActionTypes.VerifyTokenSuccess;

    constructor(public payload: any) { }
}

export class VerifyTokenError implements Action {
    readonly type = SignupStateActionTypes.VerifyTokenError;

    constructor(public payload: any) { }
}
export class SendRegisterationMail implements Action {
    readonly type = SignupStateActionTypes.SendRegisterationMail;

    constructor(public payload: any) { }
}

export class SendRegisterationMailSuccess implements Action {
    readonly type = SignupStateActionTypes.SendRegisterationMailSuccess;

    constructor(public payload: any) { }
}

export class SendRegisterationMailError implements Action {
    readonly type = SignupStateActionTypes.SendRegisterationMailError;

    constructor(public payload: any) { }
}


export type SignupStateAction =
    | TryLoadingSignupTaskInfo
    | LoadSignupTaskInfoSuccess
    | LoadSignupTaskInfoError
    | SignupFormSubmit
    | SignupFormCompleted
    | DoLogin
    | DoLoginSuccess
    | DoLoginError
    | SendVerificationMail
    | SendVerificationMailSuccess
    | SendVerificationMailError
    | CheckVerificationToken
    | CheckVerificationTokenSuccess
    | CheckVerificationTokenError
    | VerifyToken
    | VerifyTokenSuccess
    | VerifyTokenError
    | SendRegisterationMail
    | SendRegisterationMailSuccess
    | SendRegisterationMailError

export const fromSignupStateActionTypes = {
    TryLoadingSignupTaskInfo,
    LoadSignupTaskInfoSuccess,
    LoadSignupTaskInfoError,
    SignupFormSubmit,
    SignupFormCompleted,
    DoLogin,
    DoLoginSuccess,
    DoLoginError,
    SendVerificationMail,
    SendVerificationMailSuccess,
    SendVerificationMailError,
    CheckVerificationToken,
    CheckVerificationTokenSuccess,
    CheckVerificationTokenError,
    VerifyToken,
    VerifyTokenSuccess,
    VerifyTokenError,
    SendRegisterationMail,
    SendRegisterationMailSuccess,
    SendRegisterationMailError
};
