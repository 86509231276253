import { createFeatureSelector, createSelector } from '@ngrx/store';

export interface DashboardState {
    dashboardActive: boolean;
    statusUpdatedCount: number;
    statusPipeline: {
        status_id: string, 
        status_mapping_key: string
    } | null,
    dateRange: {
        start_date: string,
        end_date: string
    } | null,
    toggleAppListType: string | null
}

export const selectDasbhoard = createFeatureSelector("dashboard");
export const selectDateRange = createSelector(
    selectDasbhoard,
    (state: DashboardState) => {
        return state?.dateRange
    }
);
export const selectShowRangeFilter = createSelector(
    selectDasbhoard,
    (state: DashboardState)=>{
        return state?.dashboardActive;
    }
)

export const selectToggleAppListType = createSelector(
    selectDasbhoard,
    (state: DashboardState)=>{
        return state?.toggleAppListType;
    }
);