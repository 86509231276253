import { Component, OnInit,Inject, Input, TemplateRef, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { CommonService, FormGenerateService, TaskInfoService, addAppID, addBusinessID, addAppDetails } from '@rubicon/utils';
import { distinctUntilChanged } from 'rxjs/operators';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { WorkflowActionsService } from '../services/workflow-actions.service';
import { AccessValidatorService } from '../../../../biz2xPro-config-engine';
import { DateTimezonePipe } from '@rubicon/libraries/core-utils/shared-lazy';

@Component({
  selector: 'additional-header',
  templateUrl: './additional-header.component.html',
  styleUrls: ['./additional-header.component.scss']
})
export class AdditionalHeaderComponent implements OnInit, OnDestroy {
  loginUser: any;
  userData: any;
  @Input() startApplication: any;
  enableApplication: boolean = false;
  modalRef: BsModalRef;
  bankerAppConfig = [];
  bankerCreateAppForm: FormGroup;
  isActionAllowed: boolean = false;
  slug: string;
  appData: any;
  workflowAction: any;
  unassignedStageBankers: any;
  
  constructor(
    private store: Store<any>,
    private common: CommonService,
    private taskInfoService: TaskInfoService,
    private modalService: BsModalService,
    private formGenerate: FormGenerateService,
    @Inject('environment') public environment,
    @Inject("CONSTANTS") public CONSTANTS,
    private router: Router,
    public workflowActionsService: WorkflowActionsService,
    public accessValidatorService: AccessValidatorService,
    public dateTimezonePipe: DateTimezonePipe
    ) { }
  ngOnInit(): void {
    (this.router.url === this.CONSTANTS.APP_ROUTES['manage-leads'])?this.enableApplication=true:""; 
    this.store.select('app').subscribe(rootState => {
      if (rootState?.userData?.id) {
        const userData = rootState.userData;
        this.appData = rootState.appData;
        this.userData = {
          ...userData
        };
        this.isActionAllowed = this.userData?.create_application;
      } else {
        this.userData=null;
      }
      this.workflowAction=this.workflowActionsService.getWorkflowActions('create_application');
      if(this.userData?.admin_privilage){ // for ticket 927
        this.setBankersList();
      }
    });
  }

  openModal(template: TemplateRef<any>){
    this.taskInfoService.getTaskInfo({slug: this.CONSTANTS.SLUG['start_an_application']}).subscribe(response => {
      this.bankerAppConfig = response.form_fields;
      this.slug = response.task_slug;
      this.bankerCreateAppForm = this.formGenerate.createControl(this.bankerAppConfig);
      this.common.sendMasterDataToFields(this.bankerAppConfig, response.response_data);
    })
    this.modalRef = this.modalService.show(template, { class: 'modal-lg createAnApplication', backdrop: 'static' });
  }

  onSubmit(action: string) {
    if(this.formGenerate.validateCustomFormFields(this.bankerCreateAppForm, action, this.bankerAppConfig)) {
      const payload = {
        ...this.bankerCreateAppForm.value,
        phone : Number(this.bankerCreateAppForm.get('phone').value),
        mobile : Number(this.bankerCreateAppForm.get('phone').value),
        source : 'banker',
        agreed_terms_and_conditions : false,
        role_id: this.userData.role_id,
        banker_user_id: this.userData.user_id,
        banker_email_address :  this.userData.email_address,
        header_logo_path_1: this.environment.logo1_path,
        header_logo_path_2: this.environment.logo2_path,
        banker_url: this.environment.banker_url,
        action: 'BANKER_CREATE_NEW_APP_REQUEST',
        role: this.userData.role_slug,
        mail_client_name: this.CONSTANTS.MAIL_TEMPLATE.project_name,
        senders_name: this.CONSTANTS.MAIL_TEMPLATE.senders_name,
        copyright_text: this.CONSTANTS.MAIL_TEMPLATE.copyright_text,
        user_name: this.userData.full_name,
        privacy: this.environment.privacy,
        terms: this.environment.terms,
        submitted_date: this.dateTimezonePipe.transform(new Date(), 'MM-dd-yyyy'),
        to_name_bankerlist : [this.userData.full_name.split(' ')[0]],
        to_bankerlist: [this.userData.email_address]
      } 
      if (this.bankerCreateAppForm.value?.business_name) {
        payload.business_name_template = `<b>Business Name:-</b>${this.bankerCreateAppForm.value.business_name}`;
      } else {
        payload.business_name_template = "<p></p>";
      }
      
      // payload.next_role_id = this.userData.role_id;
      // payload.next_stage_id = stage_id
      // payload.assigned_to = this.userData.user_id
      // payload.backend_user_id = this.userData.user_id
      
      if(this.workflowAction?.config?.banker){
        payload.next_role_id = this.workflowAction?.config?.banker?.next_assignments?.[0]?.next_stage_role_id;
        payload.next_stage_id = this.workflowAction?.config?.banker?.next_assignments?.[0]?.next_stage_id;
        
        payload.banker_next_role_id = this.workflowAction?.config?.banker?.next_assignments?.[1]?.next_stage_role_id || this.userData?.role_id ;
        payload.banker_next_stage_id = this.workflowAction?.config?.banker?.next_assignments?.[1]?.next_stage_id;
        payload.stage_id = payload.banker_next_stage_id;
        payload.banker_assigned_to = this.userData.user_id
        payload.banker_backend_user_id = this.userData.user_id;
        payload.status_id = this.workflowAction?.config?.status;
        payload.sub_status_id = this.workflowAction?.config?.sub_status;
      }
      
      if(this.userData?.restricted_app_assignment){ // for ticket 927
        payload.is_admin_priviledge = true;
        payload.to_bankerlist = this.unassignedStageBankers?.map(banker=> banker.email_address);
        payload.to_name_bankerlist = this.unassignedStageBankers?.map(banker=> banker.name.split(' ')[0]);
        payload.stage_id = payload.next_stage_id; // ticket admin app stage
      }

      const params = {
        slug: this.CONSTANTS.SLUG['start_an_application']
      }
      this.taskInfoService.saveTaskInfo(params, payload).subscribe(response => {
        // if(response?.check_zip_code?.data?.data[0]?.serviceable!=true) {
        //   this.common.popToast('error', 'Error', 'This ZIP Code falls outside our business banking area.');
        //   return;
        // }
        if(response?.create_user.data?.code === 201){
          this.common.popToast('success', '', 'Application Initiated successfully. Please fill Application Details');
          let appid= response.create_application.data.data._id;
          let {_id:businessId, current_state} = response.create_business.data.data;
          let data = { first_name: response?.create_user.data.data?.first_name, 
                       last_name: response?.create_user.data.data?.last_name, 
                       email_address : response?.create_user.data.data?.email_address, 
                       record_id: response?.create_user.data.data?.record_id, 
                       phone: response?.create_user.data.data?.phone,
                       user_id: response?.create_user.data.data?.id,
                      }
                    
          this.store.dispatch(addAppID({ appID: appid}));
          this.store.dispatch(addBusinessID({ businessID: businessId }));
          this.store.dispatch(addAppDetails({ appData: {userData: data,  loan_id : response?.create_application.data.data.auto_id,
            status_id: response?.create_application.data.data?.status_id,user_id: response.create_application.data.data?.user_id, referrerRoute: "manage-leads"}}));
          this.modalRef.hide();
          this.common.navigate(current_state);  
        }
      })
    }
  }
  ngOnDestroy(){
    if(this.modalRef){
      this.modalRef.hide();
    }
  }

  setBankersList() {
    this.taskInfoService.getTaskInfo({ slug: "banker_by_role_id", role_id:  this.workflowAction?.config?.banker?.next_assignments?.[0]?.next_stage_role_id}).subscribe((res) => {
      if (res?.response_data?.get_banker_by_role?.data?.data?.length) {
        let stage_bankers = res?.response_data?.get_banker_by_role?.data?.data;
        this.unassignedStageBankers = stage_bankers;
      }
    })

  }

}
