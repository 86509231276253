import { Component, OnInit, Injectable, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { NgbDateStruct, NgbCalendar, NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl } from '@angular/forms';
import { FormFieldInterface } from '@rubicon/interface/form-field';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';

/**
 * Service handles how to save the data  in the form
 */
@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {
  readonly DELIMITER = '-';

  pad2(num) {
    return (num < 10 ? '0' : '') + num
  }

  fromModel(value: string): NgbDateStruct {
    let result: NgbDateStruct = null;
    if (value && value.length) {
      const date = new Date(value)
      result= {
        day:date.getUTCDate(),
        month:date.getUTCMonth() +1,
        year:date.getUTCFullYear()
         };
    }
    return result;
  }

  toModel(date: NgbDateStruct): string {
    let iso_date: string = null;
    if (date) {
      let new_date: Date = moment.utc([date.year,date.month-1,date.day]).toDate();
      // console.log(moment);
      iso_date = new_date.toUTCString();
    }
    // Some error coming when returning iso_date
    return iso_date;
  }
}

/**
 * This Service handles how the date is shown in the field
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

  private DELIMITER = '/';

  // private get DELIMITER() {
  //   return this.getBrowserDateDelimiter();
  // }

  pad2(num) {
    return (num < 10 ? '0' : '') + num;
  }

  parse(value: string): NgbDateStruct {
    let result: NgbDateStruct = null;
    if (value && value.length) {
      const date = value.split(this.DELIMITER);
      result = {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10)
      };
    }
    return result;
  }

  format(date: NgbDateStruct): string {
    let result: string = null;
    if (date) {
      result = this.pad2(date.month) + this.DELIMITER + this.pad2(date.day) + this.DELIMITER + date.year;
    }
    return result;
  }

  getBrowserDateDelimiter() {
    const test = function(regexp) {return regexp.test(window.navigator.userAgent)}
    switch (true) {
        case test(/edg/i): return "/"; //Microsoft Edge
        case test(/trident/i): return "/"; //Microsoft Internet Explorer
        case test(/firefox|fxios/i): return "/"; //Mozilla Firefox
        case test(/opr\//i): return "/"; //Opera
        case test(/ucbrowser/i): return "/"; //UC Browser
        case test(/samsungbrowser/i): return "/"; //Samsung Browser
        case test(/chrome|chromium|crios/i): return "/"; //Google Chrome
        case test(/safari/i): return "-"; //Apple Safari
        default: return "/";
    }
  }
}

@Component({
  selector: 'rubicon-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
   providers: [
     { provide: NgbDateAdapter, useClass: CustomAdapter },
     { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
   ]
})

export class DateComponent implements OnInit {
  @ViewChild('infoTip') infoTip: ElementRef;
  isFilled;
  field: FormFieldInterface;
  group: FormGroup;
  slug: string;
  model: NgbDateStruct;
  date: { year: number, month: number };
  indexVal: Number;
  today = new Date();
  currentMonth = this.today.getMonth();
  currentDate = this.today.getDate();
  currentYear = this.today.getFullYear();
  maxDate: any;
  minDate: any = {month: 1, year: 1900,day : 1};
  
  compDestroyed$ = new ReplaySubject(1);

  constructor(private calendar: NgbCalendar, config: NgbDateAdapter<string>) {
  }

  selectToday() {
    this.model = this.calendar.getToday();
  }

  ngOnInit() {
    this.isFilled = this.group.get(this.field.name).value ? true: false;
    this.group.get(this.field.name).valueChanges.subscribe((value)=>{
      this.isFilled = value? true: false;
    })
    const currentDate = new Date();
    this.indexVal = currentDate.getTime();
    this.field.validations.forEach((validation) => {
      let date = { year: currentDate.getFullYear(), month: currentDate.getMonth() + 1, day: currentDate.getDate() };
      switch (validation.validations) {
        case 'tommorow':
          date.day += 1;
          break;
        case 'yesterday':
          date.day -= 1;
          break;
        case 'another':
          let utc_date = moment.utc(validation.date);
          let another_date = new Date(`${utc_date.year()}/${this.pad2(utc_date.month()+1)}/${this.pad2(utc_date.date())}`);
          // let another_date = new Date(validation.date) ;
          date = {year: another_date.getFullYear(), month: another_date.getMonth() + 1, day: another_date.getDate()}
          break;
      }
      switch (validation.name) {
        case 'max':
          this.maxDate = date;
          if(validation.date_field){
            let max_field_control: FormControl = this.group.get(validation.date_field) as FormControl;
            if(max_field_control){
              this.checkForMaxDate(max_field_control.value, validation.days_by);
              max_field_control.valueChanges.pipe(
                takeUntil(this.compDestroyed$)
              ).subscribe((value)=>{
                  this.checkForMaxDate(value, validation.days_by);
              });
            }
          }
          break;
        case 'min':
          this.minDate = date;
          if(validation.date_field){
            let min_field_control: FormControl = this.group.get(validation.date_field) as FormControl;
            if(validation.custom_date){
              this.minDate =min_field_control.value
            }
            if(min_field_control){ 
              this.checkForMinDate(min_field_control.value, validation.days_by || 0);
              min_field_control.valueChanges.pipe(
                takeUntil(this.compDestroyed$)
              ).subscribe((value)=>{
                  this.checkForMinDate(value, validation.days_by || 0);
              });
            }
          }
          break;
      }


    });
  }

  checkForMinDate(dateString: string, days_by = 0){
    let min_date;
    let min_date_validation;
    if(dateString){
      min_date = this.getDaysAhead(dateString, days_by);
      if(this.maxDate ){
        let max_date_converted  = new Date(this.maxDate.year, this.maxDate.month - 1, this.maxDate.day);
        if(min_date.getTime() > max_date_converted.getTime()){
             min_date = max_date_converted;
        }
      }
      min_date_validation = { year: min_date.getFullYear(), month: min_date.getMonth() + 1, day: min_date.getDate() };
      
      if(!this.minDate){
        this.minDate = min_date_validation;
      } else{
        let min_date_converted  = new Date(this.minDate.year, this.minDate.month - 1, this.minDate.day);
        // if(min_date && min_date.getTime() < min_date_converted.getTime()){
          // console.log(this.field.name, "min", min_date_validation);
          this.minDate = min_date_validation;
        // }
      } 
    }
    // console.log("min date", dateString);
  }

  checkForMaxDate(dateString: string, days_by = 0){
    let max_date;
    let max_date_validation;
    if(dateString){
      max_date = this.getDaysAhead(dateString, days_by);
      if(this.minDate){
        let min_date_converted  = new Date(this.minDate.year, this.minDate.month - 1, this.minDate.day);
        if(max_date.getTime() < min_date_converted.getTime()){
             max_date = min_date_converted;
        }
      }
      max_date_validation = { year: max_date.getFullYear(), month: max_date.getMonth() + 1, day: max_date.getDate() };
      if(!this.maxDate){
        this.maxDate = max_date_validation;
      } else{
        let max_date_converted  = new Date(this.maxDate.year, this.maxDate.month - 1, this.maxDate.day);
        // if(max_date.getTime() < max_date_converted.getTime()){
          this.maxDate = max_date_validation;
        // }
      } 
    }
    // console.log("max date", dateString);
  }

  getDaysAhead(dateString, days){
    // let date;
    // date = new Date(dateString);
    // date.setDate(date.getDate() + days);
    let utc_date_ahead = moment(dateString).utc().add(days, 'days');
    let date = new Date(`${utc_date_ahead.year()}/${this.pad2(utc_date_ahead.month()+1)}/${this.pad2(utc_date_ahead.date())}`);
    return date;
  }

  pad2(num) {
    return (num < 10 ? '0' : '') + num
  }

  ngOnDestroy(){
    this.compDestroyed$.next();
    this.compDestroyed$.complete();
  }

}
