import { Injectable, Inject } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
// import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { concatMap, take } from 'rxjs/operators';
@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  skip_null_removal_for_keys = ["alt_covered_start_date","tin","ssn","year_of_establishment","business_news_organization","signer_title","title","is_government_declaration_linked"];
  skip_null_removal_for_slugs = ["pfs-form","lead-sba-submit", "sba-pfs-pdf-generate"]
  constructor(private store: Store<any>, @Inject('environment') private environment, @Inject('email_templates') private EMAIL_TEMPLATE) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headers = request.headers.append('X-REGION', this.environment.region);
    let x_tenant_id = this.environment.x_tenant_id
    const isFormData = request.body instanceof FormData;
    let body = {};
    let slug =request.params.get('slug');

    if (!isFormData) {
      if (request.body) {
        body = { ...request.body };
        body = !this.skip_null_removal_for_slugs.includes(slug) ? this.removeNullValues(body) : body;       
      }
    }
    const timeStamp = (new Date().getTime()) + '';
    let params = request.params;
    let template = this.EMAIL_TEMPLATE.find(template => slug in template) || null;

    if(template != null){
      params = request.params.appendAll(template[slug])
    }
    
    if (request.method.toUpperCase() === 'GET') {
      params = request.params.append('timestamp', timeStamp);
    }
    return this.store.select('app')
      .pipe(
        take(1),
        concatMap((state) => {
          if(x_tenant_id){
            headers = headers.append('x-tenant-id', this.environment.x_tenant_id);
          }
          if (state.userData && state.userData.id && !state.backendUserData) {
            headers = headers.append('X-SESSION-ID', state.userData.id);
          } else if(state.backendUserData) {
            headers = headers.append('mint', String(this.environment.mint));
            headers = headers.append('X-SESSION-ID', state.backendUserData.id);
          }
          if (state.appID) {
            headers = headers.append('app_id', state.appID);
          }
          request = request.clone({
            params,
            headers,
          });
          if (!isFormData) {
            request = request.clone({
              body: { ...request.body, ...body }
            });
          }
          return next.handle(request)
        }));
  }

  removeNullValues(body: any) {
    for (const key in body) {
      if (!this.skip_null_removal_for_keys.includes(key) && body[key] === null) {
        body[key] = undefined;
      }
      else if (typeof body[key] === 'object') {
        body[key] = this.removeNullValues(body[key])
      }
    }
    return body;
  }
}
