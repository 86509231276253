import { Component, OnInit } from '@angular/core';
import { ToasterConfig } from 'angular2-toaster';

@Component({
  selector: 'toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss']
})
export class ToasterComponent implements OnInit {

  config: ToasterConfig =
  new ToasterConfig({
    timeout: 7000,
    limit: 1
  });

  constructor() { }

  ngOnInit(): void {
  }

}
