import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { CommonService, FeatureAccessService } from '@rubicon/utils_custom';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { tool_tip_values } from 'libs/affiliate-portal/dashboard/src/lib/constants';
import { CpaService } from 'libs/utils/src/lib/services/client/cpa/cpa.service';

@Component({
  selector: 'rubicon-affiliate-footer',
  templateUrl: './affiliate-footer.component.html',
  styleUrls: ['./affiliate-footer.component.scss']
})
export class AffiliateFooterComponent implements OnInit {
  supportMail='cpasupport@biz2credit.com';
  modalRef: BsModalRef;
  my_client_tooltip: string = tool_tip_values.myClient_tooltip_content;
  show_support_mail: any;
  footer_terms_url: any;
  footer_privacy_url: any;
  isCpaClient: boolean;
  biz2credit_url: any;

  constructor(
    private FeatureService: FeatureAccessService,
    private modalService: BsModalService, 
    @Inject("urls") public URLS,
    @Inject('config') private CONFIG,
    private cpaService: CpaService,
    private common: CommonService,
  ) { }

  ngOnInit(): void {
    this.isCpaClient = this.cpaService.isPartnerCPA();
    this.footer_terms_url = this.common.getStaticUrl('footer_terms');
    this.footer_privacy_url = this.common.getStaticUrl('footer_privacy');
    this.biz2credit_url = this.common.getStaticUrl("biz2credit");
    this.show_support_mail = this.CONFIG?.features?.view?.footer?.support_mail ?? true;
    let plan = this.FeatureService.getPlan() ? this.FeatureService.getPlan().type : 'none';
    if(plan == "enterprise" || plan === 'custom'|| plan==='premium'){
      this.supportMail='cpasupport-premier@biz2credit.com';
    }
    this.FeatureService.planType.subscribe((data:any)=>{
      if(data.type == "enterprise" || data.type ==='premium'){
      this.supportMail='cpasupport-premier@biz2credit.com';
    }
    })
  }
 
  openModalWithpolicyfooter(templatepolicyfooter: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      templatepolicyfooter,
      Object.assign({}, { class: 'CpaPolicy modal-lg' })
    );
  }

  getYear() {
    return this.common.getYear();
  }

}
