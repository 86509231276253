
<div class="piyanoTitle fs16 fwsb" >Your Application Progress</div>
<ul class="piyanoNav">
    <li [ngClass]="step === +item.step ? 'active step'+(i+1) : step > +item.step ? 'done step'+(i+1) : 'step'+(i+1)" *ngFor="let item of businessItemList; let i = index" >
        <span class="circleap"></span> <dd (click)="clickable(step, item)" [style.cursor]="(item.step <= maxStep)?'pointer':'default'">{{ item.name }}</dd>
    </li>
</ul>
<div class="row fs12" *ngIf="step === 6 && environment?.products_enabled[0] == 'sbaloans'">
    <div class="py-5 mt-4 col-12" [innerHTML]="'sba-primary-note.html' | translate">
</div>
