import { Directive, ElementRef, HostListener, HostBinding, Renderer2 } from '@angular/core';

@Directive({
  selector: 'input'
})
export class InputFilledDirective {

  @HostListener('blur') onblur() {
    this.checkCss();
  }

  checkCss() {
    let inputValue: string = this.el.nativeElement.value.trim();
    if (this.el.nativeElement.parentNode) {
      if (inputValue && inputValue.length) {
        this.renderer.addClass(this.el.nativeElement.parentNode, 'focusActive');
      }
      else {
        this.renderer.removeClass(this.el.nativeElement.parentNode, 'focusActive');
      }
    }
  }
  constructor(private el: ElementRef, private renderer: Renderer2) {
    setTimeout(() => {
      this.checkCss();
    }, 500);
  }
}
