<header>      
    <div class="container">
        <div class="row logoRow">
            <div class="col">
                <div class="b2cLogo d-flex">
                    <div><img src="assets/images/logo.svg" alt="logo" class="img-fluid" style="width: 180px;"></div>   
                    <span class="d-md-none d-inline-block mr-lg-4 ml-auto cpalogo"><img src="assets/images/headerlogo.svg" alt="logo" class="img-fluid" style="width: 108px;"></span>                 
                </div>
            </div>
            
            <div class="col-12 col-md-9 col-lg-7 ml-auto text-lg-right pl-sm-0 text-center">
                <div class="headRightLink">
                    <span class="d-none d-md-inline-block mr-lg-4 mr-2 cpalogo"><img src="assets/images/headerlogo.svg" alt="logo" class="img-fluid" style="width: 108px;"></span>
                </div>
            </div>
            <div class="d-flex justify-content-end" *ngIf="isLoggedIn">
                <div class="btn-group" dropdown placement="bottom right">
                    <button id="button-alignment" dropdownToggle type="button" class="doted3 dropdown-toggle" aria-controls="dropdown-alignment">
                            &nbsp;
                    </button>
                    <ul id="dropdown-alignment" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-alignment">
                       <li role="menuitem"><a class="dropdown-item cp" (click)="onClick('logout')">Logout</a></li>
                   </ul>
                </div>
            </div>
        </div>
    </div>
</header>