<ng-container [formGroup]="group">

    <ng-container *ngIf="field?.css?.floatingLabel; else normal">
        <div  [ngClass]="(field?.css?.floatingLabelDivClass)? field?.css?.floatingLabelDivClass :field?.css?.labelParent">
            <label  [ngClass]="(field?.css?.labelClass)? field?.css?.labelClass :'labelText'"  [for]="field.name + '_' + indexVal">{{slug + ".label." + field.name | translate}}</label>
            <select *ngIf="!field.multiple" 
                [ngStyle]="{'background':field.multiple?'transparent':''}"
                [ngClass]="(field?.css?.floatingLabelClass)? field?.css?.floatingLabelClass :'floating-select'"  
                placeholder="&nbsp;"
                [id]="field.name + '_' + indexVal" [formControlName]="field.name">
                <option disabled value="null">{{slug + ".default_label." + field.name | translate }}</option>
                <option *ngFor="let val of field.options" class="selected" [ngValue]="val.id || val._id">
                    {{val.displayVal ? val.value: val.value && field.name!=='business_type' ? val.value :(slug + ".label." + val.type | translate) }} 
                    <!-- {{val.displayVal||!val.type ? val.value: (slug + ".label." + val.type | translate) }}  -->
                </option>
            </select>
            <select *ngIf="field.multiple"
                multiple
                [ngStyle]="{'background':field.multiple?'transparent':''}"
                [ngClass]="(field?.css?.floatingLabelClass)? field?.css?.floatingLabelClass :'floating-select'"  
                placeholder="&nbsp;"
                [id]="field.name + '_' + indexVal" [formControlName]="field.name">
                <option disabled value="null" *ngIf='field?.css?.show_default'>{{ slug + ".default_label." + field.name | translate }}</option>
                <option *ngFor="let val of field.options" class="selected" [ngValue]="val.id || val._id">
                    {{val.displayVal||!val.type ? val.value : (slug + ".label." + val.type | translate) }} 
                </option>
            </select>
            <span class="highlight"></span>
    
            <div *ngIf="group.get(field.name).invalid && (group.get(field.name).dirty || group.get(field.name).touched)"
                class="error-msg">
                <ng-container *ngFor="let validation of field.validations;">
                    <label class="error" *ngIf="group.get(field.name).hasError(validation.name)">
                        {{slug + ".errorMessage." + field.name + "." + validation.name | translate}}
                    </label>
                </ng-container>
            </div>
        </div>

    </ng-container>

    <ng-template #normal>
        <label [ngClass]="(field?.css?.labelClass)? field?.css?.labelClass :'primary-label'"
            [for]="field.name + '_' + indexVal">{{slug + ".label." + field.name | translate}}
        </label>
        <select class="form-control" [ngClass]="(field?.css?.normalSelect)? field?.css?.normalSelect :''" size="1" [id]="field.name + '_' + indexVal" [formControlName]="field.name">
            <option disabled value="null">{{ field?.css?.defaultlabel? (slug + ".default_label." + field.name | translate) : 'Please Select' }}</option>           
            <option *ngFor="let val of field.options" class="selected" [ngValue]="val.id || val._id">
                {{val.type?(slug + ".label." + val.type | translate): val.value }} </option>
            
        </select>
        <div *ngIf="group.get(field.name).invalid && (group.get(field.name).dirty || group.get(field.name).touched)"
            class="error-msg">
            <ng-container *ngFor="let validation of field.validations;">
                <label class="error" *ngIf="group.get(field.name).hasError(validation.name)">
                    {{slug + ".errorMessage." + field.name + "." + validation.name | translate}}
                </label>
            </ng-container>
        </div>
    </ng-template>
</ng-container>