<div class="container-fluid mainmenu">
    <div class="row">
        <div class="col-12">
            <div class="container">
                <nav class="navbar navbar-expand-lg navbar-light py-0">                   
                    <div class="navbar-collapse" id="navbarSupportedContent1">
                        <ul class="navbar-nav mr-auto">
                            <li class="nav-item " routerLinkActive="active"><a class="nav-link" [routerLink]="['/verifier/dashboard']"><img src="../assets/images/dashboard.svg" alt="Dashboard" class="align-top mt-1 pr-2"> Verifier Home</a></li>
                            <li class="nav-item"  routerLinkActive="active"><a class="nav-link" [routerLink]="['/verifier/verify-request']"><img src="../assets/images/verifiedMenu.svg" alt="CPA verify Request" class="align-top mt-15 pr-2"> Verify CPA Requests <sup class="badge badge-dark p-1 fs11 fwm rounded-circle cpaNmu">{{non_verified_count}}</sup></a></li>
                            <li class="nav-item"  routerLinkActive="active"><a class="nav-link" [routerLink]="['/verifier/incomplete-request']"><img src="../assets/images/incomplete-application.svg" alt="Incomplete Applications Request" class="align-top mt-1 pr-2"> Incomplete Applications</a></li>
                        </ul>  
                        <ul>
                            <li>Hi, {{ user_name ? user_name : ''}}</li>
                        </ul>  
                    </div>
                </nav>                
            </div>
        </div>
    </div>
</div>