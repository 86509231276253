<ng-container [formGroup]="group">


    <ng-container>

        <ng-container *ngIf="field?.css?.floatingLabel else normal">
            <div [ngClass]="(field?.css?.labelParent)? field?.css?.labelParent :'floating-label'">
               
                <ng-container *ngIf="slug=='document-listing-ppp2-2';else other">
                    <input rubiconBlockCopyPaste type="text" *ngIf="!field?.css?.employee_number_que_info" placeholder="&nbsp;"
                        [ngClass]="(field?.css?.field)? field?.css?.field :'form-control'" [formControlName]="field.name"
                        [id]="field.name + '_' + indexVal" [mask]="field.format?field.format.type:null"
                        [thousandSeparator]="field.format&&field.format.delimiter?field.format.delimiter:''" (focus)="showSearchList()"
                        (focusout)="hideSearchList()" [readOnly]="field?.readonly ? true: null">
                </ng-container>
                <label [ngClass]="(field?.css?.labelClass)? field?.css?.labelClass :'labelText'" [for]="field.name + '_' + indexVal">{{slug + ".label." + field.name | translate}}
                    <ng-container *ngIf="field?.css?.tooltip && field?.css?.tooltip[field.name] && field?.css?.employee_number_que_info"><!--
                    --><a  class='que_info-text' [triggers]="(field?.css?.tooltip && field?.css?.tooltip[field.name+ '_trigger'])?field?.css?.tooltip[field.name+ '_trigger']:'hover'" placement="right" [ngbTooltip]="tipContent"><!----></a><!--​
                    --><ng-template #tipContent><span [innerHTML]='slug + ".tooltip."+ field.name | translate'></span></ng-template><!--
                    --></ng-container>
                </label>
                <ng-template #other>
                    <input type="text" *ngIf="!field?.css?.employee_number_que_info" placeholder="&nbsp;"
                        [ngClass]="(field?.css?.field)? field?.css?.field :'form-control'" [formControlName]="field.name"
                        [id]="field.name + '_' + indexVal" [mask]="field.format?field.format.type:null"
                        [thousandSeparator]="field.format&&field.format.delimiter?field.format.delimiter:''" (focus)="showSearchList()"
                        (focusout)="hideSearchList()" [readOnly]="field?.readonly ? true: null">
                </ng-template>
                <span class="highlight"></span>
                <input type="text" *ngIf="field?.css?.employee_number_que_info" placeholder="&nbsp;" [ngClass]="(field?.css?.field)? field?.css?.field :'form-control'" [formControlName]="field.name" [id]="field.name + '_' + indexVal" [mask]="field.format?field.format.type:null" [thousandSeparator]="field.format&&field.format.delimiter?field.format.delimiter:''"
                (focus)="showSearchList()" [placeholder]="slug + '.placeholder.' + field.name | translate"
                (focusout)="hideSearchList()" [readOnly]="field?.readonly ? true: null">
                <span class="highlight"></span>

                <!-- <i *ngIf="field.name =='registration_code'" class="info" [tooltip]="slug + '.tooltip.' + field.name | translate">&nbsp;</i> -->
               <ng-container *ngIf="field?.css?.tooltip && field?.css?.tooltip[field.name] && !field?.css?.employee_number_que_info"><!--
               --><a  class='que_info' placement="right" [triggers]="(field?.css?.tooltip && field?.css?.tooltip[field.name+ '_trigger'])?field?.css?.tooltip[field.name+ '_trigger']:'hover'" [ngbTooltip]="tipContent"><!----></a><!--​
               --><ng-template #tipContent><span [innerHTML]='slug + ".tooltip."+ field.name | translate'></span></ng-template><!--
               --></ng-container><!--
               --><div *ngIf="group.get(field.name).invalid && (group.get(field.name).dirty || group.get(field.name).touched)"
               [ngClass]="(field?.css?.errorParent)? field?.css?.errorParent :'error-msg'" >
                    <ng-container *ngFor="let validation of field.validations;">
                        <label class="error" *ngIf="group.get(field.name).hasError(validation.name) && slug === 'cpabusiness-loan-info-forgiveness-v2' && field.name ==='covered_period_length' ; else normalMinMax">
                            {{slug + ".errorMessage." + field.name + "." + validation.name | translate}}
                        </label>
                        <ng-template #normalMinMax>
                            <label class="error" *ngIf="group.get(field.name).hasError(validation.name)">
                                {{slug + ".errorMessage." + field.name + "." + validation.name | translate}}
                                {{validation.name==="max"? (group.get(field.name).errors.max.max)+".":""}}
                                {{validation.name==="min"? (group.get(field.name).errors.min.min)+".":""}}
                            </label>
                        </ng-template>
                    </ng-container>
                    <label class="error" *ngIf="group.get(field.name).hasError('incorrect')">
                        {{slug + ".errorMessage." + field.name + ".incorrect" | translate}}
                    </label>
                    <label class="error" *ngIf="group.get(field.name).hasError('not_found')">
                        {{slug + ".errorMessage." + field.name + ".not_found" | translate}}
                    </label>
                </div>
                <label class="helpTextETran fs11" *ngIf="field?.css?.showHelpTextETran"  [innerHTML]='slug + ".aboutInfo." + field.name | translate'>
                </label>
            </div>


        </ng-container>

        <ng-template #normal>

            <label *ngIf="slug + '.label.' + field.name | translate" [ngClass]="(field?.css?.labelClass)? field?.css?.labelClass :'secondary-label'" [for]="field.name + '_' + indexVal">{{slug + ".label." + field.name | translate}}
            </label>

            <input type="text" [placeholder]="slug + '.placeholder.' + field.name | translate" [ngClass]="(field?.css?.field)? field?.css?.field :'form-control'" [formControlName]="field.name" [id]="field.name + '_' + indexVal" [mask]="field.format?field.format.type:null"
                [thousandSeparator]="field.format&&field.format.delimiter?field.format.delimiter:''" >
                
            <div *ngIf="group.get(field.name).invalid && (group.get(field.name).dirty || group.get(field.name).touched)" class="error-msg">
                <ng-container *ngFor="let validation of field.validations;">
                    <label class="error" *ngIf="group.get(field.name).hasError(validation.name)">
                        {{slug + ".errorMessage." + field.name + "." + validation.name | translate}}
                        {{validation.name==="max"? (group.get(field.name).errors.max.max)+".":""}}
                    </label>
                </ng-container>
                <label class="error" *ngIf="group.get(field.name).hasError('incorrect')">
                    {{slug + ".errorMessage." + field.name + ".incorrect" | translate}}
                </label>
            </div>
        </ng-template>
        <!-- 
        <div *ngIf="group.get(field.name).invalid && (group.get(field.name).dirty || group.get(field.name).touched)"
            class="error-msg">
            <ng-container *ngFor="let validation of field.validations;">
                <label class="error" *ngIf="group.get(field.name).hasError(validation.name)">
                    {{slug + ".errorMessage." + field.name + "." + validation.name | translate}}
                </label>
            </ng-container>
            <label class="error" *ngIf="group.get(field.name).hasError('incorrect')">
                {{slug + ".errorMessage." + field.name + ".incorrect" | translate}}
            </label>
        </div> -->
    </ng-container>
</ng-container>
<div class="search-result" *ngIf="autoData?.length > 0" [class.d-none]="!show_search_list">
    <ul>
        <ng-container *ngFor="let data of field.autoCompleteList; let i = index">
            <ng-container *ngIf="data.best && data.best.company_name_info.company_name">
                <li (click)="getAddress(i)"><a>{{data.best.company_name_info.company_name}}</a></li>
            </ng-container>
            <ng-container *ngIf="data.naics_code">
                <li (click)="getNaicsCode(i)"><a>{{data.naics_code}} - {{data.value}}</a></li>
            </ng-container>
            <ng-container *ngIf="data.lender_name">
                <li (click)="getLender(i)"> <a>{{data.lender_name}} ({{data.city}})</a></li>
            </ng-container>
            <ng-container *ngIf="data.city && data.value">
                <li (click)="getCity(data.value)"> <a>{{data.value}}</a></li>
            </ng-container>
            <ng-container *ngIf="data.value && field.name==='title'">
                <li (click)="getTitle(data.value)"> <a>{{data.value}}</a></li>
            </ng-container>
        </ng-container>
        <!-- <li *ngFor="let data of field.autoCompleteList; let i = index" (click)="getAddress(i)">
            <ng-container *ngIf="data.best && data.best.company_name_info.company_name;else lender">
                <a (click)="getAddress(i)">{{data.best.company_name_info.company_name}}</a>
            </ng-container>

            <ng-template #lender>
                <a (click)="getLender(i)">{{data.lender_name}}</a>
            </ng-template>
        </li> -->
    </ul>
</div>