import { Injectable } from '@angular/core';
import { MasterDataPipe } from '@rubicon/utils';
import * as _ from 'lodash';
@Injectable({
  providedIn: 'root'
})
export class OwnerListService {

  constructor(private masterDataPipe: MasterDataPipe) {}

  setLevelAndCount(ownerData : any){
    if(ownerData?.length){
      const ordinalCountObj= ownerData.reduce((obj : any,owner: any) => {
        if(owner.level === 0) {
          obj['levelzero'] = (obj['levelzero'] || 0) + 1;
          owner['ordinalCount'] =  obj['levelzero'];
        }
        else {
          obj[owner.parent_id] = (obj[owner.parent_id] || 0) + 1 ;
          owner['ordinalCount'] =  obj[owner.parent_id];
        }
        return obj;
      }, {})
    }
  }

  sortByLevel( a:any, b:any ) {
      if(a.level == b.level) return (a.parent_id < b.parent_id) ? -1 : (a.parent_id > b.parent_id) ? 1 : 0;
      else return (a.level < b.level) ? -1 : 1;
  }

  getSignersSBA(primaryBusiness, primaryOwner, secondaryOwners, refrences, company_role, allOwnersData, professional_title?) {
    secondaryOwners.sort(this.sortByLevel);
    let ownersData = _.cloneDeep([primaryBusiness, primaryOwner, ...secondaryOwners]);
    ownersData.forEach(owner => {
      if (owner.sba_process_status !== 1) {
        owner.selected_sba_sections = [];
        owner.signer_details = null;
      }
    });
    refrences = refrences.filter(el => el.type === "form_1919");
    let pages = {
      section_1: 0,
      section_2: 0,
      section_3: 0,
    };
    let mappingCompiled: any = {};
    mappingCompiled.ids = [{ first_pdf: [] }, { second_pdf: [] }, { third_pdf: [] }, { other_pdf: [] }];

    ownersData.forEach((element, index) => {
      element.index = index;
      if (element?.selected_sba_sections?.includes(1)) {
        let ref = refrences[refrences.map(el => el?.response?.form_owner_id === element._id).lastIndexOf(true)]?.ref_id;
        if (ref) {
          mappingCompiled.ids[0].first_pdf.push(ref);
          pages.section_1 = pages.section_1 + 4;
        }
      }
      if (element?.selected_sba_sections?.includes(2)) {
        let ref = refrences.find(el => el?.response?.form_owner_id === element._id)?.ref_id;
        if (ref) {
          mappingCompiled.ids[1].second_pdf.push(ref);
          pages.section_2 = pages.section_2 + 3;
        }
      }
      if (element?.selected_sba_sections?.includes(3)) {
        let ref = refrences.find(el => el?.response?.form_owner_id === element._id)?.ref_id;
        if (ref) {
          mappingCompiled.ids[2].third_pdf.push(ref);
          pages.section_3 = pages.section_3 + 2;
        }
      }
      if (element?.selected_sba_sections?.includes(1) || element?.selected_sba_sections?.includes(2) || element?.selected_sba_sections?.includes(3)) {
        let ref = refrences[refrences.map(el => el?.response?.form_owner_id === element._id).lastIndexOf(true)]?.ref_id;
        if (ref) {
          mappingCompiled.ids[3].other_pdf.push(ref);
        }
      }
    });

    let i = 0;
    let reciepents = [];
    let signer_ids = [];
    let final_signer_sec_mapping: any = {};
    let hashMap = {
      1: 0,
      2: 0,
      3: 0
    }
    ownersData.forEach(owner=> {
      if (owner.signer_details) {
        for (var section in owner.signer_details) {
          if (!final_signer_sec_mapping?.[allOwnersData.find(el => el._id === owner.signer_details[section])?._id]) {
            final_signer_sec_mapping[allOwnersData.find(el => el._id === owner.signer_details[section])?._id] = [];
          }
          final_signer_sec_mapping[allOwnersData.find(el => el._id === owner.signer_details[section])?._id].push({ [section]: hashMap[section] });
          hashMap[section] = hashMap[section] + 1;
          let signer = allOwnersData.find(el => el._id === owner.signer_details[section]);
          if (!signer_ids?.includes(signer?._id)) {
            signer_ids.push(signer?._id);
            reciepents.push(
              {
                "signer_email": signer?.email_address,
                "signer_name": signer?.businessname || (signer?.first_name + " " + signer?.last_name),
                "recipient_id": (i + 1).toString(),
                "routing_order": (i + 1).toString(),
              }
            );
            i = i + 1;
          }
        }
      }
    });
    let sign_tabs_pos_final: any = [];
    let date_tabs_pos_final: any = [];
    let initials_tabs_pos_final: any = [];
    let text_tabs_pos_final: any = [];
    let totalpages1 = 1;
    let totalpages2 = pages.section_1 + 1;
    let totalpages3 = pages.section_1 + pages.section_2 + 1;
    for (var signer in final_signer_sec_mapping) {
      let sign_tabs_pos = [];
      let date_tabs_pos = [];
      let initials_tabs_pos = [];
      let text_tabs_pos = [];
      let index = 0;
      if (!sign_tabs_pos[index]) {
        sign_tabs_pos[index] = [];
      }
      if (!date_tabs_pos[index]) {
        date_tabs_pos[index] = [];
      }
      final_signer_sec_mapping[signer].forEach(section => {
        if (Object.keys(section)[0] === '1') {
          sign_tabs_pos[index]={
            "x_position": 103,
            "y_position": 443,
            "page_number": totalpages1 + (+Object.values(section)[0]+1)*4,
          }
          let signerInfo = allOwnersData.find(el => el._id === signer);
          text_tabs_pos.push(...[{
            "name": signerInfo?.first_name + ' ' + signerInfo?.last_name,
            "page_number": totalpages1 + (+Object.values(section)[0] + 1) * 4,
            "x_position": 100,
            "y_position": 500
          },
          {
            "title": this.masterDataPipe.transform(signerInfo?.owner_title, professional_title,'id','value') || this.masterDataPipe.transform(signerInfo?.title_at_company, professional_title,'id','value'),
            "page_number": totalpages1 + (+Object.values(section)[0] + 1) * 4,
            "x_position": 420,
            "y_position": 500
          }]);

          date_tabs_pos[index]={
          "x_position": 404,
          "y_position": 458,
          "page_number": totalpages1 + (+Object.values(section)[0]+1)*4,
        }
        // totalpages1 = totalpages1 + 4;
        }
        if (Object.keys(section)[0] === '2') {
                sign_tabs_pos[index] = {
                  "x_position": 106,
                  "y_position": 559,
                  "page_number": totalpages2 + (+Object.values(section)[0]+1) * 3,
                }
                let signerInfo = allOwnersData.find(el => el._id === signer);
                text_tabs_pos.push(...[{
                  "name": signerInfo.first_name + ' ' + signerInfo.last_name,
                  "title":  this.masterDataPipe.transform(signerInfo?.owner_title, professional_title,'id','value') || this.masterDataPipe.transform(signerInfo?.title_at_company, professional_title,'id','value'),
                  "page_number": totalpages2 + (+Object.values(section)[0] + 1) * 3,
                  "x_position": 110,
                  "y_position": 615
                }]);
                date_tabs_pos[index] = {
                  "x_position": 384,
                  "y_position": 580,
                  "page_number": totalpages2 + (+Object.values(section)[0]+1) * 3,
                };
                initials_tabs_pos.push(...[
                    {
                      "page_number": totalpages2 + (+Object.values(section)[0]+1) * 3 - 2,
                      "x_position": 541,
                      "y_position": 369
                    },
                    {
                      "page_number": totalpages2 + (+Object.values(section)[0]+1) * 3 - 2,
                      "x_position": 541,
                      "y_position": 411
                    },
                    {
                      "page_number": totalpages2 + (+Object.values(section)[0]+1) * 3 - 2,
                      "x_position": 541,
                      "y_position": 477
                    },
                    {
                      "page_number": totalpages2 + (+Object.values(section)[0]+1) * 3 - 2,
                      "x_position": 541,
                      "y_position": 643
                    }
                  ])
                // totalpages2 = totalpages2 + 3;
              }
      if (Object.keys(section)[0] === '3') {
        sign_tabs_pos[index] = {
          "x_position": 110,
          "y_position": 559,
          "page_number": totalpages3 + (+Object.values(section)[0]+1) * 2,
        }
        let signerInfo = allOwnersData.find(el => el._id === signer);
        text_tabs_pos.push(...[{
          "name": signerInfo.first_name + ' ' + signerInfo.last_name,
          "title":  this.masterDataPipe.transform(signerInfo?.owner_title, professional_title,'id','value') || this.masterDataPipe.transform(signerInfo?.title_at_company, professional_title,'id','value'),
          "page_number": totalpages3 + (+Object.values(section)[0] + 1) * 2,
          "x_position": 110,
          "y_position": 615
        }]);
        date_tabs_pos[index] = {
          "x_position": 384,
          "y_position": 580,
          "page_number": totalpages3 + (+Object.values(section)[0]+1) * 2,
        }
        // totalpages3 = totalpages3 + 2;
        }
        index = index + 1;
      });
      sign_tabs_pos_final.push(sign_tabs_pos);
      text_tabs_pos_final.push(text_tabs_pos);
      date_tabs_pos_final.push(date_tabs_pos);
      initials_tabs_pos_final.push(initials_tabs_pos);
    }


    mappingCompiled.action1 = "1919_merger";
    mappingCompiled.type = "combine_pdfs";
    mappingCompiled.provider = "S10";
    return { sendForMergeMapping: mappingCompiled,  receipents: reciepents, sign_tabs_pos: sign_tabs_pos_final, date_tabs_pos: date_tabs_pos_final, initials_tabs_pos: initials_tabs_pos_final, signerIds:signer_ids, text_tabs_pos: text_tabs_pos_final };
    // return { sendForMergeMapping: mappingCompiled,  receipents: reciepents, sign_tabs_pos: sign_tabs_pos_final, date_tabs_pos: date_tabs_pos_final, initials_tabs_pos: initials_tabs_pos_final, signerIds};
  }





  validate_ownership(owners, business): any {
    let incomplete_ownership = Array.from(owners.reduce(
      (m, {parent_id, ownership}) => m.set(parent_id, parseFloat(((m.get(parent_id) || 0) + (ownership?+ownership:0)).toFixed(2))), new Map),
      ([parent_id, ownership]) => {
        if(+ownership !== 100 && parent_id!=='0') {
          return owners.find(ele=>ele._id===parent_id)?.businessname
        } else if(+ownership !== 100 && parent_id =='0') {
          return business.business_name
        }
      })?.filter(Boolean);
      if(incomplete_ownership?.length){
        return incomplete_ownership;
      } else {
        return [];
      }
  }


  getOwnersForTreeStructure(business, ownersData, company_role_obj?){
    if(!company_role_obj){
      company_role_obj = {
        co_borrower : '63e9e42eebaba70a71d230f2'
      };
    }
    business.parent_id = null;
    business.businessname = business.business_name;
    business.is_primary_business = true;
    let coborrowers = [];
    let owners = _.cloneDeep(ownersData).filter((owner: any) => {
      if (owner.parent_id === '0') owner.parent_id = business._id;
      if (owner.company_role === company_role_obj.co_borrower) {
        owner.parent_id = null;
        coborrowers.push(owner);
        return false;
      }
      return true;
    });
    return owners.concat(business, coborrowers);
  }

  updateActualOwnershipPercent(treeOwner){
    if(Array.isArray(treeOwner)){
      for(let owner of treeOwner){
        if(owner.is_primary_business){
          owner.ownership = 100;
        }
        this.updateActualOwnershipPercent(owner);
      }
    }else{
      if(Array.isArray(treeOwner.items)){
        let parentOwnerPercent = +treeOwner.ownership || 0;
        for(let child_owner of treeOwner.items){
          if(!child_owner.updatedActualOwnership){
            let childActualOwnership = parseFloat((parentOwnerPercent * (+child_owner.ownership || 0) / 100).toFixed(2))
            child_owner.ownership = childActualOwnership;
            child_owner.updatedActualOwnership = true;
            this.updateActualOwnershipPercent(child_owner);
          }
        }
      }
    }
  }



  // getSignersSBA(owners, refrences, business_data) {
  //   let ownersData = _.cloneDeep(owners);
  //   let signerIds = [];
  //   refrences = refrences.filter(el => el.type === "form_1919");
  //   let mappingCompiled: any = {};
  //   let reciepents: any = [];
  //   let sign_tabs_pos_final: any = [];
  //   let date_tabs_pos_final: any = [];
  //   let initials_tabs_pos_final: any = [];
  //   let reciepents_email = [];
  //   let esignMapping: any = {};
  //   let pages = {
  //     section_1: 0,
  //     section_2: 0,
  //     section_3: 0,
  //   };
  //   ownersData.sort(this.sortByLevel);
  //   let allSbaSections = [];
  //   ownersData.forEach((element, index) => {
  //     element.index = index;
  //     if (element.is_signer) {
  //       if (element.parent_id === '0') {
  //         allSbaSections.push(...(element?.selected_sba_sections?.map(ele => ele + '_' + index)));
  //         if (!element?.selected_sba_sections?.includes(1)) {
  //           allSbaSections.push('1_' + index);
  //         }
  //       }
  //       if (element.parent_id && element.parent_id !== '0') {
  //         let additionalBusiness = ownersData.find(ele => ele._id === element.parent_id);
  //         let sections = [];
  //         if (additionalBusiness?.selected_sba_sections?.length > 0) {
  //           sections.push(...additionalBusiness.selected_sba_sections);
  //         }
  //         if (element?.selected_sba_sections?.length > 0) {
  //           sections.push(...element.selected_sba_sections);
  //         }
  //         if (sections.length > 0) {
  //           allSbaSections.push(...(sections?.map(ele => ele + '_' + index)));
  //         }
  //       }
  //     } else if (!element.is_signer && element.owner_type === 'individual') {
  //       element?.selected_sba_sections?.forEach(element => {
  //         if (element !== 1) {
  //           allSbaSections.push(element + '_' + index);
  //         }
  //       });
  //     }
  //   });
  //   allSbaSections = allSbaSections.sort((one, two) => {
  //     const arr1 = one.split('_');
  //     const arr2 = two.split('_');
  //     return ((+arr1[0]) * 100 + (+arr1[1])) - ((+arr2[0]) * 100 + (+arr2[1]));
  //   });
  //   allSbaSections = allSbaSections.filter((v, i, a) => a.indexOf(v) === i);
  //   let hashMap = {
  //     1: 0,
  //     2: 0,
  //     3: 0
  //   }
  //   let i = 0;
  //   allSbaSections.forEach((section, index) => {
  //     let owner = ownersData[section.split('_')[1]];
  //     if (!reciepents_email.includes(owner.email_address)) {
  //       reciepents_email.push(owner.email_address);
  //       signerIds.push(owner._id);
  //       reciepents.push(
  //         {
  //           "signer_email": owner.email_address,
  //           "signer_name": owner.first_name || owner.businessname,
  //           "recipient_id": (i+1).toString(),
  //           "routing_order": (i+1).toString(),
  //         }
  //       );
  //       i = i + 1;
  //     }

  //     hashMap[section.split('_')[0]] = hashMap[section.split('_')[0]] + 1;
  //     if (esignMapping[owner.email_address]) {
  //       esignMapping[owner.email_address].push(section.split('_')[0] + '_' + (hashMap[section.split('_')[0]]));
  //     } else {
  //       esignMapping[owner.email_address] = [];
  //       esignMapping[owner.email_address].push(section.split('_')[0] + '_' + (hashMap[section.split('_')[0]]));
  //     }
  //   })

  //   refrences = refrences.sort((a, b) => a.created_at - b.created_at);
  //   mappingCompiled.ids = [{ first_pdf: [] }, { second_pdf: [] }, { third_pdf: [] }, { other_pdf: [] }];
  //   let allSbaSectionsClone = _.cloneDeep(allSbaSections);
  //   let primary = ownersData.find(el => el.is_primary);
  //   allSbaSectionsClone[0] = `1_${primary.index}`;
  //   allSbaSectionsClone.forEach((section) => {
  //     const owner = _.cloneDeep(ownersData[section.split('_')[1]]);
  //     if (owner.parent_id === '0') {
  //       owner.parent_id = owner._id;
  //     }
  //     if (section.split('_')[0] === '1') {
  //       let ref = refrences.find(el => el?.owner_id === owner.parent_id);
  //       if (ref) {
  //         mappingCompiled.ids[0].first_pdf.push(ref?.ref_id);
  //         mappingCompiled.ids[3].other_pdf.push(ref?.ref_id);
  //         pages.section_1 = pages.section_1 + 4;
  //       }
  //     }
  //     if (section.split('_')[0] === '2') {
  //       let ref = refrences.find(el => el?.owner_id === owner._id);
  //       if (ref) {
  //         mappingCompiled.ids[1].second_pdf.push(ref?.ref_id);
  //         pages.section_2 = pages.section_2 + 3;
  //       }
  //     } if (section.split('_')[0] === '3') {
  //       let ref = refrences.find(el => el?.owner_id === owner.parent_id);
  //       if (ref) {
  //         mappingCompiled.ids[2].third_pdf.push(ref?.ref_id);
  //         mappingCompiled.ids[3].other_pdf.push(ref?.ref_id);
  //         pages.section_3 = pages.section_3 + 2;
  //       }
  //     }
  //   });
  //   let totalpages1 = 1;
  //   let totalpages2 = pages.section_1 + 1;
  //   let totalpages3 = pages.section_1 + pages.section_2 + 1;
  //   for (var key in esignMapping) {
  //     let sign_tabs_pos: any = [];
  //     let date_tabs_pos: any = [];
  //     let initials_tabs_pos: any = [];
  //     esignMapping[key].forEach((section, index) => {
  //       if (!sign_tabs_pos[index]) {
  //         sign_tabs_pos[index] = [];
  //       }
  //       if (!date_tabs_pos[index]) {
  //         date_tabs_pos[index] = [];
  //       }
  //       if (section.split('_')[0] === '1') {
  //         sign_tabs_pos[index]={
  //           "x_position": 103,
  //           "y_position": 443,
  //           "page_number": totalpages1 + (+(section.split('_')[1]) * 4),
  //         }
  //         date_tabs_pos[index]={
  //           "x_position": 404,
  //           "y_position": 458,
  //           "page_number": totalpages1 + (+(section.split('_')[1]) * 4),
  //         }
  //         // totalpages1 = totalpages1 + 4;
  //       }
  //       if (section.split('_')[0] === '2') {
  //         sign_tabs_pos[index] = {
  //           "x_position": 106,
  //           "y_position": 559,
  //           "page_number": totalpages2,
  //         }
  //         date_tabs_pos[index] = {
  //           "x_position": 384,
  //           "y_position": 580,
  //           "page_number": totalpages2,
  //         };
  //         initials_tabs_pos.push(...[
  //             {
  //               "page_number": totalpages2 +  - 2,
  //               "x_position": 541,
  //               "y_position": 369
  //             },
  //             {
  //               "page_number": totalpages2 +  - 2,
  //               "x_position": 541,
  //               "y_position": 411
  //             },
  //             {
  //               "page_number": totalpages2 +  - 2,
  //               "x_position": 541,
  //               "y_position": 477
  //             },
  //             {
  //               "page_number": totalpages2 +  - 2,
  //               "x_position": 541,
  //               "y_position": 643
  //             }
  //           ])
  //         // totalpages2 = totalpages2 + 3;
  //       }
  //       if (section.split('_')[0] === '3') {
  //         sign_tabs_pos[index] = {
  //           "x_position": 110,
  //           "y_position": 559,
  //           "page_number": totalpages3 + (+(section.split('_')[1]) * 2),
  //         }
  //         date_tabs_pos[index] = {
  //           "x_position": 384,
  //           "y_position": 580,
  //           "page_number": totalpages3 + (+(section.split('_')[1]) * 2),
  //         }
  //         // totalpages3 = totalpages3 + 2;
  //        }
  //     });
  //     sign_tabs_pos_final.push(sign_tabs_pos);
  //     date_tabs_pos_final.push(date_tabs_pos);
  //     initials_tabs_pos_final.push(initials_tabs_pos);
  //   }
  //   mappingCompiled.action1 = "1919_merger";
  //   mappingCompiled.type = "combine_pdfs";
  //   mappingCompiled.provider = "S10";
  //   // mappingCompiled.callback_url = "https://www.google.com/";
  //   return { sendForMergeMapping: mappingCompiled,  receipents: reciepents, sign_tabs_pos: sign_tabs_pos_final, date_tabs_pos: date_tabs_pos_final, initials_tabs_pos: initials_tabs_pos_final, signerIds};
  // }
}
