import { Pipe, PipeTransform, Inject, LOCALE_ID } from '@angular/core';
import { DatePipe } from '@angular/common';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, takeWhile } from 'rxjs/operators';


@Pipe({
  name: 'dateTimezone'
})
export class DateTimezonePipe extends DatePipe implements PipeTransform {
  private _isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?Z$/;
  default_timezone = 'EDT';
  timezone;
  constructor(private store: Store, @Inject(LOCALE_ID) _locale: string){
    super(_locale);
    this.timezone = this.default_timezone;
    this.store.pipe(select((state:any)=>state?.app),takeWhile((rootState:any) => !rootState?.configurations?.[0]?.settings?.dateConfig, true),distinctUntilChanged()).subscribe(rootState => {
      let dateConfig = rootState?.configurations?.[0]?.settings?.dateConfig;
      if(dateConfig && 'timezone' in dateConfig){
        this.timezone = dateConfig.timezone;
      }else if(dateConfig && 'default_timezone' in dateConfig){
        this.timezone = dateConfig.default_timezone;
      }
      else{
        this.timezone = this.default_timezone;
      }
    });
  }
    transform(value: string | Date, format: string = 'mediumDate') : any {
        let dateArr = (value + '').split('-');
      
        if(!this.isIsoDateString(value) && dateArr.length === 3) {
          return super.transform(value, format)
        }
        
        if(dateArr[0].length < 4) {
          value = dateArr[2]+'-'+dateArr[1]+'-'+dateArr[0];
        }
        return super.transform(value, format, this.timezone);
      }

      isIsoDateString(value: any): boolean {
        if (value === null || value === undefined) {
          return false;
        }
        if (typeof value === 'string'){
          return this._isoDateFormat.test(value);
        }    return false;
      }

}

