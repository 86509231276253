<div class="row d-sm-flex">
    <!-- <div class="col text-right"></div> -->
    
    <div class="col d-sm-flex justify-content-end">
        <div class="d-flex justify-content-end mb-sm-0 mb-3">            
            <span class="fs14 mt-1 mr-4">Showing {{skip + 1}} to {{currentRecords }} of {{total}} entries</span>
            <span class="fs14 mt-1 mr-2">Show</span>
            <ng-select class="form-control pageSelect mr-2" [items]="perPageRecords" [virtualScroll]="true" bindLabel="value" [(ngModel)]="selectLimit" (change)="onPerPageChange($event)" [clearable]=false></ng-select>
            <span class="fs14 mt-1 mr-sm-5">entries</span>
        </div>
        <pagination [totalItems]="total" [itemsPerPage]="limit" [maxSize]="paginationMaxSize" boundaryLinks="true" [(ngModel)]="currentPage" 
                    (pageChanged)="pageChanged($event)" ></pagination>
    </div>
</div>