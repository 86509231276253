import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class PfsAssetsLiablitiesService {

    constructor() { }

    calAssetsLiabilities(data, changeInput = false) {
        if (changeInput && data) {
            let inputData = {};
            data.forEach(ele => {
                inputData = {...ele.formsValue, ...inputData}
            })
            data = inputData;
        }
        let totalValues = {
            cash_total: this.calculatedVal('sum', data?.cash, data?.savings_accounts),
            source_of_income_total: this.arrValSum(data?.source_of_income, 'restricted_amount'),
            contingent_income_total: this.arrValSum(data?.contingent_income, 'contingent_amount')
        };

        let ira_other_retirement_account_amount;
        let accounts_or_notes_receivable_amount;
        let automobiles_amount;
        let other_personal_property_amount;
        let other_assets_amount;

        data?.other_personal_property_assets?.forEach(element => {
            if (element?.assets_property === '60239be6b656c24c74ebd597') {
                ira_other_retirement_account_amount = this.calculatedVal('sum', element.estimated_value, ira_other_retirement_account_amount);
            }
            else if (element?.assets_property === '60239be6b656c24d74ebd598') {
                accounts_or_notes_receivable_amount = this.calculatedVal('sum', element.estimated_value, accounts_or_notes_receivable_amount);
            }
            else if (element?.assets_property === '60239be6b656c24e74ebd599') {
                automobiles_amount = this.calculatedVal('sum', element.estimated_value, automobiles_amount);
            }
            else if (element?.assets_property === '60239be6b656c24f74eed598') {
                other_personal_property_amount = this.calculatedVal('sum', element.estimated_value, other_personal_property_amount);
            }
            else if (element?.assets_property === '60239ee6b656c24f74eed598') {
                other_assets_amount = this.calculatedVal('sum', element.estimated_value, other_assets_amount);
            }
        });

        let pfs_assets: any = {
            cash: data?.cash,
            savings: data?.savings_accounts,
            ira_other_retirement_account: ira_other_retirement_account_amount,
            accounts_or_notes_receivable: accounts_or_notes_receivable_amount,
            automobiles: automobiles_amount,
            other_personal_property: other_personal_property_amount,
            other_assets: other_assets_amount,
            cash_surrender_value: data?.life_insurance?.length ? this.arrValSum(data?.life_insurance, 'cash_surrender_value') : '',
            stocks_and_bonds: data?.stocks_and_bonds?.length ? this.arrValSum(data?.stocks_and_bonds, 'total_value') : '',
            real_estate: data?.real_estate_owned?.length ? this.arrValSum(data?.real_estate_owned, 'present_market_value') : ''
        };
        let pfs_assets_total: any = 0;
        let anyInputValueAssets = [];
        for (const iterator in pfs_assets) {
            let value = pfs_assets[iterator];
            anyInputValueAssets.push(value ? true : false);
            pfs_assets_total = this.calculatedVal('sum', pfs_assets[iterator], pfs_assets_total);
        }
        pfs_assets['pfs_assets_total'] = anyInputValueAssets.some(val => val === true) ? pfs_assets_total : '';
        let installment_account_auto = [];
        let installment_account_other = [];
        data?.installment_account?.forEach(item => {
            item.account_type === '60239be6b655c24c74ebd597' ? installment_account_auto.push(item) : installment_account_other.push(item)
        });
        let pfs_liabilities: any = {
            accounts_payable: data?.account_payable ? this.arrValSum(data?.account_payable, 'amount_outstanding') : '',
            notes_payable_to_banks_and_others: data?.notes_payable ? this.arrValSum(data?.notes_payable, 'current_balance') : '',
            installment_account_auto: installment_account_auto?.length ? this.arrValSum(installment_account_auto, 'current_balance') : '',
            installment_account_auto_monthly_payments: installment_account_auto?.length ? this.arrValSum(installment_account_auto, 'monthly_payment') : '',
            installment_account_other: installment_account_other?.length ? this.arrValSum(installment_account_other, 'current_balance') : '',
            installment_account_other_monthly_payments: installment_account_other?.length ? this.arrValSum(installment_account_other, 'monthly_payment') : '',
            loans_against_life_insurance: data?.loan_against_life_insurance ? this.arrValSum(data?.loan_against_life_insurance, 'amount') : '',
            mortgages_on_real_estate: data?.real_estate_owned ? this.arrValSum(data?.real_estate_owned, 'mortgage_balance') : '',
            unpaid_taxes: data?.unpaid_taxes ? this.arrValSum(data?.unpaid_taxes, 'amount') : '',
            other_liabilities: data?.other_liabilities ? this.arrValSum(data?.other_liabilities, 'amount') : ''
        };
        let total_liabilities: any = 0;
        let anyInputValueLiabilities = [];
        for (const iterator in pfs_liabilities) {
            let value = pfs_liabilities[iterator];
            anyInputValueLiabilities.push(value ? true : false);
            total_liabilities = this.calculatedVal('sum', pfs_liabilities[iterator], total_liabilities);
        }
        // console.log((38194632732.58 )+(7676876874.56));
        pfs_liabilities['total_liabilities'] = anyInputValueLiabilities.some(val => val === true) ? total_liabilities : '';

        if (pfs_assets?.pfs_assets_total || pfs_liabilities?.total_liabilities) {
            pfs_liabilities['net_worth'] = this.calculatedVal('subtract', pfs_assets?.pfs_assets_total, pfs_liabilities?.total_liabilities);
        }
        if (pfs_liabilities?.net_worth || pfs_liabilities?.total_liabilities) {
            pfs_liabilities['total'] = this.calculatedVal('sum', pfs_liabilities?.net_worth, pfs_liabilities?.total_liabilities);
        }
        
        return {
            pfs_liabilities,
            pfs_assets,
            totalValues
        }
    }

    arrValSum(arr, objectKey) {
        let anyInputValue = [];
        let total = 0;
        arr?.forEach(element => {
            let value = element[objectKey];
            total = total + Number(value);
            anyInputValue.push(value ? true : false);
        })
        return anyInputValue.some(val => val === true) ? (Number(total?.toFixed(2)) || null) : '';
    }

    calculatedVal(operation, val1, val2) {
        if(val1 || val2) {
            val1 = (val1 === undefined || val1 === null) ? 0 : Number(val1);
            val2 = (val2 === undefined || val2 === null) ? 0 : Number(val2);
            let result;
            operation === 'sum' ? result = val1 + val2 : '';
            operation === 'subtract' ? result = val1 - val2 : '';
            return (Number(parseFloat(result).toFixed(2)) || null);
        }
        else return '';
    }
}