import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef, TemplateRef, Inject } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DynamicModalActionService } from '../../services/dynamic-modal-action';
// import { KnockoutModalActionService } from '../../services/knockout-modal-action';
@Component({
  selector: 'dynamic-modal',
  templateUrl: './dynamic-modal.component.html'
})
export class DynamicModalComponent implements OnInit {
  modalRef: BsModalRef;
  @Input() notificationMessage = '<p></p>';
  @Input() showOkBtn = true;
  @Input() showCancelBtn = true;
  @Input() showCloseBtn = true;
  @Input() OkBtnName = 'OK';
  @Input() CancelBtnName = 'Cancel';
  @Input() ModalHeaderName = 'Notification'
  @Input() modalBodyClass = '';
  @Input() actionConfig;
  okBtnDisabled = false;
  config;

  @Output() modalClosed$ = new EventEmitter();
  @ViewChild("modalTemplate") modalTemplate: TemplateRef<any>;

  constructor(public modalService: BsModalService,
    // @Inject('cssList') private cssList,
    private modalActionService: DynamicModalActionService
    ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(){
    this.modalRef = this.modalService.show(this.modalTemplate, {
        id: 904,
        class: 'modal-lg dynamicModal',
        backdrop: 'static',
    });
    
    this.modalRef.onHidden.subscribe(data => {
        this.modalClosed$.emit();
    });
  }

  onButtonClick(button_id){
    this.modalActionService.resolveButtonClick(button_id, this.actionConfig);
  }

  closeModal(){
    this.modalRef.hide();
  }

}
