import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldInterface } from '../../../interfaces/FormFieldInterafce';

@Component({
    selector: 'rubicon-editable-div',
    templateUrl: './editable-div.component.html',
    styleUrls: ['./editable-div.component.css']
})
export class EditableDivComponent {
    field: FormFieldInterface;
    group: FormGroup;
    slug: string;
    indexVal: Number;
    constructor() { }

    ngOnInit() {
        this.indexVal = new Date().getTime();
        if(!this.field.validations.find(val => val.name == 'max'))
        this.field.validations.push({
            name: 'max',
            validations: 'max',
            action: ['continue']
        })
    }

    ngAfterViewInit() {
        setTimeout(() => {
            if (this.group.get(this.field.name)) {
                this.group.get(this.field.name).markAsPristine({ onlySelf: true });
            }
        }, 0);
    }
}
