declare global {
    interface Number {
      toRound(scale?:number): string;
    }
}
  
  Number.prototype.toRound = function( scale=0) {
    let num = this;
    let result;
    if(!("" + num).includes("e")) {
      let n:any = num + "e+" + scale;
      result = +(Math.round(n)  + "e-" + scale);
    } else {
      var arr = ("" + num).split("e");
      var sig = ""
      if(+arr[1] + scale > 0) {
        sig = "+";
      }
      let n:any = +arr[0] + "e" + sig + (+arr[1] + scale);
      result = +(Math.round(n) + "e-" + scale);
    }
    return result+"";
  }

  export {};