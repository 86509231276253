import { Component, OnInit, ElementRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { CommonService, addNextTask } from '@rubicon/utils_custom';
import { APP_ROUTES } from '@rubicon/interface/task-info';
import { Store } from '@ngrx/store';

declare let require: any;
@Component({
  selector: 'rubicon-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, AfterViewInit {

  navItems = require('../../../../../../projects/customer-journey/src/assets/json/navbarList.json');
  mainDivClick = false;
  showBar = true;
  APP_ROUTES = APP_ROUTES;

  constructor(private common: CommonService, private changeDetection: ChangeDetectorRef,
    private elemRef: ElementRef, private store: Store<any>) { }

  ngOnInit() { }

  ngAfterViewInit() {
    this.common.menuFlag
      .subscribe((val) => {
        if (!val) {
          this.elemRef.nativeElement.querySelector('.drawerCollapse').classList.add('show');
          document.getElementById('mainDiv').classList.add('active');
          this.showBar = val;
          this.changeDetection.detectChanges();
        } else {
          this.elemRef.nativeElement.querySelector('.drawerCollapse').classList.remove('show');
          document.getElementById('mainDiv').classList.remove('active');
          this.showBar = val;
          this.changeDetection.detectChanges();
        }
      });
  }

  /**
   * function to call on click of menu icon to show/hide side bar
   */
  onMenuClick() {
    if (!this.mainDivClick) {
      this.elemRef.nativeElement.querySelector('.drawerCollapse').classList.add('show');
      document.getElementById('mainDiv').classList.add('active');
      this.mainDivClick = true;
    } else {
      document.getElementById('mainDiv').classList.remove('active');
      this.elemRef.nativeElement.querySelector('.drawerCollapse').classList.remove('show');
      this.mainDivClick = false;
    }
  }

  /**
   * function to dispatch action on navigation
   * @param path 
   */
  navigate(path) {
    this.store.dispatch(addNextTask({ nextTask: path }))
  }

}
