import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ToasterModule } from 'angular2-toaster';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpInterceptorService, LoaderInterceptorService, ErrorHandlerInterceptor, EncryptionInterceptor, BusinessTypeInterceptor } from '@rubicon/utils_custom';
import { NgxMaskModule } from 'ngx-mask';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
  imports: [
    CommonModule,
    ToasterModule.forRoot(),
    NgxMaskModule.forRoot(),
    TooltipModule.forRoot()
  ],
  exports: [
    ToasterModule,
    NgxMaskModule,
    TooltipModule
  ]
})

export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        { provide: HTTP_INTERCEPTORS, useClass: BusinessTypeInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptorService, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: EncryptionInterceptor, multi: true },
        DatePipe
      ]
    }
  }
}
