  
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ssnCommon'
})
export class SsnCommonPipe implements PipeTransform {

  transform(value: any, type?) {
    let data = value;
    if(value&&value.length===9) {
      data = value.slice(0,3)+'-'+value.slice(3,5)+'-'+ (type==='hide' ? 'XXXX': value.slice(5,9));
    }
    return data;
  }

}