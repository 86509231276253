import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SiteLayoutComponent } from './site-layout/site-layout.component';
import { CustomerPortalUiKitModule } from '@rubicon/customer-portal/ui-kit';
import { SiteLayoutRoutingModule } from './site-layout-routing.module';
import { OtherLayoutComponent } from './other-layout/other-layout.component';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';
import { SbaLayoutComponent } from './sba-layout/sba-layout.component';
import { SbaLayoutV2Component } from './sba-layout-v2/sba-layout-v2.component';
import { AffiliateLayoutComponent } from './affiliate-layout/affiliate-layout.component';
import { VerifierLayoutComponent } from './verifier-layout/verifier-layout.component';
@NgModule({
  imports: [CommonModule, SiteLayoutRoutingModule, CustomerPortalUiKitModule],
  declarations: [SiteLayoutComponent, OtherLayoutComponent, CustomLayoutComponent, SbaLayoutComponent, SbaLayoutV2Component, AffiliateLayoutComponent, VerifierLayoutComponent],
  exports: [SiteLayoutComponent, OtherLayoutComponent, CustomLayoutComponent, SbaLayoutComponent, SbaLayoutV2Component, AffiliateLayoutComponent, VerifierLayoutComponent]
})
export class CustomerPortalSiteLayoutModule { }
