import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CommonService } from '@rubicon/utils';
import { FormFieldInterface } from '../../../interfaces/FormFieldInterafce';

@Component({
  selector: 'rubicon-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss']
})
export class FileComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef;
  field: FormFieldInterface;
  group: FormGroup;
  slug: string;
  indexVal: number;
  fileUrl: any;
  iframeElement: HTMLIFrameElement;
  iframeReady = false;
  multipleFiles = false;
  multipleFields = false;
  fileFieldName;
  previousFilesUploadedField;
  constructor(private common: CommonService) { }

  ngOnInit(): void {
    if(this.field?.config?.multipleFiles)
    this.multipleFiles = true;
    if(this.field?.config?.multipleFields){
      this.fileFieldName = `${this.field.name}_file_data`;
      this.previousFilesUploadedField = `${this.field.name}_files`;
      this.group.addControl(this.fileFieldName, new FormControl(null));
      this.multipleFields = true;
    }
    this.group.addControl('file_data',new FormControl(null));
  }

  onSelectFile($event) {
    if($event){
      if(this.field?.config?.allowed_file_type && this.field?.config?.allowed_file_type?.length && !this.validFileFormat($event)) return;
      let field_name = 'file_data';
      if(this.multipleFields) field_name = this.fileFieldName;
      const uploadedFiles=[];
      if(this.multipleFiles){
        if(!this.group.get(field_name)?.value)
          Array.prototype.push.apply( uploadedFiles, $event.target.files );
        else {
          Array.prototype.push.apply( uploadedFiles, this.group.get(field_name)?.value );
          Array.prototype.push.apply( uploadedFiles, $event.target.files );
        }
        this.group.get(field_name).setValue(uploadedFiles);
      }
      else {
        Array.prototype.push.apply( uploadedFiles, $event.target.files );
        this.group.get('file_data').setValue(uploadedFiles);
      }
      if ($event.target.files && $event.target.files[0]) {
        const reader = new FileReader();
        reader.onload = (event: ProgressEvent) => {
          this.fileUrl = (<FileReader>event.target).result;
          this.iframeReady  = this.field.config?.preview ? true : false;
          this.iframeElement = document.querySelector('#filePreview');
        }
        reader.readAsDataURL($event.target.files[0]);
      }
    }
  }

  showPreview() {
    this.iframeElement.classList.remove('d-none');
    this.iframeElement.src = this.fileUrl;
  }

  validFileFormat($event){
    const files = $event.target.files;
    for(let  i= 0; i < files?.length; i++){
      const extension = files[i]?.name?.split('.')[files[i].name?.split('.')?.length - 1];
      if(this.field?.config?.allowed_file_type?.indexOf(extension) < 0){
        if(!this.multipleFields){
          this.group.get(this.field.name)?.setValue('');
          this.group.get('file_data')?.setValue([]);
        }
        this.common.popToast('error', 'Error', `Invalid File Format.
        Allowed file ${this.field?.config?.allowed_file_type?.length==1? 'format':'formats'} -
        ${this.field?.config?.allowed_file_type?.join(', ')}`);
        return false;
      }
    }
    return true;
  }

  onDelete(index){
    if(this.multipleFields){
      const uploadedFiles=this.group.get(this.fileFieldName).value;
      uploadedFiles.splice(index,1);
      this.group.get(this.fileFieldName).setValue(uploadedFiles);
      this.group.get(this.field.name).setValue('');
    }
    else{
      const uploadedFiles=this.group.get('file_data').value;
      uploadedFiles.splice(index,1);
      this.group.get('file_data').setValue(uploadedFiles);
      this.group.get(this.field.name)?.setValue('');
      this.group?.get('file')?.setValue('');
    }
    this.fileInput.nativeElement.value='';
    this.clearIframe();
  }

  clearIframe() {
    this.iframeReady = false;
    this.iframeElement?.classList?.add('d-none');
    if(this.iframeElement?.src) {
      this.iframeElement.src = '';
    }
  }

}
