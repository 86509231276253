import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldInterface } from '@rubicon/interface/form-field';

@Component({
  selector: 'rubicon-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss']
})
export class SwitchComponent implements OnInit{
  @ViewChild('infoTip') infoTip: ElementRef;

  field: FormFieldInterface;
  group: FormGroup;
  slug: string;
  indexVal: Number;
  constructor() { }

  ngOnInit() {
    this.indexVal=new Date().getTime();
  }

}
