import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicComponent } from './components/dynamic/dynamic.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { UtilsModule } from '@rubicon/utils';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { FormService } from './services/form.service';
import { GoogleAddressService } from './services/google-address.service';


// Directives
import { FieldDirective } from './directives/field.directive';

// Component
import {
  TextComponent, NumberComponent, PasswordComponent, RadioComponent,
  SliderComponent, CheckboxComponent, SwitchComponent, TelComponent, DateComponent,
  SelectComponent, GroupComponent, TextareaComponent, FileComponent, AmountComponent,
  LabelComponent, ButtonComponent
} from './components/form-fields/index';

import { Ng5SliderModule } from 'ng5-slider';
import { NgxMaskModule } from 'ngx-mask';
import { NgSelectModule } from '@ng-select/ng-select';
import { EditableDivComponent } from './components/form-fields/editable-div/editable-div.component';
import { ContentEditableFormDirective } from './directives/content-editable-form.directive';
@NgModule({
  imports: [
    CommonModule,
    Ng5SliderModule,
    TooltipModule.forRoot(),
    UtilsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    BsDatepickerModule.forRoot(),
    NgxMaskModule,
    NgSelectModule
  ],
  declarations: [
    DynamicComponent,
    FieldDirective,
    TextComponent,
    DateComponent,
    NumberComponent,
    SwitchComponent,
    TelComponent,
    TextareaComponent,
    PasswordComponent,
    FileComponent,
    GroupComponent,
    CheckboxComponent,
    RadioComponent,
    SliderComponent,
    SelectComponent,
    AmountComponent,
    LabelComponent,
    ButtonComponent,
    EditableDivComponent,
    ContentEditableFormDirective
  ],
  exports: [
    Ng5SliderModule,
    DynamicComponent,
    FieldDirective,
    TextComponent,
    DateComponent,
    NumberComponent,
    SwitchComponent,
    TelComponent,
    TextareaComponent,
    PasswordComponent,
    FileComponent,
    GroupComponent,
    CheckboxComponent,
    RadioComponent,
    SliderComponent,
    SelectComponent,
    AmountComponent,
    LabelComponent,
    ButtonComponent,
    EditableDivComponent,
    ContentEditableFormDirective
  ],
  providers:[FormService, GoogleAddressService]
})
export class DynamicFormModule {}
