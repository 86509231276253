import { Component, OnInit, EventEmitter } from '@angular/core';
import { FormGroup , Validators} from '@angular/forms';
import { FormFieldInterface } from '@rubicon/interface/form-field';
import { Options } from 'ng5-slider';
import { CommonService } from '@rubicon/utils_custom';
import { CUSTOMER_PORTAL } from '@rubicon/interface/task-info';
@Component({
  selector: 'rubicon-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {

  field: FormFieldInterface;
  group: FormGroup;
  slug: string;
  sliderManualRefresh: EventEmitter<void> = new EventEmitter<void>();
  newOptions: Options;
  value: number;
  index: number;

  constructor(private common: CommonService) { }

  ngOnInit() {
    this.field.slider = {}
    this.sliderConfig();
    if (this.slug === 'applicant-details-ppp2' && (this.group.get('business_structure_type').value==""|| this.group.get('business_structure_type').value==null)) {
      this.group.get('is_corporate_entity').valueChanges.subscribe((value) => {
        if (value) {
          this.field.slider.min = 1
        } else {
          this.field.slider.min = 0
        }
        this.group.get('ownership').setValidators([Validators.required,Validators.min(this.field.slider.min),Validators.max(this.field.slider.max)])
        this.newOptions = this.createOptions();
        if (!this.group.get(this.field.name).value) {
          this.value = this.field.slider.min;
          this.group.get(this.field.name).patchValue(this.field.slider.min);
        } else {
          this.value = this.group.get(this.field.name).value;
        }
      })
    }
  }

  sliderConfig() {
    this.common.masterData
      .subscribe((data: any) => {
        if (data && data[this.field.name]) {
          this.field.slider = data[this.field.name];
        } else {
          this.field.validations.forEach((validation) => {
            switch (validation.name) {
              case 'min':
                this.field.slider.min = validation.validations;
                break;
              case 'max':
                this.field.slider.max = validation.validations;
                break;
              case 'step':
                this.field.slider.step = validation.validations;
                break;
            }
          });
        }
        if (this.slug === 'applicant-details-ppp2' && (this.group.get('business_structure_type').value==""|| this.group.get('business_structure_type').value==null)) {
          if (this.group.get('is_corporate_entity').value) {
            this.field.slider.min = 1
          } else {
            this.field.slider.min = 0
          }
          this.group.get('ownership').setValidators([Validators.required,Validators.min(this.field.slider.min),Validators.max(this.field.slider.max)])
        }
        this.newOptions = this.createOptions();
        if (!this.group.get(this.field.name).value) {
          this.value = this.field.slider.min;
          this.group.get(this.field.name).patchValue(this.field.slider.min);
        } else {
          this.value = this.group.get(this.field.name).value;
        }
      });
  }

  /**
   * function to create slider options
   */
  createOptions() {
    //to disable slider on selected slugs
    let disable_slugs = [CUSTOMER_PORTAL.owner_consent_config]
    let slider_disable = false;

    if(this.slug === CUSTOMER_PORTAL.owner_details && this.group.disabled){
      slider_disable = true;
    }

    if(disable_slugs.includes(this.slug))  slider_disable = true;

    return {
      floor: this.field.slider.min,
      ceil: this.field.slider.max,
      step: this.field.slider.step,
      showTicksValues: true,
      disabled: slider_disable,
      translate: (value: number): string => {
        if (this.field.type === 'range_amount')
          return '$ ' + (+value).toLocaleString();
        if (this.field.type === 'range_percentage')
          return value+'%';
        if (this.field.type === 'range')
          if (value > 1)
            return value + ' Years';
        return value + 'Year';
      },
      ticksArray: [
        this.field.slider.min,
        this.field.slider.max
      ],
      getLegend: (value: number): string => {
        if (this.field.type === 'range_amount')
          return '$' + value / 1000 + 'K';
        else if (this.field.type === 'range_percentage') {
          return value + ' %';
        } else {
          if (value > 1)
            return value + ' Years';
          else
            return value + ' Year';
        }
      }
    };
  }

  manualChangeAmount(event: any) {
    event = (event + '').replace(/,/g, "");
    event = parseInt(event, 10);

    if (isNaN(event)) {
      event = this.field.slider.min;
    }
    if (event > this.field.slider.max) {
      this.group.controls[this.field.name].setErrors({ 'incorrect': true });
      return this.field.slider.max;
    }
    if (event < this.field.slider.min) {
      this.group.controls[this.field.name].setErrors({ 'incorrect': true });
      return this.field.slider.min;
    }
    return event;
  }

  storeAmount(value) {
    this.group.get(this.field.name).patchValue(value);
  }

}
