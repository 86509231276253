import { Inject, Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { distinctUntilChanged, map, take, switchMap } from "rxjs/operators";
import * as _ from 'lodash';
import { addAppDetails, CommonService, TaskInfoService } from "@rubicon/utils";
import { Subject, of, BehaviorSubject } from "rxjs";
import { conditionHelperService } from "./conditions.helper";
import { ActivatedRouteSnapshot, Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class MultiProductService {
  product_types = {};
  product_application_tabs = [];
  all_tab_items;
  app_detail_tab_items$:BehaviorSubject<any> = new BehaviorSubject([]);
  product_tab_items$:BehaviorSubject<any> = new BehaviorSubject([]);
  app_detail_progress_items$:BehaviorSubject<any> = new BehaviorSubject([]);
  all_progress_steps;
  incomplete_products;
  progress_steps;
  product_config;
  step_not_required;
  sba_initiated_by;
  product_types_master;
  app_store_data : any;
  appId;
  openApplication$ = new Subject();
  constructor(private store: Store<any>,
    private taskInfoService: TaskInfoService,
    private commonService: CommonService,
    private _router: Router,
    @Inject('environment') public environment,
    @Inject('CONSTANTS') public CONSTANTS,
    @Inject('APP_ROUTES') public APP_ROUTES
    ) {
    this.store.pipe(
      select('app'),
      distinctUntilChanged((prev, next) => {
        this.appId = next?.appID;
        this.product_config = next?.configurations?.[0]?.products.find(res => res?.name === this.environment.products_enabled[0]);
        if (['banker-journey', 'broker-journey'].includes(this.environment.journeyType)) {
          this.step_not_required = next?.appData?.step_not_required;
          this.sba_initiated_by = next?.appData?.sba_initiated_by;
        } else {
          this.step_not_required = next?.userData?.step_not_required;
        }
        let prevProductTypes = prev?.appData?.product_types;
        let nextProductTypes = next?.appData?.product_types;
        if (prevProductTypes && nextProductTypes) {
          return _.isEqual(prevProductTypes, nextProductTypes);
        }
        return false;
      })
    ).subscribe((storeData) => {
      this.app_store_data = storeData;
      this.appId = storeData?.appID;
      this.product_types = storeData?.appData?.product_types || {};
      this.product_application_tabs = storeData?.appData?.product_application_tabs || [];
      this.incomplete_products = storeData?.appData?.incomplete_products || null;
      let productsConfig = storeData?.configurations?.[0]?.products;
      let multi_product, productConfig;
      if (productsConfig) {
        productConfig = productsConfig.find(res => res?.name === this.environment.products_enabled[0]);
        multi_product = productConfig?.multi_product;
      }
      this.getApplicationProgressItems().subscribe();
      if ((multi_product?.dynamic_app_tabs || productConfig?.dynamic_app_tabs) && storeData?.appData?.product_types && ['banker-journey', 'broker-journey'].includes(this.environment.journeyType)){
        this.getTabItems(this.appId, "app_detail_tab").subscribe();
      }
    });

    
    this.store.select('app').pipe(
      map(rootState=>{
        return rootState?.appData?.active_product_code_tab;
      }),
      distinctUntilChanged()
    ).subscribe((active_product_code_tab)=>{
      if(active_product_code_tab){
        let active_product = this.product_types?.[active_product_code_tab];
        if(active_product){
          this.getTabItems(this.appId, 'app_detail_tab', {[active_product_code_tab]: active_product},{skip_emit_tabs: true, emit_product_tabs: true, active_product_code_tab}).subscribe();
        }else{
          this.product_tab_items$.next({[this.appId]: []});
        }
      }else{
        this.product_tab_items$.next({[this.appId]: []});
      }
    });
  }

  setProductTypesMaster(product_types_master){
    this.product_types_master = product_types_master;
  }

  getProductTypesMaster(){
    return this.product_types_master;
  }

  getApplicationProgressItems(store_product_types?) {
    let all_progress_steps$;
    if (this.all_progress_steps) {
      all_progress_steps$ = of(this.all_progress_steps);
    } else {
      all_progress_steps$ = this.taskInfoService.getTaskInfo({ slug: 'application-progress' }).pipe(
        map(response => {
          if (response.response_data.progress_list.data.data?.length) {
            this.all_progress_steps = response.response_data.progress_list.data.data;
            return this.all_progress_steps;
          }
          throw response;
        }));
    }
      all_progress_steps$ = all_progress_steps$.pipe(map((all_progress_steps: any[]) => {
        // if (!this.step_not_required || ['banker', 'broker'].includes(this.sba_initiated_by)) {
        //   this.progress_steps = all_progress_steps.filter(val => val.display === 1)
        // } else if (this.step_not_required.length > 0) {
        //   this.progress_steps = all_progress_steps.filter(val => !this.step_not_required.includes(val.name))
        // }
        let product_types = this.incomplete_products?.length ? this.incomplete_products : Object.values(store_product_types || this.product_types).filter((item:any)=>item.type).map((item: any)=>{
          return item.type;
        });
        let product_codes = this.incomplete_products?.length ? this.incomplete_products : Object.values(store_product_types || this.product_types).filter((item:any)=>item.product_code).map((item: any)=>{
          return item.product_code;
        });
          this.progress_steps = all_progress_steps.filter(stepItem => {
            let filterIn = false;
            let hidden = false;
            // let currentStepConfig = this.product_config?.customer?.applicationSteps?.[stepItem.type];
            // let journey_type = this.product_types ? (Object.keys(this.product_types)?.[0] || null) : null;
            // if(currentStepConfig?.journey_types?.[journey_type]){
            //   currentStepConfig = {...currentStepConfig, ...currentStepConfig?.journey_types?.[journey_type]};
            // }
            if(stepItem.display != "1"){
              hidden = true;
            }
            if(stepItem.type == "createProfile" && this.appId){
              filterIn = false;
            }
            else if(this.step_not_required?.length > 0 && this.step_not_required?.includes(stepItem.name)){
              filterIn = false;
            }
            else if (stepItem?.filter_conditions?.length) {
              filterIn = conditionHelperService.resolve({
                product_types: ['*',...product_types],
                product_codes: ['*',...product_codes],
                app_store_data : _.cloneDeep(this.app_store_data)
              }, stepItem.filter_conditions).resolved;
              //  stepItem.list.includes(null) || ((this.product_types && Object.keys(this.product_types)?.length) ? stepItem.list.some(type => this.product_types?.[type]):false);
            } else {
              filterIn = true;
            }
            // if(currentStepConfig?.visible_till_step){
            //   let till_step_order = this.getStepOrder(currentStepConfig?.visible_till_step);
            //   if(this.step > till_step_order){
            //     isVisible = false;
            //   }
            // }
            stepItem.hidden = filterIn ? hidden: false;
            return filterIn;
          });

        //check for same progress item in list
        this.progress_steps = _.cloneDeep(this.progress_steps);
        let sameListStepMap = {};
        for(let step of this.progress_steps){
          if(!step.hidden && step?.list?.length > 0){
            let uniqueName = step.list[0];
            if(!sameListStepMap[uniqueName]){
              sameListStepMap[uniqueName] = step;
              step.name = uniqueName;
            }else{
              step.hidden = true;
            }
          }
        }
        this.app_detail_progress_items$.next({[this.appId]:this.progress_steps});
        return this.progress_steps;
      }));
      return all_progress_steps$;
  }


  getTabItems(app_id, type, store_product_types?, config?){
    let all_tab_items$;
    if (this.all_tab_items) {
      all_tab_items$ = of(this.all_tab_items);
    } else {
      all_tab_items$ = this.taskInfoService.getTaskInfo({ slug: 'master_data_optimised', model: 'tab_item' , journeyType: this.environment.journeyType}).pipe(
        map(response => {
          if (response.response_data.master_data_optimised.data.data.tab_item?.length) {
            this.all_tab_items = response.response_data.master_data_optimised.data.data.tab_item;
            return this.all_tab_items;
          }
          throw response;
        }));
    }
    all_tab_items$ = all_tab_items$.pipe(map((_all_tab_items: any[]) => {
        let product_types = Object.values(store_product_types || this.product_types).filter((item:any)=>item.type).map((item: any)=>{
          return item.type;
        });
        let product_codes = Object.values(store_product_types || this.product_types).filter((item:any)=>item.product_code).map((item: any)=>{
          return item.product_code;
        });
        let all_tab_items = _.cloneDeep(_all_tab_items);
        let tab_items = all_tab_items.filter(tab_item => {
            tab_item.child_tabs?.forEach(child_tab_item => {
              this.filterRequiredItem(child_tab_item, product_types, product_codes);
            })
            let isFiltered = false;
            if(config?.emit_product_tabs){
              isFiltered = this.filterProductTabItem(tab_item, product_types, product_codes);
            }else{
              isFiltered = this.filterTabItem(tab_item, product_types, product_codes)
            }
            return tab_item.type == type && isFiltered;
          }).map((tab_item)=>{
            if(tab_item?.child_tabs?.length){
              tab_item.child_tabs = tab_item.child_tabs.filter(child_tab_item=>{
                return this.filterTabItem(child_tab_item, product_types, product_codes);
              });
              return tab_item;
            }else{
              return tab_item;
            }
          })
      let first_ddl_tab_index = -1;
      for (let i = 0; i < tab_items.length; i++) {
        if (tab_items[i]?.config?.is_ddl_tab) {
          first_ddl_tab_index = i;
          break;
        }
      }
      if(first_ddl_tab_index==-1){
        first_ddl_tab_index = tab_items?.length;
      }
          if(this.product_application_tabs?.length){
            let product_dynamic_tabs = [];
            for(let product_master of this.product_application_tabs){
              product_dynamic_tabs.push({
                "name": "product_dynamic_tab",
                "type": "app_detail_tab",
                "nav_type": "product_dynamic_tab",
                "product_code": product_master?.code,
                "value": product_master?.config?.application_tab?.label || product_master?.value,
                "route": "product_dynamic_tab",
                "display": "1"
              });
            }
            tab_items.splice(first_ddl_tab_index, 0, ...product_dynamic_tabs);
          }
          if(!config?.skip_emit_tabs){
            switch(type){
              case 'app_detail_tab':
                this.app_detail_tab_items$.next({[app_id]: tab_items});
                break;
            }
          }else{
            if(config?.emit_product_tabs){
              let application_tabs_for_active_product = tab_items?.filter((item)=>item.display && item?.nav_type != "product_dynamic_tab").map(app_tab=>{
                if(app_tab.child_tabs?.length){
                  app_tab.child_tabs = app_tab.child_tabs.map(child_tab=>{
                    if(!child_tab.config){
                      child_tab.config = {};
                    }
                    child_tab.config.router_link = this.getRouteLink(app_tab, child_tab);
                    return child_tab;
                  });
                }
                if(!app_tab.config){
                  app_tab.config = {};
                }
                app_tab.config.router_link = this.getRouteLink(app_tab);
                return app_tab;
              });
              this.product_tab_items$.next({[app_id]: {[config.active_product_code_tab]:application_tabs_for_active_product}});
            }
          }
        return tab_items;
      }));
      return all_tab_items$;
  }


  getRouteLink(app_tab_item, child_tab?){
    let child_tab_paths = child_tab?.config?.path?.split("/") || [];
    let parent_tab_path = app_tab_item.value.toLowerCase().replace(/  +/g, '_')
    return [this.APP_ROUTES['product_dynamic_tab'], parent_tab_path, ...child_tab_paths].join("/");
  }

  getParentTabType(route: ActivatedRouteSnapshot){
    let routeParent = route.parent;
    if(routeParent?.data?.tab_type){
      return routeParent?.data?.tab_type;
    }
    else if(routeParent?.parent){
      return this.getParentTabType(routeParent);
    }else{
      return null;
    }
  }

  getNavTypeTab(nav_type, tab_items){
    if(tab_items?.length){
      return tab_items.find(tab_item=>tab_item.nav_type==nav_type);
    }else{
      return null;
    }
  }

  getNavTypeChildTabs(nav_type, tab_items){
    return this.getNavTypeTab(nav_type, tab_items)?.child_tabs || [];
  }

  filterTabItem(tab_item, product_types, product_codes) {
    let filterIn = false;
    if (tab_item?.filter_conditions?.length) {
      filterIn = conditionHelperService.resolve({
        product_types: ['*', ...product_types],
        product_codes: ['*', ...product_codes],
        journeyType: this.environment.journeyType,
        app_store_data: _.cloneDeep(this.app_store_data)
      }, tab_item.filter_conditions).resolved;
    } else {
      filterIn = true;
    }
    return filterIn;
  }

  filterProductTabItem(tab_item, product_types, product_codes) {
    let filterIn = false;
    if (tab_item?.config?.product_tab?.filter_conditions?.length) {
      filterIn = conditionHelperService.resolve({
        product_types: ['*', ...product_types],
        product_codes: ['*', ...product_codes],
        app_store_data: _.cloneDeep(this.app_store_data)
      }, tab_item?.config?.product_tab?.filter_conditions).resolved;
    }
    return filterIn;
  }

  filterRequiredItem(tab_item, product_types, product_codes) {
    let filterIn = false;
    if (tab_item?.config?.required_conditions?.length) {
      filterIn = conditionHelperService.resolve({
        product_types: ['*', ...product_types],
        product_codes: ['*', ...product_codes],
        app_store_data: _.cloneDeep(this.app_store_data)
      }, tab_item?.config?.required_conditions).resolved;
    }
    if(tab_item.config){
      tab_item.config.required = filterIn;
    }
  }

  getFirstStep(){
    return this.progress_steps?.[0];
  }

  getNextStep(currentStep){
    let nextStep, currentStepFound = false;
    for(let progress_step of this.progress_steps){
      if(currentStepFound){
        nextStep = progress_step.type;
        break;
      }
      if(progress_step.type == currentStep){
        currentStepFound = true;
      }
    }
    return nextStep;
  }

  getNextStepRoute(current_step, productConfig){
      let nextStep = this.getNextStep(current_step);
      let next_route = productConfig?.customer?.applicationSteps?.[nextStep]?.redirects?.currentRoute;
      return next_route;
  }

  getMiddlewareRoute(current_step, productConfig){
    return productConfig?.customer?.applicationSteps?.[current_step]?.redirects?.middlewareRoute;
  }
  getCurrentStepRoute(current_step, productConfig){
    return productConfig?.customer?.applicationSteps?.[current_step]?.redirects?.currentRoute;
}

  getFirstStepRoute(productConfig){
    let nextStep = this.progress_steps?.[0]?.type;
    let next_route = productConfig?.customer?.applicationSteps?.[nextStep]?.redirects?.currentRoute;
    return next_route;
  }

  getMiddlewareConfig(current_step, productConfig){
    return productConfig?.customer?.applicationSteps?.[current_step]?.middlewareConfig;
  }

  navigateToNextStep(action, referrer_next_task, product_config, current_step, business_id?, data?){
    setTimeout(()=>{
      let dashboardRoute = 'dashboard';
      if(this.environment.journeyType == "customer-journey"){
        dashboardRoute = "dashboard";
      }else{
        dashboardRoute = 'banker-dashboard';
      }
      let next_route = action == "continue"?(this.getNextStepRoute(current_step, product_config) || dashboardRoute): (referrer_next_task || dashboardRoute);
      let middlewareRoute = action == "continue"? next_route ? this.getMiddlewareRoute(current_step, product_config) : '' : null;
      if(middlewareRoute){
        let current_route = this.getCurrentStepRoute(current_step, product_config);
        if(data?.is_next_step_current){
          next_route = current_route;
        }else if(data?.next_route){
          next_route = data?.next_route
        }
        this._router.navigate([this.CONSTANTS.APP_ROUTES[middlewareRoute]],{state: {routes : {prev : current_route, next : next_route, business_id , config : current_step, data}}});
      } else if(business_id && (action == "continue" && next_route!=dashboardRoute)){
          this.commonService.navigate(next_route, business_id);
      }else if(data?.is_next_step_current){
        // console.log('inside next step');
        return;
      }else{
        this.commonService.navigate(data?.next_route || next_route);
      }
    }, 300);
  }

  getPrevStep(currentStep){
    let prevStep, currentStepFound = false;
    if(this.progress_steps?.length){
      for(let i=this.progress_steps.length-1; i>=0; i--){
        let progress_step = this.progress_steps[i];
        if(currentStepFound){
          prevStep = progress_step.type;
          break;
        }
        if(progress_step.type == currentStep){
          currentStepFound = true;
        }
      }
    }
    return prevStep;
  }

  getStepOrder(currentStep){
    let current_progress_step;
    let prev_step;
    if(this.progress_steps?.length){
      for(let progress_step of this.progress_steps){
        if(progress_step.type == currentStep){
          current_progress_step = progress_step;
          break;
        }
        if(!progress_step.hidden){
          prev_step = progress_step;
        }
      }
    }
    return (current_progress_step?.hidden)? (prev_step?.step):(current_progress_step?.step);
  }


  buildStoreProducts(all_saved_products) {
    let storeProductType = {};
    for (let product of all_saved_products) {
      if (product.product_code) {
        storeProductType[product.product_code] = product;
      } else if (product.type) {
        storeProductType[product.type] = product;
      }
    }
    return storeProductType;
  }

  getEnabledProductApplicationTabs(product_types_store, product_types_master){
    let product_type_map = {};
    let product_application_tabs = [];
    for(let product of product_types_master){
      product_type_map[product.code] = product;
    }
    for(let product_code in product_types_store){
      if(product_type_map[product_code]?.config?.application_tab?.enabled){
        product_application_tabs.push(product_type_map[product_code]);
      }
    }
    return product_application_tabs;
  }

  buildSavedProductMap(productsSaved) {
    let savedProductMap = {};
    for (let product of productsSaved) {
      if (!savedProductMap[product.type]) {
        savedProductMap[product.type] = {};
      }
      if (product.product_code) {
        savedProductMap[product.type][product.product_code] = product;
      } else if (product.type) {
        savedProductMap[product.type][product.type] = product;
      }
    }
    return savedProductMap;
  }

  buildProductCards(product_types_master) {
    let temp_product_cards = [];
    let productKeyCardMap = {};
    let productTypeGuidanceMap = {};
    for (let product_type of product_types_master) {

      if (product_type?.config?.product_selection) {
        if (product_type?.config?.product_guidance) {
          if (!productTypeGuidanceMap[product_type.type]) {
            productTypeGuidanceMap[product_type.type] = [];
            productKeyCardMap[product_type.type] = {
              type: product_type.type,
              guidance: true
            }
          }
          productTypeGuidanceMap[product_type.type].push(product_type.code);
        }
        temp_product_cards.push({
          _id: product_type.id,
          type: product_type.type,
          product_code: product_type.code,
          product_guidance: product_type?.config?.product_guidance
        });
      } else if (product_type?.config?.product_type_selection) {
        temp_product_cards.push({
          type: product_type.type
        });
      }

    }

    //delete duplicate type
    let uniqueProductType = {};
    let product_cards = [];
    for (let product_card of temp_product_cards) {
      if (product_card.product_code) {
        let _card = { ...product_card, product_key: product_card.product_code };
        productKeyCardMap[_card.product_key] = _card;
        product_cards.push(_card);
        //not checking for product_code
        continue;
      }
      if (!uniqueProductType[product_card.type]) {
        //picking only one type
        let product = { ...product_card, product_key: product_card.type };
        uniqueProductType[product_card.type] = product;
        productKeyCardMap[product_card.type] = product;
        product_cards.push(product);
      }
    }
    return { product_cards, productKeyCardMap, productTypeGuidanceMap };
    // return product_cards;
  }

  getProductsPayload(data: any) {
    let {appId, product_cards, savedProductMap, productTypeGuidanceMap, formValue, product_status} = data;
    let incompleteStatus = product_status.find(response => response.type == 'incomplete');
    let deleteProductsMap = {};
    let newProducts = [];
    let productsSelected = formValue.products;
    let guidanceProductsSelected = formValue.products_guidance;

    for (let product_type_card of product_cards) {
      let savedProduct = savedProductMap[product_type_card.type]?.[product_type_card.product_key];
      // console.log(savedProduct);
      if (savedProduct?.guided) {
        savedProduct = null;
      }
      if (!savedProduct) {
        if (product_type_card.type && !product_type_card.product_code && savedProductMap[product_type_card.type]) {
          //check if any sub product is saved
          savedProduct = savedProductMap[product_type_card.type][Object.keys(savedProductMap[product_type_card.type])[0]];
        }
      }

      if (product_type_card.product_code) {
        if (productsSelected.includes(product_type_card.product_key)) {
          //checked
          if (!savedProduct) {
            newProducts.push({ type: product_type_card.type, product_code: product_type_card.product_code, product_id: product_type_card._id, product_status_id : incompleteStatus?.id});
          }
          if (product_type_card.product_guidance) {
            //check if it't guide product type is in saved products
            if (savedProductMap[product_type_card.type]?.[product_type_card.type]) {
              //delete product type guidance if now product is selected
              let _id = appId ? savedProductMap[product_type_card.type]?.[product_type_card.type]?._id : this.getUniqueHashCode(savedProductMap[product_type_card.type]?.[product_type_card.type]);
              deleteProductsMap[_id] = savedProductMap[product_type_card.type]?.[product_type_card.type];
            }
          }
        } else {
          //unchecked
          if (savedProduct) {
            //delete product
            let _id = appId ? savedProduct?._id: (this.getUniqueHashCode(savedProduct));
            deleteProductsMap[_id] = savedProduct;
          }
        }
      } else if (product_type_card.type) {
        if (productsSelected.includes(product_type_card.product_key)) {
          //checked
          if (!savedProduct) {
            newProducts.push({ type: product_type_card.type });
          }
        } else {
          //unchecked
          if (savedProduct && savedProductMap[product_type_card.type] && Object.keys(savedProductMap[product_type_card.type]).length > 0) {
            for (let key in savedProductMap[product_type_card.type]) {
              let _id = appId ? (savedProductMap[product_type_card.type][key]?._id) : this.getUniqueHashCode(savedProductMap[product_type_card.type][key]);
              deleteProductsMap[_id] = savedProductMap[product_type_card.type][key];
            }
          }
        }
      }
    }

    for (let type in productTypeGuidanceMap) {
      let guide_product_codes = productTypeGuidanceMap[type];
      let savedProduct = savedProductMap[type]?.[type];
      if (guidanceProductsSelected.includes(type)) {
        //checked
        if (!savedProduct) {
          newProducts.push({ type: type, guidance: true });
        }
        //delete sub products but not guided
        for (let product_code of guide_product_codes) {
          if (savedProductMap[type]?.[product_code] && !savedProductMap[type]?.[product_code]?.guided) {
            let _id = appId ? (savedProductMap[type]?.[product_code]?._id): this.getUniqueHashCode(savedProductMap[type]?.[product_code]);
            deleteProductsMap[_id] = savedProductMap[type]?.[product_code];
          }
        }
      } else {
        //unchecked
        if (savedProduct) {
          let _id = appId? (savedProduct?._id): this.getUniqueHashCode(savedProduct);
          deleteProductsMap[_id] = savedProduct;
        }
        //delete sub products guided
        for (let product_code of guide_product_codes) {
          if (savedProductMap[type]?.[product_code] && savedProductMap[type]?.[product_code]?.guided) {
            let _id = appId? (savedProductMap[type]?.[product_code]?._id) : this.getUniqueHashCode(savedProductMap[type]?.[product_code]);
            deleteProductsMap[_id] = savedProductMap[type]?.[product_code];
          }
        }
      }
    }

    //remove duplicate delete products
    let deleteProducts = [];
    for (let _id in deleteProductsMap) {
      deleteProducts.push(deleteProductsMap[_id]);
    }
    return {
      newProducts, deleteProducts
    }
  }

  getUniqueHashCode(product){
    let code = [product?.type || 1, product?.product_code || 1, product?.sub_product_code || 1, (product?.guided ? true: false)].join("#");
    return code;
  }

  
  isValidNavTypeItem(nav_type, tab_items) {
    let nav_type_paths = nav_type.split("::");
    let current_tab_items = tab_items;
    let isValid = true;
    let redirect_default_tab_item = current_tab_items?.find(tab_item=>!tab_item?.config?.skip_default_redirect);
    for (let i = 0; i < nav_type_paths.length; i++) {
      let current_nav_type = nav_type_paths[i];
      let nav_type_item = this.getNavTypeTab(current_nav_type, current_tab_items);
      if (nav_type_item) {
        current_tab_items = nav_type_item?.child_tabs || [];
        if ((nav_type_paths.length - 1) != i && nav_type_item?.child_tabs?.length) {
          redirect_default_tab_item = current_tab_items?.find(tab_item=>!tab_item?.config?.skip_default_redirect);
        }
      } else {
        isValid = false;
      }
      if (!isValid) {
        break;
      }
    }
    return { isValid, redirect_default_tab_item };
  }

  isValidProgressStep(type, progress_steps){
    return (progress_steps || []).find(item=>item?.type == type) ? true: false;
  }

  totalRequestedAmount(businessData): string {
    if (businessData?.total_loan_purpose_amount) return businessData?.total_loan_purpose_amount.toString();
    const credReqs = businessData?.credit_request;
    let totalAmount = 0;
    if (typeof credReqs === 'object' && Object.keys(credReqs).length) {
      Object.values(credReqs).forEach((cred: any) => {
        if (cred?.requested_amount) {
          totalAmount += 1*cred?.requested_amount;
        }
      }) 
    }
    return totalAmount ? totalAmount.toString() : '';
  }

  getUniqueProductTypes(product_types_master){
    let product_types_set = new Set();
    let unique_product_types_master = [];
    for(let product of product_types_master){
      if(product?.type && !product_types_set.has(product?.type) && (product?.config?.product_selection || product?.config?.product_type_selection)){
        product_types_set.add(product.type);
        unique_product_types_master.push({type: product?.type});
      }
    }
    return unique_product_types_master;
  }

  getUniqueProductCodes(product_types_master){
    let product_codes_set = new Set();
    let unique_product_codes_master = [];
    for(let product of product_types_master){
      if(product?.code && !product_codes_set.has(product?.code) && (product?.config?.product_selection || product?.config?.product_type_selection)){
        product_codes_set.add(product.code);
        unique_product_codes_master.push({value: product?.code, name: product?.value});
      }
    }
    return unique_product_codes_master;
  }

  isStepInJourney(step_type, products){
    let appStep = this.all_progress_steps?.find(step=>step?.type == step_type);
    let isValidStep = true;
    if(appStep && products?.length){
      let product_types = Object.values(products).filter((item:any)=>item.type).map((item: any)=>{
        return item.type;
      });
      let product_codes = Object.values(products).filter((item:any)=>item.product_code).map((item: any)=>{
        return item.product_code;
      });
      isValidStep = appStep && conditionHelperService.resolve({
        product_types: ['*',...product_types],
        product_codes: ['*',...product_codes]
      }, appStep.filter_conditions).resolved;
    }
    return isValidStep;
  }

  navigateOnBackendEditApp(productConfig, storeAppData, action, businessData, app_item){
    let applicationStepsConfig = productConfig?.customer?.applicationSteps || {};
    return this.getApplicationProgressItems(storeAppData.product_types).pipe(
      map((progress_steps: any)=>{
      let currentState = 'loan-type';
      if(action.type === "complete_application"){
        currentState = businessData?.current_state || 'loan-type';
      }else{
        if(productConfig?.multi_product?.product_selection){
          //multi product journey
          if(!app_item?.products?.length){
            //no products found
            currentState = 'choose-your-product';
          }else{
            if(progress_steps?.length){
              let valid_routes = [];
              for(let step of progress_steps){
                let step_route = applicationStepsConfig[step.type]?.redirects?.currentRoute;
                if(step_route){
                  valid_routes.push(step_route)
                }
              }
              if(valid_routes?.length && !valid_routes.includes(currentState)){
                //find valid first route skipping choose product
                let valid_first_route =  valid_routes.filter((route)=>route != "choose-your-product")[0];
                if(valid_first_route){
                  currentState = valid_first_route;
                }
              }
            }
          }
        }
      }
      sessionStorage.setItem("maxStep", this.CONSTANTS.APP_STEP[businessData.current_state]);
      // console.log("currentState", currentState);
      this.store.dispatch(addAppDetails({ appData: storeAppData}));
      this.commonService.navigate(currentState);
      return { currentState }
    }));
  }

  getBusinessPurpose(business) {
    if(business){
      if (business.credit_request){
        let purposeArr = [];
        for (let credit_req in business.credit_request) {
          let cre_req_purpose_arr = business.credit_request[credit_req]?.purpose_arr;
          if (cre_req_purpose_arr) {
            cre_req_purpose_arr.map(elem => {
              elem.product = credit_req;
              purposeArr.push(elem);
            });
            // cre_req_purpose_arr[0].product = credit_req;
            // purposeArr.push(cre_req_purpose_arr[0]);
          }
        }
        return purposeArr;
      }
      else {
        return business.purpose_arr;
      }
    }
  }
}