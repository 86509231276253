<ng-container [formGroup]="group">
    <ng-container *ngIf="field?.css?.floatingLabel; else normal">
        <div [ngClass]="(field?.css?.floatingLabelClass)? field?.css?.floatingLabelClass :'floating-label'">
            <input type="text" placeholder="&nbsp;" [ngClass]="(field?.css?.field)? field?.css?.field :'form-control'"
                [formControlName]="field.name" [id]="field.name + '_' + indexVal" [mask]="decimal_places ? 'separator.' + decimal_places : 'separator'"
                thousandSeparator="," [maxlength]="(field?.format?.maxlength)?field.format.maxlength:null">

            <span class="highlight"></span>
            <label [ngClass]="(field?.css?.labelClass)? field?.css?.labelClass :'labelText'"
                [for]="field.name + '_' + indexVal">{{slug + ".label." + field.name | translate}}</label>

            <div *ngIf="group.get(field.name).invalid && (group.get(field.name).dirty || group.get(field.name).touched)"
                class="error-msg">
                <ng-container *ngFor="let validation of field.validations;">
                    <label class="error" *ngIf="group.get(field.name).hasError(validation.name)">
                        {{slug + ".errorMessage." + field.name + "." + validation.name | translate}}
                    </label>
                </ng-container>
            </div>
        </div>
    </ng-container>
    <ng-template #normal>
        <div [ngClass]="(field?.css?.numberLabelParent)? field?.css?.numberLabelParent :'col'">
        
            <div [ngClass]="(field?.css?.labelClass)? field?.css?.labelClass :'fs14 fwsb mb-0'">
                {{slug + ".label." + field.name | translate}}</div>
        </div>
        <div [ngClass]="(field?.css?.inputColClass)? field?.css?.inputColClass :'col-12'">
            <input class="form-control" rubiconNumber type="text" [id]="field.name + '_' + indexVal"
                [formControlName]="field.name" [placeholder]="slug + '.placeholder.' + field.name | translate" [maxlength]="(field?.format?.maxlength)?field.format.maxlength:null">
        </div>
        <div *ngIf="group.get(field.name).invalid && (group.get(field.name).dirty || group.get(field.name).touched)"
            class="error-msg">
            <ng-container *ngFor="let validation of field.validations;">
                <label class="error" *ngIf="group.get(field.name).hasError(validation.name)">
                    {{slug + ".errorMessage." + field.name + "." + validation.name | translate}}
                </label>
            </ng-container>
        </div>
    </ng-template>

</ng-container>