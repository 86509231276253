import {  Component, OnInit, Input, Inject } from '@angular/core';
import { TaskInfoService } from '@rubicon/utils';

@Component({
  selector: 'main-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  footerText: string;
  client_config: any;
  powered_by_logo: string;
  ui_component:[] = [];
  ui_comp_placement: string= "footer";
  ui_comp_type: string = "link";
  constructor(  
    @Inject('environment') public environment,
    @Inject('CONSTANTS') public CONSTANTS,
    public taskInfoService: TaskInfoService) { }

  ngOnInit(): void {
    this.powered_by_logo = this.environment.powered_by_logo ;
    this.footerText = this.CONSTANTS.FOOTER_CONGIF.footer_text;
    this.client_config = this.CONSTANTS.CLIENT_CONFIG;

    let params= {slug: this.CONSTANTS.SLUG['ui-component'], placement: this.ui_comp_placement, component_type: this.ui_comp_type};
    this.taskInfoService.getTaskInfo(params).subscribe((res)=>{
      if(res && res.response_data){
        this.ui_component= res.response_data.get_ui_component?.data?.data;
      }
    });
  }
  
}
