import { Injectable } from '@angular/core';
import { conditionHelperService } from '../utils/conditions.helper';
@Injectable({
    providedIn: 'root'
})

export class BeneficiaryOwnerFeatureService {

  constructor() {}
 
  conditionConfig(formValues: any, config: any ){
    if(!config) return false;

    if(config?.enabled){
        if(config?.required){
            return true;
        }
        else {
            let conditions = Object.keys(config?.conditions);
            if(conditions?.length > 0){
              switch (conditions[0]?.toLowerCase()) {
                case 'and':
                    return !config?.conditions[conditions[0]].some((res: any) => !conditionHelperService.operatorConfig(formValues, res));
                case 'or': 
                    return config?.conditions[conditions[0]].some((res: any) => conditionHelperService.operatorConfig(formValues, res));
                default:
                    return false;
            }
            }
        }
    }
    else{
        return false;
    }
  }

  
  
}