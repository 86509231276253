import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { RouteValidationService } from "../services/route-service/route-validation.service";

@Injectable({
    providedIn: 'root'
})
export class RouteValidationGuard implements CanActivate{

    constructor(
        private routeValidationService: RouteValidationService,
        private router: Router,
    ) {}


    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        let removeStringFromURL
        let url = state?.url;
        // remove hash from url
        if(route?.params?.hash) {
            removeStringFromURL = "/" + route?.params?.hash        
            url = url.replace(removeStringFromURL, '');
        }
        
        if(this.routeValidationService?.isValidRoutes(url)) {
            return true;
        }else {
            this.router.navigate(['/page-not-found']);
            return false;
        }
        
    }


    
}