import { Injectable } from '@angular/core';

import { select, Store, Action } from '@ngrx/store';

import { SignupStatePartialState } from './signup-state.reducer';
import { signupStateQuery } from './signup-state.selectors';

@Injectable({
  providedIn: 'root',
})
export class SignupStateFacade {
  loaded$ = this.store.pipe(select(signupStateQuery.getLoaded));
  allSignupState$ = this.store.pipe(select(signupStateQuery.getAllSignupState));
  submitSignupState$ = this.store.pipe(select(signupStateQuery.getSignupResponseState));
  getErrorState$ = this.store.pipe(select(signupStateQuery.getError));

  constructor(private store: Store<SignupStatePartialState>) { }

  dispatch(action: Action) {
    return this.store.dispatch(action);
  }
}
