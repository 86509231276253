<ng-container [formGroup]="group">
    <div class="fileWrap">
    <div class="row">
        <div [ngClass]="(field?.css?.childClass)? field?.css?.childClass : 'col-12 col-lg-10 m-auto'">
            <div class="p-label">Upload file</div>
            <label [for]="field.name" class="browseWrap">
                <span *ngFor="let file of group.get('file_data').value; let i=index" class="filename text-truncate"
                [ngClass]="(field?.css?.listClass)? field?.css?.listClass :''">
                {{file.name}} <span class="d-inline-block deleteIcon2 mx-2" (click)="onDelete(i)"></span>
                </span>
                <label class="btn btn-primary browseBtn" [ngClass]="(field?.css?.labelClass)? field?.css?.labelClass :''">{{slug +
                    ".label." + field.name | translate}}
                    <input class="sr-only" type="file" [id]="field.name" placeholder="Upload file" (change)="onSelectFile($event)" [formControlName]="field.name"
                        #fileInput />
                </label>
            </label>
            
        </div>
        <!-- <div [ngClass]="(field?.css?.fileClass)? field?.css?.fileClass : 'col-12 col-lg-9'">
            <div *ngFor="let file of group.get('file_data').value; let i=index" class="d-flex pt-2 filename"
                [ngClass]="(field?.css?.listClass)? field?.css?.listClass :''">
                {{file.name}} <div class="d-inline-block deleteIcon2 mx-2" (click)="onDelete(i)"></div>
            </div>
        </div> -->

        <!-- <iframe id="filePreview" class="embed-responsive-item d-none" src=""> </iframe>
        <div *ngIf="iframeReady" class="previewBtn">
            <button class="btn btn-default btn-md" (click)="showPreview()">Preview</button>
        </div> -->
    </div>
    <div *ngIf="group.get(field.name).invalid && (group.get(field.name).dirty || group.get(field.name).touched)"
        class="error-msg">
        <ng-container *ngFor="let validation of field.validations;">
            <label class="error" *ngIf="group.get(field.name).hasError(validation.name)">
                {{slug + ".errorMessage." + field.name + "." + validation.name | translate}}
            </label>
        </ng-container>
    </div>
    <!-- <div class="row mt-3" *ngIf="field?.css?.instruction">
        <div [ngClass]="(field?.css?.instruction)? field?.css?.instruction : 'col-12 col-lg-12'">
            <div class="fs12 fwm border-dotted p-3" [innerHTML]='slug + ".instruction." + field.name | translate'></div>
        </div>
    </div> -->
</div>
</ng-container>