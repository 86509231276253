<footer>
    <div class="container">
        <div class="row">
            <div class="col-12 col-sm-6 col-md-6 col-lg-6 copyrightlink">
                <ul>
                    <li><a href="https://www.biz2credit.com/privacy-policy" target="_blank">Privacy</a></li>
                    <li><a href="https://www.biz2credit.com/terms-of-use" target="_blank">Terms</a></li>
                    <li><a href="https://www.biz2credit.com/site-index" target="_blank">Sitemap</a></li>
                </ul>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-6 copyright">
                <p>© <a href="https://www.biz2credit.com/">Biz2Credit Inc 2019</a>. All rights reserved.</p>
            </div>
        </div>
    </div>
</footer>