import { Component, OnInit, Output, Input, EventEmitter, Inject } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { FeatureAccessService } from '@rubicon/utils_custom';
import { CpaService } from 'libs/utils/src/lib/services/client/cpa/cpa.service';
import { CommonService } from '@rubicon/utils';

@Component({
  selector: 'rubicon-affiliate-header-inner',
  templateUrl: './affiliate-header-inner.component.html',
  styleUrls: ['./affiliate-header-inner.component.scss']
})
export class AffiliateHeaderInnerComponent implements OnInit {

  @Input() template;
  @Output() clickEvent: EventEmitter<any> = new EventEmitter();
  @Output('dashboardClicked') dashboardClickedEvent: EventEmitter<any> = new EventEmitter();
  @Input() createUser;
  @Input() currentState;
  isBulKUploadEnable: boolean = false;
  manageUser: boolean;
  planType = false;
  plan = 'none';
  // storeSubscription: any;
  myPlanActive: boolean;
  @Input() isAdmin;
  @Input() isTabView;
  cpa_com: any;
  
  constructor( 
    @Inject("urls") public URLS,
    private store: Store<any>,
    @Inject('environment') private environment,
    private cpaService: CpaService,
    private featureService: FeatureAccessService,
    private common: CommonService
    ) {
      this.isBulKUploadEnable = this.featureService.featureValue('pp1_forgiveness.excel_parse');
      // this.isBulKUploadEnable = environment.CUSTOMER_BULK_UPLOAD;
      // this.manageUser = this.environment.user_management;
      this.manageUser = this.featureService.featureExists('acl.user_count') || this.featureService.featureExists('acl.role_count');
    
    }

  ngOnInit(): void {
    this.cpa_com = this.URLS?.cpa_com
    this.plan = this.featureService.getPlan()?this.featureService.getPlan().type:'none';
    if(this.plan == "enterprise"){
      this.planType = true; 
    }
    if(this.plan == "free"){
       this.manageUser = false; 
    }

    if(this.plan === 'enterprise' || this.plan === 'premium' || this.plan === 'custom' || this.plan == "basic" || this.plan == "new_premium") {
      this.myPlanActive = true;
    }
    
    this.featureService.planType.subscribe((data:any)=>{
      if(data.type == "enterprise"){
      this.planType = true;
    }
      if(this.plan == "free"){
       this.manageUser = false; 
      }

      if(data.type === 'enterprise' || data.type === 'premium' || data.type == "basic" || data.type == "new_premium") {
        this.myPlanActive = true;
      }
    })
    // this.storeSubscription = this.store.
    // pipe(
    //   select('app'),
    //   take(1)
    // ).subscribe(rootState => {
    //   if(rootState) {
    //     if (rootState.nextTask) {
    //     }
    //   }
    // });
  }

  cpaClient() {
    return this.cpaService.isPartnerCPA();
  }

  dashboardClicked(){
    if(this.featureService.getPlan()){
      this.common.navigateByUrl('dashboard');
      this.dashboardClickedEvent.emit();
    }else{
      this.common.navigateByUrl('plans');
    }
  }

  openModal() {
    this.clickEvent.emit(this.template);
  }
}
