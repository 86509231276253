<ng-container [formGroup]="group">
    <ng-container *ngIf="!field.multiple;else multipleCase">
        <div [ngClass]="field.css.heading? field.css.heading:''" *ngIf="field.css.heading">
            {{slug + ".label." + field.name +".title" | translate}}
            <ng-template #popContentTitle2>
                <div class="fs12 fwsb">Instructions</div>
            </ng-template>

            <ng-template #popContent2>
                <div [innerHTML]="slug + '.label.' + field.name+'_tooltip' | translate"></div>
            </ng-template>

            <button type="button" class="infoTip border-0" ngbdata-toggle="popover" ngbdata-html="true"
                ngbdata-placement="right" [ngbPopover]="popContent2" triggers="mouseenter:mouseleave"
                [popoverTitle]="popContentTitle2" style="background: transparent;">
                <img src="/assets/base/images/info-icon.svg" alt="" style="width:16px;">
            </button>
            <div class='fs10 fwm' *ngIf="field.css.help_text" [innerHTML]="slug + '.label.' + field.name +'.help_text'| translate"></div>
        </div>
        <rubicon-dynamic class="row" [fields]="field.group_fields" [group]="group.get(this.field.name)" [slug]="slug" [index]="index">
        </rubicon-dynamic>
    </ng-container>
    <ng-template #multipleCase>
        <div [ngClass]="field.css.heading? field.css.heading:''" *ngIf="field.css.heading">
            {{slug + ".label." + field.name +".title"| translate}}
            <ng-template #popContentTitle2>
                <div class="fs12 fwsb">Instructions</div>
            </ng-template>

            <ng-template #popContent2>
                <div [innerHTML]="slug + '.label.' + field.name+'_tooltip' | translate"></div>
            </ng-template>

            <button type="button" class="infoTip border-0" ngbdata-toggle="popover" ngbdata-html="true"
                ngbdata-placement="right" [ngbPopover]="popContent2" triggers="mouseenter:mouseleave"
                [popoverTitle]="popContentTitle2" style="background: transparent;">
                <img src="/assets/base/images/info-icon.svg" alt="" style="width:16px;">
            </button>
            <div class='fs10 fwm' [innerHTML]="slug + '.label.' + field.name +'.help_text'| translate">
            </div>

        </div>
        <ng-container [formArrayName]="field.name" *ngFor="let item of control; let i = index;">
            <ng-container [formGroupName]="i">
                <div [ngClass]="field.css.wrapper? field.css.wrapper:''">
                    <div [ngClass]="field.css.labelWrapper? field.css.labelWrapper:'row'"
                        *ngIf="slug === 'applicant-details'">
                        <div class="col-lg-7">
                            <div class="form-group mb-0">
                                <label class="primary-label m-0 p-0"
                                    [innerHTML]="slug + '.label.' + i + 'applicant' | translate"
                                    [ngClass]="field.css.labelClass? field.css.labelClass:'primary-label m-0 p-0'"
                                    for="businessName"></label>
                            </div>
                        </div>
                        <div class="col-lg-5 text-right" *ngIf="slug + '.label.is_primary' | translate">
                            <div class="md-radio md-radio-inline my-0 mt-5 pt-3">
                                <input class="form-check-input" type="radio" [disabled]="isPrimarySelected(i)"
                                    (click)="setOwnerValue($event,i)" name="is_primary"
                                    [checked]="group.get('owners').controls[i].get('is_primary').value"
                                    [id]="'is_primary' +'_' + i">
                                <label class="form-check-label fs12 fwsb  pr-lg-0" [for]="'is_primary' +'_' + i">
                                    {{slug + '.label.is_primary' | translate}}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="slug === 'applicant-details'">
                        <div class="boototmline pt-3"></div>
                    </div>
                    <rubicon-dynamic [fields]="field.group_fields" [ngClass]="field.css.field? field.css.field:'row'"
                        [group]="item" [slug]="slug" [index]="i">
                    </rubicon-dynamic>
                    <div [ngClass]="field.css.removeButton? field.css.removeButton:' col-12 text-right border-bottom pb-4 pr-0 mb-5'" style="margin-top: -65px" *ngIf="control.length>1 || (slug == 'collateral' && control.length>0)">
                        <button 
                            class="btn btn-primary btn-sm remove-link mt-sm-0 mt-2" (click)="showPopup(i, control.length)"
                            type="button">
                            {{slug + ".label." + field.name +".remove" | translate}}
                        </button>
                    </div>
                </div>
            </ng-container>
        </ng-container>
        <div
         *ngIf="(control.length<field.max_limit || !field.max_limit)&& saturationReached() "
            [ngClass]="field.css.addButtonContainer?field.css.addButtonContainer:'alert alert-warning col-12 col-lg-9'"
            role="alert">
            <div class="justify-content-between text-right">
                <ng-container *ngIf="is_corporate_entity; else other">
                        <a href="javascript:void(0)" (click)="addMore()"
                        [ngClass]="field.css.addButton? field.css.addButton:'btn-link'">
                        {{slug + '.label.' + field.name + '.button_entity' | translate}}
                    </a>
                </ng-container>
                <ng-template #other>
                        <a href="javascript:void(0)" (click)="addMore()"
                        [ngClass]="field.css.addButton? field.css.addButton:'btn-link'">
                        {{slug + '.label.' + field.name + '.button' | translate}}
                    </a>
                </ng-template>
                <div *ngIf="field.total">
                    <div class="sm-text">
                        <span class="sm-text f-sbold">$ {{total}}
                        </span><br>
                        {{slug + ".label." + field.total | translate}}
                    </div>
                </div>
            </div>
        </div>
    </ng-template>

</ng-container>
<!-- Creating a modal for popping up on clicking remove -->
<div class="modal fade" [id]="field.name+'-remove'">
    <div class="modal-dialog">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">{{slug+".popup."+field.name+".title" | translate}}</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                {{slug+".popup."+field.name+".message" | translate}}
            </div>

            <!-- Modal footer -->
            <div class="modal-footer">
                <button type="button" class="btn btn-primary btn-sm" 
                    (click)="removeForm(index_field, length)">{{slug+".popup."+field.name+".ok" | translate}}</button>
                <button type="button" class="btn btn-secondary btn-sm"
                    (click)="closeModal()">{{slug+".popup."+field.name+".cancel" | translate}}</button>
            </div>

        </div>
    </div>
</div>