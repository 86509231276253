<ng-template #modalTemplate>
        <div class="modal-header pl-3">
                <h4 class="modal-title pull-left">{{ModalHeaderName}}</h4>
                <ng-container *ngIf="showCloseBtn">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="onButtonClick('cancel')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </ng-container>
            </div>
            <div class="modal-body" [ngClass]="modalBodyClass">
                <div class="fs18 ptc pb-2" [innerHtml]="notificationMessage"></div>
            </div>
            <div class="sbaConfirmation-footer">
                <div class="pt-3 text-right w-100">
                    <ng-container *ngIf="showCancelBtn">
                        <button type="button" (click)="onButtonClick('cancel')"  class="btn btn-default mr-3">{{CancelBtnName}}</button>
                    </ng-container>
                    <ng-container *ngIf="showOkBtn">
                        <button type="button" (click)="onButtonClick('ok')" class="btn btn-primary" [disabled]="okBtnDisabled">{{OkBtnName}}</button>
                    </ng-container>
                </div>
            </div>
</ng-template>