import { createReducer, Action } from '@ngrx/store';

export const initialState = {

}

const utilReducer = createReducer(
    initialState
);

export function reducer(state: any, action: Action) {
    return utilReducer(state, action);
}