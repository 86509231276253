import { Router } from '@angular/router';
import { Component, OnInit, Input, Inject } from '@angular/core';
import { CommonService, onLogout,addAppDetails, FormGenerateService, TaskInfoService, addUserDetails } from '@rubicon/utils';
import { select, Store } from '@ngrx/store';
import { debounceTime, distinctUntilChanged, map, take, takeUntil } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { updateDateSearchFilter } from '../+state/header.actions';
import { selectShowRangeFilter, selectDateRange } from '../+state/header.selectors';

@Component({
  selector: 'main-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
   loginUser: any;
   userData: any;
   permissions_assigned: any;
   showAdmin!: any;
   logo1_path: string;
   logo2_path: string;
   page_url: string;
   environment_type:string;
   showManageLeads = false;
   showDashboard = false;
   appData:any;
   showSignIn: boolean = false;
   dateRangeSlug: string;
   dateRangeConfig: any
   dateRangeForm: FormGroup;
   dateRangeFilter = false;
   compDestroyed$ = new ReplaySubject(1);
   referral_source : any;
  
   @Input() hideButton: Boolean = false;

   constructor(private commonService: CommonService,
    private store: Store<any>,
    private router: Router,
    @Inject('environment') public environment,
    @Inject('cssList') public cssList,
    private taskInfoService: TaskInfoService,
    private formGenerateService: FormGenerateService,
    ) { 
    this.page_url = this.router.url;    
      // console.log('page url boolean',)
    }

  ngOnInit(): void {
  this.logo1_path = this.environment.logo1_path ;
  this.logo2_path = this.environment.logo2_path ;
  this.environment_type = this.environment.journeyType;
  this.store.pipe(select(selectShowRangeFilter), takeUntil(this.compDestroyed$)).subscribe(
    (showRangeFilter)=>{
      if(showRangeFilter && !this.dateRangeForm){
        this.loadDateRangeForm();
      }
    this.dateRangeFilter = showRangeFilter;
  });
    
    this.store.select('app').pipe(
      distinctUntilChanged())
      .subscribe(rootState => {
        this.appData = rootState?.appData;
        if(rootState?.appData?.referral_source){
          this.referral_source = rootState?.appData?.referral_source;
        }
        if (rootState?.userData?.id) {
          this.referral_source = null;
          this.userData = {...rootState.userData};
          if(this.environment_type === "banker-journey") {
            this.permissions_assigned= this.userData.permissions_assigned;
            this.showAdmin = this.permissions_assigned?.find(x=> x?.permissions?.slug=='admin_portal');
            this.showDashboard = this.permissions_assigned?.find(x=> x?.permissions?.slug=='dashboard');
            this.showManageLeads = this.permissions_assigned?.find(x=> x?.permissions?.slug=='leads');
          } else {
            this.showDashboard = true;
          }

          if(!this.userData.full_name){
            this.userData['full_name'] = rootState.userData.first_name+' '+ rootState.userData.last_name;
          } 
        }
        else if(rootState?.appData?.isSignButton){
          this.userData = null;
          this.hideButton=false 
        }
        else {
          this.userData = null;
          this.hideButton=true;
        }
        if(rootState?.appData?.isSignButton =='reset-password'){
          this.showSignIn=true;
        }
        else{
          this.showSignIn=false;
        }
    });
  }

  onNavigate(key) {
    this.showSignIn=false;
    this.store.dispatch(addAppDetails({
      appData: {
        ...this.appData,
        isSignButton: undefined
      }
    }))
    this.commonService.navigate(key);
  }


  logoNavigation() {
    if(this.userData && this.userData?.id){
      if(this.showDashboard)
        this.submit('dashboard');
      else if (this.showManageLeads)  
        this.commonService.navigate('manage-leads');
      else if (this.showAdmin)  
        this.commonService.navigate('admin');
      else {
        this.commonService.popToast('Error', '', 'No route to navigate.');
      }
    }
    else{
      this.submit('logout');
    }
  }

  submit(key){
    if(key === 'logout') {
      this.store.dispatch(onLogout({}));
      if(this.referral_source){
        this.store.dispatch(addAppDetails({
          appData: {referral_source: this.referral_source}
        }))
      }
      this.userData = {};
      this.commonService.navigate('signin');
    }
    else if( key === 'change_password') {
      this.commonService.navigate('change-password');
    }
    else if(key === 'dashboard') {
      if(this.environment.journeyType == "banker-journey") this.commonService.navigate("banker-dashboard")
      else this.commonService.navigate('dashboard');
    }
  }

  loadDateRangeForm(){
    this.taskInfoService.getTaskInfo({slug:'backend-dashboard-filter-form'}).pipe(debounceTime(1000)).subscribe((response:any) => {
      this.dateRangeSlug = response.task_slug;
      this.dateRangeConfig = response.form_fields;
      this.dateRangeForm = this.formGenerateService.createControl(this.dateRangeConfig);
      this.updateDateRangeForm();
    });
  }

  updateDateRangeForm() {
    const currentDate = new Date();
    const selectedYear = currentDate.getFullYear();
    const selectedMonth = currentDate.getMonth();
    this.store.pipe(
      select(selectDateRange), take(1), takeUntil(this.compDestroyed$)).pipe(
        map((dateRange)=>{
        let start_date;
        let end_date;
        if(dateRange?.start_date){
          start_date = new Date(+dateRange?.start_date*1000);
        } else {
          start_date = new Date(currentDate.setFullYear(selectedYear-1, 0, 1)).setHours(0, 0, 0, 0);
        }
        // else // For instance- If today is 17-Jan-2023 then the calendar should show 1-Dec-2022 to 17-Jan-2023 (biz2x-2105)
        // {
        //   currentDate.setDate(1);
        //   currentDate.setMonth(selectedMonth - 1);
        //   start_date = new Date(currentDate).setHours(0, 0, 0, 0);
        // }
        if(dateRange?.end_date){
          end_date = new Date(+dateRange?.end_date*1000);
        }else{
          end_date = new Date(currentDate.setFullYear(selectedYear, selectedMonth + 1, 0)).setHours(23, 59, 59, 0);
        }
        return {
          start_date: new Date(start_date),
          end_date: new Date(end_date)
        };
      })).subscribe(dateRange=>{
      this.dateRangeForm.patchValue(dateRange);
      this.applyDateFilter();
    });
  }

  applyDateFilter():void {
    const start_date=(this.dateRangeForm.getRawValue()?.start_date.getTime()/1000).toString();
    const end_date=(this.dateRangeForm.getRawValue()?.end_date.getTime()/1000).toString();
    if(start_date < end_date) {
      this.store.dispatch(updateDateSearchFilter({start_date, end_date}));
    } else {
      this.commonService.popToast('error','','Start date cannot be greater than end date.')
    }
  }

  ngOnDestroy(){
    this.compDestroyed$.next();
    this.compDestroyed$.complete();
  }
}
